import { useMutation, useQuery } from '@apollo/client';
import {
  Callout,
  DefaultButton,
  DirectionalHint,
  IDropdownOption,
  IModalStyles,
  Icon,
  IconButton,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { FormikDropdown } from 'common/components';
import { AccountingDetails } from 'common/components/AccountingDetails';
import { CloseButton } from 'common/components/Buttons/CloseButton';
import { DocumentEntityList } from 'common/components/DocumentEntityList';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import {
  EntityDocument,
  EntityDocumentVariables,
} from 'common/components/FileList/__generated__/EntityDocument';
import {
  EntityDocumentUpdate,
  EntityDocumentUpdateVariables,
} from 'common/graphql/__generated__/EntityDocumentUpdate';
import { EntityDocumentPatch } from 'common/types/globalTypes';
import { dateConvertions } from 'common/utils/dateFormats';
import { GetDocumentPoolCommonData_documentPoolAvailableDocumentTypes_nodes_extractionTypes_nodes } from 'documents/__generated__/GetDocumentPoolCommonData';
import { DocumentTypeOptions } from 'documents/personalPool/UploadDocumentsForm';
import { Form, Formik, useFormikContext } from 'formik';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useToasts } from 'react-toast-notifications';
import { DocumentPoolDocuments_documentPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes } from '../__generated__/DocumentPoolDocuments';
import { FormView } from './FormView';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { DOCUMENT_INITIAL_VALUES } from './constant';
import { validationSchema } from './documentValidations';
import { useStyles } from './index.styles';
import { EntityDocumentValues } from './types';
import { ExtractionTypes } from './__generated__/ExtractionTypes';
import { getFileTypesList } from './utils';
const UPDATE_DOC = loader(
  '../../../../../common/graphql/EntityDocumentUpdate.graphql'
);
const ENTITY_DOCUMENT = loader(
  '../../../../../common/components/FileList/EntityDocument.graphql'
);
const EXTRACTION_TYPES = loader('./ExtractionTypes.graphql');

const modalStyles: Partial<IModalStyles> = {
  main: {
    marginLeft: 'auto',
  },
};
export interface UploadDocumentMutationProps {
  uploadDocumentData: (
    documentType: IDropdownOption,
    fileSelected: File[],
    content?: string | null,
    indexName?: string,
    indexDescription?: string | null,
    indexReferenceNumber?: string | null,
    indexTransactionDate?: string | undefined | null,
    indexAmount?: number | null,
    indexCurrencyId?: number | null,
    comment?: string | null,
    extractionTypeId?: number | null
  ) => void;
}
interface UploadFormProps {
  defaultDocumentTypeId: number | undefined;
  isDocumentTypeSelector: boolean;
  extractionTypeId: number | null;
  documentData: DocumentPoolDocuments_documentPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes | null;
  documentOptions?: DocumentTypeOptions[];
  companyCurrencies?: IDropdownOption[];
  visible: boolean;
  uploadDocument: UploadDocumentMutationProps;
  onDismiss: () => void;
}
export const UploadDocumentForm: React.FC<UploadFormProps> = ({
  defaultDocumentTypeId,
  isDocumentTypeSelector,
  extractionTypeId,
  documentData,
  visible,
  documentOptions,
  uploadDocument,
  onDismiss,
  companyCurrencies,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [fileSelected, setFileSelected] = useState<File[]>([]);
  const [showIsAccountingDocumentInputs, setShowIsAccountingDocumentInputs] =
    useState<boolean>(true);
  const [hideExtractionDropdown, setHideExtractionDropdown] =
    useState<boolean>(false);
  const [isAccountingDocument, setIsAccountingDocument] =
    useState<boolean>(false);
  const [documentID, setDocumentID] = useState<string>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });
  //When usecallback use previous states get cleard
  const onDrop = (files: File[]) => {
    setFileSelected([...fileSelected, ...files]);
  };
  const multipleIsAccountingDocumentSelected =
    isAccountingDocument && fileSelected.length > 1;
  useEffect(() => {
    if (multipleIsAccountingDocumentSelected) {
      setShowIsAccountingDocumentInputs(false);
    } else {
      setShowIsAccountingDocumentInputs(true);
    }
  }, [multipleIsAccountingDocumentSelected]);
  // const [retireBalance, { loading: retireBalanceLoading }] = useMutation<
  //   EntityDocumentRetireBalance,
  //   EntityDocumentRetireBalanceVariables
  // >(RETIRE_BALANCE, { errorPolicy: 'all' });
  const [UpdateDocument, { loading: updateDocLoading }] = useMutation<
    EntityDocumentUpdate,
    EntityDocumentUpdateVariables
  >(UPDATE_DOC, { errorPolicy: 'all' });
  const [documentType, setDocumentType] = useState<DocumentTypeOptions>();
  const { uploadDocumentData } = uploadDocument;
  let { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
    multiple: true,
  });
  const onRemoveFile = (fileIndex: number) => {
    let newFiles = [...fileSelected!].filter((_, index) => fileIndex !== index);
    setFileSelected(newFiles);
  };

  const { data: entityDocumentData, loading: entityDocumentDataLoading } =
    useQuery<EntityDocument, EntityDocumentVariables>(ENTITY_DOCUMENT, {
      variables: {
        documentId: documentData?.id!,
      },
      skip: !documentData?.id,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });
  const { data: extractionTypesData } = useQuery<ExtractionTypes>(
    EXTRACTION_TYPES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  let initialValues: EntityDocumentValues = DOCUMENT_INITIAL_VALUES;
  if (entityDocumentData?.entityDocument) {
    initialValues = {
      fileIndexInformation:
        entityDocumentData?.entityDocument?.fileIndexInformation,
      _documentContents: entityDocumentData?.entityDocument?._documentContents,
      comment: entityDocumentData?.entityDocument?.comment,
      entityDocumentTypeId:
        entityDocumentData?.entityDocument?.entityDocumentTypeId,
      indexTransactionDate: entityDocumentData?.entityDocument
        ?.indexTransactionDate
        ? dateConvertions(
            entityDocumentData?.entityDocument?.indexTransactionDate
          )
        : null,
      indexName: entityDocumentData?.entityDocument?.indexName,
      indexDescription: entityDocumentData?.entityDocument?.indexDescription,
      indexReferenceNumber:
        entityDocumentData?.entityDocument?.indexReferenceNumber,
      indexAmount: entityDocumentData?.entityDocument?.indexAmount,
      indexCurrencyId: entityDocumentData?.entityDocument?.indexCurrencyId,
      extractionTypeId: entityDocumentData?.entityDocument?.extractionTypeId,
    };
  } else {
    initialValues = {
      ...initialValues,
      extractionTypeId,
    };
  }

  useEffect(() => {
    if (documentData && documentData?.id) {
      setDocumentID(documentData.id);
      setIsAccountingDocument(
        documentData.documentTypes?.isAccountingDocument || false
      );
    }
  }, [documentData]);
  useEffect(() => {
    if (defaultDocumentTypeId) {
      const optionMap = documentOptions?.map(
        (item) => item as DocumentTypeOptions
      );
      let optionFilter: DocumentTypeOptions[] | undefined;
      if (documentData && documentData.id) {
        optionFilter = optionMap?.filter(
          (item) => item.key === documentData?.entityDocumentTypeId
        );
      } else {
        optionFilter = optionMap?.filter(
          (item) => item.key === defaultDocumentTypeId
        );
      }
      if (optionFilter && optionFilter[0]) {
        setDocumentType(optionFilter[0]);
        setIsAccountingDocument(optionFilter[0].isAccountingDocument || false);
        if (optionFilter![0].isAccountingDocument) {
          setFileSelected([]);
        }
      }
    } else {
    }
  }, [visible, defaultDocumentTypeId, documentOptions, documentData]);
  const handleSubmit = async (values: EntityDocumentValues) => {
    if (documentID) {
      const { ...item } = values;
      const entityDocumentPatch: EntityDocumentPatch = Object.entries(
        item
      ).reduce(
        (res, [key, val]) => {
          if (val !== initialValues[key as keyof EntityDocumentValues]) {
            return { ...res, [key]: val };
          }
          return res;
        },
        {
          indexTransactionDate: values.indexTransactionDate,
        }
      );

      const { errors } = await UpdateDocument({
        variables: {
          input: {
            entityDocumentsUpdate: [
              {
                id: documentData?.id,
                rowTimestamp: entityDocumentData?.entityDocument?._rowTimestamp,
                entityDocumentPatch: entityDocumentPatch,
              },
            ],
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: ENTITY_DOCUMENT,
            variables: {
              documentId: documentData?.id,
            },
          },
        ],
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Document updated', { appearance: 'success' });
      }
    } else {
      let filteredFilesList: File[] = fileSelected;
      if (values.extractionTypeId) {
        const selectedExtractionType =
          extractionTypesData?.extractionTypes?.nodes.find(
            (node) => node.id === values.extractionTypeId
          );
        const fileTypesAllowed = getFileTypesList(selectedExtractionType);
        filteredFilesList = fileSelected.filter((file) => {
          return fileTypesAllowed?.includes(file.type);
        });
      }
      if (multipleIsAccountingDocumentSelected)
        uploadDocumentData(
          documentType!,
          filteredFilesList || [],
          null,
          values.indexName!,
          null,
          null,
          null,
          null,
          null,
          null,
          values.extractionTypeId
        );
      else {
        uploadDocumentData(
          documentType!,
          filteredFilesList || [],
          values._documentContents!,
          values.indexName!,
          values.indexDescription,
          values.indexReferenceNumber!,
          values.indexTransactionDate!,
          values.indexAmount ? parseFloat(values.indexAmount) : null,
          values.indexCurrencyId,
          values.comment!,
          values.extractionTypeId
        );
      }
      if (fileSelected.length - filteredFilesList.length > 0)
        addToast('Files with invalid data types are not uploaded', {
          appearance: 'error',
        });
    }
  };
  const clearData = () => {
    setDocumentID(undefined);
    setIsAccountingDocument(false);
    setDocumentType(undefined);
    setFileSelected([]);
  };
  // const CONFIRM_DIALOG_TITLE = 'Are you sure you want to retire balance?';
  // const CONFIRM_DIALOG_SUBTEXT = '';
  // const [hideConfirmDialog, setHideConfirmDialog] = useState<boolean>(true);
  const [dataExtractModelOptions, setDataExtractModelOptions] = useState<
    IDropdownOption[]
  >([]);
  // const toggleConfirmDialog = () =>
  //   setHideConfirmDialog((prevState) => !prevState);
  const disableCustomDropdown =
    isDisabled || isDocumentTypeSelector || documentOptions?.length === 1;
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const calloutId = useId(`callOutId${documentData?.id!}`);
  const isDocumentProtected = documentData
    ? !documentData?._isUpdatable
    : false;

  return (
    <Stack>
      <Formik<EntityDocumentValues>
        enableReinitialize
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, dirty, resetForm, errors, values }) => {
          return (
            <Form>
              <Modal
                isOpen={visible}
                isBlocking={true}
                onDismiss={onDismiss}
                styles={documentID ? modalStyles : undefined}
              >
                <Stack className={styles.container}>
                  <Stack
                    horizontal
                    horizontalAlign={'space-between'}
                    className={styles.dialogStyles}
                  >
                    <Stack tokens={{ childrenGap: 10 }}>
                      <Text variant={'xLarge'}>
                        {`${documentID ? 'Update' : 'Upload'}`} Documents
                      </Text>
                      {!documentID && (
                        <Text variant={'medium'}>
                          Select a document to upload for this Folder
                        </Text>
                      )}
                      {documentID &&
                        documentData?.documentFileDistributionsByDocumentFileId
                          ?.totalCount &&
                        documentData?.documentFileDistributionsByDocumentFileId
                          ?.totalCount > 0 && (
                          <>
                            <Text
                              className={styles.accountingDetailsText}
                              id={calloutId}
                              onMouseEnter={() => setIsCalloutVisible(true)}
                            >
                              Accounting Details
                            </Text>
                            {isCalloutVisible && (
                              <Callout
                                className={styles.calloutContainer}
                                gapSpace={0}
                                target={`#${calloutId}`}
                                directionalHint={DirectionalHint.leftTopEdge}
                                onDismiss={() => setIsCalloutVisible(false)}
                              >
                                <AccountingDetails
                                  data={
                                    documentData.documentFileDistributionsByDocumentFileId
                                  }
                                />
                              </Callout>
                            )}
                          </>
                        )}
                    </Stack>
                    <Stack
                      horizontal
                      tokens={{
                        childrenGap: 20,
                      }}
                    >
                      {documentID && (
                        <>
                          <IconButton
                            iconProps={{ iconName: 'View' }}
                            onClick={() => {
                              setDocViewState({
                                isOpen: true,
                                title:
                                  entityDocumentData?.entityDocument
                                    ?.fileReference,
                                entityDocumentId: documentID,
                                _fileType:
                                  entityDocumentData?.entityDocument
                                    ?._fileType!,
                              });
                            }}
                          />
                        </>
                      )}
                      <CloseButton
                        onClick={() => {
                          resetForm();
                          clearData();
                          onDismiss();
                        }}
                      />
                    </Stack>
                  </Stack>
                  {entityDocumentDataLoading ? (
                    <ShimmerView />
                  ) : (
                    <Stack
                      tokens={{
                        childrenGap: 20,
                        padding: '100px 25px 150px 25px',
                      }}
                    >
                      {documentOptions && (
                        <CustomDropDown
                          isEdit={documentID ? true : false}
                          defaultDocumentTypeId={defaultDocumentTypeId}
                          documentData={documentData}
                          isDocumentProtected={isDocumentProtected}
                          documentOptions={documentOptions}
                          setDocumentType={(data) => setDocumentType(data)}
                          setFileSelected={() => setFileSelected([])}
                          setIsAccountingDocument={(isAccounting) => {
                            setIsAccountingDocument(isAccounting!);
                          }}
                          disabled={disableCustomDropdown}
                          onDocumentTypeChange={setDataExtractModelOptions}
                          hideExtractionDropdown={() =>
                            setHideExtractionDropdown(true)
                          }
                        />
                      )}
                      {!documentID && values.entityDocumentTypeId && (
                        <div
                          {...getRootProps()}
                          className={clsx(
                            styles.dropZone,
                            isDragActive && styles.dropZoneHover
                          )}
                        >
                          <Stack
                            grow
                            horizontalAlign="center"
                            verticalAlign="center"
                            verticalFill
                          >
                            <input
                              {...getInputProps({
                                multiple: true,
                              })}
                            />
                            {isDragActive ? (
                              <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <Icon
                                  iconName={'CloudUpload'}
                                  className={styles.uploadIconStyle}
                                />
                                <Text className={styles.uploadIconStyle}>
                                  Drop the files here...
                                </Text>
                              </Stack>
                            ) : (
                              <Text>
                                Drag 'n' drop some files here, or click to
                                select files
                              </Text>
                            )}
                          </Stack>
                        </div>
                      )}
                      {fileSelected.length > 0 && (
                        <DocumentEntityList
                          onRemoveFile={onRemoveFile}
                          filesList={fileSelected!}
                        />
                      )}
                      <FormView
                        isAccountingDocument={isAccountingDocument}
                        isDocumentProtected={isDocumentProtected}
                        showIsAccountingDocumentInputs={
                          showIsAccountingDocumentInputs
                        }
                        documentData={documentData}
                        documentType={documentType}
                        companyCurrencies={companyCurrencies}
                        onInputFieldsDisabled={setIsDisabled}
                        dataExtractModelOptions={dataExtractModelOptions}
                        isEdit={documentID ? true : false}
                        isDocumentTypeSelector={isDocumentTypeSelector}
                        hideExtractionDropdown={hideExtractionDropdown}
                        isExtractionTypeSelected={
                          extractionTypeId ? true : false
                        }
                      />
                    </Stack>
                  )}
                  <Stack className={styles.footerStyles}>
                    {updateDocLoading && <ProgressIndicator />}
                    <Stack
                      horizontalAlign="space-between"
                      horizontal
                      tokens={{ childrenGap: 6, padding: 25 }}
                    >
                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <PrimaryButton
                          disabled={
                            !dirty ||
                            Object.keys(errors).length > 0 ||
                            isSubmitting ||
                            (!documentData?.id && fileSelected.length === 0) ||
                            documentType === undefined
                          }
                          text={`${documentID ? 'Update' : 'Upload'}`}
                          onClick={async () => {
                            await submitForm();
                            if (!documentID) {
                              resetForm();
                              // clearData();
                              onDismiss();
                            }
                          }}
                        />
                        <DefaultButton
                          onClick={() => {
                            resetForm();
                            clearData();
                            onDismiss();
                          }}
                          text="Cancel"
                        />
                      </Stack>
                      {/* <Stack>
                        {documentData?.documentAppliedAmounts
                          ?._isRetiringBalanceAllowed &&
                          documentData?.documentAppliedAmounts?.usedTotal !==
                            null &&
                          parseFloat(
                            documentData?.documentAppliedAmounts?.usedTotal
                          ) > 0 && (
                            <PrimaryButton
                              disabled={retireBalanceLoading}
                              text="Retire Balance"
                              onClick={toggleConfirmDialog}
                            />
                          )}
                      </Stack> */}
                    </Stack>
                  </Stack>
                  {/* <ConfirmDialog
                    hidden={hideConfirmDialog}
                    title={CONFIRM_DIALOG_TITLE}
                    subText={CONFIRM_DIALOG_SUBTEXT}
                    onDismiss={toggleConfirmDialog}
                    onConfirm={async () => {
                      toggleConfirmDialog();
                      const { errors } = await retireBalance({
                        variables: {
                          input: {
                            entityDocumentId: documentData?.id!,
                            rowTimestamp: documentData?._rowTimestamp!,
                          },
                        },
                      });
                      if (errors?.length)
                        addToast(errors[0].message, {
                          appearance: 'error',
                        });
                      else {
                        addToast('Balance retired', { appearance: 'success' });
                        resetForm();
                        clearData();
                        onDismiss();
                      }
                    }}
                  /> */}
                </Stack>
              </Modal>
            </Form>
          );
        }}
      </Formik>
      <DocumentViewModal
        centerAlign
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        existingDownloadLink={entityDocumentData?.entityDocument?._downloadLink}
        {...docViewState}
      />
    </Stack>
  );
};
interface CustomDropDown {
  isEdit: boolean;
  defaultDocumentTypeId: number | undefined;
  documentData: DocumentPoolDocuments_documentPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes | null;
  isDocumentProtected: boolean;
  documentOptions?: DocumentTypeOptions[];
  setDocumentType: (data: DocumentTypeOptions) => void;
  setIsAccountingDocument: (isAccounting: boolean | undefined) => void;
  setFileSelected: () => void;
  disabled?: boolean;
  onDocumentTypeChange?: (options: IDropdownOption[]) => void;
  hideExtractionDropdown: () => void;
}
export const CustomDropDown: React.FC<CustomDropDown> = ({
  isEdit,
  defaultDocumentTypeId,
  documentData,
  isDocumentProtected,
  documentOptions,
  setDocumentType,
  setIsAccountingDocument,
  setFileSelected,
  disabled,
  onDocumentTypeChange,
  hideExtractionDropdown,
}) => {
  const { values, setFieldValue } = useFormikContext<EntityDocumentValues>();
  useEffect(() => {
    if (defaultDocumentTypeId && !documentData?.id) {
      setFieldValue(
        'entityDocumentTypeId',
        defaultDocumentTypeId ? defaultDocumentTypeId : null
      );
    }
  }, [defaultDocumentTypeId, documentData, documentOptions, setFieldValue]);
  useEffect(() => {
    let extractionTypesArray: GetDocumentPoolCommonData_documentPoolAvailableDocumentTypes_nodes_extractionTypes_nodes[] =
      [];
    extractionTypesArray =
      documentOptions?.find((x) => x.key === values.entityDocumentTypeId)
        ?.extractionTypes.nodes || [];
    onDocumentTypeChange?.(
      extractionTypesArray.map((ele) => ({
        key: ele.id,
        text: ele.extractionType,
      }))
    );
  }, [values.entityDocumentTypeId, documentOptions, onDocumentTypeChange]);
  return (
    <>
      <FormikDropdown
        label="Document Type"
        placeholder="Select"
        name="entityDocumentTypeId"
        options={documentOptions || []}
        onChange={(_event, option) => {
          const selectedOptions = option as DocumentTypeOptions;
          setDocumentType(selectedOptions);
          setIsAccountingDocument(selectedOptions.isAccountingDocument!);
          if (selectedOptions.isAccountingDocument) setFileSelected();
          if (isEdit) hideExtractionDropdown();
        }}
        disabled={
          disabled ||
          (!!documentData &&
            (isDocumentProtected || !documentData._isDocumentTypeUpdatable))
        }
        required
      />
    </>
  );
};
