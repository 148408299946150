import { useQuery, useReactiveVar } from '@apollo/client';
import { IconButton, Stack } from '@fluentui/react';
import Decimal from 'decimal.js';
import {
  GetDocumentPoolCommonData,
  GetDocumentPoolCommonDataVariables,
} from 'documents/__generated__/GetDocumentPoolCommonData';
import { loader } from 'graphql.macro';
import React, { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { setUserDefaults } from 'utility/cache/ui';
import { GetEntityDocument } from '../../__generated__/GetEntityDocument';
import { AccountingViewRowValues, EntityDocumentValues } from '../../types';
import { ACCOUNTING_INITIAL_VALUES } from '../constant';
import { ColumnAmountBottom } from './ColumnAmountBottom';
import { ColumnsHeader } from './ColumnHeader';
import FormField from './FormField';
import { getColumns } from './columns.data';
import { useStyles } from './index.styles';
const GENERAL_DATA = loader('../../../../../GetDocumentPoolCommonData.graphql');

interface AccountingProps {
  documentIsUpdatable: boolean;
  documentData: GetEntityDocument;
}

export const Accounting: React.FC<AccountingProps> = ({
  documentIsUpdatable,
  documentData,
}) => {
  const isDeleting = useRef<boolean>(false);
  const { control, setValue } = useFormContext<EntityDocumentValues>();
  const { fields, remove, append } = useFieldArray({
    name: 'invoiceDistributions', // unique name for your Field Array
  });
  const styles = useStyles();
  const { data: commonData } = useQuery<
    GetDocumentPoolCommonData,
    GetDocumentPoolCommonDataVariables
  >(GENERAL_DATA, {
    variables: {
      isDocumentUpload: true,
    },
  });

  const tax1099T4TypesOptions =
    commonData?.tax1099T4Types?.nodes.map((item) => ({
      key: item.id,
      text: item.tax1099T4Type || '',
    })) || [];

  const watchInvoiceDistributions = useWatch({
    control,
    name: 'invoiceDistributions',
  });
  const userDefaults = useReactiveVar(setUserDefaults);
  const { columnArray } = getColumns(userDefaults);

  const lastItem = watchInvoiceDistributions?.[fields.length - 1];

  const isValueExist = lastItem
    ? Object.entries(lastItem!).reduce((prev, current) => {
      const isValue = current?.[1] !== null;
      return isValue || prev;
    }, false)
    : true;

  const total = watchInvoiceDistributions?.reduce((
    sum: Decimal = new Decimal(0),
    current: AccountingViewRowValues
  ) => {
    const distributionAmount = current.distributionAmount || 0;
    try {
      return new Decimal(distributionAmount || 0).plus(sum);
    } catch (error) {
      return sum
    }
  }, new Decimal(0));

  const { _protectedWithAccountingUpdate, _isUpdatable } = { ...documentData.entityDocument }

  useEffect(() => {
    if (isValueExist && (_isUpdatable || _protectedWithAccountingUpdate) && !isDeleting.current) {
      append(ACCOUNTING_INITIAL_VALUES, { shouldFocus: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [append, isValueExist]);


  return (
    <Stack>
      <Stack className={styles.scrollContainer}>
        <Stack tokens={{ padding: '20px 0px 40px' }}>
          <Stack horizontal style={{ overflowX: 'clip' }}>
            <Stack tokens={{ padding: '52px 0px', childrenGap: 3 }}>
              {fields.map((_, index) => {
                const isDeletable = !!watchInvoiceDistributions?.[index]?.id ?
                  (_isUpdatable || _protectedWithAccountingUpdate ? watchInvoiceDistributions?.[index]?._isDeletable : false)
                  : true;
                const isLastItem = fields.length - 1 !== index && isDeletable;

                return (
                  <Stack key={index} style={{ height: 33, width: 40 }}>
                    {isLastItem && (
                      <IconButton
                        disabled={!isDeletable}
                        iconProps={{ iconName: 'Blocked2Solid' }}
                        ariaLabel="delete"
                        style={{ color: 'red' }}
                        onClick={() => {
                          isDeleting.current = true;
                          remove(index);
                          setTimeout(() => {
                            isDeleting.current = false;
                          }, 1000);
                        }}
                      />
                    )}
                  </Stack>
                );
              })}
            </Stack>
            <Stack style={{ overflowX: 'scroll', padding: '0px 0px 40px 0px' }}>
              <ColumnsHeader columnArray={columnArray} />
              {fields.map((field, index) => {
                const baseField = `invoiceDistributions.${index}`;
                const isLastItem = fields.length - 1 === index;

                return (
                  <Stack key={index} horizontal tokens={{ childrenGap: 10 }}>
                    {columnArray.map((value, key) => {
                      const isEditable = !!watchInvoiceDistributions?.[index]?.id ?
                        (_isUpdatable || _protectedWithAccountingUpdate ? watchInvoiceDistributions?.[index]?._isUpdatable : false)
                        : true;

                      const isDeletable = !!watchInvoiceDistributions?.[index]?.id ?
                        (_isUpdatable || _protectedWithAccountingUpdate ? watchInvoiceDistributions?.[index]?._isDeletable : false)
                        : true;
                      return (
                        <FormField
                          documentData={documentData}
                          baseField={baseField}
                          index={index}
                          columnData={value}
                          disable={!isEditable}
                          tax1099T4TypesOptions={tax1099T4TypesOptions}
                          isDeletable={
                            isLastItem
                              ? false
                              : !!isDeletable
                          }
                          key={field.id + key}
                          onSelectAccount={(data) => {
                            if (!!data.projectAccount) {
                              setValue(
                                `invoiceDistributions.${index}.projectReference`,
                                data.projectAccount
                              );
                            }
                            if (!!data.setAccount) {
                              setValue(
                                `invoiceDistributions.${index}.setReference`,
                                data.setAccount
                              );
                            }
                          }}
                        />
                      );
                    })}
                  </Stack>
                );
              })}
              {fields.length > 1 && (
                <ColumnAmountBottom
                  totalSum={total!}
                  columnArray={columnArray}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
