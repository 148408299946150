import { useReactiveVar } from '@apollo/client';
import { AppRouter } from 'common/types/globalTypes';
import React from 'react';
import { SettingsBase, SettingsOption } from 'settings';
import { SuppliersList } from 'settings/account/supplier/list';
import { setUserPrivileges } from 'utility/cache/ui';
import { TravelersList } from './Travelers/list';
import { ApprovalSetup } from './approvalSetup/list';
import { UserSetup } from './userSetup/list';
import { TravelPolicyList } from './TravelPolicy/list';
import { BusinessUnitsList } from './businessUnits/list';
import { CorporateAccountsList } from './corporateAccounts/list';
import { AccountsEntryList } from './accountsEntry/list';
import { CorporateWorkGroupsList } from './corporateWorkGroups/list';
import { DepartmentGroupsList } from './departmentGroups/list';
import { DepartmentsList } from './departments/list';
import { OccupationsList } from './occupations/list';
import { CompanyMaintenance } from './companyMaintenance';
import { UserTemplatesList } from './userTemplates/list';

const ACCOUNT_PATH = '/account-management';
export const sections: SettingsOption[] = [
  {
    name: 'Approval Rules',
    path: `${ACCOUNT_PATH}/approvals`,
    component: ApprovalSetup,
    routeName: AppRouter.APPROVAL_SETUP,
    icon: 'Org',
  },
  {
    name: 'Users',
    path: `${ACCOUNT_PATH}/users`,
    component: UserSetup,
    routeName: AppRouter.USER_SETUP,
    icon: 'Group',
  },
  {
    name: 'User Templates',
    path: `${ACCOUNT_PATH}/userTemplates`,
    component: UserTemplatesList,
    routeName: AppRouter.DEPARTMENT_OCC_TEMPLATE,
    icon: 'Group',
  },
  {
    name: 'Travelers',
    path: `${ACCOUNT_PATH}/travelers`,
    component: TravelersList,
    routeName: AppRouter.TRAVELER,
    icon: 'Group',
  },
  {
    name: 'Travel Policies',
    path: `${ACCOUNT_PATH}/travel-policy`,
    component: TravelPolicyList,
    routeName: AppRouter.TRAVEL_POLICIES,
    icon: 'Airplane',
  },
  {
    name: 'Suppliers',
    path: `${ACCOUNT_PATH}/suppliers`,
    component: SuppliersList,
    routeName: AppRouter.SUPPLIER,
    icon: 'Contact',
  },
  {
    name: 'Company',
    path: `${ACCOUNT_PATH}/company-maintenance`,
    component: CompanyMaintenance,
    routeName: AppRouter.COMPANY_INFO,
    icon: 'BusinessCenterLogo',
  },
  {
    name: 'Business Units',
    path: `${ACCOUNT_PATH}/business-units`,
    component: BusinessUnitsList,
    routeName: AppRouter.COMPANY_BUSINESS_UNITS,
    icon: 'BusinessCenterLogo',
  },
  {
    name: 'Accounts',
    path: `${ACCOUNT_PATH}/accounts`,
    component: AccountsEntryList,
    routeName: AppRouter.LOOKUP_ACCOUNTS,
    icon: 'AccountManagement',
  },
  {
    name: 'Corporate Accounts',
    path: `${ACCOUNT_PATH}/corporate-accounts`,
    component: CorporateAccountsList,
    routeName: AppRouter.LOOKUP_CORPORATE_ACCOUNTS,
    icon: 'AccountBrowser',
  },
  {
    name: 'Workgroups',
    path: `${ACCOUNT_PATH}/work-groups`,
    component: CorporateWorkGroupsList,
    routeName: AppRouter.CORPORATE_WORKGROUPS,
    icon: 'WorkforceManagement',
  },

  {
    name: 'Departments',
    path: `${ACCOUNT_PATH}/departments`,
    component: DepartmentsList,
    routeName: AppRouter.DEPARTMENT_SETUP,
    icon: 'Org',
  },
  {
    name: 'Department Groups',
    path: `${ACCOUNT_PATH}/department-groups`,
    component: DepartmentGroupsList,
    routeName: AppRouter.DEPARTMENT_GROUP_SETUP,
    icon: 'RowsGroup',
  },
  {
    name: 'Occupations',
    path: `${ACCOUNT_PATH}/occupations`,
    component: OccupationsList,
    routeName: AppRouter.OCCUPATION_SETUP,
    icon: 'Toolbox',
  },
];

export const AccountManagement: React.FC = () => {
  const privileges = useReactiveVar(setUserPrivileges);

  const sectionsList: SettingsOption[] = sections.filter(
    (item) => privileges.has(item.routeName!) || item.routeName === undefined
  );

  return (
    <SettingsBase
      basePath={ACCOUNT_PATH}
      sections={sectionsList}
      title="Account Management"
    />
  );
};
