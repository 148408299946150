import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  DatePicker,
  DayOfWeek,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { PanelHeader } from 'common/components/PanelHeader';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalRequestInput,
  EntityDeleteInput,
  SupplierAddressInput,
  SupplierAddressUpdateTypeInput,
  SupplierApprovalRevokeInput,
  SupplierContactInput,
  SupplierContactUpdateTypeInput,
  SupplierInput,
  SupplierPatch,
  UploadStatusType,
} from 'common/types/globalTypes';
import { EntityType, PanelCommonProps } from 'common/types/utility';
import {
  dateConvertions,
  dateFormat,
  onFormatDate,
} from 'common/utils/dateFormats';
import { Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import { differenceBy, intersection, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  SupplierDelete,
  SupplierDeleteVariables,
} from '../__generated__/SupplierDelete';
import { Suppliers, SuppliersVariables } from '../list/__generated__/Suppliers';
import { ActionMenu } from './ActionMenu';
import { Addresses } from './Addresses';
import { ApprovalHistory } from './ApprovalHistory';
import { Banking } from './Banking';
import { BasicForm } from './BasicForm';
import { Contacts } from './Contacts';
import { ReviewsRatings } from './ReviewsRatings';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { SignatureNodes, SignatureView } from './Signatures';
import { validationSchema } from './SupplierValidation';
import { Supplier, SupplierVariables } from './__generated__/Supplier';
import { SupplierCommonData } from './__generated__/SupplierCommonData';
import {
  SupplierCreate,
  SupplierCreateVariables,
} from './__generated__/SupplierCreate';
import {
  SupplierUpdate,
  SupplierUpdateVariables,
} from './__generated__/SupplierUpdate';
import { SUPPLIER_INITIAL_VALUES } from './constants';
import { useStyles } from './index.styles';
import { AddressRowValues, ContactRowValues, SupplierValues } from './types';
import {
  SupplierApprovalCreate,
  SupplierApprovalCreateVariables,
} from 'common/graphql/__generated__/SupplierApprovalCreate';
import {
  SupplierApprovalRevoke,
  SupplierApprovalRevokeVariables,
} from 'common/graphql/__generated__/SupplierApprovalRevoke';
import {
  SupplierCompetitiveBidUpdate,
  SupplierCompetitiveBidUpdateVariables,
} from 'common/graphql/__generated__/SupplierCompetitiveBidUpdate';
import { UrgencyFolder } from './UrgencyFolder';
import { UrgencyToggle } from 'common/components/UrgencySelector/UrgencyToggle';
const SUPPLIERS = loader('../list/Suppliers.graphql');
const SUPPLIER_COMMON_DATA = loader('./SupplierCommonData.graphql');
const SUPPLIER_CREATE = loader('./SupplierCreate.graphql');
const SUPPLIER_UPDATE = loader('./SupplierUpdate.graphql');
const SUPPLIER_COMPETITIVE_BID_UPDATE = loader(
  '../../../../common/graphql/SupplierCompetitiveBidUpdate.graphql'
);
const SUPPLIER_DETAILS = loader('./Supplier.graphql');
const SUPPLIER_DELETE = loader('../SupplierDelete.graphql');
const SUPPLIER_APPROVAL_REVOKE = loader(
  '../../../../common/graphql/SupplierApprovalRevoke.graphql'
);
const SUPPLIER_APPROVAL_CREATE = loader(
  '../../../../common/graphql/SupplierApprovalCreate.graphql'
);
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
const CONFIRM_AMEND_DIALOG_TITLE = 'Are you sure?';
const CONFIRM_AMEND_DIALOG_SUBTEXT =
  'This will remove the Supplier from the approval cycle and require re-approval.';
const CONFIRM_REQUEST_DIALOG_TITLE =
  'Are you sure you want to request this supplier for approval?';
const CONFIRM_REQUEST_DIALOG_SUBTEXT =
  'Your supplier will be requested for approval.';
const FINALIZED_BY_DATE_TOOLTIP =
  'Automatically promotes to urgent notifications when not approved by this date';

export const SupplierDetails = () => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const { addToast, updateToast } = useToasts();
  const client = useApolloClient();
  const [requestComment, setRequestComment] = React.useState<string>();
  const [requiredDate, setRequiredDate] = React.useState<string | undefined>();
  const [urgencyLevel, setUrgencyLevel] = React.useState<number>(1);
  const [signatureData, setSignatureData] = useState<
    SignatureNodes[] | undefined
  >([]);
  const [hideConfirmAmendDialog, { toggle: toggleConfirmAmendDialog }] =
    useBoolean(true);
  const [hideConfirmRequestDialog, { toggle: toggleConfirmDialog }] =
    useBoolean(true);
  const [isSaveAnother, { setTrue: saveAnother, setFalse: dontSaveAnother }] =
    useBoolean(false);
  const { supplierId } = useParams<{ supplierId: string | undefined }>();
  const isNew = !supplierId;

  const {
    data: supplierDetailsData,
    loading: supplierDetailsLoading,
    refetch,
  } = useQuery<Supplier, SupplierVariables>(SUPPLIER_DETAILS, {
    variables: {
      id: supplierId!,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [createSupplier, { loading: addSupplierLoading }] = useMutation<
    SupplierCreate,
    SupplierCreateVariables
  >(SUPPLIER_CREATE, { errorPolicy: 'all' });

  const [updateSupplier, { loading: loadingSupplierUpdate }] = useMutation<
    SupplierUpdate,
    SupplierUpdateVariables
  >(SUPPLIER_UPDATE, { errorPolicy: 'all' });

  const [
    supplierCompetitiveBidUpdate,
    { loading: supplierCompetitiveBidUpdateLoading },
  ] = useMutation<
    SupplierCompetitiveBidUpdate,
    SupplierCompetitiveBidUpdateVariables
  >(SUPPLIER_COMPETITIVE_BID_UPDATE, { errorPolicy: 'all' });

  const { data: supplierCommonData, loading: supplierCommonDataLoading } =
    useQuery<SupplierCommonData>(SUPPLIER_COMMON_DATA, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  const [deleteSupplier, { loading: deleteSupplierLoading }] = useMutation<
    SupplierDelete,
    SupplierDeleteVariables
  >(SUPPLIER_DELETE, {
    errorPolicy: 'all',
  });

  const [supplierApprovalCreate, { loading: supplierApprovalLoading }] =
    useMutation<SupplierApprovalCreate, SupplierApprovalCreateVariables>(
      SUPPLIER_APPROVAL_CREATE,
      {
        errorPolicy: 'all',
        onCompleted: () => {
          setUrgencyLevel(1);
        },
      }
    );

  const [supplierApprovalRevoke, { loading: supplierApprovalRevokeLoading }] =
    useMutation<SupplierApprovalRevoke, SupplierApprovalRevokeVariables>(
      SUPPLIER_APPROVAL_REVOKE,
      { errorPolicy: 'all' }
    );

  const deleteRecord = async () => {
    const { errors } = await deleteSupplier({
      variables: {
        input: {
          entityDelete: [
            {
              id: supplierId!,
              rowTimestamp: supplierDetailsData?.supplier?._rowTimestamp!,
            },
          ],
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: SUPPLIERS,
        },
      ],
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
      history.replace('/account-management/suppliers');
    }
  };

  const panelHeading = isNew ? 'Create Supplier' : 'Edit Supplier';
  let panelSupplierStatus: string = '(Draft)';
  if (supplierDetailsData && !supplierDetailsLoading)
    panelSupplierStatus = `(${supplierDetailsData.supplier?.statusType
      ?.statusType!})`;
  else panelSupplierStatus = '';

  const _onRenderHeader = (dirty: boolean, isSubmitting: boolean) => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/account-management/suppliers');
        }}
      >
        <Stack
          grow
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">{panelHeading}</Text>
            {!isNew && (
              <>
                {supplierDetailsData?.supplier?._fullName && (
                  <Text
                    variant="xLarge"
                    className={commonStyles.colorThemePrimary}
                  >
                    {supplierDetailsData.supplier._fullName}
                  </Text>
                )}
                {supplierDetailsData?.supplier?.vendorNumber && (
                  <Text
                    variant="xLarge"
                    className={commonStyles.colorThemePrimary}
                  >
                    {supplierDetailsData?.supplier.vendorNumber}
                  </Text>
                )}
              </>
            )}
            <UnsavedIndicator
              visible={
                !isNew && !supplierDetailsLoading && dirty && !isSubmitting
              }
            />
          </Stack>
          <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 10 }}>
            {supplierDetailsData?.supplier?.statusType?.statusType ===
              'Pending' && (
              <UrgencyFolder supplierDetailsData={supplierDetailsData} />
            )}
            <ActionMessageModal
              visible={!isNew}
              entityType={EntityType.Supplier}
              disabled={!supplierDetailsData?.supplier?._isDeletable}
              onConfirm={deleteRecord}
            />
            <Text
              variant="mediumPlus"
              className={commonStyles.colorThemePrimary}
            >
              {panelSupplierStatus}
            </Text>
          </Stack>
        </Stack>
      </PanelHeader>
    );
  };

  const { _isUpdatable, _isUpdateSupplierOverride } = {
    ...supplierDetailsData?.supplier,
  };

  const handleSubmit = async (values: SupplierValues) => {
    const {
      supplierAddressesBySupplierId,
      supplierContactsBySupplierId,
      isRateRequired,
      isServiceRateAllowed,
      w9ExpirationDate,
      ...supplierFields
    } = values;
    if (isNew) {
      const { data, errors } = await createSupplier({
        variables: {
          input: {
            // supplier: supplierFields as SupplierInput,
            supplier: {
              ...supplierFields,
              w9ExpirationDate: w9ExpirationDate
                ? dateFormat(w9ExpirationDate)
                : null,
            } as SupplierInput,
            supplierAddresses: supplierAddressesBySupplierId?.map(
              ({ id, _rowTimestamp, ...addr }) => addr as SupplierAddressInput
            ),
            supplierContacts: supplierContactsBySupplierId
              ?.filter((contact) => contact.contactName !== null)
              ?.map(
                ({ id, _rowTimestamp, ...contact }) =>
                  contact as SupplierContactInput
              ),
          },
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery<Suppliers>({
            query: SUPPLIERS,
          });
          if (cacheData) {
            const newData: Suppliers = {
              suppliers: {
                ...cacheData?.suppliers!,
                pageInfo: cacheData?.suppliers?.pageInfo!,
                nodes: [
                  data?.supplierCreate?.supplier!,
                  ...cacheData?.suppliers?.nodes!,
                ],
              },
            };
            cache.writeQuery<Suppliers, SuppliersVariables>({
              query: SUPPLIERS,
              data: newData,
            });
          }
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        if (!isSaveAnother) {
          history.replace(
            `/account-management/suppliers/supplier/${data?.supplierCreate?.supplier?.id}`
          );
        }
        addToast('Supplier added successfully', {
          appearance: 'success',
        });
      }
    } else {
      const updatedSupplierFields = {
        ...supplierFields,
        w9ExpirationDate: w9ExpirationDate
          ? dateFormat(w9ExpirationDate)
          : null,
      };
      const supplierPatch: SupplierPatch = Object.entries(
        updatedSupplierFields
      ).reduce((res, [key, val]) => {
        if (
          val !== initialValues[key as keyof SupplierValues] &&
          key !== 'entityDocumentsByEntityId' &&
          key !== 'supplierHistoriesByEntityId' &&
          key !== 'entityTagsByEntityId' &&
          key !== 'userMessagesByEntityId' &&
          key !== 'notesByEntityId' &&
          key !== 'approvalHistoriesByEntityId' &&
          key !== 'approvalHistorySignaturesByEntityId' &&
          key !== 'statusType'
        ) {
          return { ...res, [key]: val };
        }
        return res;
      }, {});
      //Array for new Addresses
      const newAddresses =
        supplierAddressesBySupplierId &&
        (supplierAddressesBySupplierId
          ?.filter((addr) => !addr.id && addr.addressLine1 !== null)
          .map(
            ({ id, _rowTimestamp, ...addr }) => addr
          ) as SupplierAddressInput[]);

      //Array for deleted Addresses
      const deletedAddresses =
        initialValues.supplierAddressesBySupplierId &&
        (differenceBy(
          initialValues.supplierAddressesBySupplierId,
          supplierAddressesBySupplierId || [],
          'id'
        ).map((addr) => ({
          id: addr.id!,
          rowTimestamp: addr._rowTimestamp!,
        })) as EntityDeleteInput[]);
      //Array for updated Addresses
      const updatedAddresses =
        initialValues.supplierAddressesBySupplierId &&
        supplierAddressesBySupplierId &&
        intersection(
          initialValues.supplierAddressesBySupplierId.map((addr) => addr.id),
          supplierAddressesBySupplierId
            .filter((addr) => addr.id)
            .map((addr) => addr.id)
        ).reduce((arr, targetId) => {
          const initialAddress =
            initialValues.supplierAddressesBySupplierId!.find(
              (addr) => addr.id === targetId
            )!;
          const { id, _rowTimestamp, ...updatedAddress } =
            supplierAddressesBySupplierId!.find(
              (addr) => addr.id === targetId
            )!;
          const patch = Object.entries(updatedAddress).reduce(
            (res, [key, val]) => {
              if (val !== initialAddress[key as keyof AddressRowValues])
                return { ...res, [key]: val };
              return res;
            },
            {}
          );
          if (!isEmpty(patch))
            return [
              ...arr,
              {
                id,
                rowTimestamp: _rowTimestamp,
                supplierAddressPatch: patch,
              },
            ];
          return arr;
        }, [] as SupplierAddressUpdateTypeInput[]);

      //Array for new Contacts
      const newContacts =
        supplierContactsBySupplierId &&
        (supplierContactsBySupplierId
          ?.filter((contact) => !contact.id && contact.contactName !== null)
          .map(
            ({ id, _rowTimestamp, ...contact }) => contact
          ) as SupplierContactInput[]);
      //Array for deleted Contacts
      const deletedContacts =
        initialValues.supplierContactsBySupplierId &&
        (differenceBy(
          initialValues.supplierContactsBySupplierId,
          supplierContactsBySupplierId || [],
          'id'
        ).map((contact) => ({
          id: contact.id!,
          rowTimestamp: contact._rowTimestamp!,
        })) as EntityDeleteInput[]);
      //Array for updated Contact
      const updatedContacts =
        initialValues.supplierContactsBySupplierId &&
        supplierContactsBySupplierId &&
        intersection(
          initialValues.supplierContactsBySupplierId.map(
            (contact) => contact.id
          ),
          supplierContactsBySupplierId
            .filter((contact) => contact.id)
            .map((contact) => contact.id)
        ).reduce((arr, targetId) => {
          const initialContacts =
            initialValues.supplierContactsBySupplierId!.find(
              (contact) => contact.id === targetId
            )!;
          const { id, _rowTimestamp, ...updatedContact } =
            supplierContactsBySupplierId!.find(
              (contact) => contact.id === targetId
            )!;
          const patch = Object.entries(updatedContact).reduce(
            (res, [key, val]) => {
              if (val !== initialContacts[key as keyof ContactRowValues])
                return { ...res, [key]: val };
              return res;
            },
            {}
          );
          if (!isEmpty(patch))
            return [
              ...arr,
              {
                id,
                rowTimestamp: _rowTimestamp,
                supplierContactPatch: patch,
              },
            ];
          return arr;
        }, [] as SupplierContactUpdateTypeInput[]);

      const { errors } = await updateSupplier({
        variables: {
          input: {
            id: supplierId!,
            rowTimestamp: supplierDetailsData?.supplier?._rowTimestamp!,
            supplierPatch: !isEmpty(supplierPatch) ? supplierPatch : undefined,
            supplierAddressesCreate:
              newAddresses && newAddresses.length > 0
                ? newAddresses
                : undefined,
            supplierAddressesUpdate:
              updatedAddresses && updatedAddresses.length > 0
                ? updatedAddresses
                : undefined,
            supplierAddressesDelete:
              deletedAddresses && deletedAddresses.length > 0
                ? deletedAddresses
                : undefined,
            supplierContactsCreate:
              newContacts && newContacts.length > 0 ? newContacts : undefined,
            supplierContactsUpdate:
              updatedContacts && updatedContacts.length > 0
                ? updatedContacts
                : undefined,
            supplierContactsDelete:
              deletedContacts && deletedContacts.length > 0
                ? deletedContacts
                : undefined,
          },
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery<Supplier, SupplierVariables>({
            query: SUPPLIER_DETAILS,
            variables: {
              id: supplierId!,
            },
          });
          const updatedData: Supplier = {
            supplier: {
              ...cacheData?.supplier!,
              ...data?.supplierUpdate?.supplier!,
            },
          };
          cache.writeQuery<Supplier, SupplierVariables>({
            query: SUPPLIER_DETAILS,
            variables: {
              id: supplierId!,
            },
            data: updatedData!,
          });
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('Supplier edited successfully', {
          appearance: 'success',
        });
      }
    }
  };

  let initialValues: SupplierValues = SUPPLIER_INITIAL_VALUES;
  if (supplierDetailsData?.supplier && !isNew) {
    const supplierAddresses =
      supplierDetailsData.supplier.supplierAddressesBySupplierId.nodes.map(
        (ele) => {
          const { stateRegion, country, ...allAddressFields } = ele;
          return allAddressFields;
        }
      );

    initialValues = {
      ...supplierDetailsData.supplier!,
      w9ExpirationDate: supplierDetailsData.supplier.w9ExpirationDate
        ? dateFormat(
            dateConvertions(supplierDetailsData.supplier.w9ExpirationDate)
          )
        : null,
      isRateRequired:
        supplierDetailsData.supplier.vendorServiceType?.isRateRequired || null,
      isServiceRateAllowed:
        supplierDetailsData.supplier.vendorServiceType?.isServiceRateAllowed ||
        null,
      supplierAddressesBySupplierId: [...supplierAddresses],
      supplierContactsBySupplierId: [
        ...supplierDetailsData.supplier.supplierContactsBySupplierId.nodes,
      ],
    };
  }

  const isLoading =
    addSupplierLoading ||
    loadingSupplierUpdate ||
    deleteSupplierLoading ||
    supplierApprovalRevokeLoading ||
    supplierApprovalLoading ||
    supplierCompetitiveBidUpdateLoading;

  useEffect(() => {
    const { nodes } = {
      ...supplierDetailsData?.supplier?.approvalHistorySignaturesByEntityId,
    };
    if (!supplierDetailsLoading && nodes?.length) setSignatureData(nodes);
  }, [supplierDetailsData, supplierDetailsLoading]);

  const isEnabled = !isNew
    ? _isUpdateSupplierOverride
      ? true
      : _isUpdatable
        ? true
        : false
    : true;

  return (
    <>
      <Formik<SupplierValues>
        enableReinitialize
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, dirty, resetForm, errors }) => {
          return (
            <Form>
              <DraggablePanel
                {...PanelCommonProps}
                initialWidth={1200}
                minWidth={1200}
                isBlocking={isNew}
                onRenderHeader={() => _onRenderHeader(dirty, isSubmitting)}
                onRenderFooter={() => (
                  <FooterActionBar
                    createNewText="New Supplier"
                    addNewForm={() => {
                      history.replace('/account-management/suppliers/supplier');
                    }}
                    onCancel={() =>
                      history.replace('/account-management/suppliers')
                    }
                    disabled={
                      supplierCommonDataLoading || {
                        save: !dirty || Object.keys(errors).length > 0,
                        cancel: !dirty,
                      }
                    }
                    isSubmitting={isSubmitting}
                    isCreate={!supplierId ? true : false}
                    onSave={async () => {
                      dontSaveAnother();
                      await submitForm();
                    }}
                    onSaveAnother={async () => {
                      saveAnother();
                      await submitForm();
                      resetForm();
                    }}
                    isLoading={isLoading}
                    children={
                      supplierDetailsData && (
                        <Stack>
                          {!isNew &&
                            supplierDetailsData?.supplier
                              ?._isStagedApprovalRequest && (
                              <PrimaryButton
                                disabled={dirty}
                                className={styles.diabledButton}
                                text="Request Approval"
                                onClick={() => toggleConfirmDialog()}
                              />
                            )}
                          {!isNew &&
                            supplierDetailsData.supplier
                              ?._isApprovalRevocable && (
                              <PrimaryButton
                                disabled={dirty}
                                className={styles.diabledButton}
                                text="Amend Transaction"
                                onClick={() => toggleConfirmAmendDialog()}
                              />
                            )}
                        </Stack>
                      )
                    }
                  />
                )}
                isOpen
                onDismiss={() => {
                  history.replace('/account-management/suppliers');
                }}
                isLightDismiss
              >
                {!isNew && supplierDetailsLoading ? (
                  <Stack
                    className={styles.headerContainer}
                    tokens={{ childrenGap: 10 }}
                  >
                    <ShimmerView />
                  </Stack>
                ) : (
                  <>
                    {!isNew && supplierDetailsData?.supplier && (
                      <>
                        {supplierDetailsData?.supplier
                          ._isApprovalDocumentsRequired &&
                          supplierDetailsData?.supplier
                            ._requiredApprovalDocuments && (
                            <Stack tokens={{ padding: '0px 25px' }}>
                              <MessageBar messageBarType={MessageBarType.error}>
                                {
                                  supplierDetailsData?.supplier
                                    ?._requiredApprovalDocuments
                                }
                              </MessageBar>
                            </Stack>
                          )}
                        {supplierDetailsData.supplier && (
                          <ActionMenu
                            supplierDetails={supplierDetailsData.supplier}
                            secureRowLevels={
                              supplierCommonData?.secureRowLevels
                            }
                            onUpload={async (
                              fileSelected,
                              document,
                              toastId
                            ) => {
                              const observer = client.subscribe({
                                query: DOCUMENT_UPLOAD_STATUS,
                                variables: {
                                  documentId:
                                    document.document._documentFileId!,
                                },
                              });

                              const subscription = observer.subscribe(
                                (data) => {
                                  const subscribedData =
                                    data.data as OnDocumentUploadStatus;

                                  const { status, document } = {
                                    ...subscribedData.documentUploadStatus,
                                  };

                                  if (!document) {
                                    if (
                                      status.type ===
                                      UploadStatusType.VALIDATING
                                    ) {
                                      updateToast(toastId!, {
                                        content: status.message
                                          ? `Validating files ${fileSelected.name} - ${status.message}`
                                          : `Validating files ${fileSelected.name}`,
                                        appearance: 'info',
                                        autoDismiss: false,
                                      });
                                    } else if (
                                      status.type ===
                                      UploadStatusType.EXTRACTING
                                    ) {
                                      updateToast(toastId!, {
                                        content: status.message
                                          ? `Extracting data from ${fileSelected.name} - ${status.message}`
                                          : `Extracting data from ${fileSelected.name}`,
                                        appearance: 'info',
                                        autoDismiss: false,
                                      });
                                    } else if (
                                      status.type === UploadStatusType.FAILURE
                                    ) {
                                      subscription.unsubscribe();
                                      updateToast(toastId!, {
                                        content: status.message
                                          ? `Upload of ${fileSelected.name} failed - ${status.message}`
                                          : `Upload of ${fileSelected.name} failed`,
                                        appearance: 'error',
                                        autoDismiss: true,
                                      });
                                    } else if (
                                      status.type === UploadStatusType.WARNING
                                    ) {
                                      subscription.unsubscribe();
                                      updateToast(toastId!, {
                                        content: status.message
                                          ? `Warning for file ${fileSelected.name}: ${status.message}`
                                          : `Warning for file ${fileSelected.name}`,
                                        appearance: 'warning',
                                        autoDismiss: true,
                                      });
                                    }
                                  } else {
                                    subscription.unsubscribe();
                                    updateToast(toastId!, {
                                      content: status.message
                                        ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                                        : `Successfully uploaded ${fileSelected.name}`,
                                      appearance: 'success',
                                      autoDismiss: true,
                                    });

                                    refetch({ id: supplierId });
                                  }
                                }
                              );
                            }}
                            onRefetch={() => {
                              refetch({ id: supplierId });
                            }}
                          />
                        )}

                        <Separator />
                        <ReviewsRatings
                          supplier={supplierDetailsData?.supplier}
                          countTotal={
                            supplierDetailsData.supplier.supplierRatingResults
                              ?.countTotal
                          }
                        />
                        <Separator />
                      </>
                    )}
                    <BasicForm
                      supplierAvailableDocumentTypes={
                        supplierCommonData?.availableSupplierDocumentPools
                          ?.nodes || []
                      }
                      paymentTermTypes={
                        supplierCommonData?.paymentTermTypes?.nodes || []
                      }
                      environmentalMetrics={
                        supplierCommonData?.environmentalMetrics?.nodes || []
                      }
                      serviceTypes={
                        supplierCommonData?.vendorServiceTypes?.nodes || []
                      }
                      isUpdatable={supplierDetailsData?.supplier?._isUpdatable}
                      supplier={supplierDetailsData?.supplier}
                      isNew={isNew}
                    />
                    <Separator />
                    <Addresses
                      isEdit={!isNew}
                      countries={supplierCommonData?.countries?.nodes || []}
                      // isUpdatable={supplierDetailsData?.supplier?._isUpdatable}
                      isUpdatable={isEnabled}
                    />
                    <Separator />
                    <Contacts
                      isEdit={false}
                      // isUpdatable={supplierDetailsData?.supplier?._isUpdatable}
                      isUpdatable={isEnabled}
                    />
                    <Separator />
                    <Banking
                      isEdit={!isNew}
                      isBankAccountVerified={
                        supplierDetailsData?.supplier?._isBankAccountVerified
                      }
                      // isUpdatable={supplierDetailsData?.supplier?._isUpdatable}
                      isUpdatable={isEnabled}
                    />
                    {supplierDetailsData?.supplier
                      ?.approvalHistorySignaturesByEntityId.nodes.length! > 0 &&
                      !isNew && (
                        <Stack>
                          <Separator />
                          <Stack
                            className={styles.tagsContainer}
                            tokens={{ childrenGap: 20 }}
                          >
                            <Text variant="xLarge">Signatures</Text>
                            <SignatureView signatureData={signatureData!} />
                          </Stack>
                        </Stack>
                      )}
                    {supplierDetailsData?.supplier &&
                      supplierDetailsData?.supplier?.approvalHistoriesByEntityId
                        .nodes.length! > 0 && (
                        <Stack>
                          <Separator />
                          <Stack
                            className={styles.tagsContainer}
                            tokens={{ childrenGap: 20 }}
                          >
                            <Text variant="xLarge">Approval History</Text>
                            <Text
                              variant="medium"
                              className={styles.requestedByText}
                            >
                              Requested By:
                            </Text>
                            <ApprovalHistory
                              data={
                                supplierDetailsData.supplier
                                  .approvalHistoriesByEntityId
                              }
                            />
                          </Stack>
                        </Stack>
                      )}
                  </>
                )}

                <Prompt
                  when={dirty && !isSubmitting}
                  message="Are you sure you want to leave your changes unsaved?"
                />
                <ConfirmDialog
                  hidden={hideConfirmRequestDialog}
                  title={CONFIRM_REQUEST_DIALOG_TITLE}
                  subText={CONFIRM_REQUEST_DIALOG_SUBTEXT}
                  onDismiss={toggleConfirmDialog}
                  minWidth={500}
                  onConfirm={async () => {
                    toggleConfirmDialog();
                    const inputVariables: ApprovalRequestInput = {
                      entityId: supplierId!,
                      rowTimestamp:
                        supplierDetailsData?.supplier?._rowTimestamp!,
                      comments: requestComment,
                    };
                    if (requiredDate)
                      inputVariables.requiredDate = requiredDate;
                    const { errors } = await supplierApprovalCreate({
                      variables: {
                        input: {
                          entityApproval: [inputVariables],
                          urgencyLevel: urgencyLevel,
                        },
                      },
                      awaitRefetchQueries: true,
                      refetchQueries: [
                        {
                          query: SUPPLIER_DETAILS,
                          variables: {
                            id: supplierId!,
                          },
                        },
                      ],
                    });

                    if (errors?.length)
                      addToast(errors[0].message, {
                        appearance: 'error',
                      });
                    else {
                      setRequestComment('');
                      setRequiredDate('');
                      addToast('Request sent for approval', {
                        appearance: 'success',
                      });
                    }
                  }}
                >
                  <Stack tokens={{ childrenGap: 10 }}>
                    <TextField
                      className={styles.marginT10}
                      multiline
                      rows={3}
                      value={requestComment}
                      placeholder="Please write your comment here (optional)"
                      resizable={false}
                      onChange={(_event, value) =>
                        setRequestComment(value || '')
                      }
                    />
                    <TooltipHost content={FINALIZED_BY_DATE_TOOLTIP}>
                      <DatePicker
                        minDate={new Date()}
                        firstDayOfWeek={DayOfWeek.Sunday}
                        placeholder="Finalized by date (optional)"
                        ariaLabel="Date"
                        formatDate={onFormatDate}
                        showWeekNumbers
                        firstWeekOfYear={1}
                        showMonthPickerAsOverlay
                        showGoToToday={false}
                        onSelectDate={(date) =>
                          setRequiredDate(dateFormat(date!.toString()))
                        }
                      />
                    </TooltipHost>

                    <Stack tokens={{ padding: '6px 0px 0px 0px' }}>
                      <UrgencyToggle
                        onToggle={(data) => {
                          setUrgencyLevel(data ? 0 : 1);
                        }}
                      />
                    </Stack>
                  </Stack>
                </ConfirmDialog>
                <ConfirmDialog
                  isAmendButton
                  hidden={hideConfirmAmendDialog}
                  title={CONFIRM_AMEND_DIALOG_TITLE}
                  subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
                  onDismiss={toggleConfirmAmendDialog}
                  onConfirm={async () => {
                    toggleConfirmAmendDialog();
                    const inputVariables: SupplierApprovalRevokeInput = {
                      entityId: supplierId!,
                      rowTimestamp:
                        supplierDetailsData?.supplier?._rowTimestamp!,
                    };
                    const { errors } = await supplierApprovalRevoke({
                      variables: {
                        input: inputVariables,
                      },
                      awaitRefetchQueries: true,
                      refetchQueries: [
                        {
                          query: SUPPLIER_DETAILS,
                          variables: {
                            id: supplierId!,
                          },
                        },
                      ],
                    });

                    if (errors?.length)
                      addToast(errors[0].message, {
                        appearance: 'error',
                      });
                    else
                      addToast('Approval amended successfully', {
                        appearance: 'success',
                      });
                  }}
                />
              </DraggablePanel>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
