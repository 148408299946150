import { useMutation } from '@apollo/client';
import { IconButton, Separator, Stack, Text } from '@fluentui/react';
import {
  DeleteNote,
  DeleteNoteVariables,
} from 'common/graphql/__generated__/DeleteNote';
import { NotesEntityFields } from 'common/graphql/__generated__/NotesEntityFields';
import { useCommonStyles } from 'common/styles';
import { ApprovalState } from 'common/types/globalTypes';
import { getGlobalDateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { NoteUpdateForm } from '../Update';
const DELETE_NOTE = loader('../../../../common/graphql/DeleteNote.graphql');

interface ListItemProps {
  item: NotesEntityFields;
  index: number;
  totalNotes: number;
  isDirty?: boolean;
  approvalStatus?: ApprovalState | null;
  listRefreshRequest?: () => void;
}
export const ListItem: React.FC<ListItemProps> = ({
  item,
  index,
  totalNotes,
  approvalStatus,
  listRefreshRequest,
}) => {
  const { addToast } = useToasts();
  const commonStyles = useCommonStyles();
  const [isEdit, setEdit] = useState<boolean>(false);

  const [noteDelete, { loading: deleteInProgress }] = useMutation<
    DeleteNote,
    DeleteNoteVariables
  >(DELETE_NOTE, {
    errorPolicy: 'all',
  });

  const onNoteDelete = async () => {
    if (!deleteInProgress) {
      const { errors } = await noteDelete({
        variables: {
          input: {
            entityDelete: [
              {
                id: item.id,
                rowTimestamp: item._rowTimestamp!,
              },
            ],
          },
        },
        update(cache) {
          cache.evict({ id: `Note:${item.id}` });
          cache.gc();
        },
      });

      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Note has been deleted', { appearance: 'success' });
      }
    }
  };

  return (
    <Stack>
      {isEdit ? (
        <NoteUpdateForm
          item={item}
          onCancel={() => {
            setEdit(false);
          }}
          listRefreshRequest={listRefreshRequest}
        />
      ) : (
        <>
          <Stack
            horizontal
            horizontalAlign="space-between"
            key={index.toString()}
            tokens={{ padding: '0px 10px' }}
          >
            <Stack tokens={{ childrenGap: 10 }}>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Text className={commonStyles.semibold}>
                  {item._createdByUserName}
                </Text>
                <Text>
                  {item?._createdDate &&
                    getGlobalDateFormat(item?._createdDate)}
                </Text>
              </Stack>
              <Text>{item.noteComment}</Text>
            </Stack>

            {approvalStatus === ApprovalState.PENDING && (
              <Stack
                horizontal
                tokens={{ childrenGap: 10 }}
                horizontalAlign="center"
              >
                <IconButton
                  disabled={!item._isUpdatable}
                  onClick={() => {
                    setEdit(true);
                  }}
                  iconProps={{ iconName: 'Edit' }}
                />

                <IconButton
                  disabled={deleteInProgress || !item._isDeletable}
                  onClick={onNoteDelete}
                  iconProps={{ iconName: 'delete' }}
                />
              </Stack>
            )}
          </Stack>
        </>
      )}
      <Stack>{index === totalNotes - 1 ? null : <Separator />}</Stack>
    </Stack>
  );
};
