import { AccountingViewRowValues, TransactionSigningValues } from './types';

export const ACCOUNTING_INITIAL_VALUES: AccountingViewRowValues = {
  _createdDate: null,
  _isDeletable: null,
  _isUpdatable: null,
  _rowTimestamp: null,
  accountReference: null,
  additionalDescription: null,
  businessUnitId: null,
  departmentId: null,
  distributionAmount: null,
  productionIncentiveId: null,
  projectReference: null,
  purchaseOrderReference: null,
  statusTypeId: null,
  setReference: null,
  tax1099T4TypeId: null,
  referenceCode1: null,
  referenceCode2: null,
  referenceCode3: null,
  referenceCode4: null,
  referenceCode5: null,
  _ff1Name: null,
  _ff2Name: null,
  _ff3Name: null,
  _ff4Name: null,
  statusExplanation: null,
  poEntityDocumentId: null,
  isPoCharge: null,
  documentFileDistributionId: null,
  id: null,
  referenceCode6: null,
  referenceCode7: null,
};

export const INVOICE_INITIAL_VALUES: TransactionSigningValues = {
  _documentPackageId: null,
  _rowTimestamp: null,
  _urgencyLevel:null,
  approvalTriggerDate: null,
  businessUnitId: null,
  corporatePeriodId: null,
  transactionTypeId: null,
  controlTotalAmount: null,
  currencyId: null,
  currency: {
    id: 0,
    isoCode: null,
  },
  departmentId: null,
  description: null,
  invoiceDate: null,
  invoiceNumber: null,
  vendorReference: null,
  vendorId: null,
  _isDeletable: null,
  isDraft: null,
  invoiceDistributions: [{ ...ACCOUNTING_INITIAL_VALUES }],
  transactionType: {
    transactionType: null,
    layoutType: null,
  },
  reimburseAccountReference: null,
  reimburseAmount: null,
  bankAccountNumber: null,
  bankIbanCode: null,
  bankName: null,
  bankRoutingNumber: null,
  bankSortCode: null,
  bankSwiftCode: null,
  bankAccountName: null,
  layoutType: null,
  cardHolderId: null,
  statusHintOverride: null,
  statusHintOverrideId: null,
  entityDocuments: [],
};
