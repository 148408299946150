import { APPROVAL_TYPE_VALUES } from 'settings/account/userSetup/view/constant';
import { ChartTypeRowValues, UserTemplatevalues } from './types';

export const CHART_TYPE_INITIAL_VALUES: ChartTypeRowValues = {
  chartTypeId: null,
  chartPosition: null,
  isActive: null,
  id:null,
  _rowTimestamp:null
};

export const USER_TEMPLATE_INITIAL_VALUES: UserTemplatevalues = {
  name: null,
  description: null,
  roleId: null,
  role: null,
  userOccupationTitleId: null,
  userOccupationTitle: null,
  departmentId: null,
  department: null,
  departmentGroupId: null,
  departmentGroup: null,
  dataAccessPolicyId: null,
  dataAccessPolicy: null,
  userGroupId: null,
  userGroup: null,
  accessGroupId: null,
  accessGroup: null,
  tagGroupId: null,
  tagGroup: null,
  communicationGroupId: null,
  communicationGroup: null,
  userRankTypeId: null,
  userRank: null,
  secureRowLevelId: null,
  secureRowLevel: null,
  userDefaultCompany: null,
  userDefaultLocation: null,
  isUserPrimaryCurrencyDefault: null,
  isUserDepartmentDefault: null,
  isUserPrimaryBusinessUnitDefault: null,
  isTemplateControlled: null,
  isDepartmentUserGroupRequired: null,
  departmentOccupationApprovals: null,
  departmentOccupationCharts: null,
  userTemplateApprovals: [APPROVAL_TYPE_VALUES],
  userTemplateCharts: [CHART_TYPE_INITIAL_VALUES],
};
