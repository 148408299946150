import React from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';

import {
  CreateMessageData,
  CreateNewMessage,
} from 'common/components/CreateNewMessage';
import { useToasts } from 'react-toast-notifications';
import { PivotKeys } from 'common/components/MessagesSections';
import {
  MessageInvoiceSigningCreate,
  MessageInvoiceSigningCreateVariables,
} from 'common/graphql/__generated__/MessageInvoiceSigningCreate';
import { useFormContext } from 'react-hook-form';
import { TransactionSigningValues } from 'ap/signing/transactionSigning/view/types';
const ADD_NEW_MESSAGE = loader(
  '../../../../common/graphql/MessageInvoiceSigningCreate.graphql'
);
const GET_INVOICE_MESSAGE = loader(
  '../../transactionSigning/view/FormView/ActionMenu/actionMenuContents/Messages/TransactionSigningMessage.graphql'
);
interface NewMessageProps {
  invoiceID: string;
  selectedKey: string;
  onCreateMessage: () => void;
}

export const NewMessage: React.FC<NewMessageProps> = ({
  invoiceID,
  selectedKey,
  onCreateMessage,
}) => {
  const { addToast } = useToasts();
  const {
    formState: { isDirty },
  } = useFormContext<TransactionSigningValues>();
  const [createMessage, { loading }] = useMutation<
    MessageInvoiceSigningCreate,
    MessageInvoiceSigningCreateVariables
  >(ADD_NEW_MESSAGE);

  const messageFilter =
    selectedKey === PivotKeys.sent
      ? { _isOutgoingMessage: { equalTo: true } }
      : { _isIncomingMessage: { equalTo: true } };

  const onSubmit = async (formData: CreateMessageData) => {
    const { messageComment, reminderDates, isResponseRequired, receivers } = {
      ...formData,
    };
    const { communicationChannel, department, user } = { ...receivers };
    const { errors } = await createMessage({
      variables: {
        input: {
          entityId: invoiceID,
          message: { messageComment, reminderDates, isResponseRequired },
          communicationChannel,
          department,
          user,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_INVOICE_MESSAGE,
          variables: {
            id: invoiceID,
            first: 10,
            filter: messageFilter,
          },
        },
      ],
    });

    if (!errors) {
      onCreateMessage();
      addToast('Message sent successfully.', {
        appearance: 'success',
      });
    } else
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
  };

  return <CreateNewMessage onSubmit={onSubmit} loading={loading} dirty={isDirty} />;
};
