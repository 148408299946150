import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { invoiceDateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { differenceBy, intersection, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  EntityDeleteInput,
  InvoiceDistributionInput,
  InvoiceDistributionPatch,
  InvoiceDistributionUpdateTypeInput,
  InvoiceInput,
  InvoicePatch,
} from '../../../../common/types/globalTypes';
import { InvoicesSigningSearch_invoiceSigningSearch } from '../../__generated__/InvoicesSigningSearch';
import { FileListArray, FormView } from './FormView';
import {
  InvoiceDetails,
  InvoiceDetailsVariables,
} from './__generated__/InvoiceDetails';
import {
  InvoiceSigningCreateApply,
  InvoiceSigningCreateApplyVariables,
} from './__generated__/InvoiceSigningCreateApply';
import {
  InvoiceSigningUpdate,
  InvoiceSigningUpdateVariables,
} from './__generated__/InvoiceSigningUpdate';
import { AccountingViewRowValues, TransactionSigningValues } from './types';
import { getDefaultValues } from './utils';
import { validationSchema } from './validation';
import { InvoiceSigningSearchFilterTotals_invoiceSigningSearchFilterTotals } from 'ap/signing/__generated__/InvoiceSigningSearchFilterTotals';

const GET_INVOICE_DETAILS = loader('./InvoiceDetails.graphql');
const UPDATE_INVOICE_SIGNING = loader('./InvoiceSigningUpdate.graphql');
const CREATE_TRANSACTION = loader('./InvoiceSigningCreateApply.graphql');

export const TransactionSigningView: React.FC = () => {
  const { transactionSigningId } = useParams<{
    transactionSigningId: string | undefined;
  }>();
  const isNew = !transactionSigningId;
  const history = useHistory();
  const { addToast } = useToasts();
  const saveAnotherTransaction = useRef<boolean>(false);
  const fileListRef = useRef<FileListArray[] | undefined>();
  const {
    data: invoiceDetailsData,
    loading: invoiceDetailsLoading,
    refetch,
  } = useQuery<InvoiceDetails, InvoiceDetailsVariables>(GET_INVOICE_DETAILS, {
    variables: {
      id: transactionSigningId!,
    },
    skip: !transactionSigningId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [createTransaction, { loading: createTransactionLoading }] =
    useMutation<InvoiceSigningCreateApply, InvoiceSigningCreateApplyVariables>(
      CREATE_TRANSACTION,
      { errorPolicy: 'all' }
    );

  const [updateTransaction, { loading: updateTransactionLoading }] =
    useMutation<InvoiceSigningUpdate, InvoiceSigningUpdateVariables>(
      UPDATE_INVOICE_SIGNING,
      { errorPolicy: 'all' }
    );

  const formMethods = useForm<TransactionSigningValues>({
    // mode: 'onChange',
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const { handleSubmit, trigger, reset } = { ...formMethods };

  const onHandleSubmit = async (values: TransactionSigningValues) => {
    const initialValues = getDefaultValues({ isNew, invoiceDetailsData });
    const {
      controlTotalAmount,
      description,
      invoiceDate,
      reimburseAccountReference,
      reimburseAmount,
      invoiceNumber,
      corporatePeriodId,
      vendorReference,
      departmentId,
      statusHintOverride,
      statusHintOverrideId,
      transactionTypeId,
      currencyId,
      bankAccountNumber,
      bankIbanCode,
      bankName,
      bankRoutingNumber,
      bankSortCode,
      bankSwiftCode,
      bankAccountName,
      cardHolderId,
      invoiceDistributions,
      businessUnitId,
      entityDocuments,
      poInvoiceSchedules,
    } = values;

    const invoiceValues = {
      controlTotalAmount: !!controlTotalAmount
        ? parseFloat(controlTotalAmount).toFixed(2).toString()
        : '0.00',
      description,
      invoiceDate: invoiceDate ? invoiceDateFormat(invoiceDate) : null,
      reimburseAccountReference,
      reimburseAmount,
      invoiceNumber,
      corporatePeriodId,
      vendorReference,
      departmentId,
      statusHintOverride,
      statusHintOverrideId,
      transactionTypeId: parseInt(transactionTypeId?.toString() || '0'),
      currencyId: parseInt(currencyId?.toString() || '0'),
      bankAccountNumber,
      bankIbanCode,
      bankName,
      bankRoutingNumber,
      bankSortCode,
      bankSwiftCode,
      bankAccountName,
      // cardAccountId: cardHolderId,
      cardHolderId,
      businessUnitId,
    };

    if (isNew) {
      const { data, errors } = await createTransaction({
        variables: {
          input: {
            invoice: invoiceValues as InvoiceInput,
            invoiceDistributions: invoiceDistributions
              ?.filter((ele) => Object.values(ele).some((item) => item))
              ?.map(
                ({
                  id,
                  _rowTimestamp,
                  _createdDate,
                  _isDeletable,
                  _isUpdatable,
                  tax1099T4TypeId,
                  _ff1Name,
                  _ff2Name,
                  _ff3Name,
                  _ff4Name,
                  poEntityDocumentId,
                  isPoCharge,
                  ...invoice
                }) => invoice as InvoiceDistributionInput
              ),
            entityDocuments,
            poInvoiceSchedules,
          },
        },
        update: (cache, { data }) => {
          try {
            cache.modify({
              fields: {
                invoiceSigningSearchFilterTotals(
                  existing: InvoiceSigningSearchFilterTotals_invoiceSigningSearchFilterTotals
                ) {
                  if (existing?.totalCount1 !== 0) {
                    const {
                      controlTotalAmount,
                      _baseCurrencyAmount,
                      _spotCurrencyAmount,
                    } = {
                      ...data?.invoiceSigningCreateApply?.invoice,
                    };
                    const controlTotalAmountSum =
                      Number(existing?.totalAmount1) +
                        Number(controlTotalAmount) ?? 0;
                    const baseCurrencyAmountSum =
                      Number(existing?.totalAmount2) +
                        Number(_baseCurrencyAmount) ?? 0;
                    const spotCurrencyAmountSum =
                      Number(existing?.totalAmount3) +
                        Number(_spotCurrencyAmount) ?? 0;
                    return {
                      totalAmount1: controlTotalAmountSum?.toString(),
                      totalAmount2: baseCurrencyAmountSum?.toString(),
                      totalAmount3: spotCurrencyAmountSum?.toString(),
                      totalCount1: existing?.totalCount1 || 0 + 1,
                    };
                  } else {
                    return {
                      ...existing,
                    };
                  }
                },
                invoiceSigningSearch(
                  existing: InvoicesSigningSearch_invoiceSigningSearch
                ) {
                  const { invoice } = { ...data?.invoiceSigningCreateApply };
                  const { pageInfo } = {
                    ...existing,
                  };

                  return {
                    ...existing,
                    nodes: [invoice, ...existing.nodes],
                    pageInfo: pageInfo!,
                  };
                },
              },
            });
          } catch (error) {}
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      }
      if (data?.invoiceSigningCreateApply?.invoice) {
        if (!saveAnotherTransaction.current) {
          history.replace(
            `/signing/transaction/${data.invoiceSigningCreateApply?.invoice?.id}`
          );
        }
        addToast('Transaction added successfully', {
          appearance: 'success',
        });
        if (!fileListRef.current?.length) {
          addToast('No documents attached while creating the transaction', {
            appearance: 'warning',
          });
        }
        // setFileListArray([]);
        // setInitialDocumentMetaData(undefined);
      }
    } else {
      const invoicePatch: InvoicePatch = Object.entries(invoiceValues).reduce(
        (res, [key, val]) => {
          if (val !== initialValues[key as keyof TransactionSigningValues]) {
            return { ...res, [key]: val };
          }
          return res;
        },
        {}
      );

      const newInvoiceDistributionRows =
        invoiceDistributions &&
        (invoiceDistributions
          ?.filter(
            (addr) => !addr.id && !Object.values(addr).every((x) => x === null)
          )
          .map(
            ({
              id,
              _rowTimestamp,
              _createdDate,
              _isDeletable,
              _isUpdatable,
              _ff1Name,
              _ff2Name,
              _ff3Name,
              _ff4Name,
              ...addr
            }) => addr
          ) as InvoiceDistributionPatch[]);

      const deletedInvoiceDistributionRows =
        initialValues.invoiceDistributions &&
        (differenceBy(
          initialValues.invoiceDistributions,
          invoiceDistributions || [],
          'id'
        ).map((addr) => ({
          id: addr.id!,
          rowTimestamp: addr._rowTimestamp!,
        })) as EntityDeleteInput[]);

      const updatedInvoiceDistributions =
        initialValues.invoiceDistributions &&
        invoiceDistributions &&
        intersection(
          initialValues.invoiceDistributions.map((addr) => addr.id),
          invoiceDistributions.filter((addr) => addr.id).map((addr) => addr.id)
        ).reduce((arr, targetId) => {
          const initialInvoiceDistributions =
            initialValues.invoiceDistributions!.find(
              (addr) => addr.id === targetId
            )!;
          const { id, _rowTimestamp, ...updatedAddress } =
            invoiceDistributions!.find((addr) => addr.id === targetId)!;
          const patch = Object.entries(updatedAddress).reduce(
            (res, [key, val]) => {
              if (
                val !==
                initialInvoiceDistributions[
                  key as keyof AccountingViewRowValues
                ]
              )
                return { ...res, [key]: val };
              return res;
            },
            {}
          );
          if (!isEmpty(patch))
            return [
              ...arr,
              {
                id,
                rowTimestamp: _rowTimestamp,
                invoiceDistributionPatch: patch,
              },
            ];
          return arr;
        }, [] as InvoiceDistributionUpdateTypeInput[]);
      if (transactionSigningId && invoiceDetailsData?.invoice?._rowTimestamp) {
        const { errors } = await updateTransaction({
          variables: {
            input: {
              id: transactionSigningId,
              rowTimestamp: invoiceDetailsData.invoice._rowTimestamp,
              invoiceDistributionsUpdate:
                updatedInvoiceDistributions &&
                updatedInvoiceDistributions.length > 0
                  ? updatedInvoiceDistributions
                  : undefined,
              invoiceDistributionsCreate:
                newInvoiceDistributionRows &&
                newInvoiceDistributionRows.length > 0
                  ? newInvoiceDistributionRows
                  : undefined,
              invoiceDistributionsDelete:
                deletedInvoiceDistributionRows &&
                deletedInvoiceDistributionRows.length > 0
                  ? deletedInvoiceDistributionRows
                  : undefined,
              invoicePatch: !isEmpty(invoicePatch) ? invoicePatch : undefined,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_INVOICE_DETAILS,
              variables: {
                id: transactionSigningId,
              },
            },
          ],
        });
        if (errors?.length) {
          addToast(errors[0].message, {
            appearance: 'error',
          });
        } else {
          addToast('Transaction edited successfully', {
            appearance: 'success',
          });
        }
      }
    }
  };
  const triggerCallBack = useCallback(trigger, []);

  useEffect(() => {
    triggerCallBack();
  }, [triggerCallBack]);

  useEffect(() => {
    const defaultValues = getDefaultValues({ isNew, invoiceDetailsData });

    reset(defaultValues);
  }, [invoiceDetailsData, isNew, reset]);

  return (
    <>
      <FormProvider {...formMethods}>
        <FormView
          onSave={handleSubmit(onHandleSubmit)}
          isSaveAnother={(value) => (saveAnotherTransaction.current = value)}
          isNew={isNew}
          isCreating={createTransactionLoading}
          isUpdating={updateTransactionLoading}
          invoiceDetailsData={invoiceDetailsData}
          dataLoading={invoiceDetailsLoading}
          refetch={refetch}
          onFileListArrayChange={(files) => (fileListRef.current = files)}
        />
      </FormProvider>
    </>
  );
};
