import { APPROVAL_TYPE_VALUES } from 'settings/account/userSetup/view/constant';
import { UserTemplate } from './__generated__/UserTemplate';
import { USER_TEMPLATE_INITIAL_VALUES } from './constants';
import { ChartTypeRowValues, UserTemplatevalues } from './types';
import {
  ApprovalTypeRowValues,
  UserGrants,
} from 'settings/account/userSetup/view/types';

export const getDefaultValues = (props?: UserTemplate) => {
  let defaultValues: UserTemplatevalues = USER_TEMPLATE_INITIAL_VALUES;
  if (props?.departmentOccupationTemplate) {
    const { nodes } = {
      ...props.departmentOccupationTemplate?.departmentOccupationApprovals,
    };
    const grantNodes = nodes?.map(
      ({ id, _rowTimestamp, approvalTypeId, ...item }) => {
        return {
          id,
          _rowTimestamp,
          approvalTypeId,
          grants: {
            ...item,
          } as UserGrants,
        } as ApprovalTypeRowValues;
      }
    );

    const { __typename, id, _isUpdatable, _rowTimestamp, ...values } = {
      ...props.departmentOccupationTemplate,
    };
    const { nodes: chartNodes } = {
      ...props.departmentOccupationTemplate?.departmentOccupationCharts,
    };
    const templateChartNodes = chartNodes?.map(({ chartType, ...item }) => {
      return {
        ...item,
      } as ChartTypeRowValues;
    });
    defaultValues = {
      ...values,
      userTemplateApprovals:
        nodes?.length! > 0
          ? [...grantNodes!, ...[APPROVAL_TYPE_VALUES]]
          : [APPROVAL_TYPE_VALUES],
      userTemplateCharts:
        chartNodes?.length! > 0 ? [...templateChartNodes!] : null,
    };
  }
  return defaultValues;
};
