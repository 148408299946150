import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
import useGetScreenWidth from 'common/hooks/useGetScreenWidth';

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

const colHeaderLeftAlignStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    marginLeft: 25,
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

const useColumns = () => {
  const { isSm, isLg } = useGetScreenWidth();

  const columns: ColumnData[] = [
    {
      key: 'urgencyLevel',
      name: '',
      fieldName: 'urgencyLevel',
      minWidth: 40,
      maxWidth: 40,
      styles: colHeaderLeftAlignStyle,
      ...commonColumnProps,
    },
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 260,
      maxWidth: 260,
      ...commonColumnProps,
    },
    {
      key: 'number',
      name: 'Number',
      fieldName: 'number',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
    },
    {
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      minWidth: 190,
      maxWidth: 190,
      ...commonColumnProps,
      isVisible: !isSm,
    },
    {
      key: 'date',
      name: 'Date',
      fieldName: 'date',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
      styles: rightAlignHeaderStyle,
    },
    {
      key: 'businessUnitName',
      name: 'Business Unit',
      fieldName: 'businessUnitName',
      minWidth: 90,
      maxWidth: 90,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'departmentName',
      name: 'Department',
      fieldName: 'departmentName',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'role',
      name: 'My Role',
      fieldName: 'role',
      minWidth: 90,
      maxWidth: 100,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'approvers',
      name: 'Approved By',
      fieldName: 'approvers',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'additionalApprovalInformation',
      name: 'Approval Hint',
      fieldName: 'additionalApprovalInformation',
      minWidth: 120,
      maxWidth: 120,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'amount',
      name: 'Amount',
      fieldName: 'amount',
      minWidth: 100,
      maxWidth: 100,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
    },
    {
      key: 'currencyData',
      name: 'Currency',
      fieldName: 'currencyData',
      minWidth: 70,
      maxWidth: 70,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'periodYear',
      name: 'Period',
      fieldName: 'periodYear',
      minWidth: 70,
      maxWidth: 70,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'requesterName',
      name: 'Requested By',
      fieldName: 'requesterName',
      minWidth: 100,
      maxWidth: 200,
      ...commonColumnProps,
      isVisible: false,
    },

    {
      key: 'requesterDate',
      name: 'Requested on',
      fieldName: 'requesterDate',
      minWidth: 120,
      maxWidth: 200,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'approvedDate',
      name: 'Approved on',
      fieldName: 'approvedDate',
      minWidth: 120,
      maxWidth: 200,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'rejectors',
      name: 'Denied By',
      fieldName: 'rejectors',
      minWidth: 100,
      maxWidth: 200,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'rejectedDate',
      name: 'Denied on',
      fieldName: 'rejectedDate',
      minWidth: 120,
      maxWidth: 200,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'amendedByName',
      name: 'Amended By',
      fieldName: 'amendedByName',
      minWidth: 100,
      maxWidth: 200,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'amendedDate',
      name: 'Amended on',
      fieldName: 'amendedDate',
      minWidth: 120,
      maxWidth: 200,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'phaseName',
      name: 'Project phase',
      fieldName: 'phaseName',
      minWidth: 120,
      maxWidth: 120,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'status',
      name: 'Status',
      fieldName: 'status',
      minWidth: 250,
      ...commonColumnProps,
      toggleVisibility: false,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];

  return { columns };
};

export { useColumns };
