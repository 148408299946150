import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { FilterArrayType } from 'common/components/Filters';
import { EntityDocumentsOrderBy } from 'common/types/globalTypes';

type OrderLookup = {
  [direction in OrderDirection]: EntityDocumentsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'documentType',
    {
      [OrderDirection.ASC]: [
        EntityDocumentsOrderBy.DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        EntityDocumentsOrderBy.DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_DESC,
      ],
    },
  ],
  [
    '_documentPoolName',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._DOCUMENT_POOL_NAME_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._DOCUMENT_POOL_NAME_DESC],
    },
  ],
  [
    'indexName',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_NAME_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.INDEX_NAME_DESC],
    },
  ],
  [
    'indexReferenceNumber',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_REFERENCE_NUMBER_ASC],
      [OrderDirection.DESC]: [
        EntityDocumentsOrderBy.INDEX_REFERENCE_NUMBER_DESC,
      ],
    },
  ],
  [
    'indexTransactionDate',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_TRANSACTION_DATE_ASC],
      [OrderDirection.DESC]: [
        EntityDocumentsOrderBy.INDEX_TRANSACTION_DATE_DESC,
      ],
    },
  ],
  [
    '_documentContents',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._DOCUMENT_CONTENTS_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._DOCUMENT_CONTENTS_DESC],
    },
  ],
  [
    'comment',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.COMMENT_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.COMMENT_DESC],
    },
  ],
  [
    'indexAmount',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_AMOUNT_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.INDEX_AMOUNT_DESC],
    },
  ],
  [
    'isoCode',
    {
      [OrderDirection.ASC]: [
        EntityDocumentsOrderBy.COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ISO_CODE_ASC,
      ],
      [OrderDirection.DESC]: [
        EntityDocumentsOrderBy.COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ISO_CODE_DESC,
      ],
    },
  ],
  [
    'fileReference',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.FILE_REFERENCE_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.FILE_REFERENCE_DESC],
    },
  ],
  [
    '_exportDate',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._SORT_EXPORT_DATE_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._SORT_EXPORT_DATE_DESC],
    },
  ],

  [
    '_createdDate',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._CREATED_DATE_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._CREATED_DATE_DESC],
    },
  ],
  [
    '_exportedByUser',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._EXPORTED_BY_USER_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._EXPORTED_BY_USER_DESC],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [EntityDocumentsOrderBy.PRIMARY_KEY_ASC];
  if (!sort) return orderByArray;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [...(orderBy ? orderBy : []), ...orderByArray];
};

export const toFilterVariable = (filterList: FilterArrayType[]) =>
  filterList
    .map((filter) => {
      switch (filter.filterKey) {
        case 'documentTypeId':
        case 'indexCurrencyId':
        case '_documentPoolId':
          return {
            [filter.filterKey]: {
              equalTo: filter.value,
            },
          };
        default:
          return undefined;
      }
    })
    .filter((i) => i);
