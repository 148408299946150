import { InvoiceDetails_invoice_entityEnvironmentalsByEntityId } from 'ap/signing/transactionSigning/view/__generated__/InvoiceDetails';
import { CarbonAccountingValues } from '../types';
import {
  CARBONACCOUNTING_INITIAL_VALUES,
  ENVIRONMENTAL_ITEMS_VALUES,
} from '../constant';
import _ from 'lodash';
import { dateConvertions } from 'common/utils/dateFormats';
import { EntityEnvironmentalItemsType } from '..';

interface DefaultValueProps {
  // isNew: boolean;
  invoice: InvoiceDetails_invoice_entityEnvironmentalsByEntityId | undefined;
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { invoice } = { ...props };

  const environmentalItemsArray: EntityEnvironmentalItemsType[] =
    invoice?.nodes[0]?.entityEnvironmentalItems?.nodes?.map((ele) => {
      const {
        __typename,
        _isDeletable,
        _isUpdatable,
        entityEnvironmentalId,
        _isIntegerFormat,
        _isFloatFormat,
        _isAmountFormat,
        _rowTimestamp,
        environmentalImpactType,
        ...environmentalItemFields
      } = { ...ele };
      return {
        ...environmentalItemFields!,
        isIntegerFormat: ele._isIntegerFormat,
        isFloatFormat: ele._isFloatFormat,
        isAmountFormat: ele._isAmountFormat,
      };
    }) || [];

  let defaultValues: CarbonAccountingValues = {
    ...CARBONACCOUNTING_INITIAL_VALUES,
  };
  if (!_.isEmpty(invoice?.nodes?.[0])) {
    defaultValues = {
      ...invoice?.nodes?.[0]!,
      startDate: invoice?.nodes[0].startDate
        ? dateConvertions(invoice?.nodes[0].startDate)
        : null,
      endDate: invoice?.nodes[0].endDate
        ? dateConvertions(invoice?.nodes[0].endDate)
        : null,
      entityEnvironmentalItems:
        environmentalItemsArray.length > 0
          ? [...environmentalItemsArray]
          : [ENVIRONMENTAL_ITEMS_VALUES],
    };
  }
  return defaultValues;
};
