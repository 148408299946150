import { TextField } from '@fluentui/react';
import React from 'react';
import { FilterArrayType, filterCombineType } from '.';

type TextSearchFilterProps = {
  placeholder?: string;
  filterKey: string;
  onOptionChange?: (data: FilterArrayType) => void;
  disabled?: boolean;
  selectedKey?: number | string | undefined;
  defaultFilter?: boolean;
  value?: string | undefined;
  combineType?:
    | filterCombineType.AND
    | filterCombineType.NOT
    | filterCombineType.OR;
};

export const TextSearchFilter: React.FC<TextSearchFilterProps> = ({
  placeholder = 'Select item',
  defaultFilter = false,
  onOptionChange,
  filterKey,
  disabled = false,
  value,
  combineType = filterCombineType.AND,
}) => {
  const label = defaultFilter ? placeholder : undefined;
  const placeholderContent = defaultFilter ? 'Search' : placeholder;
  return (
    <TextField
      disabled={disabled}
      placeholder={placeholderContent}
      label={label}
      value={value}
      onChange={(_event, value) => {
        onOptionChange?.({
          value: value || '',
          filterKey,
          label: value || '',
          combineType,
        });
      }}
    />
  );
};
