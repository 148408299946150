import {
  Callout,
  DirectionalHint,
  Icon,
  IconButton,
  Stack,
  Text,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { InvoiceTransactionTypes } from 'ap/signing/__generated__/InvoiceTransactionTypes';
import { Badge } from 'common/components/Badge';
import { CloseButton } from 'common/components/Buttons';
import { FormHookTextField } from 'common/components/FormHooksFields';
import { useCommonStyles } from 'common/styles';
import { ApprovalState } from 'common/types/globalTypes';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { InvoiceDetails } from '../../../__generated__/InvoiceDetails';
import { TransactionSigningValues } from '../../../types';
import { useStyles } from './index.styles';

interface OverrideApprovalHintProps {
  invoiceDetailsData?: InvoiceDetails;
  transactionTypeData?: InvoiceTransactionTypes;
}

export const OverrideApprovalHint: React.FC<OverrideApprovalHintProps> = ({
  transactionTypeData,
  invoiceDetailsData,
}) => {
  const { setValue, control } = useFormContext<TransactionSigningValues>();
  const watchStatusHintOverrideId = useWatch({
    control,
    name: 'statusHintOverrideId',
  });

  const buttonId = useId('OverrideApprovalHint');
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [visibility, { toggle, setFalse }] = useBoolean(false);
  const { statusTypes } = { ...transactionTypeData };
  const { statusType, additionalStatusType, additionalInformationStatus } = {
    ...invoiceDetailsData?.invoice,
  };
  const overrideSelected = statusTypes?.nodes.find(
    (data) => data.id === watchStatusHintOverrideId
  );

  const disableBasedOnApprovalState =
    statusType?.approvalState === ApprovalState.APPROVED ||
    statusType?.approvalState === ApprovalState.PENDING;

  const isAvailable =
    overrideSelected !== undefined || additionalInformationStatus !== null;

  if (!isAvailable && disableBasedOnApprovalState) return null;
  else
    return (
      <Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack id={buttonId}>
            <Badge visible={isAvailable}>
              <IconButton iconProps={{ iconName: 'info' }} onClick={toggle} />
            </Badge>
          </Stack>

          <Stack horizontal>
            {additionalStatusType?.statusType && (
              <Text variant="mediumPlus" className={commonStyles.colorThemeRed}>
                {additionalStatusType.statusType}
              </Text>
            )}

            {additionalInformationStatus && (
              <Text variant="mediumPlus" className={commonStyles.colorThemeRed}>
                {additionalStatusType?.statusType ? ', ' : ''}
                {additionalInformationStatus}
              </Text>
            )}
          </Stack>
        </Stack>

        {visibility && (
          <Callout
            className={styles.container}
            gapSpace={0}
            target={`#${buttonId}`}
            directionalHint={DirectionalHint.leftTopEdge}
            onDismiss={setFalse}
          >
            <Stack
              horizontal
              horizontalAlign="space-between"
              tokens={{ padding: 20 }}
              className={styles.container}
            >
              <Text variant={'xLarge'}>Override Approval Hint</Text>
              <CloseButton onClick={setFalse} />
            </Stack>

            <Stack tokens={{ padding: '0px 0px' }}>
              {statusTypes?.nodes.map((item, index) => {
                const isSelected = overrideSelected?.id === item.id;
                return (
                  <Stack
                    horizontal
                    verticalAlign="center"
                    onClick={() => {
                      if (!disableBasedOnApprovalState) {
                        const data = isSelected ? null : item.id;

                        setValue('statusHintOverrideId', data, {
                          shouldDirty: true,
                        });
                      }
                    }}
                    tokens={{ childrenGap: 10 }}
                    className={
                      isSelected
                        ? styles.itemSelected
                        : !disableBasedOnApprovalState
                        ? styles.itemHover
                        : styles.item
                    }
                    key={index}
                  >
                    {isSelected ? (
                      <Icon iconName="accept" />
                    ) : (
                      <Stack style={{ width: 17 }} />
                    )}
                    <Text>{item.statusType}</Text>
                  </Stack>
                );
              })}
            </Stack>
            <Stack tokens={{ padding: '20px 20px' }}>
              <FormHookTextField name="statusHintOverride" />
            </Stack>
          </Callout>
        )}
      </Stack>
    );
};
