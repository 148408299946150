import { useQuery } from '@apollo/client';
import {
  DayOfWeek,
  DefaultButton,
  DetailsList,
  DetailsRow,
  DirectionalHint,
  IColumn,
  IconButton,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles,
  IDropdownOption,
  IDropdownStyles,
  IRenderFunction,
  Link,
  SelectionMode,
  Separator,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { TransactionModal } from 'ap/paymentCycle/view/TransactionModal';
import { InvoiceRow } from 'ap/paymentTracking/StampedTransactions/list';
import clsx from 'clsx';
import {
  AmountTextField,
  FormikDropdown,
  FormikTextField,
} from 'common/components';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { DocumentList } from 'common/components/DocumentList';
import { AutoCompleteFormikTextField } from 'common/components/FormikFields/AutoCompleteFormikTextField';
import { FormikDatePicker } from 'common/components/FormikFields/DatePicker';
import {
  EntityDeleteInput,
  InvoicePaymentInput,
} from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import Decimal from 'decimal.js';
import { useFormikContext } from 'formik';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { documentColumns } from '../document.columns';
import { PaymentValues } from '../types';
import { GetPaymentDetails } from '../__generated__/GetPaymentDetails';
import { AccountingInvoices, TransactionRowItem } from './AccountingInvoices';
import { useStyles } from './index.styles';
import { invoiceColumns } from './invoice.columns';
import {
  AttachedDocumentsDataType,
  PaymentDocuments,
} from './PaymentDocuments';
import {
  GetPaymentCycles,
  GetPaymentCyclesVariables,
} from './__generated__/GetPaymentCycles';
const PAYMENT_CYCLES = loader('./GetPaymentCycles.graphql');
interface PaymentCyclesOptions extends IDropdownOption {
  key: string | number;
  text: string;
  currency: string | undefined | null;
  defaultPaymentDate: Date | null;
  transactionTypeId: number;
}
export interface AttachedDocumentsType {
  documentTypeId: number | null | undefined;
  entityDocumentId: string[] | null;
}
interface ParamType {
  fieldName: string;
  value: string | number;
}
interface BasicFormProps {
  onDocumentsAttached?: (attachedDocumentsData: AttachedDocumentsType) => void;
  clearStates?: boolean;
  clearToDeleteTransactions?: boolean;
  paymentDetails?: GetPaymentDetails;
  isNew: boolean;
  toDeleteInvoices?: (array: EntityDeleteInput[]) => void;
  defaultInvoices?: InvoiceRow[];
  defaultAmount: number;
  paymentCycleId: string | null | undefined;
  paymentCycleDate: Date | null | undefined;
  paymentCycleCurrency: string | null | undefined;
  paymentCycleTransactionTypeId: number | undefined;
  paymentRowDocument?: InvoiceRow | undefined;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  onDocumentsAttached,
  clearStates,
  clearToDeleteTransactions,
  paymentDetails,
  isNew,
  toDeleteInvoices,
  defaultInvoices,
  defaultAmount,
  paymentCycleId,
  paymentCycleDate,
  paymentCycleCurrency,
  paymentCycleTransactionTypeId,
  paymentRowDocument,
}) => {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<PaymentValues>();
  const isUpdatable: boolean =
    !isNew && !paymentDetails?.payment?._isUpdatable ? false : true;
  const [selectedInvoice, setSelectedInvoice] = useState<string | undefined>();
  const styles = useStyles();
  const [isAttachTransactionOpen, setIsAttachTransactionOpen] = useState(false);
  const [isShowAttachDocumentButton, setIsShowAttachDocumentButton] =
    useState(false);
  const [isShowApplyTransactionButton, setIsShowApplyTransactionButton] =
    useState(true);
  const [isPaymentProtected, setIsPaymentProtected] = useState<boolean>(false);
  const [accountingInvoiceTotal, setAccountingInvoiceTotal] =
    useState<Decimal | null>(new Decimal(0));
  const [difference, setDifference] = useState<Decimal | null>(new Decimal(0));
  const [paymentCyclesOptions, setPaymentCyclesOptions] = useState<
    PaymentCyclesOptions[]
  >([]);
  const [currency, setCurrency] = useState<string | null | undefined>();
  const [payCycleId, setPayCycleId] = useState<string | null | undefined>();
  const [transactionTypeId, setTransactionTypeId] = useState<
    number | undefined
  >();
  const [selectedTransaction, setSelectedTransaction] = useState<
    TransactionRowItem[]
  >([]);
  const [selectedTransactionIDs, setSelectedTransactionIDs] = useState<
    InvoicePaymentInput[]
  >([]);
  const [vendorReferenceState, setVendorReferenceState] = useState<
    string | null | undefined
  >();
  const [attachedDocuments, setAttachedDocuments] = useState<
    AttachedDocumentsDataType | undefined
  >();
  const [toDeleteInvoicesState, setToDeleteInvoicesState] = useState<
    EntityDeleteInput[]
  >([]);
  const { data: paymentCycles } = useQuery<
    GetPaymentCycles,
    GetPaymentCyclesVariables
  >(PAYMENT_CYCLES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (paymentCycles?.payCycles?.nodes) {
      const cycles =
        paymentCycles.payCycles?.nodes
          .filter((item) => !isNew || !!item._isPaymentSelectable)
          .map((item) => {
            const bankAccountNumber = item.paymentAccount?.bankAccountNumber;
            const bankName = `${item.paymentAccount?.name} ${
              bankAccountNumber ? bankAccountNumber : ''
            }`;
            return {
              key: item.id,
              text: `${item.description} ${bankName}` || '',
              currency: item.currency?.isoCode + '-' + item.currency?.name!,
              defaultPaymentDate: item.defaultPaymentDate
                ? new Date(dateConvertions(item.defaultPaymentDate!))
                : null,
              transactionTypeId: item.transactionTypeId,
            };
          }) || [];
      setPaymentCyclesOptions(cycles);
    }
  }, [paymentCycles, isNew]);

  const handleAttachDoc = () => {
    if (attachedDocuments) {
      onDocumentsAttached?.({
        documentTypeId: attachedDocuments.documentTypeId,
        entityDocumentId: attachedDocuments.entityDocumentId,
      });
    }
  };

  const handleAttachDocMemo = useCallback(handleAttachDoc, [attachedDocuments]);

  useEffect(() => {
    handleAttachDocMemo();
  }, [handleAttachDocMemo]);

  const _removeAccountingInvoice = (
    invoiceId: string,
    vendorReference: string | null
  ) => {
    if (selectedTransaction.length > 0) {
      const selectedAccountingInvoices = selectedTransaction.filter(
        (ele) => ele.id !== invoiceId
      );
      setSelectedTransaction(selectedAccountingInvoices);
      if (vendorReference === vendorReferenceState) {
        setFieldValue('vendorReference', null);
      }
      if (isNew) {
        const trasactionIDs: InvoicePaymentInput[] =
          selectedAccountingInvoices.map((ele) => ({ invoiceId: ele.id }));
        setSelectedTransactionIDs(trasactionIDs);
      } else {
        const trasactionIDs: InvoicePaymentInput[] = selectedAccountingInvoices
          .filter(
            (ele) => ele.id !== invoiceId && ele.invoicePaymentId === undefined
          )
          .map((ele) => ({ invoiceId: ele.id }));
        setSelectedTransactionIDs(trasactionIDs);
        const toDeleteInvoicesArray = selectedTransaction
          .filter(
            (ele) =>
              ele.id === invoiceId && ele.invoicePaymentId && ele._rowTimestamp
          )
          .map((ele) => ({
            id: ele.invoicePaymentId!,
            rowTimestamp: ele._rowTimestamp!,
          }));
        if (toDeleteInvoicesArray.length > 0) {
          setToDeleteInvoicesState([
            ...toDeleteInvoicesState,
            ...toDeleteInvoicesArray,
          ]);
        }
      }
    }
  };

  const handleDeleteInvoicesState = () => {
    if (!isNew) {
      toDeleteInvoices?.(toDeleteInvoicesState);
    }
  };

  const handleDeleteInvoicesStateMemo = useCallback(handleDeleteInvoicesState, [
    toDeleteInvoicesState,
  ]);

  useEffect(() => {
    handleDeleteInvoicesStateMemo();
  }, [handleDeleteInvoicesStateMemo]);

  const handleDefaultInvoices = () => {
    if (defaultInvoices && defaultInvoices.length > 0) {
      const invoicesSelected = defaultInvoices?.map(
        (invoice) =>
          ({
            id: invoice?.id,
            type: invoice?.transactionType?.transactionType!,
            name: invoice?.vendorReference!,
            description: invoice?.description!,
            invoiceDate: invoice?.invoiceDate
              ? dateFormat(dateConvertions(invoice.invoiceDate))
              : null,
            invoiceNumber: invoice?.invoiceNumber!,
            amount: invoice?.controlTotalAmount!,
            invoicePaymentId: invoice.id,
            isDocumentSigningPayment: invoice.isDocumentSigningPayment,
          }) as TransactionRowItem
      );
      const trasactionIDs: InvoicePaymentInput[] = defaultInvoices?.map(
        (ele) => ({
          invoiceId: ele.id,
        })
      );
      setSelectedTransaction(invoicesSelected!);
      setSelectedTransactionIDs(trasactionIDs);

      if (paymentRowDocument) {
        setFieldValue('payCycleId', paymentCycleId);
        setFieldValue('vendorReference', paymentRowDocument.vendorReference);
        setFieldValue('paidAmount', paymentRowDocument.controlTotalAmount);
        if (paymentRowDocument.invoiceDate)
          setFieldValue(
            'paidDate',
            dateFormat(dateConvertions(paymentRowDocument.invoiceDate))
          );
        setFieldValue('paymentReference', paymentRowDocument.invoiceNumber);
      } else {
        setFieldValue('payCycleId', paymentCycleId);
        setFieldValue('paidDate', paymentCycleDate);
        setFieldValue('paidAmount', defaultAmount.toFixed(2));
        if (!values.vendorReference && invoicesSelected[0]) {
          setFieldValue('vendorReference', defaultInvoices[0].vendorReference);
          setVendorReferenceState(defaultInvoices[0].vendorReference);
        }
      }
      window.history.replaceState({}, document.title);
    }
  };

  const handleDdefaultInvoicesMemo = useCallback(handleDefaultInvoices, []);

  useEffect(() => {
    handleDdefaultInvoicesMemo();
  }, [handleDdefaultInvoicesMemo]);

  const handlePaymentDetails = () => {
    if (paymentDetails?.payment) {
      const paymentDetailsArray: TransactionRowItem[] =
        paymentDetails.payment.invoicePayments.nodes.map((ele) => ({
          ...ele.invoice!,
          type: ele.invoice?.transactionType?.transactionType!,
          name: ele.invoice?.vendorReference!,
          description: ele?.invoice?.description!,
          invoiceDate: ele?.invoice?.invoiceDate
            ? dateFormat(dateConvertions(ele?.invoice.invoiceDate))
            : null,
          invoiceNumber: ele?.invoice?.invoiceNumber!,
          amount: ele?.invoice?.controlTotalAmount!,
          _rowTimestamp: ele._rowTimestamp,
          _isUpdatable: ele._isUpdatable,
          _isDeletable: ele._isDeletable,
          invoicePaymentId: ele.id,
          isDocumentSigningPayment:
            ele.invoice?.transactionType?.isDocumentSigningPayment,
        }));

      setSelectedTransaction(paymentDetailsArray);
      setPayCycleId(paymentDetails?.payment.payCycleId);
      setCurrency(
        paymentDetails?.payment.currency?.isoCode +
          '-' +
          paymentDetails?.payment.currency?.name
      );
    }
  };

  useEffect(() => {
    if (!isNew && paymentDetails?.payment?._isUpdatable) {
      setIsShowApplyTransactionButton(true);
      if (paymentDetails.payment._isTransactionPayment)
        setIsPaymentProtected(true);
      else setIsPaymentProtected(false);
    }
  }, [paymentDetails, isNew]);

  const setTransactionFieldValues = async (param: ParamType) => {
    await setFieldValue(param.fieldName, param.value);
    setFieldTouched(param.fieldName, true);
  };

  const handlePaymentDetailsMemo = useCallback(handlePaymentDetails, [
    paymentDetails?.payment,
  ]);

  useEffect(() => {
    handlePaymentDetailsMemo();
  }, [handlePaymentDetailsMemo]);

  const handleSelectedTransactionIDs = () => {
    setFieldValue('invoicePayments', selectedTransactionIDs);
  };

  const handleSelectedTransactionIDsMemo = useCallback(
    handleSelectedTransactionIDs,
    [selectedTransactionIDs]
  );

  useEffect(() => {
    handleSelectedTransactionIDsMemo();
  }, [handleSelectedTransactionIDsMemo]);

  useEffect(() => {
    if (clearStates) {
      setIsPaymentProtected(false);
      setTransactionTypeId(0);
      setVendorReferenceState(undefined);
      setSelectedTransaction([]);
      setSelectedTransactionIDs([]);
      setAttachedDocuments(undefined);
      setIsShowAttachDocumentButton(false);
    }
  }, [clearStates]);

  useEffect(() => {
    if (selectedTransaction.length > 0) {
      const totalAmount = selectedTransaction.reduce(
        (n, { amount }) => new Decimal(amount || 0).plus(n),
        new Decimal(0)
      );
      setAccountingInvoiceTotal(totalAmount);
    } else {
      setAccountingInvoiceTotal(new Decimal(0));
    }
  }, [selectedTransaction]);

  const setAccountingInvoiceTotalField = () =>
    setFieldValue('accountingInvoiceTotal', accountingInvoiceTotal?.toNumber());

  const setAccountingInvoiceTotalFieldMemo = useCallback(
    setAccountingInvoiceTotalField,
    [accountingInvoiceTotal]
  );

  useEffect(() => {
    setAccountingInvoiceTotalFieldMemo();
  }, [setAccountingInvoiceTotalFieldMemo]);

  useEffect(() => {
    if (clearToDeleteTransactions) {
      setToDeleteInvoicesState([]);
      setSelectedTransactionIDs([]);
    }
    if (values.transactionTypeId) {
      setTransactionTypeId(values.transactionTypeId);
    }
  }, [clearToDeleteTransactions, values.transactionTypeId]);

  const onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const { item } = { ...props };
    const customStyles: Partial<IDetailsRowStyles> = {};
    const tootTipMessage = 'Approved Payment Request';
    const tooltipVisible = item?.isDocumentSigningPayment;
    if (!props) {
      return null;
    }
    if (tooltipVisible) {
      customStyles.root = {
        backgroundColor: '#1dba6e1c',
      };
      return (
        <TooltipHost
          content={tootTipMessage}
          directionalHint={DirectionalHint.leftCenter}
        >
          <DetailsRow {...props} styles={customStyles} />
        </TooltipHost>
      );
    } else return <DetailsRow {...props} />;
  };

  const onRenderItemColumn = (
    item: TransactionRowItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof TransactionRowItem
      ] as string;
      switch (column?.key) {
        case 'amount':
          return (
            <AmountTextView
              className={clsx(styles.contentColumnAlignRight)}
              value={item.amount}
            />
          );
        case 'name':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Link
                className={clsx(styles.linkRoot)}
                onClick={() => {
                  setSelectedInvoice(item.id);
                }}
              >
                <Text>{fieldContent && fieldContent}</Text>
              </Link>
            </Stack>
          );
        case 'action':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <TooltipHost content="Remove invoice" id="tooltipId">
                {isUpdatable && (
                  <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    onClick={() =>
                      _removeAccountingInvoice(item.id, item.vendorReference)
                    }
                  />
                )}
              </TooltipHost>
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{fieldContent && fieldContent}</Text>
            </Stack>
          );
      }
    }
  };

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: '415px' },
    dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
    dropdownItem: { height: 'auto' },
  };

  const controlTotalAmountAccum = selectedTransaction
    .filter((tran) => !tran.isDocumentSigningPayment)
    .map((item) => item.amount)
    .reduce((sum, curr) => {
      return new Decimal(curr || 0).plus(sum);
    }, new Decimal(0))
    .toNumber();

  useEffect(() => {
    if (paymentDetails?.payment?.invoicePayments.nodes) {
      //To check if payment row exist.

      //Condition commented as per the discussion with Nick on 12/05/2022

      // const isPaymentRowExist =
      //   paymentDetails?.payment?.invoicePayments.nodes.some(
      //     (row) => row.invoice?.transactionType?.isDocumentSigningPayment
      //   );
      // if (isPaymentRowExist) {
      const grandTotal = paymentDetails?.payment?.invoicePayments.nodes
        .filter(
          (tran) => !tran.invoice?.transactionType?.isDocumentSigningPayment
        )
        .map((item) => item.invoice?.controlTotalAmount)
        .reduce((sum, curr) => {
          return new Decimal(curr || 0).plus(sum);
        }, new Decimal(0))
        .toNumber();

      if (grandTotal) {
        try {
          const paidAmount = values.paidAmount || 0;
          const difference = new Decimal(paidAmount).minus(grandTotal);
          setDifference(difference);
        } catch (error) {
          setDifference(new Decimal(0).minus(grandTotal));
        }
      } else {
        setDifference(new Decimal(0));
      }
      // }
    }
  }, [paymentDetails, values.paidAmount]);

  const renderFooterColumn = (
    _item?: TransactionRowItem,
    _index?: number,
    column?: IColumn
  ) => {
    if (column?.key === 'invoiceDate')
      return (
        <Stack>
          <Text className={styles.invoiceTotalLabel}>Invoice Total</Text>
        </Stack>
      );
    if (column?.key === 'amount')
      return (
        <AmountTextView
          value={controlTotalAmountAccum.toString()}
          className={clsx(styles.amountStack, styles.contentColumnAlignRight)}
        />
      );
    return null;
  };

  const renderFooter: IRenderFunction<IDetailsFooterProps> = (props) => {
    if (!props || !controlTotalAmountAccum) {
      return null;
    }
    return (
      <DetailsRow
        {...props}
        item={{}}
        onRenderItemColumn={renderFooterColumn}
        itemIndex={-1}
      />
    );
  };

  useEffect(() => {
    if (isNew) {
      if (paymentCycleId) {
        setIsShowAttachDocumentButton(true);
        setIsShowApplyTransactionButton(true);
        setPayCycleId(paymentCycleId);
        setTransactionTypeId(paymentCycleTransactionTypeId);
        setCurrency(paymentCycleCurrency);
      }
    }
  }, [
    isNew,
    paymentCycleId,
    paymentCycleCurrency,
    paymentCycleTransactionTypeId,
  ]);

  return (
    <>
      {isNew && isShowAttachDocumentButton && (
        <Stack>
          <Stack tokens={{ childrenGap: 10 }} className={styles.mainContainer}>
            <Stack className={styles.documentsContainer}>
              {payCycleId && transactionTypeId && (
                <PaymentDocuments
                  transactionTypeId={transactionTypeId}
                  payCycleId={payCycleId}
                  onDocumentsAttached={(documentsData) => {
                    if (documentsData) {
                      for (let data of documentsData.attachedDocuments) {
                        if (
                          data.indexReferenceNumber &&
                          data.indexAmount &&
                          data.indexName
                        ) {
                          setTransactionFieldValues({
                            fieldName: 'paymentReference',
                            value: data.indexReferenceNumber!,
                          });

                          setTransactionFieldValues({
                            fieldName: 'paidAmount',
                            value: data.indexAmount!,
                          });

                          setTransactionFieldValues({
                            fieldName: 'vendorReference',
                            value: data.indexName!,
                          });
                        }
                      }
                      if (attachedDocuments?.attachedDocuments.length) {
                        setAttachedDocuments({
                          documentTypeId: documentsData.documentTypeId,
                          entityDocumentId: [
                            ...attachedDocuments.entityDocumentId!,
                            ...documentsData.entityDocumentId!,
                          ],
                          attachedDocuments: [
                            ...attachedDocuments.attachedDocuments,
                            ...documentsData.attachedDocuments,
                          ],
                        });
                      } else {
                        setAttachedDocuments(documentsData);
                      }
                    }
                  }}
                  prevSelectedDocuments={attachedDocuments?.attachedDocuments}
                />
              )}
              {attachedDocuments?.attachedDocuments.length && (
                <Stack tokens={{ childrenGap: 10 }}>
                  <Text variant="xLarge" className={styles.marginB20}>
                    Attached Documents
                  </Text>
                  <DocumentList
                    isNew={true}
                    columns={documentColumns}
                    data={attachedDocuments?.attachedDocuments || []}
                    isDeleteButtonVisible
                    onRemoveRow={(data) => {
                      const tempObject = {
                        documentTypeId: attachedDocuments.documentTypeId,
                        entityDocumentId: attachedDocuments?.entityDocumentId
                          ? attachedDocuments?.entityDocumentId.filter(
                              (item) => item !== data.id
                            )
                          : [],
                        attachedDocuments: attachedDocuments?.attachedDocuments
                          ? attachedDocuments?.attachedDocuments.filter(
                              (item) => item.id !== data.id
                            )
                          : [],
                      };
                      setAttachedDocuments(tempObject);
                    }}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
          {attachedDocuments?.attachedDocuments.length && <Separator />}
        </Stack>
      )}
      <Stack tokens={{ childrenGap: 20 }} className={styles.mainContainer}>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack className={styles.flex50}>
            <FormikDropdown
              styles={dropdownStyles}
              label="Pay Cycle"
              placeholder="Select"
              options={paymentCyclesOptions}
              name="payCycleId"
              onChange={(_event, option) => {
                setIsShowAttachDocumentButton(true);
                setIsShowApplyTransactionButton(true);
                const selectedOption = option as PaymentCyclesOptions;
                setPayCycleId(selectedOption.key.toString());
                setTransactionTypeId(selectedOption.transactionTypeId);
                setFieldValue('paidDate', selectedOption.defaultPaymentDate);
                setCurrency(selectedOption.currency);
              }}
              disabled={!isNew}
              required
            />
          </Stack>
          <Stack className={styles.flex50}>
            <FormikDatePicker
              name="paidDate"
              placeholder="Select a date"
              label="Payment Date"
              firstDayOfWeek={DayOfWeek.Sunday}
              showWeekNumbers
              firstWeekOfYear={1}
              showMonthPickerAsOverlay
              showGoToToday
              disabled={!isUpdatable || isPaymentProtected}
              isRequired
            />
          </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack className={styles.flex50}>
            <FormikTextField
              name="paymentReference"
              label="Payment Number1"
              placeholder="Check Number / Reference"
              disabled={!isUpdatable || isPaymentProtected}
              required
            />
          </Stack>
          <Stack className={styles.flex50}>
            <AmountTextField
              name="paidAmount"
              label="Amount"
              placeholder="Payment Total"
              disabled={!isUpdatable || isPaymentProtected}
              fixedDecimalScale
              decimalScale={2}
              allowNegative={true}
              required
            />
            {difference?.toNumber()! > 0 && (
              <>
                <Stack horizontal>
                  <Text className={styles.differenceText}>{`Difference:`}</Text>
                  <AmountTextView
                    className={clsx(
                      styles.differenceText,
                      styles.differenceAmount
                    )}
                    value={difference?.toString() || ''}
                  />
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack.Item className={styles.flex50}>
            <AutoCompleteFormikTextField
              name="vendorReference"
              label="Pay To (Name)"
              placeholder="Payee"
              disabled={!isUpdatable || isPaymentProtected}
              lookupData={paymentDetails?.payment?.lookupName}
              transactionTypeId={transactionTypeId!}
              id={'vendorReference'}
              lookUpCalloutWidth={410}
              required
            />
          </Stack.Item>
          <Stack className={styles.flex50}>
            <TextField
              value={currency ? currency : ''}
              disabled
              label="Currency"
              readOnly
            />
          </Stack>
        </Stack>
      </Stack>

      <>
        <Separator />
        {
          <Stack
            className={styles.container}
            horizontal
            horizontalAlign="space-between"
          >
            <Text variant="xLarge">Accounting - Invoice</Text>
            {isUpdatable && isShowApplyTransactionButton && (
              <DefaultButton
                text="Apply Transactions"
                disabled={!payCycleId}
                iconProps={{
                  iconName: 'Add',
                }}
                onClick={() => {
                  setIsAttachTransactionOpen(true);
                }}
              />
            )}
          </Stack>
        }

        <Stack tokens={{ padding: '10px 25px' }} className={styles.marginB200}>
          {selectedTransaction.length > 0 && (
            <DetailsList
              setKey="set"
              compact
              selectionMode={SelectionMode.none}
              items={selectedTransaction}
              columns={invoiceColumns}
              onRenderItemColumn={onRenderItemColumn}
              onRenderDetailsFooter={renderFooter}
              onRenderRow={onRenderRow}
            />
          )}
        </Stack>
      </>

      {isAttachTransactionOpen && payCycleId && (
        <AccountingInvoices
          payCycleId={payCycleId}
          isOpen={isAttachTransactionOpen}
          dismissModal={(data) => {
            if (data) setIsAttachTransactionOpen(false);
          }}
          selectedTransactions={(transactions) => {
            if (transactions.length > 0) {
              const selectedTransactionArray = [
                ...selectedTransaction,
                ...transactions,
              ];
              if (isNew) {
                const trasactionIDs: InvoicePaymentInput[] =
                  selectedTransactionArray.map((ele) => ({
                    invoiceId: ele.id,
                  }));
                setSelectedTransaction(selectedTransactionArray);
                if (!values.vendorReference) {
                  setFieldValue(
                    'vendorReference',
                    selectedTransactionArray[0].vendorReference
                  );
                  setVendorReferenceState(
                    selectedTransactionArray[0].vendorReference
                  );
                }
                setSelectedTransactionIDs(trasactionIDs);
              } else {
                if (!paymentDetails?.payment?._isTransactionPayment) {
                  const isDocumentSigningPayment = transactions.find(
                    (ele) => ele.transactionType?.isDocumentSigningPayment
                  );
                  if (isDocumentSigningPayment) {
                    setFieldValue(
                      'paidDate',
                      isDocumentSigningPayment.invoiceDate
                    );
                    setFieldValue(
                      'paymentReference',
                      isDocumentSigningPayment.invoiceNumber
                    );
                    setFieldValue(
                      'paidAmount',
                      isDocumentSigningPayment.controlTotalAmount
                    );
                    setFieldValue(
                      'vendorReference',
                      isDocumentSigningPayment.vendorReference
                    );
                  }
                }
                const trasactionIDs: InvoicePaymentInput[] = transactions.map(
                  (ele) => ({ invoiceId: ele.id })
                );
                const setSelectedTransactionIdArray = [
                  ...selectedTransactionIDs,
                  ...trasactionIDs,
                ];
                setSelectedTransaction(selectedTransactionArray);
                setSelectedTransactionIDs(setSelectedTransactionIdArray);
              }
            }
          }}
          prevSelectedDocuments={selectedTransaction}
        />
      )}
      {selectedInvoice !== undefined && (
        <TransactionModal
          entityId={selectedInvoice}
          isOpen={selectedInvoice !== undefined}
          onDismiss={() => setSelectedInvoice(undefined)}
        />
      )}
    </>
  );
};
