import {
  makeVar,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  ContextualMenu,
  IDropdownOption,
  Modal,
  Separator,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AvailablePurchaseOrders,
  AvailablePurchaseOrdersVariables,
} from 'ap/signing/__generated__/AvailablePurchaseOrders';
import { InvoiceTransactionTypes } from 'ap/signing/__generated__/InvoiceTransactionTypes';
import { AccountingView as AccountingEntryRows } from 'ap/signing/accountingEntry/view/AccountingView';
import { ActionsMenu } from 'ap/signing/accountingEntry/view/ActionMenu';
import { BasicForm } from 'ap/signing/accountingEntry/view/BasicForm';
import { FormCarbonAccount } from 'ap/signing/accountingEntry/view/CarbonAccounting';
import { ShimmerView } from 'ap/signing/accountingEntry/view/ShimmerView/ShimmerViews';
import {
  SignatureOptions,
  SignatureView,
} from 'ap/signing/accountingEntry/view/Signatures';
import {
  AccountingEntryInvoice,
  AccountingEntryInvoiceVariables,
} from 'ap/signing/accountingEntry/view/__generated__/AccountingEntryInvoice';
import {
  InvoiceSigningAccountingStamper,
  InvoiceSigningAccountingStamperVariables,
} from 'ap/signing/accountingEntry/view/__generated__/InvoiceSigningAccountingStamper';
import { columns } from 'ap/signing/accountingEntry/view/column.data';
import { useStyles } from 'ap/signing/accountingEntry/view/index.styles';
import {
  AccountingDistributionRows,
  AccountingEntryValues,
} from 'ap/signing/accountingEntry/view/types';
import { getDefaultValues } from 'ap/signing/accountingEntry/view/utils';
import { Accounting } from 'ap/signing/components/Accounting';
import {
  InvoiceSigningUpdate,
  InvoiceSigningUpdateVariables,
} from 'ap/signing/transactionSigning/view/__generated__/InvoiceSigningUpdate';
import { validationSchema } from 'ap/signing/transactionSigning/view/validation';
import { ApprovalHistory } from 'common/components/ApprovalHistory';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { CustomDropdown } from 'common/components/CustomDropdown';
import { DocumentList } from 'common/components/DocumentList';
import { VIEW_DOC_MODAL_WIDTH } from 'common/components/DocumentList/DocumentViewModal';
import {
  TransactionDocumentPackageStatus,
  TransactionDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/TransactionDocumentPackageStatus';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import {
  DocumentPackageStatusType,
  EntityDeleteInput,
  InvoiceBatchesOrderBy,
  InvoiceDistributionPatch,
  InvoiceDistributionUpdateTypeInput,
  UploadStatusType,
} from 'common/types/globalTypes';
import { getWindowDimensions } from 'common/utils/columnUtilities';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { differenceBy, intersection, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { FooterActions } from './FooterActions';
import { Header } from './Header';

const INVOICE_DETAILS = loader(
  '../../../../../../../ap/signing/accountingEntry/view/AccountingEntryInvoice.graphql'
);
const BATCH_TRANSACTION = loader('../../../BatchTransaction.graphql');

const TRANSACTION_DATA = loader(
  '../../../../../../../ap/signing/InvoiceTransactionTypes.graphql'
);
const UPDATE_STAMPER = loader(
  '../../../../../../../ap/signing/accountingEntry/view/InvoiceSigningAccountingStamper.graphql'
);

const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);

const DOCUMENT_PACKAGE_STATUS = loader(
  '../../../../../../../common/graphql/DocumentPackageSubscription/TransactionPackageStatus.graphql'
);
const AVAILABLE_PURCHASE_ORDER = loader(
  '../../../../../../../ap/signing/AvailablePurchaseOrders.graphql'
);
const UPDATE_INVOICE_SIGNING = loader(
  '../../../../../../../ap/signing/transactionSigning/view//InvoiceSigningUpdate.graphql'
);
const INVOICE_BATCHES = loader(
  '../../../../../../batchEdit/unpostedTransactions/InvoiceBatchModal/InvoiceBatches.graphql'
);

interface AccountingEditPanelProps {
  id: string | undefined;
  batchTransactionId: string | undefined;
  isOpen: boolean;
  onDismiss: () => void;
}
interface BatchOptions extends IDropdownOption {
  key: string | number;
  text: string;
  isoCode: string;
  corporatePeriodId: string | undefined;
}

export const setBatchSelected = makeVar<BatchOptions | undefined>(undefined);

export const AccountingEditPanel: React.FC<AccountingEditPanelProps> = ({
  id,
  batchTransactionId,
  isOpen,
  onDismiss,
}) => {
  const accountingEntryId = id;
  const toggleConfirmDialog = () => {
    setHideConfirmRequestDialog((prevState) => !prevState);
    setRequestAccountingTransaction(undefined);
    setSuccessBtnDisable(true);
  };
  const { addToast, updateToast } = useToasts();
  const styles = useStyles();
  const client = useApolloClient();
  const currentSelectedBatch = useReactiveVar(setBatchSelected);
  const [corporatePeriodId, setCorporatePeriodId] = React.useState<
    string | undefined
  >();
  const [signatureData, setSignatureData] = useState<SignatureOptions[]>([]);

  const [requestAccountingTransaction, setRequestAccountingTransaction] =
    React.useState<string>();
  const [hideConfirmRequestDialog, setHideConfirmRequestDialog] =
    useState<boolean>(true);
  const [successBtnDisable, setSuccessBtnDisable] = useState<boolean>(true);

  const [accountingPeriodOptions, setaccountingPeriodOptions] = React.useState<
    IDropdownOption[]
  >([]);
  const [
    accountingTransactionReferenceReimbursement,
    setAccountingTransactionReferenceReimbursement,
  ] = React.useState<string>();
  const { width } = getWindowDimensions();
  const documentViewPosition = width
    ? width - (1100 + VIEW_DOC_MODAL_WIDTH)
    : 50;
  const [receiptTotal, setReceiptTotal] = useState<string | null>(null);
  const [
    getInvoiceDetails,
    { loading: invoiceDetailsDataLoading, data: invoiceDetailsData, refetch },
  ] = useLazyQuery<AccountingEntryInvoice, AccountingEntryInvoiceVariables>(
    INVOICE_DETAILS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const isReimburseAmountExist: boolean = invoiceDetailsData?.invoice
    ?.reimburseAmount
    ? true
    : false;

  const formMethods = useForm<AccountingEntryValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });
  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = { ...formMethods };
  const { data: transactionTypeData } = useQuery<InvoiceTransactionTypes>(
    TRANSACTION_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const [updateTransaction, { loading: updateTransactionLoading }] =
    useMutation<InvoiceSigningUpdate, InvoiceSigningUpdateVariables>(
      UPDATE_INVOICE_SIGNING,
      { errorPolicy: 'all' }
    );

  const [updateStamper] = useMutation<
    InvoiceSigningAccountingStamper,
    InvoiceSigningAccountingStamperVariables
  >(UPDATE_STAMPER, { errorPolicy: 'all' });
  const { _receiptsTotal } = { ...invoiceDetailsData?.invoice };
  useEffect(() => {
    setReceiptTotal(_receiptsTotal!);
  }, [_receiptsTotal]);
  useEffect(() => {
    if (accountingEntryId && accountingEntryId !== '') {
      getInvoiceDetails({
        variables: {
          id: accountingEntryId,
        },
      });
    }
  }, [getInvoiceDetails, accountingEntryId]);
  useEffect(() => {
    const accP =
      transactionTypeData?.companyCorporatePeriods?.nodes.map((item) => ({
        disabled: item.isEntryAllowed ? false : true,
        key: item.id,
        text:
          item._periodYear +
          `(${
            item.startDate! ? dateFormat(dateConvertions(item.startDate!)) : ''
          } - ${
            item.endDate! ? dateFormat(dateConvertions(item.endDate!)) : ''
          })`,
      })) || [];
    setaccountingPeriodOptions(accP);
    if (!currentSelectedBatch) {
      if (invoiceDetailsData?.invoice?.corporatePeriodId)
        setCorporatePeriodId(invoiceDetailsData?.invoice?.corporatePeriodId);
    }
  }, [transactionTypeData, invoiceDetailsData, currentSelectedBatch]);
  useEffect(() => {
    if (!currentSelectedBatch) {
      if (invoiceDetailsData?.invoice?.corporatePeriodId)
        setCorporatePeriodId(invoiceDetailsData?.invoice?.corporatePeriodId);
    }
  }, [invoiceDetailsData, hideConfirmRequestDialog, currentSelectedBatch]);
  useEffect(() => {
    if (
      invoiceDetailsData?.invoice &&
      invoiceDetailsData?.invoice.approvalHistorySignaturesByEntityId.nodes
        .length > 0
    ) {
      const signatureOptions: SignatureOptions[] =
        invoiceDetailsData?.invoice.approvalHistorySignaturesByEntityId.nodes.map(
          (item) =>
            ({
              actionDate: item.actionDate,
              additionalInformation: item.additionalInformation,
              digitalSignature: item.digitalSignature,
              id: item.id,
              isApproved: item.isApproved,
              isRejected: item.isRejected,
              isRequested: item.isRequested,
              userName: item.userName,
              entityDocumentId: item.entityDocumentId,
              userOccupationTitle: item.userOccupationTitle,
              userSignatureDocument: {
                _downloadLink: item.userSignatureDocument?._downloadLink,
                fileIndexInformation:
                  item.userSignatureDocument?.fileIndexInformation,
                fileReference: item.userSignatureDocument?.fileReference,
                iconType: item.userSignatureDocument?.iconType,
                id: item.userSignatureDocument?.id,
              },
            } as SignatureOptions)
        );
      setSignatureData(signatureOptions);
    }
  }, [invoiceDetailsData]);
  useEffect(() => {
    const defaultValues = getDefaultValues(invoiceDetailsData);
    reset(defaultValues);
  }, [invoiceDetailsData, reset]);

  const { data: availablePurchaseOrders } = useQuery<
    AvailablePurchaseOrders,
    AvailablePurchaseOrdersVariables
  >(AVAILABLE_PURCHASE_ORDER, {
    skip: !invoiceDetailsData?.invoice?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    variables: { invoiceId: invoiceDetailsData?.invoice?.id! },
  });

  const onHandleSubmit = async (values: AccountingEntryValues) => {
    const initialValues = getDefaultValues(invoiceDetailsData);
    const { _rowTimestamp, invoiceDistributions } = values;

    const newInvoiceDistributionRows =
      invoiceDistributions &&
      (invoiceDistributions
        ?.filter(
          (addr) => !addr.id && !Object.values(addr).every((x) => x === null)
        )
        .map(
          ({
            id,
            _rowTimestamp,
            _createdDate,
            _isDeletable,
            _isUpdatable,
            _ff1Name,
            _ff2Name,
            _ff3Name,
            _ff4Name,
            ...addr
          }) => addr
        ) as InvoiceDistributionPatch[]);

    const deletedInvoiceDistributionRows =
      initialValues.invoiceDistributions &&
      (differenceBy(
        initialValues.invoiceDistributions,
        invoiceDistributions || [],
        'id'
      ).map((addr) => ({
        id: addr.id!,
        rowTimestamp: addr._rowTimestamp!,
      })) as EntityDeleteInput[]);

    const updatedInvoiceDistributions =
      initialValues.invoiceDistributions &&
      invoiceDistributions &&
      intersection(
        initialValues.invoiceDistributions.map((addr) => addr.id),
        invoiceDistributions.filter((addr) => addr.id).map((addr) => addr.id)
      ).reduce((arr, targetId) => {
        const initialInvoiceDistributions =
          initialValues.invoiceDistributions!.find(
            (addr) => addr.id === targetId
          )!;
        const { id, _rowTimestamp, ...updatedAddress } =
          invoiceDistributions!.find((addr) => addr.id === targetId)!;
        const patch = Object.entries(updatedAddress).reduce(
          (res, [key, val]) => {
            if (
              val !==
              initialInvoiceDistributions[
                key as keyof AccountingDistributionRows
              ]
            )
              return { ...res, [key]: val };
            return res;
          },
          {}
        );
        if (!isEmpty(patch))
          return [
            ...arr,
            {
              id,
              rowTimestamp: _rowTimestamp,
              invoiceDistributionPatch: patch,
            },
          ];
        return arr;
      }, [] as InvoiceDistributionUpdateTypeInput[]);

    if (accountingEntryId && _rowTimestamp) {
      const { errors } = await updateTransaction({
        variables: {
          input: {
            id: accountingEntryId,
            rowTimestamp: _rowTimestamp,
            invoiceDistributionsUpdate:
              updatedInvoiceDistributions &&
              updatedInvoiceDistributions.length > 0
                ? updatedInvoiceDistributions
                : undefined,
            invoiceDistributionsCreate:
              newInvoiceDistributionRows &&
              newInvoiceDistributionRows.length > 0
                ? newInvoiceDistributionRows
                : undefined,
            invoiceDistributionsDelete:
              deletedInvoiceDistributionRows &&
              deletedInvoiceDistributionRows.length > 0
                ? deletedInvoiceDistributionRows
                : undefined,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: INVOICE_DETAILS,
            variables: {
              id: accountingEntryId,
            },
          },
          {
            query: INVOICE_BATCHES,
            variables: {
              id: batchTransactionId,
              orderBy: [
                InvoiceBatchesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
                InvoiceBatchesOrderBy.PRIMARY_KEY_ASC,
              ],
            },
          },
        ],
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('Transaction edited successfully', {
          appearance: 'success',
        });
      }
    }
  };
  return (
    <>
      <FormProvider {...formMethods}>
        <Modal
          isOpen={isOpen}
          isBlocking
          focusTrapZoneProps={{
            style: {
              overflowY: 'hidden',
              width: 1200,
            },
          }}
          onDismiss={() => {
            onDismiss();
          }}
          dragOptions={{
            moveMenuItemText: 'Move',
            closeMenuItemText: 'Close',
            menu: ContextualMenu,
            dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
          }}
        >
          <Header
            invoiceDetailsData={invoiceDetailsData}
            transactionTypeData={transactionTypeData}
            onClose={() => onDismiss()}
            toggleConfirmDialog={toggleConfirmDialog}
          />
          {(invoiceDetailsDataLoading || updateTransactionLoading) && (
            <Stack
              className={styles.shimmerViewMainContainer}
              tokens={{ childrenGap: 20 }}
            >
              <Stack
                className={styles.headerContainer}
                tokens={{ childrenGap: 10 }}
              >
                <ShimmerView />
              </Stack>
            </Stack>
          )}
          <Stack className={styles.marginTop20}>
            <ConfirmDialog
              isConfirmPrimaryButton
              successBtnDisable={successBtnDisable}
              hidden={hideConfirmRequestDialog}
              title={
                'Are you sure you want to stamp this transaction as Entered?'
              }
              isDraggable
              onDismiss={toggleConfirmDialog}
              minWidth={500}
              onConfirm={async () => {
                if (requestAccountingTransaction) {
                  let dataVariables: InvoiceSigningAccountingStamperVariables =
                    {
                      input: {
                        id: invoiceDetailsData?.invoice?.id || '',
                        rowTimestamp:
                          invoiceDetailsData?.invoice?._rowTimestamp || '',
                        batchTransactionId: batchTransactionId,
                        corporatePeriodId: corporatePeriodId,
                        transactionReference: requestAccountingTransaction,
                        reimbursementTransactionReference:
                          isReimburseAmountExist &&
                          accountingTransactionReferenceReimbursement
                            ? accountingTransactionReferenceReimbursement
                            : null,
                      },
                    };
                  const { errors } = await updateStamper({
                    variables: dataVariables,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                      {
                        query: INVOICE_BATCHES,
                        variables: {
                          id: batchTransactionId,
                          orderBy: [
                            InvoiceBatchesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
                            InvoiceBatchesOrderBy.PRIMARY_KEY_ASC,
                          ],
                        },
                      },
                      {
                        query: BATCH_TRANSACTION,
                        variables: {
                          id: batchTransactionId,
                        },
                      },
                    ],
                  });
                  if (errors?.length)
                    addToast(errors[0].message, {
                      appearance: 'error',
                    });
                  else {
                    addToast('Stamp successful', {
                      appearance: 'success',
                    });
                    toggleConfirmDialog();
                    refetch?.();

                    const observer = client.subscribe<
                      TransactionDocumentPackageStatus,
                      TransactionDocumentPackageStatusVariables
                    >({
                      query: DOCUMENT_PACKAGE_STATUS,
                      variables: {
                        id: invoiceDetailsData?.invoice?.id!,
                      },
                    });

                    const subscription = observer.subscribe(
                      ({ data, errors }) => {
                        if (errors)
                          addToast(
                            'Errors received while Subscribing to document package',
                            { appearance: 'error' }
                          );
                        else {
                          const { document, status } = {
                            ...data?.transactionDocumentPackageStatus,
                          };

                          if (
                            status ===
                            DocumentPackageStatusType.REGENERATION_FAILURE
                          ) {
                            addToast(
                              'Report generation failed. Document package was not created',
                              { appearance: 'error' }
                            );
                          }
                          if (status === DocumentPackageStatusType.FAILURE) {
                            addToast('Error while creating Document package ', {
                              appearance: 'error',
                            });
                          }
                          if (document) {
                            addToast('Document package created', {
                              appearance: 'success',
                            });
                            client.cache.modify({
                              id: `${invoiceDetailsData?.invoice?.__typename}:${invoiceDetailsData?.invoice?.id}`,
                              fields: {
                                _documentPackageId: () => {
                                  return document.id;
                                },
                              },
                            });
                          }
                        }

                        subscription.unsubscribe();
                      }
                    );
                  }
                }
              }}
            >
              <Stack tokens={{ childrenGap: 10 }}>
                <TextField
                  value={requestAccountingTransaction}
                  placeholder="Enter Accounting System Transaction #"
                  resizable={false}
                  onChange={(_event, value) => {
                    setRequestAccountingTransaction(value || '');
                    if (value) {
                      setSuccessBtnDisable(false);
                    } else {
                      setSuccessBtnDisable(true);
                    }
                  }}
                />
                {isReimburseAmountExist && (
                  <>
                    <Stack style={{ marginTop: 20 }}>
                      <TextField
                        label="Reimbursement Transaction #"
                        value={accountingTransactionReferenceReimbursement!}
                        placeholder="Enter Accounting System Transaction # (Reimbursement)"
                        resizable={false}
                        onChange={(_event, value) => {
                          setAccountingTransactionReferenceReimbursement(
                            value || ''
                          );
                        }}
                      />
                    </Stack>
                  </>
                )}
                <CustomDropdown
                  label="Accounting Period"
                  placeholder="Select"
                  selectedKey={corporatePeriodId ? corporatePeriodId : null}
                  options={accountingPeriodOptions}
                  onChange={(_, option) => {
                    setCorporatePeriodId(option?.key.toString());
                  }}
                  onClear={() => setCorporatePeriodId(undefined)}
                  notifyOnReselect
                />
              </Stack>
            </ConfirmDialog>
            <Stack
              className={styles.headerContainer}
              tokens={{ childrenGap: 10 }}
            >
              {invoiceDetailsData?.invoice && (
                <ActionsMenu
                  secureRowLevels={transactionTypeData?.secureRowLevels}
                  invoice={invoiceDetailsData.invoice}
                  reloaddata={() => refetch?.()}
                  onUpload={async (fileSelected, document, toastId) => {
                    const observer = client.subscribe({
                      query: DOCUMENT_UPLOAD_STATUS,
                      variables: {
                        documentId: document.document._documentFileId!,
                      },
                    });
                    const subscription = observer.subscribe((data) => {
                      const subscribedData =
                        data.data as OnDocumentUploadStatus;

                      const { status, document } = {
                        ...subscribedData.documentUploadStatus,
                      };

                      if (status.type === UploadStatusType.VALIDATING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Validating files ${fileSelected.name} - ${status.message}`
                            : `Validating files ${fileSelected.name}`,
                          appearance: 'info',
                          autoDismiss: false,
                        });
                      } else if (status.type === UploadStatusType.EXTRACTING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Extracting data from ${fileSelected.name} - ${status.message}`
                            : `Extracting data from ${fileSelected.name}`,
                          appearance: 'info',
                          autoDismiss: false,
                        });
                      } else if (status.type === UploadStatusType.FAILURE) {
                        subscription.unsubscribe();
                        updateToast(toastId!, {
                          content: status.message
                            ? `Upload of ${fileSelected.name} failed - ${status.message}`
                            : `Upload of ${fileSelected.name} failed`,
                          appearance: 'error',
                          autoDismiss: true,
                        });
                      } else if (status.type === UploadStatusType.WARNING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Warning for file ${fileSelected.name}: ${status.message}`
                            : `Warning for file ${fileSelected.name}`,
                          appearance: 'warning',
                          autoDismiss: true,
                        });
                      } else {
                        subscription.unsubscribe();
                        updateToast(toastId!, {
                          content: status.message
                            ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                            : `Successfully uploaded ${fileSelected.name}`,
                          appearance: 'success',
                          autoDismiss: true,
                        });

                        const cacheData = client.readQuery<
                          AccountingEntryInvoice,
                          AccountingEntryInvoiceVariables
                        >({
                          query: INVOICE_DETAILS,
                          variables: {
                            id: accountingEntryId!,
                          },
                        });

                        const updatedData: AccountingEntryInvoice = {
                          invoice: {
                            ...cacheData?.invoice!,
                            entityDocumentsByEntityId: {
                              ...cacheData?.invoice?.entityDocumentsByEntityId
                                .nodes,
                              nodes: [
                                { ...document! },
                                ...cacheData?.invoice?.entityDocumentsByEntityId
                                  .nodes!,
                              ],
                            },
                          },
                        };

                        client.writeQuery<
                          AccountingEntryInvoice,
                          AccountingEntryInvoiceVariables
                        >({
                          query: INVOICE_DETAILS,
                          data: updatedData,
                          variables: {
                            id: accountingEntryId!,
                          },
                        });
                      }
                    });
                  }}
                />
              )}
            </Stack>
            <Stack className={styles.marginTop10}>
              <Separator />
            </Stack>
            <Stack className={styles.paddingTop10}>
              <BasicForm
                invoiceDetails={invoiceDetailsData}
                receiptTotal={receiptTotal}
              />
            </Stack>
          </Stack>
          {invoiceDetailsData &&
            invoiceDetailsData?.invoice?.invoiceDistributions.nodes.length !==
              0 &&
            !invoiceDetailsData?.invoice?._isUpdatable && (
              <Stack className={styles.marginTop20}>
                <Stack className={styles.marginTop10}>
                  <Separator />
                </Stack>
                <AccountingEntryRows invoiceDetails={invoiceDetailsData} />
              </Stack>
            )}
          {transactionTypeData && invoiceDetailsData?.invoice?._isUpdatable && (
            <>
              <Separator />
              <Accounting
                invoiceData={invoiceDetailsData?.invoice!}
                invoiceDistributionStatusTypes={transactionTypeData}
                availablePurchaseOrders={availablePurchaseOrders}
              />
            </>
          )}
          {invoiceDetailsData &&
            invoiceDetailsData?.invoice?.entityEnvironmentalsByEntityId.nodes
              .length !== 0 && (
              <Stack className={styles.marginTop20}>
                <Stack className={styles.marginTop10}>
                  <Separator />
                </Stack>
                <FormCarbonAccount invoiceDetails={invoiceDetailsData} />
              </Stack>
            )}
          {invoiceDetailsData?.invoice?.entityDocumentsByEntityId.nodes
            .length! > 0 &&
            invoiceDetailsData && (
              <Stack>
                <Separator />
                <Stack
                  className={styles.tagsContainer}
                  tokens={{ childrenGap: 20 }}
                >
                  <Text variant="xLarge">Documents</Text>
                  {invoiceDetailsData?.invoice?.entityDocumentsByEntityId.nodes
                    .length && (
                    <Stack className={styles.fileListContainer}>
                      <DocumentList
                        paddingLeft={documentViewPosition}
                        isNew={false}
                        columns={columns}
                        data={
                          invoiceDetailsData?.invoice?.entityDocumentsByEntityId
                            .nodes!
                        }
                      />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            )}
          {invoiceDetailsData?.invoice?.approvalHistorySignaturesByEntityId
            .nodes.length! > 0 && (
            <Stack>
              <Separator />
              <Stack
                className={styles.tagsContainer}
                tokens={{ childrenGap: 20 }}
              >
                <Text variant="xLarge">Signatures</Text>
                <SignatureView signatureData={signatureData} />
              </Stack>
            </Stack>
          )}
          {invoiceDetailsData?.invoice &&
            !!invoiceDetailsData?.invoice?.approvalHistoriesByEntityId?.nodes
              .length && (
              <Stack style={{ paddingBottom: 200 }}>
                <Separator />
                <Stack
                  className={styles.tagsContainer}
                  tokens={{ childrenGap: 20 }}
                >
                  <Text variant="xLarge">Approval History</Text>
                  <Text variant="medium" className={styles.requestedByText}>
                    Requested By:
                  </Text>
                  <ApprovalHistory
                    data={
                      invoiceDetailsData.invoice.approvalHistoriesByEntityId
                        ?.nodes || []
                    }
                  />
                </Stack>
              </Stack>
            )}
          <Prompt
            when={isDirty && !isSubmitting}
            message="Are you sure you want to leave your changes unsaved?"
          />
          <FooterActions
            batchTransactionId={batchTransactionId}
            handleSubmit={handleSubmit(onHandleSubmit)}
            invoiceDetailsData={invoiceDetailsData}
            refetch={() => refetch?.()}
            onDismiss={onDismiss}
          />
        </Modal>
      </FormProvider>
    </>
  );
};
