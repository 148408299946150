import {
  NetworkStatus,
  makeVar,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  Checkbox,
  DefaultButton,
  Dropdown,
  FontSizes,
  FontWeights,
  ICheckboxStyles,
  IColumn,
  IDetailsRowBaseProps,
  IDetailsRowProps,
  IDropdownStyles,
  IRenderFunction,
  PrimaryButton,
  Stack,
  Text,
  TextField,
  useTheme,
} from '@fluentui/react';
import clsx from 'clsx';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { FilterArrayType } from 'common/components/Filters';
import {
  HighLightActiveLink,
  HighlightTextView,
} from 'common/components/HighLight';
import { InfiniteList } from 'common/components/InfiniteList';
import {
  MultiSelectOption,
  MultiSelectTags,
} from 'common/components/MultiSelectTags';
import { RedBoxIndicator } from 'common/components/RedBoxIndicator';
import { RequestMessageModal } from 'common/components/RequestMessageModal';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalRequestInput,
  EntityDeleteInput,
  PurchaseOrderFilter,
  PurchaseOrdersOrderBy,
  SearchRequestInput,
} from 'common/types/globalTypes';
import { EntityAction, EntityType } from 'common/types/utility';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { convertToTitleCase } from 'common/utils/convertToTitleCase';
import {
  dateConvertions,
  dateFormat,
  formatDateTime,
} from 'common/utils/dateFormats';
import Decimal from 'decimal.js';
import { loader } from 'graphql.macro';
import {
  PurchaseOrderApprovalCreate,
  PurchaseOrderApprovalCreateVariables,
} from 'purchaseOrder/__generated__/PurchaseOrderApprovalCreate';
import {
  PurchaseOrderDelete,
  PurchaseOrderDeleteVariables,
} from 'purchaseOrder/__generated__/PurchaseOrderDelete';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { StringParam } from 'serialize-query-params';
import { useQueryParams } from 'use-query-params';
import { expandedNav, globalMode, setGlobalSearchText } from 'utility/cache/ui';
import { StatusIcon } from '../../common/components/StatusIcon';
import { ExportPurchaseOrder } from './ExportPurchaseOrders';
import { POSearchBar } from './POSearchBar';
import { StampTransactionFooter } from './StampTransaction';
import {
  PurchaseOrderSearch,
  PurchaseOrderSearchVariables,
  PurchaseOrderSearch_purchaseOrderSearch,
  PurchaseOrderSearch_purchaseOrderSearch_nodes,
} from './__generated__/PurchaseOrderSearch';
import {
  PurchaseOrderSearchFilterTotals,
  PurchaseOrderSearchFilterTotalsVariables,
  PurchaseOrderSearchFilterTotals_purchaseOrderSearchFilterTotals,
} from './__generated__/PurchaseOrderSearchFilterTotals';
import {
  PurchaseOrderSearchHints,
  PurchaseOrderSearchHintsVariables,
} from './__generated__/PurchaseOrderSearchHints';
import { useColumns } from './column.data';
import { useStyles } from './index.styles';
import {
  getFilterParams,
  listTypeEnum,
  listTypeOptions,
  toFilterVariable,
  toOrderByVariable,
} from './utils';
const PURCHASE_ORDER_SEARCH = loader('./PurchaseOrderSearch.graphql');
const PURCHASE_ORDER_FILTER_TOTAL = loader(
  './PurchaseOrderSearchFilterTotals.graphql'
);
const PURCHASE_ORDER_DELETE = loader('../PurchaseOrderDelete.graphql');
const PURCHASE_ORDER_SEARCH_HINTS = loader(
  './PurchaseOrderSearchHints.graphql'
);
const PURCHASE_ORDER_APPROVAL_CREATE = loader(
  '../PurchaseOrderApprovalCreate.graphql'
);

const checkBoxStyle: ICheckboxStyles = {
  label: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.size10,
    color: '#a9a9a9',
  },
};

export type PurchaseOrderItem =
  PurchaseOrderSearch_purchaseOrderSearch_nodes & {
    purchaseOrderType: string | undefined;
    purchaseOrderDate: string | null;
    department: string | null | undefined;
    currency: string | null | undefined;
    status: string | undefined;
    appliedUsedTotals: string | undefined;
    appliedRemainingAmount: string | undefined;
    appliedAmount: string | undefined;
    appliedRetiredAmount: string | undefined;
    appliedOverageAmount: string | undefined;
  };

export const setPOListViewOptions = makeVar<{
  isSigning: boolean;
  isHistory: boolean;
  isSearchAll: boolean;
}>({ isSigning: true, isHistory: false, isSearchAll: true });

export const PurchaseOrderList = () => {
  const { addToast } = useToasts();
  const theme = useTheme();
  const POListViewOptions = useReactiveVar(setPOListViewOptions);
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const history = useHistory();
  const [urlParameters, setUrlParameters] = useQueryParams({
    t: StringParam,
  });
  const { t: searchParam } = urlParameters;
  const globalSearchText = useReactiveVar(setGlobalSearchText);
  const globalState = useReactiveVar(globalMode);
  const { columns } = useColumns();

  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [selectedRows, setSelectedRows] = useState<PurchaseOrderItem[]>([]);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [searchTextValue, setSearchTextValue] = useState<string | null>(
    searchParam! || null
  );
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>([]);
  const [searchHints, setSearchHints] = useState<number | null>();
  const [hintsVisibility, setHintsVisibility] = useState<boolean>(false);
  const [isAllRequestChecked, setIsAllRequestChecked] = useState(false);
  const [requestedIndices, setRequestedIndices] = useState<number[]>([]);
  const filterParams = getFilterParams(searchFilters || []);

  // STAMP PURCHASE ORDER
  const [stampTransaction, setStampTransaction] = useState(false);
  const [transactionStampField, setTransactionStampField] = useState<
    Map<string, string>
  >(new Map());

  const renderRef = useRef(false);

  const [
    purchaseOrderApprovalCreate,
    { loading: purchaseOrderApprovalCreateLoading },
  ] = useMutation<
    PurchaseOrderApprovalCreate,
    PurchaseOrderApprovalCreateVariables
  >(PURCHASE_ORDER_APPROVAL_CREATE, { errorPolicy: 'all' });

  const [deletePurchaseOrder] = useMutation<
    PurchaseOrderDelete,
    PurchaseOrderDeleteVariables
  >(PURCHASE_ORDER_DELETE, {
    errorPolicy: 'all',
  });

  const {
    loading,
    data,
    networkStatus,
    refetch,
    fetchMore,
    variables: purchaseOrderSearchVariables,
  } = useQuery<PurchaseOrderSearch, PurchaseOrderSearchVariables>(
    PURCHASE_ORDER_SEARCH,
    {
      variables: {
        first: TABLE_ROWS,
        orderBy: [
          PurchaseOrdersOrderBy.PURCHASE_ORDER_NUMBER_DESC,
          PurchaseOrdersOrderBy.PRIMARY_KEY_ASC,
        ],
        isHistorySearch: false,
        isSigningSearch: true,
        isSearchAllData: true,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const { data: purchaseOrderFilterTotal, refetch: refetchFilterTotal } =
    useQuery<
      PurchaseOrderSearchFilterTotals,
      PurchaseOrderSearchFilterTotalsVariables
    >(PURCHASE_ORDER_FILTER_TOTAL, {
      variables: {
        isSigningSearch: true,
        isHistorySearch: false,
        isLinkSearch: false,
        isSearchAllData: true,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });
  const [fetchHints, { data: hintsData }] = useLazyQuery<
    PurchaseOrderSearchHints,
    PurchaseOrderSearchHintsVariables
  >(PURCHASE_ORDER_SEARCH_HINTS, {
    variables: {
      searchRequest: {
        searchText: [searchTextValue ? searchTextValue : ''],
        searchHintId: searchHints ? searchHints : null,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const handleSearchGlobal = (
    showMore: boolean,
    newHints: boolean,
    globalValue: boolean
  ) => {
    if (!showMore) {
      setSelectedRows([]);
    }
    setIsAllRequestChecked(false);
    const filter = searchFilters?.length
      ? ({ and: toFilterVariable(searchFilters) } as PurchaseOrderFilter)
      : undefined;
    const searchRequest = searchTextValue
      ? {
          searchText: [searchTextValue ? searchTextValue : ''],
          searchHintId: searchHints,
        }
      : undefined;
    const searchQueryParam =
      searchTextValue && searchTextValue.length > 0
        ? searchTextValue
        : undefined;
    const variables: PurchaseOrderSearchVariables = {
      first: TABLE_ROWS,
      after: showMore
        ? data?.purchaseOrderSearch?.pageInfo.endCursor
        : undefined,
      orderBy: toOrderByVariable(sortOrderParam),
      filter: filter,
      searchRequest: searchRequest,
      isLinkSearch: globalValue,
      isSigningSearch: POListViewOptions.isSigning,
      isHistorySearch: POListViewOptions.isHistory,
      isSearchAllData: POListViewOptions.isSearchAll,
    };
    const totalFilterVariables: PurchaseOrderSearchFilterTotalsVariables = {
      searchRequest: searchRequest,
      isLinkSearch: globalValue,
      isSigningSearch: POListViewOptions.isSigning,
      isHistorySearch: POListViewOptions.isHistory,
      isSearchAllData: POListViewOptions.isSearchAll,
      ...filterParams,
    };
    const searchHintsRequest: SearchRequestInput = {
      searchText: [searchTextValue ? searchTextValue : ''],
    };
    const variableHints: PurchaseOrderSearchHintsVariables = {
      searchRequest: searchHintsRequest,
      isLinkSearch: globalValue,
    };
    setUrlParameters({ t: searchQueryParam }, 'replace');
    if (showMore) fetchMore?.({ variables });
    else {
      refetch(variables);
      refetchFilterTotal({
        ...totalFilterVariables,
      });
    }
    if (newHints && searchRequest && !searchHints) {
      setSearchHints(null);
      fetchHints({
        variables: variableHints!,
      });
    }
  };
  const _onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }

    const item: PurchaseOrderItem = { ...props.item };
    const _isUrgentApproval = !Boolean(item._urgencyLevel);
    const newProps: IDetailsRowProps | undefined = props
      ? {
          ...props,
          className: clsx(
            styles.rowBaseStyle,
            _isUrgentApproval ? commonStyles.urgentRow : styles.rowBaseStyle
          ),
        }
      : undefined;

    return <>{defaultRender?.(newProps)}</>;
  };
  const handleSearch = (showMore: boolean, newHints: boolean) =>
    handleSearchGlobal(showMore, newHints, globalState);

  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
    searchTextValue,
    searchHints,
    POListViewOptions,
  ]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false, true);
    else renderRef.current = true;
  }, [
    searchFilters,
    sortOrderParam,
    searchTextValue,
    searchHints,
    handleSearchMemo,
    POListViewOptions,
  ]);

  const searchHintsOptions: MultiSelectOption[] =
    hintsData?.purchaseOrderSearchHints?.nodes.map(
      (hint) =>
        ({
          key: hint.id?.toString(),
          text: `${hint.caption} (${hint.resultsCount})`,
        } as MultiSelectOption)
    ) || [];

  const HINTS_AVAILABLE =
    searchHintsOptions.length > 0 && searchTextValue?.length! > 0;

  const refetching = loading && networkStatus !== NetworkStatus.fetchMore;

  const transformedData = refetching
    ? undefined
    : data?.purchaseOrderSearch?.nodes?.map(
        (po) =>
          ({
            ...po,
            purchaseOrderType: po?.purchaseOrderType?.purchaseOrderType,
            purchaseOrderDate: po?.purchaseOrderDate
              ? dateFormat(dateConvertions(po?.purchaseOrderDate))
              : null,
            department: po?.department?.name,
            businessUnit: po?.businessUnit?.name,
            currency: po?.currency?.isoCode,
            _createdDate: po?._createdDate
              ? formatDateTime(po?._createdDate)
              : null,
            status: po?.statusType?.statusType,
            appliedUsedTotals: po._appliedTotals?.usedAmount,
            appliedRemainingAmount: po._appliedTotals?.remainingAmount,
            appliedAmount: po._appliedTotals?.appliedAmount,
            appliedRetiredAmount: po._appliedTotals?.retiredAmount,
            appliedOverageAmount: po._appliedTotals?.overageAmount,
          } as PurchaseOrderItem)
      );

  const hasNextPage = data?.purchaseOrderSearch?.pageInfo.hasNextPage;

  const { totalAmount1, totalAmount2, totalAmount3, totalCount1 } = {
    ...purchaseOrderFilterTotal?.purchaseOrderSearchFilterTotals,
  };
  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      const fieldContent =
        column?.key === 'controlTotalAmount'
          ? totalAmount1
          : column?.key === '_baseCurrencyAmount'
          ? totalAmount2
          : column?.key === '_spotCurrencyAmount'
          ? totalAmount3
          : '';

      return (
        <AmountTextView
          variant="medium"
          className={styles.footerTotal}
          value={fieldContent!}
        />
      );
    };

  const showFooter =
    !refetching &&
    parseFloat(totalAmount1 || '0.0') > 0 &&
    (totalCount1 || 0) > 0;

  const _renderItemColumn = (
    item: PurchaseOrderItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof PurchaseOrderItem
      ] as string;
      const _isUrgentApproval = !Boolean(item._urgencyLevel);
      switch (column?.key) {
        case '_urgencyLevel':
          return (
            <RedBoxIndicator
              _isUrgentApproval={_isUrgentApproval}
              itemId={item?.id}
            />
          );
        case 'purchaseOrderNumber':
          return (
            <Stack verticalAlign="center" className={styles.rowContainer}>
              <HighLightActiveLink
                key={item?.id}
                to={`/purchase-orders/purchase-order/${item?.id}`}
                highlightText={globalSearchText}
                text={fieldContent}
              />
            </Stack>
          );
        case 'controlTotalAmount':
        case '_baseCurrencyAmount':
        case '_spotCurrencyAmount':
        case 'appliedUsedTotals':
        case 'appliedRemainingAmount':
        case 'appliedAmount':
        case 'appliedRetiredAmount':
        case 'appliedOverageAmount':
          return (
            <Stack verticalAlign="center">
              <AmountColumnTextView
                value={fieldContent}
                searchText={globalSearchText!}
              />
            </Stack>
          );
        case 'status':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
              horizontal
              tokens={{ childrenGap: 10 }}
            >
              <HighlightTextView
                className={styles.statusType}
                highlightText={globalSearchText}
                text={convertToTitleCase(item?.status!)!}
              />
              <StatusIcon
                approval={false}
                iconType={item.statusType!}
                approvalData={item!}
              />
            </Stack>
          );
        case 'transactionStamp':
          if (
            !item._isAccountingEntryStampedComplete &&
            item._isAccountingEntry
          )
            return (
              <Stack
                verticalAlign="center"
                className={styles.onrenderColumnStack}
                style={{ backgroundColor: 'yellow' }}
              >
                <TextField
                  underlined
                  value={transactionStampField.get(item.id) || ''}
                  onChange={(_, newValue) => {
                    if (newValue) {
                      setTransactionStampField(
                        new Map(transactionStampField.set(item.id, newValue))
                      );
                    } else {
                      transactionStampField.delete(item.id);
                      setTransactionStampField(new Map(transactionStampField));
                    }
                  }}
                />
              </Stack>
            );
          else return null;
        default:
          return (
            <Stack verticalAlign="center" className={styles.rowContainer}>
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                />
              )}
            </Stack>
          );
      }
    }
  };

  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );
        setGridColumns(newColumns);
        setSortOrderParam({
          column: clickedColumn.key,
          direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
        });
        await refetch({
          after: null,
          orderBy: toOrderByVariable({
            column: clickedColumn.key,
            direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
          }),
        });
      }
    },
    [gridColumns, refetch]
  );

  const _onConfirm = async () => {
    const selectedData: PurchaseOrderItem[] = selectedRows;
    const deletedRows: PurchaseOrderItem[] = selectedData.filter(
      (item) => item._isDeletable
    );
    const entityDelete: EntityDeleteInput[] = selectedData
      .filter((item) => item._isDeletable)
      .map((item) => {
        return { id: item.id, rowTimestamp: item._rowTimestamp! };
      });
    const { errors } = await deletePurchaseOrder({
      variables: {
        input: {
          entityDelete,
        },
      },
      update(cache) {
        deletedRows.forEach((item) => {
          const identity = cache.identify({
            ...item,
          });
          //  DELETE CACHE REQUIRES FIX - TEMPORARY FIX MANUAL CACHE UPDATES
          cache.evict({ id: identity });
          cache.gc();
          cache.modify({
            fields: {
              purchaseOrderSearch: (
                existingData: PurchaseOrderSearch_purchaseOrderSearch
              ) => {
                const newList = existingData.nodes.filter(
                  (ix) => ix.id !== item.id
                );
                return {
                  ...existingData,
                  nodes: newList,
                };
              },
              purchaseOrderSearchFilterTotals: (
                existingData: PurchaseOrderSearchFilterTotals_purchaseOrderSearchFilterTotals
              ) => {
                if (existingData?.totalCount1 !== 0) {
                  return {
                    ...existingData,
                    totalCount1: Number(existingData.totalCount1) - 1,
                    totalAmount1: new Decimal(existingData.totalAmount1 || '0')
                      .minus(item?.controlTotalAmount || '0')
                      .toNumber(),
                    totalAmount2: new Decimal(existingData.totalAmount2 || '0')
                      .minus(item?._baseCurrencyAmount || '0')
                      .toNumber(),
                    totalAmount3: new Decimal(existingData.totalAmount3 || '0')
                      .minus(item?._spotCurrencyAmount || '0')
                      .toNumber(),
                  };
                } else {
                  return {
                    ...existingData,
                  };
                }
              },
            },
          });
        });
      },
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record Deleted Successfully', {
        appearance: 'success',
      });
    }
  };

  const dropdownStyles: Partial<IDropdownStyles> = {
    title: {
      border: 0,
      outline: 0,
      borderBottom: `1px solid ${theme.semanticColors.inputBorder}`,
    },
    dropdown: {
      width: 140,
    },
  };

  useEffect(() => {
    return () => {
      setPOListViewOptions({
        ...POListViewOptions,
        isSigning: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showRequestAll = transformedData?.some(
    (invoice) => invoice._isStagedApprovalRequest
  );

  useEffect(() => {
    if (requestedIndices.length > 0) {
      const stagedRowsLength = selectedRows.filter(
        (item) => item._isStagedApprovalRequest
      ).length;
      if (stagedRowsLength !== requestedIndices.length && isAllRequestChecked) {
        setIsAllRequestChecked(false);
      } else {
        if (requestedIndices.length === selectedRows.length) {
          setIsAllRequestChecked(true);
        } else {
          if (isAllRequestChecked) setIsAllRequestChecked(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  useEffect(() => {
    if (POListViewOptions.isHistory) {
      const newColumns = gridColumns.map((item) => {
        if (item.key === 'transactionStamp')
          return { ...item, isVisible: false };
        else return item;
      });
      setGridColumns(newColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [POListViewOptions.isHistory]);

  const rowsStampable = useMemo(() => {
    const data = transformedData?.filter((item) => {
      return !item._isAccountingEntryStampedComplete && item._isAccountingEntry;
    });
    return data;
  }, [transformedData]);

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Purchase Orders</Text>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Dropdown
              placeholder="Select"
              selectedKey={
                POListViewOptions.isSearchAll
                  ? listTypeEnum.all
                  : listTypeEnum.my
              }
              options={listTypeOptions}
              onChange={(event, option) => {
                if (event) {
                  const isSearchAll = option?.key === listTypeEnum.all;
                  setPOListViewOptions({ ...POListViewOptions, isSearchAll });
                }
              }}
              styles={dropdownStyles}
            />

            <ExportPurchaseOrder
              selectedRows={selectedRows}
              isSigning={POListViewOptions.isSigning}
            />
            {rowsStampable &&
              !POListViewOptions.isHistory &&
              !POListViewOptions.isSigning && (
                <DefaultButton
                  text="Stamp"
                  onClick={() => {
                    setStampTransaction((prevState) => !prevState);
                    expandedNav(stampTransaction);
                    const newColumns = gridColumns.map((item) => {
                      if (item.key === 'transactionStamp')
                        return { ...item, isVisible: !stampTransaction };
                      else return item;
                    });
                    setGridColumns(newColumns);
                  }}
                  iconProps={{
                    iconName: 'StampSmall',
                    styles: {
                      root: {
                        fill: theme.palette.black,
                      },
                    },
                  }}
                />
              )}

            {showRequestAll && (
              <RequestMessageModal
                entityType={EntityType.PurchaseOrder}
                action={EntityAction.Request}
                disabled={
                  !selectedRows.some(
                    (selected) => selected._isStagedApprovalRequest
                  )
                }
                visible={selectedRows.length > 0}
                multiple={{
                  validCount: selectedRows.filter(
                    (selected) => selected._isStagedApprovalRequest
                  ).length,
                  invalidNames: selectedRows
                    .filter((selected) => !selected._isStagedApprovalRequest!)
                    .map((cannotDelete) => cannotDelete.purchaseOrderNumber!),
                }}
                buttonProps={{ text: 'Request Approval' }}
                onConfirm={async (
                  requestComment: string | null,
                  requiredDate: string | null
                ) => {
                  const inputVariables: ApprovalRequestInput[] = selectedRows
                    .filter((item) => item._isStagedApprovalRequest)
                    .map((requestInput) => ({
                      entityId: requestInput.id!,
                      rowTimestamp: requestInput._rowTimestamp!,
                      comments: requestComment,
                      requiredDate: requiredDate ? requiredDate : undefined,
                    }));
                  if (inputVariables.length > 0) {
                    const { errors } = await purchaseOrderApprovalCreate({
                      variables: {
                        input: {
                          entityApproval: inputVariables,
                        },
                      },
                      awaitRefetchQueries: true,
                      refetchQueries: [
                        {
                          query: PURCHASE_ORDER_SEARCH,
                          variables: purchaseOrderSearchVariables,
                        },
                      ],
                    });
                    if (errors?.length)
                      addToast(errors[0].message, {
                        appearance: 'error',
                      });
                    else {
                      addToast('Request sent for approvals', {
                        appearance: 'success',
                      });
                      setIsAllRequestChecked(false);
                      setRequestedIndices([]);
                    }
                  }
                }}
                isLoading={purchaseOrderApprovalCreateLoading}
              />
            )}

            <ActionMessageModal
              entityType={EntityType.PurchaseOrder}
              disabled={!selectedRows.some((selected) => selected._isDeletable)}
              visible={selectedRows.length > 0}
              multiple={{
                validCount: selectedRows.filter(
                  (selected) => selected._isDeletable
                ).length,
                invalidNames: selectedRows
                  .filter((selected) => !selected._isDeletable)
                  .map((selected) => selected.purchaseOrderNumber || ''),
              }}
              onConfirm={_onConfirm}
            />
            <PrimaryButton
              onClick={() => history.push('/purchase-orders/purchase-order')}
              iconProps={{
                iconName: 'Add',
              }}
              text="New Purchase Order"
            />
          </Stack>
        </Stack>
        <POSearchBar
          urlParams={{
            searchParam: searchParam!,
          }}
          hintsAvailable={HINTS_AVAILABLE}
          columns={gridColumns}
          onToggleVisibility={(columns) => {
            setGridColumns(columns);
          }}
          onRefresh={() => {
            handleSearch(false, false);
          }}
          onFilterChange={(filters) => {
            setSearchFilters(filters);
          }}
          onEnterPress={(value, globalSearch) => {
            setSearchHints(null);
            setHintsVisibility(false);
            setSearchTextValue(value);
            setGlobalSearchText(value);
          }}
          onHintsViewToggle={() =>
            setHintsVisibility((prevState) => !prevState)
          }
          onGlobalToggle={(value) => {
            setPOListViewOptions({
              ...POListViewOptions,
              isHistory: false,
              isSigning: true,
            });
            handleSearchGlobal(false, true, value);
          }}
        />
        {hintsVisibility && HINTS_AVAILABLE && (
          <MultiSelectTags
            multiSelect={false}
            options={searchHintsOptions}
            onSelectionChange={(selctedTags) => {
              const hints = selctedTags.map((item) => parseInt(item.key));
              setSearchHints(hints[0]);
            }}
          />
        )}
        <Stack horizontal className={styles.checkboxContainer}>
          {showRequestAll && (
            <Checkbox
              boxSide="end"
              styles={checkBoxStyle}
              checked={isAllRequestChecked}
              label="Request All"
              onChange={(_, value) => {
                setIsAllRequestChecked(!isAllRequestChecked!);
                if (value) {
                  const requestedIndicesArray = transformedData?.reduce(
                    function (array: number[], element, index) {
                      if (element._isStagedApprovalRequest) array.push(index);
                      return array;
                    },
                    []
                  );
                  setRequestedIndices(requestedIndicesArray || []);
                } else {
                  setRequestedIndices([]);
                }
              }}
            />
          )}
        </Stack>
      </Stack>
      <InfiniteList
        items={transformedData || []}
        loading={loading}
        hasNextPage={hasNextPage}
        showFooter={showFooter}
        columns={gridColumns.filter((column) => column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
        onRenderRow={_onRenderRow}
        onColumnHeaderClick={_onColumnClick}
        onLoadMore={async () => await handleSearch(true, false)}
        onSelectionChanged={setSelectedRows}
        requestedIndices={requestedIndices}
        totalRowsCount={totalCount1}
      />
      <StampTransactionFooter
        isStampTransactionMode={stampTransaction}
        invoices={transformedData}
        stampFields={transactionStampField}
        onStampProcessComplete={() => {
          expandedNav(true);
          setStampTransaction(false);
          setTransactionStampField(new Map([]));
          const newColumns = gridColumns.map((item) => {
            if (item.key === 'transactionStamp')
              return { ...item, isVisible: false };
            else return item;
          });
          setGridColumns(newColumns);
        }}
      />
    </>
  );
};
