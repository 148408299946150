import { GlobalActions } from 'common/constants';
import React, { useCallback, useState } from 'react';
import { useStyles } from './index.styles';
import { PurchaseOrder_purchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import { Notes } from './Notes';
import { PivotDisplay, PivotMenuItemProps } from 'common/components/PivotDisplay';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { PurchaseOrderPropertiesCount, getUpdatedPivotActionItems, pivotActionItems } from './constants';
import { HistoryActionMenuView } from 'common/components/History';
import { Tags } from './Tags';
import { Attach } from './Attach';
import { PurchaseOrderUploadDocument_purchaseOrderUploadDocument } from './Attach/UploadForm/__generated__/PurchaseOrderUploadDocument';
import { Messages } from './Messages';
import { useFormContext } from 'react-hook-form';
import { PurchaseOrderValues } from 'purchaseOrder/view/types';
import { UserEntityMessageCounts, UserEntityMessageCountsVariables } from 'common/graphql/__generated__/UserEntityMessageCounts';
import { CarbonAccounting } from '../CarbonAccounting';
import { ProtectSection } from 'common/components/Protect';
import { PurchaseOrderCommonData_secureRowLevels } from 'purchaseOrder/view/__generated__/PurchaseOrderCommonData';
import { PurchaseOrderRowProtection, PurchaseOrderRowProtectionVariables } from './__generated__/PurchaseOrderRowProtection';
import { useToasts } from 'react-toast-notifications';
import { ListPurchaseOrderNotes, ListPurchaseOrderNotesVariables } from 'common/graphql/__generated__/ListPurchaseOrderNotes';
const LIST_PURCHASE_ORDER_NOTES = loader("../../../../common/graphql/ListPurchaseOrderNotes.graphql");
const PURCHASE_ORDER_ROW_PROTECTION = loader("./PurchaseOrderRowProtection.graphql");
const USER_ENTITY_MESSAGE_COUNTS = loader(
  '../../../../common/graphql/UserEntityMessageCounts.graphql'
);

interface ActionMenuProps {
  purchaseOrder: PurchaseOrder_purchaseOrder;
  secureRowLevels: PurchaseOrderCommonData_secureRowLevels | undefined | null;
  onRemove?: () => void;
  onUpload?: (
    fileSelected: File,
    documentData: PurchaseOrderUploadDocument_purchaseOrderUploadDocument,
    toastId: string
  ) => void;
}

export const ActionMenu: React.FC<ActionMenuProps> = ({
  purchaseOrder,
  secureRowLevels,
  onRemove,
  onUpload,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [expandMenu, setExpandMenu] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string | undefined>(
    GlobalActions.attach
  );

  const { formState: { isDirty } } = useFormContext<PurchaseOrderValues>();

  const [invoiceRowProtection, { loading: updateLoading }] = useMutation<
    PurchaseOrderRowProtection,
    PurchaseOrderRowProtectionVariables
  >(PURCHASE_ORDER_ROW_PROTECTION, { errorPolicy: 'all' });

  const {
    id,
    _isUpdatable,
    secureRowLevel,
    purchaseOrderHistoriesByEntityId,
    _isEnvironmentalsExist,
    entityEnvironmentalsByEntityId,
  } = { ...purchaseOrder }

  const { data: userMessageCounts, refetch: userMessageCountsRefetch } =
    useQuery<UserEntityMessageCounts, UserEntityMessageCountsVariables>(
      USER_ENTITY_MESSAGE_COUNTS,
      {
        variables: {
          entityId: id,
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
      }
    );

  const updateRowLevel = async (selectedLevel: string | null) => {
    const inputData =
      selectedLevel !== null
        ? {
          entityId: id,
          rowSecurityId: selectedLevel,
        }
        : {
          entityId: id,
          isProtectionRemoval: true,
        };
    const { errors } = await invoiceRowProtection({
      variables: {
        input: inputData,
      },
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Security/Protection level updated', {
        appearance: 'success',
      });
    }
  };

  const {
    data: entityNotesData,
    refetch: entityNotesListRefetch
  } =
    useQuery<
      ListPurchaseOrderNotes,
      ListPurchaseOrderNotesVariables
    >(LIST_PURCHASE_ORDER_NOTES, {
      variables: {
        id,
      },
      skip: !id,
    });

  const getEntityNotes = () => {
    const variables: ListPurchaseOrderNotesVariables = { id };
    entityNotesListRefetch(variables);
  };

  const getEntityNotesMemo = useCallback(getEntityNotes, [id]);

  const isUpdatable = isDirty ? false : (!!_isUpdatable ? true : false)

  const getSelectedSection = (key: string) => {
    switch (key) {
      case GlobalActions.protect:
        return (
          <Stack tokens={{ padding: "0px 0px 0px 20px" }}>
            <ProtectSection
              updateLoading={updateLoading}
              updateRowLevel={async (selectedLevel) => {
                updateRowLevel(selectedLevel);
              }}
              secureRowLevels={secureRowLevels?.nodes!}
              secureRowLevelId={secureRowLevel?.id}
              isUpdatable={isUpdatable}
            />
          </Stack>
        )
      case GlobalActions.notes:
        return (
          <Stack>
            <Notes
              listRefreshRequest={getEntityNotesMemo}
              id={id}
              data={entityNotesData?.purchaseOrder?.notesByEntityId.nodes!}
            />
          </Stack>
        );
      case GlobalActions.history:
        return (
          <Stack>
            <HistoryActionMenuView
              moduleName="Purchase Order"
              numberOfItems={3}
              historyData={purchaseOrderHistoriesByEntityId.nodes || []}
            />
          </Stack>
        );
      case GlobalActions.attach:
        return (
          <Stack>
            {
              purchaseOrder &&
              <Attach
                purchaseOrder={purchaseOrder}
                onUpload={onUpload}
                onRemove={onRemove}
              />
            }
          </Stack>
        );
      case GlobalActions.tags:
        return (
          <Stack>
            {
              id && <Tags purchaseOrderId={id} />
            }
          </Stack>
        )
      case GlobalActions.messages:
        return (
          <Stack>
            <Messages
              dirty={isDirty}
              onCreateMessage={userMessageCountsRefetch}
              userMessageCounts={userMessageCounts}
              purchaseOrderId={id}
            />
          </Stack>
        )
      default:
        return null;
    }
  };

  let updatedPivotActionItems: PivotMenuItemProps[] = [];
  if (purchaseOrder) {
    const {
      notesByEntityId,
      entityDocumentsByEntityId,
      purchaseOrderHistoriesByEntityId,
      entityTagsByEntityId,
    } = purchaseOrder;

    const purchaseOrderCountArray: PurchaseOrderPropertiesCount[] = [
      {
        name: 'Protect',
        count: 0,
      },
      {
        name: 'Files',
        count: entityDocumentsByEntityId?.totalCount || 0,
      },
      {
        name: 'Notes',
        count: notesByEntityId?.nodes?.length || 0,
      },
      {
        name: 'History',
        count: purchaseOrderHistoriesByEntityId?.nodes?.length || 0,
      },
      {
        name: 'Tags',
        count: entityTagsByEntityId?.nodes?.length || 0,
      },
      {
        name: 'Message',
        count: userMessageCounts?.userEntityMessageCounts?.totalMessages || 0,
      },
    ];

    updatedPivotActionItems = getUpdatedPivotActionItems(
      purchaseOrderCountArray
    );

  } else updatedPivotActionItems = pivotActionItems;

  return (
    <Stack>
      {expandMenu ?
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.pivotContainer}
            >
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey);
                }}
              >
                {updatedPivotActionItems.map((item, index) => {
                  return (
                    <PivotItem
                      key={index}
                      itemKey={item.itemKey}
                      itemIcon={item.iconName}
                      headerText={item.name}
                      itemCount={item.count}
                      onRenderItemLink={PivotCustomRender}
                    />
                  );
                })}
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
              />
            </Stack>
            <CarbonAccounting
              isEnvironmentalsExist={_isEnvironmentalsExist || false}
              refetchPO={(isDeleted) => {
                if (isDeleted) onRemove?.();
              }}
              purchaseOrderDetails={entityEnvironmentalsByEntityId}
              entityId={id}
            />
          </Stack>
          <Stack>{getSelectedSection(selectedKey!)}</Stack>
        </Stack>
        :
        <Stack
          horizontalAlign="space-between"
          horizontal
          className={styles.pivotContainer}
        >
          <Stack horizontal verticalAlign="center">
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 5 }}
            >
              {updatedPivotActionItems.map((item, index) => {
                return (
                  <PivotDisplay
                    key={index}
                    {...item}
                    onPivotClick={() => {
                      setSelectedKey(item.itemKey);
                      setExpandMenu(true);
                    }}
                  />
                );
              })}
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
            />
          </Stack>
          <CarbonAccounting
            isEnvironmentalsExist={
              _isEnvironmentalsExist || false
            }
            refetchPO={(isDeleted) => {
              if (isDeleted) onRemove?.();
            }}
            purchaseOrderDetails={entityEnvironmentalsByEntityId}
            entityId={id}
          />
        </Stack>
      }
    </Stack>
  )
}
