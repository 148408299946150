import { Stack } from '@fluentui/react';
import { FooterActionBar } from 'common/components/FooterActionBar';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { InvoiceDetails } from '../../__generated__/InvoiceDetails';
import { TransactionSigningValues } from '../../types';
import { AmendTransaction } from './Amend';
import { ReleaseReviewTransaction } from './ReleaseReview';
import { RequestApprovalTransaction } from './RequestApproval';
interface FooterProps {
  invoiceDetailsData?: InvoiceDetails;
  isNew: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  transactionTypeLoading: boolean;
  onSave: (saveAnother: boolean) => void;
}

export const Footer: React.FC<FooterProps> = ({
  isNew,
  invoiceDetailsData,
  transactionTypeLoading,
  onSave,
  isCreating,
  isUpdating,
}) => {
  const [releaseStatus, setReleaseStatus] = useState(false);
  const [requestStatus, setRequestStatus] = useState(false);
  const [amendStatus, setAmendStatus] = useState<boolean>(false);
  const {
    formState: { isDirty, isSubmitting, errors },
  } = useFormContext<TransactionSigningValues>();

  const history = useHistory();
  const isLoading =
    isUpdating || isCreating || amendStatus || requestStatus || releaseStatus;

  return (
    <>
      <FooterActionBar
        createNewText="New Transaction"
        addNewForm={() => {
          history.replace('/signing/transaction');
        }}
        disabled={
          transactionTypeLoading || {
            save: !isDirty || Object.keys(errors).length > 0,
            cancel: !isDirty,
          }
        }
        isSubmitting={isSubmitting}
        isCreate={isNew}
        onCancel={() => history.replace('/signing')}
        onSave={() => {
          onSave(false);
        }}
        onSaveAnother={async () => {
          onSave(true);
          // resetForm();
        }}
        isLoading={isLoading}
        children={
          invoiceDetailsData && (
            <Stack>
              <RequestApprovalTransaction
                invoiceDetailsData={invoiceDetailsData}
                isNew={isNew}
                isDirty={isDirty}
                onRequestStatus={setRequestStatus}
              />
              <AmendTransaction
                invoiceDetailsData={invoiceDetailsData}
                isNew={isNew}
                isDirty={isDirty}
                onAmendingStatus={setAmendStatus}
              />
              <ReleaseReviewTransaction
                invoiceDetailsData={invoiceDetailsData}
                isNew={isNew}
                isDirty={isDirty}
                onReleaseStatus={setReleaseStatus}
              />
            </Stack>
          )
        }
      />
    </>
  );
};
