import React, { useState } from 'react';
import {
  NetworkStatus,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  IColumn,
  IDetailsHeaderProps,
  IRenderFunction,
  PrimaryButton,
  SelectionMode,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { TABLE_ROWS } from 'common/constants';
import { loader } from 'graphql.macro';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import {
  DocumentPools,
  DocumentPoolsVariables,
  DocumentPools_documentPools_nodes,
} from './__generated__/DocumentPools';
import {
  EntityDeleteInput,
  DocumentPoolsOrderBy,
} from 'common/types/globalTypes';
import { toOrderByVariable } from '../utils';
import {
  DocumentPoolDelete,
  DocumentPoolDeleteVariables,
} from './__generated__/DocumentPoolDelete';
import { useToasts } from 'react-toast-notifications';
import { useCommonStyles } from 'common/styles';
import { ActiveLink } from 'common/components/ActiveRowLink';
import { useHistory } from 'react-router';
import { getColumns } from './column.data';
import { InfiniteList } from 'common/components/InfiniteList';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityType } from 'common/types/utility';
import { setUserDefaults } from 'utility/cache/ui';
const DOCUMENTPOOL = loader('./DocumentPools.graphql');
const DELETE_DOCUMENT_POOL = loader('./DeleteDocumentPool.graphql');
type DocumentPoolType = DocumentPools_documentPools_nodes;
export const DocumentsPool: React.FC = () => {
  const { addToast } = useToasts();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [selectedList, setSelectedList] = useState<DocumentPoolType[]>([]);
  const sortColumns = (columnName: string, direction: string) => {
    let orderBy = {
      column: columnName,
      direction: direction as OrderDirection,
    };
    setSortOrderParam(orderBy);
    handleSortAndPagination(orderBy);
  };

  // ONCLICK FUNCTION ON HEADER TO HANDLE SORT
  const _onColumnClick = (column: IColumn) => {
    const newColumns: IColumn[] = _columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
      }
    });
    setGridColumns(newColumns);
    let direction = 'asc';
    if (column.isSortedDescending === true) {
      direction = 'asc';
      sortColumns(column.key, direction);
    } else {
      direction = 'desc';
      sortColumns(column.key, direction);
    }
  };

  //FOR RENDERING THE COLUMN CELL VALUES IN DESIRED FORMAT.
  let _renderItemColumn = (
    item: DocumentPoolType,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    switch (column?.key) {
      case 'name':
        return (
          <ActiveLink to={`/doc/groups/group/${item.id}`}>
            {item.name}
          </ActiveLink>
        );
      case 'description':
        return <Text>{item.description && item.description}</Text>;
      case 'defaultDirectoryTypes.description':
        return <Text>{item.defaultDirectoryTypes?.description}</Text>;
      case 'defaultDocumentTypes.documentType':
        return <Text>{item.defaultDocumentTypes?.documentType}</Text>;
      case 'documentPoolTotals.availableDocuments':
        return (
          <TooltipHost
            content={`${item._documentFileCount?.selectedDocuments} Attached`}
          >
            <Text>{item._documentFileCount?.availableDocuments}</Text>
          </TooltipHost>
        );
    }
  };
  let _columns = getColumns(_onColumnClick);
  const [gridColumns, setGridColumns] = useState<IColumn[]>(_columns);

  const {
    loading: documentPoolLoading,
    data: documentPoolListData,
    fetchMore,
    refetch,
    variables,
    networkStatus,
  } = useQuery<DocumentPools, DocumentPoolsVariables>(DOCUMENTPOOL, {
    variables: {
      first: TABLE_ROWS,
      orderBy: [DocumentPoolsOrderBy.NAME_ASC],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  const handleSortAndPagination = (
    sortOrder?: SortOrder,
    showMore?: boolean
  ) => {
    const variables: DocumentPoolsVariables = {
      first: TABLE_ROWS,
      after: showMore
        ? documentPoolListData?.documentPools?.pageInfo.endCursor
        : undefined,
      orderBy: toOrderByVariable(sortOrder),
    };
    if (showMore) fetchMore({ variables });
    else refetch(variables);
  };

  const [documentPoolDelete] = useMutation<
    DocumentPoolDelete,
    DocumentPoolDeleteVariables
  >(DELETE_DOCUMENT_POOL, { errorPolicy: 'all' });

  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
    props,
    defaultRender
  ) => {
    if (!props) return null;
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        <Stack>
          {defaultRender!({
            ...props,
          })}
        </Stack>
      </Sticky>
    );
  };

  const _onConfirm = async () => {
    let deletableData: EntityDeleteInput[] = [];
    for (let data of selectedList) {
      if (data._isDeletable) {
        deletableData.push({
          id: data.id,
          rowTimestamp: data._rowTimestamp!,
        });
      }
    }

    if (deletableData.length > 0) {
      const { errors } = await documentPoolDelete({
        variables: {
          input: {
            entityDelete: deletableData,
          },
        },
        update: (cache, { data }) => {
          const deletedIds = data?.documentPoolDelete?.deletedEntities?.map(
            (entity) => entity?.id
          );
          if (deletedIds) {
            const filteredList =
              documentPoolListData?.documentPools?.nodes.filter(
                (doc) => deletedIds.indexOf(doc.id) === -1
              );
            const newData: DocumentPools = {
              documentPools: {
                pageInfo: documentPoolListData?.documentPools?.pageInfo!,
                nodes: filteredList!,
                totalCount:
                  documentPoolListData?.documentPools?.totalCount! -
                  deletedIds.length,
              },
              // currentUserProfile: documentPoolListData?.currentUserProfile!
            };
            cache.writeQuery<DocumentPools, DocumentPoolsVariables>({
              query: DOCUMENTPOOL,
              data: newData,
              variables: variables,
            });
          }
        },
      });

      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else
        addToast('Records deleted successfully', {
          appearance: 'success',
        });
    } else addToast('Cannot delete these records.', { appearance: 'error' });
  };

  return (
    <>
      <Sticky stickyPosition={StickyPositionType.Header}>
        <Stack className={commonStyles.listHeaderContainer}>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            className={commonStyles.listTitleContainer}
          >
            <Text variant="xLarge">Document Folders</Text>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              {selectedList.length > 0 && (
                <ActionMessageModal
                  entityType={EntityType.Folder}
                  disabled={
                    !selectedList.some((selected) => selected._isDeletable)
                  }
                  visible={selectedList.length > 0}
                  multiple={{
                    validCount: selectedList.filter(
                      (selected) => selected._isDeletable
                    ).length,
                    invalidNames: selectedList
                      .filter((selected) => !selected._isDeletable)
                      .map((cannotDelete) => cannotDelete.name),
                  }}
                  onConfirm={_onConfirm}
                />
              )}

              <PrimaryButton
                onClick={() => history.push('/doc/groups/group')}
                iconProps={{
                  iconName: 'Add',
                }}
                text="New Folder"
              />
            </Stack>
          </Stack>
        </Stack>
      </Sticky>

      <InfiniteList
        onRenderDetailsHeader={onRenderDetailsHeader}
        items={
          !refetching
            ? documentPoolListData?.documentPools?.nodes || []
            : undefined
        }
        loading={documentPoolLoading}
        hasNextPage={
          documentPoolListData?.documentPools?.pageInfo?.hasNextPage!
        }
        selectionMode={SelectionMode.multiple}
        columns={gridColumns}
        onRenderItemColumn={_renderItemColumn}
        onLoadMore={() => handleSortAndPagination(sortOrderParam, true)}
        onSelectionChanged={setSelectedList}
      />
    </>
  );
};
