import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { AnimationClassNames, Stack } from '@fluentui/react';
import { PurchaseOrderInvoiceScheduleInput, PurchaseOrderInvoiceScheduleUpdateInput } from 'common/types/globalTypes';
import { dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import React, { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { setUserDefaults } from 'utility/cache/ui';
import { InvoiceSchedulesItem } from '../ScheduleList';
import { Accounting } from './Accounting';
import { BasicForm } from './BasicForm';
import { Footer } from './Footer';
import { PurchaseOrderInvoiceSchedule, PurchaseOrderInvoiceScheduleVariables } from './__generated__/PurchaseOrderInvoiceSchedule';
import { PurchaseOrderInvoiceScheduleCreate, PurchaseOrderInvoiceScheduleCreateVariables } from './__generated__/PurchaseOrderInvoiceScheduleCreate';
import { PurchaseOrderInvoiceScheduleUpdate, PurchaseOrderInvoiceScheduleUpdateVariables } from './__generated__/PurchaseOrderInvoiceScheduleUpdate';
import { PurchaseOrderInvoiceScheduleValues } from './interface';
import { getDefaultValues, getNewDistributions, getRemovedDistributions, getUpdatedDistributions } from './utils';
const PURCHASE_ORDER_INVOICE_SCHEDULE_CREATE = loader("./PurchaseOrderInvoiceScheduleCreate.graphql");
const PURCHASE_ORDER_INVOICE_SCHEDULE_UPDATE = loader("./PurchaseOrderInvoiceScheduleUpdate.graphql");
const PURCHASE_ORDER_INVOICE_SCHEDULE = loader("./PurchaseOrderInvoiceSchedule.graphql");

const SlideLeftIn = AnimationClassNames.slideLeftIn400;
const slideRightOut = AnimationClassNames.slideRightOut400;

interface InvoiceScheduleViewProps {
  purchaseOrderData: PurchaseOrder | undefined;
  invoiceSchedule: InvoiceSchedulesItem | undefined;
  listMode: boolean;
  isEdit?: boolean;
  onBack: () => void;
}

export const InvoiceScheduleView: React.FC<InvoiceScheduleViewProps> = ({
  purchaseOrderData,
  invoiceSchedule,
  listMode,
  isEdit,
  onBack,
}) => {

  const { addToast } = useToasts();
  const userDefaults = useReactiveVar(setUserDefaults);
  const fetched = useRef<boolean>(false);

  const formMethods = useForm<PurchaseOrderInvoiceScheduleValues>({ mode: 'all' });

  const {
    data: invoiceScheduleData,
  } = useQuery<PurchaseOrderInvoiceSchedule, PurchaseOrderInvoiceScheduleVariables>(
    PURCHASE_ORDER_INVOICE_SCHEDULE,
    {
      variables: {
        id: invoiceSchedule?.id!
      },
      skip: !invoiceSchedule?.id && !isEdit,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  )

  const { _isUpdatable } = { ...invoiceScheduleData?.purchaseOrderInvoiceSchedule }

  const [createPurchaseOrderInvoiceSchedule, { loading: createPurchaseOrderLoadingInvoiceSchedule }] =
    useMutation<PurchaseOrderInvoiceScheduleCreate, PurchaseOrderInvoiceScheduleCreateVariables>(
      PURCHASE_ORDER_INVOICE_SCHEDULE_CREATE,
      { errorPolicy: 'all' }
    );

  const [updatePurchaseOrderInvoiceSchedule, { loading: updatePurchaseOrderInvoiceScheduleLoading }] =
    useMutation<PurchaseOrderInvoiceScheduleUpdate, PurchaseOrderInvoiceScheduleUpdateVariables>(
      PURCHASE_ORDER_INVOICE_SCHEDULE_UPDATE,
      { errorPolicy: 'all' }
    );

  const {
    handleSubmit,
    trigger,
    reset,
    formState: {
      isDirty
    }
  } = { ...formMethods };

  const onHandleSubmit = async (values: PurchaseOrderInvoiceScheduleValues) => {
    const {
      invoiceSequence,
      scheduledDate,
      scheduledNote,
      scheduledAmount,
    } = { ...values }
    if (!isEdit) {
      const { data, errors } = await createPurchaseOrderInvoiceSchedule({
        variables: {
          input: {
            purchaseOrderId: purchaseOrderData?.purchaseOrder?.id!,
            purchaseOrderInvoiceSchedule: {
              scheduledNote,
              scheduledAmount,
              invoiceSequence: invoiceSequence ? parseInt(invoiceSequence.toString()) : null,
              scheduledDate: scheduledDate ? dateFormat(scheduledDate) : null,
            }
          },
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      }
      if (data?.purchaseOrderInvoiceScheduleCreate?.purchaseOrderInvoiceSchedule) {
        addToast('Invoice schedule added successfully.', {
          appearance: 'success',
        });
        onBack();
      }
    } else {
      const defaultValues = getDefaultValues({
        isNew: false,
        invoiceSchedule: invoiceScheduleData?.purchaseOrderInvoiceSchedule!,
        isScheduleUpdatable: true
      });
      const newDistributions = getNewDistributions(values, userDefaults)?.map(ele => {
        const { _isUpdatable, _isDeletable, ...purchaseOrderItemDistribution } = { ...ele }
        return purchaseOrderItemDistribution
      })
      const updatedDistributions = getUpdatedDistributions(values, defaultValues);
      const deletedDistributions = getRemovedDistributions(invoiceScheduleData?.purchaseOrderInvoiceSchedule!, values);

      const requestPayload: PurchaseOrderInvoiceScheduleInput = {
        scheduledNote,
        scheduledAmount,
        invoiceSequence: invoiceSequence ? parseInt(invoiceSequence.toString()) : null,
        scheduledDate: scheduledDate ? dateFormat(scheduledDate) : null,
      }

      const { errors } = await updatePurchaseOrderInvoiceSchedule({
        variables: {
          input: {
            poInvoiceScheduleId: invoiceSchedule?.id!,
            poInvoiceScheduleRowTimestamp: invoiceSchedule?._rowTimestamp!,
            purchaseOrderInvoiceSchedule: requestPayload,
            purchaseOrderInvoiceScheduleDistributionCreate: newDistributions,
            purchaseOrderInvoiceScheduleDistributionUpdate: updatedDistributions,
            purchaseOrderInvoiceScheduleDistributionDelete: deletedDistributions
          } as PurchaseOrderInvoiceScheduleUpdateInput,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: PURCHASE_ORDER_INVOICE_SCHEDULE,
            variables: {
              id: invoiceSchedule?.id!,
            },
          },
        ],
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('Invoice schedule edited successfully.', {
          appearance: 'success',
        });
      }
    }
  }

  const { _isInvoiceScheduleUpdatable } = { ...purchaseOrderData?.purchaseOrder }
  const isLoading = createPurchaseOrderLoadingInvoiceSchedule || updatePurchaseOrderInvoiceScheduleLoading;
  const buttonText = isEdit ? "Save" : "Create";
  const isScheduleUpdatable = isEdit ? (_isInvoiceScheduleUpdatable ? (_isUpdatable ? true : false) : false) : true;

  useEffect(() => {
    if (
      invoiceScheduleData?.purchaseOrderInvoiceSchedule &&
      !fetched.current
    ) {
      const defaultValues = getDefaultValues({
        isNew: !isEdit,
        userDefaults,
        invoiceSchedule: invoiceScheduleData.purchaseOrderInvoiceSchedule,
        isScheduleUpdatable
      });
      reset(defaultValues);
      trigger();
      fetched.current = true;
    } else {
      const defaultValues = getDefaultValues({
        isNew: !isEdit,
        userDefaults,
        invoiceSchedule: invoiceScheduleData?.purchaseOrderInvoiceSchedule!,
        isScheduleUpdatable
      });
      reset(defaultValues);
      trigger();
    }
  }, [
    userDefaults,
    invoiceScheduleData,
    isEdit,
    reset,
    trigger,
    isScheduleUpdatable
  ]);

  return (
    <FormProvider {...formMethods}>
      <Stack
        style={{
          paddingBottom: 100,
          paddingTop: 80,
        }}
      >
        <Stack
          className={!listMode ? SlideLeftIn : slideRightOut}
        >
          <BasicForm
            disableInputs={!isScheduleUpdatable}
          />
          {
            isEdit &&
            <Accounting
              purchaseOrder={purchaseOrderData}
              isUpdatable={isScheduleUpdatable}
            />
          }
        </Stack>
        <Footer
          buttonText={buttonText}
          disabled={!isDirty || isLoading || !_isInvoiceScheduleUpdatable}
          isLoading={isLoading}
          onSubmit={handleSubmit(onHandleSubmit)}
          onCancel={onBack}
        />
      </Stack>
    </FormProvider>

  )
}
