import { useApolloClient } from '@apollo/client';
import { Separator, Stack } from '@fluentui/react';

import { InvoiceUploadDocument_invoiceUploadDocument } from 'ap/signing/transactionSigning/view/FormView/ActionMenu/actionMenuContents/Attach/UploadForm/__generated__/InvoiceUploadDocument';
import {
  InvoiceDetails,
  InvoiceDetailsVariables,
} from 'ap/signing/transactionSigning/view/__generated__/InvoiceDetails';
import { DocumentList } from 'common/components/DocumentList';
import { VIEW_DOC_MODAL_WIDTH } from 'common/components/DocumentList/DocumentViewModal';
import { secureRowLevelNode } from 'common/components/SecureRowLevel';
import { getWindowDimensions } from 'common/utils/columnUtilities';
import { loader } from 'graphql.macro';
import React from 'react';
import { AccountingEntryInvoice_invoice } from '../../../__generated__/AccountingEntryInvoice';
import { AttachForm } from './AttachForm';
import { UploadForm } from './UploadForm';
import { columns } from './column.data';
import { useStyles } from './index.styles';
const INVOICE_DETAILS = loader('../../../AccountingEntryInvoice.graphql');

interface AttachSectionProps {
  secureRowLevel: secureRowLevelNode[];
  // invoice: AccountingEntryInvoice | undefined;
  invoice: AccountingEntryInvoice_invoice;
  onUpload?: (
    fileSelected: File,
    documentData: InvoiceUploadDocument_invoiceUploadDocument,
    toastId: string
  ) => void;
}
export const AttachSection: React.FC<AttachSectionProps> = ({
  secureRowLevel,
  invoice,
  onUpload,
}) => {
  const styles = useStyles();
  const client = useApolloClient();

  const { width } = getWindowDimensions();
  const documentViewPosition = width
    ? width - (1100 + VIEW_DOC_MODAL_WIDTH)
    : 50;
  return (
    <Stack tokens={{ childrenGap: 10 }} className={styles.mainStack}>
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{ childrenGap: 10 }}
      >
        <AttachForm invoice={invoice} />
        <Separator vertical />
        {/* <UploadForm invoice={invoice} onUpload={onUpload} /> */}
        <UploadForm invoice={invoice} onUpload={onUpload} />
      </Stack>
      {/* {invoice?.invoice?.entityDocumentsByEntityId.nodes.length! > 0 && ( */}
      {!!invoice.entityDocumentsByEntityId.nodes.length && (
        <Stack tokens={{ childrenGap: 10, padding: 10 }}>
          <DocumentList
            paddingLeft={documentViewPosition}
            isNew={false}
            columns={columns}
            // data={invoice?.invoice?.entityDocumentsByEntityId?.nodes || []}
            data={invoice.entityDocumentsByEntityId.nodes || []}
            isDeleteButtonVisible
            isShareAndProtectButtonsVisible
            secureRowLevel={secureRowLevel}
            onRemoveRow={(data) => {
              const cacheData = client.readQuery<
                InvoiceDetails,
                InvoiceDetailsVariables
              >({
                query: INVOICE_DETAILS,
                variables: {
                  // id: invoice?.invoice?.id!,
                  id: invoice.id,
                },
              });
              const filteredList =
                cacheData?.invoice?.entityDocumentsByEntityId.nodes.filter(
                  (ele) => ele.id !== data.id
                );
              const updatedData: InvoiceDetails = {
                invoice: {
                  ...cacheData?.invoice!,
                  _isApprovalDocumentsRequired:
                    data.invoiceDocument?._isApprovalDocumentsRequired!,
                  _requiredApprovalDocuments:
                    data.invoiceDocument?._requiredApprovalDocuments!,
                  _isStagedApprovalRequest:
                    data.invoiceDocument?._isStagedApprovalRequest!,
                  entityDocumentsByEntityId: {
                    ...cacheData?.invoice?.entityDocumentsByEntityId,
                    nodes: filteredList!,
                  },
                },
              };
              client.writeQuery<InvoiceDetails, InvoiceDetailsVariables>({
                query: INVOICE_DETAILS,
                data: updatedData,
                variables: {
                  // id: invoice?.invoice?.id!,
                  id: invoice.id,
                },
              });
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};
