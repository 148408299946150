import { ColumnActionsMode, IColumn } from '@fluentui/react';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import { TransactionLayout } from 'common/types/globalTypes';
export type ColumnData = IColumn & {
  /** property to determine whether this coloumn can be toggled for visibility */
  toggleVisibility?: boolean;
  /** property to determine whether the column is visble or not*/
  isVisible?: boolean;
  isFormField?: boolean;
  viewWidth?: number;
  isTransactionOnly?: boolean;
};

const columns = new Map<string, ColumnData>([
  [
    'delete',
    {
      key: 'delete',
      name: '',
      fieldName: 'delete',
      minWidth: 40,
      maxWidth: 40,
      columnActionsMode: ColumnActionsMode.disabled,
      isFormField: false,
    },
  ],
  [
    'referenceCode6',
    {
      key: 'referenceCode6',
      name: 'CO',
      fieldName: 'referenceCode6',
      minWidth: 50,
      maxWidth: 50,
      viewWidth: 100,
    },
  ],

  [
    'referenceCode7',
    {
      key: 'referenceCode7',
      name: 'LOC',
      fieldName: 'referenceCode7',
      minWidth: 50,
      maxWidth: 50,
      viewWidth: 100,
    },
  ],
  [
    'accountReference',
    {
      key: 'accountReference',
      name: 'Account',
      fieldName: 'accountReference',
      minWidth: 110,
      maxWidth: 110,
      viewWidth: 100,
    },
  ],

  [
    'projectReference',
    {
      key: 'projectReference',
      name: 'Project',
      fieldName: 'projectReference',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 80,
    },
  ],
  [
    'setReference',
    {
      key: 'setReference',
      name: 'Set',
      fieldName: 'setReference',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 80,
    },
  ],
  [
    'additionalDescription',
    {
      key: 'additionalDescription',
      name: 'Item Description',
      fieldName: 'additionalDescription',
      minWidth: 230,
      maxWidth: 230,
      viewWidth: 230,
    },
  ],
  [
    'distributionAmount',
    {
      key: 'distributionAmount',
      name: 'Amount',
      fieldName: 'distributionAmount',
      minWidth: 110,
      maxWidth: 110,
      viewWidth: 110,
    },
  ],
  // always keep purchaseOrderReference column's width equal to component PoReferenceModal for proper alignment
  [
    'purchaseOrderReference',
    {
      key: 'purchaseOrderReference',
      name: 'PO Reference',
      fieldName: 'purchaseOrderReference',
      minWidth: 110,
      maxWidth: 110,
      viewWidth: 110,
      isTransactionOnly: true,
    },
  ],
  [
    'referenceCode5',
    {
      key: 'referenceCode5',
      name: 'Ins',
      fieldName: 'referenceCode5',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode1',
    {
      key: 'referenceCode1',
      name: 'FF1',
      fieldName: 'referenceCode1',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode2',
    {
      key: 'referenceCode2',
      name: 'FF2',
      fieldName: 'referenceCode2',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode3',
    {
      key: 'referenceCode3',
      name: 'FF3',
      fieldName: 'referenceCode3',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode4',
    {
      key: 'referenceCode4',
      name: 'FF4',
      fieldName: 'referenceCode4',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'productionIncentiveId',
    {
      key: 'productionIncentiveId',
      name: 'Production Incentives',
      fieldName: 'productionIncentiveId',
      minWidth: 140,
      maxWidth: 140,
      viewWidth: 180,
    },
  ],
  [
    'tax1099T4TypeId',
    {
      key: 'tax1099T4TypeId',
      name: '1099/T4',
      fieldName: 'tax1099T4TypeId',
      minWidth: 160,
      maxWidth: 160,
      viewWidth: 190,
    },
  ],
  [
    'departmentId',
    {
      key: 'departmentId',
      name: 'Additional Department',
      fieldName: 'departmentId',
      minWidth: 150,
      maxWidth: 150,
      viewWidth: 170,
    },
  ],
  [
    'businessUnitId',
    {
      key: 'businessUnitId',
      name: 'Additional Business Unit',
      fieldName: 'businessUnitId',
      minWidth: 174,
      maxWidth: 174,
      viewWidth: 175,
    },
  ],
]);

const columnsGL = new Map<string, ColumnData>([
  [
    'delete',
    {
      key: 'delete',
      name: '',
      fieldName: 'delete',
      minWidth: 40,
      maxWidth: 40,
      columnActionsMode: ColumnActionsMode.disabled,
      isFormField: false,
    },
  ],
  [
    'referenceCode6',
    {
      key: 'referenceCode6',
      name: 'CO',
      fieldName: 'referenceCode6',
      minWidth: 50,
      maxWidth: 50,
      viewWidth: 100,
    },
  ],

  [
    'referenceCode7',
    {
      key: 'referenceCode7',
      name: 'LOC',
      fieldName: 'referenceCode7',
      minWidth: 50,
      maxWidth: 50,
      viewWidth: 100,
    },
  ],

  [
    'projectReference',
    {
      key: 'projectReference',
      name: 'Project',
      fieldName: 'projectReference',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 80,
    },
  ],
  [
    'accountReference',
    {
      key: 'accountReference',
      name: 'Account',
      fieldName: 'accountReference',
      minWidth: 110,
      maxWidth: 110,
      viewWidth: 100,
    },
  ],

  [
    'setReference',
    {
      key: 'setReference',
      name: 'Set',
      fieldName: 'setReference',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 80,
    },
  ],
  [
    'referenceCode1',
    {
      key: 'referenceCode1',
      name: 'FF1',
      fieldName: 'referenceCode1',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 90,
    },
  ],
  [
    'referenceCode2',
    {
      key: 'referenceCode2',
      name: 'FF2',
      fieldName: 'referenceCode2',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 90,
    },
  ],
  [
    'referenceCode3',
    {
      key: 'referenceCode3',
      name: 'FF3',
      fieldName: 'referenceCode3',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode4',
    {
      key: 'referenceCode4',
      name: 'FF4',
      fieldName: 'referenceCode4',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode5',
    {
      key: 'referenceCode5',
      name: 'Ins',
      fieldName: 'referenceCode5',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 100,
    },
  ],
  [
    'distributionAmount',
    {
      key: 'distributionAmount',
      name: 'Amount',
      fieldName: 'distributionAmount',
      minWidth: 110,
      maxWidth: 110,
      viewWidth: 100,
    },
  ],
  // always keep purchaseOrderReference column's width equal to component PoReferenceModal for proper alignment

  [
    'purchaseOrderReference',
    {
      key: 'purchaseOrderReference',
      name: 'PO Reference',
      fieldName: 'purchaseOrderReference',
      minWidth: 110,
      maxWidth: 110,
      viewWidth: 110,
      isTransactionOnly: true,
    },
  ],
  [
    'additionalDescription',
    {
      key: 'additionalDescription',
      name: 'Item Description',
      fieldName: 'additionalDescription',
      minWidth: 230,
      maxWidth: 230,
      viewWidth: 230,
    },
  ],
  [
    'productionIncentiveId',
    {
      key: 'productionIncentiveId',
      name: 'Production Incentives',
      fieldName: 'productionIncentiveId',
      minWidth: 140,
      maxWidth: 140,
      viewWidth: 180,
    },
  ],
  [
    'tax1099T4TypeId',
    {
      key: 'tax1099T4TypeId',
      name: '1099/T4',
      fieldName: 'tax1099T4TypeId',
      minWidth: 160,
      maxWidth: 160,
      viewWidth: 190,
    },
  ],
  [
    'departmentId',
    {
      key: 'departmentId',
      name: 'Additional Department',
      fieldName: 'departmentId',
      minWidth: 150,
      maxWidth: 150,
      viewWidth: 170,
    },
  ],
  [
    'businessUnitId',
    {
      key: 'businessUnitId',
      name: 'Additional Business Unit',
      fieldName: 'businessUnitId',
      minWidth: 174,
      maxWidth: 174,
      viewWidth: 175,
    },
  ],
]);

const columnsPSL = new Map<string, ColumnData>([
  [
    'delete',
    {
      key: 'delete',
      name: '',
      fieldName: 'delete',
      minWidth: 40,
      maxWidth: 40,
      columnActionsMode: ColumnActionsMode.disabled,
      isFormField: false,
    },
  ],
  // always keep purchaseOrderReference column's width equal to component PoReferenceModal for proper alignment

  [
    'purchaseOrderReference',
    {
      key: 'purchaseOrderReference',
      name: 'PO Reference',
      fieldName: 'purchaseOrderReference',
      minWidth: 110,
      maxWidth: 110,
      viewWidth: 110,
      isTransactionOnly: true,
    },
  ],
  [
    'accountReference',
    {
      key: 'accountReference',
      name: 'Account',
      fieldName: 'accountReference',
      minWidth: 110,
      maxWidth: 110,
      viewWidth: 100,
    },
  ],
  [
    'projectReference',
    {
      key: 'projectReference',
      name: 'Episode',
      fieldName: 'projectReference',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 80,
    },
  ],
  [
    'referenceCode7',
    {
      key: 'referenceCode7',
      name: 'LOC',
      fieldName: 'referenceCode7',
      minWidth: 50,
      maxWidth: 50,
      viewWidth: 100,
    },
  ],
  [
    'setReference',
    {
      key: 'setReference',
      name: 'Set',
      fieldName: 'setReference',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 80,
    },
  ],
  [
    'referenceCode5',
    {
      key: 'referenceCode5',
      name: 'Ins',
      fieldName: 'referenceCode5',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode1',
    {
      key: 'referenceCode1',
      name: 'FF1',
      fieldName: 'referenceCode1',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode2',
    {
      key: 'referenceCode2',
      name: 'FF2',
      fieldName: 'referenceCode2',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode3',
    {
      key: 'referenceCode3',
      name: 'FF3',
      fieldName: 'referenceCode3',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode4',
    {
      key: 'referenceCode4',
      name: 'FF4',
      fieldName: 'referenceCode4',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'additionalDescription',
    {
      key: 'additionalDescription',
      name: 'Item Description',
      fieldName: 'additionalDescription',
      minWidth: 230,
      maxWidth: 230,
      viewWidth: 230,
    },
  ],
  [
    'distributionAmount',
    {
      key: 'distributionAmount',
      name: 'Amount',
      fieldName: 'distributionAmount',
      minWidth: 110,
      maxWidth: 110,
      viewWidth: 100,
    },
  ],
  [
    'productionIncentiveId',
    {
      key: 'productionIncentiveId',
      name: 'Production Incentives',
      fieldName: 'productionIncentiveId',
      minWidth: 140,
      maxWidth: 140,
      viewWidth: 180,
    },
  ],
  [
    'tax1099T4TypeId',
    {
      key: 'tax1099T4TypeId',
      name: '1099/T4',
      fieldName: 'tax1099T4TypeId',
      minWidth: 160,
      maxWidth: 160,
      viewWidth: 190,
    },
  ],
  [
    'departmentId',
    {
      key: 'departmentId',
      name: 'Additional Department',
      fieldName: 'departmentId',
      minWidth: 150,
      maxWidth: 150,
      viewWidth: 170,
    },
  ],
  [
    'businessUnitId',
    {
      key: 'businessUnitId',
      name: 'Additional Business Unit',
      fieldName: 'businessUnitId',
      minWidth: 174,
      maxWidth: 174,
      viewWidth: 175,
    },
  ],
]);

export const getColumns = (
  data: UserDefaults_userDefaults_nodes | undefined
) => {
  const { layoutType } = {
    ...data?.distributionLayoutType,
  };

  let columnArray = [];
  let existingData;
  switch (layoutType) {
    case TransactionLayout.DEFAULT_DISTRIBUTION_LAYOUT:
      columns.delete('projectReference');
      columnArray = Array.from(columns, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.GL_LOC_EPISODE_DISTRIBUTION_LAYOUT:
      columnsGL.delete('referenceCode6');
      existingData = columnsGL.get('projectReference');
      columnsGL.set('projectReference', {
        ...existingData,
        name: 'Episode',
      } as ColumnData);
      columnArray = Array.from(columnsGL, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.GL_LOC_DISTRIBUTION_LAYOUT:
      columnsGL.delete('referenceCode6');
      columnsGL.delete('projectReference');
      columnArray = Array.from(columnsGL, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.EPISODIC_DISTRIBUTION_LAYOUT:
      existingData = columns.get('projectReference');
      columns.set('projectReference', {
        ...existingData,
        name: 'Episode',
      } as ColumnData);
      columnArray = Array.from(columns, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.GL_CO_LOC_EPISODE_DISTRIBUTION_LAYOUT:
      existingData = columnsGL.get('projectReference');
      columnsGL.set('projectReference', {
        ...existingData,
        name: 'Episode',
      } as ColumnData);
      columnArray = Array.from(columnsGL, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.GL_CO_LOC_DISTRIBUTION_LAYOUT:
      columnsGL.delete('projectReference');
      columnArray = Array.from(columnsGL, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.PSL_ENTRY_EPISODIC:
      columnArray = Array.from(columnsPSL, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.PSL_ENTRY_STANDARD:
      columnsPSL.delete('projectReference');
      columnArray = Array.from(columnsPSL, (data) => data[1]);
      return { columnArray };
    default:
      columns.delete('referenceCode6');
      columns.delete('referenceCode7');
      columnArray = Array.from(columns, (data) => data[1]);
      return { columnArray };
  }
};
