import { NetworkStatus, useQuery } from '@apollo/client';
import {
  ActionButton,
  IconButton,
  Stack,
  Text,
  TooltipHost
} from '@fluentui/react';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { AttachDocumentModal } from 'common/components/AttachDocumentModal';
import { DocumentDataCallout } from 'common/components/AttachDocumentModal/DocumentDataCallout';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { DISABLED_FILE_FORMATS, TABLE_ROWS } from 'common/constants';
import { EntityDocumentFilter } from 'common/types/globalTypes';
import { SortOrder } from 'common/utils/commonTypes';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useCallback, useMemo, useState } from 'react';
import {
  PaymentAvailableDocumentTypes,
  PaymentAvailableDocumentTypesVariables,
} from '../../__generated__/PaymentAvailableDocumentTypes';
import {
  AttachablePaymentDocuments,
  AttachablePaymentDocumentsVariables,
  AttachablePaymentDocuments_attachablePaymentDocuments_nodes,
} from './__generated__/AttachablePaymentDocuments';
import { _columns } from './columns.data';
import { useStyles } from './index.styles';
import { toOrderByVariable } from './utils';

const ATTACHABLE_PAYEMENT_DOCUMENTS = loader(
  './AttachablePaymentDocuments.graphql'
);
const PAYMENT_AVAILABLE_DOCUMENT_TYPES = loader(
  '../../PaymentAvailableDocumentTypes.graphql'
);

export type AttachableDataType =
  AttachablePaymentDocuments_attachablePaymentDocuments_nodes;
export interface AttachedDocumentsDataType {
  documentTypeId: number | null | undefined;
  entityDocumentId: string[] | null;
  attachedDocuments: AttachableDataType[];
}
interface PaymentDocumentsProps {
  transactionTypeId: number;
  payCycleId: string;
  paymentId?: string;
  isOpen?: boolean;
  dismissModal?: (param: boolean) => void;
  onDocumentsAttached: (
    attachedDocumentsData: AttachedDocumentsDataType
  ) => void;
  prevSelectedDocuments?: AttachableDataType[];
}
export const PaymentDocuments: React.FC<PaymentDocumentsProps> = ({
  transactionTypeId,
  payCycleId,
  paymentId,
  onDocumentsAttached,
  prevSelectedDocuments,
}) => {
  const styles = useStyles();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [documentTypeId, setDocumentTypeId] = useState<
    number | null | undefined
  >();
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });
  const [selectedList, setSelectedList] = useState<AttachableDataType[]>([]);

  const {
    data: documentsList,
    loading: documentsLoading,
    variables: documentsVariables,
    networkStatus,
    fetchMore,
    refetch,
  } = useQuery<AttachablePaymentDocuments, AttachablePaymentDocumentsVariables>(
    ATTACHABLE_PAYEMENT_DOCUMENTS,
    {
      variables: {
        payCycleId: payCycleId,
        paymentId: paymentId,
        first: TABLE_ROWS,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const { data: paymentAvailableDocumentTypesData } = useQuery<
    PaymentAvailableDocumentTypes,
    PaymentAvailableDocumentTypesVariables
  >(PAYMENT_AVAILABLE_DOCUMENT_TYPES, {
    variables: {
      transactionTypeId: transactionTypeId,
    },
  });

  const documentTypeOptions =
    paymentAvailableDocumentTypesData?.paymentAvailableDocumentTypes?.nodes.map(
      (doctype) => ({
        key: doctype.id,
        text: doctype.documentType || '',
      })
    ) || [];

  const { pageInfo } = {
    ...documentsList?.attachablePaymentDocuments,
  };

  const reload = useCallback(
    async (sort?: SortOrder) =>
      await refetch({ after: null, orderBy: toOrderByVariable(sort) }),
    [refetch]
  );

  const loadMore = useCallback(
    async () =>
      await fetchMore({
        variables: {
          ...documentsVariables,
          after: documentsList?.attachablePaymentDocuments?.pageInfo.endCursor,
        },
      }),
    [fetchMore, documentsVariables, documentsList]
  );

  const onDocumentTypeReload = useCallback(
    async (documentTypeId: number | null) => {
      setDocumentTypeId(documentTypeId);
      await refetch({ ...documentsVariables, documentTypeId });
    },
    [refetch, documentsVariables]
  );

  const transformedData = useMemo(() => {
    const documents = documentsList?.attachablePaymentDocuments?.nodes.map(
      (documents) =>
      ({
        ...documents,
        available: documents.documentAppliedAmounts?.remainingTotal,
        isoCode: documents.currency?.isoCode,
      } as AttachableDataType)
    );
    if (prevSelectedDocuments?.length && documents?.length) {
      const filteredArray = documents.filter(
        ({ id: id1 }) =>
          !prevSelectedDocuments.some(({ id: id2 }) => id2 === id1)
      );
      return filteredArray;
    }
    return documents;
  }, [documentsList, prevSelectedDocuments]);

  const onRenderItemColumn = useCallback(
    (item?: AttachableDataType, _index?: number, column?: IColumn) => {
      if (!item || !column) return undefined;

      const fieldContent = item[
        column.fieldName as keyof AttachableDataType
      ] as string | null;
      const viewDocumentVisible =
        item._isProtected! || DISABLED_FILE_FORMATS.includes(item._fileType!);
      switch (column.key) {
        case 'fileReference':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <DocumentDataCallout item={item} />
            </Stack>
          );
        case 'documentType':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text>{item.documentTypes?.documentType}</Text>
            </Stack>
          );

        case 'isoCode':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text>{item.currency?.isoCode}</Text>
            </Stack>
          );

        case 'indexTransactionDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              {item.indexTransactionDate && (
                <Text>
                  {dateFormat(dateConvertions(item.indexTransactionDate))}
                </Text>
              )}
            </Stack>
          );

        case '_uploadDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text>{getGlobalDateFormat(item._uploadDate!)}</Text>
            </Stack>
          );
        case 'indexAmount':
          return (
            <AmountTextView
              className={clsx(styles.contentColumnAlignRight)}
              value={item.indexAmount}
            />
          );

        case 'action':
          return (
            <Stack
              className={styles.columnHeight}
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );

        default:
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    },
    [styles]
  );

  const onFiltersReload = useCallback(
    async (filter: EntityDocumentFilter | undefined) =>
      await refetch({ ...documentsVariables, filter }),
    [documentsVariables, refetch]
  );

  const _onAttach = async () => {
    const selectedDocumentsArray = selectedList;
    if (selectedDocumentsArray.length > 0) {
      const documentsIDs: string[] = selectedDocumentsArray.map(
        (ele) => ele.id
      );
      onDocumentsAttached({
        documentTypeId: documentTypeId,
        entityDocumentId: documentsIDs,
        attachedDocuments: selectedDocumentsArray,
      });
    }
    setDialogVisible(false);
    setSelectedList([]);
  };

  const attachDisabled = selectedList.length === 0;

  return (
    <Stack>
      <Stack horizontal horizontalAlign="end">
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Documents"
            checked={true}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachDocumentModal
          columns={_columns}
          modalWidth={1300}
          loading={documentsLoading}
          items={
            networkStatus === NetworkStatus.refetch ||
              networkStatus === NetworkStatus.setVariables
              ? undefined
              : transformedData
          }
          hasNextPage={pageInfo?.hasNextPage}
          attachLoading={false}
          setOpen={setDialogVisible}
          availableDocumentTypes={documentTypeOptions}
          onSortReload={reload}
          onLoadMore={loadMore}
          onFiltersReload={onFiltersReload}
          onDocumentTypeChange={onDocumentTypeReload}
          attachDisabled={attachDisabled}
          onSelectionChanged={setSelectedList}
          dropdownDisabled={selectedList.length > 0}
          onRenderItemColumn={onRenderItemColumn}
          onAttachDocuments={_onAttach}
          isDraggable
        />
      )}
      <DocumentViewModal
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </Stack>
  );
};
