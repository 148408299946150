import { makeVar } from '@apollo/client';
import { CurrentUserProfile } from 'common/graphql/__generated__/CurrentUserProfile';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import { storedDarkMode } from 'utility/localStorage';

export const darkMode = makeVar<boolean>(storedDarkMode());
export const expandedNav = makeVar<boolean>(true);
export const globalMode = makeVar<boolean>(false);
export const highlightTextMode = makeVar<boolean>(true);
export const setGlobalSearchText = makeVar<string>('');
export const setUserPrivileges = makeVar<Set<string>>(new Set<string>());
export const setUserDefaults = makeVar<
  UserDefaults_userDefaults_nodes | undefined
>(undefined);
export const setCurrentProfile = makeVar<CurrentUserProfile | undefined>(
  undefined
);
export const closeOpenedDocument = makeVar<boolean>(false);
export const setActiveOrderIndex = makeVar<
  Map<number, { orderNumber: number; layerNumber: number }>
>(new Map<number, { orderNumber: number; layerNumber: number }>());
