import React from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { NoteAddMutationProps } from 'common/components/NotesSection/NotesForm';
import { NotesEntityFields } from 'common/graphql/__generated__/NotesEntityFields';
import {
  CreateInvoiceNote,
  CreateInvoiceNoteVariables,
} from 'ap/paymentCycle/view/TransactionModal/TransactionDetail/ActionMenu/TransactionDetailNotes/__generated__/CreateInvoiceNote';
import {
  NotesSectionProps,
  NotesSection,
} from 'common/components/NotesSection';
import { TransactionSigningValues } from 'ap/signing/transactionSigning/view/types';
import { useFormContext } from 'react-hook-form';

const ADD_NEW_NOTE = loader(
  '../../../../../../../../common/graphql/CreateInvoiceSigningNote.graphql'
);

type TransactionNotesSectionProps = Pick<
  NotesSectionProps,
  'listRefreshRequest'
> & {
  invoiceId: string;
  data: NotesEntityFields[];
  loading?: boolean;
};

export const TransactionNotesSection: React.FC<
  TransactionNotesSectionProps
> = ({ invoiceId, data, listRefreshRequest, loading }) => {
  const {
    formState: { isDirty },
  } = useFormContext<TransactionSigningValues>();

  const [createNote, { loading: createNoteInProgress, error }] = useMutation<
    CreateInvoiceNote,
    CreateInvoiceNoteVariables
  >(ADD_NEW_NOTE);

  const noteAddMutation: NoteAddMutationProps = {
    loading: createNoteInProgress,
    createNote: async (formData) => {
      const { noteComment, isShared } = { ...formData };
      await createNote({
        variables: {
          input: {
            note: {
              entityId: invoiceId,
              noteComment: noteComment!,
              isShared: isShared!,
            },
          },
        },
      });
      if (!error) listRefreshRequest?.();
      return error;
    },
  };

  return (
    <NotesSection
      dataLoading={loading!}
      noteAddMutation={noteAddMutation}
      addingNoteInProgress={createNoteInProgress}
      listRefreshRequest={listRefreshRequest}
      data={data}
      isDirty={isDirty}
    />
  );
};
