import { useQuery, useReactiveVar } from '@apollo/client';
import { IconButton, Stack, Text } from '@fluentui/react';
import { AvailablePurchaseOrders } from 'ap/signing/__generated__/AvailablePurchaseOrders';
import { InvoiceTransactionTypes } from 'ap/signing/__generated__/InvoiceTransactionTypes';
import { ReferenceCodeList } from 'common/graphql/__generated__/ReferenceCodeList';
import { TransactionLayout } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { setUserDefaults } from 'utility/cache/ui';
import { InvoiceDetails_invoice } from '../../transactionSigning/view/__generated__/InvoiceDetails';
import { ACCOUNTING_INITIAL_VALUES } from '../../transactionSigning/view/constant';
import { TransactionSigningValues } from '../../transactionSigning/view/types';
import { ColumnsHeader } from './ColumnHeader';
import FormField from './FormField';
import { getColumns } from './columns.data';
import { useStyles } from './index.styles';
import { getRefCodes } from './utils';
import { DistributionRowTotal } from './DistributionRowTotal';
const REFERENCE_CODE = loader(
  '../../../../common/graphql/ReferenceCodesList.graphql'
);
interface AccountingProps {
  invoiceData?: Partial<InvoiceDetails_invoice>;
  invoiceDistributionStatusTypes: InvoiceTransactionTypes | undefined;
  availablePurchaseOrders: AvailablePurchaseOrders | undefined;
}

export const Accounting: React.FC<AccountingProps> = ({
  invoiceData,
  invoiceDistributionStatusTypes,
  availablePurchaseOrders,
}) => {
  const userDefaults = useReactiveVar(setUserDefaults);
  const { columnArray } = getColumns(userDefaults);
  const watch = useWatch<TransactionSigningValues>();
  const { control, getValues } = useFormContext<TransactionSigningValues>();
  const { fields, remove, append } = useFieldArray({
    name: 'invoiceDistributions',
    control,
  });
  const styles = useStyles();
  const { data: referenceCodes } = useQuery<ReferenceCodeList>(REFERENCE_CODE, {
    fetchPolicy: 'cache-first',
  });
  const watchInvoiceDistributions = getValues('invoiceDistributions');

  const refCodesOptions = useMemo(
    () => getRefCodes(referenceCodes),
    [referenceCodes]
  );
  const lastItem = watchInvoiceDistributions?.[fields.length - 1];

  const isValueExist = lastItem
    ? Object.entries(lastItem).reduce((prev, current) => {
        const isValue = current?.[1] !== null;
        return isValue || prev;
      }, false)
    : true;

  const isEdit = !!invoiceData?.id;
  const { _isUpdatable } = { ...invoiceData };
  const isUpdatable = isEdit ? !!_isUpdatable : true;

  useEffect(() => {
    if (isValueExist && isUpdatable) {
      append(ACCOUNTING_INITIAL_VALUES, { shouldFocus: false });
    }
  }, [append, isValueExist, isUpdatable]);

  const seletedTransactionType =
    invoiceDistributionStatusTypes?.invoiceTransactionTypes?.nodes.find(
      (item) => item.id === watch.transactionTypeId
    );

  const isAccountRowHidden =
    seletedTransactionType?.layoutType === TransactionLayout.SIGNING_DOCUMENT ||
    seletedTransactionType?.layoutType ===
      TransactionLayout.SIGNING_AMOUNT_DOCUMENT;
  if (isAccountRowHidden) return null;

  return (
    <Stack tokens={{ padding: '0px 0px 30px 0px' }}>
      <Stack tokens={{ padding: 20 }}>
        <Text variant="xLarge">Accounting</Text>
      </Stack>
      <Stack className={styles.scrollContainer}>
        <Stack tokens={{ padding: '20px 0px 40px' }}>
          <Stack horizontal style={{ overflowX: 'clip' }}>
            {fields.length > 1 && (
              <Stack tokens={{ padding: '52px 0px', childrenGap: 3 }}>
                {fields.map((_, index) => {
                  const isLastItem = fields.length - 1 !== index;
                  return (
                    <Stack key={index} style={{ height: 33, width: 40 }}>
                      {isLastItem && (
                        <IconButton
                          iconProps={{ iconName: 'Blocked2Solid' }}
                          ariaLabel="delete"
                          onClick={() => remove(index)}
                          style={{ color: 'red' }}
                        />
                      )}
                    </Stack>
                  );
                })}
              </Stack>
            )}
            <Stack
              style={{
                overflowX: 'scroll',
                padding: '0px 0px 40px 0px',
              }}
            >
              <ColumnsHeader columnArray={columnArray} />
              {fields.map((field, index) => {
                const baseField = `invoiceDistributions.${index}`;
                return (
                  <Stack key={index} horizontal tokens={{ childrenGap: 10 }}>
                    {columnArray.map((value, key) => {
                      return (
                        <FormField
                          index={index}
                          removeCurrentIndex={() => remove(index)}
                          baseField={baseField}
                          columnData={value}
                          key={field.id + key}
                          refCodesOptions={refCodesOptions}
                          invoiceData={invoiceData}
                          invoiceDistributionStatusTypes={
                            invoiceDistributionStatusTypes
                          }
                          availablePurchaseOrders={availablePurchaseOrders}
                        />
                      );
                    })}
                  </Stack>
                );
              })}
              <DistributionRowTotal columnsArray={columnArray} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
