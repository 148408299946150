import { useQuery } from '@apollo/client';
import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import { HistoryActionMenuView } from 'common/components/History';
import { PivotCustomRender } from 'common/components/PivotComponent';
import {
  PivotDisplay,
  PivotMenuItemProps,
} from 'common/components/PivotDisplay';
import { GlobalActions } from 'common/constants';
import {
  UserEntityMessageCounts,
  UserEntityMessageCountsVariables,
} from 'common/graphql/__generated__/UserEntityMessageCounts';
import { loader } from 'graphql.macro';
import { BatchTransaction_batchTransaction } from 'postingTracker/batchEdit/batch/view/__generated__/BatchTransaction';
import React, { useCallback, useState } from 'react';
import { Attach } from './Attach';
import { BatchMessages } from './Messages';
import { Notes } from './Notes';
import {
  BatchNotesView,
  BatchNotesViewVariables,
} from './__generated__/BatchNotesView';
import {
  BatchPropertiesCount,
  getUpdatedPivotActionItems,
  pivotActionItems,
} from './constants';
import { useStyles } from './index.styles';
const BATCH_NOTES_LIST = loader('./BatchNotesView.graphql');
const ENTITY_MESSAGE_COUNTS = loader(
  '../../../../../../common/graphql/UserEntityMessageCounts.graphql'
);

interface ActionsProps {
  batchTransaction: BatchTransaction_batchTransaction;
}
export const ActionData = 9;
export const Actions: React.FC<ActionsProps> = ({
  batchTransaction,

  // secureRowLevels
}) => {
  const [selectedKey, setSelectedKey] = React.useState<string | undefined>(
    GlobalActions.attach
  );

  const [expandMenu, setExpandMenu] = useState(false);
  const styles = useStyles();

  const {
    id,
    batchTransactionHistoriesByEntityId,
  } = { ...batchTransaction }

  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    BatchNotesView,
    BatchNotesViewVariables
  >(BATCH_NOTES_LIST, {
    variables: {
      id,
    },
    skip: !id,
  });

  const getEntityNotes = () => {
    const variables: BatchNotesViewVariables = {
      id,
    };
    entityNotesListRefetch(variables);
  };
  const getEntityNotesMemo = useCallback(getEntityNotes, [id]);

  const { data: userMessageCounts, refetch: userMessageCountsRefetch } =
    useQuery<UserEntityMessageCounts, UserEntityMessageCountsVariables>(
      ENTITY_MESSAGE_COUNTS,
      {
        variables: {
          entityId: id,
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
      }
    );

  const getSelectedSection = (key: string) => {
    switch (key) {
      case GlobalActions.notes:
        return (
          <Stack>
            <Notes
              listRefreshRequest={getEntityNotesMemo}
              id={id}
              data={entityNotesData?.batchTransaction?.notesByEntityId.nodes || []}
            />
          </Stack>
        );
      case GlobalActions.history:
        return (
          <Stack>
            <HistoryActionMenuView
              moduleName="Batch"
              numberOfItems={4}
              historyData={batchTransactionHistoriesByEntityId.nodes || []}
            />
          </Stack>
        );
      case GlobalActions.messages:
        return (
          <Stack tokens={{ padding: '0px 15px' }}>
            <BatchMessages
              onCreateMessage={userMessageCountsRefetch}
              userMessageCounts={userMessageCounts}
              batchID={id}
            />
          </Stack>
        );
      case GlobalActions.attach:
        return (
          <Stack tokens={{ padding: '0px 15px' }}>
            <Attach batchTransaction={batchTransaction} />
          </Stack>
        );
      default:
        return null;
    }
  };

  //Update the values of the count property in the pivotActionItems array

  let updatedPivotActionItems: PivotMenuItemProps[] = [];
  if (batchTransaction) {
    const {
      notesByEntityId,
      entityDocumentsByEntityId,
      batchTransactionHistoriesByEntityId,
    } = batchTransaction;

    const batchTransactionCountArray: BatchPropertiesCount[] = [
      {
        name: 'Files',
        count: entityDocumentsByEntityId?.totalCount,
      },
      {
        name: 'Notes',
        count: notesByEntityId?.nodes?.length || 0,
      },
      {
        name: 'History',
        count: batchTransactionHistoriesByEntityId?.nodes?.length || 0,
      },
      {
        name: 'Messages',
        count: userMessageCounts?.userEntityMessageCounts?.messagesSent! || 0,
      },
    ];
    updatedPivotActionItems = getUpdatedPivotActionItems(
      batchTransactionCountArray
    );
  } else updatedPivotActionItems = pivotActionItems;

  return (
    <Stack>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.pivotContainer}
            >
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey);
                }}
              >
                {updatedPivotActionItems.map((item, index) => {
                  return (
                    <PivotItem
                      key={index}
                      itemKey={item.itemKey}
                      itemIcon={item.iconName}
                      headerText={item.name}
                      itemCount={item.count}
                      onRenderItemLink={PivotCustomRender}
                    />
                  );
                })}
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
              />
            </Stack>
          </Stack>
          <Stack>{getSelectedSection(selectedKey!)}</Stack>
        </Stack>
      ) : (
        <Stack
          horizontalAlign="space-between"
          horizontal
          className={styles.pivotContainer}
        >
          <Stack horizontal verticalAlign="center">
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 5 }}
            >
              {updatedPivotActionItems.map((item, index) => {
                return (
                  <PivotDisplay
                    key={index}
                    {...item}
                    onPivotClick={() => {
                      setSelectedKey(item.itemKey);
                      setExpandMenu(true);
                    }}
                  />
                );
              })}
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
