import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  DatePicker,
  DayOfWeek,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { PanelHeader } from 'common/components/PanelHeader';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import {
  TravelerApprovalCreate,
  TravelerApprovalCreateVariables,
} from 'common/graphql/__generated__/TravelerApprovalCreate';
import {
  TravelerApprovalRevoke,
  TravelerApprovalRevokeVariables,
} from 'common/graphql/__generated__/TravelerApprovalRevoke';
import {
  TravelerCreate,
  TravelerCreateVariables,
} from 'common/graphql/__generated__/TravelerCreate';
import {
  TravelerDelete,
  TravelerDeleteVariables,
} from 'common/graphql/__generated__/TravelerDelete';
import {
  TravelerUpdate,
  TravelerUpdateVariables,
} from 'common/graphql/__generated__/TravelerUpdate';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalRequestInput,
  EntityDeleteInput,
  SupplierApprovalRevokeInput,
  TravelerAddressInput,
  TravelerAddressUpdateTypeInput,
  TravelerDietaryRequirementInput,
  TravelerDietaryRequirementUpdateTypeInput,
  TravelerInput,
  TravelerPatch,
  TravelerPolicyAllowanceInput,
  TravelerPolicyAllowanceUpdateTypeInput,
  UploadStatusType,
} from 'common/types/globalTypes';
import { EntityType, PanelCommonProps } from 'common/types/utility';
import { dateFormat, onFormatDate } from 'common/utils/dateFormats';
import { Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import { differenceBy, intersection, isEmpty } from 'lodash';
import React from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Travelers, TravelersVariables } from '../list/__generated__/Travelers';
import { ActionsMenu } from './ActionMenu';
import { ApprovalHistory } from './ApprovalHistory';
import { BasicForm, SeatPreferenceOptions } from './BasicForm';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { SignatureOptions, SignatureView } from './Signatures';
import { validationSchema } from './TravelerValidation';
import { Traveler, TravelerVariables } from './__generated__/Traveler';
import { TravelerCommonData } from './__generated__/TravelerCommonData';
import {
  DIETARY_REQUIREMENTS_INITIAL_VALUES,
  TRAVELER_INITIAL_VALUES,
} from './constants';
import { useStyles } from './index.styles';
import {
  AddressRowValues,
  DietaryRequirementsRowValues,
  TravelerValues,
} from './types';
import { UrgencyFolder } from './UrgencyFolder';
import { UrgencyToggle } from 'common/components/UrgencySelector/UrgencyToggle';
import { setUserDefaults } from 'utility/cache/ui';
const TRAVELER_COMMON_DATA = loader('./TravelerCommonData.graphql');
const TRAVELER_CREATE = loader(
  '../../../../common/graphql/TravelerCreate.graphql'
);
const TRAVELER_UPDATE = loader(
  '../../../../common/graphql/TravelerUpdate.graphql'
);
const TRAVELER = loader('./Traveler.graphql');
const TRAVELERS = loader('../list/Travelers.graphql');
const TRAVELER_DELETE = loader(
  '../../../../common/graphql/TravelerDelete.graphql'
);
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
const TRAVELER_APPROVAL_REVOKE = loader(
  '../../../../common/graphql/TravelerApprovalRevoke.graphql'
);
const TRAVELER_APPROVAL_CREATE = loader(
  '../../../../common/graphql/TravelerApprovalCreate.graphql'
);
const CONFIRM_AMEND_DIALOG_TITLE = 'Are you sure?';
const CONFIRM_AMEND_DIALOG_SUBTEXT =
  'This will remove the Traveler from the approval cycle and require re-approval.';
const CONFIRM_REQUEST_DIALOG_TITLE =
  'Are you sure you want to request this traveler for approval?';
const CONFIRM_REQUEST_DIALOG_SUBTEXT =
  'Your traveler will be requested for approval.';
const FINALIZED_BY_DATE_TOOLTIP =
  'Automatically promotes to urgent notifications when not approved by this date';

export const TravelersView = () => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const userDefaults = useReactiveVar(setUserDefaults);
  const history = useHistory();
  const { addToast, updateToast } = useToasts();
  const client = useApolloClient();
  const { travelerId } = useParams<{ travelerId: string | undefined }>();
  const isNew = !travelerId;
  const panelHeading = isNew ? 'Create Traveler' : 'Edit Traveler';
  const [isSaveAnother, { setTrue: saveAnother, setFalse: dontSaveAnother }] =
    useBoolean(false);
  const [hideConfirmRequestDialog, { toggle: toggleConfirmDialog }] =
    useBoolean(true);
  const [hideConfirmAmendDialog, { toggle: toggleConfirmAmendDialog }] =
    useBoolean(true);
  const [requestComment, setRequestComment] = React.useState<string>();
  const [requiredDate, setRequiredDate] = React.useState<string | undefined>();
  const [urgencyLevel, setUrgencyLevel] = React.useState<number>(1);

  const { data: travelerCommonData, loading: travelerCommonDataLoading } =
    useQuery<TravelerCommonData>(TRAVELER_COMMON_DATA, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  const [deleteTraveler, { loading: deleteTravelerLoading }] = useMutation<
    TravelerDelete,
    TravelerDeleteVariables
  >(TRAVELER_DELETE, {
    errorPolicy: 'all',
  });

  const { data: travelerData, loading: travelerDataLoading } = useQuery<
    Traveler,
    TravelerVariables
  >(TRAVELER, {
    variables: {
      id: travelerId!,
    },
    skip: !travelerId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const [createTraveler, { loading: addTravelerLoading }] = useMutation<
    TravelerCreate,
    TravelerCreateVariables
  >(TRAVELER_CREATE, { errorPolicy: 'all' });

  const [updateTraveler, { loading: loadingTravelerUpdate }] = useMutation<
    TravelerUpdate,
    TravelerUpdateVariables
  >(TRAVELER_UPDATE, { errorPolicy: 'all' });

  const [travelerApprovalCreate, { loading: travelerApprovalLoading }] =
    useMutation<TravelerApprovalCreate, TravelerApprovalCreateVariables>(
      TRAVELER_APPROVAL_CREATE,
      {
        errorPolicy: 'all',
        onCompleted: () => {
          setUrgencyLevel(1);
        },
      }
    );

  const [supplierApprovalRevoke, { loading: travelerApprovalRevokeLoading }] =
    useMutation<TravelerApprovalRevoke, TravelerApprovalRevokeVariables>(
      TRAVELER_APPROVAL_REVOKE,
      { errorPolicy: 'all' }
    );

  let panelTravelerStatus: string = '(Draft)';
  if (travelerData && !travelerDataLoading)
    panelTravelerStatus = `(${travelerData.traveler?.statusType?.statusType})`;
  else panelTravelerStatus = '';
  let initialValues: TravelerValues = TRAVELER_INITIAL_VALUES;
  if (travelerData?.traveler && !isNew) {
    initialValues = {
      ...travelerData.traveler!,
      seatPreference: travelerData.traveler?.isIsleSeatPreferred
        ? SeatPreferenceOptions.Isle
        : travelerData.traveler?.isWindowSeatPreferred
          ? SeatPreferenceOptions.Window
          : '',
      travelerAddressesByTravelerId: [
        ...travelerData?.traveler?.travelerAddressesByTravelerId?.nodes,
      ],
      travelerDietaryRequirementsByTravelerId:
        travelerData.traveler?.travelerDietaryRequirementsByTravelerId?.nodes
          ?.length > 0
          ? [
              ...travelerData.traveler?.travelerDietaryRequirementsByTravelerId?.nodes.map(
                (item) => ({
                  id: item.id,
                  _rowTimestamp: item._rowTimestamp,
                  _isDeletable: item._isDeletable,
                  description: item.description,
                  dietaryRequirementId: item.dietaryRequirement?.id!,
                  isDescriptionRequired:
                    item.dietaryRequirement?.isDescriptionRequired!,
                })
              ),
            ]
          : [],
      travelerPolicyAllowancesByTravelerId:
        travelerData.traveler?._travelPolicyAllowances?.nodes.map((ele) => ({
          id: ele.id,
          expenditureType: ele.expenditureType,
          allowanceOverrideAmount: ele.allowanceOverrideAmount,
          _allowanceDescription: ele._allowanceDescription,
          isAllowanceCustomizable: ele.isAllowanceCustomizable,
          _travelerPolicyAllowanceId: ele._travelerPolicyAllowanceId,
          _travelerPolicyAllowanceRowTimestamp:
            ele._travelerPolicyAllowanceRowTimestamp,
          isChecked: ele._travelerPolicyAllowanceId ? true : false,
        })) || [],
    };
  }

  let insertEmptyDietaryRequirementsRow: boolean = false;
  if (
    travelerData?.traveler?.travelerDietaryRequirementsByTravelerId?.nodes &&
    travelerCommonData?.dietaryRequirements?.nodes
  ) {
    insertEmptyDietaryRequirementsRow =
      travelerCommonData?.dietaryRequirements?.nodes?.length >
      travelerData.traveler?.travelerDietaryRequirementsByTravelerId?.nodes
        ?.length;
  }
  if (insertEmptyDietaryRequirementsRow) {
    initialValues = {
      ...initialValues,
      travelerDietaryRequirementsByTravelerId: [
        ...initialValues.travelerDietaryRequirementsByTravelerId!,
        ...[DIETARY_REQUIREMENTS_INITIAL_VALUES],
      ],
    };
  }

  const deleteRecord = async () => {
    const { errors } = await deleteTraveler({
      variables: {
        input: {
          entityDelete: [
            {
              id: travelerId!,
              rowTimestamp: travelerData?.traveler?._rowTimestamp!,
            },
          ],
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: TRAVELERS,
        },
      ],
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
      history.replace('/account-management/travelers');
    }
  };

  const handleSubmit = async (values: TravelerValues) => {
    const {
      travelerAddressesByTravelerId,
      seatPreference,
      travelerPolicyAllowancesByTravelerId,
      travelerDietaryRequirementsByTravelerId,
      travelerCompanionTickets,
      ...travelerFields
    } = values;
    if (isNew) {
      const { data, errors } = await createTraveler({
        variables: {
          input: {
            traveler: {
              isIsleSeatPreferred:
                seatPreference === SeatPreferenceOptions.Isle ? true : false,
              isWindowSeatPreferred:
                seatPreference === SeatPreferenceOptions.Window ? true : false,
              travelerCompanionTickets: Number(travelerCompanionTickets),
              ...travelerFields,
            } as TravelerInput,
            travelerAddresses: travelerAddressesByTravelerId?.map(
              ({ id, _rowTimestamp, ...addr }) => addr as TravelerAddressInput
            ),
            travelerPolicyAllowances: travelerPolicyAllowancesByTravelerId
              ?.filter((ele) => ele && ele.isChecked)
              ?.map(
                (ele) =>
                  ({
                    travelPolicyAllowanceId: ele.id,
                    allowanceOverrideAmount: ele.allowanceOverrideAmount,
                  }) as TravelerPolicyAllowanceInput
              ),
            travelerDietaryRequirements: travelerDietaryRequirementsByTravelerId
              ?.filter((item) => item.dietaryRequirementId !== null)
              ?.map(
                ({
                  id,
                  _rowTimestamp,
                  _isDeletable,
                  isDescriptionRequired,
                  ...requirement
                }) => requirement as TravelerDietaryRequirementInput
              ),
          },
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery<Travelers>({
            query: TRAVELERS,
          });
          if (cacheData?.travelers && data?.travelerCreate?.traveler?.id) {
            const updatedData: Travelers = {
              ...cacheData.travelers,
              travelers: {
                nodes: [
                  data?.travelerCreate?.traveler!,
                  ...cacheData.travelers.nodes!,
                ],
                pageInfo: cacheData.travelers.pageInfo,
              },
            };
            if (updatedData) {
              cache.writeQuery<Travelers, TravelersVariables>({
                query: TRAVELERS,
                data: updatedData,
              });
            }
          }
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        if (!isSaveAnother) {
          history.replace(
            `/account-management/travelers/traveler/${data?.travelerCreate?.traveler?.id}`
          );
        }
        addToast('Traveler added successfully', {
          appearance: 'success',
        });
      }
    } else {
      const {
        seatPreference,
        travelerAddressesByTravelerId,
        travelerPolicyAllowancesByTravelerId,
        travelerDietaryRequirementsByTravelerId,
        travelerCompanionTickets,
        ...restTravelerFields
      } = values;

      const updatedTravelerFields = {
        ...restTravelerFields,
        isIsleSeatPreferred:
          seatPreference === SeatPreferenceOptions.Isle ? true : false,
        isWindowSeatPreferred:
          seatPreference === SeatPreferenceOptions.Window ? true : false,
        travelerCompanionTickets: Number(travelerCompanionTickets),
      };

      const travelerPatch: TravelerPatch = Object.entries(
        updatedTravelerFields
      ).reduce((res, [key, val]) => {
        if (val !== initialValues[key as keyof TravelerValues]) {
          return { ...res, [key]: val };
        }
        return res;
      }, {});

      //Array for new Addresses
      const newAddresses =
        travelerAddressesByTravelerId &&
        (travelerAddressesByTravelerId
          ?.filter((addr) => !addr.id && addr.addressLine1 !== null)
          .map(
            ({ id, _rowTimestamp, ...addr }) => addr
          ) as TravelerAddressInput[]);

      //Array for deleted Addresses
      const deletedAddresses =
        initialValues.travelerAddressesByTravelerId &&
        (differenceBy(
          initialValues.travelerAddressesByTravelerId,
          travelerAddressesByTravelerId || [],
          'id'
        ).map((addr) => ({
          id: addr.id!,
          rowTimestamp: addr._rowTimestamp!,
        })) as EntityDeleteInput[]);
      //Array for updated Addresses
      const updatedAddresses =
        initialValues.travelerAddressesByTravelerId &&
        travelerAddressesByTravelerId &&
        intersection(
          initialValues.travelerAddressesByTravelerId.map((addr) => addr.id),
          travelerAddressesByTravelerId
            .filter((addr) => addr.id)
            .map((addr) => addr.id)
        ).reduce((arr, targetId) => {
          const initialAddress =
            initialValues.travelerAddressesByTravelerId!.find(
              (addr) => addr.id === targetId
            )!;
          const { id, _rowTimestamp, ...updatedAddress } =
            travelerAddressesByTravelerId!.find(
              (addr) => addr.id === targetId
            )!;
          const patch = Object.entries(updatedAddress).reduce(
            (res, [key, val]) => {
              if (val !== initialAddress[key as keyof AddressRowValues])
                return { ...res, [key]: val };
              return res;
            },
            {}
          );
          if (!isEmpty(patch))
            return [
              ...arr,
              {
                id,
                rowTimestamp: _rowTimestamp,
                travelerAddressPatch: patch,
              },
            ];
          return arr;
        }, [] as TravelerAddressUpdateTypeInput[]);

      //Array for new Traveler Policy Allowances
      const newTravelerPolicyAllowances =
        travelerPolicyAllowancesByTravelerId &&
        (travelerPolicyAllowancesByTravelerId
          ?.filter(
            (allowance) =>
              !allowance._travelerPolicyAllowanceId && allowance.isChecked
          )
          .map(
            (ele) =>
              ({
                travelPolicyAllowanceId: ele.id,
                allowanceOverrideAmount: ele.allowanceOverrideAmount,
              }) as TravelerPolicyAllowanceInput
          ) as TravelerPolicyAllowanceInput[]);

      //Array for deleted Traveler Policy Allowances
      const deletedTravelerPolicyAllowances =
        travelerPolicyAllowancesByTravelerId &&
        (travelerPolicyAllowancesByTravelerId
          .filter(
            (ele) =>
              ele.isChecked === false && ele._travelerPolicyAllowanceId !== null
          )
          .map((item) => ({
            id: item._travelerPolicyAllowanceId!,
            rowTimestamp: item._travelerPolicyAllowanceRowTimestamp!,
          })) as EntityDeleteInput[]);

      //Array for updated Traveler Policy Allowances
      const updatedTravelerPolicyAllowances =
        initialValues.travelerPolicyAllowancesByTravelerId &&
        travelerPolicyAllowancesByTravelerId &&
        travelerPolicyAllowancesByTravelerId
          .filter(
            ({
              allowanceOverrideAmount: overrideAmount1,
              _travelerPolicyAllowanceId,
            }) =>
              !initialValues?.travelerPolicyAllowancesByTravelerId?.some(
                ({ allowanceOverrideAmount: overrideAmount2 }) =>
                  overrideAmount2 === overrideAmount1
              ) && _travelerPolicyAllowanceId !== null
          )
          .map(
            (ele) =>
              ({
                id: ele._travelerPolicyAllowanceId,
                rowTimestamp: ele._travelerPolicyAllowanceRowTimestamp,
                travelerPolicyAllowancePatch: {
                  travelPolicyAllowanceId: ele.id,
                  allowanceOverrideAmount: ele.allowanceOverrideAmount,
                },
              }) as TravelerPolicyAllowanceUpdateTypeInput
          );

      //Array for new Dietary Requirements

      const newDietaryRequirements =
        travelerDietaryRequirementsByTravelerId &&
        travelerDietaryRequirementsByTravelerId
          ?.filter(
            (item) => item.dietaryRequirementId !== null && item.id === null
          )
          ?.map(
            ({
              id,
              _rowTimestamp,
              _isDeletable,
              isDescriptionRequired,
              ...requirement
            }) => requirement as TravelerDietaryRequirementInput
          );

      //Array for deleted Dietary Requirements
      const deletedDietaryRequirements =
        initialValues.travelerDietaryRequirementsByTravelerId &&
        (differenceBy(
          initialValues.travelerDietaryRequirementsByTravelerId,
          travelerDietaryRequirementsByTravelerId || [],
          'id'
        ).map((addr) => ({
          id: addr.id!,
          rowTimestamp: addr._rowTimestamp!,
        })) as EntityDeleteInput[]);

      //Array for updated DietaryRequirements
      const updatedDietaryRequirements =
        initialValues.travelerDietaryRequirementsByTravelerId &&
        travelerDietaryRequirementsByTravelerId &&
        intersection(
          initialValues.travelerDietaryRequirementsByTravelerId.map(
            (requirement) => requirement.id
          ),
          travelerDietaryRequirementsByTravelerId
            .filter((requirement) => requirement.id)
            .map((requirement) => requirement.id)
        ).reduce((arr, targetId) => {
          const initialDietaryRequirements =
            initialValues.travelerDietaryRequirementsByTravelerId!.find(
              (requirement) => requirement.id === targetId
            )!;
          const {
            id,
            _rowTimestamp,
            _isDeletable,
            isDescriptionRequired,
            ...updatedRequirements
          } = travelerDietaryRequirementsByTravelerId!.find(
            (requirement) => requirement.id === targetId
          )!;

          const patch = Object.entries(updatedRequirements).reduce(
            (res, [key, val]) => {
              if (
                val !==
                initialDietaryRequirements[
                  key as keyof DietaryRequirementsRowValues
                ]
              )
                return { ...res, [key]: val };
              return res;
            },
            {}
          );
          if (!isEmpty(patch))
            return [
              ...arr,
              {
                id,
                rowTimestamp: _rowTimestamp,
                travelerDietaryRequirementPatch: patch,
              },
            ];
          return arr;
        }, [] as TravelerDietaryRequirementUpdateTypeInput[]);

      const { errors } = await updateTraveler({
        variables: {
          input: {
            id: travelerId!,
            rowTimestamp: travelerData?.traveler?._rowTimestamp!,
            travelerPatch: !isEmpty(travelerPatch) ? travelerPatch : undefined,
            travelerAddressesCreate:
              newAddresses && newAddresses.length > 0
                ? newAddresses
                : undefined,
            travelerAddressesUpdate:
              updatedAddresses && updatedAddresses.length > 0
                ? updatedAddresses
                : undefined,
            travelerAddressesDelete:
              deletedAddresses && deletedAddresses.length > 0
                ? deletedAddresses
                : undefined,
            travelerPolicyAllowancesCreate:
              newTravelerPolicyAllowances &&
              newTravelerPolicyAllowances.length > 0
                ? newTravelerPolicyAllowances
                : undefined,
            travelerPolicyAllowancesDelete:
              deletedTravelerPolicyAllowances &&
              deletedTravelerPolicyAllowances.length > 0
                ? deletedTravelerPolicyAllowances
                : undefined,
            travelerPolicyAllowancesUpdate:
              updatedTravelerPolicyAllowances &&
              updatedTravelerPolicyAllowances.length > 0
                ? updatedTravelerPolicyAllowances
                : undefined,
            travelerDietaryRequirementsCreate:
              newDietaryRequirements && newDietaryRequirements.length > 0
                ? newDietaryRequirements
                : undefined,
            travelerDietaryRequirementsDelete:
              deletedDietaryRequirements &&
              deletedDietaryRequirements.length > 0
                ? deletedDietaryRequirements
                : undefined,
            travelerDietaryRequirementsUpdate:
              updatedDietaryRequirements &&
              updatedDietaryRequirements.length > 0
                ? updatedDietaryRequirements
                : undefined,
          },
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery<Traveler, TravelerVariables>({
            query: TRAVELER,
            variables: {
              id: travelerId!,
            },
          });
          const updatedData: Traveler = {
            traveler: {
              ...cacheData?.traveler!,
              ...data?.travelerUpdate?.traveler!,
            },
          };
          cache.writeQuery<Traveler, TravelerVariables>({
            query: TRAVELER,
            variables: {
              id: travelerId!,
            },
            data: updatedData!,
          });
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('Traveler edited successfully', {
          appearance: 'success',
        });
      }
    }
  };

  const _onRenderHeader = (dirty: boolean, isSubmitting: boolean) => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/account-management/travelers');
        }}
      >
        <Stack
          grow
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">{panelHeading}</Text>
            {!isNew && (
              <>
                {travelerData?.traveler?._fullName && (
                  <Text
                    variant="xLarge"
                    className={commonStyles.colorThemePrimary}
                  >
                    {travelerData.traveler._fullName}
                  </Text>
                )}
                {travelerData?.traveler?.vendorNumber && (
                  <Text
                    variant="xLarge"
                    className={commonStyles.colorThemePrimary}
                  >
                    {travelerData.traveler.vendorNumber}
                  </Text>
                )}
              </>
            )}
            <UnsavedIndicator
              visible={!isNew && !travelerDataLoading && dirty && !isSubmitting}
            />
          </Stack>
          <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 10 }}>
            {travelerData?.traveler?.statusType?.statusType === 'Pending' && (
              <UrgencyFolder travelerData={travelerData} />
            )}
            <ActionMessageModal
              visible={!isNew}
              entityType={EntityType.Traveler}
              disabled={!travelerData?.traveler?._isDeletable}
              onConfirm={deleteRecord}
            />
            <Text
              variant="mediumPlus"
              className={commonStyles.colorThemePrimary}
            >
              {panelTravelerStatus}
            </Text>
          </Stack>
        </Stack>
      </PanelHeader>
    );
  };

  const isLoading =
    addTravelerLoading ||
    loadingTravelerUpdate ||
    deleteTravelerLoading ||
    travelerApprovalLoading ||
    travelerApprovalRevokeLoading;

  const { _isUpdatable, _isUpdateTravelerOverride } = {
    ...travelerData?.traveler,
  };

  const isUpdatable = !isNew ? !!_isUpdatable : true;

  // const isDisabled = _isUpdateTravelerOverride ? false : _isUpdatable ? false : true

  const isDisabled = travelerData
    ? !travelerData.traveler?._isUpdatable
    : false;

  const signatureData: SignatureOptions[] =
    travelerData?.traveler?.approvalHistorySignaturesByEntityId.nodes.map(
      (item) =>
        ({
          actionDate: item.actionDate,
          additionalInformation: item.additionalInformation,
          digitalSignature: item.digitalSignature,
          id: item.id,
          isApproved: item.isApproved,
          isRejected: item.isRejected,
          isRequested: item.isRequested,
          userName: item.userName,
          entityDocumentId: item.entityDocumentId,
          userOccupationTitle: item.userOccupationTitle,
          userSignatureDocument: {
            _downloadLink: item.userSignatureDocument?._downloadLink,
            fileIndexInformation:
              item.userSignatureDocument?.fileIndexInformation,
            fileReference: item.userSignatureDocument?.fileReference,
            iconType: item.userSignatureDocument?.iconType,
            id: item.userSignatureDocument?.id,
          },
        }) as SignatureOptions
    ) || [];

  return (
    <Formik<TravelerValues>
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting, dirty, resetForm, errors }) => {
        return (
          <Form>
            <DraggablePanel
              {...PanelCommonProps}
              isBlocking={isNew}
              initialWidth={1200}
              minWidth={1200}
              onRenderHeader={() => _onRenderHeader(dirty, isSubmitting)}
              onRenderFooter={() => (
                <FooterActionBar
                  createNewText="New Traveler"
                  addNewForm={() => {
                    history.replace('/account-management/travelers/traveler');
                  }}
                  onCancel={() =>
                    history.replace('/account-management/travelers')
                  }
                  disabled={
                    travelerCommonDataLoading || {
                      save: !dirty || Object.keys(errors).length > 0,
                      cancel: !dirty,
                    }
                  }
                  onSave={async () => {
                    dontSaveAnother();
                    await submitForm();
                  }}
                  onSaveAnother={async () => {
                    saveAnother();
                    await submitForm();
                    resetForm();
                  }}
                  isLoading={isLoading}
                  isCreate={!travelerId ? true : false}
                  isSubmitting={isSubmitting}
                  children={
                    travelerData && (
                      <Stack>
                        {!isNew &&
                          travelerData?.traveler?._isStagedApprovalRequest && (
                            <PrimaryButton
                              disabled={dirty}
                              className={styles.diabledButton}
                              text="Request Approval"
                              onClick={() => toggleConfirmDialog()}
                            />
                          )}
                        {!isNew &&
                          travelerData.traveler?._isApprovalRevocable && (
                            <PrimaryButton
                              disabled={dirty}
                              className={styles.diabledButton}
                              text="Amend Transaction"
                              onClick={() => toggleConfirmAmendDialog()}
                            />
                          )}
                      </Stack>
                    )
                  }
                />
              )}
              isOpen
              onDismiss={() => {
                history.replace('/account-management/suppliers');
              }}
              isLightDismiss
            >
              {!isNew && travelerDataLoading ? (
                <Stack
                  tokens={{ childrenGap: 10, padding: '25px 25px 0px 25px' }}
                >
                  <ShimmerView />
                </Stack>
              ) : (
                <>
                  {!isNew && (
                    <>
                      <Stack tokens={{ padding: '20px 0px 0px 0px' }}>
                        {travelerData?.traveler && (
                          <ActionsMenu
                            secureRowLevels={
                              travelerCommonData?.secureRowLevels!
                            }
                            traveler={travelerData.traveler}
                            onUpload={async (
                              fileSelected,
                              document,
                              toastId
                            ) => {
                              const observer = client.subscribe({
                                query: DOCUMENT_UPLOAD_STATUS,
                                variables: {
                                  documentId:
                                    document.document._documentFileId!,
                                },
                              });
                              const subscription = observer.subscribe(
                                (data) => {
                                  const subscribedData =
                                    data.data as OnDocumentUploadStatus;

                                  const { status, document } = {
                                    ...subscribedData.documentUploadStatus,
                                  };

                                  if (!document) {
                                    if (
                                      status.type ===
                                      UploadStatusType.VALIDATING
                                    ) {
                                      updateToast(toastId!, {
                                        content: status.message
                                          ? `Validating files ${fileSelected.name} - ${status.message}`
                                          : `Validating files ${fileSelected.name}`,
                                        appearance: 'info',
                                        autoDismiss: false,
                                      });
                                    } else if (
                                      status.type ===
                                      UploadStatusType.EXTRACTING
                                    ) {
                                      updateToast(toastId!, {
                                        content: status.message
                                          ? `Extracting data from ${fileSelected.name} - ${status.message}`
                                          : `Extracting data from ${fileSelected.name}`,
                                        appearance: 'info',
                                        autoDismiss: false,
                                      });
                                    } else if (
                                      status.type === UploadStatusType.FAILURE
                                    ) {
                                      subscription.unsubscribe();
                                      updateToast(toastId!, {
                                        content: status.message
                                          ? `Upload of ${fileSelected.name} failed - ${status.message}`
                                          : `Upload of ${fileSelected.name} failed`,
                                        appearance: 'error',
                                        autoDismiss: true,
                                      });
                                    } else if (
                                      status.type === UploadStatusType.WARNING
                                    ) {
                                      subscription.unsubscribe();
                                      updateToast(toastId!, {
                                        content: status.message
                                          ? `Warning for file ${fileSelected.name}: ${status.message}`
                                          : `Warning for file ${fileSelected.name}`,
                                        appearance: 'warning',
                                        autoDismiss: true,
                                      });
                                    }
                                  } else {
                                    subscription.unsubscribe();
                                    updateToast(toastId!, {
                                      content: status.message
                                        ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                                        : `Successfully uploaded ${fileSelected.name}`,
                                      appearance: 'success',
                                      autoDismiss: true,
                                    });

                                    const cacheData = client.readQuery<
                                      Traveler,
                                      TravelerVariables
                                    >({
                                      query: TRAVELER,
                                      variables: {
                                        id: travelerId!,
                                      },
                                    });
                                    if (cacheData) {
                                      const updatedData: Traveler = {
                                        traveler: {
                                          ...cacheData?.traveler!,
                                          entityDocumentsByEntityId: {
                                            ...cacheData?.traveler
                                              ?.entityDocumentsByEntityId,
                                            nodes: [
                                              document!,
                                              ...cacheData?.traveler
                                                ?.entityDocumentsByEntityId
                                                .nodes!,
                                            ],
                                          },
                                        },
                                      };
                                      client.writeQuery<
                                        Traveler,
                                        TravelerVariables
                                      >({
                                        query: TRAVELER,
                                        data: updatedData,
                                        variables: {
                                          id: travelerId!,
                                        },
                                      });
                                    }
                                  }
                                }
                              );
                            }}
                          />
                        )}
                        <Separator />
                      </Stack>
                    </>
                  )}
                  <BasicForm
                    traveler={travelerData}
                    isEdit={travelerId ? true : false}
                    userOccupationTitles={
                      travelerCommonData?.userOccupationTitles?.nodes || []
                    }
                    dietaryRequirements={
                      travelerCommonData?.dietaryRequirements?.nodes || []
                    }
                    companyDepartments={
                      travelerCommonData?.companyDepartments?.nodes || []
                    }
                    travelPolicies={
                      travelerCommonData?.travelPolicies?.nodes || []
                    }
                    travelerHosts={
                      travelerCommonData?.travelerCompanionMasters?.nodes || []
                    }
                    countries={travelerCommonData?.countries?.nodes || []}
                    travelPolicyDetails={
                      travelerData?.traveler?.travelPolicy || null
                    }
                    inputsDisabled={isDisabled}
                    isUpdatable={isUpdatable}
                    isUpdateTravelerOverride={!!_isUpdateTravelerOverride}
                  />
                  {travelerData?.traveler?.approvalHistorySignaturesByEntityId
                    .nodes.length! > 0 && (
                    <Stack>
                      <Separator />
                      <Stack
                        className={styles.tagsContainer}
                        tokens={{ childrenGap: 20 }}
                      >
                        <Text variant="xLarge">Signatures</Text>
                        <SignatureView signatureData={signatureData} />
                      </Stack>
                    </Stack>
                  )}
                  {travelerData?.traveler &&
                    travelerData?.traveler?.approvalHistoriesByEntityId?.nodes
                      .length! > 0 && (
                      <Stack className={styles.bottomMargin}>
                        <Separator />
                        <Stack
                          className={styles.tagsContainer}
                          tokens={{ childrenGap: 20 }}
                        >
                          <Text variant="xLarge">Approval History</Text>
                          <Text
                            variant="medium"
                            className={styles.requestedByText}
                          >
                            Requested By:
                          </Text>
                          <ApprovalHistory
                            data={
                              travelerData.traveler.approvalHistoriesByEntityId
                            }
                          />
                        </Stack>
                      </Stack>
                    )}
                  <ConfirmDialog
                    hidden={hideConfirmRequestDialog}
                    title={CONFIRM_REQUEST_DIALOG_TITLE}
                    subText={CONFIRM_REQUEST_DIALOG_SUBTEXT}
                    onDismiss={toggleConfirmDialog}
                    minWidth={500}
                    onConfirm={async () => {
                      toggleConfirmDialog();
                      const inputVariables: ApprovalRequestInput = {
                        entityId: travelerId!,
                        rowTimestamp: travelerData?.traveler?._rowTimestamp!,
                        comments: requestComment,
                      };
                      if (requiredDate)
                        inputVariables.requiredDate = requiredDate;
                      const { errors } = await travelerApprovalCreate({
                        variables: {
                          input: {
                            entityApproval: [inputVariables],
                            urgencyLevel: urgencyLevel,
                          },
                        },
                        awaitRefetchQueries: true,
                        refetchQueries: [
                          {
                            query: TRAVELER,
                            variables: {
                              id: travelerId!,
                            },
                          },
                        ],
                      });

                      if (errors?.length)
                        addToast(errors[0].message, {
                          appearance: 'error',
                        });
                      else {
                        setRequestComment('');
                        setRequiredDate('');
                        addToast('Request sent for approval', {
                          appearance: 'success',
                        });
                      }
                    }}
                  >
                    <Stack tokens={{ childrenGap: 10 }}>
                      <TextField
                        className={styles.marginT10}
                        multiline
                        rows={3}
                        value={requestComment}
                        placeholder="Please write your comment here (optional)"
                        resizable={false}
                        onChange={(_event, value) =>
                          setRequestComment(value || '')
                        }
                      />
                      <TooltipHost content={FINALIZED_BY_DATE_TOOLTIP}>
                        <DatePicker
                          minDate={new Date()}
                          firstDayOfWeek={DayOfWeek.Sunday}
                          placeholder="Finalized by date (optional)"
                          ariaLabel="Date"
                          formatDate={onFormatDate}
                          showWeekNumbers
                          firstWeekOfYear={1}
                          showMonthPickerAsOverlay
                          showGoToToday={false}
                          onSelectDate={(date) =>
                            setRequiredDate(dateFormat(date!.toString()))
                          }
                        />
                      </TooltipHost>
                      <Stack tokens={{ padding: '6px 0px 0px 0px' }}>
                        {userDefaults?._isUserUrgencyUpdateAllowed && (
                          <UrgencyToggle
                            onToggle={(data) => {
                              setUrgencyLevel(data ? 0 : 1);
                            }}
                          />
                        )}
                      </Stack>
                    </Stack>
                  </ConfirmDialog>
                  <ConfirmDialog
                    hidden={hideConfirmAmendDialog}
                    title={CONFIRM_AMEND_DIALOG_TITLE}
                    subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
                    onDismiss={toggleConfirmAmendDialog}
                    onConfirm={async () => {
                      toggleConfirmAmendDialog();
                      const inputVariables: SupplierApprovalRevokeInput = {
                        entityId: travelerId!,
                        rowTimestamp: travelerData?.traveler?._rowTimestamp!,
                      };
                      const { errors } = await supplierApprovalRevoke({
                        variables: {
                          input: inputVariables,
                        },
                        awaitRefetchQueries: true,
                        refetchQueries: [
                          {
                            query: TRAVELER,
                            variables: {
                              id: travelerId!,
                            },
                          },
                        ],
                      });

                      if (errors?.length)
                        addToast(errors[0].message, {
                          appearance: 'error',
                        });
                      else
                        addToast('Approval amended successfully', {
                          appearance: 'success',
                        });
                    }}
                  />
                </>
              )}
              <Prompt
                when={dirty && !isSubmitting}
                message="Are you sure you want to leave your changes unsaved?"
              />
            </DraggablePanel>
          </Form>
        );
      }}
    </Formik>
  );
};
