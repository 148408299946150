import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import {
  IDetailsRowProps,
  IRenderFunction,
  IconButton,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { EntityDeleteInput, SupplierFilter } from 'common/types/globalTypes';
import { EntityAction, EntityType } from 'common/types/utility';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { filterOptionProps, ListFilters } from '../Filters';
import {
  SupplierDelete,
  SupplierDeleteVariables,
} from '../__generated__/SupplierDelete';
import { _renderItemColumn } from './coloumn.render';
import { columns } from './column.data';
import { toFilterVariable, toOrderByVariable } from './utils';
import {
  Suppliers,
  SuppliersVariables,
  Suppliers_suppliers_nodes,
} from './__generated__/Suppliers';
import clsx from 'clsx';
import { useStyles } from './index.styles';

const SUPPLIERS = loader('./Suppliers.graphql');
const SUPPLIER_DELETE = loader('../SupplierDelete.graphql');

export type SupplierRowItem = Suppliers_suppliers_nodes & {
  state: string | undefined | null;
  country: string | undefined;
  cityName: string | null;
  status: string | undefined | null;
  rating: string | null | undefined;
  name: string | null;
  addressLine1: string | null;
};

export const SuppliersList: React.FC = () => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const history = useHistory();
  const { addToast } = useToasts();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [filterOptions, setFilterOptions] = useState<filterOptionProps>({
    filterTypes: [],
    startsWith: true,
  });
  const [selectedRows, setSelectedRows] = useState<SupplierRowItem[]>([]);

  const [deleteSupplier] = useMutation<SupplierDelete, SupplierDeleteVariables>(
    SUPPLIER_DELETE,
    { errorPolicy: 'all' }
  );

  const {
    data: supplierData,
    loading,
    variables,
    networkStatus,
    refetch,
    fetchMore,
  } = useQuery<Suppliers, SuppliersVariables>(SUPPLIERS, {
    variables: {
      first: TABLE_ROWS,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  const transformedData: SupplierRowItem[] | undefined = refetching
    ? undefined
    : supplierData?.suppliers?.nodes?.map((supplier) => {
      const { country, stateRegion, addressLine1, cityName } = {
        ...supplier?.supplierAddressesBySupplierId?.nodes[0],
      };
      return {
        ...supplier,
        status: supplier.statusType?.statusType,
        country: country?.country,
        state: stateRegion?.stateRegionDescription,
        rating: supplier.supplierRatingResults?.starValue,
        name: supplier._sortName,
        addressLine1,
        cityName,
      };
    });

  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );

        setGridColumns(newColumns);
        const sort: SortOrder = {
          column: clickedColumn.key,
          direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
        };
        refetch({ after: null, orderBy: toOrderByVariable(sort) });
      }
    },
    [gridColumns, refetch]
  );

  const onRefresh = () => {
    refetch();
  };

  const onFilterChange = (filterOptions: filterOptionProps) => {
    setFilterOptions(filterOptions);
    const filterArray: SupplierFilter | undefined = filterOptions.filterTypes
      ?.length
      ? ({ and: toFilterVariable(filterOptions) } as SupplierFilter)
      : undefined;
    refetch({ ...variables, filter: filterArray! });
  };

  const onLoadMore = () => {
    const getSupplierVariables: SuppliersVariables = {
      ...variables,
      after: supplierData?.suppliers?.pageInfo.endCursor,
    };
    fetchMore({ variables: getSupplierVariables });
  };
  const _onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    const item: SupplierRowItem = { ...props.item };
    const { _urgencyLevel } = { ...item };
    const newProps: IDetailsRowProps | undefined = props
      ? {
        ...props,
        className: clsx(
          styles.rowBaseStyle,
          _urgencyLevel === 0 ? commonStyles.urgentRow : styles.rowBaseStyle
        ),
      }
      : undefined;

    return <>{defaultRender?.(newProps)}</>;
  };

  const _deleteSupplier = async () => {
    const deletableData: EntityDeleteInput[] = selectedRows.map((obj) => {
      return { id: obj.id, rowTimestamp: obj._rowTimestamp! };
    });
    const { errors } = await deleteSupplier({
      variables: { input: { entityDelete: deletableData } },
      update: (cache, { data }) => {
        const deletedIds = data?.supplierDelete?.deletedEntities?.map(
          (entity) => entity?.id
        );
        if (deletedIds) {
          const filteredList = supplierData?.suppliers?.nodes.filter(
            (emp) => deletedIds.indexOf(emp.id) === -1
          );
          const newData: Suppliers = {
            suppliers: {
              pageInfo: supplierData?.suppliers?.pageInfo!,
              nodes: filteredList!,
            },
          };
          cache.writeQuery<Suppliers, SuppliersVariables>({
            query: SUPPLIERS,
            variables,
            data: newData,
          });
        }
      },
    });
    if (errors?.length)
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
    else
      addToast('Traveler deleted Successfully', {
        appearance: 'success',
      });
  };

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Suppliers</Text>
          <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 10 }}>
            <ListFilters
              filterOptions={filterOptions}
              onFilterChange={onFilterChange}
            />
            <IconButton
              iconProps={{ iconName: 'refresh' }}
              onClick={onRefresh}
            />
            <ActionMessageModal
              entityType={EntityType.Supplier}
              action={EntityAction.Remove}
              disabled={!selectedRows.some((selected) => selected._isDeletable)}
              visible={selectedRows.length > 0}
              multiple={{
                validCount: selectedRows.filter(
                  (selected) => selected._isDeletable
                ).length,
                invalidNames: selectedRows
                  .filter((selected) => !selected._isDeletable)
                  .map((cannotDelete) => cannotDelete._sortName || ''),
              }}
              onConfirm={async () => await _deleteSupplier()}
            />
            <PrimaryButton
              onClick={() => {
                history.push('/account-management/suppliers/supplier');
              }}
              iconProps={{ iconName: 'Add' }}
              text="New Supplier"
            />
          </Stack>
        </Stack>
      </Stack>

      <InfiniteList<SupplierRowItem>
        loading={loading}
        hasNextPage={supplierData?.suppliers?.pageInfo.hasNextPage}
        items={transformedData}
        selectionMode={SelectionMode.multiple}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={_onColumnClick}
        onSelectionChanged={setSelectedRows}
        onLoadMore={onLoadMore}
        onRenderRow={_onRenderRow}
      />
    </>
  );
};
