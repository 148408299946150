import { useMutation } from '@apollo/client';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import {
  EntityDocumentRetireBalance,
  EntityDocumentRetireBalanceVariables,
} from 'documents/documentAssignment/folder/list/UploadForm/__generated__/EntityDocumentRetireBalance';
import {
  EntityDocumentUnretireBalance,
  EntityDocumentUnretireBalanceVariables,
} from 'documents/documentAssignment/folder/list/UploadForm/__generated__/EntityDocumentUnretireBalance';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { GetEntityDocument } from '../../../__generated__/GetEntityDocument';
import { RevisionModal } from '../RevisionModal';
import { RetireSplitButton } from './RetireRevisionButton';
import { Stack } from '@fluentui/react';

const RETIRE_BALANCE = loader(
  '../../../../list/UploadForm/EntityDocumentRetireBalance.graphql'
);
const UNRETIRE_BALANCE = loader(
  '../../../../list/UploadForm/EntityDocumentUnretireBalance.graphql'
);
const TITLE_RETIRE = 'Are you sure you want to retire the remaining balance?';
const TITLE_UNRETIRE = 'Are you sure you want to remove the retire amount?';

export const RetireBalance: React.FC<{
  documentData: GetEntityDocument | undefined;
  onMutationSuccess: () => void;
}> = ({ documentData, onMutationSuccess }) => {
  const [dialogState, setDialogState] = useState<{
    visible: boolean;
    retire: boolean;
  }>({ visible: false, retire: false });
  const { addToast } = useToasts();

  const [openRevisionModal, setOpenRevisionModal] = useState(false);

  const [retire] = useMutation<
    EntityDocumentRetireBalance,
    EntityDocumentRetireBalanceVariables
  >(RETIRE_BALANCE, { errorPolicy: 'all' });

  const [unRetire] = useMutation<
    EntityDocumentUnretireBalance,
    EntityDocumentUnretireBalanceVariables
  >(UNRETIRE_BALANCE, { errorPolicy: 'all' });

  const onConfirm = async () => {
    const { _rowTimestamp, id } = { ...documentData?.entityDocument };

    if (!dialogState.retire) {
      const { errors } = await unRetire({
        variables: {
          input: {
            rowTimestamp: _rowTimestamp!,
            entityDocumentId: id!,
          },
        },
      });

      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        setDialogState({ retire: dialogState.retire, visible: false });
        onMutationSuccess();
        addToast('Balance Unretired successfully', {
          appearance: 'success',
        });
      }
    } else {
      const { errors } = await retire({
        variables: {
          input: {
            rowTimestamp: _rowTimestamp!,
            entityDocumentId: id!,
          },
        },
      });

      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Balance retired', {
          appearance: 'success',
        });
        onMutationSuccess();
        setDialogState({ retire: dialogState.retire, visible: false });
      }
    }
  };

  return (
    <Stack horizontal>
      {/* {_unretirePrivilege && (
        <PrimaryButton
          text="Unretire Balance"
          onClick={() => {
            setDialogState({ retire: false, visible: true });
          }}
        />
      )} */}

      <RetireSplitButton
        entityDocument={documentData?.entityDocument}
        onRevisionPO={() => {
          setOpenRevisionModal(true);
        }}
        onClick={(retire) => {
          setDialogState({ retire, visible: true });
        }}
      />
      <ConfirmDialog
        hidden={!dialogState.visible}
        title={dialogState.retire ? TITLE_RETIRE : TITLE_UNRETIRE}
        onDismiss={() => {
          setDialogState({ retire: dialogState.retire, visible: false });
        }}
        maxWidth={350}
        onConfirm={async () => {
          onConfirm();
        }}
      />
      {!!documentData?.entityDocument && (
        <RevisionModal
          isOpen={openRevisionModal}
          entityDocument={documentData.entityDocument}
          onClose={() => setOpenRevisionModal(false)}
        />
      )}
    </Stack>
  );
};
