import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import {
  DefaultButton,
  IColumn,
  IconButton,
  IDetailsRowBaseProps,
  IDetailsRowProps,
  IRenderFunction,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
} from '@fluentui/react';
import { UserApprovalSearch_userApprovalSearch_nodes } from 'approvals/MyApprovals/__generated__/UserApprovalSearch';
import clsx from 'clsx';
import { FormikTextField } from 'common/components';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { CloseButton } from 'common/components/Buttons';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { TransactionSection } from 'common/components/TransactionSection';
import { useCommonStyles } from 'common/styles';
import { InvoiceBatchUpdateTypeInput } from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection } from 'common/utils/commonTypes';
import { dateFormat } from 'common/utils/dateFormats';
import { Form, Formik, useFormikContext } from 'formik';
import { loader } from 'graphql.macro';
import {
  InvoiceBatches,
  InvoiceBatchesVariables,
  InvoiceBatches_invoiceBatches_nodes,
} from 'postingTracker/batchEdit/unpostedTransactions/InvoiceBatchModal/__generated__/InvoiceBatches';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { columns } from './columns.data';
import { useStyles } from './index.styles';
import { ValidationFormValues } from './types';
import { toOrderByVariable } from './utils';
import {
  InvoiceBatchValidate,
  InvoiceBatchValidateVariables,
} from './__generated__/InvoiceBatchValidate';
import { ModalWrapper } from 'common/components/ModalWrapper';
import { DocumentPackage } from 'common/components/DocumentPackage';

const INVOICE_BATCHES = loader(
  '../../postingTracker/batchEdit/unpostedTransactions/InvoiceBatchModal/InvoiceBatches.graphql'
);
const INVOICE_BATCHE_VALIDATE = loader('./InvoiceBatchValidate.graphql');
const GET_APPROVAL_DETAILS = loader('../ApprovalDetails/UserApproval.graphql');
const GET_APPROVAL_HISTORY_DETAILS = loader(
  '../ApprovalDetails/ApprovalHistory.graphql'
);

interface InvoiceBatchFormProps {
  onClose?: () => void;
  batchTransactionId: string;
  approvalData?: Partial<UserApprovalSearch_userApprovalSearch_nodes>;
  refetchAllApproval?: boolean;
}

type InvoiceBatchTransactionModal = InvoiceBatchFormProps & {
  items: InvoiceBatchRow[] | undefined;
  loading: boolean;
  invoiceBatchesLoading: boolean;
  invoiceBatches: InvoiceBatches | undefined;
  onSave: () => void;
  gridColumns: ColumnData[];
  _onColumnClick: (
    _ev?: React.MouseEvent<HTMLElement>,
    clickedColumn?: ColumnData
  ) => void;
};

export type InvoiceBatchRow = InvoiceBatches_invoiceBatches_nodes & {
  transactionType: string | null;
  name: string | null | undefined;
  date: string | null;
  description: string | null;
  invoiceNumber: string | null;
  amount: string | null;
  accountingNo: string | null;
};

interface rowsItemProp {
  rowTimestamp?: string | undefined;
  id?: string | undefined;
  comment?: string | undefined;
  isVerified?: boolean | undefined;
}

interface CommentBoxProps {
  commentValue?: string | null;
  position: number;
}
const CommentBox: React.FC<CommentBoxProps> = ({ commentValue, position }) => {
  const [enable, setEnable] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const logicEnable =
      commentValue === null ||
      commentValue === undefined ||
      commentValue.length === 0;

    setEnable(logicEnable);
  }, [commentValue]);

  return (
    <>
      {enable ? (
        <IconButton
          iconProps={{ iconName: 'CannedChat' }}
          onClick={() => setEnable(false)}
        />
      ) : (
        <FormikTextField
          styles={{ fieldGroup: { width: 350 } }}
          name={`invoiceBatchesUpdate[${position}].invoiceBatchPatch.comment`}
          placeholder="Enter comment"
        />
      )}
    </>
  );
};
export const InvoiceBatchTransactionModal: React.FC<
  InvoiceBatchTransactionModal
> = ({
  approvalData,
  items,
  loading,
  onClose,
  invoiceBatchesLoading,
  invoiceBatches,
  onSave,
  _onColumnClick,
  gridColumns,
}) => {
  const {
    dirty,
    values: formikValues,
    setFieldValue,
  } = useFormikContext<ValidationFormValues>();

  const { invoiceBatchesUpdate } = { ...formikValues };
  const [requestedIndices, setRequestedIndices] = useState<number[]>([]);
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [selectedRows, setSelectedRows] = useState<InvoiceBatchRow[]>([]);
  const [activeRow, setActiveRow] = useState<string | undefined>();
  const renderRef = useRef<number>(0);
  const setRef = useRef<boolean>(true);

  const _renderItemColumn = (
    item: InvoiceBatchRow | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (!item || !column) return undefined;

    const fieldContent = item[column.fieldName as keyof InvoiceBatchRow] as
      | string
      | null;
    const validate = activeRow === item.id && activeRow !== undefined;
    const position = formikValues.invoiceBatchesUpdate.findIndex(
      (row) => row.id === item.id
    );

    const commentValue =
      invoiceBatchesUpdate[position]?.invoiceBatchPatch?.comment;

    switch (column.key) {
      case 'expand':
        return (
          <IconButton
            iconProps={{
              iconName: !validate ? 'ChevronRightMed' : 'ChevronDownMed',
            }}
            onClick={() => {
              if (validate) {
                setActiveRow(undefined);
              } else setActiveRow(item.id);
            }}
          />
        );
      case '_documentPackageId':
        return (
          <DocumentPackage
            documentPackageId={item?.invoice?._documentPackageId}
          />
        );
      case 'amount':
        return (
          <Stack verticalAlign="center" className={styles.onrenderColumnStack}>
            <AmountTextView
              className={styles.contentColumnAlignRight}
              variant="medium"
              value={fieldContent!}
            />
          </Stack>
        );
      case 'date':
        return (
          <Stack verticalAlign="center">
            <Text className={styles.contentColumnAlignRight}>
              {dateFormat(item.date!)}
            </Text>
          </Stack>
        );
      case 'accountingNo':
        return (
          <Stack verticalAlign="center">
            <Text>{item.invoice?._accountingStampTransactionReference}</Text>
          </Stack>
        );

      case 'comment':
        return (
          <Stack verticalAlign="center">
            <CommentBox commentValue={commentValue} position={position} />
          </Stack>
        );

      default:
        return (
          <Stack verticalAlign="center">
            <Text>{fieldContent}</Text>
          </Stack>
        );
    }
  };

  const onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }

    const item: InvoiceBatchRow = { ...props.item };
    const newProps: IDetailsRowProps | undefined = props
      ? { ...props, className: styles.row }
      : undefined;

    return (
      <>
        {defaultRender!(newProps)}
        {activeRow === item.id && activeRow !== undefined && (
          <TransactionSection invoiceId={item.invoiceId!} />
        )}
      </>
    );
  };

  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      const { appliedAmount } = {
        ...invoiceBatches?.invoiceBatches?.aggregates?.sum,
      };
      const fieldContent = column?.key === 'amount' ? appliedAmount : '';
      return (
        <AmountTextView
          variant="medium"
          className={clsx(
            styles.amountStack,
            styles.contentColumnAlignRight,
            commonStyles.colorThemePrimary
          )}
          value={fieldContent!}
        />
      );
    };

  const canSelectItem = useCallback((item) => {
    return item._isVerifiable;
  }, []);

  useEffect(() => {
    if (renderRef.current > 1) {
      if (selectedRows.length! > 0 && selectedRows.length!) {
        const newFormValues = invoiceBatchesUpdate?.map((item) => {
          const isExisting =
            selectedRows.findIndex((row) => item.id === row.id) !== -1;
          const newData: InvoiceBatchUpdateTypeInput = {
            rowTimestamp: item.rowTimestamp,
            id: item.id,
            invoiceBatchPatch: {
              ...item.invoiceBatchPatch,
              isVerified: isExisting,
            },
          };
          return newData;
        });

        setFieldValue('invoiceBatchesUpdate', newFormValues);
      } else {
        const isAllSelected = items?.length === selectedRows.length;
        const newFormValues = invoiceBatchesUpdate?.map((item) => {
          const newData: InvoiceBatchUpdateTypeInput = {
            ...item,
            invoiceBatchPatch: {
              ...item.invoiceBatchPatch,
              isVerified: isAllSelected,
            },
          };
          return newData;
        });

        setFieldValue('invoiceBatchesUpdate', newFormValues);
      }
    } else {
      renderRef.current = renderRef.current + 1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  // Called only once just after opening modal to set the already verified rows
  useEffect(() => {
    if (setRef && invoiceBatchesUpdate.length) {
      const requestedIds = invoiceBatchesUpdate
        ?.map((item, index) => ({
          index,
          isVerified: item.invoiceBatchPatch?.isVerified,
        }))
        .filter((item) => item.isVerified)
        .map((item) => item.index);
      setRequestedIndices(requestedIds || []);
      setRef.current = false;
    }
  }, [invoiceBatchesUpdate]);

  return (
    <ModalWrapper isOpen isBlocking>
      <Stack
        className={
          activeRow !== undefined ? styles.containerExpanded : styles.container
        }
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          className={styles.stickyInsideStack}
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">Batch:</Text>
            <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
              {approvalData?.description1}
            </Text>
            <Text variant="xLarge">{`-  (${approvalData?.currency?.isoCode})`}</Text>
          </Stack>

          <CloseButton onClick={onClose!} />
        </Stack>

        <InfiniteList
          loading={invoiceBatchesLoading}
          items={items}
          compact
          hasNextPage={invoiceBatches?.invoiceBatches?.pageInfo.hasPreviousPage}
          showFooter={true}
          columns={gridColumns}
          onRenderItemColumn={_renderItemColumn}
          onSelectionChanged={setSelectedRows}
          onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
          canSelectItem={canSelectItem}
          onRenderRow={onRenderRow}
          onColumnHeaderClick={_onColumnClick}
          releasedIndices={requestedIndices}
          isSelectedOnFocus={false}
          disableFullRowSelect={true}
        />

        <Stack>
          {loading && <ProgressIndicator />}
          <Stack
            tokens={{ childrenGap: 10 }}
            horizontal
            horizontalAlign="space-between"
            className={styles.stickyInsideBottomStack}
          >
            <PrimaryButton
              onClick={onSave}
              text="Save"
              disabled={loading || !dirty}
            />
            <DefaultButton onClick={onClose} text="Close" />
          </Stack>
        </Stack>
      </Stack>
    </ModalWrapper>
  );
};

export const InvoiceBatchForm: React.FC<InvoiceBatchFormProps> = (props) => {
  const { batchTransactionId, refetchAllApproval, approvalData } = { ...props };
  const { addToast } = useToasts();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);

  const {
    loading: invoiceBatchesLoading,
    data: invoiceBatches,
    networkStatus,
    refetch,
  } = useQuery<InvoiceBatches, InvoiceBatchesVariables>(INVOICE_BATCHES, {
    variables: {
      orderBy: toOrderByVariable(),
      id: batchTransactionId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const [updateBatchInvoice, { loading: updateLoading }] = useMutation<
    InvoiceBatchValidate,
    InvoiceBatchValidateVariables
  >(INVOICE_BATCHE_VALIDATE);

  const refetching =
    invoiceBatchesLoading && networkStatus !== NetworkStatus.fetchMore;

  let transformedData = refetching
    ? undefined
    : invoiceBatches?.invoiceBatches?.nodes.map(
        (inv) =>
          ({
            ...inv,
            name: inv?.invoice?.vendorReference,
            transactionType: inv?.invoiceBatchTransactionType?.transactionType,
            description: inv?.invoice?.description,
            date: inv?.invoice ? inv?.invoice?.invoiceDate : '',
            invoiceNumber: inv?.invoice?.invoiceNumber,
            amount: inv?.appliedAmount,
            accountingNo: inv?.invoice?._accountingStampTransactionReference,
          }) as InvoiceBatchRow
      );

  const initialValues: ValidationFormValues = {
    invoiceBatchesUpdate:
      transformedData?.map(
        (item) =>
          ({
            id: item.id,
            rowTimestamp: item._rowTimestamp,
            invoiceBatchPatch: {
              comment: item.comment,
              isVerified: item.isVerified,
            },
          }) as InvoiceBatchUpdateTypeInput
      ) || [],
  };

  const handleSubmit = async (values: ValidationFormValues) => {
    const { errors } = await updateBatchInvoice({
      variables: {
        input: {
          batchTransactionId,
          invoiceBatchesUpdate: values.invoiceBatchesUpdate,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: refetchAllApproval
            ? GET_APPROVAL_HISTORY_DETAILS
            : GET_APPROVAL_DETAILS,
          variables: {
            id: approvalData?.id!,
          },
        },
      ],
    });
    if (!errors) {
      addToast('Transaction validated successfully', {
        appearance: 'success',
      });
    } else
      addToast('Failed to validate transaction', {
        appearance: 'error',
      });
  };

  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );
        setGridColumns(newColumns);
        await refetch({
          orderBy: toOrderByVariable({
            column: clickedColumn.key,
            direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
          }),
        });
      }
    },
    [gridColumns, refetch]
  );

  return (
    <Formik<ValidationFormValues>
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {({ submitForm }) => {
        return (
          <Form>
            <InvoiceBatchTransactionModal
              {...props}
              items={transformedData}
              loading={updateLoading}
              invoiceBatchesLoading={invoiceBatchesLoading}
              invoiceBatches={invoiceBatches}
              onSave={submitForm}
              gridColumns={gridColumns}
              _onColumnClick={_onColumnClick}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
