import React from 'react';
import { Separator, Stack } from '@fluentui/react';
import { NoDataView } from 'common/components/DataPlaceholders';
import { DocumentList } from 'common/components/DocumentList';
import {
  Approval,
  ApprovalVariables,
  Approval_approval,
} from '../../__generated__/Approval';
import { AttachFormModal } from './AttachForm';
import { UploadForm } from './UploadForm';
import { loader } from 'graphql.macro';
import { useApolloClient, useQuery } from '@apollo/client';
import { ApprovalSetupUploadDocument_approvalSetupUploadDocument } from './UploadForm/__generated__/ApprovalSetupUploadDocument';
import { ApprovalSetupCommonData_secureRowLevels } from 'settings/account/approvalSetup/__generated__/ApprovalSetupCommonData';
import { ApprovalAvailableDocumentTypes } from './__generated__/ApprovalAvailableDocumentTypes';
import { columns } from '../../column.data';
const APPROVAL_DETAILS = loader('../../../view/Approval.graphql');
const AVAILABLE_DOCUMENT_TYPES = loader(
  './ApprovalAvailableDocumentTypes.graphql'
);
interface AttachSectionProps {
  approvalSetupDetails: Approval_approval | null | undefined;
  entityAttachData: Approval_approval | undefined;
  onUpload?: (
    fileSelected: File,
    documentData: ApprovalSetupUploadDocument_approvalSetupUploadDocument,
    toastId: string
  ) => void;
  secureRowLevel: ApprovalSetupCommonData_secureRowLevels;
}
export const AttachSection: React.FC<AttachSectionProps> = ({
  approvalSetupDetails,
  entityAttachData,
  onUpload,
  secureRowLevel,
}) => {
  const client = useApolloClient();

  const { data: AvailableDocumentTypes } =
    useQuery<ApprovalAvailableDocumentTypes>(AVAILABLE_DOCUMENT_TYPES, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });
  return (
    <Stack tokens={{ childrenGap: 10, padding: '10px 0px 0px 0px' }}>
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{ childrenGap: 10 }}
      >
        <AttachFormModal
          AvailableDocumentTypes={AvailableDocumentTypes}
          approvalSetup={approvalSetupDetails}
        />
        <Separator vertical />
        <UploadForm
          availableDocumentTypes={AvailableDocumentTypes}
          approvalSetup={approvalSetupDetails}
          onUpload={onUpload}
        />
      </Stack>
      {entityAttachData?.entityDocumentsByEntityId?.nodes?.length! > 0 && (
        <Stack tokens={{ childrenGap: 10, padding: 10 }}>
          <DocumentList
            isNew={false}
            columns={columns}
            data={entityAttachData?.entityDocumentsByEntityId?.nodes! || []}
            isDeleteButtonVisible
            isShareAndProtectButtonsVisible
            secureRowLevel={secureRowLevel.nodes!}
            onRemoveRow={(data) => {
              const cacheData = client.readQuery<Approval, ApprovalVariables>({
                query: APPROVAL_DETAILS,
                variables: {
                  id: approvalSetupDetails?.id!,
                },
              });
              const filteredList =
                cacheData?.approval?.entityDocumentsByEntityId.nodes.filter(
                  (ele) => ele.id !== data.id
                );
              const updatedData: Approval = {
                approval: {
                  ...cacheData?.approval!,
                  entityDocumentsByEntityId: {
                    ...cacheData?.approval?.entityDocumentsByEntityId,
                    nodes: filteredList!,
                  },
                },
              };
              client.writeQuery<Approval, ApprovalVariables>({
                query: APPROVAL_DETAILS,
                data: updatedData,
                variables: {
                  id: approvalSetupDetails?.id!,
                },
              });
            }}
          />
        </Stack>
      )}
      <NoDataView
        show={entityAttachData?.entityDocumentsByEntityId.nodes.length === 0}
        title="No Attachments"
      />
    </Stack>
  );
};
