import {
  NetworkStatus,
  useLazyQuery,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  Checkbox,
  FontSizes,
  FontWeights,
  ICheckboxStyles,
  SelectionMode,
  Stack,
} from '@fluentui/react';
import {
  InvoiceSigningSearchFilterTotals,
  InvoiceSigningSearchFilterTotalsVariables,
} from 'ap/signing/__generated__/InvoiceSigningSearchFilterTotals';
import {
  InvoiceSigningSearchHints,
  InvoiceSigningSearchHintsVariables,
} from 'ap/signing/__generated__/InvoiceSigningSearchHints';
import {
  InvoicesSigningSearch,
  InvoicesSigningSearchVariables,
} from 'ap/signing/__generated__/InvoicesSigningSearch';
import { StampTransactionFooter } from 'ap/signing/components/StampTransaction';
import { TransactionSearchBar } from 'ap/signing/components/TransactionSearchBar';
import {
  ColumnStylesProps,
  onRenderFooterItems,
  onRenderItems,
} from 'ap/signing/transactionSigning/list/columns.render';
import { handleSearchGlobal } from 'ap/signing/transactionSigning/list/utils';
import { InvoiceRow } from 'ap/signing/types';
import { getTransformedData } from 'ap/signing/utils.common';
import { FilterArrayType } from 'common/components/Filters';
import { InfiniteList } from 'common/components/InfiniteList';
import { MultiSelectOption } from 'common/components/MultiSelectTags';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { InvoicesOrderBy } from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import {
  expandedNav,
  globalMode,
  setGlobalSearchText
} from 'utility/cache/ui';
import { DefaultDataViewEnum, Header } from './Header';
import { useStyles } from './index.styles';
const INVOICES = loader('../../InvoicesSigningSearch.graphql');
const INVOICES_FILTER_TOTAL = loader(
  '../../InvoiceSigningSearchFilterTotals.graphql'
);
const INVOICE_SIGNING_SEARCH_HINTS = loader(
  '../../InvoiceSigningSearchHints.graphql'
);

interface AccountingEntryProps {
  isSigningSearch: boolean;
  toggleActions: () => void;
  onHistoryCheckbox: () => void;
  onDefaultDataViewChange: (param: boolean) => void;
  isSearchAllData: boolean;
  isHistorySearch: boolean;
  gridColumns: ColumnData[];
  setGridColumns: React.Dispatch<React.SetStateAction<ColumnData[]>>;
  sortOrderParam: SortOrder | undefined;
  setSortOrderParam: React.Dispatch<
    React.SetStateAction<SortOrder | undefined>
  >;
}

export const AccountingEntry: React.FC<AccountingEntryProps> = ({
  isSigningSearch,
  toggleActions,
  onHistoryCheckbox,
  onDefaultDataViewChange,
  isHistorySearch,
  gridColumns,
  setGridColumns,
  sortOrderParam,
  setSortOrderParam,
  ...props
}) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const [exportedIndices, setExportedIndices] = useState<number[]>([]);
  const [isAllExportChecked, setIsAllExportChecked] = React.useState(false);
  const globalSearchText = useReactiveVar(setGlobalSearchText);
  const [selectedRows, setSelectedRows] = useState<InvoiceRow[]>([]);
  const [urlParameters, setUrlParameters] = useQueryParams({
    t: StringParam,
  });
  const [transactionStampField, setTransactionStampField] = useState<
    Map<string, string>
  >(new Map());
  const [stampTransaction, setStampTransaction] = useState(false);
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>([]);
  const { t: searchParam } = urlParameters;
  const [searchTextValue, setSearchTextValue] = useState<string | null>(
    searchParam! || null
  );
  const [isSearchAllData, setIsSearchAllData] = useState(props.isSearchAllData);
  const [searchHints, setSearchHints] = useState<number | null>();
  const globalState = useReactiveVar(globalMode);
  const [defaultDataView, setDefaultDataView] = useState<string>();

  const [
    getInvoiceListData,
    {
      loading: invoicesLoading,
      data: invoicesData,
      fetchMore,
      networkStatus,
      refetch,
      variables,
    },
  ] = useLazyQuery<InvoicesSigningSearch, InvoicesSigningSearchVariables>(
    INVOICES,
    {
      variables: {
        isSigningSearch: false,
        isHistorySearch: false,
        first: TABLE_ROWS,
        orderBy: [
          InvoicesOrderBy._CREATED_DATE_DESC,
          InvoicesOrderBy.PRIMARY_KEY_ASC,
        ],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const { data: invoicesFilterTotal, refetch: refetchFilterTotal } = useQuery<
    InvoiceSigningSearchFilterTotals,
    InvoiceSigningSearchFilterTotalsVariables
  >(INVOICES_FILTER_TOTAL, {
    variables: {
      isSigningSearch: false,
      isHistorySearch: false,
      isLinkSearch: false,
      isSearchAllData: isSearchAllData,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [fetchHints, { data: hintsData }] = useLazyQuery<
    InvoiceSigningSearchHints,
    InvoiceSigningSearchHintsVariables
  >(INVOICE_SIGNING_SEARCH_HINTS, {
    fetchPolicy: 'network-only',
  });

  const { invoiceSigningSearch } = {
    ...invoicesData,
  };
  const { invoiceSigningSearchFilterTotals } = {
    ...invoicesFilterTotal,
  };
  const { nodes, pageInfo } = { ...invoiceSigningSearch };
  const { totalAmount1, totalCount1 } = { ...invoiceSigningSearchFilterTotals };
  const refetching =
    invoicesLoading && networkStatus !== NetworkStatus.fetchMore;
  const showFooter =
    !refetching && parseFloat(totalAmount1 || '0.0') > 0 && totalCount1! > 0;
  const transformedData = getTransformedData(refetching, nodes);
  const columnStyles: ColumnStylesProps = {
    cellContainer: styles.cellContainer,
    amountStack: styles.amountStack,
    statusType: styles.statusType,
    logo: styles.logo,
  };

  const onSetTransactionStampField = (
    id: string,
    value: string | undefined
  ) => {
    if (value && value !== '') {
      setTransactionStampField(new Map(transactionStampField.set(id, value)));
    } else {
      transactionStampField.delete(id);
      setTransactionStampField(new Map(transactionStampField));
    }
  };

  const _renderItemColumn = useCallback(
    onRenderItems(
      globalSearchText,
      columnStyles,
      transactionStampField,
      onSetTransactionStampField
    ),
    [columnStyles, globalSearchText]
  );

  const _renderDetailsFooterItemColumn = onRenderFooterItems(
    invoicesFilterTotal,
    styles
  );
  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  const searchHintsOptions: MultiSelectOption[] =
    hintsData?.invoiceSigningSearchHints?.nodes.map(
      (hint) =>
        ({
          key: hint.id?.toString(),
          text: `${hint.caption} (${hint.resultsCount})`,
        } as MultiSelectOption)
    ) || [];

  const isHintsAvailable =
    searchHintsOptions.length > 0 && searchTextValue?.length! > 0;

  const handleSearch = async (showMore: boolean, newHints: boolean) => {
    if (!showMore) {
      setSelectedRows([]);
    }
    const {
      searchQueryParam,
      searchRequest,
      variableHints,
      queryVariables,
      queryTotalFilterVariables,
    } = await handleSearchGlobal(
      showMore,
      globalState,
      searchTextValue,
      searchHints,
      invoicesData,
      sortOrderParam,
      searchFilters,
      isSearchAllData,
      false,
      isHistorySearch
    );
    setUrlParameters({ t: searchQueryParam }, 'replace');
    if (showMore) await fetchMore?.({ variables: queryVariables });
    else {
      getInvoiceListData({ variables: queryVariables });
      refetchFilterTotal(queryTotalFilterVariables);
    }
    if (newHints && searchRequest && !searchHints) {
      setSearchHints(null);
      fetchHints({
        variables: variableHints,
      });
    }
  };

  const renderRef = useRef(false);

  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
    searchTextValue,
    searchHints,
    isHistorySearch,
    isSearchAllData,
  ]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false, true);
  }, [searchFilters, sortOrderParam, handleSearchMemo]);

  useEffect(() => {
    handleSearchMemo(false, true);
    renderRef.current = true;
  }, [searchTextValue, handleSearchMemo]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false, false);
  }, [searchHints, handleSearchMemo]);

  useEffect(() => {
    if (props.isSearchAllData)
      setDefaultDataView(DefaultDataViewEnum.all_Transactions);
    else setDefaultDataView(DefaultDataViewEnum.my_Transactions);
  }, [props.isSearchAllData]);

  const showRequestAll = transformedData?.some(
    (invoice) => invoice.companyTransactionType?.exportLayout
  );
  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Header
          selectedRows={selectedRows}
          invoicesData={invoicesData}
          refetch={() => refetch?.(variables)}
          defaultDataView={defaultDataView}
          variables={variables}
          isHistorySearch={isHistorySearch}
          onDataViewChange={(option) => {
            setDefaultDataView(option?.toString() || '');
            if (option === DefaultDataViewEnum.all_Transactions) {
              onDefaultDataViewChange(true);
              setIsSearchAllData(true);
            } else {
              onDefaultDataViewChange(false);
              setIsSearchAllData(false);
            }
          }}
          onStamp={() => {
            setStampTransaction((prevState) => !prevState);
            expandedNav(stampTransaction);
            const newColumns = gridColumns.map((item) => {
              if (item.key === 'transactionStamp')
                return { ...item, isVisible: !stampTransaction };
              else return item;
            });
            setGridColumns(newColumns);
          }}
        />
        <Stack>
          <TransactionSearchBar
            urlParams={{
              searchParam: searchParam!,
            }}
            disabled={stampTransaction}
            toggleActions={() => {
              if (globalState) globalMode(false);
              toggleActions();
            }}
            onHistoryCheckbox={onHistoryCheckbox}
            isSigningSearch={isSigningSearch}
            columns={gridColumns}
            hintsAvailable={isHintsAvailable}
            onRefresh={() => handleSearch(false, false)}
            onEnterPress={(value) => {
              setSearchHints(null);
              setSearchTextValue(value);
              setGlobalSearchText(value);
            }}
            onFilterChange={setSearchFilters}
            onToggleVisibility={setGridColumns}
            onGlobalToggle={(value) => {
              handleSearchGlobal(
                true,
                value,
                searchTextValue,
                searchHints,
                invoicesData,
                sortOrderParam,
                searchFilters,
                isSearchAllData,
                false,
                isHistorySearch
              );
            }}
          />

          <Stack horizontalAlign="end">
            {showRequestAll && isHistorySearch === false && (
              <Checkbox
                boxSide="end"
                styles={checkBoxStyle}
                checked={isAllExportChecked}
                label="Export All"
                onChange={(_, value) => {
                  setIsAllExportChecked(!isAllExportChecked!);
                  if (value) {
                    const result = transformedData
                      ?.map((data, index) => ({ index, data }))
                      .filter(
                        ({ data }) => data?.companyTransactionType?.exportLayout
                      )
                      .map((data) => data.index);
                    setExportedIndices(result || []);
                  } else {
                    setExportedIndices([]);
                  }
                }}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      <InfiniteList
        loading={invoicesLoading}
        items={transformedData}
        hasNextPage={pageInfo?.hasNextPage}
        showFooter={showFooter}
        selectionMode={SelectionMode.multiple}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={async () => await handleSearch(true, false)}
        onSelectionChanged={setSelectedRows}
        requestedIndices={exportedIndices}
        totalRowsCount={totalCount1}
      />

      <StampTransactionFooter
        isStampTransactionMode={stampTransaction}
        invoices={transformedData}
        stampFields={transactionStampField}
        onStampProcessComplete={() => {
          expandedNav(true);
          setStampTransaction(false);
          setTransactionStampField(new Map([]));
          const newColumns = gridColumns.map((item) => {
            if (item.key === 'transactionStamp')
              return { ...item, isVisible: false };
            else return item;
          });
          setGridColumns(newColumns);
        }}
      />
    </>
  );
};

const checkBoxStyle: ICheckboxStyles = {
  label: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.size10,
    color: '#a9a9a9',
  },
};
