import {
  EntityDeleteInput,
  TransactionLayout,
  TripDistributionUpdateTypeInput,
  TripItemInput,
  TripItemUpdateTypeInput,
  TripPatch,
} from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { differenceBy, intersection, isEmpty } from 'lodash';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { SectionProps } from '.';
import { TravelerAuthorizationCommonData } from '../../__generated__/TravelerAuthorizationCommonData';
import {
  Trip,
  Trip_trip_tripItems_nodes,
  Trip_trip_tripItems_nodes_tripDistributions_nodes,
} from './__generated__/Trip';
import { TripCreateVariables } from './__generated__/TripCreate';
import {
  TripCreateFormValues,
  TripDistributionFormValues,
  TripItemFormValues,
  TripType,
} from './types';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import {
  STOP_MODAL_INITIAL_VALUES,
  TRIP_DISTRIBUTION_INITIAL_VALUES,
} from './constants';
import { convertToPascalCase } from 'common/utils/convertToTitleCase';

const getReducedTripDistributions = (values: TripCreateFormValues) => {
  const result = values.tripItems.reduce((prev, curr) => {
    return [...prev, ...(curr.tripDistributions || [])];
  }, [] as TripDistributionFormValues[]);

  return result;
};
const getTripVariables = (
  values: TripCreateFormValues,
  travelAuth:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined,
  userDefaults: UserDefaults_userDefaults_nodes | undefined
) => {
  const valuesTripDistributions = getReducedTripDistributions(values);
  const { tripItems } = { ...values };
  const tripItemsArray = getTripItems(tripItems);
  const tripDistribution = getTripDistribution(
    valuesTripDistributions,
    userDefaults
  );

  if (travelAuth) {
    // @ts-ignore

    const returnValues = {
      input: {
        rowTimestamp: travelAuth._rowTimestamp,
        travelAuthorizationId: travelAuth.id,
        trip: { travelerId: values.trip.travelerId },
        tripItem: tripItemsArray,
        tripDistribution,
      },
    } as TripCreateVariables;
    return returnValues;
  } else return undefined;
};

const getTime = (time: string) => {
  return time.slice(0, 5);
};

const getTripItems = (values: TripItemFormValues[]) => {
  if (values.length > 0) {
    const itemsValues = values
      .filter((item) => !!item.tripSectionItemId)
      .map((item) => {
        const updatedObject = Object.entries(item)?.reduce(
          (res, [key, val]) => {
            if (
              key === 'layout' ||
              key === 'id' ||
              key === 'tripSectionId' ||
              key === '_rowTimestamp' ||
              key === 'tripDistributions' ||
              key === 'departureDepotName' ||
              key === 'arrivalDepotName' ||
              key === 'carrierName' ||
              key === 'returnDepotName' ||
              key === 'returnArrivalDepotName' ||
              key === 'returnCarrierName' ||
              key === 'pickupSupplier' ||
              key === 'pickupDropSupplier' ||
              key === 'returnDropSupplier' ||
              key === 'returnSupplier' ||
              key === 'stopModalReturnItems' ||
              key === 'stopModalItems'
            )
              return res;
            if (
              key === 'bookingFeeQuantity' ||
              key === 'perDiemQuantity' ||
              key === 'incidentalQuantity' ||
              key === 'miscellaneousQuantity' ||
              key === 'departureMiles' ||
              key === 'returnMiles' ||
              key === 'departureStops' ||
              key === 'returnStops'
            )
              return { ...res, [key]: val ? Number(val) : null };
            else if (
              key === 'departureDate' ||
              key === 'departureArrivalDate' ||
              key === 'returnDate' ||
              key === 'returnArrivalDate' ||
              key === 'checkInDate' ||
              key === 'checkOutDate'
            )
              return { ...res, [key]: val ? dateFormat(val.toString()) : null };
            else if (
              key === 'departureTime' ||
              key === 'departureArrivalTime' ||
              key === 'returnTime' ||
              key === 'returnArrivalTime'
            )
              return {
                ...res,
                [key]: val ? getTime(val.toString()) : null,
              };
            else if (key === 'preferredRateTypeId') {
              return {
                ...res,
                [key]: val ? Number(val) : 0,
              };
            } else return { ...res, [key]: val };
          },
          {}
        );

        const tripStops = getTripStops(values);

        return { ...updatedObject, ...tripStops };
      });
    return itemsValues;
  } else return [];
};

const getSections = (
  trip: Trip | undefined,
  commonData: TravelerAuthorizationCommonData | undefined,
  layoutAdded: string | null
) => {
  if (!trip) {
    return { map: null, initialLayout: null };
  }
  const map = new Map<string, SectionProps>();
  const initialLayout = layoutAdded
    ? layoutAdded
    : trip?.trip?.tripItems?.nodes
        .map((item) => item.tripSection)
        ?.sort(
          (a, b) =>
            parseInt(a?.sortOrder || '0') - parseInt(b?.sortOrder || '0')
        )[0]?.layoutType!;

  trip?.trip?.tripItems.nodes.forEach((tripItem, index) => {
    const data = commonData?.tripSections?.nodes.find((item) => {
      return item.layoutType === tripItem.tripSection?.layoutType;
    });
    map.set(tripItem.tripSection?.layoutType!, data!);
  });

  return { map, initialLayout };
};

const getDefaultValues = (
  trip?: Trip | undefined,
  commonData?: TravelerAuthorizationCommonData | undefined,
  userDefaults?: UserDefaults_userDefaults_nodes | undefined
) => {
  const { referenceCode6, referenceCode7, project, accountCode } = {
    ...userDefaults,
  };

  const projectReference =
    userDefaults?.distributionLayoutType?.layoutType ===
    TransactionLayout.EPISODIC_DISTRIBUTION_LAYOUT
      ? project?.code
      : undefined;

  let defaultValues: TripCreateFormValues = {
    trip: {
      travelerId: null,
      travelerFullName: null,
      specialInstructions: null,
      comments: null,
      _isOverBudget: null,
      travelPolicy: null,
    },
    tripItems: [],
  };

  const { travelerId, traveler, specialInstructions, comments, _isOverBudget } =
    {
      ...trip?.trip,
    };
  if (trip && trip.trip?.tripItems && commonData) {
    const newTripItems = trip.trip?.tripItems.nodes.map((itemTrip) => {
      const isFlight =
        itemTrip._tripSectionLayout ===
        TransactionLayout.TRAVEL_BOOKING_AIR_TRAVEL;

      const stopModalItems = isFlight ? getStopItemArray(itemTrip, true) : [];
      const stopModalReturnItems = isFlight
        ? getStopItemArray(itemTrip, false)
        : [];

      const data = {
        ...Object.entries(itemTrip).reduce((prev, [key, value]) => {
          if (
            key === 'isIndividual' ||
            key === '__typename' ||
            key === '_tripSectionId' ||
            key === 'tripId' ||
            key === 'supplier'
          ) {
            return prev;
          } else if (
            key === 'departureArrivalTime' ||
            key === 'departureTime' ||
            key === 'returnArrivalTime' ||
            key === 'returnTime' ||
            key === 'returnArrivalTime'
          ) {
            return { ...prev, [key]: value };
          } else if (key === 'tripSection') {
            const tripSectionFound = commonData?.tripSections?.nodes.find(
              (item) => item.layoutType === value['layoutType']
            );
            return {
              ...prev,
              layout: tripSectionFound?.layoutType,
              tripSectionId: tripSectionFound?.id,
            } as TripItemFormValues;
          } else if (key === 'departureDepot') {
            return {
              ...prev,
              departureDepotName: value?.['description'],
            } as TripItemFormValues;
          } else if (key === 'departureArrivalDepot') {
            return {
              ...prev,
              arrivalDepotName: value?.['description'],
            } as TripItemFormValues;
          } else if (key === 'departureCarrier') {
            return {
              ...prev,
              carrierName: value?.['description'],
            } as TripItemFormValues;
          } else if (key === 'returnDepot') {
            return {
              ...prev,
              returnDepotName: value?.['description'],
            } as TripItemFormValues;
          } else if (key === 'returnArrivalDepot') {
            return {
              ...prev,
              returnArrivalDepotName: value?.['description'],
            } as TripItemFormValues;
          } else if (key === 'returnCarrier') {
            return {
              ...prev,
              returnCarrierName: value?.['description'],
            } as TripItemFormValues;
          } else if (key === 'tripDistributions') {
            const { _tripSectionId } = { ...itemTrip };
            return {
              ...prev,
              tripDistributions: !!itemTrip.tripDistributions.nodes.length
                ? [
                    ...itemTrip.tripDistributions.nodes.map((row) => {
                      const patch = Object.entries(row).reduce(
                        (res, [key, val]) => {
                          if (
                            key === '_isUpdatable' ||
                            key === '_isDeletable' ||
                            key === '_createdDate' ||
                            key === '__typename'
                          )
                            return res;
                          else if (key === 'allocationPercent')
                            return {
                              ...res,
                              allocationPercent: val
                                ? parseInt(val).toString()
                                : null,
                            };
                          else return { ...res, [key]: val };
                        },
                        {}
                      );
                      return patch as TripDistributionFormValues;
                    }),
                    {
                      ...TRIP_DISTRIBUTION_INITIAL_VALUES,
                      referenceCode6: referenceCode6!,
                      referenceCode7: referenceCode7!,
                      projectReference: projectReference!,
                      tripSectionId: _tripSectionId,
                      accountReference: accountCode ? accountCode : null,
                    },
                  ]
                : [
                    {
                      ...TRIP_DISTRIBUTION_INITIAL_VALUES,
                      referenceCode6: referenceCode6!,
                      referenceCode7: referenceCode7!,
                      tripSectionId: _tripSectionId,
                      projectReference: projectReference!,
                      accountReference: accountCode ? accountCode : null,
                    },
                  ],
            } as TripItemFormValues;
          } else if (key === 'currency') {
            return {
              ...prev,
              currencyId: itemTrip.currency?.id || null,
            };
          } else if (key === 'preferredRateTypeId') {
            return {
              ...prev,
              preferredRateTypeId:
                itemTrip.preferredRateTypeId?.toString() || '0',
            };
          } else if (
            key === 'departureDate' ||
            key === 'departureArrivalDate' ||
            key === 'returnDate' ||
            key === 'returnArrivalDate' ||
            key === 'checkInDate' ||
            key === 'checkOutDate'
          ) {
            return {
              ...prev,
              [key]: value ? dateFormat(dateConvertions(value)) : null,
            };
          } else return { ...prev, [key]: value };
        }, {}),
      } as TripItemFormValues;

      return { ...data, stopModalItems, stopModalReturnItems };
    });

    const data = {
      trip: {
        travelerId: travelerId,
        travelerFullName: traveler?._fullName,
        specialInstructions,
        comments,
        _isOverBudget,
        travelPolicy: traveler?.travelPolicy?.description,
      },
      tripItems: newTripItems,
    } as TripCreateFormValues;

    defaultValues = data;
  }

  return defaultValues;
};

const getStopItemArray = (
  itemTrip: Trip_trip_tripItems_nodes,
  isDepart: boolean
) => {
  const departureDate = itemTrip.departureDate
    ? dateFormat(dateConvertions(itemTrip.departureDate)).toString()
    : null;
  const returnDate = itemTrip.returnDate
    ? dateFormat(dateConvertions(itemTrip.returnDate)).toString()
    : null;
  if (
    itemTrip._tripSectionLayout === TransactionLayout.TRAVEL_BOOKING_AIR_TRAVEL
  ) {
    const numberOfIterations = 4;
    const result = Array.from({ length: numberOfIterations }, (_, index) => {
      const baseField = isDepart ? `depart${index + 1}` : `return${index + 1}`;
      const nameBaseField = isDepart
        ? `departure${index + 1}`
        : `return${index + 1}`;

      // return {
      //   ...prev,
      //   [key]: value ? dateFormat(dateConvertions(value)) : null,
      // };
      // depotId: null,
      // arriveDate: null,
      // arriveTime: null,
      // departTerminal: null,
      // arriveTerminal: null,
      // departDate: null,
      // departTime: null,
      // depotName: null,

      // const resultObj = Object.entries(STOP_MODAL_INITIAL_VALUES).reduce(
      //   (prev, [key]) => {
      //     const keyField = `${baseField}${convertToPascalCase(key)}`;
      //     const value = itemTrip[keyField as keyof Trip_trip_tripItems_nodes];

      //     if (key !== 'depotName') {
      //       if (key === 'arriveDate' || key === 'departDate') {
      //         return {
      //           ...prev,
      //           [key]: value
      //             ? dateFormat(dateConvertions(value as string))
      //             : null,
      //         };
      //       } else
      //         return {
      //           ...prev,
      //           [key]: value,
      //         };
      //     } else return prev;
      //   },
      //   {}
      // );
      // return resultObj;
      if (itemTrip[`${baseField}DepotId` as keyof Trip_trip_tripItems_nodes])
        return {
          depotName:
            // @ts-ignore
            itemTrip[
              `${nameBaseField}Depot` as keyof Trip_trip_tripItems_nodes
            ]?.[`description`],
          depotId:
            itemTrip[`${baseField}DepotId` as keyof Trip_trip_tripItems_nodes],
          arriveDate:
            // @ts-ignore
            itemTrip[`${baseField}ArriveDate`]
              ? dateFormat(
                  dateConvertions(
                    itemTrip[
                      `${baseField}ArriveDate` as keyof Trip_trip_tripItems_nodes
                    ] as string
                  )
                )
              : null,
          // itemTrip[`${baseField}ArriveDate` as keyof Trip_trip_tripItems_nodes],
          arriveTime:
            itemTrip[
              `${baseField}ArriveTime` as keyof Trip_trip_tripItems_nodes
            ],
          arriveTerminal:
            itemTrip[
              `${baseField}ArriveTerminal` as keyof Trip_trip_tripItems_nodes
            ],
          departTerminal:
            itemTrip[
              `${baseField}DepartTerminal` as keyof Trip_trip_tripItems_nodes
            ],
          departDate:
            // @ts-ignore
            itemTrip[`${baseField}DepartDate`]
              ? dateFormat(
                  dateConvertions(
                    itemTrip[
                      `${baseField}DepartDate` as keyof Trip_trip_tripItems_nodes
                    ] as string
                  )
                )
              : null,
          departTime:
            itemTrip[
              `${baseField}DepartTime` as keyof Trip_trip_tripItems_nodes
            ],
        };
      else return {};
    });

    const resultArray = result.filter(
      (obj) =>
        !Object.values(obj).every(
          (value) => value === null || value === undefined
        )
    );
    return !!resultArray.length
      ? resultArray
      : [
          {
            ...STOP_MODAL_INITIAL_VALUES,
            arriveDate: isDepart ? departureDate : returnDate,
            departDate: isDepart ? departureDate : returnDate,
          },
        ];
  } else return [];
};

const getNonNullRows = (
  distributionRows: TripDistributionFormValues[],
  userDefaults?: UserDefaults_userDefaults_nodes | undefined
) => {
  return distributionRows.filter((row) => {
    const patch = Object.entries(row).reduce((res, [key, val]) => {
      if (
        (key === 'referenceCode6' && val === userDefaults?.referenceCode6) ||
        (key === 'referenceCode7' && val === userDefaults?.referenceCode7) ||
        (key === 'accountReference' && val === userDefaults?.accountCode) ||
        key === 'tripSectionId' ||
        key === 'allocationPercent' ||
        val === 100
      )
        return res;
      else if (
        (key === 'projectReference' &&
          val === TransactionLayout.EPISODIC_DISTRIBUTION_LAYOUT) ||
        (key === 'projectReference' &&
          val !== TransactionLayout.DEFAULT_DISTRIBUTION_LAYOUT)
      )
        return res;
      else if (
        val !==
        TRIP_DISTRIBUTION_INITIAL_VALUES[
          key as keyof TripDistributionFormValues
        ]
      )
        return { ...res, [key]: val };
      return res;
    }, {});

    return !isEmpty(patch);
  });
};

const getTripDistribution = (
  tripDistributions: TripDistributionFormValues[],
  userDefaults: UserDefaults_userDefaults_nodes | undefined
) => {
  // @ts-ignore
  const distributions = getNonNullRows(
    tripDistributions || [],
    userDefaults
  ).map((item) => {
    return Object.entries(item).reduce((rest, [key, value]) => {
      if (
        key === 'id' ||
        key === '_rowTimestamp' ||
        key === 'pickupSupplier' ||
        key === 'pickupDropSupplier' ||
        key === 'returnDropSupplier' ||
        key === 'returnSupplier'
      )
        return rest;
      else return { ...rest, [key]: value };
    }, {});
  }) as TripDistributionFormValues[];

  return distributions;
};

const getUpdateTripItems = (
  initialTripItems: TripItemFormValues[],
  currentTripItems: TripItemFormValues[]
) => {
  const AirTravelItemID = currentTripItems.find(
    (item) => item.layout === TransactionLayout.TRAVEL_BOOKING_AIR_TRAVEL
  );
  const updatedTripItems =
    initialTripItems &&
    currentTripItems &&
    intersection(
      initialTripItems.map((item) => item.id),
      currentTripItems.filter((item) => item.id).map((item) => item.id)
    ).reduce((arr, targetId) => {
      const initialItems = initialTripItems!.find(
        (item) => item.id === targetId
      )!;
      const {
        id,
        _rowTimestamp,
        departureDepotName,
        arrivalDepotName,
        carrierName,
        returnDepotName,
        returnArrivalDepotName,
        returnCarrierName,
        ...updatedItems
      } = currentTripItems!.find((item) => item.id === targetId)!;
      const tripStops =
        targetId === AirTravelItemID?.id ? getTripStops(currentTripItems) : {};
      const patch = Object.entries(updatedItems).reduce((res, [key, val]) => {
        if (val !== initialItems[key as keyof TripItemFormValues])
          if (
            key === 'bookingFeeQuantity' ||
            key === 'perDiemQuantity' ||
            key === 'incidentalQuantity' ||
            key === 'miscellaneousQuantity' ||
            key === 'departureStops' ||
            key === 'returnStops' ||
            key === 'departureMiles' ||
            key === 'returnMiles'
          ) {
            return { ...res, [key]: Number(val) };
          } else if (
            key === 'tripDistributions' ||
            key === 'id' ||
            key === '_rowTimestamp' ||
            key === 'pickupSupplier' ||
            key === 'pickupDropSupplier' ||
            key === 'returnDropSupplier' ||
            key === 'returnSupplier' ||
            key === 'departure1Depot' ||
            key === 'departure2Depot' ||
            key === 'departure3Depot' ||
            key === 'departure4Depot' ||
            key === 'return1Depot' ||
            key === 'return2Depot' ||
            key === 'return3Depot' ||
            key === 'return4Depot' ||
            key === 'stopModalReturnItems' ||
            key === 'stopModalItems'
          ) {
            return res;
          } else if (
            key === 'departureDate' ||
            key === 'departureArrivalDate' ||
            key === 'returnDate' ||
            key === 'returnArrivalDate' ||
            key === 'checkInDate' ||
            key === 'checkOutDate'
          ) {
            return {
              ...res,
              [key]: val ? dateFormat(val.toString()) : null,
            };
          } else if (key === 'preferredRateTypeId') {
            return {
              ...res,
              [key]: val ? Number(val) : 0,
            };
          } else return { ...res, [key]: val };
        return res;
      }, tripStops);

      if (!isEmpty(patch)) {
        return [
          ...arr,
          {
            id,
            rowTimestamp: _rowTimestamp,
            tripItemUpdatePatch: patch,
          },
        ];
      }
      return arr;
    }, [] as TripItemUpdateTypeInput[]);
  return updatedTripItems;
};

// type DeletedTripItems = TripItemFormValues & EntityDeleteInput
const getDeletedTripItems = (
  initialTripItems: TripItemFormValues[],
  currentTripItems: TripItemFormValues[]
) => {
  const result =
    differenceBy(initialTripItems, currentTripItems || [], 'id').map(
      (addr) => ({
        ...addr,
      })
    ) || [];

  const deletedTripItems = result.map((addr) => ({
    id: addr.id,
    rowTimestamp: addr._rowTimestamp!,
  })) as EntityDeleteInput[];

  return { deletedTripItems, deletedData: result };
};

const getNewTripItems = (values: TripItemFormValues[]) => {
  const AirTravelItemID = values.find(
    (item) => item.layout === TransactionLayout.TRAVEL_BOOKING_AIR_TRAVEL
  );

  const newTripItems =
    (values
      ?.filter((addr) => !addr.id && addr.tripSectionItemId !== null)
      .filter((item) => !!item.tripSectionItemId)
      .map(
        ({
          id,
          _rowTimestamp,
          layout,
          tripSectionId,
          departureDepotName,
          arrivalDepotName,
          carrierName,
          returnDepotName,
          returnArrivalDepotName,
          returnCarrierName,
          ...addr
        }) => {
          const tripStops =
            id === AirTravelItemID?.id ? getTripStops(values) : {};
          const data = Object.entries(addr).reduce((prev, [key, value]) => {
            if (
              key === 'departureStops' ||
              key === 'departureMiles' ||
              key === 'returnMiles' ||
              key === 'returnStops' ||
              key === 'bookingFeeQuantity' ||
              key === 'perDiemQuantity' ||
              key === 'miscellaneousQuantity' ||
              key === 'incidentalQuantity'
            ) {
              return {
                ...prev,
                [key]: value ? Number(value) : null,
              };
            } else if (
              key === 'tripDistributions' ||
              key === 'stopModalReturnItems' ||
              key === 'stopModalItems'
            ) {
              return prev;
            }

            else if (
              key === 'departureDate' ||
              key === 'departureArrivalDate' ||
              key === 'returnDate' ||
              key === 'returnArrivalDate' ||
              key === 'checkInDate' ||
              key === 'checkOutDate'
            ) {
              return {
                ...prev,
                [key]: value ? dateFormat(value.toString()) : null,
              };
            } else if (key === 'preferredRateTypeId') {
              return {
                ...prev,
                [key]: value ? Number(value) : 0,
              };
            } else return { ...prev, [key]: value };
          }, tripStops);

          return data;
        }
      ) as TripItemInput[]) || [];
  return newTripItems;
};

const getRemovedTripDistributions = (
  trip: Trip | undefined,
  values: TripCreateFormValues,
  deletedTripSections: any[]
) => {
  const valuesTripDistributions = getReducedTripDistributions(values);

  const tripDistributions = trip?.trip?.tripItems?.nodes
    ?.reduce((prev, curr) => {
      return [...prev, ...curr.tripDistributions.nodes];
    }, [] as Trip_trip_tripItems_nodes_tripDistributions_nodes[])
    .filter((item) => {
      return (
        valuesTripDistributions.findIndex((innerItem) => {
          return innerItem.id === item.id;
        }) === -1 &&
        !deletedTripSections.filter(
          (en) => en.tripSectionId === item.tripSectionId
        ).length
      );
    })
    .map(
      (item) =>
        ({
          rowTimestamp: item._rowTimestamp,
          id: item.id,
        }) as EntityDeleteInput
    );
  return tripDistributions;
};

const getUpdatedTripDistributions = (
  values: TripCreateFormValues,
  defaultValues: TripCreateFormValues | undefined,
  deletedTripSections: EntityDeleteInput[]
) => {
  const defaultDistributions = defaultValues?.tripItems.reduce((prev, curr) => {
    return [...prev, ...(curr.tripDistributions || [])];
  }, [] as TripDistributionFormValues[]);

  const existingTripDistributions = getReducedTripDistributions(values).filter(
    (item) => item.id !== null
  );

  const patchFilter =
    existingTripDistributions.filter((item, index) => {
      if (item.tripItemId !== null) {
        if (index !== -1) {
          const patch = Object.entries(item).reduce((res, [key, val]) => {
            if (
              val !==
              defaultDistributions?.[index!][
                key as keyof TripDistributionFormValues
              ]
            )
              return { ...res, [key]: val };
            else return res;
          }, {});
          return !isEmpty(patch);
        } else return false;
      }
      return false;
    }) || [];

  const result = patchFilter.map((item) => {
    const patch = Object.entries(item).reduce((res, [key, val]) => {
      if (key === 'id' || key === '_rowTimestamp') return res;
      else return { ...res, [key]: val };
    }, {});

    return {
      id: item.id,
      rowTimestamp: item._rowTimestamp,
      tripDistributionUpdatePatch: patch,
    } as TripDistributionUpdateTypeInput;
  }) as TripDistributionUpdateTypeInput[];
  return result;
};

const getNewTripDistributions = (
  values: TripCreateFormValues,
  userDefaults: UserDefaults_userDefaults_nodes | undefined
) => {
  const distributionsReduced = values.tripItems.reduce((prev, curr) => {
    return [...prev, ...(curr.tripDistributions || [])];
  }, [] as TripDistributionFormValues[]);

  const distributions = getTripDistribution(
    distributionsReduced,
    userDefaults
  ).filter((item) => item.tripItemId === null);

  return distributions;
};

const getNullStopItems = (isDepart: boolean) => {
  // const baseField = isDepart ? `depart${index + 1}` : `return${index + 1}`;
  // const nameBaseField = isDepart
  //   ? `departure${index + 1}`
  //   : `return${index + 1}`;

  const result = new Array(4).fill(undefined).reduce((prev, _, index) => {
    const st = Object.entries(STOP_MODAL_INITIAL_VALUES).reduce(
      (prevValue, [objKey, objVal]) => {
        // if(true)
        if (objKey !== 'depotName')
          return {
            ...prevValue,
            [`${isDepart ? 'depart' : 'return'}${
              index + 1
            }${convertToPascalCase(objKey)}`]: objVal,
          };
        else return prevValue;
      },
      {}
    );
    return { ...st, ...prev };
  }, {});

  return result;
};

const getTripStops = (tripItems: TripItemFormValues[]) => {
  const tripItem = tripItems.find(
    (item) => item.layout === TransactionLayout.TRAVEL_BOOKING_AIR_TRAVEL
  );
  if (tripItem) {
    const nullDepartItems = getNullStopItems(true);
    const nullReturnItems = getNullStopItems(false);

    const stopsReduced = tripItem.stopModalItems?.reduce(
      (prev, curr, index) => {
        const st = Object.entries(curr).reduce(
          (prevValue, [objKey, objVal]) => {
            // if(true)
            if (objKey !== 'depotName')
              if (objKey === 'arriveDate' || objKey === 'departDate')
                return {
                  ...prevValue,
                  [`depart${index + 1}${convertToPascalCase(objKey)}`]: objVal
                    ? dateFormat(objVal.toString())
                    : null,
                };
              else
                return {
                  ...prevValue,
                  [`depart${index + 1}${convertToPascalCase(objKey)}`]: objVal,
                };
            else return prevValue;
          },
          {}
        );

        return { ...st, ...prev };
      },
      {}
    );
    const stopsReturnReduced = tripItem.stopModalReturnItems?.reduce(
      (prev, curr, index) => {
        const st = Object.entries(curr).reduce(
          (prevValue, [objKey, objVal]) => {
            if (objKey !== 'depotName')
              if (objKey === 'arriveDate' || objKey === 'departDate')
                return {
                  ...prevValue,
                  [`return${index + 1}${convertToPascalCase(objKey)}`]: objVal
                    ? dateFormat(objVal.toString())
                    : null,
                };
              else
                return {
                  ...prevValue,
                  [`return${index + 1}${convertToPascalCase(objKey)}`]: objVal,
                };
            else return prevValue;
          },
          {}
        );
        return { ...st, ...prev };
      },
      {}
    );
    const result = {
      ...nullDepartItems,
      ...nullReturnItems,
      ...stopsReduced,
      ...stopsReturnReduced,
    };

    return result;
  } else return null;
};
const getTripPatch = (
  initialTripValues: TripType,
  currentTripValues: TripType
) => {
  const fieldsToExclude = ['travelPolicy'];
  const tripPatch: TripPatch = Object.entries(currentTripValues).reduce(
    (res, [key, val]) => {
      if (key === 'travelerFullName') return res;
      else if (
        val !== initialTripValues?.[key as keyof TripType] &&
        !fieldsToExclude.includes(key)
      ) {
        return { ...res, [key]: val };
      }
      return res;
    },
    {}
  );
  return tripPatch;
};

const getDefaultDistribution = (
  tripSectionId: string,
  userDefaults: UserDefaults_userDefaults_nodes | undefined,
  initial?: boolean
) => {
  const { referenceCode6, referenceCode7, project, accountCode } = {
    ...userDefaults,
  };

  const projectReference =
    userDefaults?.distributionLayoutType?.layoutType ===
    TransactionLayout.EPISODIC_DISTRIBUTION_LAYOUT
      ? project?.code
      : undefined;

  let defaultValues: TripDistributionFormValues | undefined = {
    ...TRIP_DISTRIBUTION_INITIAL_VALUES,
    tripSectionId,
    allocationPercent: initial ? '100' : null,
    referenceCode6: referenceCode6 === undefined ? null : referenceCode6,
    referenceCode7: referenceCode7 === undefined ? null : referenceCode7,
    projectReference: projectReference!,
    accountReference: accountCode === undefined ? null : accountCode,
  };

  return defaultValues;
};
export {
  getDefaultValues,
  getDeletedTripItems,
  getNewTripItems,
  getDefaultDistribution,
  getSections,
  getTripPatch,
  getTripStops,
  getTripVariables,
  getUpdateTripItems,
  getRemovedTripDistributions,
  getUpdatedTripDistributions,
  getNewTripDistributions,
};
