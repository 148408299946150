import {
  FormHookAmount, FormHookDatePicker
} from 'common/components/FormHooksFields';
import { FormHookTextField } from 'common/components/FormHooksFields/FormHookTextField';
import { ColumnData } from 'common/components/SearchBar';
import React from 'react';
import { DayOfWeek, Stack } from '@fluentui/react';
import { AutoCompleteTextField } from '../../AccountingView/CustomFields/AutoCompleteTextField';

export interface FormFieldProps {
  baseField: string;
  columnData: ColumnData;
  disable?: boolean;
  isDeletable?: boolean;
}
const FormField: React.FC<FormFieldProps> = ({
  baseField,
  columnData,
  disable,
}) => {
  switch (columnData.key) {
    case 'delete':
      return null;
    case 'accountReference':
      return (
        <AutoCompleteTextField
          id={`accountReference${columnData.key}`}
          name={`${baseField}.${columnData.key}`}
          width={columnData.maxWidth!}
          lookUpCalloutWidth={500}
          businessUnitId={null}
          disabled={disable}
        />
      );
    case 'additionalDescription':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          style={{ width: columnData.maxWidth }}
          disabled={disable}
        />
      );
    case 'distributionDate':
      return (
        <Stack
          style={{ width: columnData.maxWidth }}
          tokens={{ padding: "0px 4px 0px 0px" }}
        >
          <FormHookDatePicker
            name={`${baseField}.${columnData.key}`}
            placeholder="Date"
            firstDayOfWeek={DayOfWeek.Sunday}
            firstWeekOfYear={1}
            showMonthPickerAsOverlay
            showGoToToday
            style={{ width: Number(columnData.maxWidth) - 40 }}
            underlined
            disabled={disable}
          />
        </Stack>
      )
    case 'distributionAmount':
      return (
        <FormHookAmount
          name={`${baseField}.distributionAmount`}
          underlined
          style={{ width: columnData.maxWidth, textAlign: 'end' }}
          disabled={disable}
        />
      );
    default:
      return null;
  }
};

export default FormField;
