import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { MessageBar, MessageBarType, Separator, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { InvoiceRow } from 'ap/paymentTracking/StampedTransactions/list';
import { PaymentsInvoicesSigningSearch_invoiceSigningSearch } from 'ap/paymentTracking/__generated__/PaymentsInvoicesSigningSearch';
import { PaymentSearch_paymentSearch } from 'ap/paymentTracking/list/__generated__/PaymentSearch';
import { PaymentSearchFilterTotals_paymentSearchFilterTotals } from 'ap/paymentTracking/list/__generated__/PaymentSearchFilterTotals';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { PanelHeader } from 'common/components/PanelHeader';
import { StampOptions, StamperView } from 'common/components/StamperView';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import {
  EntityDeleteInput,
  InvoicePaymentInput,
  UploadStatusType,
} from 'common/types/globalTypes';
import { PanelCommonProps } from 'common/types/utility';
import {
  dateConvertions,
  dateFormat,
  invoiceDateFormat,
} from 'common/utils/dateFormats';
import { Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ActionsMenu } from './ActionMenu';
import { AttachedDocumentsType, BasicForm } from './BasicForm';
import { DeletePayment } from './DeletePayment';
import { Header } from './Header';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import {
  GetPaymentDetails,
  GetPaymentDetailsVariables,
  GetPaymentDetails_payment_currency,
} from './__generated__/GetPaymentDetails';
import {
  PaymentCreate,
  PaymentCreateVariables,
} from './__generated__/PaymentCreate';
import { PaymentTypes } from './__generated__/PaymentTypes';
import {
  PaymentUpdate,
  PaymentUpdateVariables,
} from './__generated__/PaymentUpdate';
import { PAYMENT_INITIAL_VALUES } from './constant';
import { useStyles } from './index.styles';
import { validationSchema } from './paymentsValidation';
import { PaymentValues } from './types';
const CREATE_PAYMENT = loader('./PaymentCreate.graphql');
const UPDATE_PAYMENT = loader('./PaymentUpdate.graphql');
const GET_PAYMENT_DETAILS = loader('./GetPaymentDetails.graphql');
const PAYMENT_DATA = loader('./PaymentTypes.graphql');
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
interface locationStateProps {
  selectedRows?: InvoiceRow[];
  amountTotal: number;
  paymentCycleId: string | null | undefined;
  paymentCycleDate: Date | null | undefined;
  paymentCycleCurrency: string | null | undefined;
  paymentCycleTransactionTypeId: number | undefined;
  paymentRowDocument?: InvoiceRow;
}
export const PaymentTrackingView: React.FC = () => {
  const history = useHistory();
  const { addToast, updateToast } = useToasts();
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { state } = useLocation<locationStateProps>();
  const selectedInvoicesParam: InvoiceRow[] = [...(state?.selectedRows || [])];
  const paymentCycleId: string | null | undefined = state?.paymentCycleId;
  const paymentCycleDate: Date | null | undefined = state?.paymentCycleDate;
  const paymentCycleCurrency: string | null | undefined =
    state?.paymentCycleCurrency;
  const paymentCycleTransactionTypeId: number | undefined =
    state?.paymentCycleTransactionTypeId;
  const paymentRowDocument: InvoiceRow | undefined = state?.paymentRowDocument;
  const amountTotalParam: number = state?.amountTotal || 0;
  const client = useApolloClient();
  const [toDeleteInvoicesState, setToDeleteInvoicesState] = useState<
    EntityDeleteInput[]
  >([]);
  const [isSaveAnother, { setTrue: saveAnother, setFalse: dontSaveAnonther }] =
    useBoolean(false);
  const [isClearFormStates, setIsClearFormStates] = useState<boolean>(false);
  const [isClearToDeleteTransactions, setClearToDeleteTransactions] =
    useState<boolean>(false);
  const [attachedDocumentsData, setAttachedDocumentsData] =
    useState<AttachedDocumentsType>();
  const [stampData, setStampData] = useState<StampOptions>();
  const { paymentId } = useParams<{ paymentId: string | undefined }>();

  const isNew = !paymentId;

  const { data: paymentDetailsData, loading: paymentDetailsLoading } = useQuery<
    GetPaymentDetails,
    GetPaymentDetailsVariables
  >(GET_PAYMENT_DETAILS, {
    variables: {
      id: paymentId!,
    },
    skip: !paymentId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [forNewPaymentCycleId, setForNewPaymentCycleId] = useState<string>('');
  const [forNewPaymentCycleDate, setForNewPaymentCycleDate] =
    useState<string>('');
  const [forNewPaymentCycleCurrency, setForNewPaymentCycleCurrency] =
    useState<GetPaymentDetails_payment_currency>();

  const [updatePayment, { loading: updatePaymentLoading }] = useMutation<
    PaymentUpdate,
    PaymentUpdateVariables
  >(UPDATE_PAYMENT, {
    errorPolicy: 'all',
  });
  const { data: paymentTypeData, loading: paymentTypeLoading } =
    useQuery<PaymentTypes>(PAYMENT_DATA, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  let initialValues: PaymentValues = PAYMENT_INITIAL_VALUES;
  if (paymentDetailsData?.payment && !isNew) {
    initialValues = {
      ...paymentDetailsData.payment,
      paidDate: dateConvertions(paymentDetailsData.payment.paidDate),
      accountingInvoiceTotal: paymentDetailsData.payment.invoicePayments
        .aggregates?.sum?.appliedAmount
        ? parseFloat(
            paymentDetailsData.payment.invoicePayments.aggregates?.sum
              ?.appliedAmount
          )
        : 0.0,
      invoicePayments: [],
    };
  } else {
    initialValues = {
      ...initialValues,
      payCycleId: forNewPaymentCycleId || '',
      currency: forNewPaymentCycleCurrency!,
      paidDate: forNewPaymentCycleDate,
      accountingInvoiceTotal: 0,
      invoicePayments: [],
    };
  }
  const [createPayment, { loading: addPaymentLoading }] = useMutation<
    PaymentCreate,
    PaymentCreateVariables
  >(CREATE_PAYMENT, { errorPolicy: 'all' });
  const handleSubmit = async (values: PaymentValues) => {
    if (isNew) {
      const { data, errors } = await createPayment({
        variables: {
          input: {
            payment: {
              payCycleId: values.payCycleId!,
              vendorId: values.vendorId,
              vendorReference: values.vendorReference!,
              paymentReference: values.paymentReference!,
              paidDate: invoiceDateFormat(values.paidDate!),
              paidAmount: values.paidAmount!,
              rowSecurityId: values.rowSecurityId,
            },
            invoicePayments: values.invoicePayments as InvoicePaymentInput[],
            entityDocumentId: attachedDocumentsData?.entityDocumentId,
            documentTypeId: attachedDocumentsData?.documentTypeId,
          },
        },
        update: (cache, { data }) => {
          if (data?.paymentCreate?.payment?.id) {
            try {
              cache.modify({
                fields: {
                  paymentSearchFilterTotals(
                    existing: PaymentSearchFilterTotals_paymentSearchFilterTotals
                  ) {
                    if (existing?.totalCount1 !== 0) {
                      if (data?.paymentCreate?.payment?.id) {
                      const {
                        paidAmount,
                        _baseCurrencyAmount,
                        _spotCurrencyAmount,
                      } = {
                        ...data?.paymentCreate?.payment,
                      };
                        const newSum =
                          Number(existing?.totalAmount1) +
                            Number(paidAmount) ?? 0;
                        const baseCurrencyAmountSum =
                          Number(existing?.totalAmount2) +
                            Number(
                              _baseCurrencyAmount
                            ) ?? 0;
                        const spotCurrencyAmountSum =
                          Number(existing?.totalAmount3) +
                            Number(
                              _spotCurrencyAmount
                            ) ?? 0;
                        return {
                          ...existing,
                          totalAmount1: newSum.toString(),
                          totalAmount2: baseCurrencyAmountSum.toString(),
                          totalAmount3: spotCurrencyAmountSum.toString(),
                          totalCount1: (existing?.totalCount1 || 0) + 1,
                        };
                      }
                    } else {
                      return {
                        ...existing,
                      };
                    }
                  },

                  paymentSearch(existing: PaymentSearch_paymentSearch) {
                    return {
                      ...existing,
                      pageInfo: existing?.pageInfo!,
                      nodes: [
                        data?.paymentCreate?.payment,
                        ...existing?.nodes!,
                      ],
                    };
                  },
                  invoiceSigningSearch(
                    existing: PaymentsInvoicesSigningSearch_invoiceSigningSearch
                  ) {
                    const filteredList = existing.nodes.filter(
                      (emp) =>
                        values.invoicePayments?.findIndex(
                          (item) => item.invoiceId === emp.id
                        ) === -1
                    );

                    return {
                      ...existing,
                      nodes: filteredList!,
                    };
                  },
                },
              });
            } catch (error) {}
          }
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        setIsClearFormStates(true);
        if (!isSaveAnother)
          history.replace(
            `/pay/payment_tracking/payment/${data?.paymentCreate?.payment?.id}`
          );
        addToast('Payment added successfully', {
          appearance: 'success',
        });
        setIsClearFormStates(false);
      }
    } else {
      const { errors } = await updatePayment({
        variables: {
          input: {
            id: paymentDetailsData?.payment?.id!,
            rowTimestamp: paymentDetailsData?.payment?._rowTimestamp!,
            invoicePaymentsCreate:
              values.invoicePayments as InvoicePaymentInput[],
            invoicePaymentsDelete: toDeleteInvoicesState,
            paymentPatch: {
              payCycleId: values.payCycleId!,
              vendorId: values.vendorId,
              vendorReference: values.vendorReference!,
              paymentReference: values.paymentReference!,
              paidDate: invoiceDateFormat(values.paidDate!),
              paidAmount: values.paidAmount!,
              rowSecurityId: values.rowSecurityId,
            },
          },
        },
        awaitRefetchQueries: true,
        update: (cache, { data }) => {
          const cacheData = cache.readQuery<
            GetPaymentDetails,
            GetPaymentDetailsVariables
          >({
            query: GET_PAYMENT_DETAILS,
            variables: {
              id: paymentDetailsData?.payment?.id!,
            },
          });
          if (cacheData) {
            const updatedData: GetPaymentDetails = {
              payment: {
                ...cacheData?.payment!,
                ...data?.paymentUpdate?.payment!,
              },
            };
            cache.writeQuery<GetPaymentDetails, GetPaymentDetailsVariables>({
              query: GET_PAYMENT_DETAILS,
              variables: {
                id: paymentDetailsData?.payment?.id!,
              },
              data: updatedData!,
            });
          }
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        setToDeleteInvoicesState([]);
        setClearToDeleteTransactions(true);
        addToast('Payment updated successfully.', {
          appearance: 'success',
        });
        setClearToDeleteTransactions(false);
      }
    }
  };
  const _onRenderHeader = (dirty: boolean, isSubmitting: boolean) => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/pay/payment_tracking', {
            clearSelection: true,
            enableTabSwitch: true,
          });
          setIsOpen(true);
        }}
      >
        <Stack grow horizontal horizontalAlign="space-between">
          <Header
            isNew={isNew}
            dirty={dirty}
            isSubmitting={isSubmitting}
            dataLoading={paymentDetailsLoading}
            payment={paymentDetailsData?.payment}
          />
          <Stack horizontal>
            <DeletePayment payment={paymentDetailsData} />
            <Stack>
              {!isNew && (
                <Stack>
                  {stampData &&
                    paymentDetailsData?.payment
                      ?._isAccountingEntryStampedComplete && (
                      <StamperView invoiceDetails={stampData} />
                    )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </PanelHeader>
    );
  };
  useEffect(() => {
    if (paymentDetailsData) {
      if (paymentDetailsData.payment?.payCycleId) {
        setForNewPaymentCycleId(paymentDetailsData.payment?.payCycleId);
      }
      if (paymentDetailsData.payment?.currency) {
        setForNewPaymentCycleCurrency(paymentDetailsData.payment?.currency);
      }
      if (paymentDetailsData.payment?.paidDate) {
        setForNewPaymentCycleDate(
          dateFormat(dateConvertions(paymentDetailsData.payment?.paidDate))
        );
      }
      const stampOptions: StampOptions = {
        _accountingStampDate: paymentDetailsData.payment?._accountingStampDate,
        _accountingStampTransactionReference:
          paymentDetailsData.payment?._accountingStampTransactionReference,
        _accountingStampUserName:
          paymentDetailsData.payment?._accountingStampUserName,
        _isAccountingEntryStampedComplete:
          paymentDetailsData.payment?._isAccountingEntryStampedComplete,
        _isTransactionCancelled:
          paymentDetailsData.payment?._isTransactionCancelled,
      };
      setStampData(stampOptions);
    }
  }, [paymentDetailsData]);
  const isLoading =
    addPaymentLoading ||
    paymentDetailsLoading ||
    updatePaymentLoading ||
    paymentTypeLoading;

  return (
    <Formik<PaymentValues>
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting, resetForm, dirty, errors }) => {
        return (
          <Form>
            <DraggablePanel
              {...PanelCommonProps}
              isOpen={isOpen}
              initialWidth={900}
              minWidth={900}
              isBlocking={isNew === true ? true : false}
              onRenderFooter={() => (
                <FooterActionBar
                  createNewText="New Payment"
                  addNewForm={() => {
                    setAttachedDocumentsData(undefined);
                    setClearToDeleteTransactions(true);
                    setIsClearFormStates(true);
                    history.replace(`/pay/payment_tracking/payment`, {
                      forNewPaymentCycleId,
                      forNewPaymentCycleCurrency,
                      forNewPaymentCycleDate,
                    });
                  }}
                  disabled={{
                    save: !dirty || Object.keys(errors).length > 0,
                    cancel: !dirty,
                  }}
                  isSubmitting={isSubmitting}
                  isCreate={!paymentId ? true : false}
                  onCancel={() =>
                    history.replace('/pay/payment_tracking', {
                      clearSelection: true,
                      enableTabSwitch: true,
                    })
                  }
                  onSave={async () => {
                    dontSaveAnonther();
                    await submitForm();
                  }}
                  onSaveAnother={async () => {
                    saveAnother();
                    await submitForm();
                    resetForm();
                  }}
                  isLoading={isLoading}
                />
              )}
              onRenderHeader={() => _onRenderHeader(dirty, isSubmitting)}
              isLightDismiss
            >
              {!isNew && paymentDetailsLoading ? (
                <Stack
                  className={styles.shimmerViewMainContainer}
                  tokens={{ childrenGap: 20 }}
                >
                  <Stack
                    className={styles.headerContainer}
                    tokens={{ childrenGap: 10 }}
                  >
                    <ShimmerView />
                  </Stack>
                </Stack>
              ) : (
                <Stack>
                  {!isNew && (
                    <Stack>
                      <Stack
                        className={styles.headerContainer}
                        tokens={{ childrenGap: 10 }}
                      >
                        <Stack className={styles.requiredAttachmentsMessage}>
                          {paymentDetailsData?.payment
                            ?._isApprovalDocumentsRequired &&
                            paymentDetailsData?.payment
                              ?._requiredApprovalDocuments && (
                              <MessageBar messageBarType={MessageBarType.error}>
                                {
                                  paymentDetailsData?.payment
                                    ?._requiredApprovalDocuments
                                }
                              </MessageBar>
                            )}
                          {paymentDetailsData?.payment && (
                            <ActionsMenu
                              payment={paymentDetailsData?.payment}
                              secureRowLevels={paymentTypeData?.secureRowLevels}
                              onUpload={async (
                                fileSelected,
                                document,
                                toastId
                              ) => {
                                const observer = client.subscribe({
                                  query: DOCUMENT_UPLOAD_STATUS,
                                  variables: {
                                    documentId:
                                      document.document._documentFileId!,
                                  },
                                });
                                const subscription = observer.subscribe(
                                  (data) => {
                                    const subscribedData =
                                      data.data as OnDocumentUploadStatus;
                                    const { status, document } = {
                                      ...subscribedData.documentUploadStatus,
                                    };
                                    if (!document) {
                                      if (
                                        status.type ===
                                        UploadStatusType.VALIDATING
                                      ) {
                                        updateToast(toastId!, {
                                          content: status.message
                                            ? `Validating files ${fileSelected.name} - ${status.message}`
                                            : `Validating files ${fileSelected.name}`,
                                          appearance: 'info',
                                          autoDismiss: false,
                                        });
                                      } else if (
                                        status.type ===
                                        UploadStatusType.EXTRACTING
                                      ) {
                                        updateToast(toastId!, {
                                          content: status.message
                                            ? `Extracting data from ${fileSelected.name} - ${status.message}`
                                            : `Extracting data from ${fileSelected.name}`,
                                          appearance: 'info',
                                          autoDismiss: false,
                                        });
                                      } else if (
                                        status.type === UploadStatusType.FAILURE
                                      ) {
                                        subscription.unsubscribe();
                                        updateToast(toastId!, {
                                          content: status.message
                                            ? `Upload of ${fileSelected.name} failed - ${status.message}`
                                            : `Upload of ${fileSelected.name} failed`,
                                          appearance: 'error',
                                          autoDismiss: true,
                                        });
                                      } else if (
                                        status.type === UploadStatusType.WARNING
                                      ) {
                                        subscription.unsubscribe();
                                        updateToast(toastId!, {
                                          content: status.message
                                            ? `Warning for file ${fileSelected.name}: ${status.message}`
                                            : `Warning for file ${fileSelected.name}`,
                                          appearance: 'warning',
                                          autoDismiss: true,
                                        });
                                      }
                                    } else {
                                      subscription.unsubscribe();
                                      updateToast(toastId!, {
                                        content: status.message
                                          ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                                          : `Successfully uploaded ${fileSelected.name}`,
                                        appearance: 'success',
                                        autoDismiss: true,
                                      });
                                      const cacheData = client.readQuery<
                                        GetPaymentDetails,
                                        GetPaymentDetailsVariables
                                      >({
                                        query: GET_PAYMENT_DETAILS,
                                        variables: {
                                          id: paymentId!,
                                        },
                                      });
                                      if (cacheData) {
                                        const updatedData: GetPaymentDetails = {
                                          payment: {
                                            ...cacheData?.payment!,
                                            _isApprovalDocumentsRequired:
                                              document?.paymentDocument
                                                ?._isApprovalDocumentsRequired!,
                                            _requiredApprovalDocuments:
                                              document?.paymentDocument
                                                ?._requiredApprovalDocuments!,
                                            entityDocumentsByEntityId: {
                                              ...cacheData?.payment
                                                ?.entityDocumentsByEntityId,
                                              nodes: [
                                                document!,
                                                ...cacheData?.payment
                                                  ?.entityDocumentsByEntityId
                                                  .nodes!,
                                              ],
                                            },
                                          },
                                        };
                                        client.writeQuery<
                                          GetPaymentDetails,
                                          GetPaymentDetailsVariables
                                        >({
                                          query: GET_PAYMENT_DETAILS,
                                          data: updatedData,
                                          variables: {
                                            id: paymentId!,
                                          },
                                        });
                                      }
                                    }
                                  }
                                );
                              }}
                            />
                          )}
                        </Stack>
                      </Stack>
                      <Separator />
                    </Stack>
                  )}
                  <BasicForm
                    onDocumentsAttached={(documentsData) => {
                      if (documentsData)
                        setAttachedDocumentsData(documentsData);
                    }}
                    clearStates={isClearFormStates}
                    clearToDeleteTransactions={isClearToDeleteTransactions}
                    paymentDetails={paymentDetailsData}
                    isNew={isNew}
                    toDeleteInvoices={(toDeleteArray) => {
                      if (toDeleteArray.length > 0)
                        setToDeleteInvoicesState(toDeleteArray);
                    }}
                    defaultAmount={amountTotalParam}
                    defaultInvoices={selectedInvoicesParam}
                    paymentCycleId={paymentCycleId}
                    paymentCycleDate={paymentCycleDate}
                    paymentCycleCurrency={paymentCycleCurrency}
                    paymentCycleTransactionTypeId={
                      paymentCycleTransactionTypeId
                    }
                    paymentRowDocument={paymentRowDocument}
                  />
                  <Prompt
                    when={dirty && !isSubmitting}
                    message="Are you sure you want to leave your changes unsaved?"
                  />
                </Stack>
              )}
            </DraggablePanel>
          </Form>
        );
      }}
    </Formik>
  );
};
