import {
  ColumnActionsMode,
  IColumn,
  IDetailsColumnStyles,
} from '@fluentui/react';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import { TransactionLayout } from 'common/types/globalTypes';

const commonProps: Partial<IColumn> = {
  isResizable: true,
  columnActionsMode: ColumnActionsMode.disabled,
};

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    paddingLeft: 25,
  },
};

const columns = new Map<string, IColumn>([
  [
    'referenceCode6',
    {
      key: 'referenceCode6',
      name: 'CO',
      fieldName: 'referenceCode6',
      minWidth: 35,
      maxWidth: 35,
      ...commonProps,
    },
  ],
  [
    'referenceCode7',
    {
      key: 'referenceCode7',
      name: 'LOC',
      fieldName: 'referenceCode7',
      minWidth: 35,
      maxWidth: 35,
      ...commonProps,
    },
  ],
  [
    'account',
    {
      key: 'account',
      name: 'Account',
      fieldName: 'itemDescription1',
      minWidth: 70,
      maxWidth: 70,
      styles: rightAlignHeaderStyle,
      ...commonProps,
    },
  ],
  [
    'projectEpisode',
    {
      key: 'projectEpisode',
      name: 'Project/Episode',
      fieldName: 'itemDescription2',
      minWidth: 60,
      maxWidth: 60,
      ...commonProps,
    },
  ],
  [
    'setReference',
    {
      key: 'setReference',
      name: 'Set',
      fieldName: 'setReference',
      minWidth: 45,
      maxWidth: 45,
      ...commonProps,
    },
  ],
  [
    'itemDescription3',
    {
      key: 'itemDescription3',
      name: 'Additional Information',
      fieldName: 'itemDescription3',
      minWidth: 280,
      maxWidth: 280,
      ...commonProps,
    },
  ],
  [
    'amount',
    {
      key: 'amount',
      name: 'Amount',
      fieldName: 'itemAmount1',
      minWidth: 75,
      maxWidth: 75,
      isPadded: true,
      styles: { cellTitle: { paddingRight: 32, justifyContent: 'flex-end' } },
      ...commonProps,
    },
  ],
  [
    'purchaseOrder',
    {
      key: 'purchaseOrder',
      name: 'Purchase Order',
      fieldName: 'itemDescription4',
      minWidth: 100,
      maxWidth: 100,
      ...commonProps,
    },
  ],
  [
    'referenceCode5',
    {
      key: 'referenceCode5',
      name: 'Ins',
      fieldName: 'referenceCode5',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode1',
    {
      key: 'referenceCode1',
      name: 'FF1',
      fieldName: 'referenceCode1',
      minWidth: 30,
      maxWidth: 30,
      ...commonProps,
    },
  ],
  [
    'referenceCode2',
    {
      key: 'referenceCode2',
      name: 'FF2',
      fieldName: 'referenceCode2',
      minWidth: 30,
      maxWidth: 30,
      ...commonProps,
    },
  ],
  [
    'referenceCode3',
    {
      key: 'referenceCode3',
      name: 'FF3',
      fieldName: 'referenceCode3',
      minWidth: 30,
      maxWidth: 30,
      ...commonProps,
    },
  ],
  [
    'referenceCode4',
    {
      key: 'referenceCode4',
      name: 'FF4',
      fieldName: 'referenceCode4',
      minWidth: 30,
      maxWidth: 30,
      ...commonProps,
    },
  ],
]);

const columnsGL = new Map<string, IColumn>([
  [
    'referenceCode6',
    {
      key: 'referenceCode6',
      name: 'CO',
      fieldName: 'referenceCode6',
      minWidth: 35,
      maxWidth: 35,
      ...commonProps,
    },
  ],
  [
    'referenceCode7',
    {
      key: 'referenceCode7',
      name: 'LOC',
      fieldName: 'referenceCode7',
      minWidth: 35,
      maxWidth: 35,
      ...commonProps,
    },
  ],
  [
    'projectEpisode',
    {
      key: 'projectEpisode',
      name: 'Project/Episode',
      fieldName: 'itemDescription2',
      minWidth: 60,
      maxWidth: 60,
      ...commonProps,
    },
  ],
  [
    'account',
    {
      key: 'account',
      name: 'Account',
      fieldName: 'itemDescription1',
      minWidth: 70,
      maxWidth: 70,
      styles: rightAlignHeaderStyle,
      ...commonProps,
    },
  ],
  [
    'setReference',
    {
      key: 'setReference',
      name: 'Set',
      fieldName: 'setReference',
      minWidth: 45,
      maxWidth: 45,
      ...commonProps,
    },
  ],
  [
    'referenceCode1',
    {
      key: 'referenceCode1',
      name: 'FF1',
      fieldName: 'referenceCode1',
      minWidth: 30,
      maxWidth: 30,
      ...commonProps,
    },
  ],
  [
    'referenceCode2',
    {
      key: 'referenceCode2',
      name: 'FF2',
      fieldName: 'referenceCode2',
      minWidth: 30,
      maxWidth: 30,
      ...commonProps,
    },
  ],
  [
    'referenceCode3',
    {
      key: 'referenceCode3',
      name: 'FF3',
      fieldName: 'referenceCode3',
      minWidth: 30,
      maxWidth: 30,
      ...commonProps,
    },
  ],
  [
    'referenceCode4',
    {
      key: 'referenceCode4',
      name: 'FF4',
      fieldName: 'referenceCode4',
      minWidth: 30,
      maxWidth: 30,
      ...commonProps,
    },
  ],
  [
    'referenceCode5',
    {
      key: 'referenceCode5',
      name: 'Ins',
      fieldName: 'referenceCode5',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'amount',
    {
      key: 'amount',
      name: 'Amount',
      fieldName: 'itemAmount1',
      minWidth: 75,
      maxWidth: 75,
      isPadded: true,
      styles: { cellTitle: { paddingRight: 32, justifyContent: 'flex-end' } },
      ...commonProps,
    },
  ],
  [
    'purchaseOrder',
    {
      key: 'purchaseOrder',
      name: 'Purchase Order',
      fieldName: 'itemDescription4',
      minWidth: 100,
      maxWidth: 100,
      ...commonProps,
    },
  ],
  [
    'itemDescription3',
    {
      key: 'itemDescription3',
      name: 'Additional Information',
      fieldName: 'itemDescription3',
      minWidth: 280,
      maxWidth: 280,
      ...commonProps,
    },
  ],
]);
const columnsPSL = new Map<string, IColumn>([
  [
    'purchaseOrder',
    {
      key: 'purchaseOrder',
      name: 'Purchase Order',
      fieldName: 'itemDescription4',
      minWidth: 100,
      maxWidth: 100,
      ...commonProps,
    },
  ],
  [
    'account',
    {
      key: 'account',
      name: 'Account',
      fieldName: 'itemDescription1',
      minWidth: 70,
      maxWidth: 70,
      styles: rightAlignHeaderStyle,
      ...commonProps,
    },
  ],
  [
    'projectEpisode',
    {
      key: 'projectEpisode',
      name: 'Episode',
      fieldName: 'itemDescription2',
      minWidth: 60,
      maxWidth: 60,
      ...commonProps,
    },
  ],
  [
    'referenceCode7',
    {
      key: 'referenceCode7',
      name: 'LOC',
      fieldName: 'referenceCode7',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'setReference',
    {
      key: 'setReference',
      name: 'Set',
      fieldName: 'setReference',
      minWidth: 45,
      maxWidth: 45,
      ...commonProps,
    },
  ],
  [
    'referenceCode5',
    {
      key: 'referenceCode5',
      name: 'Ins',
      fieldName: 'referenceCode5',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode1',
    {
      key: 'referenceCode1',
      name: 'FF1',
      fieldName: 'referenceCode1',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode2',
    {
      key: 'referenceCode2',
      name: 'FF2',
      fieldName: 'referenceCode2',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode3',
    {
      key: 'referenceCode3',
      name: 'FF3',
      fieldName: 'referenceCode3',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'referenceCode4',
    {
      key: 'referenceCode4',
      name: 'FF4',
      fieldName: 'referenceCode4',
      minWidth: 40,
      maxWidth: 40,
      ...commonProps,
    },
  ],
  [
    'amount',
    {
      key: 'amount',
      name: 'Amount',
      fieldName: 'itemAmount1',
      minWidth: 75,
      maxWidth: 75,
      isPadded: true,
      styles: { cellTitle: { paddingRight: 32, justifyContent: 'flex-end' } },
      ...commonProps,
    },
  ],
  [
    'itemDescription3',
    {
      key: 'itemDescription3',
      name: 'Additional Information',
      fieldName: 'itemDescription3',
      minWidth: 280,
      maxWidth: 280,
      ...commonProps,
    },
  ],
]);

export const getColumns = (
  data: UserDefaults_userDefaults_nodes | undefined
) => {
  const { layoutType } = {
    ...data?.distributionLayoutType,
  };
  let columnArray = [];
  let existingData;
  switch (layoutType) {
    case TransactionLayout.DEFAULT_DISTRIBUTION_LAYOUT:
      columns.delete('projectEpisode');
      columnArray = Array.from(columns, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.GL_LOC_EPISODE_DISTRIBUTION_LAYOUT:
      columnsGL.delete('referenceCode6');
      existingData = columnsGL.get('projectEpisode');
      columnsGL.set('projectEpisode', {
        ...existingData,
        name: 'Episode',
      } as IColumn);
      columnArray = Array.from(columnsGL, (data) => data[1]);
      return { columnArray };

    case TransactionLayout.GL_LOC_DISTRIBUTION_LAYOUT:
      columnsGL.delete('referenceCode6');
      columnsGL.delete('projectEpisode');
      columnArray = Array.from(columnsGL, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.EPISODIC_DISTRIBUTION_LAYOUT:
      existingData = columns.get('projectEpisode');
      columns.set('projectEpisode', {
        ...existingData,
        name: 'Episode',
      } as IColumn);
      columnArray = Array.from(columns, (data) => data[1]);
      return { columnArray };

    case TransactionLayout.GL_CO_LOC_EPISODE_DISTRIBUTION_LAYOUT:
      existingData = columnsGL.get('projectEpisode');
      columnsGL.set('projectEpisode', {
        ...existingData,
        name: 'Episode',
      } as IColumn);
      columnArray = Array.from(columnsGL, (data) => data[1]);
      return { columnArray };

    case TransactionLayout.GL_CO_LOC_DISTRIBUTION_LAYOUT:
      columnsGL.delete('projectEpisode');
      columnArray = Array.from(columnsGL, (data) => data[1]);
      return { columnArray };

    case TransactionLayout.PSL_ENTRY_EPISODIC:
      columnArray = Array.from(columnsPSL, (data) => data[1]);
      return { columnArray };

    case TransactionLayout.PSL_ENTRY_STANDARD:
      columnsPSL.delete('projectEpisode');
      columnArray = Array.from(columnsPSL, (data) => data[1]);
      return { columnArray };
    default:
      columns.delete('referenceCode6');
      columns.delete('referenceCode7');
      columnArray = Array.from(columns, (data) => data[1]);
      return { columnArray };
  }
};
