import React, { useCallback, useState } from 'react';
import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import { FilesSection } from './TransactionDetailFiles';
import { useStyles } from './index.styles';
import { PivotDisplay } from 'common/components/PivotDisplay';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';

import { HistoryActionMenuView } from 'common/components/History';
import { TransactionModalNotesSection } from './TransactionDetailNotes';
import {
  TransactionInvoiceNotes,
  TransactionInvoiceNotesVariables,
} from './TransactionDetailNotes/__generated__/TransactionInvoiceNotes';
import { TransactionData } from '../../__generated__/TransactionData';
import { TransactionSigningTags } from 'common/components/TransactionSigningTags';

const ENTITY_NOTES_LIST = loader(
  './TransactionDetailNotes/TransactionInvoiceNotes.graphql'
);
const ACTION_CONSTS = {
  files: 'Files_Action',
  notes: 'Notes_Action',
  history: 'History_Action',
  tags: 'Tags_Action',
};

interface MenuActionProps {
  invoiceData: TransactionData;
}
export const MenuActions: React.FC<MenuActionProps> = ({ invoiceData }) => {
  const styles = useStyles();
  const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(false);
  const [selectedKey, setSelectedKey] = React.useState(ACTION_CONSTS.files);
  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    TransactionInvoiceNotes,
    TransactionInvoiceNotesVariables
  >(ENTITY_NOTES_LIST, {
    variables: {
      id: invoiceData?.invoice?.id!,
    },
  });

  const getEntityNotes = () => {
    const variables: TransactionInvoiceNotesVariables = {
      id: invoiceData?.invoice?.id!,
    };
    entityNotesListRefetch(variables);
  };

  const getEntityNotesMemo = useCallback(getEntityNotes, [
    invoiceData?.invoice?.id!,
  ]);

  const getSelectedSection = (key: string) => {
    switch (key) {
      case ACTION_CONSTS.files:
        return (
          <FilesSection
            fileListData={invoiceData?.invoice?.entityDocumentsByEntityId!}
          />
        );
      case ACTION_CONSTS.notes:
        return (
          <TransactionModalNotesSection
            invoiceId={invoiceData?.invoice?.id!}
            data={entityNotesData?.invoice?.notesByEntityId.nodes!}
            listRefreshRequest={getEntityNotesMemo}
          />
        );

      case ACTION_CONSTS.history:
        return (
          <HistoryActionMenuView
            moduleName="Transaction Signing"
            historyData={
              invoiceData?.invoice?.invoiceHistoriesByEntityId.nodes || []
            }
          />
        );
      case ACTION_CONSTS.tags:
        return <TransactionSigningTags invoiceID={invoiceData?.invoice?.id} />;
      default:
        return null;
    }
  };
  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <Stack className={styles.pivotWrapper}>
        {isMenuExpanded ? (
          <Stack>
            <Stack horizontalAlign="space-between" horizontal>
              <Stack horizontal className={styles.centerAlign}>
                <Pivot
                  selectedKey={selectedKey}
                  onLinkClick={(item: PivotItem | undefined) => {
                    setSelectedKey(item?.props.itemKey || ACTION_CONSTS.files);
                  }}
                  aria-label="Menu_Actions"
                >
                  <PivotItem
                    itemKey={ACTION_CONSTS.files}
                    itemIcon="Attach"
                    headerText="Files"
                    itemCount={
                      invoiceData?.invoice?.entityDocumentsByEntityId.nodes
                        .length
                    }
                    onRenderItemLink={PivotCustomRender}
                  />
                  <PivotItem
                    itemKey={ACTION_CONSTS.notes}
                    itemIcon="Page"
                    headerText="Notes"
                    itemCount={
                      invoiceData?.invoice?.notesByEntityId?.nodes.length
                    }
                    onRenderItemLink={PivotCustomRender}
                  />
                  <PivotItem
                    itemKey={ACTION_CONSTS.history}
                    itemIcon="History"
                    headerText="History"
                    itemCount={
                      invoiceData?.invoice?.invoiceHistoriesByEntityId?.nodes
                        .length
                    }
                    onRenderItemLink={PivotCustomRender}
                  />
                  <PivotItem
                    itemKey={ACTION_CONSTS.tags}
                    itemIcon="Tag"
                    headerText="Tags"
                    itemCount={
                      invoiceData?.invoice?.entityTagsByEntityId.nodes.length
                    }
                    onRenderItemLink={PivotCustomRender}
                  />
                </Pivot>
                <IconButton
                  onClick={() => setIsMenuExpanded(false)}
                  iconProps={{ iconName: 'ChevronUp' }}
                  className={styles.iconButtonColor}
                  ariaLabel="Collapse"
                />
              </Stack>
            </Stack>
            <Stack>{getSelectedSection(selectedKey)}</Stack>
          </Stack>
        ) : (
          <Stack horizontalAlign="space-between" horizontal>
            <Stack horizontal verticalAlign="center">
              <Stack horizontal verticalAlign="center">
                <PivotDisplay
                  name="Files"
                  iconName="Attach"
                  count={
                    invoiceData?.invoice?.entityDocumentsByEntityId.nodes.length
                  }
                  onPivotClick={() => {
                    setSelectedKey(ACTION_CONSTS.files);
                    setIsMenuExpanded(true);
                  }}
                />
                <PivotDisplay
                  name="Notes"
                  iconName="Page"
                  count={invoiceData?.invoice?.notesByEntityId?.nodes.length}
                  onPivotClick={() => {
                    setSelectedKey(ACTION_CONSTS.notes);
                    setIsMenuExpanded(true);
                  }}
                />
                <PivotDisplay
                  name="History"
                  iconName="History"
                  count={
                    invoiceData?.invoice?.invoiceHistoriesByEntityId?.nodes
                      .length
                  }
                  onPivotClick={() => {
                    setSelectedKey(ACTION_CONSTS.history);
                    setIsMenuExpanded(true);
                  }}
                />
                <PivotDisplay
                  name="Tags"
                  iconName="Tag"
                  count={
                    invoiceData?.invoice?.entityTagsByEntityId.nodes.length
                  }
                  onPivotClick={() => {
                    setSelectedKey(ACTION_CONSTS.tags);
                    setIsMenuExpanded(true);
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
