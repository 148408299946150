import { useLazyQuery } from '@apollo/client';
import OrganizationChart from '@dabeng/react-orgchart';
import { MessageBar, MessageBarType, Stack } from '@fluentui/react';
import { getTreeGraph } from 'common/components/Hierarchy/utils';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { NodeView } from '../../NodeView';
import { LoadingHierarchy } from 'common/components/PreviewHierarchyComponents/LoadingHierarchy';
import {
  PurchaseOrderPreviewApprovalMap,
  PurchaseOrderPreviewApprovalMapVariables,
} from './__generated__/PurchaseOrderPreviewApprovalMap';
import { ApprovalMapFields } from '../../__generated__/ApprovalMapFields';
const PURCHASE_ORDER_PREVIEW_APPROVAL_MAP = loader(
  './PurchaseOrderPreviewApprovalMap.graphql'
);

export interface HierarchyNode extends ApprovalMapFields {
  children?: HierarchyNode[];
}

interface HierarchyProps {
  entityId: string;
}

export const Hierarchy: React.FC<HierarchyProps> = ({ entityId }) => {
  const [hierarchyData, setHierarchyData] = useState<HierarchyNode[]>([]);

  const [getApprovalMap, { loading, data, error }] = useLazyQuery<
    PurchaseOrderPreviewApprovalMap,
    PurchaseOrderPreviewApprovalMapVariables
  >(PURCHASE_ORDER_PREVIEW_APPROVAL_MAP, { fetchPolicy: 'network-only' });

  const getApprovalDetails = () => {
    getApprovalMap({
      variables: {
        entityId: entityId!,
      },
    });
  };

  const getApproval = useCallback(getApprovalDetails, [entityId]);

  useEffect(() => {
    getApproval();
  }, [getApproval]);

  const memoizedChildrenData = useCallback(getTreeGraph, []);

  const { nodes } = { ...data?.purchaseOrderPreviewApprovalMap };

  useEffect(() => {
    if (!loading && nodes?.length !== 0) {
      // Implicity adding children property to avoid an error caused
      const childrenAdded = nodes?.map((item) => ({
        ...item,
        children: [] as HierarchyNode[],
      }));
      const newData = memoizedChildrenData(childrenAdded) as HierarchyNode[];
      setHierarchyData(newData);
    }
  }, [nodes, loading, memoizedChildrenData]);

  if (hierarchyData.length > 0)
    return (
      <OrganizationChart
        datasource={hierarchyData[0]}
        chartClass="myChart"
        NodeTemplate={(node: any) => <NodeView nodeData={node.nodeData} />}
        pan={true}
        containerClass={'orgchartcontainer'}
        zoom={true}
        zoominLimit={1.2}
        zoomoutLimit={0.5}
        draggable={false}
        collapsible={false}
      />
    );
  else {
    if (error)
      return (
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          style={{ height: 600 }}
        >
          <Stack style={{ width: 300 }}>
            <MessageBar messageBarType={MessageBarType.error}>
              {error.message}
            </MessageBar>
          </Stack>
        </Stack>
      );
    else return <LoadingHierarchy />;
  }
};
