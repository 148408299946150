import React from 'react';
import { Stack, Text, IPersonaProps, List, Separator } from '@fluentui/react';
import { EmployeePicker } from './EmployeePicker';
import { groupBy } from 'lodash';
import { useStyles } from './index.styles';

interface PickerData {
  name: string;
  employees: IPersonaProps[];
  id: string;
}

interface Selected_Array {
  name: string;
  employees: string[];
  id: string | undefined | null;
}

export interface Selected_Contacts {
  department: string[];
  communicationChannel: string[];
  user: string[];
}

interface GroupData {
  name?: string | null;
  count?: number;
  isCollapsed?: boolean;
  key?: string | null;
  level?: number;
  startIndex?: number;
  minWidth?: number;
  userID?: string | null;
  children?: ChilderenData[];
  id?: string | null;
}

interface ChilderenData {
  groupName: string | null;
  label: string | null;
  name: string | null;
  userID: string | null;
  value: string | null;
  id: string | null;
}

export interface ContactData {
  sequenceName: string | null;
  groupId: string | null;
  groupName: string | null;
  userId:string| null;
  userName: string | null;
  isGroupHeader: boolean | null;
  isGroupSelectable: boolean | null;
  isDepartmentGroup: boolean | null;
  departmentId: string | null;
  isCommunicationGroup: boolean | null;
  channelId:string| null;
  isContactGroup: boolean | null;
}
interface ContactListPickerProps {
  selectedContacts: (data: Selected_Contacts) => void;
  data: ContactData[];
}

interface TempArrayContacts {
  name: string;
  IndivudualConvertedData: IPersonaProps[];
}

const ContactListPicker: React.FC<ContactListPickerProps> = ({
  selectedContacts,
  data,
}) => {
  const styles = useStyles();

  const [selectedDepartMentID, setSelectedDepartmentID] = React.useState<
    string[]
  >([]);

  const [selectedChannelID, setSelectedChannelID] = React.useState<string[]>(
    []
  );

  const [users, setSelectedUsers] = React.useState<string[]>([]);
  const [departments, setDepartments] = React.useState<
    PickerData[] | undefined
  >([]);
  const [channels, setChannels] = React.useState<PickerData[] | undefined>([]);
  const [allContacts, setAllContacts] = React.useState<
    IPersonaProps[] | undefined
  >([]);
  const [selectedDepartMentsContactIds, setSelectedDepartmentsContactIds] =
    React.useState<Selected_Array[]>([]);
  const [selectedChannelsContactIds, setSelectedChannelsContactIds] =
    React.useState<Selected_Array[]>([]);

  const getSelectedItemsIds = (array: Selected_Array[]) => {
    let selectedUsers: string[] = [];
    let selectedUsersArrayOfArray: string[][] = [];
    selectedUsersArrayOfArray = array.map((item) => item.employees);
    selectedUsers = selectedUsersArrayOfArray.flat(1);
    return selectedUsers;
  };

  const sendDataToParentComp = (
    selectedDepartMentID: string[],
    selectedChannelID: string[],
    users: string[],
    selectedDepartMentsContactIds: Selected_Array[],
    selectedChannelsContactIds: Selected_Array[]
  ) => {
    let selectedDepartmentUsers: string[] = getSelectedItemsIds(
      selectedDepartMentsContactIds
    );
    let selectedChannelsUsers: string[] = getSelectedItemsIds(
      selectedChannelsContactIds
    );
    const data: Selected_Contacts = {
      department: selectedDepartMentID,
      communicationChannel: selectedChannelID,
      user: [...users, ...selectedDepartmentUsers, ...selectedChannelsUsers],
    };
    selectedContacts(data);
  };

  const onItemSelected = async (
    selectetedContact: string[],
    key: string,
    operation: number
  ) => {
    let selectedDepartmentContacts = [...selectedDepartMentsContactIds];

    let selectedChannelsContacts = [...selectedChannelsContactIds];
    switch (operation) {
      case 0: {
        let indexOfSelectedItem = selectedDepartmentContacts.findIndex(
          (item) => item.name === key
        );
        selectedDepartmentContacts[indexOfSelectedItem] = {
          ...selectedDepartmentContacts[indexOfSelectedItem],
          employees: selectetedContact,
        };
        setSelectedDepartmentsContactIds(selectedDepartmentContacts);
        break;
      }
      case 1: {
        let indexOfSelectedItem = selectedChannelsContacts.findIndex(
          (item) => item.name === key
        );
        selectedChannelsContacts[indexOfSelectedItem] = {
          ...selectedChannelsContacts[indexOfSelectedItem],
          employees: selectetedContact,
        };
        setSelectedChannelsContactIds(selectedChannelsContacts);
        break;
      }
      case 2: {
        setSelectedUsers(selectetedContact);
        break;
      }
    }
    sendDataToParentComp(
      selectedDepartMentID,
      selectedChannelID,
      selectetedContact,
      selectedDepartmentContacts,
      selectedChannelsContacts
    );
  };

  const onAllContactSelected = async (
    id: string,
    isAdd: number,
    key: string,
    operation: number
  ) => {
    let departments = [...selectedDepartMentID];

    let channels = [...selectedChannelID];

    let selectedDepartmentContacts = [...selectedDepartMentsContactIds];

    let selectedChannelsContacts = [...selectedChannelsContactIds];

    switch (operation) {
      case 0: {
        if (isAdd) {
          departments.push(id);
          setSelectedDepartmentID(departments);
          let data = selectedDepartmentContacts;
          let indexOfSelectedItem = data.findIndex((item) => item.name === key);
          data[indexOfSelectedItem] = {
            ...data[indexOfSelectedItem],
            employees: [],
          };
          setSelectedDepartmentsContactIds(data);
        } else {
          let data = departments.filter((e) => e !== id);
          setSelectedDepartmentID(data);
        }
        break;
      }
      case 1: {
        if (isAdd) {
          channels.push(id);
        } else {
          let temp = [...channels];
          temp = temp.filter((e) => e !== id);
          setSelectedChannelID(temp);
          let data = [...selectedChannelsContacts];
          let indexOfSelectedItem = data.findIndex((item) => item.name === key);
          data[indexOfSelectedItem] = {
            ...data[indexOfSelectedItem],
            employees: [],
          };
          setSelectedChannelsContactIds(data);
        }
        break;
      }
    }
    if (operation < 2) {
      sendDataToParentComp(
        departments,
        channels,
        users,
        selectedDepartmentContacts,
        selectedChannelsContacts
      );
    }
  };

  React.useEffect(() => {
    const groupData = groupBy(data, function (item) {
      return item?.sequenceName;
    });
    const filteredGroupData = Object.keys(groupData).map(
      (item, i) => groupData[item]
    );

    const userListData: GroupData[] = [];
    filteredGroupData.forEach((item, i) => {
      // if the group header is true we are showing other wise we are skiping.
      const obj = {
        name: item[i].sequenceName,
        count: filteredGroupData.length,
        isCollapsed: false,
        key: item[i].sequenceName,
        level: 0,
        startIndex: 0,
        minWidth: 300,
        children:
          item
            ?.filter(
              (user) =>
                (user?.isDepartmentGroup ||
                  user?.isCommunicationGroup ||
                  user?.isContactGroup) &&
                user?.groupId &&
                user?.userId !== null
            )
            .map((name) => ({
              name: name?.sequenceName!,
              groupName: name?.groupName!,
              value: name?.groupName! + '_' + name?.userId!,
              label: name?.userName!,
              userID: name?.userId,
              id: name.channelId ? name.channelId : name.departmentId,
            })) || [],
      };
      userListData.push(obj);
    });
    let childeren: ChilderenData[] | undefined = [];
    let departmentArray: ChilderenData[] | undefined = [];
    let channelArray: ChilderenData[] | undefined = [];
    let IndivudualConvertedData: IPersonaProps[] = [];
    let tempArray: TempArrayContacts[] = [];
    for (let i = 0; i < userListData.length; i++) {
      if (userListData[i].name === 'Individuals') {
        childeren = userListData[i].children;
        //groupby contacts
        const groupDataByInd = groupBy(childeren, function (item) {
          return item.groupName;
        });
        tempArray.splice(0, tempArray.length);
        Object.keys(groupDataByInd).forEach((items) => {
          const name: string = 'All Contacts';
          for (let a = 0; a < groupDataByInd[items].length; a++) {
            let obj: IPersonaProps = {
              text: groupDataByInd[items][a].label!,
              id: groupDataByInd[items][a].userID!,
            };
            IndivudualConvertedData.push(obj);
          }
          let objDep: TempArrayContacts = {
            name,
            IndivudualConvertedData,
          };
          tempArray.push(objDep);
        });
        setAllContacts(tempArray[0].IndivudualConvertedData);
      }
      if (userListData[i].name === 'Departments') {
        departmentArray = userListData[i].children;
        //groupby department
        const groupDataByDep = groupBy(departmentArray, function (item) {
          return item.groupName;
        });
        let tempArray: PickerData[] = [];
        let tempArray2: Selected_Array[] = [];
        Object.keys(groupDataByDep).forEach((items) => {
          const name: string = items;
          const employees: IPersonaProps[] = [];
          const id: string = groupDataByDep[items][0].id!;
          for (let a = 0; a < groupDataByDep[items].length; a++) {
            let obj: IPersonaProps = {
              text: groupDataByDep[items][a].label!,
              id: groupDataByDep[items][a].userID!,
            };
            employees.push(obj);
          }
          let objDep: PickerData = {
            name,
            employees,
            id,
          };
          tempArray.push(objDep);
        });
        for (let item in tempArray) {
          let temp = {
            name: tempArray[item].name,
            employees: [],
            id: userListData[i].id,
          };
          tempArray2.push(temp);
        }
        setDepartments(tempArray);
        setSelectedDepartmentsContactIds(tempArray2);
      }
      if (userListData[i].name === 'Channels') {
        channelArray = userListData[i].children;
        const groupDataByChannel = groupBy(channelArray, function (item) {
          return item.groupName;
        });
        let tempArray: PickerData[] = [];
        let tempArray2: Selected_Array[] = [];
        Object.keys(groupDataByChannel).forEach((items) => {
          const name: string = items;
          const id: string = groupDataByChannel[items][0].id!;

          const employees: IPersonaProps[] = [];
          for (let a = 0; a < groupDataByChannel[items].length; a++) {
            let obj: IPersonaProps = {
              text: groupDataByChannel[items][a].label!,
              id: groupDataByChannel[items][a].userID!,
            };
            employees.push(obj);
          }
          let objDep: PickerData = {
            name,
            employees,
            id,
          };
          tempArray.push(objDep);
        });
        for (let item in tempArray) {
          let temp = {
            name: tempArray[item].name,
            employees: [],
            id: tempArray[item].id,
          };
          tempArray2.push(temp);
        }
        setSelectedChannelsContactIds(tempArray2);
        setChannels(tempArray);
      }
    }
    setAllContacts(IndivudualConvertedData);
  }, [data]);

  return (
    <Stack className={styles.container}>
      {/* DEPARTMENTS HEADER */}
      <Text className={styles.departmentHeader} variant="mediumPlus">
        Departments
      </Text>
      {/* DEPARTMENT PICKER */}
      <List
        items={departments}
        onRenderCell={(item, index) => (
          <EmployeePicker
            id={item?.id}
            key={item?.name}
            pickerTitle={item?.name!}
            checkboxLabel="All Contacts"
            allContacts={item?.employees}
            onItemSelected={(
              selectetedContact: string[],
              key: string,
              operation = 0
            ) => onItemSelected(selectetedContact, key, operation)}
            onAllContactSelected={(
              id: string,
              isAdd: number,
              key: string,
              operation = 0
            ) => onAllContactSelected(id, isAdd, key, operation)}
          />
        )}
      />
      <Separator />
      {/* CHANNELS HEADER */}
      <Text className={styles.channelHeader} variant="mediumPlus">
        Channels
      </Text>
      {/* CHANNELS PICKER */}
      <List
        items={channels}
        onRenderCell={(item) => (
          <EmployeePicker
            id={item?.id}
            key={item?.name}
            pickerTitle={item?.name!}
            checkboxLabel="All Contacts"
            allContacts={item?.employees}
            onItemSelected={(
              selectetedContact: string[],
              key: string,
              operation = 1
            ) => onItemSelected(selectetedContact, key, operation)}
            onAllContactSelected={(
              id: string,
              isAdd: number,
              key: string,
              operation = 1
            ) => onAllContactSelected(id, isAdd, key, operation)}
          />
        )}
      />
      <Separator />
      {/* INDIVIDUALS HEADER */}
      <Text className={styles.individualsContainer} variant="mediumPlus">
        Individuals
      </Text>
      {/* INDIVIDUALS PICKER */}
      <EmployeePicker
        id="null"
        pickerTitle="All Contacts"
        checkboxLabel="All Contacts"
        allContacts={allContacts}
        onItemSelected={(
          selectetedContact: string[],
          key: string,
          operation = 2
        ) => onItemSelected(selectetedContact, key, operation)}
        onAllContactSelected={(
          id: string,
          isAdd: number,
          key: string,
          operation = 2
        ) => onAllContactSelected(id, isAdd, key, operation)}
      />
    </Stack>
  );
};

export { ContactListPicker };
