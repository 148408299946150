import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

const colHeaderLeftAlignStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    marginLeft: 0,
  },
};
export const columns: ColumnData[] = [
  {
    key: '_urgencyLevel',
    name: '',
    fieldName: '_urgencyLevel',
    minWidth: 10,
    maxWidth: 10,
    styles: colHeaderLeftAlignStyle,
    columnActionsMode: ColumnActionsMode.disabled,
    ...commonColumnProps,
  },
  {
    key: '_sortName',
    name: 'Name',
    fieldName: '_sortName',
    minWidth: 250,
    maxWidth: 250,

    ...commonColumnProps,
  },
  {
    key: 'vendorNumber',
    name: 'Code',
    fieldName: 'vendorNumber',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },
  {
    key: 'addressLine1',
    name: 'Address',
    fieldName: 'addressLine1',
    minWidth: 150,
    maxWidth: 200,
    ...commonColumnProps,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'cityName',
    name: 'City',
    fieldName: 'cityName',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'state',
    name: 'State',
    fieldName: 'state',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'country',
    name: 'Country',
    fieldName: 'country',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'rating',
    name: 'Rating',
    fieldName: 'rating',
    minWidth: 200,
    maxWidth: 200,
    ...commonColumnProps,
  },

  {
    key: 'status',
    name: 'Status',
    fieldName: 'status',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },
];
