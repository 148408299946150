import {
  ActionButton,
  IconButton,
  Stack,
  Text,
  TooltipHost,
  makeStyles,
} from '@fluentui/react';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { BusinessUnitCommonData_companyCurrencies } from '../__generated__/BusinessUnitCommonData';
import { CURRENCY_INITIAL_VALUES } from '../constants';
import { BusinessUnitValues } from '../types';
import { FormFields } from './FormFields';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.black,
    alignSelf: 'end',
    margin: '0px 4px',
  },
}));

interface CurrenciesProps {
  isUpdatable: boolean;
  companyCurrencies:
    | BusinessUnitCommonData_companyCurrencies
    | null
    | undefined;
}

export const Currencies: React.FC<CurrenciesProps> = ({
  isUpdatable,
  companyCurrencies,
}) => {
  const styles = useStyles();
  const { control, setValue } = useFormContext<BusinessUnitValues>();
  const { fields, remove, append } = useFieldArray({
    name: 'companyBusinessUnitCurrencies',
    control,
  });

  const onAdd = () => {
    append(CURRENCY_INITIAL_VALUES);
  };

  const _onPrimaryChange = (index: number, checked: boolean) => {
    if (fields.length === 1 || !checked) {
      setValue(`companyBusinessUnitCurrencies.${index}.isPrimary`, checked, {
        shouldDirty: true,
      });
    } else {
      fields?.forEach((_ele, i) => {
        setValue(`companyBusinessUnitCurrencies.${i}.isPrimary`, i === index, {
          shouldDirty: true,
        });
      });
    }
  };

  return (
    <Stack tokens={{ childrenGap: 10, padding: '20px 20px 250px' }}>
      <Text variant="xLarge">Currencies</Text>
      {fields.map((row, index) => {
        const baseField = `companyBusinessUnitCurrencies.${index}`;
        return (
          <Stack horizontal verticalAlign="end" key={row.id}>
            <FormFields
              baseField={baseField}
              currencyDisabled={!!row.id && !!row._rowTimestamp}
              companyCurrencies={companyCurrencies}
              isUpdatable={!!row._isUpdatable}
              onPrimaryChange={(checked) => {
                _onPrimaryChange(index, checked);
              }}
            />
            {
              <TooltipHost content="Remove" id="tooltipId">
                <IconButton
                  iconProps={{ iconName: 'Cancel' }}
                  className={styles.icon}
                  onClick={() => remove(index)}
                  disabled={!row._isDeletable}
                />
              </TooltipHost>
            }
          </Stack>
        );
      })}
      <Stack horizontalAlign="start">
        <ActionButton
          iconProps={{ iconName: 'add' }}
          text="Add"
          onClick={onAdd}
          disabled={!isUpdatable}
        />
      </Stack>
    </Stack>
  );
};
