import { Stack, Text, makeStyles } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import React from 'react';
// import { useFormContext } from 'react-hook-form';
// import { CorporateAccountValues } from '../types';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { useFormContext } from 'react-hook-form';
import { DepartmentGroupValues } from '../types';
import { DEPARTMENT_GROUP_INITIAL_VALUES } from '../constants';

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    cursor: 'move',
  },
  row: {
    height: 30
  }
}));

interface HeaderProps {
  isEdit: boolean;
  onClose: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  isEdit,
  onClose,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { formState: { isDirty }, reset } = useFormContext<DepartmentGroupValues>();

  const heading = isEdit ? "Edit Document Group" : "Create Document Group";

  const _onClose = () => {
    reset(DEPARTMENT_GROUP_INITIAL_VALUES)
    onClose()
  }

  return (
    <Stack
      horizontal
      className={styles.container}
      horizontalAlign="space-between"
      verticalAlign="center"
      tokens={{
        padding: 20
      }}
    >
      <Stack horizontal tokens={{ childrenGap: 10 }} className={styles.row}>
        <Text variant='xLarge' className={commonStyles.colorThemePrimary}>
          {heading}
        </Text>
        <UnsavedIndicator visible={isEdit && isDirty} />
      </Stack>
      <CloseButton onClick={_onClose} />
    </Stack>
  )
}
