import { useReactiveVar } from '@apollo/client';
import { Separator, Stack, Text } from '@fluentui/react';
import {
  ColumnData,
  getColumns,
} from 'ap/signing/components/Accounting/columns.data';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { setUserDefaults } from 'utility/cache/ui';
import {
  AccountingEntryInvoice,
  AccountingEntryInvoice_invoice_invoiceDistributions_aggregates,
  AccountingEntryInvoice_invoice_invoiceDistributions_nodes,
} from '../__generated__/AccountingEntryInvoice';
import { useStyles } from './index.styles';

interface AccountingViewProps {
  invoiceDetails: AccountingEntryInvoice;
}

export const AccountingView: React.FC<AccountingViewProps> = ({
  invoiceDetails,
}) => {
  const userDefaults = useReactiveVar(setUserDefaults);
  const { columnArray } = getColumns(userDefaults);

  const styles = useStyles();
  const mainData = invoiceDetails?.invoice!;
  return (
    <Stack className={styles.mainContainer}>
      <Stack className={styles.scrollboxWrapper}>
        <Stack className={styles.renderAccountingOptions} horizontal>
          <RenderAccountingOptions columnArray={columnArray} />
        </Stack>

        <Stack className={styles.separator}>
          <Separator />
        </Stack>
        <Stack
          className={styles.renderAccountingOptions}
          style={{ marginLeft: 25 }}
        >
          {mainData &&
            mainData?.invoiceDistributions?.nodes?.map((item, index) => {
              return (
                <Stack
                  horizontal
                  key={index}
                  className={clsx(index !== 0 && styles.accountingRowMargin)}
                >
                  <RenderAccountingRowItems
                    data={item}
                    columnArray={columnArray}
                  />
                </Stack>
              );
            })}
        </Stack>
        <Stack className={styles.separator}>
          <Separator />
        </Stack>
        {mainData?.invoiceDistributions?.aggregates! && (
          <Stack horizontal>
            <RenderAccountingAmount
              columnArray={columnArray}
              aggregates={mainData?.invoiceDistributions?.aggregates!}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

interface RenderAccountingAmountProps {
  columnArray: ColumnData[];
  aggregates: AccountingEntryInvoice_invoice_invoiceDistributions_aggregates | null;
}

const RenderAccountingAmount: React.FC<RenderAccountingAmountProps> = ({
  aggregates,
  columnArray,
}) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  let sum = aggregates?.sum?.distributionAmount;
  return (
    <Stack
      horizontalAlign="center"
      className={styles.renderAccountingOptionsContainer}
      horizontal
      tokens={{ childrenGap: 5 }}
    >
      {columnArray
        .filter((item) => item.isFormField !== false)
        .map((item, index) => {
          return (
            <Text
              key={index}
              style={{
                textAlign:
                  item.key === 'distributionAmount' ? 'right' : undefined,
                // marginLeft: index === 0 ? 0 : 5,
                // backgroundColor: 'wheat',
                marginRight: item.key === 'distributionAmount' ? 10 : 0,
                width: item.viewWidth,
              }}
              className={clsx(
                commonStyles.semibold,
                commonStyles.colorThemePrimary
              )}
            >
              {item.key === 'distributionAmount' ? (
                <AmountTextView
                  className={clsx(
                    commonStyles.semibold,
                    commonStyles.colorThemePrimary
                  )}
                  value={parseFloat(sum!.toString()).toFixed(2)}
                />
              ) : (
                <Text>-</Text>
              )}
            </Text>
          );
        })}
    </Stack>
  );
};

const RenderAccountingOptions: React.FC<{
  columnArray: ColumnData[];
}> = ({ columnArray }) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  return (
    <Stack
      horizontalAlign="center"
      className={styles.renderAccountingOptionsContainer}
      horizontal
    >
      {columnArray
        .filter((item) => item.isFormField !== false)
        .map((item, index) => {
          return (
            <Text
              key={index}
              style={{
                textAlign:
                  item.key === 'distributionAmount' ? 'right' : 'start',
                marginLeft: index === 0 ? 0 : 5,
                // backgroundColor: 'wheat',
                marginRight: item.key === 'distributionAmount' ? 10 : 0,

                width: item.viewWidth,
              }}
              className={commonStyles.semibold}
            >
              {item.name}
            </Text>
          );
        })}
    </Stack>
  );
};

const getFieldValue = (
  columnArray: ColumnData,
  data: AccountingEntryInvoice_invoice_invoiceDistributions_nodes
) => {
  switch (columnArray.key) {
    case 'accountReference':
    case 'projectReference':
    case 'setReference':
    case 'additionalDescription':
    case 'referenceCode5':
    case 'referenceCode1':
    case 'referenceCode2':
    case 'referenceCode3':
    case 'referenceCode4':
    case 'referenceCode6':
    case 'referenceCode7':
    case 'distributionAmount':
      return data[
        columnArray.key as keyof AccountingEntryInvoice_invoice_invoiceDistributions_nodes
      ];
    case 'productionIncentiveId':
      return data.productionIncentive?.incentiveType?.incentiveType;
    case 'tax1099T4TypeId':
      return data.tax1099t4Type?.tax1099T4Type;
    case 'departmentId':
      return data.department?.name;
    case 'businessUnitId':
      return data.businessUnit?.name;
  }
};

interface RenderAccountingRowItemsProps {
  columnArray: ColumnData[];
  data: AccountingEntryInvoice_invoice_invoiceDistributions_nodes;
}

const RenderAccountingRowItems: React.FC<RenderAccountingRowItemsProps> = ({
  data,
  columnArray,
}) => {
  return (
    <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 5 }}>
      {columnArray
        .filter((item) => item.isFormField !== false)
        .map((item, index) => {
          const value = getFieldValue(item, data) || '-';
          return (
            <Stack key={index} horizontalAlign="center">
              <Text
                key={item.key}
                style={{
                  width: item.viewWidth,
                  // backgroundColor: 'wheat',
                  marginRight: item.key === 'distributionAmount' ? 10 : 0,

                  textAlign:
                    item.key === 'distributionAmount' ? 'right' : undefined,
                }}
              >
                {value}
              </Text>
            </Stack>
          );
        })}
    </Stack>
  );
};
