import React from 'react';
import { ColumnData } from '../columns.data';
import { Stack, makeStyles } from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { useFormContext, useWatch } from 'react-hook-form';
import { TransactionSigningValues } from 'ap/signing/transactionSigning/view/types';
import Decimal from 'decimal.js';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '25px !important',
  },
  amountText: {
    textAlign: 'right',
  },
}));

interface DistributionRowTotalProps {
  columnsArray: ColumnData[];
}

export const DistributionRowTotal: React.FC<DistributionRowTotalProps> = ({
  columnsArray
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { control } = useFormContext<TransactionSigningValues>();
  const watchDistribution =
    useWatch({
      control,
      name: 'invoiceDistributions',
    }) || [];

  const total = watchDistribution.reduce((sum: Decimal, current) => {
    const distributionAmount = current.distributionAmount || 0;
    try {
      return new Decimal(sum || 0.0).plus(distributionAmount);
    } catch (error) {
      return sum || 0.0;
    }
  }, new Decimal(0)) as Decimal;

  // if (!watchDistribution.length) return null;
  if (watchDistribution.length <= 1) return null;
  else
    return (
      <>
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 10 }}
          className={styles.container}
        >
          {columnsArray?.map((ele, index) => {
            switch (ele.key) {
              case 'delete':
                return null;
              case 'distributionAmount':
                return (
                  <Stack
                    key={index}
                    style={{
                      minWidth: ele.maxWidth,
                    }}
                  >
                    <AmountTextView
                      className={clsx(
                        styles.amountText,
                        commonStyles.semibold,
                        commonStyles.colorThemePrimary
                      )}
                      value={total.toNumber().toFixed(2)}
                    />
                  </Stack>
                );
              default:
                return (
                  <Stack
                    key={index}
                    style={{
                      minWidth: ele.minWidth,
                    }}
                  />
                );
            }
          })}
        </Stack>
      </>
    );
}
