import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { DayOfWeek, PrimaryButton, Separator, Stack } from '@fluentui/react';
import {
  FormHookAmount,
  FormHookDatePicker,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import {
  DateCalcConsecutiveDayTotals,
  DateCalcConsecutiveDayTotalsVariables,
} from 'common/graphql/DateCalculateTotals/__generated__/DateCalcConsecutiveDayTotals';
import {
  DateCalcMonthTotals,
  DateCalcMonthTotalsVariables,
} from 'common/graphql/DateCalculateTotals/__generated__/DateCalcMonthTotals';
import {
  DateCalcNonConsecutiveDayTotals,
  DateCalcNonConsecutiveDayTotalsVariables,
} from 'common/graphql/DateCalculateTotals/__generated__/DateCalcNonConsecutiveDayTotals';
import {
  DateCalcWeek5DayTotals,
  DateCalcWeek5DayTotalsVariables,
} from 'common/graphql/DateCalculateTotals/__generated__/DateCalcWeek5DayTotals';
import {
  DateCalcWeek7DayTotals,
  DateCalcWeek7DayTotalsVariables,
} from 'common/graphql/DateCalculateTotals/__generated__/DateCalcWeek7DayTotals';
import { StandardCalculationType } from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { CustomCalendar } from 'purchaseOrder/view/FormView/POItems/POItemsModal/POItemsForm/CustomCalendar';
import React, { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { EndDateCalculator } from '../EndDateCalculator';
import {
  PurchaseOrderItemFormProps,
  PurchaseOrderItemLayout,
} from '../interface';
import { RateAmountSection } from './RateAmountSection';
import { setRateCalculate } from '..';
import { RentalDates } from '../RentalDates';
import {
  DateCalcBiWeek5DayTotals,
  DateCalcBiWeek5DayTotalsVariables,
} from 'common/graphql/DateCalculateTotals/__generated__/DateCalcBiWeek5DayTotals';
import {
  DateCalcBiWeek7DayTotals,
  DateCalcBiWeek7DayTotalsVariables,
} from 'common/graphql/DateCalculateTotals/__generated__/DateCalcBiWeek7DayTotals';
const CALC_WEEK5DAY_TOTAL = loader(
  '../../../../../../../common/graphql/DateCalculateTotals/DateCalcWeek5DayTotals.graphql'
);
const CALC_BIWEEK5DAY_TOTAL = loader(
  '../../../../../../../common/graphql/DateCalculateTotals/DateCalcBiWeek5DayTotals.graphql'
);
const CALC_BIWEEK7DAY_TOTAL = loader(
  '../../../../../../../common/graphql/DateCalculateTotals/DateCalcBiWeek7DayTotals.graphql'
);
const CALC_WEEK7DAY_TOTAL = loader(
  '../../../../../../../common/graphql/DateCalculateTotals/DateCalcWeek7DayTotals.graphql'
);
const CALC_MONTH_TOTAL = loader(
  '../../../../../../../common/graphql/DateCalculateTotals/DateCalcMonthTotals.graphql'
);
const CALC_DAYS_TOTAL = loader(
  '../../../../../../../common/graphql/DateCalculateTotals/DateCalcConsecutiveDayTotals.graphql'
);
const CALC_NON_CONSC_DAYS_TOTAL = loader(
  '../../../../../../../common/graphql/DateCalculateTotals/DateCalcNonConsecutiveDayTotals.graphql'
);

type RentalTypeItemProps = PurchaseOrderItemLayout & {
  children?: React.ReactNode;
};

export const RentalTypeItem: React.FC<RentalTypeItemProps> = ({
  selectedItemType,
  commonData,
  purchaseOrderData,
  item,
  isNew,
  children,
}) => {
  const isRateCalculate = useReactiveVar(setRateCalculate);
  const { setValue, control } = useFormContext<PurchaseOrderItemFormProps>();
  const itemRentalDates = useWatch({
    control,
    name: 'itemRentalDates',
  });
  const { purchaseOrder } = { ...purchaseOrderData };
  const { unitOfMeasureGroup, isTaxAllowed, isDiscountAllowed } = {
    ...selectedItemType,
  };
  const { unitOfMeasureItemsByUnitOfMeasureGroupId } = {
    ...unitOfMeasureGroup,
  };

  const [getWeek5Totals, { data: week5DTotals }] = useLazyQuery<
    DateCalcWeek5DayTotals,
    DateCalcWeek5DayTotalsVariables
  >(CALC_WEEK5DAY_TOTAL, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [getWeek7Totals, { data: week7DTotals }] = useLazyQuery<
    DateCalcWeek7DayTotals,
    DateCalcWeek7DayTotalsVariables
  >(CALC_WEEK7DAY_TOTAL, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  const [getBiWeek5Totals, { data: biWeek5DTotals }] = useLazyQuery<
    DateCalcBiWeek5DayTotals,
    DateCalcBiWeek5DayTotalsVariables
  >(CALC_BIWEEK5DAY_TOTAL, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  const [getBiWeek7Totals, { data: biWeek7DTotals }] = useLazyQuery<
    DateCalcBiWeek7DayTotals,
    DateCalcBiWeek7DayTotalsVariables
  >(CALC_BIWEEK7DAY_TOTAL, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [getMonthTotals, { data: monthTotals }] = useLazyQuery<
    DateCalcMonthTotals,
    DateCalcMonthTotalsVariables
  >(CALC_MONTH_TOTAL, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  const [getDaysTotals, { data: daysTotals }] = useLazyQuery<
    DateCalcConsecutiveDayTotals,
    DateCalcConsecutiveDayTotalsVariables
  >(CALC_DAYS_TOTAL, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  const [getNonConscDaysTotals, { data: nonConcDaysTotals }] = useLazyQuery<
    DateCalcNonConsecutiveDayTotals,
    DateCalcNonConsecutiveDayTotalsVariables
  >(CALC_NON_CONSC_DAYS_TOTAL, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const expenditureTypeOption = useMemo(() => {
    if (
      selectedItemType?.isExpenditureTypeAllowed &&
      !selectedItemType.isUnitOfMeasureRequired
    )
      return (
        commonData?.expenditureTypes?.nodes.map((item) => ({
          key: item.id!,
          text: item.expenditureType! || '',
          ...item,
        })) || []
      );
    else {
      return (
        selectedItemType?.unitOfMeasureGroup?.expenditureTypes.nodes.map(
          (item) => ({
            key: item.id!,
            text: item.expenditureType! || '',
            ...item,
          })
        ) || []
      );
    }
  }, [selectedItemType, commonData]);

  const rateFrequencyOption = useMemo(
    () =>
      unitOfMeasureItemsByUnitOfMeasureGroupId?.nodes.map((item) => ({
        key: item.id!,
        text: item.unitOfMeasureItem! || '',
        ...item,
      })) || [],
    [unitOfMeasureItemsByUnitOfMeasureGroupId]
  );

  const watchValues = useWatch({
    name: [
      'unitOfMeasureItemId',
      'itemFromDate',
      'itemToDate',
      'itemUnitPrice',
      'itemRentalDates',
    ],
  });

  const watchRateFrequencyId = watchValues?.[0];
  const watchStartDate = watchValues?.[1];
  const watchEndDate = watchValues?.[2];
  const watchItemRate = watchValues?.[3];
  const watchItemRentalDates = watchValues?.[4];

  const selectedFrequency = rateFrequencyOption.find(
    (item) => item.id === watchRateFrequencyId
  );

  const onCalculateClick = () => {
    setRateCalculate(true);
    switch (selectedFrequency?.calculationType) {
      case StandardCalculationType.PO_WEEK_5_DAY:
        getWeek5Totals({
          variables: {
            startDate: watchStartDate!,
            endDate: watchEndDate,
            rate: watchItemRate,
          },
        });
        break;
      case StandardCalculationType.PO_WEEK_7_DAY:
        getWeek7Totals({
          variables: {
            startDate: watchStartDate!,
            endDate: watchEndDate,
            rate: watchItemRate,
          },
        });
        break;
      case StandardCalculationType.PO_MONTH_30_DAYS:
        getMonthTotals({
          variables: {
            startDate: watchStartDate!,
            endDate: watchEndDate,
            rate: watchItemRate,
          },
        });
        break;
      case StandardCalculationType.PO_MULTI_DAY:
        getNonConscDaysTotals({
          variables: {
            dates: watchItemRentalDates,
            rate: watchItemRate,
          },
        });
        break;
      case StandardCalculationType.PO_BI_WEEK_5_DAY:
        getBiWeek5Totals({
          variables: {
            startDate: watchStartDate!,
            endDate: watchEndDate,
            rate: watchItemRate,
          },
        });
        break;
      case StandardCalculationType.PO_BI_WEEK_7_DAY:
        getBiWeek7Totals({
          variables: {
            startDate: watchStartDate!,
            endDate: watchEndDate,
            rate: watchItemRate,
          },
        });
        break;
      default:
        getDaysTotals({
          variables: {
            startDate: watchStartDate!,
            endDate: watchEndDate,
            rate: watchItemRate,
          },
        });
        break;
    }
  };

  useEffect(() => {
    if (
      selectedFrequency?.calculationType &&
      (daysTotals ||
        week5DTotals ||
        week7DTotals ||
        biWeek5DTotals ||
        biWeek7DTotals ||
        monthTotals ||
        nonConcDaysTotals)
    ) {
      const getRounded = (value: string | null | undefined) => {
        const newFloatDayRate = parseFloat(value || '0.00').toFixed(2);

        return newFloatDayRate;
      };
      switch (selectedFrequency.calculationType) {
        case StandardCalculationType.PO_DAILY:
          const { dateCalcConsecutiveDayTotals } = { ...daysTotals };
          setValue('itemDays', dateCalcConsecutiveDayTotals?.fullPeriods!, {
            shouldDirty: true,
          });
          setValue(
            'itemDayRate',
            getRounded(dateCalcConsecutiveDayTotals?.periodRate),
            {
              shouldDirty: true,
            }
          );
          setValue(
            'itemDayAmount',
            dateCalcConsecutiveDayTotals?.periodTotal!,
            { shouldDirty: true }
          );
          setValue('itemMonths', null, { shouldDirty: true });
          setValue('itemMonthRate', null, { shouldDirty: true });
          setValue('itemMonthAmount', null, { shouldDirty: true });
          setValue('itemWeeks', null, { shouldDirty: true });
          setValue('itemWeekRate', null, { shouldDirty: true });
          setValue('itemWeekAmount', null, { shouldDirty: true });
          setValue('itemRentalDates', [], { shouldDirty: true });
          break;
        case StandardCalculationType.PO_MULTI_DAY:
          const { dateCalcNonConsecutiveDayTotals } = { ...nonConcDaysTotals };
          setValue('itemDays', dateCalcNonConsecutiveDayTotals?.fullPeriods!, {
            shouldDirty: true,
          });
          setValue(
            'itemDayRate',
            getRounded(dateCalcNonConsecutiveDayTotals?.periodRate),
            { shouldDirty: true }
          );
          setValue(
            'itemDayAmount',
            dateCalcNonConsecutiveDayTotals?.periodTotal!,
            { shouldDirty: true }
          );
          setValue('itemMonths', null, { shouldDirty: true });
          setValue('itemMonthRate', null, { shouldDirty: true });
          setValue('itemMonthAmount', null, { shouldDirty: true });
          setValue('itemWeeks', null, { shouldDirty: true });
          setValue('itemWeekRate', null, { shouldDirty: true });
          setValue('itemWeekAmount', null, { shouldDirty: true });
          break;
        case StandardCalculationType.PO_WEEK_5_DAY:
          const { dateCalcWeek5DayTotals } = { ...week5DTotals };
          setValue('itemWeeks', dateCalcWeek5DayTotals?.fullPeriods!, {
            shouldDirty: true,
          });
          setValue(
            'itemWeekRate',
            getRounded(dateCalcWeek5DayTotals?.periodRate),
            {
              shouldDirty: true,
            }
          );
          setValue('itemWeekAmount', dateCalcWeek5DayTotals?.periodTotal!, {
            shouldDirty: true,
          });
          setValue('itemDays', dateCalcWeek5DayTotals?.partialPeriodDays!, {
            shouldDirty: true,
          });
          setValue('itemDayRate', getRounded(dateCalcWeek5DayTotals?.dayRate), {
            shouldDirty: true,
          });
          setValue('itemDayAmount', dateCalcWeek5DayTotals?.dayTotal!, {
            shouldDirty: true,
          });
          setValue('itemMonths', null, { shouldDirty: true });
          setValue('itemMonthRate', null, { shouldDirty: true });
          setValue('itemMonthAmount', null, { shouldDirty: true });
          setValue('itemRentalDates', [], { shouldDirty: true });
          break;
        case StandardCalculationType.PO_WEEK_7_DAY:
          const { dateCalcWeek7DayTotals } = { ...week7DTotals };
          setValue('itemWeeks', dateCalcWeek7DayTotals?.fullPeriods!, {
            shouldDirty: true,
          });
          setValue(
            'itemWeekRate',
            getRounded(dateCalcWeek7DayTotals?.periodRate),
            {
              shouldDirty: true,
            }
          );
          setValue('itemWeekAmount', dateCalcWeek7DayTotals?.periodTotal!, {
            shouldDirty: true,
          });
          setValue('itemDays', dateCalcWeek7DayTotals?.partialPeriodDays!, {
            shouldDirty: true,
          });

          setValue('itemDayRate', getRounded(dateCalcWeek7DayTotals?.dayRate), {
            shouldDirty: true,
          });
          setValue('itemDayAmount', dateCalcWeek7DayTotals?.dayTotal!, {
            shouldDirty: true,
          });
          setValue('itemMonths', null, { shouldDirty: true });
          setValue('itemMonthRate', null, { shouldDirty: true });
          setValue('itemMonthAmount', null, { shouldDirty: true });
          setValue('itemRentalDates', [], { shouldDirty: true });
          break;
        case StandardCalculationType.PO_BI_WEEK_5_DAY:
          const { dateCalcBiWeek5DayTotals } = { ...biWeek5DTotals };
          setValue('itemWeeks', dateCalcBiWeek5DayTotals?.fullPeriods!, {
            shouldDirty: true,
          });
          setValue(
            'itemWeekRate',
            getRounded(dateCalcBiWeek5DayTotals?.periodRate),
            {
              shouldDirty: true,
            }
          );
          setValue('itemWeekAmount', dateCalcBiWeek5DayTotals?.periodTotal!, {
            shouldDirty: true,
          });
          setValue('itemDays', dateCalcBiWeek5DayTotals?.partialPeriodDays!, {
            shouldDirty: true,
          });
          setValue(
            'itemDayRate',
            getRounded(dateCalcBiWeek5DayTotals?.dayRate),
            {
              shouldDirty: true,
            }
          );
          setValue('itemDayAmount', dateCalcBiWeek5DayTotals?.dayTotal!, {
            shouldDirty: true,
          });
          setValue('itemMonths', null, { shouldDirty: true });
          setValue('itemMonthRate', null, { shouldDirty: true });
          setValue('itemMonthAmount', null, { shouldDirty: true });
          setValue('itemRentalDates', [], { shouldDirty: true });
          break;
        case StandardCalculationType.PO_BI_WEEK_7_DAY:
          const { dateCalcBiWeek7DayTotals } = { ...biWeek7DTotals };
          setValue('itemWeeks', dateCalcBiWeek7DayTotals?.fullPeriods!, {
            shouldDirty: true,
          });
          setValue(
            'itemWeekRate',
            getRounded(dateCalcBiWeek7DayTotals?.periodRate),
            {
              shouldDirty: true,
            }
          );
          setValue('itemWeekAmount', dateCalcBiWeek7DayTotals?.periodTotal!, {
            shouldDirty: true,
          });
          setValue('itemDays', dateCalcBiWeek7DayTotals?.partialPeriodDays!, {
            shouldDirty: true,
          });
          setValue(
            'itemDayRate',
            getRounded(dateCalcBiWeek7DayTotals?.dayRate),
            {
              shouldDirty: true,
            }
          );
          setValue('itemDayAmount', dateCalcBiWeek7DayTotals?.dayTotal!, {
            shouldDirty: true,
          });
          setValue('itemMonths', null, { shouldDirty: true });
          setValue('itemMonthRate', null, { shouldDirty: true });
          setValue('itemMonthAmount', null, { shouldDirty: true });
          setValue('itemRentalDates', [], { shouldDirty: true });
          break;
        case StandardCalculationType.PO_MONTH_30_DAYS:
          const { dateCalcMonthTotals } = { ...monthTotals };
          setValue('itemMonths', dateCalcMonthTotals?.fullPeriods!, {
            shouldDirty: true,
          });
          setValue(
            'itemMonthRate',
            getRounded(dateCalcMonthTotals?.periodRate),
            {
              shouldDirty: true,
            }
          );
          setValue('itemMonthAmount', dateCalcMonthTotals?.periodTotal!, {
            shouldDirty: true,
          });
          setValue('itemDays', dateCalcMonthTotals?.partialPeriodDays!, {
            shouldDirty: true,
          });
          setValue('itemDayRate', getRounded(dateCalcMonthTotals?.dayRate), {
            shouldDirty: true,
          });
          setValue('itemDayAmount', dateCalcMonthTotals?.dayTotal!, {
            shouldDirty: true,
          });
          setValue('itemWeeks', null, { shouldDirty: true });
          setValue('itemWeekRate', null, { shouldDirty: true });
          setValue('itemWeekAmount', null, { shouldDirty: true });
          setValue('itemRentalDates', [], { shouldDirty: true });
          break;

        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watchRateFrequencyId,
    week5DTotals,
    week7DTotals,
    biWeek5DTotals,
    biWeek7DTotals,
    daysTotals,
    monthTotals,
    nonConcDaysTotals,
  ]);

  const fieldDisabled = !purchaseOrder?._isUpdatable && !isNew;

  const isCalculateButtonDisabled = fieldDisabled
    ? true
    : selectedFrequency?.calculationType ===
      StandardCalculationType.PO_MULTI_DAY
    ? !watchItemRentalDates || !watchItemRate
    : !watchStartDate || !watchEndDate || !watchItemRate;

  return (
    <Stack
      tokens={{
        padding: '10px 20px 10px 20px',
        childrenGap: 10,
      }}
    >
      <Stack horizontal>
        <Stack
          grow={1}
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 20 }}
        >
          {selectedItemType?.isExpenditureTypeAllowed && (
            <Stack.Item>
              <FormHookDropdown
                label="Expenditure Type"
                placeholder="Select"
                options={expenditureTypeOption}
                name="expenditureTypeId"
                required
                disabled={fieldDisabled}
                styles={{ root: { width: 250 } }}
              />
            </Stack.Item>
          )}

          <Stack.Item grow={2}>
            <FormHookTextField
              name="description"
              label="Description"
              placeholder="Description"
              disabled={fieldDisabled}
              required
            />
          </Stack.Item>
          <Stack style={{ width: 200 }}>
            <FormHookAmount
              name="itemQuantity"
              label="Quantity"
              placeholder="Quantity"
              fixedDecimalScale
              decimalScale={0}
              allowNegative={false}
              thousandSeparator={false}
              disabled={fieldDisabled}
            />
          </Stack>
        </Stack>
        <Stack grow={1} horizontalAlign="end"></Stack>
      </Stack>
      <Separator />

      <Stack tokens={{ childrenGap: 10 }}>
        <Stack horizontal>
          <Stack
            tokens={{ childrenGap: 10 }}
            verticalAlign="start"
            horizontal
            grow={2}
          >
            <Stack style={{ width: 250 }}>
              <FormHookDropdown
                label="Frequency"
                placeholder="Select"
                options={rateFrequencyOption}
                name="unitOfMeasureItemId"
                onClear={() => {}}
                disabled={fieldDisabled}
              />
            </Stack>
            {selectedFrequency?.calculationType ===
            StandardCalculationType.PO_MULTI_DAY ? (
              <Stack style={{ marginTop: 0 }}>
                {fieldDisabled ? (
                  <RentalDates dates={itemRentalDates} />
                ) : (
                  <CustomCalendar label="Multi Dates" />
                )}
              </Stack>
            ) : (
              <>
                <Stack grow={1}>
                  <FormHookDatePicker
                    name="itemFromDate"
                    placeholder="Start Date"
                    label="Start Date"
                    firstDayOfWeek={DayOfWeek.Sunday}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay
                    showGoToToday
                    disabled={fieldDisabled}
                    onSelectDate={(date) => {
                      if (date)
                        setValue('itemFromDate', dateFormat(date.toString()), {
                          shouldDirty: true,
                        });
                    }}
                  />
                </Stack>
                <Stack grow={1}>
                  <FormHookDatePicker
                    name="itemToDate"
                    placeholder="End Date"
                    label="End Date"
                    firstDayOfWeek={DayOfWeek.Sunday}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay
                    disabled={fieldDisabled}
                    onSelectDate={(date) => {
                      if (date)
                        setValue('itemToDate', dateFormat(date.toString()), {
                          shouldDirty: true,
                        });
                    }}
                  />
                </Stack>

                {watchStartDate && (
                  <EndDateCalculator
                    calculationType={selectedFrequency?.calculationType}
                    startDate={watchStartDate.toString()}
                    disabled={fieldDisabled}
                    onEndDateReturn={(date) => {
                      setValue('itemToDate', dateFormat(dateConvertions(date)));
                    }}
                  />
                )}
              </>
            )}
          </Stack>
          <Stack grow={4} />
        </Stack>

        <Stack
          horizontal
          verticalAlign="start"
          grow={1}
          style={{ marginTop: 20 }}
        >
          <Stack verticalAlign="end" horizontal grow={1}>
            <Stack style={{ width: 250 }}>
              <FormHookAmount
                name="itemUnitPrice"
                label="Rate"
                decimalScale={2}
                fixedDecimalScale
                placeholder="Enter rate"
                disabled={fieldDisabled}
              />
            </Stack>

            <PrimaryButton
              text="Calculate"
              onClick={onCalculateClick}
              disabled={isCalculateButtonDisabled}
            />
          </Stack>

          {isRateCalculate && (
            <RateAmountSection
              isNew={isNew}
              purchaseOrderData={purchaseOrderData}
              item={item}
            />
          )}
        </Stack>
      </Stack>

      {children}
      {/* Tax and Doscount checkbox */}
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
        }}
      >
        {isDiscountAllowed && (
          <FormHookCheckBox
            name="isDiscounted"
            label="Discounted"
            disabled={fieldDisabled}
          />
        )}
        {isTaxAllowed && (
          <FormHookCheckBox
            name="isTaxable"
            label="Taxable"
            disabled={fieldDisabled}
          />
        )}
      </Stack>
    </Stack>
  );
};
