import { ColumnActionsMode, IColumn, IDetailsColumnStyles } from "@fluentui/react";

const commonProps: Partial<IColumn> = {
  isResizable: true,
  columnActionsMode: ColumnActionsMode.disabled,
};

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

export const columns: IColumn[] = [
  {
    key: 'itemDescription1',
    name: 'Item#',
    fieldName: 'itemDescription1',
    minWidth: 100,
    ...commonProps,
  },
  {
    key: 'itemDescription2',
    name: 'Description',
    fieldName: 'itemDescription2',
    minWidth: 200,
    ...commonProps,
  },
  {
    key: 'itemDescription3',
    name: 'Account#',
    fieldName: 'itemDescription3',
    minWidth: 120,
    ...commonProps,
  },
  {
    key: 'itemUnit1',
    name: 'Qty',
    fieldName: 'itemUnit1',
    minWidth: 100,
    styles: rightAlignHeaderStyle,
    ...commonProps,
  },
  {
    key: 'itemDescription4',
    name: 'Term',
    fieldName: 'itemDescription4',
    minWidth: 100,
    ...commonProps,
  },
  {
    key: 'itemDescription5',
    name: 'Unit Price',
    fieldName: 'itemDescription5',
    minWidth: 100,
    styles: rightAlignHeaderStyle,
    ...commonProps,
  },
  {
    key: 'itemAmount1',
    name: 'Total',
    fieldName: 'itemAmount1',
    minWidth: 100,
    styles: rightAlignHeaderStyle,
    ...commonProps,
  },
];
