import {
  IColumn,
  IDetailsRowBaseProps,
  Icon,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { InvoiceSigningSearchFilterTotals } from 'ap/signing/__generated__/InvoiceSigningSearchFilterTotals';
import { InvoiceRow } from 'ap/signing/types';
import clsx from 'clsx';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import {
  HighLightActiveLink,
  HighlightTextView,
} from 'common/components/HighLight';
import { RedBoxIndicator } from 'common/components/RedBoxIndicator';
import { StatusIcon } from 'common/components/StatusIcon';
import { convertToTitleCase } from 'common/utils/convertToTitleCase';
import { formatDateTime, getGlobalDateFormat } from 'common/utils/dateFormats';
import React from 'react';

export type ColumnStylesProps = {
  cellContainer: string;
  statusType: string;
  logo: string;
  amountStack: string;
  urgentBox?: string;
};
export const onRenderItems = (
  globalSearchText: string,
  styles: ColumnStylesProps,
  transactionStampField: Map<string, string>,
  setTransactionStampField: (index: string, value: string | undefined) => void,
) => {

  const onRenderItemColumn = (
    item?: InvoiceRow,
    _?: number,
    column?: IColumn
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof InvoiceRow
      ] as string;
      const approvers = item.invoiceHistoryApprover?.approvers
        ? item.invoiceHistoryApprover?.approvers.split(',').join(', ')
        : '';
      const amendedByName = item.invoiceHistoryApprover?.amendedByName
        ? item.invoiceHistoryApprover?.amendedByName.split(',').join(', ')
        : '';

      const rejectors = item.invoiceHistoryApprover?.rejectors
        ? item.invoiceHistoryApprover?.rejectors.split(',').join(', ')
        : '';

      const requesterName = item.invoiceHistoryApprover?.requesterName
        ? item.invoiceHistoryApprover?.requesterName.split(',').join(', ')
        : '';

      const _isUrgentApproval = !Boolean(item._urgencyLevel);

      switch (column?.key) {
        case '_urgencyLevel':
          return (
            <RedBoxIndicator
              _isUrgentApproval={_isUrgentApproval}
              itemId={item?.id}
            />
          );
        case 'transactionType':
          return (
            <HighLightActiveLink
              highlightText={globalSearchText}
              text={item?.transactionType}
              to={`/signing/${
                item._isAccountingEntry ? 'accounting' : 'transaction'
              }/${item.id}`}
            />
          );
        case '_baseCurrencyAmount':
        case '_spotCurrencyAmount':
        case 'controlTotalAmount':
          return (
            <AmountColumnTextView
              value={fieldContent}
              searchText={globalSearchText}
            />
          );
        case 'approvedDate':
          return (
            <Stack verticalAlign="center" className={styles.cellContainer}>
              <Text>
                {item.invoiceHistoryApprover?.approvedDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.approvedDate!
                  )}
              </Text>
            </Stack>
          );
        case 'rejectedDate':
          return (
            <Stack verticalAlign="center" className={styles.cellContainer}>
              <Text>
                {item.invoiceHistoryApprover?.rejectedDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.rejectedDate!
                  )}
              </Text>
            </Stack>
          );
        case 'amendedDate':
          return (
            <Stack verticalAlign="center" className={styles.cellContainer}>
              <Text>
                {item.invoiceHistoryApprover?.amendedDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.amendedDate!
                  )}
              </Text>
            </Stack>
          );
        case 'requesterDate':
          return (
            <Stack verticalAlign="center" className={styles.cellContainer}>
              <Text>
                {item.requesterDate
                  ? getGlobalDateFormat(item.requesterDate)
                  : null}
              </Text>
            </Stack>
          );
        case 'status':
          return (
            <Stack
              verticalAlign="center"
              className={styles.cellContainer}
              horizontal
              horizontalAlign="space-between"
              tokens={{ childrenGap: 10 }}
            >
              <HighlightTextView
                className={styles.statusType}
                highlightText={globalSearchText}
                text={convertToTitleCase(item?.status!)!}
              />
              <Stack>
                {item._isAccountingEntryStampedComplete ? (
                  <TooltipHost
                    content={item._accountingStampTransactionReference || ''}
                  >
                    <Icon className={styles.logo} iconName="Stamp" />
                  </TooltipHost>
                ) : (
                  <StatusIcon
                    approval={false}
                    iconType={item.statusType!}
                    approvalData={item!}
                  />
                )}
              </Stack>
            </Stack>
          );
        case 'approvers':
          return (
            <Stack verticalAlign="center" className={styles.cellContainer}>
              <HighlightTextView
                highlightText={globalSearchText}
                text={approvers!}
              />
            </Stack>
          );
        case 'amendedByName':
          return (
            <Stack verticalAlign="center" className={styles.cellContainer}>
              <HighlightTextView
                highlightText={globalSearchText}
                text={amendedByName!}
              />
            </Stack>
          );
        case 'requesterName':
          return (
            <Stack verticalAlign="center" className={styles.cellContainer}>
              <HighlightTextView
                highlightText={globalSearchText}
                text={requesterName!}
              />
            </Stack>
          );

        case 'rejectors':
          return (
            <Stack verticalAlign="center" className={styles.cellContainer}>
              <HighlightTextView
                highlightText={globalSearchText}
                text={rejectors!}
              />
            </Stack>
          );

        case '_createdDate':
          return (
            <Stack verticalAlign="center" className={styles.cellContainer}>
              <Text>
                {item._createdDate ? formatDateTime(item._createdDate) : null}
              </Text>
            </Stack>
          );
        case 'transactionStamp':
          if (
            !item._isAccountingEntryStampedComplete &&
            item._isAccountingEntry &&
            item.reimburseAmount === null
          )
            return (
              <Stack verticalAlign="center" className={styles.cellContainer}>
                <TextField
                  underlined
                  value={transactionStampField.get(item.id) || ''}
                  onChange={(_, newValue) => {
                    setTransactionStampField(item.id, newValue);
                  }}
                />
              </Stack>
            );
          else return null;
        default:
          return (
            <Stack verticalAlign="center" className={styles.cellContainer}>
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                />
              )}
            </Stack>
          );
      }
    }
  };

  return onRenderItemColumn;
};

export const onRenderFooterItems = (
  invoicesFilterTotal: InvoiceSigningSearchFilterTotals | undefined,
  styles: any
) => {
  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      const { totalAmount1, totalAmount2, totalAmount3 } = {
        ...invoicesFilterTotal?.invoiceSigningSearchFilterTotals,
      };
      const columnKey = column?.key as keyof InvoiceRow;
      const fieldContent =
        columnKey === 'controlTotalAmount'
          ? totalAmount1
          : columnKey === '_baseCurrencyAmount'
            ? totalAmount2
            : columnKey === '_spotCurrencyAmount'
              ? totalAmount3
              : '';
      return (
        <AmountTextView
          variant="medium"
          className={clsx(styles.amountStack, styles.contentColumnAlignRight)}
          value={fieldContent!}
        />
      );
    };
  return _renderDetailsFooterItemColumn;
};
