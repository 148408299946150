import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogFooter,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
} from '@fluentui/react';
import { CustomDropdown } from 'common/components/CustomDropdown';
import { SuccessButton } from 'common/components/SuccessButton';
import {
  DocumentPackageStatusType,
  ProcessRequestInput,
} from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useEffect, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { setUserDefaults } from 'utility/cache/ui';
import { WarningModal } from './WarningModal';
import {
  PurchaseOrderEntryStampProcessing,
  PurchaseOrderEntryStampProcessingVariables,
} from './__generated__/PurchaseOrderEntryStampProcessing';
import { StampCorporatePeriods } from './__generated__/StampCorporatePeriods';
import { PurchaseOrderItem } from '..';
import {
  PurchaseOrderDocumentPackageStatus,
  PurchaseOrderDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/PurchaseOrderDocumentPackageStatus';
const PURCHASE_ORDER_ENTRY_STAMP_PROCESSING = loader(
  './PurchaseOrderEntryStampProcessing.graphql'
);
const PO_DOCUMENT_PACKAGE_STATUS = loader(
  '../../../common/graphql/DocumentPackageSubscription/PurchaseOrderDocumentPackageStatus.graphql'
);
const STAMP_CORPORATE_PERIOD = loader('./StampCorporatePeriods.graphql');

interface StampTransactionFooterProps {
  isStampTransactionMode: boolean;
  invoices: PurchaseOrderItem[] | undefined;
  stampFields: Map<string, string>;
  onStampProcessComplete: (success?: boolean) => void;
}
interface responseProps {
  itemRef: string;
  message: string;
}

export const StampTransactionFooter: React.FC<StampTransactionFooterProps> = ({
  isStampTransactionMode,
  invoices,
  stampFields,
  onStampProcessComplete,
}) => {
  const { addToast } = useToasts();
  const [visible, setVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState<responseProps[]>([]);
  const [warningVisible, setWarningVisible] = useState<responseProps[]>([]);
  const userDefaults = useReactiveVar(setUserDefaults);
  const [corporatePeriod, setCorporatePeriod] = useState<IDropdownOption>();
  const client = useApolloClient();
  const { data } = useQuery<StampCorporatePeriods>(STAMP_CORPORATE_PERIOD);

  const [stampEntry, { loading }] = useMutation<
    PurchaseOrderEntryStampProcessing,
    PurchaseOrderEntryStampProcessingVariables
  >(PURCHASE_ORDER_ENTRY_STAMP_PROCESSING, { errorPolicy: 'all' });

  const companyCorporatePeriodOptions: IDropdownOption[] =
    data?.companyCorporatePeriods?.nodes.map((item) => ({
      disabled: item.isEntryAllowed ? false : true,
      key: item.id,
      text:
        item._periodYear +
        `(${
          item.startDate! ? dateFormat(dateConvertions(item.startDate!)) : ''
        } - ${
          item.endDate! ? dateFormat(dateConvertions(item.endDate!)) : ''
        })`,
    })) || [];

  useEffect(() => {
    const selected = companyCorporatePeriodOptions.find(
      (item) => item.key === userDefaults?.companyCorporatePeriod?.id
    );

    if (selected && !corporatePeriod) setCorporatePeriod(selected);
  }, [companyCorporatePeriodOptions, corporatePeriod, userDefaults]);

  const rowsStampable = useMemo(() => {
    const data = invoices?.filter(
      (item) =>
        !item._isAccountingEntryStampedComplete && item._isAccountingEntry
    );
    return data;
  }, [invoices]);

  const stampFieldsArray = Array.from(stampFields);

  const isConfirmable = stampFieldsArray.length > 0;

  const onConfirm = async () => {
    const processRequestsData: ProcessRequestInput[] =
      rowsStampable
        ?.filter((item) => stampFields.has(item.id))
        .map(
          (item) =>
            ({
              id: item.id,
              reference: stampFields.get(item.id),
              rowTimestamp: item._rowTimestamp,
            } as ProcessRequestInput)
        ) || [];

    const { errors, data: serverResponse } = await stampEntry({
      variables: {
        input: {
          corporatePeriodId: corporatePeriod?.key.toString()!,
          processRequests: processRequestsData,
        },
      },
      update(cache, { data }) {
        const successRequest =
          data?.purchaseOrderEntryStampProcessing?.processRequests?.filter(
            (item) => item?.isSuccess
          );
        if (successRequest) {
          const sucessMapValue = new Map(
            successRequest.map((obj) => [obj?.id, obj])
          );
          const existingInvoices = invoices?.filter((item) =>
            sucessMapValue.has(item.id)
          );
          existingInvoices?.forEach((item) => {
            const responseItem = sucessMapValue.get(item.id);
            cache.modify({
              id: `PurchaseOrder:${item.id}`,
              fields: {
                statusType(existing) {
                  return {
                    ...existing,
                    statusType: 'Entered',
                    approvalState: 'APPROVED',
                    _transactionIconStatus: 'ENTERED',
                  };
                },
                _rowTimestamp() {
                  return responseItem?.rowTimestamp;
                },
                _accountingStampTransactionReference() {
                  return responseItem?.reference;
                },
                _isAccountingEntryStampedComplete() {
                  return true;
                },
              },
            });
          });
        }
      },
    });

    const { processRequests } = {
      ...serverResponse?.purchaseOrderEntryStampProcessing,
    };

    if (errors?.length) {
      addToast(errors[0].message, { appearance: 'error' });
      setVisible(false);
    } else {
      if (processRequests) {
        const warningsMessages = processRequests
          .filter((item) => item?.isWarning)
          .map(
            (item) =>
              ({
                itemRef: item?.reference,
                message: item?.message || '',
              } as responseProps)
          );
        if (warningsMessages) setWarningVisible(warningsMessages || []);
        const errorMessages = processRequests
          .filter((item) => item?.isFail)
          .map(
            (item) =>
              ({
                itemRef: item?.reference,
                message: item?.message || '',
              } as responseProps)
          );

        if (errorMessages) setErrorVisible(errorMessages || []);
      }
      setVisible(false);
      onStampProcessComplete(true);
      addToast('Purchase order stamped successfully', {
        appearance: 'success',
      });

      processRequests?.forEach((item) => {
        const observer = client.subscribe<
          PurchaseOrderDocumentPackageStatus,
          PurchaseOrderDocumentPackageStatusVariables
        >({
          query: PO_DOCUMENT_PACKAGE_STATUS,
          variables: {
            id: item?.id!,
          },
        });

        const subscription = observer.subscribe(({ data, errors }) => {
          if (errors)
            addToast('Errors received while Subscribing to document package', {
              appearance: 'error',
            });
          else {
            const { document, status } = {
              ...data?.purchaseOrderDocumentPackageStatus,
            };
            if (status === DocumentPackageStatusType.REGENERATION_FAILURE) {
              addToast(
                'Report generation failed. Document package was not created',
                { appearance: 'error' }
              );
            }
            if (status === DocumentPackageStatusType.FAILURE) {
              addToast('Error while creating Document package ', {
                appearance: 'error',
              });
            }
            if (document) {
              addToast('Document package created', {
                appearance: 'success',
              });
              client.cache.modify({
                id: client.cache.identify({
                  ...item,
                }),
                fields: {
                  _documentPackageId: () => document.id,
                },
              });
            }
          }
          subscription.unsubscribe();
        });
      });
    }
  };

  const showDialog: boolean =
    errorVisible?.length! > 0 || warningVisible.length > 0;

  return (
    <Stack>
      {isStampTransactionMode && (
        <Sticky stickyPosition={StickyPositionType.Footer}>
          <Stack
            horizontal
            horizontalAlign="end"
            verticalAlign="center"
            tokens={{ childrenGap: 10, padding: '20px 50px' }}
          >
            <Text>Enter transaction # and press</Text>
            <PrimaryButton
              text="Confirm Stamp"
              disabled={!isConfirmable}
              onClick={() => setVisible(true)}
              styles={{ root: { width: 200 } }}
            />
            <Text>Or</Text>
            <WarningModal
              isDataEntered={isConfirmable}
              onCancel={() => onStampProcessComplete()}
            />
          </Stack>
        </Sticky>
      )}
      {visible && (
        <>
          <Dialog
            dialogContentProps={{
              title:
                'Are you sure you want to stamp this purchase order as Entered?',
            }}
            hidden={false}
            minWidth={500}
            modalProps={{
              isBlocking: false,
              dragOptions: {
                moveMenuItemText: 'Move',
                closeMenuItemText: 'Close',
                menu: ContextualMenu,
                keepInBounds: true,
              },
            }}
          >
            <Stack tokens={{ childrenGap: 10 }}>
              <CustomDropdown
                label="Accounting Period"
                placeholder="Select"
                selectedKey={corporatePeriod ? corporatePeriod.key : null}
                options={companyCorporatePeriodOptions}
                onChange={(_, option) => {
                  setCorporatePeriod(option);
                }}
                onClear={() => {
                  setCorporatePeriod(undefined);
                }}
                notifyOnReselect
              />
              {loading && <ProgressIndicator />}
              <DialogFooter>
                <SuccessButton text="Confirm" onClick={onConfirm} />
                <DefaultButton
                  onClick={() => setVisible(false)}
                  text="Cancel"
                />
              </DialogFooter>
            </Stack>
          </Dialog>
        </>
      )}
      {showDialog && (
        <Dialog
          dialogContentProps={{
            title: 'Results',
          }}
          hidden={false}
          minWidth={500}
          modalProps={{
            isBlocking: false,
            dragOptions: {
              moveMenuItemText: 'Move',
              closeMenuItemText: 'Close',
              menu: ContextualMenu,
              keepInBounds: true,
            },
          }}
        >
          <Stack tokens={{ childrenGap: 10 }}>
            {errorVisible.map((item) => {
              return (
                <Stack horizontal>
                  <MessageBar messageBarType={MessageBarType.error}>
                    {item.message}
                  </MessageBar>
                </Stack>
              );
            })}

            {warningVisible.map((item) => {
              return (
                <Stack horizontal>
                  <MessageBar messageBarType={MessageBarType.warning}>
                    {item.message}
                  </MessageBar>
                </Stack>
              );
            })}

            <DialogFooter>
              <DefaultButton
                onClick={() => {
                  setErrorVisible([]);
                  onStampProcessComplete();
                }}
                text="Close"
              />
            </DialogFooter>
          </Stack>
        </Dialog>
      )}
    </Stack>
  );
};
