import { IDropdownOption } from '@fluentui/react';
import { FilterArrayType } from 'common/components/Filters';
import { PurchaseOrdersOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { PurchaseOrderSearchFilterTotalsVariables } from './__generated__/PurchaseOrderSearchFilterTotals';
import { ToNullable } from 'utility/types';

type OrderLookup = {
  [direction in OrderDirection]: PurchaseOrdersOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'purchaseOrderType',
    {
      [OrderDirection.ASC]: [
        PurchaseOrdersOrderBy.PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__PURCHASE_ORDER_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        PurchaseOrdersOrderBy.PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__PURCHASE_ORDER_TYPE_DESC,
      ],
    },
  ],
  [
    'purchaseOrderNumber',
    {
      [OrderDirection.ASC]: [PurchaseOrdersOrderBy.PURCHASE_ORDER_NUMBER_ASC],
      [OrderDirection.DESC]: [PurchaseOrdersOrderBy.PURCHASE_ORDER_NUMBER_DESC],
    },
  ],
  [
    'purchaseOrderDate',
    {
      [OrderDirection.ASC]: [PurchaseOrdersOrderBy.PURCHASE_ORDER_DATE_ASC],
      [OrderDirection.DESC]: [PurchaseOrdersOrderBy.PURCHASE_ORDER_DATE_DESC],
    },
  ],
  [
    'sortSupplierName',
    {
      [OrderDirection.ASC]: [PurchaseOrdersOrderBy.SORT_SUPPLIER_NAME_ASC],
      [OrderDirection.DESC]: [PurchaseOrdersOrderBy.SORT_SUPPLIER_NAME_DESC],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [PurchaseOrdersOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [PurchaseOrdersOrderBy.DESCRIPTION_DESC],
    },
  ],
  [
    'department',
    {
      [OrderDirection.ASC]: [
        PurchaseOrdersOrderBy.COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        PurchaseOrdersOrderBy.COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC,
      ],
    },
  ],
  [
    'businessUnit',
    {
      [OrderDirection.ASC]: [
        PurchaseOrdersOrderBy.COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        PurchaseOrdersOrderBy.COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC,
      ],
    },
  ],
  [
    'currency',
    {
      [OrderDirection.ASC]: [
        PurchaseOrdersOrderBy.COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC,
      ],
      [OrderDirection.DESC]: [
        PurchaseOrdersOrderBy.COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC,
      ],
    },
  ],
  [
    'controlTotalAmount',
    {
      [OrderDirection.ASC]: [PurchaseOrdersOrderBy.CONTROL_TOTAL_AMOUNT_ASC],
      [OrderDirection.DESC]: [PurchaseOrdersOrderBy.CONTROL_TOTAL_AMOUNT_DESC],
    },
  ],
  [
    '_referenceItemTypes',
    {
      [OrderDirection.ASC]: [PurchaseOrdersOrderBy._REFERENCE_ITEM_TYPES_ASC],
      [OrderDirection.DESC]: [PurchaseOrdersOrderBy._REFERENCE_ITEM_TYPES_DESC],
    },
  ],
  [
    '_accountingStampTransactionReference',
    {
      [OrderDirection.ASC]: [
        PurchaseOrdersOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
      ],
      [OrderDirection.DESC]: [
        PurchaseOrdersOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC,
      ],
    },
  ],
  [
    '_createdByUserName',
    {
      [OrderDirection.ASC]: [PurchaseOrdersOrderBy._CREATED_BY_USER_NAME_ASC],
      [OrderDirection.DESC]: [PurchaseOrdersOrderBy._CREATED_BY_USER_NAME_DESC],
    },
  ],
  [
    '_createdDate',
    {
      [OrderDirection.ASC]: [PurchaseOrdersOrderBy._CREATED_DATE_ASC],
      [OrderDirection.DESC]: [PurchaseOrdersOrderBy._CREATED_DATE_DESC],
    },
  ],
  [
    '_baseCurrencyAmount',
    {
      [OrderDirection.ASC]: [PurchaseOrdersOrderBy._BASE_CURRENCY_AMOUNT_ASC],
      [OrderDirection.DESC]: [PurchaseOrdersOrderBy._BASE_CURRENCY_AMOUNT_DESC],
    },
  ],
  [
    '_spotCurrencyAmount',
    {
      [OrderDirection.ASC]: [PurchaseOrdersOrderBy._SPOT_CURRENCY_AMOUNT_ASC],
      [OrderDirection.DESC]: [PurchaseOrdersOrderBy._SPOT_CURRENCY_AMOUNT_DESC],
    },
  ],
  [
    'status',
    {
      [OrderDirection.ASC]: [
        PurchaseOrdersOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        PurchaseOrdersOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    PurchaseOrdersOrderBy.PURCHASE_ORDER_NUMBER_DESC,
    PurchaseOrdersOrderBy.PRIMARY_KEY_ASC,
  ];
  if (!sort) return orderByArray;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [...(orderBy ? orderBy : []), PurchaseOrdersOrderBy.PRIMARY_KEY_ASC];
};

export const toFilterVariable = (filterList: FilterArrayType[]) =>
  filterList.map((filter) => {
    switch (filter.filterKey) {
      case 'purchaseOrderTypeId':
      case 'departmentId':
      case 'businessUnitId':
      case 'currencyId':
      case '_statusTypeId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };
      default:
        return undefined;
    }
  });

type searchTotalFilterType = ToNullable<
  Omit<
    PurchaseOrderSearchFilterTotalsVariables,
    | 'isSigningSearch'
    | 'isHistorySearch'
    | 'searchRequest'
    | 'isLinkSearch'
    | 'isSearchAllData'
  >
>;
export const getFilterParams = (searchFilters: FilterArrayType[]) => {
  const searchTotalFilterArray: searchTotalFilterType = {
    filterCurrencyId: undefined,
    filterPurchaseOrderTypeId: undefined,
    filterBusinessUnitId: undefined,
    filterDepartmentId: undefined,
    filterStatusTypeId: undefined,
  };
  searchFilters.forEach((curr) => {
    switch (curr.filterKey) {
      case 'purchaseOrderTypeId':
        return (searchTotalFilterArray['filterPurchaseOrderTypeId'] =
          curr.value as number | null);
      case 'businessUnitId':
        return (searchTotalFilterArray['filterBusinessUnitId'] =
          curr.value as GqlUUID | null);
      case 'departmentId':
        return (searchTotalFilterArray['filterDepartmentId'] =
          curr.value as GqlUUID | null);
      case '_statusTypeId':
        return (searchTotalFilterArray['filterStatusTypeId'] = curr.value as
          | number
          | null);
      case 'currencyId':
        return (searchTotalFilterArray['filterCurrencyId'] = curr.value as
          | number
          | null);
      default:
        return searchTotalFilterArray;
    }
  });
  return searchTotalFilterArray;
};

export enum listTypeEnum {
  all = 'ALL_TRANSACTIONS',
  my = 'MY_TRANSACTIONS',
}

export const listTypeOptions: IDropdownOption[] = [
  {
    key: listTypeEnum.my,
    text: 'My Transactions',
  },
  {
    key: listTypeEnum.all,
    text: 'All Transactions',
  },
];
