import { PrimaryButton, Stack } from '@fluentui/react';
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import React, { useState } from 'react';
import { ReviseModal } from './ReviseModal';

interface ReviseProps {
  purchaseOrder: PurchaseOrder | undefined;
}

export const Revise: React.FC<ReviseProps> = ({ purchaseOrder }) => {
  const [visibility, setVisibility] = useState(false);

  const { _isRevisable } = { ...purchaseOrder?.purchaseOrder };
  return (
    <Stack>
      {_isRevisable && (
        <PrimaryButton text="Revise" onClick={() => setVisibility(true)} />
      )}
      {visibility && (
        <ReviseModal
          isOpen={visibility}
          purchaseOrder={purchaseOrder}
          onClose={() => {
            setVisibility(false);
          }}
        />
      )}
    </Stack>
  );
};
