import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.palette.neutralQuaternaryAlt,
    position: 'relative',
    '::before': {
      backgroundColor: theme.palette.accent,
      content: '""',
      width: 3,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    flexGrow: 1,
    display: 'flex',
    alignSelf: 'stretch',
  },
  linkHover: {
    ':hover': {
      backgroundColor: theme.palette.neutralQuaternaryAlt,
    },
  },
  linkLightHover: {
    ':hover': {
      backgroundColor: theme.palette.neutralLighter,
    },
  },
  iconContainer: {
    padding: '12px 0',
    width: 48,
  },
  compactIconContainer: {
    padding: '8px 0',
    width: 44,
  },
  largeIcon: {
    height: 22,
    width: 22,
    fontSize: 22,
  },
  smallIcon: {
    height: 16,
    width: 16,
    fontSize: 16,
    fill: theme.palette.neutralDark,
  },
}));
