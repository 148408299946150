import { Text, View } from '@react-pdf/renderer';
import { getFormattedAmountValue } from 'common/components/AmountView/utils';
import { TransactionLayout } from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import React, { useCallback, useEffect, useState } from 'react';
import { InvoiceDetails } from '../../../__generated__/InvoiceDetails';
import { LabelParams } from '../../BasicForm/utils';
import { styles } from './index.stylesheet';
import { InvoiceTransactionTypes } from 'ap/signing/__generated__/InvoiceTransactionTypes';

interface BasicFormProps {
  data?: InvoiceDetails;
  transactionTypeData?: InvoiceTransactionTypes;
}
export const BasicForm: React.FC<BasicFormProps> = ({
  data,
  transactionTypeData,
}) => {
  const { invoice } = { ...data };
  const [bunit, setBunit] = useState<string>('');
  const [department, setDepartment] = useState<string>('');
  const [companyPeriod, setCompanyPeriod] = useState<string>('');
  const [currency, setCurrency] = useState<string>('');
  const { businessUnitId, departmentId, corporatePeriodId, currencyId } = {
    ...invoice,
  };
  const [vendorLabelPlaceholder, setVendorLabelPlaceholder] =
    useState<string>();
  const [invoiceNumberLabelPlaceholder, setInvoiceNumberLabelPlaceholder] =
    useState<string | undefined>();
  const [invoiceDateLabelPlaceholder, setInvoiceDateLabelPlaceholder] =
    useState<string | undefined>();
  const [invoiceTotalLabelPlaceholder, setInvoiceTotalLabelPlaceholder] =
    useState<string | undefined>();
  const setFormLabelsAndPlaceholders = (param: LabelParams) => {
    setVendorLabelPlaceholder(param.vendor);
    setInvoiceNumberLabelPlaceholder(param.invoiceNumber);
    setInvoiceTotalLabelPlaceholder(param.invoiceTotal);
    setInvoiceDateLabelPlaceholder(param.invoiceDate);
  };

  useEffect(() => {
    if (invoice?.transactionType?.layoutType) {
      switch (invoice?.transactionType?.layoutType) {
        case TransactionLayout.AP_INVOICE:
          return setFormLabelsAndPlaceholders({
            vendor: 'Vendor',
            invoiceNumber: 'Invoice Number',
            invoiceDate: 'Invoice Date',
            invoiceTotal: 'Invoice Total',
          });
        case TransactionLayout.PETTY_CASH:
        case TransactionLayout.PER_DIEM:
        case TransactionLayout.PETTY_CASH_FUND:
        case TransactionLayout.PETTY_CASH_ELAPSED_DAYS:
          return setFormLabelsAndPlaceholders({
            vendor: 'Employee',
            invoiceNumber: 'Envelope Number',
            invoiceDate: 'Envelope Date',
            invoiceTotal: 'Envelope Amount',
          });
        case TransactionLayout.PR_INVOICE:
          return setFormLabelsAndPlaceholders({
            vendor: 'Payroll Company',
            invoiceNumber: 'PR Invoice Number',
            invoiceDate: 'Invoice Date',
            invoiceTotal: 'Invoice Total',
          });
        case TransactionLayout.CREDIT_CARD_INVOICE:
          return setFormLabelsAndPlaceholders({
            vendor: 'Card holder',
            invoiceNumber: 'Statement Number',
            invoiceDate: 'Date',
            invoiceTotal: 'Card Total',
          });
        case TransactionLayout.PURCHASE_ORDER:
          return setFormLabelsAndPlaceholders({
            vendor: 'Supplier',
            invoiceNumber: 'PO Number',
            invoiceDate: 'Order Date',
            invoiceTotal: 'Order Total',
          });
        case TransactionLayout.JOURNAL_VOUCHER:
          return setFormLabelsAndPlaceholders({
            vendor: 'Journal Type',
            invoiceNumber: 'Voucher Number',
            invoiceDate: 'Journal Date',
            invoiceTotal: 'Control Total (Debits)',
          });
        case TransactionLayout.TRAVEL_AUTHORIZATION:
          return setFormLabelsAndPlaceholders({
            vendor: 'Passenger(s)',
            invoiceNumber: 'TA Number',
            invoiceDate: 'Transaction Date',
            invoiceTotal: 'Trip Total',
          });
        case TransactionLayout.WIRE_TRANSFER:
          return setFormLabelsAndPlaceholders({
            vendor: 'Wire To (Name)',
            invoiceNumber: 'Reference Number',
            invoiceDate: 'Wire Date',
            invoiceTotal: 'Wire Total',
          });
        case TransactionLayout.PAYMENT_REQUEST:
          return setFormLabelsAndPlaceholders({
            vendor: 'Pay To (Name)',
            invoiceNumber: 'Reference Number',
            invoiceDate: 'Payment Date',
            invoiceTotal: 'Payment Total',
          });
        case TransactionLayout.PAYMENT:
          return setFormLabelsAndPlaceholders({
            vendor: 'Pay To (Name)',
            invoiceNumber: 'Reference/Cheque Number',
            invoiceDate: 'Payment Date',
            invoiceTotal: 'Payment Total',
          });
        case TransactionLayout.PAY_CARD:
          return setFormLabelsAndPlaceholders({
            vendor: 'Vendor/Merchant',
            invoiceNumber: 'Reference Number',
            invoiceDate: 'Transaction Date',
            invoiceTotal: 'Card Total',
          });
        default:
          return setFormLabelsAndPlaceholders({
            vendor: 'Vendor',
            invoiceNumber: 'Invoice Number',
            invoiceDate: 'Invoice Date',
            invoiceTotal: 'Invoice Total',
          });
      }
    }
  }, [invoice]);
  const extractBusinessUnit = useCallback(() => {
    for (let data of transactionTypeData?.companyBusinessUnits?.nodes!) {
      if (data.id === businessUnitId) {
        setBunit(data.name!);
      }
    }
  }, [businessUnitId, transactionTypeData]);

  const extractCurrencyUnit = useCallback(() => {
    for (let data of transactionTypeData?.companyCurrencies?.nodes!) {
      if (data.id === currencyId) {
        setCurrency(data.isoCode! + '-' + data.name || '');
      }
    }
  }, [currencyId, transactionTypeData]);

  const extractDepartment = useCallback(() => {
    for (let data of transactionTypeData?.companyDepartments?.nodes!) {
      if (data.id === departmentId) {
        setDepartment(data.name!);
      }
    }
  }, [departmentId, transactionTypeData]);

  const extractCompanyPeriod = useCallback(() => {
    for (let data of transactionTypeData?.companyCorporatePeriods?.nodes!) {
      if (data.id === corporatePeriodId) {
        setCompanyPeriod(
          data._periodYear! +
            ' (' +
            dateFormat(dateConvertions(data.startDate)) +
            ' - ' +
            dateFormat(dateConvertions(data.endDate)) +
            ')' || ''
        );
      }
    }
  }, [corporatePeriodId, transactionTypeData]);

  useEffect(() => {
    extractBusinessUnit();
    extractDepartment();
    extractCompanyPeriod();
    extractCurrencyUnit();
  }, [
    extractBusinessUnit,
    extractCompanyPeriod,
    extractCurrencyUnit,
    extractDepartment,
  ]);
  const isPettyCashLayout: boolean =
    invoice?.transactionType?.layoutType === TransactionLayout.PETTY_CASH;
  const isPayrollPettyCashLayout: boolean =
    invoice?.transactionType?.layoutType ===
    TransactionLayout.PETTY_CASH_ELAPSED_DAYS;
  return (
    <View style={{ padding: 20, borderBottomWidth: 0.1, marginTop: 10 }}>
      <View style={styles.formContainer}>
        <View style={{ flex: 1 }}>
          <Text style={styles.title}>Transaction type</Text>
          <Text style={styles.value}>
            {invoice?.transactionType?.transactionType || '-'}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.title}>Department</Text>
          <Text style={styles.value}>{department || '-'}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.title}>Business unit</Text>
          <Text style={styles.value}>{bunit || '-'}</Text>
        </View>
      </View>
      <View style={styles.formContainer}>
        <View style={{ flex: 1 }}>
          <Text style={styles.title}>{invoiceDateLabelPlaceholder}</Text>
          <Text style={styles.value}>
            {(invoice?.invoiceDate &&
              dateFormat(dateConvertions(invoice?.invoiceDate))) ||
              '-'}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.title}>{vendorLabelPlaceholder}</Text>
          <Text style={styles.value}>{invoice?.vendorReference}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.title}>Accounting Period</Text>
          <Text style={styles.value}>{companyPeriod || '-'}</Text>
        </View>
      </View>
      <View style={styles.formContainer}>
        <View style={{ flex: 1 }}>
          <Text style={styles.title}>Description</Text>
          <Text style={styles.value}>{invoice?.description}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.title}>Currency</Text>
          <Text style={styles.value}>{currency || '-'}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.title}>{invoiceNumberLabelPlaceholder}</Text>
          <Text style={styles.value}>{invoice?.invoiceNumber}</Text>
        </View>
      </View>
      <View style={styles.formContainer}>
        <View style={{ flex: 1 }}>
          <Text style={styles.title}>{invoiceTotalLabelPlaceholder}</Text>
          <Text style={styles.value}>
            {invoice?.controlTotalAmount
              ? getFormattedAmountValue(invoice?.controlTotalAmount.toString())
              : '-'}
          </Text>
        </View>
        {isPettyCashLayout && (
          <View style={{ flex: 1 }}>
            <Text style={styles.title}>Reimbursed Amount</Text>
            <Text style={styles.value}>
              {invoice?.reimburseAmount
                ? getFormattedAmountValue(invoice?.reimburseAmount.toString())
                : '-'}
            </Text>
          </View>
        )}
        {isPettyCashLayout && (
          <View style={{ flex: 1 }}>
            <Text style={styles.title}>Account</Text>
            <Text style={styles.value}>
              {invoice?.reimburseAccountReference || '-'}
            </Text>
          </View>
        )}
        {isPayrollPettyCashLayout && (
          <View style={{ flex: 1 }}>
            <Text style={styles.title}>Receipts Dated</Text>
            <Text style={styles.value}>
              {(invoice?.approvalTriggerDate &&
                dateFormat(dateConvertions(invoice?.approvalTriggerDate))) ||
                '-'}
            </Text>
          </View>
        )}

        {invoice?._elapsedTriggerDays && invoice?._elapsedTriggerDays > 0 && (
          <View style={{ flex: 1 }}>
            <Text style={styles.title}>Days elapsed </Text>
            <Text style={styles.value}>
              {invoice?._elapsedTriggerDays || '-'}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};
