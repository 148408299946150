import {
  DetailsList,
  DetailsRow,
  IColumn,
  IDetailsFooterProps,
  IRenderFunction,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import { TransactionModal } from 'ap/paymentCycle/view/TransactionModal';
import clsx from 'clsx';
import { ActiveLink } from 'common/components/ActiveRowLink';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { BatchTransaction_batchTransaction_invoiceBatches_nodes } from 'postingTracker/batchEdit/batch/view/__generated__/BatchTransaction';
import React, { useCallback, useState } from 'react';
import { FormViewProps } from '..';
import { columns } from './column.data.ts';
import { useStyles } from './index.styles';
import { setUserDefaults } from 'utility/cache/ui';
import { useReactiveVar } from '@apollo/client';

type TransactionsProps = Pick<FormViewProps, 'batchTransactionData'> & {};
type TransactionListItem =
  BatchTransaction_batchTransaction_invoiceBatches_nodes & {
    name: string | null;
    vendorReference: string | null;
    description: string;
    transactionType: string | null;
    invoiceDate?: string;
  };
export const Transactions: React.FC<TransactionsProps> = ({
  batchTransactionData,
}) => {
  const styles = useStyles();
  const [selectedInvoice, setSelectedInvoice] = useState<string | undefined>();
  const userDefaults = useReactiveVar(setUserDefaults);

  const dataNew =
    batchTransactionData?.batchTransaction?.invoiceBatches?.nodes?.map(
      (item) => {
        const { description, invoiceNumber, vendorReference } = {
          ...item.invoice,
        };
        return {
          ...item,
          description,
          name: vendorReference,
          invoiceNumber,
          transactionType: item.invoiceBatchTransactionType?.transactionType,
          invoiceDate: item.invoice?.invoiceDate
            ? dateFormat(dateConvertions(item.invoice?.invoiceDate))
            : '',
          transactionNo: item.invoice?._accountingStampTransactionReference,
        };
      }
    ) || [];

  const renderFooter: IRenderFunction<IDetailsFooterProps> = (props) => {
    if (
      !props ||
      !batchTransactionData?.batchTransaction?.invoiceBatches.aggregates?.sum
    ) {
      return null;
    }
    return (
      <DetailsRow
        {...props}
        item={{}}
        onRenderItemColumn={renderFooterColumn}
        itemIndex={-1}
      />
    );
  };

  const renderFooterColumn = (
    _item?: TransactionListItem,
    _index?: number,
    column?: IColumn
  ) => {
    if (
      column?.key === 'appliedAmount' &&
      batchTransactionData?.batchTransaction?.invoiceBatches?.aggregates?.sum
        ?.appliedAmount
    ) {
      const totalAmount = batchTransactionData?.batchTransaction?.invoiceBatches
        ?.aggregates?.sum?.appliedAmount
        ? Number(
            batchTransactionData?.batchTransaction?.invoiceBatches?.aggregates
              ?.sum?.appliedAmount
          ).toFixed(2)
        : '-';
      return (
        <AmountTextView
          value={totalAmount}
          className={clsx(styles.amount, styles.amountTotal)}
        />
      );
    }
    return null;
  };

  const onRenderItemColumn = useCallback(
    (item?: TransactionListItem, _index?: number, column?: IColumn) => {
      if (!item || !column) return undefined;
      const fieldContent = item[
        column.fieldName as keyof TransactionListItem
      ] as string | null;

      switch (column.key) {
        case 'appliedAmount':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text className={styles.contentColumnAlignRight}>
                <AmountTextView value={fieldContent} />
              </Text>
            </Stack>
          );
        case 'transactionType':
          return (
            <ActiveLink
              to=""
              text={fieldContent}
              className={clsx(styles.columnHeight, styles.firstColumn)}
              onClick={() => {
                setSelectedInvoice(item.invoiceId!);
              }}
            >
              <Text> {fieldContent}</Text>
            </ActiveLink>
          );
        case 'invoiceDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text>{fieldContent}</Text>
            </Stack>
          );

        default:
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    },
    [styles]
  );
  if (!dataNew.length) return null;
  else
    return (
      <Stack>
        {
          <Stack
            className={styles.container}
            horizontal
            horizontalAlign="space-between"
          >
            <Text variant="xLarge">Transactions</Text>
          </Stack>
        }

        <Stack tokens={{ padding: '10px 0px 10px 10px' }}>
          {/* {selectedTransaction.length > 0 && ( */}
          <DetailsList
            setKey="set"
            compact
            selectionMode={SelectionMode.none}
            items={dataNew || []}
            columns={columns}
            onRenderItemColumn={onRenderItemColumn}
            onRenderDetailsFooter={renderFooter}
          />

          {selectedInvoice !== undefined && (
            <TransactionModal
              entityId={selectedInvoice}
              isOpen={selectedInvoice !== undefined}
              onDismiss={() => setSelectedInvoice(undefined)}
            />
          )}
          {/* )} */}
        </Stack>
      </Stack>
    );
};
