import { SupplierDetails, } from "../__generated__/SupplierDetails";
import { SUPPLIER_INITIAL_VALUES } from "./constants";
import { SupplierValues } from "./types";

interface DefaultValueProps {
  isNew: boolean;
  supplierDetails: SupplierDetails | undefined;
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { isNew, supplierDetails } = { ...props };
  let defaultValues: SupplierValues = { ...SUPPLIER_INITIAL_VALUES };
  if (supplierDetails?.supplier && !isNew) {
    const newSupplierValues = Object.entries(supplierDetails.supplier)
    .reduce((prev, [key, value]) => {
      if(
        key === "companyOrLastName" || 
        key === "firstName" || 
        key === "isRelatedPartySupplier" ||
        key === "isCompetitiveBidsRequired" ||
        key === "isContractualPurchaseOrderRequired" ||
        key === "isCorporation" ||
        key === "isIndividual"
        ){
        return { ...prev, [key]: value };
      } else
       return prev
    }, {supplierAddressesBySupplierId: supplierDetails.supplier.supplierAddressesBySupplierId.nodes}) 
    return newSupplierValues as SupplierValues;
  }
  return defaultValues as SupplierValues;
};
