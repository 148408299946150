import { IDropdownOption } from '@fluentui/react';
import { AvailablePurchaseOrders } from 'ap/signing/__generated__/AvailablePurchaseOrders';
import { InvoiceTransactionTypes } from 'ap/signing/__generated__/InvoiceTransactionTypes';
import {
  FormHookAmount,
  FormHookDropdown,
} from 'common/components/FormHooksFields';
import { FormHookTextField } from 'common/components/FormHooksFields/FormHookTextField';
import { ColumnData } from 'common/components/SearchBar';
import { AutoCompleteTextField } from 'documents/documentAssignment/folder/view/FormView/AccountingView/CustomFields/AutoCompleteTextField';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { InvoiceDetails_invoice } from '../../../transactionSigning/view/__generated__/InvoiceDetails';
import { TransactionSigningValues } from '../../../transactionSigning/view/types';
import { AutoCompleteFF1 } from '../CustomFields/FF1';
import { AutoCompleteFF2 } from '../CustomFields/FF2';
import { AutoCompleteFF3 } from '../CustomFields/FF3';
import { AutoCompleteFF4 } from '../CustomFields/FF4';
import { PoReferenceModal } from '../CustomFields/POReference';
export interface FormFieldProps {
  baseField: string;
  columnData: ColumnData;
  removeCurrentIndex: () => void;
  // isLastItem: boolean;
  refCodesOptions: {
    refCode1Options: IDropdownOption[];
    refCode2Options: IDropdownOption[];
    refCode3Options: IDropdownOption[];
    refCode4Options: IDropdownOption[];
  };
  index: number;
  invoiceData?: Partial<InvoiceDetails_invoice>;
  invoiceDistributionStatusTypes: InvoiceTransactionTypes | undefined;
  availablePurchaseOrders: AvailablePurchaseOrders | undefined;
}

const FormField: React.FC<FormFieldProps> = ({
  baseField,
  columnData,
  refCodesOptions,
  index,
  invoiceDistributionStatusTypes,
  availablePurchaseOrders,
  invoiceData,
}) => {
  const { setValue } = useFormContext<TransactionSigningValues>();
  const watch = useWatch<TransactionSigningValues>();
  const tax1099T4TypesOptions =
    invoiceDistributionStatusTypes?.tax1099T4Types?.nodes.map((item) => ({
      key: item.id,
      text: item.tax1099T4Type || '',
    })) || [];
  const additionalDepartmentOptions =
    invoiceDistributionStatusTypes?.companyDepartments?.nodes.map((item) => ({
      key: item.id,
      text: item.name || '',
    })) || [];
  const additionalBusinessUnitOptions =
    invoiceDistributionStatusTypes?.companyBusinessUnits?.nodes.map((item) => ({
      key: item.id,
      text: item.name || '',
    })) || [];
  const productIncentivesOptions =
    invoiceDistributionStatusTypes?.productionIncentives?.nodes.map((item) => ({
      key: item.id,
      text: item.incentiveType?.incentiveType || '',
    })) || [];

  switch (columnData.key) {
    case 'delete':
      return null;
    case 'referenceCode6':
    case 'referenceCode7':
      // return null;
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'accountReference':
      return (
        <AutoCompleteTextField
          businessUnitId={watch.businessUnitId || null}
          id={`accountReference${columnData.key}`}
          name={`${baseField}.${columnData.key}`}
          width={columnData.maxWidth!}
          lookUpCalloutWidth={500}
          onVendorSelect={(data) => {
            if (!!data.projectAccount) {
              setValue(
                `invoiceDistributions.${index}.projectReference`,
                data.projectAccount
              );
            }
            if (!!data.setAccount) {
              setValue(
                `invoiceDistributions.${index}.setReference`,
                data.setAccount
              );
            }
          }}
        />
      );
    case 'referenceCode1':
      return (
        <AutoCompleteFF1
          index={index}
          invoiceDistributions={invoiceData?.invoiceDistributions?.nodes || []}
          name={`${baseField}.${columnData.key}`}
          columnData={columnData}
        />
      );
    case 'referenceCode2':
      return (
        <AutoCompleteFF2
          index={index}
          invoiceDistributions={invoiceData?.invoiceDistributions?.nodes || []}
          name={`${baseField}.${columnData.key}`}
          columnData={columnData}
        />
      );
    case 'referenceCode3':
      return (
        <AutoCompleteFF3
          index={index}
          invoiceDistributions={invoiceData?.invoiceDistributions?.nodes || []}
          name={`${baseField}.${columnData.key}`}
          columnData={columnData}
        />
      );
    case 'referenceCode4':
      return (
        <AutoCompleteFF4
          index={index}
          invoiceDistributions={invoiceData?.invoiceDistributions?.nodes || []}
          name={`${baseField}.${columnData.key}`}
          columnData={columnData}
        />
      );
    case 'productionIncentiveId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={productIncentivesOptions}
          width={columnData.maxWidth}
          underlined
        />
      );
    case 'tax1099T4TypeId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={tax1099T4TypesOptions}
          width={columnData.maxWidth}
          underlined
        />
      );
    case 'departmentId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={additionalDepartmentOptions}
          width={columnData.maxWidth}
          calloutProps={{ calloutWidth: 200 }}
          underlined
        />
      );
    case 'businessUnitId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={additionalBusinessUnitOptions}
          width={columnData.maxWidth}
          underlined
        />
      );
    case 'distributionAmount':
      return (
        <FormHookAmount
          name={`${baseField}.${columnData.key}`}
          style={{ width: columnData.maxWidth, textAlign: 'end' }}
          fixedDecimalScale
          decimalScale={2}
          maxLength={15}
          allowNegative={true}
          underlined
        />
      );
    case 'purchaseOrderReference':
      return (
        <PoReferenceModal
          index={index}
          availablePurchaseOrders={availablePurchaseOrders}
          invoiceDistributions={invoiceData?.invoiceDistributions?.nodes || []}
        />
      );
    default:
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          style={{ width: columnData.maxWidth }}
        />
      );
  }
};

export default FormField;
