import { useQuery, useReactiveVar } from '@apollo/client';
import {
  ChoiceGroup,
  IChoiceGroupOption,
  IChoiceGroupStyles,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import { GetApprovalCommonData } from 'approvals/__generated__/GetApprovalCommonData';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { SearchBar, SearchBarProps } from 'common/components/SearchBar';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { globalMode } from 'utility/cache/ui';
import { useStyles } from './index.styles';

const COMMON_DATA = loader('../GetApprovalCommonData.graphql');
interface ApprovalSearchBarProps extends SearchBarProps, MenuActionBarProps {
  onFilterChange?: (filterarray: FilterArrayType[]) => void;
}

export const ApprovalSearchBar: React.FC<ApprovalSearchBarProps> = ({
  columns,
  onFilterChange,
  onEnterPress,
  hintsAvailable,
  onRefresh,
  searchInputPlaceholderText,
  onToggleVisibility,
  onHintsViewToggle,
  onHistoryToogle,
  onQueuedToogle,
  onActionPendingToggle,
  urlParams,
  onGlobalToggle,
  isGlobalAvailable,
  actionPendingFilter,
}) => {
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const { data: commonData } = useQuery<GetApprovalCommonData>(COMMON_DATA);

  const businessUnitOptions = formatDropdownOptions(
    commonData?.companyBusinessUnits?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );

  const departmentOptions = formatDropdownOptions(
    commonData?.availableApprovalDepartments?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );

  const statusTypeOptions = formatDropdownOptions(
    commonData?.approvalIconTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.iconStatusType!,
      includeAll: true,
    }
  );

  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    const newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    onFilterChange?.(newFilters);
    setFilterTypes(newFilters);
  };

  return (
    <SearchBar
      urlParams={urlParams}
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange?.([]);
        setFilterTypes([]);
      }}
      columns={columns}
      searchInputPlaceholderText={searchInputPlaceholderText}
      onEnterPress={onEnterPress}
      onRefresh={onRefresh}
      hintsAvailable={hintsAvailable}
      onHintsViewToggle={onHintsViewToggle}
      onToggleVisibility={onToggleVisibility}
      onGlobalToggle={onGlobalToggle}
      isGlobalAvailable={isGlobalAvailable}
      renderActions={
        <MenuActionBar
          onQueuedToogle={onQueuedToogle}
          onHistoryToogle={onHistoryToogle}
          onActionPendingToggle={onActionPendingToggle}
          actionPendingFilter={actionPendingFilter}
        />
      }
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20, padding: '0px 0px 0px 20px' }}
      >
        <DropdownFilter
          filterKey={'businessUnitId'}
          options={businessUnitOptions!}
          placeholder={'Business Unit'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'departmentId'}
          options={departmentOptions!}
          placeholder={'Department'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          dropdownWidth={200}
          filterKey={'iconTypeId'}
          options={statusTypeOptions!}
          placeholder={'Status'}
          onOptionChange={onOptionChange}
        />
      </Stack>
    </SearchBar>
  );
};
interface MenuActionBarProps {
  onQueuedToogle?: (value: boolean) => void;
  onHistoryToogle?: (value: boolean) => void;
  onActionPendingToggle?: (value: boolean) => void;
  actionPendingFilter: boolean;
}

const choiceGroupStyles: IChoiceGroupStyles = {
  label: {
    display: 'inline',
  },
  flexContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 240,
  },
};

export const MenuActionBar: React.FC<MenuActionBarProps> = ({
  onQueuedToogle,
  onHistoryToogle,
  onActionPendingToggle,
  actionPendingFilter,
}) => {
  const globalState = useReactiveVar(globalMode);
  const styles = useStyles();
  const TOOLTIP_ACTIVE_TEXT = 'Requiring immediate approval';
  const TOOLTIP_PENDING_TEXT =
    'Waiting approval, upcoming approval or already approved';
  const TOOLTIP_HISTORY_TEXT =
    'Inactive approvals: fully approved or cancelled';

  const options: IChoiceGroupOption[] = [
    {
      key: 'Active',
      text: 'Active',
      styles: { choiceFieldWrapper: { fontWeight: 800 } },
      onRenderField: (props, render) => {
        return (
          <TooltipHost content={TOOLTIP_ACTIVE_TEXT}>
            {render!(props)}
          </TooltipHost>
        );
      },
    },
    {
      key: 'Pending',
      text: 'Pending',
      styles: { choiceFieldWrapper: { fontWeight: 800 } },
      onRenderField: (props, render) => {
        return (
          <TooltipHost content={TOOLTIP_PENDING_TEXT}>
            {render!(props)}
          </TooltipHost>
        );
      },
    },
    {
      key: 'History',
      text: 'History',
      styles: { choiceFieldWrapper: { fontWeight: 800 } },
      onRenderField: (props, render) => {
        return (
          <TooltipHost content={TOOLTIP_HISTORY_TEXT}>
            {render!(props)}
          </TooltipHost>
        );
      },
    },
  ];

  const _onChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    switch (option?.key) {
      case 'Active':
        onActionPendingToggle?.(true);
        onQueuedToogle?.(false);
        onHistoryToogle?.(false);
        break;
      case 'Pending':
        onActionPendingToggle?.(false);
        onQueuedToogle?.(true);
        onHistoryToogle?.(false);
        break;
      case 'History':
        onActionPendingToggle?.(false);
        onHistoryToogle?.(true);
        onQueuedToogle?.(false);
        break;
    }
  };

  return (
    <Stack
      className={styles.actionsWrapperStyle}
      horizontalAlign="center"
      verticalAlign="center"
      horizontal
      tokens={{ childrenGap: 25 }}
    >
      <ChoiceGroup
        options={options}
        disabled={globalState}
        defaultSelectedKey={actionPendingFilter ? 'Active' : undefined}
        styles={choiceGroupStyles}
        onChange={_onChange}
      />
    </Stack>
  );
};
