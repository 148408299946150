import {
  DefaultButton,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { NoDataView } from 'common/components/DataPlaceholders';
import React, { useState } from 'react';
import { useStyles } from './index.styles';
import { ApprovalState } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  NotePendingApprovalCreate,
  NotePendingApprovalCreateVariables,
} from 'approvals/__generated__/NotePendingApprovalCreate';
import { useToasts } from 'react-toast-notifications';
import { ListItem } from './ListItem';
import {
  userApproval_userApproval,
  userApproval_userApproval_notesByEntityId_nodes,
} from '../__generated__/userApproval';
import {
  NotesUserApproval,
  NotesUserApprovalVariables,
} from './__generated__/NotesUserApproval';
import { ApprovalPanelTypes } from 'approvals/types';
import { ApprovalHistory_approvalHistory } from '../__generated__/ApprovalHistory';
const NOTE_CREATE = loader('../../NotePendingApprovalCreate.graphql');
const GET_NOTES_USER_APPROVAL = loader('./NotesUserApproval.graphql');
const GET_NOTES_APPROVAL_HISTORY = loader('./NotesApprovalHistory.graphql');
interface ApprovalNotesProps {
  availableNotes: userApproval_userApproval_notesByEntityId_nodes[];
  approvalStatus?: ApprovalState | null;
  entity:
    | userApproval_userApproval
    | ApprovalHistory_approvalHistory
    | null
    | undefined;
  listRefreshRequest?: () => void;
  approvalPanelType: ApprovalPanelTypes;
}
export const ApprovalNotes: React.FC<ApprovalNotesProps> = ({
  availableNotes,
  approvalStatus,
  entity,
  listRefreshRequest,
  approvalPanelType,
}) => {
  const [getNotesUserApproval] = useLazyQuery<
    NotesUserApproval,
    NotesUserApprovalVariables
  >(GET_NOTES_USER_APPROVAL, { fetchPolicy: 'network-only' });

  const [getNotesApprovalHistory] = useLazyQuery<
    NotesUserApproval,
    NotesUserApprovalVariables
  >(GET_NOTES_APPROVAL_HISTORY, { fetchPolicy: 'network-only' });

  return (
    <Stack tokens={{ padding: '25px 0px 25px 0px' }}>
      {approvalStatus === ApprovalState.PENDING && (
        <Stack style={{ marginBottom: 10 }}>
          <NoteForm
            entity={entity}
            availableNotes={availableNotes}
            listRefreshRequest={() => {
              if (approvalPanelType === ApprovalPanelTypes.USER_APPROVAL)
                getNotesUserApproval({ variables: { id: entity?.id! } });
              else getNotesApprovalHistory({ variables: { id: entity?.id! } });
            }}
          />
        </Stack>
      )}
      <NoDataView
        show={availableNotes.length === 0}
        title={'No notes available'}
      />
      <Stack tokens={{ childrenGap: 20 }}>
        {availableNotes?.map((item, index) => {
          return (
            <ListItem
              approvalStatus={approvalStatus}
              key={index}
              item={item}
              index={index}
              totalNotes={availableNotes.length}
              listRefreshRequest={listRefreshRequest}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
type NoteFormCreateProps = Omit<ApprovalNotesProps, 'approvalPanelType'>;
export const NoteForm: React.FC<NoteFormCreateProps> = ({
  entity,
  listRefreshRequest,
}) => {
  const [noteComment, setNoteComment] = useState<string | undefined>();
  const styles = useStyles();
  const [notesToggle, setNotesToggle] = useState(false);
  const { addToast } = useToasts();

  const [noteCreate, { loading: addingNoteInProgress }] = useMutation<
    NotePendingApprovalCreate,
    NotePendingApprovalCreateVariables
  >(NOTE_CREATE, { errorPolicy: 'all' });

  const onSave = async () => {
    const { errors } = await noteCreate({
      variables: {
        input: {
          note: {
            entityId: entity?.id!,
            noteComment: noteComment!,
            isShared: true,
          },
        },
      },
    });
    if (!errors) {
      listRefreshRequest?.();
      addToast('Note added successfully.', {
        appearance: 'success',
      });
      setNotesToggle(false);
      setNoteComment('');
    } else
      addToast(`Error while adding Notes`, {
        appearance: 'error',
      });
  };

  return (
    <>
      {notesToggle ? (
        <Stack
          className={styles.addNotesSection}
          tokens={{ childrenGap: 10, padding: '20px 30px' }}
        >
          <Stack horizontal horizontalAlign="space-between" verticalAlign="end">
            <Text className={styles.boldFont}>New Note</Text>
          </Stack>
          <TextField
            multiline
            rows={4}
            value={noteComment}
            placeholder="Write a new note..."
            resizable={false}
            onChange={(_event, value) => setNoteComment(value || '')}
          />
          {addingNoteInProgress && <ProgressIndicator label="Creating note" />}
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
            <PrimaryButton
              disabled={!noteComment}
              text="Save"
              onClick={onSave}
            />
            <DefaultButton
              text="Cancel"
              onClick={() => {
                setNotesToggle(false);
                setNoteComment('');
              }}
            />
          </Stack>
        </Stack>
      ) : (
        <Stack horizontal horizontalAlign="end">
          <DefaultButton
            iconProps={{ iconName: 'Add' }}
            text="Create Note"
            onClick={() => {
              setNotesToggle(!notesToggle);
            }}
          />
        </Stack>
      )}
    </>
  );
};
