import { useLazyQuery, useMutation } from '@apollo/client';
import { Stack } from '@fluentui/react';
import { DocumentPackageEmail } from 'common/components/DocumentPackageEmail/DocumentPackageEmail';
import { EmailCreateValues } from 'common/components/DocumentPackageEmail/DocumentPackageEmail/DocumentPackageEmailModal/FormModal/types';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { IconState } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { isTACreated } from 'travelAuthorization/TravelPlan/view';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { TravelAuthorizationValues } from 'travelAuthorization/TravelPlan/view/interface';
import { ReviseTravelPlan } from '../ReviseTravelPlan';
import { AmendApproval } from './AmendApproval';
import { PlanningDocument } from './PlanningDocument';
import { RemoveStamp } from './RemoveStamp';
import { RequestApproval } from './RequestApproval';
import {
  TravelPlanEmailStatus,
  TravelPlanEmailStatusVariables,
} from './__generated__/TravelPlanEmailStatus';
import {
  TravelPlanEmailCreate,
  TravelPlanEmailCreateVariables,
} from './__generated__/TravelPlanEmailCreate';
const TRAVEL_PLAN_EMAIL_CREATE = loader('./TravelPlanEmailCreate.graphql');
const TRAVEL_PLAN = loader('../../TravelAuthorization.graphql');
const FETCH_EMAIL_STATUS = loader('./TravelPlanEmailStatus.graphql');

interface FooterProps {
  isNew: boolean;
  isLoading: boolean;
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
  dataLoading: boolean;
  onSave: (saveAnother: boolean) => void;
  onRefetch: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  isNew,
  isLoading,
  travelAuthorizationData,
  dataLoading,
  onSave,
  onRefetch,
}) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { travelId } = useParams<{ travelId: string | undefined }>();

  const {
    formState: { isDirty, isSubmitting, isValid },
  } = useFormContext<TravelAuthorizationValues>();

  const [travelPlanEmailCreate, { loading, data }] = useMutation<
    TravelPlanEmailCreate,
    TravelPlanEmailCreateVariables
  >(TRAVEL_PLAN_EMAIL_CREATE, { errorPolicy: 'all' });

  const { id, _planEmailDocument, travelPlanEmailDocumentsByEntityId } = {
    ...travelAuthorizationData,
  };

  const [showEmailStatus, setShowEmailStatus] = useState(false);

  const [fetchEmailStatus, { stopPolling }] = useLazyQuery<
    TravelPlanEmailStatus,
    TravelPlanEmailStatusVariables
  >(FETCH_EMAIL_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    pollInterval: 60000,
    onCompleted(data) {
      if (
        data.travelAuthorization?._planEmailDocument?._sendIconState ===
        IconState.SENT
      ) {
        stopPolling?.();
        setShowEmailStatus(true);
      }
    },
  });

  const _onSubmitValues = async (values: EmailCreateValues) => {
    if (id) {
      const { errors } = await travelPlanEmailCreate({
        variables: {
          input: {
            entityId: id!,
            ...values,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: TRAVEL_PLAN,
            variables: {
              id,
            },
          },
        ],
      });
      if (!!errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Email sent successfully.', {
          appearance: 'success',
        });
        fetchEmailStatus({
          variables: {
            id: travelId!,
          },
        });
      }
    }
  };

  const isSuccessful = !!data?.travelPlanEmailCreate;

  const fetchEmailStatusMemo = useCallback(() => {
    fetchEmailStatus({
      variables: {
        id: travelId!,
      },
    });
  }, [fetchEmailStatus, travelId]);

  useEffect(() => {
    if (_planEmailDocument?._sendIconState === IconState.PENDING) {
      fetchEmailStatusMemo();
    }
  }, [_planEmailDocument, fetchEmailStatusMemo]);

  return (
    <>
      <FooterActionBar
        createNewText="New Travel Plan"
        saveButtonText="Create TA + Add Trip"
        saveNewButtonText="Save"
        addNewForm={() => {
          history.replace('/ta/travel-plan/travel');
        }}
        disabled={{
          save: !isDirty || !isValid,
          cancel: !isDirty,
        }}
        isSubmitting={isSubmitting}
        isCreate={isNew}
        onCancel={() => history.replace('/ta/travel-plan')}
        onSave={() => {
          onSave(false);
        }}
        onSaveAnother={async () => {
          onSave(true);
          isTACreated(false);
        }}
        isLoading={isLoading}
      >
        <Stack tokens={{ padding: '0px 10px 0px 0px' }}>
          <DocumentPackageEmail
            emailDocument={_planEmailDocument}
            emailDocumentsByEntityId={
              travelPlanEmailDocumentsByEntityId?.nodes || []
            }
            isSuccessful={isSuccessful}
            loading={loading}
            onSubmitValues={_onSubmitValues}
            dataLoading={dataLoading}
            showEmailStatus={showEmailStatus}
            setShowEmailStatus={setShowEmailStatus}
          />
        </Stack>
        <PlanningDocument
          isNew={isNew}
          travelAuthorizationData={travelAuthorizationData}
          onRefetch={onRefetch}
        />
        <ReviseTravelPlan travelAuthorizationData={travelAuthorizationData} />
        <RequestApproval
          isNew={isNew}
          isDirty={isDirty}
          travelAuthorizationData={travelAuthorizationData}
          onRefetch={onRefetch}
        />
        <AmendApproval
          isNew={isNew}
          isDirty={isDirty}
          travelAuthorizationData={travelAuthorizationData}
          onRefetch={onRefetch}
        />
        <RemoveStamp
          travelAuthorizationData={travelAuthorizationData}
          onRefetch={onRefetch}
        />
      </FooterActionBar>
    </>
  );
};
