import { useQuery, useReactiveVar } from '@apollo/client';
import { Checkbox, IDropdownOption, Stack, Toggle } from '@fluentui/react';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import {
  ColumnData,
  SearchBar,
  SearchBarProps,
} from 'common/components/SearchBar';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { TravelerAuthorizationFilterData } from './__generated__/TravelerAuthorizationFilterData';
import { useStyles } from './index.styles';
import { setTAListViewOptions } from '..';
import { globalMode } from 'utility/cache/ui';
const TRAVEL_AUTHORIZATION_FILTER_DATA = loader(
  './TravelerAuthorizationFilterData.graphql'
);

interface TASearchBarProps extends SearchBarProps {
  onHintsViewToggle?: () => void;
  columns: ColumnData[];
  onFilterChange: (filterArray: FilterArrayType[]) => void;
  onToggleVisibility?: (columns: ColumnData[]) => void;
}

export const TASearchBar: React.FC<TASearchBarProps> = ({
  columns,
  onFilterChange,
  onEnterPress,
  hintsAvailable,
  onRefresh,
  searchInputPlaceholderText,
  onToggleVisibility,
  onHintsViewToggle,
  urlParams,
  onGlobalToggle,
}) => {
  const styles = useStyles();
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const TAListViewOptions = useReactiveVar(setTAListViewOptions);
  const globalState = useReactiveVar(globalMode);

  const { data: travelerAuthorizationFiltersData } =
    useQuery<TravelerAuthorizationFilterData>(TRAVEL_AUTHORIZATION_FILTER_DATA);

  const departmentOptions = formatDropdownOptions(
    travelerAuthorizationFiltersData?.companyDepartments?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: true,
    }
  );

  const businessUnitOptions = formatDropdownOptions(
    travelerAuthorizationFiltersData?.companyBusinessUnits?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: true,
    }
  );
  const workgroupOptions = formatDropdownOptions(
    travelerAuthorizationFiltersData?.availableTripWorkgroups?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: true,
    }
  );
  const originOptions: IDropdownOption[] = [
    { key: 1, text: 'Corporate' },
    { key: 0, text: 'Production' },
    { key: -1, text: 'All' },
  ];

  const currencyOptions = formatDropdownOptions(
    travelerAuthorizationFiltersData?.companyCurrencies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: true,
    }
  );

  const statusOptions = formatDropdownOptions(
    travelerAuthorizationFiltersData?.travelAuthorizationStatusTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.statusType || '',
      includeAll: true,
    }
  );

  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    const newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    onFilterChange?.(newFilters);
    setFilterTypes(newFilters);
  };

  return (
    <SearchBar
      urlParams={urlParams}
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange?.([]);
        setFilterTypes([]);
      }}
      columns={columns}
      searchInputPlaceholderText={searchInputPlaceholderText}
      onEnterPress={onEnterPress}
      onRefresh={onRefresh}
      onGlobalToggle={onGlobalToggle}
      hintsAvailable={hintsAvailable}
      onHintsViewToggle={onHintsViewToggle}
      onToggleVisibility={onToggleVisibility}
      renderActions={
        <Stack
          className={styles.actionsWrapperStyle}
          horizontalAlign="center"
          verticalAlign="center"
          horizontal
          tokens={{ childrenGap: 25 }}
        >
          <Toggle
            label="Signing"
            inlineLabel
            onText="Accounting Entry"
            offText="Accounting Entry"
            disabled={globalState}
            onChange={(event, checked) => {
              if (event)
                setTAListViewOptions({
                  ...TAListViewOptions,
                  isSigning: !checked,
                  isHistory:
                    TAListViewOptions.isHistory && !checked
                      ? false
                      : TAListViewOptions.isHistory,
                });
            }}
            checked={!TAListViewOptions.isSigning}
            className={styles.signingAccountingToggle}
            styles={{
              label: {
                wordBreak: 'keep-all',
              },
            }}
          />

          {!TAListViewOptions.isSigning && (
            <Checkbox
              label="History"
              checked={TAListViewOptions.isHistory}
              onChange={(event, checked) => {
                if (event)
                  setTAListViewOptions({
                    ...TAListViewOptions,
                    isHistory: !!checked,
                  });
              }}
            />
          )}
        </Stack>
      }
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20, padding: '0px 0px 0px 20px' }}
      >
        <DropdownFilter
          filterKey={'departmentId'}
          options={departmentOptions || []}
          placeholder={'Department'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'businessUnitId'}
          options={businessUnitOptions || []}
          placeholder={'Business Unit'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'_isCorporateTravelAuthorization'}
          options={originOptions || []}
          placeholder={'Origins'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'corporateWorkgroupId'}
          options={workgroupOptions || []}
          placeholder={'Workgroup'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'currencyId'}
          options={currencyOptions || []}
          placeholder={'Currency'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="_statusTypeId"
          options={statusOptions}
          placeholder="Status"
          onOptionChange={onOptionChange}
        />
      </Stack>
    </SearchBar>
  );
};
