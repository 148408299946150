import { CheckboxFilter as CheckboxFilterColumn } from './CheckboxFilter';
import { DropdownFilter as DropdownFilterColoumn } from './DropdownFilter';
import { ComboboxFilter as ComboboxFilterColoumn } from './ComboboxFilter';

export {
  CheckboxFilterColumn as CheckboxFilter,
  DropdownFilterColoumn as DropdownFilter,
  ComboboxFilterColoumn as ComboboxFilter,
};

export enum filterCombineType {
  OR = 'OR',
  AND = 'AND',
  NOT = 'AND',
}
export type FilterArrayType = {
  value: string | number | boolean;
  label: string;
  filterKey: string;
  combineType?:
    | filterCombineType.AND
    | filterCombineType.NOT
    | filterCombineType.OR;
};
