import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  modalInsideStack: {
    width: 1250,
    height: 700,
  },
  stickyInsideStack: {
    alignItems: 'self-start',
    padding: 25,
  },
  documentTypeDropdown: {
    width: 250,
  },
  dropdownWrapper: {
    marginLeft: 25,
    marginBottom: 20,
  },
  footerStack: {
    paddingRight: 25,
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },

  onrenderColumnStack: {
    height: 25,
  },
  columnHeight: {
    height: 30,
  },
}));
