import {
  NetworkStatus,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { BlockBlobClient } from '@azure/storage-blob';
import {
  IColumn,
  Icon,
  IconButton,
  ScrollablePane,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityType } from 'common/types/utility';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { DownloadButton } from 'common/components/DownloadButton';
import { HighLightActiveLink } from 'common/components/HighLight';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { SecureRowLevel } from 'common/components/SecureRowLevel';
import { DISABLED_FILE_FORMATS, TABLE_ROWS } from 'common/constants';
import { CompanyCurrencies } from 'common/graphql/__generated__/CompanyCurrencies';
import {
  DocumentPoolFiles,
  DocumentPoolFilesVariables,
  DocumentPoolFiles_documentPools_nodes,
} from 'common/graphql/__generated__/DocumentPoolFiles';
import {
  EntityDocumentDelete,
  EntityDocumentDeleteVariables,
} from 'common/graphql/__generated__/EntityDocumentDelete';
import {
  OnDocumentUploadStatus,
  OnDocumentUploadStatus_documentUploadStatus_document,
} from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { useCommonStyles } from 'common/styles';
import {
  DocumentPoolsOrderBy,
  EntityDeleteInput,
  EntityDocumentFilter,
  EntityDocumentsOrderBy,
  UploadStatusType,
} from 'common/types/globalTypes';
import { formatDropdownOptions } from 'common/utils';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection } from 'common/utils/commonTypes';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
  invoiceDateFormat,
} from 'common/utils/dateFormats';
import { fileType, fileTypeColor } from 'common/utils/fileType';
import { Tabs } from 'documents/documentAssignment/Tabs';
import { historyFilterObject } from 'documents/Filters/ListFilterCallout';
import { MoveToFolder } from 'documents/MoveToFolder';
import { DocumentTypeOptions } from 'documents/personalPool/UploadDocumentsForm';
import {
  GetDocumentPoolCommonData,
  GetDocumentPoolCommonDataVariables,
} from 'documents/__generated__/GetDocumentPoolCommonData';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { setGlobalSearchText, setUserDefaults } from 'utility/cache/ui';
import { DocumentAssignmentLocationState } from 'utility/locationStates';
import {
  DocumentPoolUploadDocument,
  DocumentPoolUploadDocumentVariables,
} from '../__generated__/DocumentPoolUploadDocument';
import { columns } from './column.data';
import { filterOptionProps, GroupHeader } from './GroupHeader';
import { useStyles } from './index.styles';
import { DocumentLoaderShimmer } from './Shimmer';
import { UploadDocumentForm } from './UploadForm';
import {
  getFiltersFromState,
  toFilterOrVariable,
  toFilterVariable,
  toOrderByVariable,
} from './utils';
import {
  DocumentPoolDocuments,
  DocumentPoolDocumentsVariables,
  DocumentPoolDocuments_documentPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes,
} from './__generated__/DocumentPoolDocuments';
import {
  DocumentRowProtection,
  DocumentRowProtectionVariables,
} from './__generated__/DocumentRowProtection';
import { BuildTransaction } from 'documents/documentAssignment/BuildTransactions';

const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);

const DOCUMENT_POOL_DATA = loader(
  '../../../../common/graphql/DocumentPool.graphql'
);
const UPLOAD_DOCUMENT = loader('../DocumentPoolUploadDocument.graphql');
const DOCUMENT_DELETE = loader(
  '../../../../common/graphql/EntityDocumentDelete.graphql'
);

const GENERAL_DATA = loader(
  '../../../../documents/GetDocumentPoolCommonData.graphql'
);

const UPDATE_DOCUMENT_SECURITY = loader('./EntityDocumentProtection.graphql');
const CURRENCY_DATA = loader(
  '../../../../common/graphql/CompanyCurrencies.graphql'
);
const DOCUMENT_POOL_DOCUMENTS = loader('./DocumentPoolDocuments.graphql');

type DocumentPoolItemType = DocumentPoolFiles_documentPools_nodes;
type DocumentListItemType =
  DocumentPoolDocuments_documentPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes;

export type DocumentEntity =
  DocumentPoolDocuments_documentPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes & {
    documentType: string;
    usedTotal: string;
    remainingTotal: string;
    isoCode: string;
    _uploadDate: string;
  };

// DOCUMENT LOADER
export const FolderList: React.FC = () => {
  let documentListData: DocumentEntity[] = [];

  const history = useHistory<DocumentAssignmentLocationState | undefined>();
  const scrollToRef = useRef<HTMLDivElement>(null);
  const stickyHeaderRef = useRef<Sticky>(null);

  const { addToast, updateToast } = useToasts();
  const commonStyles = useCommonStyles();
  const [documentOptions, setDocumentOptions] = useState<DocumentTypeOptions[]>(
    []
  );
  const globalSearchText = useReactiveVar(setGlobalSearchText);
  const client = useApolloClient();
  const [isHistory, setIsHistory] = useState(false);
  const [defaultDocumentTypeId, setDefaultDocumentTypeId] = useState<number>();
  const [extractionTypeId, setExtractionTypeId] = useState<number | null>(null);
  const [isDocumentTypeSelector, setIsDocumentTypeSelector] =
    useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] =
    useState<DocumentListItemType | null>(null);
  const [selectedPoolId, setSelectedPoolId] = useState<string | undefined>();
  const [hideProtectionDialog, { toggle: toggleProtectionDialog }] =
    useBoolean(false);
  const [hideUploadDialog, { toggle: toggleUploadDialog }] = useBoolean(false);
  const [selectedList, setSelectedList] = useState<DocumentEntity[]>([]);
  const { cache } = useApolloClient();
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });

  const [
    getEntityDocuments,
    {
      data: entityDocumentsData,
      loading: entityDocumentsLoading,
      variables: entityDocumentVariables,
      networkStatus,
      fetchMore,
    },
  ] = useLazyQuery<DocumentPoolDocuments, DocumentPoolDocumentsVariables>(
    DOCUMENT_POOL_DOCUMENTS,
    {
      variables: {
        id: selectedPoolId!,
        first: TABLE_ROWS,
        orderBy: [EntityDocumentsOrderBy._UPLOAD_DATE_DESC],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const { nodes, totalCount, pageInfo } = {
    ...entityDocumentsData?.documentPool
      ?.entityDocumentsByDocumentPoolIdAndEntityTypeId,
  };

  const { loading: DocumentFilesLoading, data: DocumentFilesData } = useQuery<
    DocumentPoolFiles,
    DocumentPoolFilesVariables
  >(DOCUMENT_POOL_DATA, {
    variables: {
      orderBy: [DocumentPoolsOrderBy.NAME_ASC],
      filter: {
        defaultDocumentTypes: {
          isReportingDocument: {
            equalTo: false,
          },
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (
      DocumentFilesData &&
      scrollToRef.current &&
      history.location.state?.poolId &&
      stickyHeaderRef.current?.root
    ) {
      // [ref].scrollIntoView is broken in Chrome when using smooth behavior
      scrollToRef.current.offsetParent?.scrollTo({
        top:
          scrollToRef.current.offsetTop -
          stickyHeaderRef.current.root?.getBoundingClientRect().height,
        behavior: 'smooth',
      });

      _headerOnClick(
        history.location.state.poolId,
        history.location.state.filter
      );

      const documentPool = DocumentFilesData?.documentPools?.nodes.find(
        (item) => item.id === history?.location?.state?.poolId
      );

      if (documentPool?.defaultDocumentTypeId) {
        if (documentPool.isDocumentTypeSelector) {
          setDocumentOptions(filterDocumentOptions(documentPool) || []);
        } else setDocumentOptions(withoutFilterDocumentOptions);
      } else {
        setDocumentOptions(withoutFilterDocumentOptions);
      }
      setDefaultDocumentTypeId(documentPool?.defaultDocumentTypeId!);
      setIsDocumentTypeSelector(documentPool?.isDocumentTypeSelector!);
      setExtractionTypeId(documentPool?.extractionTypeId!);

      // setDefaultDocumentTypeId(documentPool?.defaultDocumentTypeId!);
      // we only want to use location state for initial scroll after data load, not any
      // subsequent renders, so this clears it
      history.replace(history.location.pathname, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DocumentFilesData]);

  const [uploadDocument] = useMutation<
    DocumentPoolUploadDocument,
    DocumentPoolUploadDocumentVariables
  >(UPLOAD_DOCUMENT);

  const { data: GeneralData } = useQuery<
    GetDocumentPoolCommonData,
    GetDocumentPoolCommonDataVariables
  >(GENERAL_DATA, {
    variables: {
      isDocumentUpload: true,
    },
  });
  const { data: currencyData } = useQuery<CompanyCurrencies>(CURRENCY_DATA);
  const [filterOptions, setFilterOptions] = useState<
    filterOptionProps | undefined
  >({ filterTypes: [historyFilterObject], startsWith: true });
  const [
    updateDocumentSecurity,
    { loading: updateSecurityLoading, error: UpdateSecurityError },
  ] = useMutation<DocumentRowProtection, DocumentRowProtectionVariables>(
    UPDATE_DOCUMENT_SECURITY,
    { errorPolicy: 'all' }
  );

  const [deleteDocuments] = useMutation<
    EntityDocumentDelete,
    EntityDocumentDeleteVariables
  >(DOCUMENT_DELETE, { errorPolicy: 'all' });

  const styles = useStyles();
  const [gridColumns, setGridColumns] = useState<IColumn[]>(columns);

  // HEADER ON CLICK
  const _headerOnClick = (
    poolId: string,
    filterRouteState?: string | undefined
  ) => {
    const { andFilters, orFilters } = getFiltersFromState(filterRouteState!);

    setFilterOptions({
      filterTypes: [...andFilters, ...orFilters],
      startsWith: true,
    });

    const filtersApplied: EntityDocumentFilter | undefined =
      andFilters?.length || orFilters?.length
        ? ({
            and: toFilterVariable(andFilters, 'startsWithInsensitive'),
            or: toFilterOrVariable(orFilters),
          } as EntityDocumentFilter)
        : undefined;
    if (selectedPoolId !== poolId) {
      getEntityDocuments({
        variables: {
          ...entityDocumentVariables,
          id: poolId,
          filter: {
            and: [{ _isSelected: { equalTo: false } }],
            ...filtersApplied,
          },
        },
      });
    }
    setSelectedPoolId((prevState) => {
      return prevState === poolId ? undefined : poolId;
    });
  };

  const currencyTypes = formatDropdownOptions(
    currencyData?.companyCurrencies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.isoCode + '-' + item.name!,
      includeAll: false,
    }
  );

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  const transformedData = refetching
    ? undefined
    : nodes
        ?.filter((item) => (!isHistory ? !item._isSelected : true))
        .map(
          (doc) =>
            ({
              ...doc,
              documentType: doc.documentTypes?.documentType,
              usedTotal: doc?.documentAppliedAmounts?.usedTotal,
              remainingTotal: doc?.documentAppliedAmounts?.remainingTotal,
              isoCode: doc?.currency?.isoCode,
              _uploadDate: getGlobalDateFormat(doc._uploadDate || ''),
              indexTransactionDate: doc.indexTransactionDate
                ? dateFormat(dateConvertions(doc.indexTransactionDate))
                : null,
            } as DocumentEntity)
        );
  if (transformedData) documentListData = transformedData;

  const documentPoolIdRef = useRef('');
  const updateDocumentPoolIdRef = (newItems: string) => {
    documentPoolIdRef.current = newItems;
  };

  const _onColumnClick = (clickedColumn: ColumnData): void => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    getEntityDocuments({
      variables: {
        ...entityDocumentVariables,
        id: documentPoolIdRef.current,
        orderBy: toOrderByVariable({
          column: clickedColumn.key,
          direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
        }),
      },
    });
  };

  const filterDocumentOptions = (
    documentPoolItem: DocumentPoolFiles_documentPools_nodes
  ) => {
    const dataArr = GeneralData?.documentPoolAvailableDocumentTypes?.nodes
      .filter(
        (defaultDocumentTypeId) =>
          defaultDocumentTypeId.id === documentPoolItem.defaultDocumentTypeId
      )
      .map((item) => ({
        key: item.id,
        text: item?.documentType! || '',
        isAccountingDocument: item?.isAccountingDocument!,
        isSigningRequired: item.isSigningRequired,
        extractionTypes: item.extractionTypes,
      }));

    return dataArr;
  };

  const withoutFilterDocumentOptions = () => {
    const documentTypes =
      GeneralData?.documentPoolAvailableDocumentTypes?.nodes.map((item) => ({
        key: item.id,
        text: item?.documentType! || '',
        isAccountingDocument: item?.isAccountingDocument!,
        isSigningRequired: item.isSigningRequired,
        extractionTypes: item.extractionTypes,
      })) || [];
    return documentTypes;
  };

  //Message Props
  // let selectedList: any[] = selection.getSelection();

  const refreshDocumentListOnDelete = (deletedRowsCount: number) => {
    const poolsCacheData = cache.readQuery<
      DocumentPoolFiles,
      DocumentPoolFilesVariables
    >({
      query: DOCUMENT_POOL_DATA,
    });
    if (poolsCacheData) {
      const poolData = poolsCacheData?.documentPools?.nodes.map((pool) => {
        if (pool.id === selectedPoolId) {
          const newPoolData: DocumentPoolFiles_documentPools_nodes = {
            ...pool,
            _documentFileCount: {
              ...pool._documentFileCount!,
              selectedDocuments:
                pool._documentFileCount?.selectedDocuments || 0,
              availableDocuments:
                (pool._documentFileCount?.availableDocuments || 0) -
                deletedRowsCount,
            },
          };
          return newPoolData;
        }
        return pool;
      });
      if (poolData) {
        const updatedPools: DocumentPoolFiles = {
          ...poolsCacheData,
          documentPools: {
            ...poolsCacheData?.documentPools!,
            nodes: [...poolData!],
          },
        };
        cache.writeQuery<DocumentPoolFiles, DocumentPoolFilesVariables>({
          query: DOCUMENT_POOL_DATA,
          data: updatedPools,
        });
      }
    }
    setSelectedList([]);
    addToast('Record deleted Successfully', {
      appearance: 'success',
    });
  };

  const _onConfirm = async () => {
    const deletedRows: DocumentEntity[] = selectedList.filter(
      (item) => item._isDeletable
    );
    const selectedData: EntityDeleteInput[] = selectedList
      ?.filter((entity) => entity._isDeletable)
      .map((obj) => {
        return { id: obj.id, rowTimestamp: obj._rowTimestamp! };
      });

    const { errors } = await deleteDocuments({
      variables: {
        input: {
          entityDelete: selectedData,
        },
      },
      update(cache, { data: response }) {
        if (
          response?.entityDocumentDelete?.deletedEntities &&
          response?.entityDocumentDelete?.deletedEntities?.length > 0
        ) {
          deletedRows.forEach((item) => {
            const identity = cache.identify({
              ...item,
            });
            cache.evict({ id: identity });
            cache.gc();
          });
        }
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      refreshDocumentListOnDelete(deletedRows.length);
    }
  };

  const _renderItemColumn = (
    item: DocumentEntity | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof DocumentEntity
      ] as string;
      const viewDocumentVisible =
        item._isProtected! || DISABLED_FILE_FORMATS.includes(item._fileType!);
      switch (column?.key) {
        case 'documentType':
          return (
            <HighLightActiveLink
              highlightText={globalSearchText}
              text={item?.documentType}
              to={`/doc/documentAssignment/document/${item.id}/${selectedPoolId}`}
              onClick={() => {
                history.push(
                  `/doc/documentAssignment/document/${item.id}/${selectedPoolId}`
                );
                setDocViewState({ isOpen: false, _fileType: 'pdf' });
              }}
            />
          );
        case '_uploadDate':
          return (
            <Stack
              className={styles.onrenderColumnStack}
              verticalAlign="center"
            >
              <Text className={styles.contentColumnAlignRight}>
                {getGlobalDateFormat(item._uploadDate || '')}
              </Text>
            </Stack>
          );
        case 'usedTotal':
        case 'indexAmount':
        case 'remainingTotal':
          return (
            <Stack
              verticalAlign="center"
              className={clsx(styles.textAlignEnd, styles.onrenderColumnStack)}
            >
              <AmountTextView value={fieldContent} />
            </Stack>
          );
        case 'fileReference':
          return (
            <Stack
              verticalAlign={'center'}
              horizontal
              className={styles.onrenderColumnStack}
              tokens={{ childrenGap: 10 }}
            >
              <Icon
                className={fileTypeColor(item.iconType || 'OTHER')}
                iconName={fileType(item.iconType || 'OTHER')}
              />
              <Text>{fieldContent}</Text>
            </Stack>
          );
        case 'view':
          return (
            <Stack
              className={styles.columnHeight}
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );
        case 'download':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
              horizontalAlign={'center'}
            >
              <DownloadButton entityDocumentId={item.id} />
            </Stack>
          );
        // case 'actions':
        //   return (
        //     <Stack
        //       horizontal
        //       verticalAlign="center"
        //       className={styles.onrenderColumnStack}
        //       horizontalAlign={'center'}
        //       tokens={{ childrenGap: 10 }}
        //     >
        //       <ProtectButton
        //         isProtected={item._isProtected}
        //         onPress={() => {
        //           setSelectedDocument(item);
        //           toggleProtectionDialog();
        //         }}
        //       />
        //       {/* <IconButton
        //         onClick={() => {
        //           setSelectedDocument(item);
        //           toggleUploadDialog();
        //         }}
        //         iconProps={{ iconName: 'Edit' }}
        //         ariaLabel="Edit"
        //         className={styles.documentActionConatiner}
        //       /> */}
        //     </Stack>
        //   );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              {fieldContent && <Text>{fieldContent}</Text>}
            </Stack>
          );
      }
    }
  };

  useEffect(() => {
    const historyPos = filterOptions?.filterTypes?.findIndex(
      (item) => item.filterKey === '_isSelected'
    );
    if (historyPos !== -1) {
      setIsHistory(
        filterOptions?.filterTypes[historyPos!].value.toString() === 'true'
      );
    }
  }, [filterOptions]);

  const onFilterChange = (
    filterOptions: filterOptionProps,
    documentPool: DocumentPoolItemType
  ) => {
    const { filterTypes: filters, startsWith } = {
      ...filterOptions,
    };

    const historyPos = filterOptions?.filterTypes?.findIndex(
      (item) => item.filterKey === '_isSelected'
    );

    const searchOption = startsWith
      ? 'startsWithInsensitive'
      : 'includesInsensitive';

    const filtersApplied =
      filters?.length > 0
        ? ({
            and: toFilterVariable(filters, searchOption),
            or: toFilterOrVariable(filters),
          } as EntityDocumentFilter)
        : undefined;

    setFilterOptions(filterOptions);
    getEntityDocuments({
      variables: {
        ...entityDocumentVariables,
        id: selectedPoolId!,
        filter: {
          ...filtersApplied,
        },
      },
    });

    setIsHistory(filters[historyPos].value === true);
  };

  const loadMore = useCallback(async () => {
    const newVariables: DocumentPoolDocumentsVariables = {
      ...entityDocumentVariables,
      id: selectedPoolId!,
      after: pageInfo?.endCursor,
    };
    await fetchMore?.({
      variables: { ...newVariables },
    });
  }, [fetchMore, entityDocumentVariables, pageInfo, selectedPoolId]);

  const documentsListheight = documentListData.length * 35 + 70;
  const sectionHeight =
    documentsListheight > 400
      ? 400
      : documentListData.length === 0
      ? 150
      : documentsListheight;

  const refreshDocumentList = (
    document: OnDocumentUploadStatus_documentUploadStatus_document
  ) => {
    if (selectedPoolId) {
      const entityCacheData = cache.readQuery<
        DocumentPoolDocuments,
        DocumentPoolDocumentsVariables
      >({
        query: DOCUMENT_POOL_DOCUMENTS,
        variables: {
          ...entityDocumentVariables,
          id: selectedPoolId,
        },
      });
      if (entityCacheData?.documentPool) {
        const entityNewData: DocumentPoolDocuments = {
          documentPool: {
            ...entityCacheData?.documentPool,
            id: selectedPoolId,
            entityDocumentsByDocumentPoolIdAndEntityTypeId: {
              ...entityCacheData?.documentPool
                ?.entityDocumentsByDocumentPoolIdAndEntityTypeId,
              totalCount:
                entityCacheData?.documentPool
                  ?.entityDocumentsByDocumentPoolIdAndEntityTypeId?.totalCount +
                1,
              nodes: [
                { ...document },
                ...entityCacheData?.documentPool
                  ?.entityDocumentsByDocumentPoolIdAndEntityTypeId.nodes,
              ],
            },
          },
        };
        cache.writeQuery<DocumentPoolDocuments, DocumentPoolDocumentsVariables>(
          {
            query: DOCUMENT_POOL_DOCUMENTS,
            data: entityNewData,
            variables: {
              ...entityDocumentVariables,
              id: selectedPoolId,
            },
          }
        );
        const poolsCacheData = cache.readQuery<
          DocumentPoolFiles,
          DocumentPoolFilesVariables
        >({
          query: DOCUMENT_POOL_DATA,
        });
        if (poolsCacheData) {
          const poolData = poolsCacheData?.documentPools?.nodes.map((pool) => {
            if (pool.id === selectedPoolId) {
              const newPoolData: DocumentPoolFiles_documentPools_nodes = {
                ...pool,
                _documentFileCount: {
                  ...pool._documentFileCount!,
                  selectedDocuments:
                    pool._documentFileCount?.selectedDocuments || 0,
                  availableDocuments:
                    (pool._documentFileCount?.availableDocuments || 0) + 1,
                },
              };
              return newPoolData;
            }
            return pool;
          });
          if (poolData) {
            const updatedPools: DocumentPoolFiles = {
              ...poolsCacheData,
              documentPools: {
                ...poolsCacheData?.documentPools!,
                nodes: [...poolData!],
              },
            };
            cache.writeQuery<DocumentPoolFiles, DocumentPoolFilesVariables>({
              query: DOCUMENT_POOL_DATA,
              data: updatedPools,
            });
          }
        }
      }
    }
  };

  const documentTypesOptions = formatDropdownOptions(
    GeneralData?.documentTypeUploads?.nodes,
    {
      getKey: (item) => item.id!,
      getText: (item) => item.documentType || '',
      includeAll: false,
    }
  );

  return (
    <ScrollablePane>
      <Stack className={styles.container}>
        <Sticky
          stickyPosition={StickyPositionType.Header}
          ref={stickyHeaderRef}
        >
          <Stack
            tokens={{ childrenGap: 20 }}
            className={commonStyles.listHeaderContainer}
          >
            <Stack
              horizontal
              horizontalAlign="space-between"
              verticalAlign="center"
              className={commonStyles.listTitleContainer}
            >
              <Text variant="xLarge">Folders</Text>
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 10 }}
              >
                {selectedList.length > 0 && (
                  <ActionMessageModal
                    entityType={EntityType.Document}
                    disabled={
                      !selectedList.some((selected) => selected._isDeletable)
                    }
                    visible={selectedList.length > 0}
                    multiple={{
                      validCount: selectedList.filter(
                        (selected) => selected._isDeletable
                      ).length,
                      invalidNames: selectedList
                        .filter((selected) => !selected._isDeletable)
                        .map((cannotDelete) => cannotDelete.fileReference),
                    }}
                    onConfirm={_onConfirm}
                  />
                )}

                {selectedList.length > 0 && (
                  <MoveToFolder
                    poolSelectedID={selectedPoolId}
                    documentsSelected={selectedList}
                    onSuccess={(poolMovedToId, filesMovedCount) => {
                      const cacheData = cache.readQuery<
                        DocumentPoolDocuments,
                        DocumentPoolDocumentsVariables
                      >({
                        query: DOCUMENT_POOL_DOCUMENTS,
                        variables: entityDocumentVariables,
                      });

                      const poolsCacheData = cache.readQuery<
                        DocumentPoolFiles,
                        DocumentPoolFilesVariables
                      >({
                        query: DOCUMENT_POOL_DATA,
                      });

                      const filteredList =
                        cacheData?.documentPool?.entityDocumentsByDocumentPoolIdAndEntityTypeId?.nodes.filter(
                          (ele: any) =>
                            selectedList.findIndex(
                              (item) => item.id === ele.id
                            ) === -1
                        );

                      const updatedEntityDocuments: DocumentPoolDocuments = {
                        documentPool: {
                          ...entityDocumentsData?.documentPool,
                          id: documentPoolIdRef.current,
                          entityDocumentsByDocumentPoolIdAndEntityTypeId: {
                            ...entityDocumentsData?.documentPool
                              ?.entityDocumentsByDocumentPoolIdAndEntityTypeId,
                            totalCount: totalCount! - selectedList.length,
                            nodes: filteredList!,
                            pageInfo: { ...pageInfo! },
                          },
                        },
                      };
                      const poolData = poolsCacheData?.documentPools?.nodes.map(
                        (pool) => {
                          if (pool.id === poolMovedToId) {
                            const newPoolData: DocumentPoolFiles_documentPools_nodes =
                              {
                                ...pool,
                                _documentFileCount: {
                                  ...pool._documentFileCount!,
                                  availableDocuments:
                                    pool._documentFileCount
                                      ?.availableDocuments! + filesMovedCount,
                                },
                              };
                            return newPoolData;
                          }
                          if (pool.id === selectedPoolId) {
                            const newPoolData: DocumentPoolFiles_documentPools_nodes =
                              {
                                ...pool,
                                _documentFileCount: {
                                  ...pool._documentFileCount!,
                                  availableDocuments:
                                    pool._documentFileCount
                                      ?.availableDocuments! - filesMovedCount,
                                },
                              };
                            return newPoolData;
                          }
                          return pool;
                        }
                      );

                      const updatedPools: DocumentPoolFiles = {
                        ...poolsCacheData,
                        documentPools: {
                          ...poolsCacheData?.documentPools!,
                          nodes: [...poolData!],
                        },
                      };
                      cache.writeQuery<
                        DocumentPoolFiles,
                        DocumentPoolFilesVariables
                      >({
                        query: DOCUMENT_POOL_DATA,
                        data: updatedPools,
                      });

                      cache.writeQuery<
                        DocumentPoolDocuments,
                        DocumentPoolDocumentsVariables
                      >({
                        query: DOCUMENT_POOL_DOCUMENTS,
                        variables: entityDocumentVariables,
                        data: updatedEntityDocuments,
                      });
                    }}
                  />
                )}

                <BuildTransaction
                  allowMultiFileBundleToggle
                  companyBusinessUnits={GeneralData?.companyBusinessUnits}
                  companyDepartments={GeneralData?.companyDepartments}
                  selectedList={selectedList}
                  onBuildComplete={() => {
                    selectedList.forEach((item) => {
                      cache.evict({ id: `EntityDocument:${item.id}` });
                    });

                    cache.gc();

                    const nodesLength = selectedList.length;
                    cache.modify({
                      id: `DocumentPool:${selectedPoolId}`,
                      fields: {
                        _documentFileCount: (existingData) => {
                          return {
                            ...existingData,
                            selectedDocuments:
                              existingData.selectedDocuments + nodesLength,
                            availableDocuments:
                              existingData.availableDocuments - nodesLength,
                          };
                        },
                      },
                    });
                  }}
                  onCloseDocumentView={() =>
                    setDocViewState({ isOpen: false, _fileType: 'pdf' })
                  }
                />
              </Stack>
            </Stack>
            <Stack tokens={{ childrenGap: 10 }}>
              <Stack
                horizontal
                verticalAlign="end"
                horizontalAlign="space-between"
              >
                <Tabs />
              </Stack>
            </Stack>
          </Stack>
        </Sticky>

        {hideUploadDialog && (
          <UploadDocumentForm
            documentOptions={documentOptions}
            companyCurrencies={currencyTypes}
            documentData={selectedDocument || null}
            defaultDocumentTypeId={defaultDocumentTypeId}
            isDocumentTypeSelector={isDocumentTypeSelector}
            extractionTypeId={extractionTypeId}
            visible={hideUploadDialog}
            onDismiss={toggleUploadDialog}
            uploadDocument={{
              uploadDocumentData: (
                documentType,
                fileSelected,
                content,
                indexName,
                indexDescription,
                indexReferenceNumber,
                indexTransactionDate,
                indexAmount,
                indexCurrencyId,
                comment,
                extractionTypeId
              ) => {
                fileSelected.map(async (fileEntity, fileIndex) => {
                  const toastId = `file.name.${fileEntity?.name}.${fileIndex}`;
                  addToast(`Uploading ${fileEntity?.name}...`, {
                    appearance: 'info',
                    id: toastId,
                    autoDismiss: false,
                  });
                  const uploadMutationResults = await uploadDocument({
                    variables: {
                      document: {
                        documentTypeId: parseInt(documentType.key.toString()),
                        description: content ? content : '',
                        comment: comment,
                        filename: fileEntity.name,
                        indexName: indexName ? indexName : '',
                        indexDescription: indexDescription
                          ? indexDescription
                          : '',
                        indexReferenceNumber: indexReferenceNumber
                          ? indexReferenceNumber
                          : '',
                        indexTransactionDate: indexTransactionDate
                          ? invoiceDateFormat(indexTransactionDate)
                          : null,
                        indexAmount: indexAmount ? indexAmount : null,
                        indexCurrencyId: indexCurrencyId
                          ? indexCurrencyId
                          : null,
                        extractionTypeId: extractionTypeId,
                      },
                      documentPoolId: selectedPoolId!,
                    },
                  });

                  if (uploadMutationResults.errors)
                    updateToast(toastId!, {
                      content: `Upload of ${fileEntity.name} failed`,
                      appearance: 'error',
                      autoDismiss: true,
                    });

                  if (
                    uploadMutationResults.data?.documentPoolUploadDocument
                      ?.document &&
                    uploadMutationResults.data.documentPoolUploadDocument
                      .document._documentFileId
                  ) {
                    const observer = client.subscribe({
                      query: DOCUMENT_UPLOAD_STATUS,
                      variables: {
                        documentId:
                          uploadMutationResults.data.documentPoolUploadDocument
                            .document._documentFileId,
                      },
                    });

                    const subscription = observer.subscribe((data) => {
                      const subscribedData =
                        data.data as OnDocumentUploadStatus;

                      const { status, document } = {
                        ...subscribedData.documentUploadStatus,
                      };

                      if (status.type === UploadStatusType.VALIDATING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Validating files ${fileEntity.name} - ${status.message}`
                            : `Validating files ${fileEntity.name}`,
                          appearance: 'info',
                          autoDismiss: false,
                        });
                      } else if (status.type === UploadStatusType.EXTRACTING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Extracting data from ${fileEntity.name} - ${status.message}`
                            : `Extracting data from ${fileEntity.name}`,
                          appearance: 'info',
                          autoDismiss: false,
                        });
                      } else if (status.type === UploadStatusType.FAILURE) {
                        subscription.unsubscribe();
                        updateToast(toastId!, {
                          content: status.message
                            ? `Upload of ${fileEntity.name} failed - ${status.message}`
                            : `Upload of ${fileEntity.name} failed`,
                          appearance: 'error',
                          autoDismiss: true,
                        });
                      } else if (status.type === UploadStatusType.WARNING) {
                        subscription.unsubscribe();
                        updateToast(toastId!, {
                          content: status.message
                            ? `Warning for file ${fileEntity.name}: ${status.message}`
                            : `Warning for file ${fileEntity.name}`,
                          appearance: 'warning',
                          autoDismiss: true,
                        });
                        if (document) refreshDocumentList(document);
                      } else {
                        subscription.unsubscribe();
                        updateToast(toastId!, {
                          content: status.message
                            ? `Successfully uploaded ${fileEntity.name}: ${status.message}`
                            : `Successfully uploaded ${fileEntity.name}`,
                          appearance: 'success',
                          autoDismiss: true,
                        });
                        if (document) refreshDocumentList(document);
                      }
                    });

                    const clientBlob = new BlockBlobClient(
                      uploadMutationResults?.data?.documentPoolUploadDocument?.uploadLink!
                    );
                    await clientBlob.uploadData(fileEntity);
                  }
                });
              },
            }}
          />
        )}
        <SecureRowLevel
          data={GeneralData?.secureRowLevels?.nodes!}
          isUpdatable={selectedDocument?._isUpdatable!}
          secureRowLevelsId={selectedDocument?.rowSecurityId || ''}
          visibility={hideProtectionDialog}
          onDismiss={toggleProtectionDialog}
          updateProtection={{
            loading: updateSecurityLoading,
            updateSecurity: async (selectedLevel) => {
              const inputData =
                selectedLevel !== null
                  ? {
                      entityId: selectedDocument?.id!,
                      rowSecurityId: selectedLevel!,
                    }
                  : {
                      entityId: selectedDocument?.id!,
                      isProtectionRemoval: true,
                    };

              await updateDocumentSecurity({
                variables: {
                  input: inputData,
                },
                update(cache, { data: dataSet }) {
                  cache.modify({
                    id: cache.identify({}),
                    fields: {
                      documentPools(existinPools = []) {
                        const NewPoolData = cache.writeFragment({
                          data: dataSet,
                          fragment: DOCUMENT_POOL_DATA,
                        });
                        return [...existinPools, NewPoolData];
                      },
                    },
                  });
                },
              });
              return UpdateSecurityError;
            },
          }}
        />
        {DocumentFilesLoading && <DocumentLoaderShimmer />}
        {DocumentFilesData?.documentPools?.nodes.map((documentPool, index) => {
          return (
            <Stack key={index.toString()}>
              <div
                ref={
                  documentPool.id === history.location.state?.poolId
                    ? scrollToRef
                    : undefined
                }
              >
                <GroupHeader
                  isOpen={selectedPoolId === documentPool.id}
                  historyCount={
                    documentPool._documentFileCount?.selectedDocuments || 0
                  }
                  availableCount={
                    documentPool._documentFileCount?.availableDocuments || 0
                  }
                  filterOptions={filterOptions!}
                  onFilterChange={(filterOptions) =>
                    onFilterChange(filterOptions, documentPool)
                  }
                  isHistory={isHistory}
                  documentPool={documentPool}
                  documentTypeOptions={documentTypesOptions}
                  onHeaderClick={(documentPoolItem) => {
                    setIsHistory(false);
                    setFilterOptions({
                      filterTypes: [historyFilterObject],
                      startsWith: true,
                    });
                    if (documentPoolItem.defaultDocumentTypeId) {
                      if (documentPoolItem.isDocumentTypeSelector) {
                        setDocumentOptions(
                          filterDocumentOptions(documentPoolItem) || []
                        );
                      } else setDocumentOptions(withoutFilterDocumentOptions);
                    } else {
                      setDocumentOptions(withoutFilterDocumentOptions);
                    }
                    setDefaultDocumentTypeId(
                      documentPoolItem.defaultDocumentTypeId!
                    );
                    setIsDocumentTypeSelector(
                      documentPoolItem.isDocumentTypeSelector!
                    );
                    setExtractionTypeId(documentPoolItem.extractionTypeId);
                    _headerOnClick(documentPoolItem.id);
                    updateDocumentPoolIdRef(documentPoolItem.id);
                  }}
                  onUploadClick={() => {
                    setSelectedDocument(null);
                    toggleUploadDialog();
                  }}
                />
              </div>
              {selectedPoolId === documentPool.id &&
                ((isHistory &&
                  !!documentPool._documentFileCount?.selectedDocuments) ||
                  (!isHistory &&
                    !!documentPool._documentFileCount?.availableDocuments)) && (
                  <Stack style={{ height: sectionHeight }}>
                    <InfiniteList
                      items={documentListData}
                      loading={entityDocumentsLoading}
                      hasNextPage={pageInfo?.hasNextPage}
                      columns={gridColumns}
                      onRenderItemColumn={_renderItemColumn}
                      onColumnHeaderClick={(_, column) => {
                        if (column) _onColumnClick(column);
                      }}
                      onLoadMore={loadMore}
                      onSelectionChanged={setSelectedList}
                      isSelectedOnFocus={false}
                    />
                  </Stack>
                )}
            </Stack>
          );
        })}
      </Stack>
      <DocumentViewModal
        centerAlign
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </ScrollablePane>
  );
};
