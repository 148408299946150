import { PrimaryButton, Stack, makeStyles } from '@fluentui/react';
import React from 'react';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { useBoolean } from '@fluentui/react-hooks';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { TravelAuthorizationApprovalRevokeInput } from 'common/types/globalTypes';
import { useToasts } from 'react-toast-notifications';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import {
  TravelAuthorizationApprovalRevoke,
  TravelAuthorizationApprovalRevokeVariables,
} from './__generated__/TravelAuthorizationApprovalRevoke';
const CONFIRM_AMEND_DIALOG_TITLE = 'Are you sure?';
const CONFIRM_AMEND_DIALOG_SUBTEXT =
  'This will remove the travel plan from the approval cycle and require re-approval.';
const TRAVELER_AUTHORIZATION_APPROVAL_REVOKE = loader(
  './TravelAuthorizationApprovalRevoke.graphql'
);

const useStyles = makeStyles((theme) => ({
  disabledButton: {
    marginLeft: 20,
    marginRight: 5,
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
}));

interface AmendApprovalProps {
  isNew: boolean;
  isDirty: boolean;
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
  onRefetch: () => void;
}
export const AmendApproval: React.FC<AmendApprovalProps> = ({
  isNew,
  isDirty,
  travelAuthorizationData,
  onRefetch,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [hideConfirmAmendDialog, { toggle: toggleConfirmAmendDialog }] =
    useBoolean(true);
  const [travelAuthorizationApprovalRevoke] = useMutation<
    TravelAuthorizationApprovalRevoke,
    TravelAuthorizationApprovalRevokeVariables
  >(TRAVELER_AUTHORIZATION_APPROVAL_REVOKE, { errorPolicy: 'all' });

  const { id, _rowTimestamp, _isApprovalRevocable } = {
    ...travelAuthorizationData,
  };

  if (!(!isNew && !!_isApprovalRevocable)) return null;

  return (
    <Stack>
      <PrimaryButton
        disabled={isDirty}
        className={styles.disabledButton}
        text="Amend Transaction"
        onClick={() => toggleConfirmAmendDialog()}
      />
      <ConfirmDialog
        isAmendButton
        hidden={hideConfirmAmendDialog}
        title={CONFIRM_AMEND_DIALOG_TITLE}
        subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
        onDismiss={toggleConfirmAmendDialog}
        onConfirm={async () => {
          toggleConfirmAmendDialog();
          const inputVariables: TravelAuthorizationApprovalRevokeInput = {
            entityId: id!,
            rowTimestamp: _rowTimestamp!,
          };
          const { errors } = await travelAuthorizationApprovalRevoke({
            variables: {
              input: inputVariables,
            },
          });
          if (errors?.length)
            addToast(errors[0].message, {
              appearance: 'error',
            });
          else {
            onRefetch();
            addToast('Approval amended successfully', {
              appearance: 'success',
            });
          }
        }}
      />
    </Stack>
  );
};
