import { Link, Stack, Text, TooltipHost } from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { NoDataView } from 'common/components/DataPlaceholders';
import { useCommonStyles } from 'common/styles';
import { PurchaseOrderCommonData } from 'purchaseOrder/view/__generated__/PurchaseOrderCommonData';
import React from 'react';
import { PurchaseOrderDataType } from '..';
import { PurchaseOrderItemProps } from '../../BasicForm';
import { POItemProps, showPurchaseOrderModal } from '../POItemsModal';
import { DeleteItem } from './DeleteItem';
import { Header } from './Header';
import { GridColumnProps, gridColumns } from './coloumn.data';
import { useStyles } from './index.styles';
import { PurchaseOrderValues } from 'purchaseOrder/view/types';
import { useFormContext } from 'react-hook-form';
import { StyledLink } from 'common/components/StyledLink';

interface ItemsListProps {
  inputsDisabled: boolean;
  purchaseOrderData: PurchaseOrderDataType | undefined;
  commonData: PurchaseOrderCommonData | undefined;
  purchaseOrderItemAvailable: PurchaseOrderItemProps[] | undefined;
}
export const ItemsList: React.FC<ItemsListProps> = ({
  inputsDisabled,
  purchaseOrderData,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const columnLength = gridColumns.length;
  const { nodes } = { ...purchaseOrderData?.purchaseOrder?.purchaseOrderItems };
  const {
    formState: { isDirty },
  } = useFormContext<PurchaseOrderValues>();

  const transformedData = nodes?.map(
    (item) =>
      ({
        ...item,
      } as POItemProps)
  );
  const lengthData = transformedData?.length || 0;

  const aggTotal = transformedData?.reduce(
    (prev, curr) => prev + parseFloat(curr._extendedItemTotal || '0.00'),
    0.0
  );
  return (
    <Stack tokens={{ padding: '10px 15px' }}>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        {gridColumns.map((item, index) => (
          <Header item={item} key={index} />
        ))}
        {/* Buffer for aligning with the IconButton */}
        <Stack style={{ width: 10 }} />
      </Stack>

      {!lengthData && (
        <Stack tokens={{ padding: '20px 5px' }}>
          <NoDataView
            title="No Purchase order items added yet"
            show={!lengthData}
          />
        </Stack>
      )}
      {transformedData && (
        <Stack>
          <Stack>
            {transformedData?.map((item: POItemProps, index: number) => {
              return (
                <Stack horizontal key={index} tokens={{ childrenGap: 0 }}>
                  {gridColumns.map((key: GridColumnProps, keyIndex: number) => {
                    return (
                      <Stack
                        key={keyIndex}
                        className={clsx(
                          styles.cellItemInitial,
                          index === lengthData - 1 ? styles.cellBottom : '',
                          keyIndex === columnLength - 1 ? styles.cellEnd : ''
                        )}
                        style={{
                          alignItems: key.alignItems,
                          flex: key.flexProportion,
                          padding: '5px 8px',
                        }}
                      >
                        <CellRender
                          column={key}
                          index={index}
                          fieldContent={item[key.key as keyof POItemProps]}
                          onItemClick={() => {
                            showPurchaseOrderModal({
                              poItem: item,
                              isModalOpen: true,
                              isNew: false,
                            });
                          }}
                          item={item}
                        />
                      </Stack>
                    );
                  })}
                  <DeleteItem
                    item={item}
                    inputsDisabled={inputsDisabled || isDirty}
                    purchaseOrder={purchaseOrderData?.purchaseOrder}
                  />
                </Stack>
              );
            })}
          </Stack>

          {!!aggTotal && (
            <Stack
              horizontalAlign="end"
              horizontal
              tokens={{ padding: '10px 30px', childrenGap: 5 }}
            >
              <Text
                variant="large"
                className={clsx(
                  commonStyles.bold,
                  commonStyles.colorThemePrimary
                )}
              >
                {purchaseOrderData?.purchaseOrder?.currency?.currencySymbol!}
              </Text>
              <AmountTextView
                value={aggTotal?.toString() || ''}
                className={styles.extendedTotal}
              />
            </Stack>
          )}
        </Stack>
      )}

      {/* Loading the PurchaseOrderModal when selection happens */}
      {/* {!!selectedItem && (
        <POItemsForm
          setOpen={() => {
            setSelectedItem(undefined);
          }}
          purchaseOrderData={purchaseOrderData}
          selectedItem={selectedItem}
          commonData={commonData}
          purchaseOrderItemAvailable={purchaseOrderItemAvailable}
        />
      )} */}
    </Stack>
  );
};

interface CellRenderProps {
  column: GridColumnProps;
  fieldContent: any;
  index: number;
  onItemClick?: () => void;
  item: POItemProps;
}

const CellRender: React.FC<CellRenderProps> = ({
  column,
  fieldContent,
  onItemClick,
  index,
  item,
}) => {
  const styles = useStyles();

  const isTaxableDiscount = item.isDiscounted || item.isTaxable;
  const toolTipContent =
    !item.isDiscounted && !item.isTaxable
      ? ''
      : item.isDiscounted && item.isTaxable
      ? 'Taxable and Discounted'
      : item.isTaxable
      ? 'Taxable'
      : 'Discounted';

  switch (column.key) {
    case 'itemNumber':
      return (
        <TooltipHost content={toolTipContent}>
          <Stack key={index}>
            {isTaxableDiscount && <Stack className={styles.badge} />}
            <Stack>{fieldContent}</Stack>
          </Stack>
        </TooltipHost>
      );
    case 'displayPoItemType':
      return (
        <Stack key={index}>
          <StyledLink onLinkClick={onItemClick} linkText={fieldContent} />
        </Stack>
      );
    case 'displayPoItemRentalTerm':
      return (
        <Stack
          grow
          key={index}
          horizontalAlign="center"
          style={{ width: '100%' }}
        >
          <Text>{fieldContent}</Text>
        </Stack>
      );
    case 'displayPoItemAccount':
      return (
        <Stack key={index} className={styles.alignCenter}>
          <Text>{fieldContent}</Text>
        </Stack>
      );
    case 'displayPoItemUnitPrice':
      return (
        <Stack key={index} className={styles.alignRight}>
          <Text>{fieldContent}</Text>
        </Stack>
      );
    case '_extendedItemTotal':
      return (
        <Stack key={index} className={styles.alignRight}>
          <AmountTextView value={fieldContent} />
        </Stack>
      );
    case 'description':
      return (
        <Stack key={index}>
          <Text>{item.purchaseOrderItemType?.description}</Text>
        </Stack>
      );
    default:
      return (
        <Stack key={index}>
          <Text>{fieldContent}</Text>
        </Stack>
      );
  }
};
