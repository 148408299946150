import { IComboBoxOption, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { RemoveRowButton } from 'common/components/Buttons';
import { FormHookDropdown } from 'common/components/FormHooksFields';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { UserSetupCommonData_approvalTypes_nodes } from 'settings/account/userSetup/__generated__/UserSetupCommonData';
import { APPROVAL_TYPE_VALUES } from 'settings/account/userSetup/view/constant';
import { ApprovalTypeRowValues } from 'settings/account/userSetup/view/types';
import { UserTemplatevalues } from '../types';
import { GrantsCallout } from './GrantsCallout';
import { useStyles } from './index.styles';

interface ApprovalTypeViewProps {
  approvalTypes: UserSetupCommonData_approvalTypes_nodes[];
  isUpdatable?: boolean;
}
export const ApprovalTypeView: React.FC<ApprovalTypeViewProps> = ({
  approvalTypes,
  isUpdatable,
}) => {
  const { control } = useFormContext<UserTemplatevalues>();

  const { fields, remove, append } = useFieldArray({
    name: 'userTemplateApprovals',
    control,
  });

  return (
    <Stack grow tokens={{ childrenGap: 25, padding: 20 }}>
      <Text variant="xLarge">User Permissions</Text>
      <Stack grow tokens={{ childrenGap: 10 }}>
        {fields.map((item, index) => {
          const isLast = !!fields && index === fields.length - 1;
          // generate available options from currently unselected types
          const approvalTypeOptions: IComboBoxOption[] = approvalTypes
            .filter(
              (type) =>
                type.id === item.approvalTypeId ||
                !fields?.some((row) => row.approvalTypeId === type.id)
            )
            .map((type) => ({
              key: type.id,
              text: type.approvalType || '',
            }));

          return (
            <ApprovalTypeRow
              key={item.id}
              index={index}
              approvalTypeOptions={approvalTypeOptions}
              isApprovalTypeSelected={!!item.approvalTypeId}
              name={`userTemplateApprovals[${index}]`}
              remove={() => {
                remove(index);
              }}
              removeState={
                isLast
                  ? //|| inputsDisabled
                    'hidden'
                  : undefined
              }
              onChange={() => {
                if (isLast) append(APPROVAL_TYPE_VALUES);
              }}
              data={item}
              isUpdatable={!!isUpdatable}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

interface ApprovalTypeRowProps {
  name: string;
  index: number;
  approvalTypeOptions: IComboBoxOption[];
  isApprovalTypeSelected: boolean;
  remove: () => void;
  removeState?: 'disabled' | 'hidden';
  onChange: () => void;
  data: ApprovalTypeRowValues;
   isUpdatable:boolean
}
export const GRANTS_VALUES = {
  isApprover: 'Approver',
  isRequester: 'Requester',
  isObserver: 'Observer',
  isAddingNonApproversAllowed: 'Add Approvers',
  isApprovalRevokingEnabled: 'Amend',
  isApprovalTreeViewerAllowed: 'View Hierarchy',
  isAutoApproveEnabled: 'Auto Approve',
  isEnMasseApprovalsEnabled: 'En Masse Approve',
  isLowerTreeLevelBypassEnabled: 'Bypass Levels',
  isSelfApproveEnabled: 'Self Approve',
};

const ApprovalTypeRow: React.FC<ApprovalTypeRowProps> = ({
  name,
  index,
  approvalTypeOptions,
  isApprovalTypeSelected,
  remove,
  removeState,
  onChange,
  data,
  isUpdatable
}) => {
  const styles = useStyles();
  return (
    <Stack horizontal grow tokens={{ childrenGap: 20 }}>
      <Stack.Item>
        <FormHookDropdown
          name={`userTemplateApprovals.${index}.approvalTypeId`}
          options={approvalTypeOptions}
          onChange={onChange}
          disabled={!isUpdatable}
          width={300}
          calloutProps={{ calloutWidth: 250 }}
        />
      </Stack.Item>
      <Stack horizontal grow={3} tokens={{ childrenGap: 5 }}>
        <Stack.Item grow>
          <GrantsCallout
            grantsData={data}
            name={name}
            index={index}
            onChange={onChange}
            disabled={!isUpdatable || !isApprovalTypeSelected}
          />
        </Stack.Item>
        <RemoveRowButton
          className={clsx(removeState === 'hidden' && styles.hide)}
          disabled={removeState === 'disabled'}
          onClick={remove}
        />
      </Stack>
    </Stack>
  );
};
