import { mergeStyleSets } from '@fluentui/react';

export const fileType = (iconType: string) => {
  switch (iconType) {
    case 'PDF':
      return 'PDF';
    case 'THUMBNAIL':
      return 'ExcelDocument';
    case 'PNG':
      return 'PictureFill';
    case 'MSEXCEL':
      return 'WordDocument';
    case 'MSWORD':
      return 'WordDocument';
    case 'MSPOWERPOINT':
      return 'PowerPointDocument';
    case 'IMAGE':
      return 'PictureFill';
    case 'VIDEO':
      return 'PictureFill';
    case 'SOUND':
      return 'MSNVideos';
    case 'TEXT':
      return 'DietPlanNotebook';
    default:
      return 'FileCode';
  }
};

const BLUE_001 = '#0078d4';
const BLUE_002 = '#004578';
const GREEN_001 = '#008000';
const RED = 'red';

const colorStyles = mergeStyleSets({
  imageColor: [{ color: BLUE_001 }],
  excelColor: [{ color: GREEN_001 }],
  docColor: [{ color: BLUE_002 }],
  pdfColor: [{ color: RED }],
});

export const fileTypeColor = (iconType: string) => {
  switch (iconType) {
    case 'PDF':
      return colorStyles.pdfColor;
    case 'THUMBNAIL':
      return colorStyles.imageColor;
    case 'PNG':
      return colorStyles.imageColor;
    case 'MSEXCEL':
      return colorStyles.excelColor;
    case 'MSPOWERPOINT':
      return colorStyles.pdfColor;
    case 'MSWORD':
      return colorStyles.docColor;
    case 'IMAGE':
      return colorStyles.imageColor;
    case 'VIDEO':
      return colorStyles.pdfColor;
    case 'SOUND':
      return colorStyles.pdfColor;
    case 'TEXT':
      return colorStyles.pdfColor;
    default:
      return colorStyles.pdfColor;
  }
};
