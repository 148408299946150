import {
  DatePicker,
  DayOfWeek,
  PrimaryButton,
  Stack,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { useStyles } from '../index.styles';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import React, { useCallback, useEffect, useState } from 'react';
import { InvoiceDetails } from '../../../__generated__/InvoiceDetails';
import { ApprovalRequestInput } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { dateFormat, onFormatDate } from 'common/utils/dateFormats';
import {
  InvoiceSigningApprovalCreate,
  InvoiceSigningApprovalCreateVariables,
} from 'ap/signing/transactionSigning/__generated__/InvoiceSigningApprovalCreate';
import { UrgencyToggle } from 'common/components/UrgencySelector/UrgencyToggle';

const INVOICE_SIGNING_APPROVAL_CREATE = loader(
  '../../../../InvoiceSigningApprovalCreate.graphql'
);
const INVOICE_DETAILS = loader('../../../InvoiceDetails.graphql');

const CONFIRM_REQUEST_DIALOG_TITLE =
  'Are you sure you want to request this transaction for approval?';
const CONFIRM_REQUEST_DIALOG_SUBTEXT =
  'Your transaction will be requested for approval.';
const FINALIZED_BY_DATE_TOOLTIP =
  'Automatically promotes to urgent notifications when not approved by this date';

interface RequestApprovalProps {
  invoiceDetailsData?: InvoiceDetails;
  isNew: boolean;
  isDirty: boolean;
  onRequestStatus: (value: boolean) => void;
}

export const RequestApprovalTransaction: React.FC<RequestApprovalProps> = ({
  invoiceDetailsData,
  isNew,
  isDirty,
  onRequestStatus,
}) => {
  const { addToast } = useToasts();
  const [visible, setVisible] = useState(false);
  const styles = useStyles();
  const [requiredDate, setRequiredDate] = React.useState<string | undefined>();
  const [requestComment, setRequestComment] = React.useState<string>();
  const [urgencyLevel, setUrgencyLevel] = React.useState<number>(1);
  const { _isStagedApprovalRequest } = { ...invoiceDetailsData?.invoice };

  const [invoiceSigningApprovalCreate, { loading }] = useMutation<
    InvoiceSigningApprovalCreate,
    InvoiceSigningApprovalCreateVariables
  >(INVOICE_SIGNING_APPROVAL_CREATE, {
    errorPolicy: 'all',
    onCompleted: () => {
      setUrgencyLevel(1);
    },
  });

  const onRequestStatusCb = useCallback(onRequestStatus, []);
  
  useEffect(() => {
    onRequestStatusCb(loading);
  }, [loading, onRequestStatusCb]);

  if (!isNew && _isStagedApprovalRequest)
    return (
      <Stack>
        <PrimaryButton
          disabled={isDirty}
          className={styles.diabledButton}
          text="Request Approval"
          onClick={() => setVisible(true)}
        />
        {visible && (
          <ConfirmDialog
            hidden={!visible}
            title={CONFIRM_REQUEST_DIALOG_TITLE}
            subText={CONFIRM_REQUEST_DIALOG_SUBTEXT}
            onDismiss={() => setVisible(false)}
            minWidth={500}
            onConfirm={async () => {
              setVisible(false);
              const inputVariables: ApprovalRequestInput = {
                entityId: invoiceDetailsData?.invoice?.id!,
                rowTimestamp: invoiceDetailsData?.invoice?._rowTimestamp!,
                comments: requestComment,
                requiredDate: requiredDate ? requiredDate : undefined,
              };
              const { errors } = await invoiceSigningApprovalCreate({
                variables: {
                  input: {
                    entityApproval: [inputVariables],
                    urgencyLevel: urgencyLevel,
                  },
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: INVOICE_DETAILS,
                    variables: {
                      id: invoiceDetailsData?.invoice?.id!,
                    },
                  },
                ],
              });

              if (errors?.length)
                addToast(errors[0].message, {
                  appearance: 'error',
                });
              else {
                setRequestComment('');
                setRequiredDate('');
                addToast('Request sent for approval', {
                  appearance: 'success',
                });
              }
            }}
          >
            <Stack tokens={{ childrenGap: 10 }}>
              <TextField
                className={styles.marginT10}
                multiline
                rows={3}
                value={requestComment}
                placeholder="Please write your comment here (optional)"
                resizable={false}
                onChange={(_event, value) => setRequestComment(value || '')}
              />
              <TooltipHost content={FINALIZED_BY_DATE_TOOLTIP}>
                <DatePicker
                  minDate={new Date()}
                  firstDayOfWeek={DayOfWeek.Sunday}
                  placeholder="Finalized by date (optional)"
                  ariaLabel="Date"
                  formatDate={onFormatDate}
                  showWeekNumbers
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay
                  showGoToToday={false}
                  onSelectDate={(date) =>
                    setRequiredDate(dateFormat(date!.toString()))
                  }
                />
              </TooltipHost>
              <Stack tokens={{ padding: '6px 0px 0px 0px' }}>
                <UrgencyToggle
                  onToggle={(data) => {
                    setUrgencyLevel(data ? 0 : 1);
                  }}
                />
              </Stack>
            </Stack>
          </ConfirmDialog>
        )}
      </Stack>
    );
  else return null;
};
