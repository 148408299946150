import { IDropdownOption, Stack, makeStyles } from '@fluentui/react';
import { FormHookDropdown } from 'common/components/FormHooksFields';
import React from 'react';
import { BusinessUnitCommonData_companyCurrencies } from '../../__generated__/BusinessUnitCommonData';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';

const useStyles = makeStyles(() => ({
  container: {
    width: '50%',
  },
  currency: {
    width: '60%',
  },
  isPrimary: {
    width: '40%',
  },
}));

interface FormFieldsProps {
  baseField: string;
  currencyDisabled: boolean;
  companyCurrencies:
    | BusinessUnitCommonData_companyCurrencies
    | null
    | undefined;
  isUpdatable: boolean;
  onPrimaryChange: (param: boolean) => void;
}

export const FormFields: React.FC<FormFieldsProps> = ({
  baseField,
  currencyDisabled,
  companyCurrencies,
  isUpdatable,
  onPrimaryChange,
}) => {
  const styles = useStyles();
  const { nodes } = { ...companyCurrencies };

  const currencyOptions: IDropdownOption[] =
    nodes?.map((ele) => {
      const name = ele.name ? ele.name : '';
      const isoCode = ele.isoCode ? ` (${ele.isoCode})` : '';
      return {
        key: ele.id,
        text: `${name}${isoCode}`,
      };
    }) || [];

  return (
    <Stack
      horizontal
      verticalAlign="end"
      className={styles.container}
      tokens={{ childrenGap: 20 }}
    >
      <Stack.Item className={styles.currency}>
        <FormHookDropdown
          name={`${baseField}.currencyId`}
          options={currencyOptions}
          disabled={currencyDisabled}
        />
      </Stack.Item>
      <Stack.Item className={styles.isPrimary}>
        <FormHookCheckBox
          name={`${baseField}.isPrimary`}
          label="Primary"
          disabled={!isUpdatable}
          onChange={(_e, checked) => {
            onPrimaryChange(!!checked);
          }}
        />
      </Stack.Item>
    </Stack>
  );
};
