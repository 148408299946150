import { AccountingEntryInvoice } from './__generated__/AccountingEntryInvoice';
import { AccountingEntryValues } from './types';
import { INVOICE_INITIAL_VALUES, ACCOUNTING_INITIAL_VALUES } from './constants';

export const getDefaultValues = (
  invoiceDetailsData: AccountingEntryInvoice | undefined
) => {
  let defaultValues: AccountingEntryValues = { ...INVOICE_INITIAL_VALUES };
  if (invoiceDetailsData?.invoice) {
    const { invoiceDistributions } = { ...invoiceDetailsData.invoice };
    const invoiceDistributionsRows = invoiceDistributions.nodes.map((item) => {
      const {
        productionIncentive,
        businessUnit,
        department,
        statusType,
        tax1099t4Type,
        ...itemFields
      } = { ...item };
      return itemFields;
    });
    if (invoiceDetailsData.invoice._isUpdatable) {
      defaultValues = {
        ...invoiceDetailsData.invoice,
        invoiceDistributions: !!invoiceDetailsData.invoice.invoiceDistributions
          .nodes.length
          ? [...invoiceDistributionsRows, { ...ACCOUNTING_INITIAL_VALUES }]
          : [ACCOUNTING_INITIAL_VALUES],
      };
    }
  }
  return defaultValues as AccountingEntryValues;
};
