import { InvoiceFilter, SearchRequestInput } from 'common/types/globalTypes';

import { TABLE_ROWS } from 'common/constants';
import {
  getFilterParams,
  toFilterVariable,
  toOrderByVariable,
} from 'ap/signing/utils.common';
import { SortOrder } from 'common/utils/commonTypes';
import { FilterArrayType } from 'common/components/Filters';
import { InvoiceSigningSearchHintsVariables } from 'ap/signing/__generated__/InvoiceSigningSearchHints';
import {
  InvoicesSigningSearch,
  InvoicesSigningSearchVariables,
} from 'ap/signing/__generated__/InvoicesSigningSearch';
import { InvoiceSigningSearchFilterTotalsVariables } from 'ap/signing/__generated__/InvoiceSigningSearchFilterTotals';

export const handleSearchGlobal = async (
  showMore: boolean,
  // newHints: boolean,
  globalValue: boolean,
  searchTextValue: string | null,
  searchHints: number | null | undefined,
  invoicesData: InvoicesSigningSearch | undefined,
  sortOrderParam: SortOrder | undefined,
  searchFilters: FilterArrayType[],
  isSearchAllData: boolean,
  isSigningSearch: boolean,
  isHistorySearch: boolean
) => {
  const filterParams = getFilterParams(searchFilters);
  const searchRequest = searchTextValue
    ? {
        searchText: [searchTextValue ? searchTextValue : ''],
        searchHintId: searchHints,
      }
    : undefined;
  const searchHintsRequest: SearchRequestInput = {
    searchText: [searchTextValue ? searchTextValue : ''],
    searchHintId: null,
  };
  const searchQueryParam =
    searchTextValue && searchTextValue.length > 0 ? searchTextValue : undefined;
  const variables: InvoicesSigningSearchVariables = {
    isSigningSearch,
    isHistorySearch,
    first: TABLE_ROWS,
    after: showMore
      ? invoicesData?.invoiceSigningSearch?.pageInfo.endCursor
      : undefined,
    orderBy: toOrderByVariable(sortOrderParam),
    filter: searchFilters?.length
      ? ({ and: toFilterVariable(searchFilters) } as InvoiceFilter)
      : undefined,
    invoiceSigningSearchSearchRequest: searchRequest,
    isLinkSearch: globalValue,
    isSearchAllData: isSearchAllData,
  };

  const totalFilterVariables: InvoiceSigningSearchFilterTotalsVariables = {
    isSigningSearch,
    isHistorySearch,
    invoiceSigningSearchSearchRequest: searchRequest,
    isLinkSearch: globalValue,
    isSearchAllData: isSearchAllData,
    ...filterParams,
  };

  const variableHints: InvoiceSigningSearchHintsVariables = {
    invoiceSigningSearchRequest: searchHintsRequest,
    isLinkSearch: globalValue,
    isSearchAllData: isSearchAllData,
  };

  return {
    searchQueryParam,
    searchRequest,
    queryVariables: variables,
    queryTotalFilterVariables: totalFilterVariables,
    variableHints,
  };
};
