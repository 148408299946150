import {
  ActionButton,
  IDropdownOption,
  Icon,
  IconButton,
  Label,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import {
  FormHookAmount,
  FormHookDropdown,
} from 'common/components/FormHooksFields';
import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { CompanyValues } from '../types';
import { useStyles } from './index.styles';

const TOOLTIP_TEXT = '1 unit of home currency';
const CURRENCY_LENGTH = 10;
interface CurrencyFormFieldsProps {
  currencyOptions: IDropdownOption[];
  isUpdatable: boolean | null | undefined;
}
export const CurrencyFormFields: React.FC<CurrencyFormFieldsProps> = ({
  currencyOptions,
  isUpdatable,
}) => {
  const styles = useStyles();
  const { control } = useFormContext<CompanyValues>();
  const { fields, append, remove } = useFieldArray({
    name: 'companyValidCurrencies',
    control,
  });

  const watchValidCurrencies = useWatch({
    name: 'companyValidCurrencies',
    control,
  });
  const watchHomeCurrency = useWatch({
    name: 'currencyId',
    control,
  });

  return (
    <Stack
      tokens={{
        childrenGap: 20,
      }}
      grow={1}
    >
      <Stack
        horizontal
        tokens={{
          childrenGap: 60,
        }}
        verticalAlign="start"
        horizontalAlign="start"
      >
        <Stack horizontal horizontalAlign="start" className={styles.width250}>
          <Text variant="large" className={styles.currencyText}>
            Currencies
          </Text>
        </Stack>
        <Stack horizontal horizontalAlign="start" className={styles.width200}>
          <TooltipHost content={TOOLTIP_TEXT}>
            <IconButton iconProps={{ iconName: 'Info' }} />
          </TooltipHost>
          <Text variant="large" className={styles.currencyText}>
            Budgeted Rate
          </Text>
        </Stack>
        <Stack horizontal horizontalAlign="start" className={styles.width200}>
          <TooltipHost content={TOOLTIP_TEXT}>
            <IconButton iconProps={{ iconName: 'Info' }} />
          </TooltipHost>
          <Text variant="large" className={styles.currencyText}>
            Spot Rate
          </Text>
        </Stack>
      </Stack>
      {fields.map((field, index) => {
        const isCurrencySelectionDisabled = !!watchValidCurrencies?.[index]?.id;

        return (
          <Stack
            horizontal
            tokens={{
              childrenGap: 60,
            }}
            verticalAlign="end"
            key={field.id}
          >
            <FormHookDropdown
              name={`companyValidCurrencies.${index}.currencyId`}
              options={currencyOptions}
              width={250}
              disabled={isCurrencySelectionDisabled}
            />
            <Stack className={styles.width200}>
              <FormHookAmount
                name={`companyValidCurrencies.${index}.companyExchangeRate`}
                placeholder="Enter rate"
                disabled={!isUpdatable}
              />
            </Stack>
            <Stack className={styles.width200}>
              <FormHookAmount
                name={`companyValidCurrencies.${index}.companySpotRate`}
                placeholder="Enter rate"
                disabled={!isUpdatable}
              />
            </Stack>

            {watchValidCurrencies?.[index]?.currencyId === watchHomeCurrency ? (
              <Stack
                horizontal
                verticalAlign="end"
                tokens={{
                  childrenGap: 5,
                }}
              >
                <Icon iconName="CheckMark" className={styles.deleteIcon} />
                <Text className={styles.primaryLabel}>Home Currency</Text>
              </Stack>
            ) : (
              (watchValidCurrencies?.[index]?._isDeletable ||
                !isCurrencySelectionDisabled) && (
                <TooltipHost content="Delete">
                  <IconButton
                    iconProps={{ iconName: 'delete' }}
                    className={styles.deleteIcon}
                    ariaLabel="Delete"
                    onClick={() => {
                      remove(index);
                    }}
                    disabled={!isUpdatable}
                  />
                </TooltipHost>
              )
            )}
          </Stack>
        );
      })}

      <Stack horizontal verticalAlign="center" className={styles.paddingBottom}>
        <ActionButton
          iconProps={{ iconName: 'add' }}
          text="Add"
          onClick={() => {
            append({
              id: null,
              _rowTimestamp: null,
              currencyId: null,
              companyExchangeRate: null,
              companySpotRate: null,
              _isHomeCurrency: null,
              _isUpdatable: null,
              _isDeletable: null,
            });
          }}
          disabled={!isUpdatable || fields.length === CURRENCY_LENGTH}
        />
      </Stack>
    </Stack>
  );
};
