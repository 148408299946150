import { Stack } from '@fluentui/react';
import { DocumentList } from 'common/components/DocumentList';
import React from 'react';
import { useStyles } from './index.styles';
import { columns } from './column.data';
import { NoDataView } from 'common/components/DataPlaceholders';
import { getWindowDimensions } from 'common/utils/columnUtilities';
import { InvoiceDetails_invoice_entityDocumentsByEntityId } from 'ap/signing/transactionSigning/view/__generated__/InvoiceDetails';

interface FilesProps {
  fileListData: InvoiceDetails_invoice_entityDocumentsByEntityId;
}

export const Files: React.FC<FilesProps> = ({ fileListData }) => {
  const styles = useStyles();

  const { width } = getWindowDimensions();
  const documentViewPosition = width ? width * 0.8 - 500 : 50;

  return (
    <Stack tokens={{ childrenGap: 10 }} className={styles.mainStack}>
      <Stack tokens={{ childrenGap: 10, padding: 10 }}>
        {fileListData?.nodes?.length && (
          <DocumentList
            isNew={false}
            columns={columns}
            data={fileListData.nodes || []}
            paddingLeft={documentViewPosition}
          />
        )}
        <NoDataView
          show={fileListData?.nodes?.length === 0}
          title="No Attachments"
        />
      </Stack>
    </Stack>
  );
};
