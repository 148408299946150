import React, { useCallback, useState } from 'react';
import { AttachFormProps } from '..';
import { useStyles } from './index.styles';
import { AttachablePurchaseOrderDocuments, AttachablePurchaseOrderDocumentsVariables, AttachablePurchaseOrderDocuments_attachablePurchaseOrderDocuments_nodes } from './__generated__/AttachablePurchaseOrderDocuments';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { DISABLED_FILE_FORMATS, TABLE_ROWS } from 'common/constants';
import { AttachPurchaseOrderDocument, AttachPurchaseOrderDocumentVariables } from './__generated__/AttachPurchaseOrderDocument';
import { useToasts } from 'react-toast-notifications';
import { DirectionalHint, IColumn, IDetailsRowProps, IRenderFunction, IconButton, Stack, Text, TooltipHost } from '@fluentui/react';
import { DocumentDataCallout } from 'common/components/AttachDocumentModal/DocumentDataCallout';
import { dateConvertions, dateFormat, getGlobalDateFormat } from 'common/utils/dateFormats';
import { EntityDocumentFilter } from 'common/types/globalTypes';
import { SortOrder } from '../types';
import { toOrderByVariable } from '../utils';
import { AttachDocumentModal } from 'common/components/AttachDocumentModal';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { _columns } from './columns.data';

const ATTACHABLE_PURCHASE_ORDER_DOCUMENTS = loader(
  './AttachablePurchaseOrderDocuments.graphql'
);
const ATTACH_PURCHASE_ORDER_DOCUMENT = loader(
  './AttachPurchaseOrderDocument.graphql'
);
const PURCHASE_ORDER = loader(
  '../../../../../PurchaseOrder.graphql'
);

type AttachableDataType =
  AttachablePurchaseOrderDocuments_attachablePurchaseOrderDocuments_nodes;

export const AttachFormModal: React.FC<
  AttachFormProps & { setOpen: (open: boolean) => void }
> = ({
  availableDocumentTypes,
  purchaseOrder,
  setOpen,
}) => {
    const styles = useStyles();
    const { addToast } = useToasts();

    const [selectedList, setSelectedList] = useState<AttachableDataType[]>([]);
    const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
      isOpen: false,
      _fileType: 'pdf',
    });
    const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState<number | null>(null);

    const {
      data: documentsList,
      loading: documentsLoading,
      variables: documentsVariables,
      fetchMore,
      refetch,
      networkStatus,
    } = useQuery<
      AttachablePurchaseOrderDocuments,
      AttachablePurchaseOrderDocumentsVariables
    >(ATTACHABLE_PURCHASE_ORDER_DOCUMENTS, {
      variables: { purchaseOrderId: purchaseOrder.id!, first: TABLE_ROWS },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

    const [attachDocuments, { loading: attachLoading }] = useMutation<
      AttachPurchaseOrderDocument,
      AttachPurchaseOrderDocumentVariables
    >(ATTACH_PURCHASE_ORDER_DOCUMENT, { errorPolicy: 'all' });

    const canSelectItem = useCallback(
      (item: AttachableDataType) =>
        purchaseOrder?.isDraft! ? true : !item._isAccountingDocument!,
      [purchaseOrder]
    );

    const documentTypeOptions =
      availableDocumentTypes?.purchaseOrderAvailableDocumentTypes?.nodes.map(
        (doctype) => ({
          key: doctype.id,
          text: doctype.documentType || '',
          title:
            !purchaseOrder?.isDraft && doctype.isAccountingDocument
              ? 'Upload prohibited during/after the approval process' || undefined
              : doctype.documentType || '',

          disabled:
            (!purchaseOrder?.isDraft && doctype.isAccountingDocument) ||
            undefined,
        })
      ) || [];

    const _onAttach = async () => {
      const entityDocIds = selectedList.map((item) => item.id);
      const { errors } = await attachDocuments({
        variables: {
          input: {
            purchaseOrderId: purchaseOrder?.id!,
            entityDocumentId: entityDocIds,
            documentTypeId: selectedDocumentTypeId
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: PURCHASE_ORDER,
            variables: {
              id: purchaseOrder?.id,
            },
          },
        ],
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else addToast('Document attached successfully', { appearance: 'success' });
      setOpen(false);
    };

    const onRenderItemColumn = useCallback(
      (item?: AttachableDataType, _index?: number, column?: IColumn) => {
        if (!item || !column) return undefined;

        const disableLogic = purchaseOrder?.isDraft
          ? false
          : item._isAccountingDocument;

        const viewDocumentVisible =
          item._isProtected! || DISABLED_FILE_FORMATS.includes(item._fileType!);

        const fieldContent = item[
          column.fieldName as keyof AttachableDataType
        ] as string | null;
        switch (column.key) {
          case 'fileReference':
            return (
              <Stack className={styles.columnHeight} verticalAlign="center">
                <DocumentDataCallout disabled={disableLogic!} item={item} />
              </Stack>
            );
          case 'documentType':
            return (
              <Stack className={styles.columnHeight} verticalAlign="center">
                <Text className={disableLogic ? styles.disabledText : ''}>
                  {item.documentTypes?.documentType}
                </Text>
              </Stack>
            );
          case 'indexAmount':
            return (
              <Stack
                verticalAlign="center"
                className={styles.onrenderColumnStack}
              >
                <Text className={styles.contentColumnAlignRight}>
                  {fieldContent}
                </Text>
              </Stack>
            );
          case 'isoCode':
            return (
              <Stack className={styles.columnHeight} verticalAlign="center">
                <Text className={disableLogic ? styles.disabledText : ''}>
                  {item.currency?.isoCode}
                </Text>
              </Stack>
            );

          case '_uploadDate':
            return (
              <Stack className={styles.columnHeight} verticalAlign="center">
                <Text className={disableLogic ? styles.disabledText : ''}>
                  {item._uploadDate ? getGlobalDateFormat(item._uploadDate) : ''}
                </Text>
              </Stack>
            );
          case 'indexTransactionDate':
            return (
              <Stack className={styles.columnHeight} verticalAlign="center">
                {
                  item.indexTransactionDate &&
                  <Text className={disableLogic ? styles.disabledText : ''}>
                    {dateFormat(dateConvertions(item.indexTransactionDate))}
                  </Text>
                }
              </Stack>
            );
          case 'view':
            return (
              <Stack
                className={styles.columnHeight}
                tokens={{ childrenGap: 10 }}
                horizontal
                verticalAlign="center"
              >
                <TooltipHost content="View" id="tooltipId">
                  <IconButton
                    disabled={viewDocumentVisible}
                    iconProps={{ iconName: 'View' }}
                    onClick={() =>
                      setDocViewState({
                        isOpen: true,
                        title: item.fileReference,
                        entityDocumentId: item.id,
                        _fileType: item._fileType!,
                      })
                    }
                  />
                </TooltipHost>
              </Stack>
            );

          default:
            return (
              <Stack className={styles.columnHeight} verticalAlign="center">
                <Text className={disableLogic ? styles.disabledText : ''}>
                  {fieldContent}
                </Text>
              </Stack>
            );
        }
      },
      [styles, purchaseOrder]
    );

    const { pageInfo, nodes } = {
      ...documentsList?.attachablePurchaseOrderDocuments,
    };

    const onRenderRow: IRenderFunction<IDetailsRowProps> = (
      props,
      defaultRender
    ) => {
      if (!props) {
        return null;
      }
      const { item } = { ...props };
      const newProps: IDetailsRowProps | undefined = props
        ? { ...props, className: styles.row }
        : undefined;

      const tootTipMessage =
        'Upload prohibited during/after the approval process';
      const tooltipVisible =
        !purchaseOrder?.isDraft && item._isAccountingDocument;
      return (
        <>
          <TooltipHost
            content={tooltipVisible ? tootTipMessage : ''}
            directionalHint={DirectionalHint.rightCenter}
          >
            {defaultRender!(newProps)}
          </TooltipHost>
        </>
      );
    };

    const loadMore = useCallback(
      async () =>
        await fetchMore({
          variables: {
            ...documentsVariables,
            after:
              documentsList?.attachablePurchaseOrderDocuments?.pageInfo
                .endCursor,
          },
        }),
      [fetchMore, documentsVariables, documentsList]
    );

    const onFiltersReload = useCallback(
      async (filter: EntityDocumentFilter | undefined) =>
        await refetch({ ...documentsVariables, filter }),
      [documentsVariables, refetch]
    );

    const reload = useCallback(
      async (sort?: SortOrder) =>
        await refetch({ after: null, orderBy: toOrderByVariable(sort) }),
      [refetch]
    );

    const onDocumentTypeReload = useCallback(
      async (documentTypeId: number | null) =>
        await refetch({ ...documentsVariables, documentTypeId }),
      [refetch, documentsVariables]
    );

    const attachDisabled = selectedList.length === 0;

    return (
      <>
        <AttachDocumentModal
          columns={_columns}
          loading={documentsLoading}
          modalWidth={1350}
          items={
            networkStatus === NetworkStatus.refetch ||
              networkStatus === NetworkStatus.setVariables
              ? undefined
              : nodes
          }
          hasNextPage={pageInfo?.hasNextPage}
          attachLoading={attachLoading}
          availableDocumentTypes={documentTypeOptions}
          setOpen={setOpen}
          onSortReload={reload}
          onLoadMore={loadMore}
          onRenderRow={onRenderRow}
          onFiltersReload={onFiltersReload}
          onDocumentTypeChange={(documentTypeId) => {
            onDocumentTypeReload(documentTypeId)
            setSelectedDocumentTypeId(documentTypeId)
          }}
          attachDisabled={attachDisabled}
          onSelectionChanged={setSelectedList}
          dropdownDisabled={selectedList.length > 0}
          onRenderItemColumn={onRenderItemColumn}
          onAttachDocuments={_onAttach}
          canSelectItem={canSelectItem}
        />
        <DocumentViewModal
          onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
          {...docViewState}
        />
      </>
    );
  };
