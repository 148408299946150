import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { IconButton, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import DraggablePanel from 'common/components/DraggablePanel';
import { TransactionModal } from 'common/components/Modals/TransactionModal';
import { PanelHeader } from 'common/components/PanelHeader';
import { useCommonStyles } from 'common/styles';
import { PANEL_COMMON_WIDTH, PanelCommonProps } from 'common/types/utility';
import {
  DocumentAssignmentTabs,
  setTabMode,
} from 'documents/documentAssignment';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DocumentDetails } from './DocumentDetails';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import {
  GetDocumentDetails,
  GetDocumentDetailsVariables,
} from './__generated__/GetDocumentDetails';
import { DocumentPackageEmail } from 'common/components/DocumentPackageEmail/DocumentPackageEmail';
import {
  EntityDocumentEmailCreate,
  EntityDocumentEmailCreateVariables,
} from './__generated__/EntityDocumentEmailCreate';
import { EmailCreateValues } from 'common/components/DocumentPackageEmail/DocumentPackageEmail/DocumentPackageEmailModal/FormModal/types';
import { useToasts } from 'react-toast-notifications';
import {
  EntityDocumentEmailStatus,
  EntityDocumentEmailStatusVariables,
} from './__generated__/EntityDocumentEmailStatus';
import { IconState } from 'common/types/globalTypes';
const GET_DOCUMENT_DETAILS = loader('./GetDocumentDetails.graphql');
const ENTITY_DOCUMENT_EMAIL_CREATE = loader(
  './EntityDocumentEmailCreate.graphql'
);
const FETCH_EMAIL_STATUS = loader('./EntityDocumentEmailStatus.graphql');

interface DocumentViewModalState {
  isOpen: boolean;
  entityDocumentId?: string;
  title?: string;
  _fileType: string;
}
export const FileCabinetDocumentView = () => {
  const { documentId, selectedPoolId } = useParams<{
    documentId: string;
    selectedPoolId: string;
  }>();
  const history = useHistory();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();
  const [isOpen, { setFalse: dismissPanel }] = useBoolean(true);
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });
  const { data: documentDetails, loading: documentDetailsLoading } = useQuery<
    GetDocumentDetails,
    GetDocumentDetailsVariables
  >(GET_DOCUMENT_DETAILS, {
    variables: {
      id: documentId!,
    },
    skip: !documentId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [entityDocumentEmailCreate, { loading, data }] = useMutation<
    EntityDocumentEmailCreate,
    EntityDocumentEmailCreateVariables
  >(ENTITY_DOCUMENT_EMAIL_CREATE, { errorPolicy: 'all' });

  useEffect(() => {
    setTabMode(
      selectedPoolId
        ? DocumentAssignmentTabs.folder
        : DocumentAssignmentTabs.documents
    );
  }, [selectedPoolId]);

  const { _documentType, _emailDocument, emailDocumentsByEntityId } = {
    ...documentDetails?.entityDocument,
  };
  const { nodes } = { ...documentDetails?.fileCabinetHistoryTransaction };
  const isSuccessful = !!data?.entityDocumentEmailCreate;
  const [showEmailStatus, setShowEmailStatus] = useState(false);

  const [fetchEmailStatus, { stopPolling }] = useLazyQuery<
    EntityDocumentEmailStatus,
    EntityDocumentEmailStatusVariables
  >(FETCH_EMAIL_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    pollInterval: 60000,
    onCompleted(data) {
      if (
        data.entityDocument?._emailDocument?._sendIconState === IconState.SENT
      ) {
        stopPolling?.();
        setShowEmailStatus(true);
      }
    },
  });
  const _onSubmitValues = async (values: EmailCreateValues) => {
    if (documentId) {
      const { errors } = await entityDocumentEmailCreate({
        variables: {
          input: {
            entityId: documentId,
            ...values,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_DOCUMENT_DETAILS,
            variables: {
              id: documentId,
            },
          },
        ],
      });
      if (!!errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Email sent successfully.', {
          appearance: 'success',
        });
        fetchEmailStatus({
          variables: {
            id: documentId!,
          },
        });
      }
    }
  };

  const _onRenderHeader = () => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/doc/fileCabinet');
          dismissPanel();
        }}
      >
        <Stack
          grow
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{
              width: '100%',
            }}
          >
            <Stack>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Text variant="xLarge">Document</Text>
                <Text
                  variant="xLarge"
                  className={commonStyles.colorThemePrimary}
                >
                  {_documentType}
                </Text>
                <DocumentPackageEmail
                  emailDocument={_emailDocument}
                  emailDocumentsByEntityId={
                    emailDocumentsByEntityId?.nodes || []
                  }
                  isSuccessful={isSuccessful}
                  loading={loading}
                  onSubmitValues={_onSubmitValues}
                  dataLoading={documentDetailsLoading}
                  showEmailStatus={showEmailStatus}
                  setShowEmailStatus={setShowEmailStatus}
                />
              </Stack>
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 5 }}
                style={{
                  marginTop: 10,
                }}
              >
                <TransactionModal
                  data={nodes?.[0]}
                  loading={documentDetailsLoading}
                />
              </Stack>
            </Stack>
            {documentDetails?.entityDocument?.id && (
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <IconButton
                  iconProps={{ iconName: 'View' }}
                  onClick={() => {
                    const { fileReference, id, _fileType } = {
                      ...documentDetails.entityDocument,
                    };
                    setDocViewState({
                      isOpen: true,
                      title: fileReference,
                      entityDocumentId: id,
                      _fileType: _fileType!,
                    });
                  }}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </PanelHeader>
    );
  };
  const fetchEmailStatusMemo = useCallback(() => {
    fetchEmailStatus({
      variables: {
        id: documentId!,
      },
    });
  }, [fetchEmailStatus, documentId]);

  useEffect(() => {
    if (_emailDocument?._sendIconState === IconState.PENDING) {
      fetchEmailStatusMemo();
    }
  }, [_emailDocument, fetchEmailStatusMemo]);
  return (
    <DraggablePanel
      initialWidth={PANEL_COMMON_WIDTH}
      minWidth={PANEL_COMMON_WIDTH}
      {...PanelCommonProps}
      isBlocking={false}
      onRenderHeader={_onRenderHeader}
      isOpen={isOpen}
    >
      {documentDetailsLoading ? (
        <ShimmerView />
      ) : (
        <>
          <DocumentDetails documentData={documentDetails?.entityDocument} />
          <DocumentViewModal
            paddingTop={0}
            onDismiss={() =>
              setDocViewState({ isOpen: false, _fileType: 'pdf' })
            }
            {...docViewState}
          />
        </>
      )}
    </DraggablePanel>
  );
};
