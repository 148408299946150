import {
  DefaultButton,
  Icon,
  IconButton,
  Modal,
  PrimaryButton,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
  Toggle,
} from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { NoDataView } from 'common/components/DataPlaceholders';
import { useCommonStyles } from 'common/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './index.styles';
import {
  AvailablePurchaseOrders,
  AvailablePurchaseOrders_availablePurchaseOrders_nodes,
} from 'ap/signing/__generated__/AvailablePurchaseOrders';
import { InvoiceDetails_invoice_invoiceDistributions_nodes } from 'ap/signing/transactionSigning/view/__generated__/InvoiceDetails';
import { TransactionSigningValues } from 'ap/signing/transactionSigning/view/types';
import { useFormContext, useWatch } from 'react-hook-form';

interface PoReferenceModalProps {
  index: number;
  invoiceDistributions: InvoiceDetails_invoice_invoiceDistributions_nodes[];
  availablePurchaseOrders: AvailablePurchaseOrders | undefined;
}
export const PoReferenceModal: React.FC<PoReferenceModalProps> = ({
  invoiceDistributions,
  index,
  availablePurchaseOrders,
}) => {
  const renderRef = useRef<boolean>(true);
  const [selected, setSelected] = useState<
    AvailablePurchaseOrders_availablePurchaseOrders_nodes | undefined
  >(undefined);
  const [PrevSelected, setPrevSelected] = useState<
    AvailablePurchaseOrders_availablePurchaseOrders_nodes | undefined
  >(undefined);
  const [isAdjusted, setAdjusted] = useState<boolean | undefined | null>(
    undefined
  );
  const [PrevIsAdjusted, setPrevAdjusted] = useState<
    boolean | undefined | null
  >(undefined);
  const { setValue } = useFormContext<TransactionSigningValues>();
  const watch = useWatch<TransactionSigningValues>();

  // const { documentFileDistributionId } = {
  //   ...invoiceDistributions[index],
  // };
  const {
    purchaseOrderReference,
    isPoCharge,
    poEntityDocumentId,
    documentFileDistributionId,
  } = {
    ...watch?.invoiceDistributions?.[index],
  };

  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [visiblity, setVisiblity] = useState(false);
  const [idHovered, setIdHovered] = useState<string | undefined>(undefined);
  const selectionToogleStyles = clsx(
    commonStyles.colorThemePrimary,
    commonStyles.bold
  );

  // useEffect(() => {
  //   if (selected?.id) {
  //     const greaterThanZero =
  //       parseFloat(selected?.documentAppliedAmount! || '0.0') > 0;
  //     setDisableToggle(greaterThanZero);
  //   }
  // }, [selected]);

  const { nodes: purchaseOrderRefs } = {
    ...availablePurchaseOrders?.availablePurchaseOrders,
  };
  useEffect(() => {
    if (purchaseOrderRefs && visiblity) {
      if (renderRef.current) {
        const isPoChargeConst =
          isPoCharge == null || isPoCharge === undefined ? null : isPoCharge;
        setAdjusted(isPoChargeConst);
        setPrevAdjusted(isPoChargeConst);
        const findSelected = purchaseOrderRefs?.find(
          (item) => item.id === poEntityDocumentId
        );
        setSelected(findSelected);
        setPrevSelected(findSelected);
      } else renderRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiblity]);

  const onApplyChanges = () => {
    setValue(
      `invoiceDistributions.${index}.poEntityDocumentId`,
      selected?.id === undefined ? null : selected.id,
      { shouldDirty: true }
    );

    setValue(
      `invoiceDistributions.${index}.purchaseOrderReference`,
      selected?.indexReferenceNumber === undefined
        ? null
        : selected?.indexReferenceNumber
    );

    if (selected === undefined) {
      setValue(`invoiceDistributions.${index}.isPoCharge`, null);
    } else setValue(`invoiceDistributions.${index}.isPoCharge`, !!isAdjusted);
    setVisiblity(false);
  };

  const customPurchaseRef = (refName: string) => {
    const isAsterisk = refName.charAt(refName.length - 1) === '*';
    const newRefName = isAsterisk
      ? refName.slice(0, refName.length - 1)
      : refName;

    return `${newRefName}${isPoCharge ? ' *' : ''}`;
  };

  const isDirty =
    selected?.id !== PrevSelected?.id || isAdjusted !== PrevIsAdjusted;
  return (
    <Stack>
      <Stack className={styles.containerMain}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          tokens={{ childrenGap: 2 }}
          style={{ width: 110 }}
        >
          <Stack >
            {purchaseOrderReference && (
              <Text >
                {`${customPurchaseRef(purchaseOrderReference)}`!}
              </Text>
            )}
          </Stack>
          {purchaseOrderRefs && (
            <IconButton
              tabIndex={-1}
              style={{ marginLeft: 5, width: 30 }}
              iconProps={{ iconName: 'DownloadDocument' }}
              onClick={() => setVisiblity(true)}
              disabled={
                documentFileDistributionId !== null &&
                documentFileDistributionId !== undefined
              }
            />
          )}
        </Stack>
      </Stack>

      {visiblity && (
        <Modal isOpen={true} containerClassName={styles.container}>
          <Sticky stickyPosition={StickyPositionType.Header}>
            <Stack
              horizontalAlign="center"
              verticalAlign="center"
              tokens={{ padding: '20px 20px 10px 20px' }}
            >
              <Text variant="xLarge">Attached Purchase Orders</Text>
            </Stack>
          </Sticky>
          <Stack tokens={{ childrenGap: 10 }}>
            {purchaseOrderRefs?.map((item, index) => {
              const isSelected = item.id === selected?.id!;
              const POTotal =
                parseFloat(item.documentAppliedAmount || '0.00') +
                parseFloat(item.documentOverageAmount || '0.00');

              return (
                <Stack
                  onMouseEnter={() => setIdHovered(index.toString())}
                  onMouseLeave={() => setIdHovered(undefined)}
                  className={clsx(
                    idHovered === index.toString()
                      ? styles.selector
                      : isSelected
                      ? styles.selectedRow
                      : '',
                    styles.itemRow
                  )}
                  key={item.id}
                  horizontal
                  tokens={{ childrenGap: 10, padding: '10px 20px 10px 20px' }}
                  onClick={() => {
                    setSelected(item.id === selected?.id ? undefined : item);
                  }}
                >
                  {isSelected ? (
                    <Icon iconName="Accept" />
                  ) : (
                    <Stack style={{ width: 14 }} />
                  )}
                  <Stack
                    horizontal
                    style={{ width: '100%' }}
                    tokens={{ padding: '0px 20px 0px 0px' }}
                    horizontalAlign="space-between"
                  >
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <Text>{item.indexReferenceNumber}</Text>
                      <Text>{item.indexName}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                      <AmountTextView
                        className={clsx(
                          commonStyles.colorThemePrimary,
                          commonStyles.bold
                        )}
                        value={POTotal?.toString() || '0'}
                      />
                      <Text
                        className={clsx(
                          commonStyles.colorThemePrimary,
                          commonStyles.bold
                        )}
                      >
                        {item.currency?.isoCode}
                      </Text>
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}
            <Stack tokens={{ padding: 20 }}>
              <NoDataView
                title="No Purchase Order Documents Attached"
                show={purchaseOrderRefs?.length! === 0}
              />
            </Stack>

            <Stack
              tokens={{ padding: '20px' }}
              horizontal
              horizontalAlign="space-between"
            >
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Text className={!isAdjusted ? selectionToogleStyles : ''}>
                  Apply PO
                </Text>
                <Toggle
                  defaultChecked={isAdjusted === null ? undefined : isAdjusted}
                  onChange={(_, value) => {
                    setAdjusted(value);
                  }}
                  disabled={selected?.id === undefined}
                />
                <Text className={isAdjusted ? selectionToogleStyles : ''}>
                  Reference PO
                </Text>
              </Stack>

              <Stack tokens={{ childrenGap: 10 }} horizontal>
                <PrimaryButton
                  disabled={!isDirty}
                  text="Apply Changes"
                  onClick={onApplyChanges}
                />
                <DefaultButton
                  text="Cancel"
                  onClick={() => setVisiblity(false)}
                />
              </Stack>
            </Stack>
          </Stack>
        </Modal>
      )}
    </Stack>
  );
};
