import { useQuery } from '@apollo/client';
import { IColumn, IconButton, SelectionMode, Stack, Text, TooltipHost } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { ActiveLink } from 'common/components/ActiveRowLink';
import { InfiniteList } from 'common/components/InfiniteList';
import { useCommonStyles } from 'common/styles';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { ActiveApproverList } from './__generated__/ActiveApproverList';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { ConfirmDialogRemove } from './ConfirmDialogRemove';
import { ActiveApproverList_activeApproverList_nodes } from './__generated__/ActiveApproverList';
import { ConfirmDialogReplace } from './ConfirmDialogReplace';
const ACTIVE_APPROVERS = loader('./ActiveApproverList.graphql');

export type ActiveApproverListItem = ActiveApproverList_activeApproverList_nodes;

export const ReplaceApprovers = () => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const removeTooltipId = useId('tooltip');
  const replaceTooltipId = useId('tooltip');
  const [hideRemoveDialog, { toggle: toggleRemoveDialog }] = useBoolean(true);
  const [hideReplaceDialog, { toggle: toggleReplaceDialog }] = useBoolean(true);
  const [selectedUser, setSelectedUser] = useState<ActiveApproverListItem>();

  const { data, loading } = useQuery<ActiveApproverList>(ACTIVE_APPROVERS);

  const transformedData = data?.activeApproverList?.nodes?.map(
    (item) =>
    ({
      ...item,
      key: item.id,
      name: item.user?.name,
    } as ActiveApproverListItem)
  );

  const _renderItemColumn = (
    item: ActiveApproverListItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[column?.fieldName as keyof ActiveApproverListItem] as string;
      switch (column?.key) {
        case 'action':
          return (
            <Stack
              className={styles.cellContainer}
              tokens={{
                padding: '0px 0px 0px 20px',
                childrenGap: 6
              }}
              verticalAlign="center"
              horizontal
            >
              <TooltipHost
                content="Remove"
              >
                <IconButton
                  id={removeTooltipId}
                  iconProps={{
                    iconName: "Cancel"
                  }}
                  onClick={() => {
                    setSelectedUser(item)
                    toggleRemoveDialog()
                  }}
                />
              </TooltipHost>

              <TooltipHost
                content="Replace"
              >
                <IconButton
                  id={replaceTooltipId}
                  iconProps={{
                    iconName: "SyncOccurence"
                  }}
                  onClick={() => {
                    setSelectedUser(item)
                    toggleReplaceDialog()
                  }}
                />
              </TooltipHost>
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.cellContainer}
            >
              {fieldContent && <Text>{fieldContent}</Text>}
            </Stack>
          );
      }
    }
  };

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Replace approver</Text>
        </Stack>
      </Stack>
      <InfiniteList
        items={transformedData}
        loading={loading}
        hasNextPage={false}
        columns={columns}
        onRenderItemColumn={_renderItemColumn}
        selectionMode={SelectionMode.none}
      />
      <ConfirmDialogRemove
        hidden={hideRemoveDialog}
        selectedUser={selectedUser}
        onDismiss={() => {
          setSelectedUser(undefined);
          toggleRemoveDialog();
        }}
      />
      <ConfirmDialogReplace
        hidden={hideReplaceDialog}
        minWidth={500}
        selectedUser={selectedUser}
        onDismiss={() => {
          setSelectedUser(undefined);
          toggleReplaceDialog();
        }}
      />
    </>
  );
};
