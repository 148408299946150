import { NetworkStatus, useQuery } from '@apollo/client';
import {
  ActionButton,
  FontIcon,
  IColumn,
  IDetailsRowProps,
  IRenderFunction,
  IStackStyles,
  IconButton,
  Stack,
  Text,
  TooltipHost
} from '@fluentui/react';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { AttachDocumentModal } from 'common/components/AttachDocumentModal';
import { DocumentDataCallout } from 'common/components/AttachDocumentModal/DocumentDataCallout';
import { DocumentMetaForm } from 'common/components/AttachDocumentModal/DocumentMetaForm';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { DISABLED_FILE_FORMATS, TABLE_ROWS } from 'common/constants';
import { EntityDocumentsFields } from 'common/graphql/__generated__/EntityDocumentsFields';
import { useCommonStyles } from 'common/styles';
import {
  EntityDocumentFilter,
  EntityDocumentsOrderBy,
} from 'common/types/globalTypes';
import { SortOrder } from 'common/utils/commonTypes';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { Decimal } from 'decimal.js';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { InitialDocumentMetaData } from '..';
import { TransactionSigningValues } from '../../types';
import {
  AvailableInvoiceDocumentTypes,
  AvailableInvoiceDocumentTypesVariables,
} from '../ActionMenu/__generated__/AvailableInvoiceDocumentTypes';
import {
  AppliableInvoiceSigningDocuments,
  AppliableInvoiceSigningDocumentsVariables,
  AppliableInvoiceSigningDocuments_appliableInvoiceSigningDocuments_nodes,
} from '../ActionMenu/actionMenuContents/Attach/AttachForm/__generated__/AppliableInvoiceSigningDocuments';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { toOrderByVariable } from './utils';

const APPLIABLE_INVOICE_SIGNING_DOCUMENTS = loader(
  '../ActionMenu/actionMenuContents/Attach/AttachForm/AppliableInvoiceSigningDocuments.graphql'
);
const AVAILABLE_DOCUMENT_TYPES = loader(
  '../ActionMenu/AvailableInvoiceDocumentTypes.graphql'
);
interface ParamType {
  fieldName: string;
  value: string | number;
}

export type AttachableDataType =
  AppliableInvoiceSigningDocuments_appliableInvoiceSigningDocuments_nodes & {
    poInvoiceSchedules?: string[];
  };
export interface attachedDocumentsData {
  attachedDocuments: AttachableDataType[];
}
interface AttachDocumentsFormProps {
  transactionTypeId: number;
  businessUnitId: string;
  currencyId?: number | null;
  departmentId?: string | null;
  cardHolderId?: string | null;
  onDocumentsAttached: (attachedDocumentsData: attachedDocumentsData) => void;
  prevSelectedDocuments?: AttachableDataType[];
  previouslyAppliedAmount?: number;
  previousReceiptTotal?: string | null;
  onReceiptTotal?: (receiptTotal: string) => void;
  initialDocumentMetaData?: InitialDocumentMetaData;
  vendorName?: string | null,
  documentComment?: string | null;
}

const stackStyles: Partial<IStackStyles> = { root: { height: 44 } };

export const AttachDocumentsForm: React.FC<AttachDocumentsFormProps> = (
  props
) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  return (
    <>
      <Stack
        horizontal
        styles={stackStyles}
        horizontalAlign="end"
        style={{ padding: '0px 20px' }}
      >
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Documents"
            checked={true}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachDocumentsFormModal {...props} setOpen={setDialogVisible} />
      )}
    </>
  );
};

const AttachDocumentsFormModal: React.FC<
  AttachDocumentsFormProps & { setOpen: (open: boolean) => void }
> = ({
  transactionTypeId,
  businessUnitId,
  currencyId,
  departmentId,
  cardHolderId,
  onDocumentsAttached,
  prevSelectedDocuments,
  previouslyAppliedAmount,
  setOpen,
  onReceiptTotal,
  previousReceiptTotal,
  initialDocumentMetaData,
  vendorName,
  documentComment
}) => {
    const { setValue, getValues, control } =
      useFormContext<TransactionSigningValues>();
    const watchControlTotalAmount = useWatch({
      control,
      name: 'controlTotalAmount',
    });
    const watchcurrency = useWatch({ control, name: 'currency' });
    const styles = useStyles();
    const [selected, setSelected] = useState<AttachableDataType[]>([]);
    const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
      isOpen: false,
      _fileType: 'pdf',
    });
    const [toPopulateData, setToPopulateData] = useState<AttachableDataType>();
    const [isAccumulatedAmount, setIsAccumulatedAmount] = useState(false);
    const [receiptTotal, setReceiptTotal] = useState<number | null>(null);
    //To check if the document data is mapped to the create transaction fields on initial attach.
    const mapDocumentData = useRef(true);
    const documentMetaData = useRef(new Map<string, DocumentMetaForm>());
    const [checkApproveOverage, setCheckApproveOverage] = useState(false);
    const [documentAppliedAmount, setDocumentAppliedAmount] = useState(0);
    const [totalApplied, setTotalApplied] = useState(0);
    const remainingTotal = new Decimal(watchControlTotalAmount || 0).minus(
      totalApplied
    );
    const [transactionAmountState, setTransactionAmountState] = useState<
      string | null
    >(null);
    const { data: documentTypesData } = useQuery<
      AvailableInvoiceDocumentTypes,
      AvailableInvoiceDocumentTypesVariables
    >(AVAILABLE_DOCUMENT_TYPES, {
      variables: {
        transactionTypeId: transactionTypeId,
        isDocumentUpload: false,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

    const documentTypeOptions =
      documentTypesData?.invoiceSigningAvailableDocumentTypes?.nodes.map(
        (doctype) => ({
          key: doctype.id,
          text: doctype.documentType || '',
        })
      ) || [];

    const filterPO =
      watchControlTotalAmount && parseFloat(watchControlTotalAmount) > 0
        ? undefined
        : {
          _isDocumentAmountAvailable: {
            equalTo: false,
          },
        };

    const {
      data: documentsList,
      loading: documentsLoading,
      fetchMore,
      variables: documentsVariables,
      networkStatus,
      refetch,
    } = useQuery<
      AppliableInvoiceSigningDocuments,
      AppliableInvoiceSigningDocumentsVariables
    >(APPLIABLE_INVOICE_SIGNING_DOCUMENTS, {
      variables: {
        businessUnitId: businessUnitId,
        transactionTypeId: transactionTypeId,
        currencyId: currencyId,
        departmentId: departmentId,
        cardHolderId: cardHolderId,
        filter: filterPO,
        first: TABLE_ROWS,
        orderBy: [
          EntityDocumentsOrderBy._UPLOAD_DATE_DESC,
          EntityDocumentsOrderBy.PRIMARY_KEY_DESC,
        ],
        transactionAmount: transactionAmountState,
      },
      skip: transactionAmountState === null,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    });

    useEffect(() => {
      setTransactionAmountState(remainingTotal.toFixed(2));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadMore = useCallback(async () => {
      await fetchMore?.({
        variables: {
          ...documentsVariables,
          after:
            documentsList?.appliableInvoiceSigningDocuments?.pageInfo.endCursor,
        },
      });
    }, [fetchMore, documentsVariables, documentsList]);

    const setTransactionFieldValues = async (
      param: keyof TransactionSigningValues,
      value: string
    ) => {
      await setValue(param, value);
    };

    const reload = useCallback(
      async (sort?: SortOrder) =>
        await refetch({ after: null, orderBy: toOrderByVariable(sort) }),
      [refetch]
    );

    const onDocumentTypeReload = useCallback(
      async (documentTypeId: number | null | undefined) =>
        await refetch?.({
          ...documentsVariables,
          documentTypeId,
        }),
      [refetch, documentsVariables]
    );

    const getDocumentAppliedAmount = useCallback(
      () =>
        Array.from(documentMetaData.current.values()).reduce(
          (curr, next) => curr + (Number(next.documentAppliedAmount) || 0),
          0
        ),
      []
    );

    const onSelectionChanged = useCallback(
      (items: AttachableDataType[]) => {
        setSelected(items);
        //add new selections to meta data map
        items.forEach((item) => {
          if (!documentMetaData.current.has(item.id))
            documentMetaData.current.set(item.id, {
              documentAppliedAmount: item._isDocumentAmountAvailable
                ? item.documentAppliedAmounts?.remainingTotal!
                : undefined,
            });
        });
        //remove old selections from meta data map
        Array.from(documentMetaData.current.keys()).forEach((id) => {
          if (!items.some((item) => item.id === id))
            documentMetaData.current.delete(id);
        });
        setDocumentAppliedAmount(getDocumentAppliedAmount());
      },
      [getDocumentAppliedAmount]
    );

    const transformedData = useMemo(() => {
      const documents =
        documentsList?.appliableInvoiceSigningDocuments?.nodes.map(
          (documents) =>
          ({
            ...documents,
            available: documents.documentAppliedAmounts?.remainingTotal,
            isoCode: documents.currency?.isoCode,
          } as AttachableDataType)
        );

      if (prevSelectedDocuments?.length && documents?.length) {
        const filteredArray = documents.filter(
          ({ id: id1 }) =>
            !prevSelectedDocuments.some(({ id: id2 }) => id2 === id1)
        );
        return filteredArray;
      }

      return documents;
    }, [documentsList, prevSelectedDocuments]);

    const commonStyles = useCommonStyles();
    const onRenderItemColumn = useCallback(
      (item?: AttachableDataType, _index?: number, column?: IColumn) => {
        if (!item || !column) return undefined;
        const fieldContent = item[
          column.fieldName as keyof AttachableDataType
        ] as string | null;
        const viewDocumentVisible =
          item._isProtected! || DISABLED_FILE_FORMATS.includes(item._fileType!);
        switch (column.key) {
          case 'indexAmount':
          case 'available':
            return (
              <Stack className={styles.columnHeight} verticalAlign="center">
                <AmountTextView
                  value={fieldContent}
                  className={styles.contentColumnAlignRight}
                />
              </Stack>
            );
          case 'used':
            return (
              <Stack className={styles.columnHeight} verticalAlign="center">
                <AmountTextView
                  className={styles.contentColumnAlignRight}
                  value={item.documentAppliedAmounts?.usedTotal!}
                />
              </Stack>
            );
          case '_documentPoolName':
            return (
              <Stack
                horizontal
                className={styles.columnHeight}
                verticalAlign="center"
                tokens={{ childrenGap: 5 }}
              >
                {item._isDocumentDistributionAttachable && (
                  <TooltipHost
                    content={'Document distributions will automatically be added'}
                  >
                    <FontIcon
                      iconName="InfoSolid"
                      className={commonStyles.colorThemePrimary}
                    />
                  </TooltipHost>
                )}
                <Text>{fieldContent}</Text>
              </Stack>
            );
          case 'fileReference':
            return (
              <Stack className={styles.columnHeight} verticalAlign="center">
                <DocumentDataCallout item={item} />
              </Stack>
            );
          case '_uploadDate':
            return (
              <Stack className={styles.columnHeight} verticalAlign="center">
                <Text>{getGlobalDateFormat(item._uploadDate!)}</Text>
              </Stack>
            );
          case 'indexTransactionDate':
            return (
              <Stack className={styles.columnHeight} verticalAlign="center">
                {item.indexTransactionDate && (
                  <Text>
                    {dateFormat(dateConvertions(item.indexTransactionDate!))}
                  </Text>
                )}
              </Stack>
            );
          case 'action':
            return (
              <Stack
                className={styles.columnHeight}
                tokens={{ childrenGap: 10 }}
                horizontal
                verticalAlign="center"
              >
                <TooltipHost content="View" id="tooltipId">
                  <IconButton
                    disabled={viewDocumentVisible}
                    iconProps={{ iconName: 'View' }}
                    onClick={() =>
                      setDocViewState({
                        isOpen: true,
                        title: item.fileReference,
                        entityDocumentId: item.id,
                        _fileType: item._fileType!,
                      })
                    }
                  />
                </TooltipHost>
              </Stack>
            );

          default:
            return (
              <Stack className={styles.columnHeight} verticalAlign="center">
                <Text>{fieldContent}</Text>
              </Stack>
            );
        }
      },
      [commonStyles, styles]
    );

    const onRenderRow: IRenderFunction<IDetailsRowProps> = useCallback(
      (props, defaultRender) => {
        if (!props || !defaultRender) return null;
        const item = props.item as EntityDocumentsFields | null;
        const isSelected = item && selected.some((row) => row.id === item.id);
        // row is null, not selected, or not expandable
        if (!item || !isSelected || !item._isAccountingDocument)
          return defaultRender(props);
        const onDataReceive = (data: DocumentMetaForm) => {
          documentMetaData.current.set(item.id, data);

          const metaValues = Array.from(documentMetaData.current.values());
          const checkApproveOverage = metaValues.some(
            (item) =>
              Number(item.remainingTotal!) <
              Number(item.documentAppliedAmount!) &&
              (!item.documentStatusExplanation || !item.isAppliedAmountOverage)
          );
          setDocumentAppliedAmount(getDocumentAppliedAmount());
          setCheckApproveOverage(checkApproveOverage);
        };

        return (
          <Stack>
            {defaultRender!({
              ...props,
            })}
            <DocumentMetaForm onChange={onDataReceive} documentItem={item} />
          </Stack>
        );
      },
      [selected, getDocumentAppliedAmount]
    );

    const _onAttach = async () => {
      const values = getValues();
      const selectedEntities = selected.map((item) => {
        const meta = documentMetaData.current.get(item.id);
        let additionalData: DocumentMetaForm | undefined = meta
          ? meta
          : undefined;
        return {
          ...item,
          ...additionalData,
          id: item.id,
        } as AttachableDataType;
      });

      if (toPopulateData) {
        if (mapDocumentData.current) {
          if (!values.vendorReference && toPopulateData.indexName)
            setValue('vendorReference', toPopulateData.indexName);
          if (!values.invoiceDate && toPopulateData.indexTransactionDate)
            setTransactionFieldValues(
              'invoiceDate',
              dateFormat(dateConvertions(toPopulateData.indexTransactionDate))
            );
          if (!values.description && toPopulateData.indexDescription)
            setTransactionFieldValues(
              'description',
              toPopulateData.indexDescription
            );
          if (!values.invoiceNumber && toPopulateData.indexReferenceNumber)
            setTransactionFieldValues(
              'invoiceNumber',
              toPopulateData.indexReferenceNumber
            );
          if (!values.currencyId && toPopulateData.indexCurrencyId) {
            setTransactionFieldValues(
              'currencyId',
              toPopulateData.indexCurrencyId.toString()
            );

            setValue('currency.id', toPopulateData.indexCurrencyId);
            if (toPopulateData.currency?.isoCode)
              setValue('currency.isoCode', toPopulateData.currency?.isoCode);
          }
          if (isAccumulatedAmount && toPopulateData.indexAmount) {
            //When isAccumulatedAmount documents are selected.

            setValue('controlTotalAmount', toPopulateData.indexAmount);
          } else {
            //When controlTotalAmount field is initially blank.
            if (!values.controlTotalAmount && toPopulateData.indexAmount)
              setValue('controlTotalAmount', toPopulateData.indexAmount);
          }
          mapDocumentData.current = false;
          if (receiptTotal) {
            onReceiptTotal?.(
              (parseFloat(previousReceiptTotal || '0') + receiptTotal)
                .toFixed(2)
                .toString()
            );
          }
        }
      }
      onDocumentsAttached({
        attachedDocuments: selectedEntities,
      });
      setSelected([]);
      documentMetaData.current.clear();
      setDocumentAppliedAmount(0);
      setOpen(false);
    };

    useEffect(() => {
      const totalApplied = new Decimal(documentAppliedAmount).plus(
        previouslyAppliedAmount || 0
      );
      setTotalApplied(totalApplied.toNumber());
    }, [documentAppliedAmount, previouslyAppliedAmount]);

    useEffect(() => {
      if (selected.length) {
        const isAccountingDocumentArray = selected.filter(
          (ele) => ele._isAccountingDocument
        );
        if (isAccountingDocumentArray.length) {
          // eg: INVOICE
          const isDocumentAmountNotAvailableArray =
            isAccountingDocumentArray.filter(
              (ele) =>
                !ele._isDocumentAmountAvailable &&
                !ele._isDocumentReceiptAccumulated
            );
          // eg: PURCHASE ORDER
          const isDocumentAmountAvailableArray = isAccountingDocumentArray.filter(
            (ele) => ele._isDocumentAmountAvailable
          );
          // Documents with accumulated amount (P-Card Slip and Wire Transfer)
          const isDocumentAmountAccumulatedArray = isAccountingDocumentArray.filter(
            (ele) => ele._isDocumentAmountAccumulated
          );
          //P-Card Receipt/Invoice documents.
          const isDocumentReceiptAccumulatedArray =
            isAccountingDocumentArray.filter(
              (item) => item._isDocumentReceiptAccumulated
            );
          if (isDocumentAmountAccumulatedArray.length > 0) {
            const indexAmountSum = isDocumentAmountAccumulatedArray.reduce(
              (accumulator, document) => {
                return accumulator + parseFloat(document.indexAmount!);
              },
              0
            );
            const documentToPopulate = {
              ...isDocumentAmountAccumulatedArray[0],
              indexAmount: (
                parseFloat(watchControlTotalAmount || '0') + indexAmountSum
              ).toString(),
            };
            setToPopulateData(documentToPopulate);
            setIsAccumulatedAmount(true);
          } else if (isDocumentReceiptAccumulatedArray.length > 0) {
            const documentReceiptTotal = isDocumentReceiptAccumulatedArray.reduce(
              (acc, doc) => acc + parseFloat(doc.indexAmount!),
              0
            );
            const documentToPopulate = {
              ...isDocumentReceiptAccumulatedArray[0],
              indexAmount: '0.00',
            };
            setToPopulateData(documentToPopulate);
            setReceiptTotal(documentReceiptTotal);
          } else {
            if (isDocumentAmountNotAvailableArray.length > 0) {
              setToPopulateData(isDocumentAmountNotAvailableArray[0]);
            }
            if (isDocumentAmountAvailableArray.length > 0) {
              setToPopulateData(isDocumentAmountAvailableArray[0]);
            }
          }
        }
        const isSigningDocumentArray = selected.filter(
          (ele) =>
            ele.documentTypes?.isSigningRequired &&
            !ele.documentTypes.isAccountingDocument
        );
        if (isSigningDocumentArray.length > 0) {
          setToPopulateData(isSigningDocumentArray[0]);
        }
      } else {
        setReceiptTotal(0);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const onFiltersReload = useCallback(
      async (filter: EntityDocumentFilter | undefined) =>
        await refetch({ ...documentsVariables, filter }),
      [documentsVariables, refetch]
    );

    const checkNegativeDoc = selected.some(
      (ele) =>
        parseFloat(ele?.documentAppliedAmounts?.remainingTotal!) < 0 &&
        ele.documentTypes?.documentType !== 'Purchase Order'
    )
      ? selected.some(
        (ele) => ele.documentTypes?.documentType === 'Purchase Order'
      )
      : false;

    const attachBtnDisabled =
      selected.length === 0 || checkApproveOverage || checkNegativeDoc;

    return (
      <>
        <AttachDocumentModal
          columns={columns}
          loading={documentsLoading}
          modalWidth={1450}
          items={
            networkStatus === NetworkStatus.refetch ||
              networkStatus === NetworkStatus.setVariables
              ? undefined
              : transformedData
          }
          hasNextPage={
            documentsList?.appliableInvoiceSigningDocuments?.pageInfo.hasNextPage
          }
          attachLoading={false}
          availableDocumentTypes={documentTypeOptions}
          setOpen={setOpen}
          onSortReload={reload}
          onLoadMore={loadMore}
          onRenderRow={onRenderRow}
          onFiltersReload={onFiltersReload}
          onDocumentTypeChange={onDocumentTypeReload}
          attachDisabled={attachBtnDisabled}
          onSelectionChanged={onSelectionChanged}
          dropdownDisabled={selected.length > 0}
          onRenderItemColumn={onRenderItemColumn}
          onAttachDocuments={_onAttach}
          onCancel={() => setSelected([])}
          isNegativeDocError={checkNegativeDoc}
          CalculateBoxProps={{
            currency: watchcurrency?.isoCode!,
            remainingTotal: remainingTotal.toNumber(),
            controlTotalAmount: watchControlTotalAmount
              ? new Decimal(watchControlTotalAmount).valueOf()
              : null!,
            appliedAmount: totalApplied,
          }}
          initialDocumentMetaData={initialDocumentMetaData}
          isDraggable
        />
        <DocumentViewModal
          onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
          {...docViewState}
        />
      </>
    );
  };
