import { ApprovalTypeRowValues, UserGrants, UserSetupValues } from './types';

export const APPROVAL_GRANTS: UserGrants = {
  isAddingNonApproversAllowed: null,
  isApprovalRevokingEnabled: null,
  isApprovalTreeViewerAllowed: true,
  isApprover: null,
  isAutoApproveEnabled: null,
  isEnMasseApprovalsEnabled: null,
  isLowerTreeLevelBypassEnabled: null,
  isObserver: null,
  isRequester: null,
  isSelfApproveEnabled: null,
};
export const APPROVAL_TYPE_VALUES: ApprovalTypeRowValues = {
  id: null,
  _rowTimestamp: null,
  approvalTypeId: null,
  _isDeletable: true,
  grants: APPROVAL_GRANTS,
};

export const USER_SETUP_INITIAL_VALUES: UserSetupValues = {
  accessGroupId: null,
  communicationGroupId: null,
  dataAccessPolicyId: null,
  departmentGroupId: null,
  departmentId: null,
  departmentOccupationTemplateId: null,
  description: null,
  emailAccount: null,
  isSigninAllowed: null,
  name: null,
  roleId: null,
  tagGroupId: null,
  userGroupId: null,
  userOccupationTitleId: null,
  userRankTypeId: null,
  secureRowLevelId: null,
  userApprovalGrants: [APPROVAL_TYPE_VALUES],
};
