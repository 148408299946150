import { useMutation, useQuery } from '@apollo/client';
import { Stack, Text } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { PanelHeader } from 'common/components/PanelHeader';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import {
  DepartmentOccupationApprovalInput,
  DepartmentOccupationApprovalUpdateTypeInput,
  DepartmentOccupationChartInput,
  DepartmentOccupationChartUpdateTypeInput,
  EntityDeleteInput,
} from 'common/types/globalTypes';
import { PanelCommonProps } from 'common/types/utility';
import { loader } from 'graphql.macro';
import { differenceBy, intersection, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { ApprovalTypeView } from './ApprovalTypeView';
import { BasicForm } from './BasicForm';
import { DashboardCharts } from './DashboardCharts';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { ApprovalTypes } from './__generated__/ApprovalTypes';
import {
  UserTemplate,
  UserTemplateVariables,
} from './__generated__/UserTemplate';
import {
  UserTemplateCreate,
  UserTemplateCreateVariables,
} from './__generated__/UserTemplateCreate';
import {
  UserTemplateUpdate,
  UserTemplateUpdateVariables,
} from './__generated__/UserTemplateUpdate';
import { UserTemplatevalues } from './types';
import { getDefaultValues } from './utils';
import { validationSchema } from './validation';
import { useHistory, useParams } from 'react-router-dom';
import { CopyUserTemplate } from './UserTemplateCopy';

const USER_TEMPLATE = loader('./userTemplate.graphql');
const USER_TEMPLATES = loader('../list/UserTemplates.graphql');
const APPROVAL_TYPES = loader('./ApprovalTypes.graphql');
const USER_TEMPLATE_CREATE = loader('./UserTemplateCreate.graphql');
const USER_TEMPLATE_UPDATE = loader('./UserTemplateUpdate.graphql');

export const UserTemplateViewModal: React.FC = () => {
  const { addToast } = useToasts();
  const { userId } = useParams<{ userId: string | undefined }>();
  const history = useHistory();
  const formMethods = useForm<UserTemplatevalues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });
  const {
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
    trigger,
  } = { ...formMethods };
  const saveDisabled = !isDirty || !isValid;
  const { data: userTemplatedata, loading: userTemplateLoading } = useQuery<
    UserTemplate,
    UserTemplateVariables
  >(USER_TEMPLATE, {
    variables: {
      id: userId!,
    },
    skip: !userId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const { _isUpdatable } = {
    ...userTemplatedata?.departmentOccupationTemplate,
  };
  const { data: approvalTypesData } = useQuery<ApprovalTypes>(APPROVAL_TYPES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [userTemplateCreate] = useMutation<
    UserTemplateCreate,
    UserTemplateCreateVariables
  >(USER_TEMPLATE_CREATE, { errorPolicy: 'all' });

  const [userTemplateUpdate] = useMutation<
    UserTemplateUpdate,
    UserTemplateUpdateVariables
  >(USER_TEMPLATE_UPDATE, { errorPolicy: 'all' });

  const isNew = !userId;
  const inputEnabled = isNew || _isUpdatable;

  const panelHeading = isNew ? 'Create User Template' : 'Edit User Template';
  useEffect(() => {
    const defaultValues = getDefaultValues(userTemplatedata);
    reset(defaultValues);
  }, [userTemplatedata, reset, trigger]);
  const { _rowTimestamp, name } = {
    ...userTemplatedata?.departmentOccupationTemplate,
  };
  const onHandleSubmit = async (values: UserTemplatevalues) => {
    let createUserTemplateApprovals: DepartmentOccupationApprovalInput[] = [];
    if (isNew) {
      if (values && values.userTemplateApprovals) {
        createUserTemplateApprovals = values?.userTemplateApprovals
          ?.filter((ele) => ele.approvalTypeId)
          .map((item) => {
            return {
              approvalTypeId: Number(item.approvalTypeId),
              isApprover: item.grants.isApprover,
              isRequester: item.grants.isRequester,
              isObserver: item.grants.isObserver,
              isEnMasseApprovalsEnabled: item.grants.isEnMasseApprovalsEnabled,
              isAutoApproveEnabled: item.grants.isAutoApproveEnabled,
              isSelfApproveEnabled: item.grants.isSelfApproveEnabled,
              isApprovalTreeViewerAllowed:
                item.grants.isApprovalTreeViewerAllowed,
              isAddingNonApproversAllowed:
                item.grants.isAddingNonApproversAllowed,
              isApprovalRevokingEnabled: item.grants.isApprovalRevokingEnabled,
              isLowerTreeLevelBypassEnabled:
                item.grants.isLowerTreeLevelBypassEnabled,
            };
          });
        const createUserTemplateCharts =
          values?.userTemplateCharts &&
          (values?.userTemplateCharts
            ?.filter((chart) => chart.chartTypeId)
            .map(({ chartPosition, id, _rowTimestamp, ...chart }) => {
              return {
                chartPosition: Number(chartPosition),
                ...chart,
              };
            }) as DepartmentOccupationChartInput[]);
        const inputVariables = {
          userTemplate: {
            description: values.description,
            departmentId: values.departmentId,
            userOccupationTitleId: values.userOccupationTitleId,
            roleId: values.roleId,
            userRankTypeId: values.userRankTypeId,
            dataAccessPolicyId: values.dataAccessPolicyId,
            tagGroupId: values.tagGroupId,
            communicationGroupId: values.communicationGroupId,
            userGroupId: values.userGroupId,
            accessGroupId: values.accessGroupId,
            departmentGroupId: values.departmentGroupId,
            name: values.name!,
            isTemplateControlled: values.isTemplateControlled,
            secureRowLevelId: values.secureRowLevelId,
            isDepartmentUserGroupRequired: values.isDepartmentUserGroupRequired,
            isUserDepartmentDefault: values.isUserDepartmentDefault,
            isUserPrimaryCurrencyDefault: values.isUserPrimaryCurrencyDefault,
            isUserPrimaryBusinessUnitDefault:
              values.isUserPrimaryBusinessUnitDefault,
            userDefaultCompany: values.userDefaultCompany,
            userDefaultLocation: values.userDefaultLocation,
          },
          userTemplateApprovals: createUserTemplateApprovals,
          userTemplateCharts: createUserTemplateCharts,
        };
        const { data, errors } = await userTemplateCreate({
          variables: {
            input: inputVariables,
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: USER_TEMPLATES,
            },
          ],
        });
        if (errors?.length) {
          addToast(errors[0].message, {
            appearance: 'error',
          });
        } else {
          addToast('User template created successfully', {
            appearance: 'success',
          });
          if (data?.userTemplateCreate?.departmentOccupationTemplate?.id) {
            history.replace(
              `/account-management/userTemplates/userTemplate/${data?.userTemplateCreate?.departmentOccupationTemplate?.id}`
            );
          }
        }
      }
    } else {
      let updateUserTemplateApprovals: DepartmentOccupationApprovalUpdateTypeInput[] =
        [];
      values.userTemplateApprovals?.map((item, index) => {
        if (index !== values.userTemplateApprovals?.length! - 1) {
          // for skipping the last empty row
          if (item.id) {
            const approvalItem = {
              id: item.id,
              rowTimestamp: item._rowTimestamp,
              departmentOccupationApprovalPatch: {
                approvalTypeId: Number(item.approvalTypeId),
                isAddingNonApproversAllowed:
                  item.grants.isAddingNonApproversAllowed,
                isApprovalRevokingEnabled:
                  item.grants.isApprovalRevokingEnabled,
                isApprovalTreeViewerAllowed:
                  item.grants.isApprovalTreeViewerAllowed,
                isApprover: item.grants.isApprover,
                isAutoApproveEnabled: item.grants.isAutoApproveEnabled,
                isEnMasseApprovalsEnabled:
                  item.grants.isEnMasseApprovalsEnabled,
                isLowerTreeLevelBypassEnabled:
                  item.grants.isLowerTreeLevelBypassEnabled,
                isObserver: item.grants.isObserver,
                isRequester: item.grants.isRequester,
                isSelfApproveEnabled: item.grants.isSelfApproveEnabled,
              },
            };
            updateUserTemplateApprovals.push(approvalItem);
          } else {
            const approvalItem = {
              approvalTypeId: Number(item.approvalTypeId),
              isAddingNonApproversAllowed:
                item.grants.isAddingNonApproversAllowed,
              isApprovalRevokingEnabled: item.grants.isApprovalRevokingEnabled,
              isApprovalTreeViewerAllowed:
                item.grants.isApprovalTreeViewerAllowed,
              isApprover: item.grants.isApprover,
              isAutoApproveEnabled: item.grants.isAutoApproveEnabled,
              isEnMasseApprovalsEnabled: item.grants.isEnMasseApprovalsEnabled,
              isLowerTreeLevelBypassEnabled:
                item.grants.isLowerTreeLevelBypassEnabled,
              isObserver: item.grants.isObserver,
              isRequester: item.grants.isRequester,
              isSelfApproveEnabled: item.grants.isSelfApproveEnabled,
            };
            createUserTemplateApprovals.push(approvalItem);
          }
        }
      });
      const newCharts =
        values?.userTemplateCharts &&
        (values?.userTemplateCharts
          ?.filter((chart) => !chart.id && chart.chartTypeId !== null)
          .map(({ id, _rowTimestamp, chartPosition, ...chart }) => {
            return {
              chartPosition: Number(chartPosition),
              ...chart,
            };
          }) as DepartmentOccupationChartInput[]);
      const updateChart =
        userTemplatedata?.departmentOccupationTemplate
          ?.departmentOccupationCharts?.nodes &&
        values?.userTemplateCharts &&
        intersection(
          userTemplatedata?.departmentOccupationTemplate?.departmentOccupationCharts?.nodes.map(
            (chart) => chart.chartTypeId
          ),
          values?.userTemplateCharts
            .filter((chart) => chart.chartTypeId)
            .map((chart) => chart.chartTypeId)
        ).reduce((arr, targetId) => {
          const initialCharts =
            userTemplatedata?.departmentOccupationTemplate?.departmentOccupationCharts?.nodes!.find(
              (chart) => chart.chartTypeId === targetId
            )!;
          const { ...updatedChart } = values?.userTemplateCharts!.find(
            (chart) => chart.chartTypeId === targetId
          )!;
          const patch = Object.entries(updatedChart).reduce(
            (res, [key, val]) => {
              if (
                val !==
                initialCharts[key as keyof DepartmentOccupationChartInput]
              )
                return { ...res, [key]: val };
              return res;
            },
            {}
          );
          if (!isEmpty(patch)) console.log('updatedChart', updatedChart);
          return [
            ...arr,
            {
              id: updatedChart?.id,
              rowTimestamp: updatedChart?._rowTimestamp,
              departmentOccupationChartPatch: patch,
            },
          ];
        }, [] as DepartmentOccupationChartUpdateTypeInput[]);

      const deletedChartRows = differenceBy(
        userTemplatedata?.departmentOccupationTemplate
          ?.departmentOccupationCharts?.nodes,
        values?.userTemplateCharts || [],
        'id'
      ).map((chart) => ({
        id: chart.id!,
        rowTimestamp: chart._rowTimestamp!,
      })) as EntityDeleteInput[];

      const deletedApprovalRows = differenceBy(
        userTemplatedata?.departmentOccupationTemplate
          ?.departmentOccupationApprovals?.nodes,
        values?.userTemplateApprovals || [],
        'id'
      ).map((approval) => ({
        id: approval.id!,
        rowTimestamp: approval._rowTimestamp!,
      })) as EntityDeleteInput[];

      const { errors } = await userTemplateUpdate({
        variables: {
          input: {
            id: userTemplatedata?.departmentOccupationTemplate?.id!,
            rowTimestamp:
              userTemplatedata?.departmentOccupationTemplate?._rowTimestamp!,
            userTemplatePatch: {
              accessGroupId: values?.accessGroupId!,
              description: values?.description,
              communicationGroupId: values.communicationGroupId!,
              dataAccessPolicyId: values.dataAccessPolicyId!,
              departmentGroupId: values.departmentGroupId!,
              departmentId: values.departmentId!,
              name: values.name!,
              roleId: values.roleId!,
              tagGroupId: values.tagGroupId!,
              userGroupId: values.userGroupId!,
              userOccupationTitleId: values.userOccupationTitleId!,
              secureRowLevelId: values.secureRowLevelId!,
              userRankTypeId: values.userRankTypeId!,
              isTemplateControlled: values.isTemplateControlled,
              isDepartmentUserGroupRequired:
                values.isDepartmentUserGroupRequired,
              isUserDepartmentDefault: values.isUserDepartmentDefault,
              isUserPrimaryCurrencyDefault: values.isUserPrimaryCurrencyDefault,
              isUserPrimaryBusinessUnitDefault:
                values.isUserPrimaryBusinessUnitDefault,
              userDefaultCompany: values.userDefaultCompany,
              userDefaultLocation: values.userDefaultLocation,
            },
            userTemplateApprovalCreate: createUserTemplateApprovals,
            userTemplateApprovalUpdate: updateUserTemplateApprovals,
            userTemplateApprovalDelete: deletedApprovalRows,
            userTemplateChartCreate: newCharts,
            userTemplateChartUpdate: updateChart,
            userTemplateChartDelete: deletedChartRows,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: USER_TEMPLATE,
            variables: {
              id: userId!,
            },
          },
        ],
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('User template edited successfully', {
          appearance: 'success',
        });
      }
    }
  };
  const _onRenderHeader = () => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/account-management/userTemplates');
        }}
      >
        <Stack horizontal grow verticalAlign="center" tokens={{ childrenGap: 20 }}>
          <Text variant="xLarge">{panelHeading}</Text>
          <UnsavedIndicator visible={!isNew && isDirty} />
        </Stack>
        {!isNew && (
          <Stack horizontal verticalAlign="center">
            <CopyUserTemplate
              id={userId!}
              rowTimestamp={_rowTimestamp}
              name={name!}
            />
          </Stack>
        )}
      </PanelHeader>
    );
  };
  const _onRenderFooter = () => {
    return (
      <FooterActionBar
        disabled={
          userTemplateLoading || {
            save: saveDisabled,
          }
        }
        onCancel={() => {
          history.replace('/account-management/userTemplates');
        }}
        onSave={handleSubmit(onHandleSubmit)}
        hideCreateButton={true}
      />
    );
  };

  return (
    <DraggablePanel
      {...PanelCommonProps}
      initialWidth={1200}
      minWidth={1200}
      isBlocking={false}
      onRenderHeader={_onRenderHeader}
      onRenderFooter={_onRenderFooter}
      isOpen={true}
      isLightDismiss
    >
      {userTemplateLoading ? (
        <ShimmerView />
      ) : (
        <Stack>
          <FormProvider {...formMethods}>
            <BasicForm isUpdatable={!!inputEnabled} />
            <ApprovalTypeView
              approvalTypes={approvalTypesData?.approvalTypes?.nodes || []}
              isUpdatable={!!inputEnabled}
            />
            <DashboardCharts isUpdatable={!!inputEnabled} />
          </FormProvider>
        </Stack>
      )}
    </DraggablePanel>
  );
};
