import { Stack } from '@fluentui/react';
import { useStyles } from './index.styles';
import { DocumentList } from 'common/components/DocumentList';
import React from 'react';
import { FileListArray } from '..';
import { getWindowDimensions } from 'common/utils/columnUtilities';
import { VIEW_DOC_MODAL_WIDTH } from 'common/components/DocumentList/DocumentViewModal';
import { columns } from './column.data';
import { attachedDocumentsData } from '../AttachDocumentsForm';
import { DocumentMetaForm } from 'common/components/DocumentList/EditDocumentModal';

interface TemporaryDocumentListProps {
  isNew: boolean;
  fileListArray: FileListArray[];
  attachedDocuments: attachedDocumentsData | undefined;
  setFileListArray: (files: FileListArray[]) => void;
  controlTotalAmount: string | null;
  receiptTotal: string | null;
  onReceiptTotalChange: (value: string, documentId: string) => void;
  onUpdatedDataReceived: (docs: attachedDocumentsData | undefined) => void;
}

export const TemporaryDocumentList: React.FC<TemporaryDocumentListProps> = ({
  isNew,
  fileListArray,
  attachedDocuments,
  setFileListArray,
  controlTotalAmount,
  receiptTotal,
  onReceiptTotalChange,
  onUpdatedDataReceived,
}) => {
  const { width } = getWindowDimensions();
  const documentViewPosition = width
    ? width - (1200 + VIEW_DOC_MODAL_WIDTH)
    : 50;

  const styles = useStyles();

  const removeDocument = (documentID: string) => {
    const filteredArray = fileListArray.filter(
      (item) => item.id !== documentID
    );
    if (attachedDocuments?.attachedDocuments) {
      attachedDocuments.attachedDocuments =
        attachedDocuments?.attachedDocuments.filter(
          (item) => item.id !== documentID
        );
    }
    setFileListArray(filteredArray);
  };

  const onUpdate = (doc: DocumentMetaForm) => {
    const updatedAttachedDocuments =
      attachedDocuments?.attachedDocuments.map((item) =>
        item.id === doc.id
          ? {
            ...item,
            documentAppliedAmount: doc.documentAppliedAmount!,
            documentStatusExplanation: doc.documentStatusExplanation!,
            isAppliedAmountRetired: doc.isAppliedAmountRetired!,
            isAppliedAmountOverage: doc.isAppliedAmountOverage!,
            documentRetiredAmount: doc.documentRetiredAmount!,
          }
          : item
      ) || [];
    if (updatedAttachedDocuments) {
      onUpdatedDataReceived({ attachedDocuments: updatedAttachedDocuments });
    }
  };

  if (isNew && fileListArray.length > 0) {
    return (
      <Stack className={styles.fileListContainer}>
        <DocumentList
          paddingLeft={documentViewPosition}
          isNew={isNew}
          columns={columns}
          data={fileListArray}
          isDeleteButtonVisible
          onRemoveRow={(data) => {
            removeDocument(data.id);

            //Deduct the amount value of the deleted document from the main invoice total.
            if (controlTotalAmount) {
              const deletedDocument = fileListArray.find(
                (document) => document.id === data.id
              );
              if (deletedDocument?._isDocumentReceiptAccumulated) {
                //For deleting the P-Card Slip documents.
                const newReceiptTotal = (
                  parseFloat(receiptTotal!) -
                  parseFloat(deletedDocument?.indexAmount!)
                )
                  .toFixed(2)
                  .toString();
                onReceiptTotalChange(newReceiptTotal, data.id);
              }
            }
          }}
          onDataReceive={onUpdate}
        />
      </Stack>
    );
  } else return null;
};
