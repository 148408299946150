import {
  ActionButton, DirectionalHint,
  IDropdownOption, Link, Stack,
  Text, TooltipHost
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { FilterArrayType } from 'common/components/Filters';
import { DocumentPoolFiles_documentPools_nodes } from 'common/graphql/__generated__/DocumentPoolFiles';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { ListFilters } from '../../../../Filters';
import { FolderExpand } from '../FolderExpand';
import { useStyles } from './index.styles';

type DocumentPoolItemType = DocumentPoolFiles_documentPools_nodes;

export type filterOptionProps = {
  filterTypes: FilterArrayType[];
  startsWith: boolean;
  // documentTypeOptions: IDropdownOption[];
};
interface GroupHeaderProps {
  isOpen: boolean;
  documentPool: DocumentPoolItemType;
  availableCount: number;
  historyCount: number;
  filterOptions: filterOptionProps;
  onUploadClick: () => void;
  onFilterChange: (filterOptions: filterOptionProps) => void;
  isHistory: boolean;
  onHeaderClick: (documentPool: DocumentPoolItemType) => void;
  documentTypeOptions: IDropdownOption[];
}

export const GroupHeader: React.FC<GroupHeaderProps> = ({
  isOpen,
  documentPool,
  availableCount,
  historyCount,
  onHeaderClick,
  onUploadClick,
  onFilterChange,
  isHistory,
  filterOptions,
  documentTypeOptions
}) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const [isHovered, { setFalse: onMouseLeave, setTrue: onMouseEnter }] =
    useBoolean(false);



  return (
    <Stack
      horizontal
      className={clsx(
        styles.root,
        isOpen && styles.groupSelected,
        isHovered ? styles.linkLightHover : styles.linkHover
      )}
      tokens={{ childrenGap: 50 }}
    >
      <Link
        style={{ textDecoration: 'none' }}
        onClick={() => onHeaderClick(documentPool)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <TooltipHost
          content={`History Documents: ${historyCount}`}
          hidden={isHistory}
          directionalHint={DirectionalHint.leftCenter}
          calloutProps={{ gapSpace: 4 }}
        >
          <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 20 }}>
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              style={{ width: 300 }}
              verticalAlign="center"
            >
              <FolderExpand isOpen={isOpen} />
              <Text
                nowrap
                block
                className={commonStyles.semibold}
                variant="medium"
              >
                {documentPool.name}
              </Text>
              <Text variant="medium" className={styles.groupName}>
                {`(${isHistory
                  ? isOpen
                    ? historyCount
                    : availableCount
                  : availableCount
                  })`}
              </Text>
            </Stack>

            <Stack horizontal grow={2}>
              <Text
                block
                nowrap
                variant="medium"
                className={styles.description}
              >
                {documentPool.description}
              </Text>
            </Stack>
          </Stack>
        </TooltipHost>
      </Link>

      {isOpen && (
        <Stack
          className={styles.optionsSection}
          horizontal
          tokens={{ childrenGap: 20 }}
          verticalAlign="center"
        >
          <ListFilters
            filterOptions={filterOptions}
            documentPoolId={documentPool.id}
            documentTypeOptions={documentTypeOptions}
            onFilterChange={onFilterChange}
          />
          <ActionButton
            onClick={onUploadClick}
            iconProps={{ iconName: 'ShareiOS' }}
            text="Upload"
            checked
            disabled={isHistory}
          />
        </Stack>
      )}
    </Stack>
  );
};
