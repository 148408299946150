import { PrimaryButton, Stack, Text } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { useCommonStyles } from 'common/styles';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useStyles } from './index.styles';
import { AccountingEntryValues } from 'ap/signing/accountingEntry/view/types';
import { DocumentPackage } from 'common/components/DocumentPackage';
import { LinkedTransaction } from 'ap/signing/transactionSigning/view/FormView/LinkedTransaction';
import { PdfViewModal } from 'common/components/PdfViewModal';
import { PdfDocument } from 'ap/signing/components/PdfDocument';
import { AccountingEntryInvoice } from 'ap/signing/accountingEntry/view/__generated__/AccountingEntryInvoice';
import { getWindowDimensions } from 'common/utils/columnUtilities';
import { VIEW_DOC_MODAL_WIDTH } from 'common/components/DocumentList/DocumentViewModal';
import { InvoiceTransactionTypes } from 'ap/signing/__generated__/InvoiceTransactionTypes';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DocumentPackageEmail } from 'common/components/DocumentPackageEmail/DocumentPackageEmail';
import {
  InvoiceEmailCreate,
  InvoiceEmailCreateVariables,
} from 'ap/signing/accountingEntry/view/__generated__/InvoiceEmailCreate';
import { EmailCreateValues } from 'common/components/DocumentPackageEmail/DocumentPackageEmail/DocumentPackageEmailModal/FormModal/types';
import { useToasts } from 'react-toast-notifications';
import {
  InvoiceEmailStatus,
  InvoiceEmailStatusVariables,
} from 'ap/signing/accountingEntry/view/__generated__/InvoiceEmailStatus';
import { IconState } from 'common/types/globalTypes';
import { StampOptions, StamperView } from 'common/components/StamperView';

const INVOICE_EMAIL_CREATE = loader(
  '../../../../../../../../ap/signing/accountingEntry/view/InvoiceEmailCreate.graphql'
);
const INVOICE_DETAILS = loader(
  '../../../../../../../../ap/signing/accountingEntry/view/AccountingEntryInvoice.graphql'
);
const FETCH_EMAIL_STATUS = loader(
  '../../../../../../../../ap/signing/accountingEntry/view/InvoiceEmailStatus.graphql'
);
interface HeaderProps {
  invoiceDetailsData: AccountingEntryInvoice | undefined;
  transactionTypeData: InvoiceTransactionTypes | undefined;
  toggleConfirmDialog: () => void;
  onClose: () => void;
}
export const Header: React.FC<HeaderProps> = ({
  onClose,
  invoiceDetailsData,
  toggleConfirmDialog,
  transactionTypeData,
}) => {
  const accountingEntryId = invoiceDetailsData?.invoice?.id;
  const { addToast } = useToasts();
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const {
    formState: { isDirty },
  } = useFormContext<AccountingEntryValues>();

  const { width } = getWindowDimensions();
  const documentViewPosition = width
    ? width - (1100 + VIEW_DOC_MODAL_WIDTH)
    : 50;
  const [stampData, setStampData] = useState<StampOptions>();

  const [showEmailStatus, setShowEmailStatus] = useState(false);

  const [fetchEmailStatus, { stopPolling }] = useLazyQuery<
    InvoiceEmailStatus,
    InvoiceEmailStatusVariables
  >(FETCH_EMAIL_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    pollInterval: 60000,
    onCompleted(data) {
      if (data?.invoice?._emailDocument?._sendIconState === IconState.SENT) {
        stopPolling?.();
        setShowEmailStatus(true);
      }
    },
  });
  const [invoiceEmailCreate, { loading, data }] = useMutation<
    InvoiceEmailCreate,
    InvoiceEmailCreateVariables
  >(INVOICE_EMAIL_CREATE, { errorPolicy: 'all' });
  const _onSubmitValues = async (values: EmailCreateValues) => {
    if (!!accountingEntryId) {
      const { errors } = await invoiceEmailCreate({
        variables: {
          input: {
            entityId: accountingEntryId,
            ...values,
          },
        },
        refetchQueries: [
          {
            query: INVOICE_DETAILS,
            variables: {
              id: accountingEntryId,
            },
          },
        ],
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Email sent successfully.', {
          appearance: 'success',
        });
        fetchEmailStatus({
          variables: {
            id: accountingEntryId!,
          },
        });
      }
    }
  };
  const isSuccessful = !!data?.invoiceEmailCreate;
  const showStampEntry =
    invoiceDetailsData?.invoice?._isAccountingEntry &&
    !invoiceDetailsData.invoice._isHistory &&
    invoiceDetailsData.invoice._isUpdatable;
  useEffect(() => {
    if (invoiceDetailsData) {
      const stampOptions: StampOptions = {
        _accountingStampDate: invoiceDetailsData.invoice?._accountingStampDate,
        _accountingStampTransactionReference:
          invoiceDetailsData.invoice?._accountingStampTransactionReference,
        _accountingStampUserName:
          invoiceDetailsData.invoice?._accountingStampUserName,
        _isAccountingEntryStampedComplete:
          invoiceDetailsData.invoice?._isAccountingEntryStampedComplete,
        _isTransactionCancelled:
          invoiceDetailsData.invoice?._isTransactionCancelled,
      };
      setStampData(stampOptions);
    }
  }, [invoiceDetailsData]);
  return (
    <Stack
      horizontal
      className={styles.container}
      horizontalAlign="space-between"
      tokens={{
        padding: 20,
      }}
    >
      <Stack
        grow
        horizontal
        verticalAlign="center"
        tokens={{
          childrenGap: 10,
        }}
      >
        <Text variant="xLarge">{'Accounting Entry'}</Text>
        {invoiceDetailsData?.invoice?.transactionType?.transactionType && (
          <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
            {invoiceDetailsData?.invoice?.transactionType?.transactionType}
          </Text>
        )}
        <DocumentPackage
          paddingLeft={documentViewPosition}
          documentPackageId={invoiceDetailsData?.invoice?._documentPackageId}
        />
        <DocumentPackageEmail
          emailDocument={invoiceDetailsData?.invoice?._emailDocument}
          emailDocumentsByEntityId={
            invoiceDetailsData?.invoice?.emailDocumentsByEntityId?.nodes || []
          }
          isSuccessful={isSuccessful}
          loading={loading}
          onSubmitValues={_onSubmitValues}
          // dataLoading={invoiceDetailsDataLoading}
          dataLoading={false}
          showEmailStatus={showEmailStatus}
          setShowEmailStatus={setShowEmailStatus}
        />
        <UnsavedIndicator visible={isDirty} />
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 10,
        }}
      >
        <LinkedTransaction
          _linkedInvoiceId={invoiceDetailsData?.invoice?._linkedInvoiceId!}
        />
        {stampData &&
          invoiceDetailsData?.invoice?._isAccountingEntry &&
          invoiceDetailsData?.invoice?._isHistory && (
            <StamperView invoiceDetails={stampData} />
          )}
        {showStampEntry && (
          <PrimaryButton
            iconProps={{
              iconName: 'StampSmall',
              styles: {
                root: {
                  fill: 'white',
                },
              },
            }}
            onClick={() => toggleConfirmDialog()}
            text="Stamp Entry"
          />
        )}
        <PdfViewModal visible={!!invoiceDetailsData}>
          <PdfDocument
            invoiceData={invoiceDetailsData}
            transactionTypeData={transactionTypeData}
          />
        </PdfViewModal>
      </Stack>
      <CloseButton onClick={onClose} />
    </Stack>
  );
};
