import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  ContextualMenu,
  DatePicker,
  DayOfWeek,
  IDragOptions,
  IModalStyles,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { CloseButton } from 'common/components/Buttons';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { ModalWrapper } from 'common/components/ModalWrapper';
import { SignatureOptions, SignatureView } from 'common/components/Signatures';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { UrgencyToggle } from 'common/components/UrgencySelector/UrgencyToggle';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalRequestInput,
  EntityDeleteInput,
  TravelerAddressInput,
  TravelerAddressUpdateTypeInput,
  TravelerDietaryRequirementInput,
  TravelerDietaryRequirementUpdateTypeInput,
  TravelerPatch,
  TravelerPolicyAllowanceInput,
  TravelerPolicyAllowanceUpdateTypeInput,
  UploadStatusType,
} from 'common/types/globalTypes';
import { dateFormat, onFormatDate } from 'common/utils/dateFormats';
import { Formik } from 'formik';
import { loader } from 'graphql.macro';
import { differenceBy, intersection, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  Traveler,
  TravelerVariables,
} from 'settings/account/Travelers/view/__generated__/Traveler';
import { setUserDefaults } from 'utility/cache/ui';

import {
  TravelerApprovalCreate,
  TravelerApprovalCreateVariables,
} from 'common/graphql/__generated__/TravelerApprovalCreate';
import {
  TravelerUpdate,
  TravelerUpdateVariables,
} from 'common/graphql/__generated__/TravelerUpdate';
import { ActionsMenu } from 'settings/account/Travelers/view/ActionMenu';
import { ApprovalHistory } from 'settings/account/Travelers/view/ApprovalHistory';
import {
  BasicForm,
  SeatPreferenceOptions,
} from 'settings/account/Travelers/view/BasicForm';
import { validationSchema } from 'settings/account/Travelers/view/TravelerValidation';
import { TravelerCommonData } from 'settings/account/Travelers/view/__generated__/TravelerCommonData';
import {
  DIETARY_REQUIREMENTS_INITIAL_VALUES,
  TRAVELER_INITIAL_VALUES,
} from 'settings/account/Travelers/view/constants';
import {
  AddressRowValues,
  DietaryRequirementsRowValues,
  TravelerValues,
} from 'settings/account/Travelers/view/types';
import { useStyles } from './index.styles';

const TRAVELER_COMMON_DATA = loader(
  '../../../../../settings/account/Travelers/view/TravelerCommonData.graphql'
);

const TRAVELER_UPDATE = loader(
  '../../../../../common/graphql/TravelerUpdate.graphql'
);
const TRAVELER = loader(
  '../../../../../settings/account/Travelers/view/Traveler.graphql'
);

const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);

const TRAVELER_APPROVAL_CREATE = loader(
  '../../../../../common/graphql/TravelerApprovalCreate.graphql'
);

const CONFIRM_REQUEST_DIALOG_TITLE =
  'Are you sure you want to request this traveler for approval?';
const CONFIRM_REQUEST_DIALOG_SUBTEXT =
  'Your traveler will be requested for approval.';
const FINALIZED_BY_DATE_TOOLTIP =
  'Automatically promotes to urgent notifications when not approved by this date';

interface TravelerModalProps {
  setOpen: (open: boolean) => void;
  travelerData: Traveler | undefined;
}

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
};

const modalStyles: Partial<IModalStyles> = {
  scrollableContent: {
    overflowY: 'visible',
  },
};

export const TravelerModal: React.FC<TravelerModalProps> = ({
  setOpen,
  travelerData,
}) => {
  const { addToast, updateToast } = useToasts();
  const { id: travelerId } = { ...travelerData?.traveler };
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const userDefaults = useReactiveVar(setUserDefaults);
  const client = useApolloClient();
  const [requestComment, setRequestComment] = useState<string>();
  const [requiredDate, setRequiredDate] = React.useState<string | undefined>();
  const [urgencyLevel, setUrgencyLevel] = React.useState<number>(1);

  const [hideConfirmRequestDialog, { toggle: toggleConfirmDialog }] =
    useBoolean(true);

  const heading: string = 'Edit Traveler';

  const { data: travelerCommonData, loading: travelerCommonDataLoading } =
    useQuery<TravelerCommonData>(TRAVELER_COMMON_DATA, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });
  const [updateTraveler, { loading: loadingTravelerUpdate }] = useMutation<
    TravelerUpdate,
    TravelerUpdateVariables
  >(TRAVELER_UPDATE, { errorPolicy: 'all' });

  const [travelerApprovalCreate, { loading: travelerApprovalLoading }] =
    useMutation<TravelerApprovalCreate, TravelerApprovalCreateVariables>(
      TRAVELER_APPROVAL_CREATE,
      {
        errorPolicy: 'all',
        onCompleted: () => {
          setUrgencyLevel(1);
        },
      }
    );

  let panelTravelerStatus: string = '(Draft)';
  if (travelerData)
    panelTravelerStatus = `(${travelerData.traveler?.statusType?.statusType})`;
  else panelTravelerStatus = '';
  let initialValues: TravelerValues = TRAVELER_INITIAL_VALUES;
  if (travelerData?.traveler) {
    initialValues = {
      ...travelerData.traveler!,
      seatPreference: travelerData.traveler?.isIsleSeatPreferred
        ? SeatPreferenceOptions.Isle
        : travelerData.traveler?.isWindowSeatPreferred
        ? SeatPreferenceOptions.Window
        : '',
      travelerAddressesByTravelerId: [
        ...travelerData?.traveler?.travelerAddressesByTravelerId?.nodes,
      ],
      travelerDietaryRequirementsByTravelerId:
        travelerData.traveler?.travelerDietaryRequirementsByTravelerId?.nodes
          ?.length > 0
          ? [
              ...travelerData.traveler?.travelerDietaryRequirementsByTravelerId?.nodes.map(
                (item) => ({
                  id: item.id,
                  _rowTimestamp: item._rowTimestamp,
                  _isDeletable: item._isDeletable,
                  description: item.description,
                  dietaryRequirementId: item.dietaryRequirement?.id!,
                  isDescriptionRequired:
                    item.dietaryRequirement?.isDescriptionRequired!,
                })
              ),
            ]
          : [],
      travelerPolicyAllowancesByTravelerId:
        travelerData.traveler?._travelPolicyAllowances?.nodes.map((ele) => ({
          id: ele.id,
          expenditureType: ele.expenditureType,
          allowanceOverrideAmount: ele.allowanceOverrideAmount,
          _allowanceDescription: ele._allowanceDescription,
          isAllowanceCustomizable: ele.isAllowanceCustomizable,
          _travelerPolicyAllowanceId: ele._travelerPolicyAllowanceId,
          _travelerPolicyAllowanceRowTimestamp:
            ele._travelerPolicyAllowanceRowTimestamp,
          isChecked: ele._travelerPolicyAllowanceId ? true : false,
        })) || [],
    };
  }

  let insertEmptyDietaryRequirementsRow: boolean = false;
  if (
    travelerData?.traveler?.travelerDietaryRequirementsByTravelerId?.nodes &&
    travelerCommonData?.dietaryRequirements?.nodes
  ) {
    insertEmptyDietaryRequirementsRow =
      travelerCommonData?.dietaryRequirements?.nodes?.length >
      travelerData.traveler?.travelerDietaryRequirementsByTravelerId?.nodes
        ?.length;
  }
  if (insertEmptyDietaryRequirementsRow) {
    initialValues = {
      ...initialValues,
      travelerDietaryRequirementsByTravelerId: [
        ...initialValues.travelerDietaryRequirementsByTravelerId!,
        ...[DIETARY_REQUIREMENTS_INITIAL_VALUES],
      ],
    };
  }

  const handleSubmit = async (values: TravelerValues) => {
    const {
      seatPreference,
      travelerAddressesByTravelerId,
      travelerPolicyAllowancesByTravelerId,
      travelerDietaryRequirementsByTravelerId,
      travelerCompanionTickets,
      ...restTravelerFields
    } = values;

    const updatedTravelerFields = {
      ...restTravelerFields,
      isIsleSeatPreferred:
        seatPreference === SeatPreferenceOptions.Isle ? true : false,
      isWindowSeatPreferred:
        seatPreference === SeatPreferenceOptions.Window ? true : false,
      travelerCompanionTickets: Number(travelerCompanionTickets),
    };

    const travelerPatch: TravelerPatch = Object.entries(
      updatedTravelerFields
    ).reduce((res, [key, val]) => {
      if (val !== initialValues[key as keyof TravelerValues]) {
        return { ...res, [key]: val };
      }
      return res;
    }, {});

    //Array for new Addresses
    const newAddresses =
      travelerAddressesByTravelerId &&
      (travelerAddressesByTravelerId
        ?.filter((addr) => !addr.id && addr.addressLine1 !== null)
        .map(
          ({ id, _rowTimestamp, ...addr }) => addr
        ) as TravelerAddressInput[]);

    //Array for deleted Addresses
    const deletedAddresses =
      initialValues.travelerAddressesByTravelerId &&
      (differenceBy(
        initialValues.travelerAddressesByTravelerId,
        travelerAddressesByTravelerId || [],
        'id'
      ).map((addr) => ({
        id: addr.id!,
        rowTimestamp: addr._rowTimestamp!,
      })) as EntityDeleteInput[]);
    //Array for updated Addresses
    const updatedAddresses =
      initialValues.travelerAddressesByTravelerId &&
      travelerAddressesByTravelerId &&
      intersection(
        initialValues.travelerAddressesByTravelerId.map((addr) => addr.id),
        travelerAddressesByTravelerId
          .filter((addr) => addr.id)
          .map((addr) => addr.id)
      ).reduce((arr, targetId) => {
        const initialAddress =
          initialValues.travelerAddressesByTravelerId!.find(
            (addr) => addr.id === targetId
          )!;
        const { id, _rowTimestamp, ...updatedAddress } =
          travelerAddressesByTravelerId!.find((addr) => addr.id === targetId)!;
        const patch = Object.entries(updatedAddress).reduce(
          (res, [key, val]) => {
            if (val !== initialAddress[key as keyof AddressRowValues])
              return { ...res, [key]: val };
            return res;
          },
          {}
        );
        if (!isEmpty(patch))
          return [
            ...arr,
            {
              id,
              rowTimestamp: _rowTimestamp,
              travelerAddressPatch: patch,
            },
          ];
        return arr;
      }, [] as TravelerAddressUpdateTypeInput[]);

    //Array for new Traveler Policy Allowances
    const newTravelerPolicyAllowances =
      travelerPolicyAllowancesByTravelerId &&
      (travelerPolicyAllowancesByTravelerId
        ?.filter(
          (allowance) =>
            !allowance._travelerPolicyAllowanceId && allowance.isChecked
        )
        .map(
          (ele) =>
            ({
              travelPolicyAllowanceId: ele.id,
              allowanceOverrideAmount: ele.allowanceOverrideAmount,
            } as TravelerPolicyAllowanceInput)
        ) as TravelerPolicyAllowanceInput[]);

    //Array for deleted Traveler Policy Allowances
    const deletedTravelerPolicyAllowances =
      travelerPolicyAllowancesByTravelerId &&
      (travelerPolicyAllowancesByTravelerId
        .filter(
          (ele) =>
            ele.isChecked === false && ele._travelerPolicyAllowanceId !== null
        )
        .map((item) => ({
          id: item._travelerPolicyAllowanceId!,
          rowTimestamp: item._travelerPolicyAllowanceRowTimestamp!,
        })) as EntityDeleteInput[]);

    //Array for updated Traveler Policy Allowances
    const updatedTravelerPolicyAllowances =
      initialValues.travelerPolicyAllowancesByTravelerId &&
      travelerPolicyAllowancesByTravelerId &&
      travelerPolicyAllowancesByTravelerId
        .filter(
          ({
            allowanceOverrideAmount: overrideAmount1,
            _travelerPolicyAllowanceId,
          }) =>
            !initialValues?.travelerPolicyAllowancesByTravelerId?.some(
              ({ allowanceOverrideAmount: overrideAmount2 }) =>
                overrideAmount2 === overrideAmount1
            ) && _travelerPolicyAllowanceId !== null
        )
        .map(
          (ele) =>
            ({
              id: ele._travelerPolicyAllowanceId,
              rowTimestamp: ele._travelerPolicyAllowanceRowTimestamp,
              travelerPolicyAllowancePatch: {
                travelPolicyAllowanceId: ele.id,
                allowanceOverrideAmount: ele.allowanceOverrideAmount,
              },
            } as TravelerPolicyAllowanceUpdateTypeInput)
        );

    //Array for new Dietary Requirements

    const newDietaryRequirements =
      travelerDietaryRequirementsByTravelerId &&
      travelerDietaryRequirementsByTravelerId
        ?.filter(
          (item) => item.dietaryRequirementId !== null && item.id === null
        )
        ?.map(
          ({
            id,
            _rowTimestamp,
            _isDeletable,
            isDescriptionRequired,
            ...requirement
          }) => requirement as TravelerDietaryRequirementInput
        );

    //Array for deleted Dietary Requirements
    const deletedDietaryRequirements =
      initialValues.travelerDietaryRequirementsByTravelerId &&
      (differenceBy(
        initialValues.travelerDietaryRequirementsByTravelerId,
        travelerDietaryRequirementsByTravelerId || [],
        'id'
      ).map((addr) => ({
        id: addr.id!,
        rowTimestamp: addr._rowTimestamp!,
      })) as EntityDeleteInput[]);

    //Array for updated DietaryRequirements
    const updatedDietaryRequirements =
      initialValues.travelerDietaryRequirementsByTravelerId &&
      travelerDietaryRequirementsByTravelerId &&
      intersection(
        initialValues.travelerDietaryRequirementsByTravelerId.map(
          (requirement) => requirement.id
        ),
        travelerDietaryRequirementsByTravelerId
          .filter((requirement) => requirement.id)
          .map((requirement) => requirement.id)
      ).reduce((arr, targetId) => {
        const initialDietaryRequirements =
          initialValues.travelerDietaryRequirementsByTravelerId!.find(
            (requirement) => requirement.id === targetId
          )!;
        const {
          id,
          _rowTimestamp,
          _isDeletable,
          isDescriptionRequired,
          ...updatedRequirements
        } = travelerDietaryRequirementsByTravelerId!.find(
          (requirement) => requirement.id === targetId
        )!;

        const patch = Object.entries(updatedRequirements).reduce(
          (res, [key, val]) => {
            if (
              val !==
              initialDietaryRequirements[
                key as keyof DietaryRequirementsRowValues
              ]
            )
              return { ...res, [key]: val };
            return res;
          },
          {}
        );
        if (!isEmpty(patch))
          return [
            ...arr,
            {
              id,
              rowTimestamp: _rowTimestamp,
              travelerDietaryRequirementPatch: patch,
            },
          ];
        return arr;
      }, [] as TravelerDietaryRequirementUpdateTypeInput[]);

    const { errors } = await updateTraveler({
      variables: {
        input: {
          id: travelerId!,
          rowTimestamp: travelerData?.traveler?._rowTimestamp!,
          travelerPatch: !isEmpty(travelerPatch) ? travelerPatch : undefined,
          travelerAddressesCreate:
            newAddresses && newAddresses.length > 0 ? newAddresses : undefined,
          travelerAddressesUpdate:
            updatedAddresses && updatedAddresses.length > 0
              ? updatedAddresses
              : undefined,
          travelerAddressesDelete:
            deletedAddresses && deletedAddresses.length > 0
              ? deletedAddresses
              : undefined,
          travelerPolicyAllowancesCreate:
            newTravelerPolicyAllowances &&
            newTravelerPolicyAllowances.length > 0
              ? newTravelerPolicyAllowances
              : undefined,
          travelerPolicyAllowancesDelete:
            deletedTravelerPolicyAllowances &&
            deletedTravelerPolicyAllowances.length > 0
              ? deletedTravelerPolicyAllowances
              : undefined,
          travelerPolicyAllowancesUpdate:
            updatedTravelerPolicyAllowances &&
            updatedTravelerPolicyAllowances.length > 0
              ? updatedTravelerPolicyAllowances
              : undefined,
          travelerDietaryRequirementsCreate:
            newDietaryRequirements && newDietaryRequirements.length > 0
              ? newDietaryRequirements
              : undefined,
          travelerDietaryRequirementsDelete:
            deletedDietaryRequirements && deletedDietaryRequirements.length > 0
              ? deletedDietaryRequirements
              : undefined,
          travelerDietaryRequirementsUpdate:
            updatedDietaryRequirements && updatedDietaryRequirements.length > 0
              ? updatedDietaryRequirements
              : undefined,
        },
      },
      update: (cache, { data }) => {
        const cacheData = cache.readQuery<Traveler, TravelerVariables>({
          query: TRAVELER,
          variables: {
            id: travelerId!,
          },
        });
        const updatedData: Traveler = {
          traveler: {
            ...cacheData?.traveler!,
            ...data?.travelerUpdate?.traveler!,
          },
        };
        cache.writeQuery<Traveler, TravelerVariables>({
          query: TRAVELER,
          variables: {
            id: travelerId!,
          },
          data: updatedData!,
        });
      },
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      addToast('Traveler edited successfully', {
        appearance: 'success',
      });
    }
  };

  const isLoading = loadingTravelerUpdate || travelerApprovalLoading;

  const { _isUpdatable, _isUpdateTravelerOverride } = {
    ...travelerData?.traveler,
  };

  const isUpdatable = !!_isUpdatable;
  const isDisabled = travelerData
    ? !travelerData.traveler?._isUpdatable
    : false;

  const signatureData: SignatureOptions[] =
    travelerData?.traveler?.approvalHistorySignaturesByEntityId.nodes.map(
      (item) =>
        ({
          actionDate: item.actionDate,
          additionalInformation: item.additionalInformation,
          digitalSignature: item.digitalSignature,
          id: item.id,
          isApproved: item.isApproved,
          isRejected: item.isRejected,
          isRequested: item.isRequested,
          userName: item.userName,
          entityDocumentId: item.entityDocumentId,
          userOccupationTitle: item.userOccupationTitle,
          userSignatureDocument: {
            _downloadLink: item.userSignatureDocument?._downloadLink,
            fileIndexInformation:
              item.userSignatureDocument?.fileIndexInformation,
            fileReference: item.userSignatureDocument?.fileReference,
            iconType: item.userSignatureDocument?.iconType,
            id: item.userSignatureDocument?.id,
          },
        } as SignatureOptions)
    ) || [];

  return (
    <>
      <Formik<TravelerValues>
        enableReinitialize
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, dirty, resetForm, errors }) => {
          return (
            <ModalWrapper
              isOpen
              isBlocking
              onDismiss={() => setOpen(false)}
              dragOptions={DragOptions}
              styles={modalStyles}
              containerClassName={styles.modalContainer}
            >
              <Stack
                horizontal
                horizontalAlign="space-between"
                className={styles.headerContainer}
              >
                <Stack
                  horizontal
                  tokens={{
                    childrenGap: 10,
                  }}
                >
                  <Text variant="xLarge">{heading}</Text>
                  <UnsavedIndicator visible={dirty} />
                </Stack>
                <Stack
                  horizontal
                  tokens={{
                    childrenGap: 10,
                  }}
                  verticalAlign="center"
                >
                  <Text
                    variant="mediumPlus"
                    className={commonStyles.colorThemePrimary}
                  >
                    {panelTravelerStatus}
                  </Text>
                  <CloseButton onClick={() => setOpen(false)} />
                </Stack>
              </Stack>
              <Separator />
              <Stack className={styles.fixedHeightContainer}>
                <Stack tokens={{ padding: '20px 0px 0px 0px' }}>
                  {travelerData?.traveler && (
                    <ActionsMenu
                      secureRowLevels={travelerCommonData?.secureRowLevels!}
                      traveler={travelerData.traveler}
                      onUpload={async (fileSelected, document, toastId) => {
                        const observer = client.subscribe({
                          query: DOCUMENT_UPLOAD_STATUS,
                          variables: {
                            documentId: document.document._documentFileId!,
                          },
                        });
                        const subscription = observer.subscribe((data) => {
                          const subscribedData =
                            data.data as OnDocumentUploadStatus;

                          const { status, document } = {
                            ...subscribedData.documentUploadStatus,
                          };

                          if (!document) {
                            if (status.type === UploadStatusType.VALIDATING) {
                              updateToast(toastId!, {
                                content: status.message
                                  ? `Validating files ${fileSelected.name} - ${status.message}`
                                  : `Validating files ${fileSelected.name}`,
                                appearance: 'info',
                                autoDismiss: false,
                              });
                            } else if (
                              status.type === UploadStatusType.EXTRACTING
                            ) {
                              updateToast(toastId!, {
                                content: status.message
                                  ? `Extracting data from ${fileSelected.name} - ${status.message}`
                                  : `Extracting data from ${fileSelected.name}`,
                                appearance: 'info',
                                autoDismiss: false,
                              });
                            } else if (
                              status.type === UploadStatusType.FAILURE
                            ) {
                              subscription.unsubscribe();
                              updateToast(toastId!, {
                                content: status.message
                                  ? `Upload of ${fileSelected.name} failed - ${status.message}`
                                  : `Upload of ${fileSelected.name} failed`,
                                appearance: 'error',
                                autoDismiss: true,
                              });
                            } else if (
                              status.type === UploadStatusType.WARNING
                            ) {
                              subscription.unsubscribe();
                              updateToast(toastId!, {
                                content: status.message
                                  ? `Warning for file ${fileSelected.name}: ${status.message}`
                                  : `Warning for file ${fileSelected.name}`,
                                appearance: 'warning',
                                autoDismiss: true,
                              });
                            }
                          } else {
                            subscription.unsubscribe();
                            updateToast(toastId!, {
                              content: status.message
                                ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                                : `Successfully uploaded ${fileSelected.name}`,
                              appearance: 'success',
                              autoDismiss: true,
                            });

                            const cacheData = client.readQuery<
                              Traveler,
                              TravelerVariables
                            >({
                              query: TRAVELER,
                              variables: {
                                id: travelerId!,
                              },
                            });
                            if (cacheData) {
                              const updatedData: Traveler = {
                                traveler: {
                                  ...cacheData?.traveler!,
                                  entityDocumentsByEntityId: {
                                    ...cacheData?.traveler
                                      ?.entityDocumentsByEntityId,
                                    nodes: [
                                      document!,
                                      ...cacheData?.traveler
                                        ?.entityDocumentsByEntityId.nodes!,
                                    ],
                                  },
                                },
                              };
                              client.writeQuery<Traveler, TravelerVariables>({
                                query: TRAVELER,
                                data: updatedData,
                                variables: {
                                  id: travelerId!,
                                },
                              });
                            }
                          }
                        });
                      }}
                    />
                  )}
                  <Separator />
                </Stack>

                <BasicForm
                  traveler={travelerData}
                  isEdit={travelerId ? true : false}
                  userOccupationTitles={
                    travelerCommonData?.userOccupationTitles?.nodes || []
                  }
                  dietaryRequirements={
                    travelerCommonData?.dietaryRequirements?.nodes || []
                  }
                  companyDepartments={
                    travelerCommonData?.companyDepartments?.nodes || []
                  }
                  travelPolicies={
                    travelerCommonData?.travelPolicies?.nodes || []
                  }
                  travelerHosts={
                    travelerCommonData?.travelerCompanionMasters?.nodes || []
                  }
                  countries={travelerCommonData?.countries?.nodes || []}
                  travelPolicyDetails={
                    travelerData?.traveler?.travelPolicy || null
                  }
                  inputsDisabled={isDisabled}
                  isUpdatable={isUpdatable}
                  isUpdateTravelerOverride={!!_isUpdateTravelerOverride}
                />
                {travelerData?.traveler?.approvalHistorySignaturesByEntityId
                  .nodes.length! > 0 && (
                  <Stack>
                    <Separator />
                    <Stack
                      className={styles.tagsContainer}
                      tokens={{ childrenGap: 20 }}
                    >
                      <Text variant="xLarge">Signatures</Text>
                      <SignatureView signatureData={signatureData} />
                    </Stack>
                  </Stack>
                )}
                {travelerData?.traveler &&
                  travelerData?.traveler?.approvalHistoriesByEntityId?.nodes
                    .length! > 0 && (
                    <Stack className={styles.bottomMargin}>
                      <Separator />
                      <Stack
                        className={styles.tagsContainer}
                        tokens={{ childrenGap: 20 }}
                      >
                        <Text variant="xLarge">Approval History</Text>
                        <Text
                          variant="medium"
                          className={styles.requestedByText}
                        >
                          Requested By:
                        </Text>
                        <ApprovalHistory
                          data={
                            travelerData.traveler.approvalHistoriesByEntityId
                          }
                        />
                      </Stack>
                    </Stack>
                  )}
                <ConfirmDialog
                  hidden={hideConfirmRequestDialog}
                  title={CONFIRM_REQUEST_DIALOG_TITLE}
                  subText={CONFIRM_REQUEST_DIALOG_SUBTEXT}
                  onDismiss={toggleConfirmDialog}
                  minWidth={500}
                  onConfirm={async () => {
                    toggleConfirmDialog();
                    const inputVariables: ApprovalRequestInput = {
                      entityId: travelerId!,
                      rowTimestamp: travelerData?.traveler?._rowTimestamp!,
                      comments: requestComment,
                    };
                    if (requiredDate)
                      inputVariables.requiredDate = requiredDate;
                    const { errors } = await travelerApprovalCreate({
                      variables: {
                        input: {
                          entityApproval: [inputVariables],
                          urgencyLevel: urgencyLevel,
                        },
                      },
                      awaitRefetchQueries: true,
                      refetchQueries: [
                        {
                          query: TRAVELER,
                          variables: {
                            id: travelerId!,
                          },
                        },
                      ],
                    });

                    if (errors?.length)
                      addToast(errors[0].message, {
                        appearance: 'error',
                      });
                    else {
                      setRequestComment('');
                      setRequiredDate('');
                      addToast('Request sent for approval', {
                        appearance: 'success',
                      });
                    }
                  }}
                >
                  <Stack tokens={{ childrenGap: 10 }}>
                    <TextField
                      className={styles.marginT10}
                      multiline
                      rows={3}
                      value={requestComment}
                      placeholder="Please write your comment here (optional)"
                      resizable={false}
                      onChange={(_event, value) =>
                        setRequestComment(value || '')
                      }
                    />
                    <TooltipHost content={FINALIZED_BY_DATE_TOOLTIP}>
                      <DatePicker
                        minDate={new Date()}
                        firstDayOfWeek={DayOfWeek.Sunday}
                        placeholder="Finalized by date (optional)"
                        ariaLabel="Date"
                        formatDate={onFormatDate}
                        showWeekNumbers
                        firstWeekOfYear={1}
                        showMonthPickerAsOverlay
                        showGoToToday={false}
                        onSelectDate={(date) =>
                          setRequiredDate(dateFormat(date!.toString()))
                        }
                      />
                    </TooltipHost>
                    <Stack tokens={{ padding: '6px 0px 0px 0px' }}>
                      {userDefaults?._isUserUrgencyUpdateAllowed && (
                        <UrgencyToggle
                          onToggle={(data) => {
                            setUrgencyLevel(data ? 0 : 1);
                          }}
                        />
                      )}
                    </Stack>
                  </Stack>
                </ConfirmDialog>

                <Prompt
                  when={dirty && !isSubmitting}
                  message="Are you sure you want to leave your changes unsaved?"
                />
              </Stack>
              <FooterActionBar
                hideCreateButton={true}
                onCancel={() => setOpen(false)}
                disabled={
                  travelerCommonDataLoading || {
                    save: !dirty || Object.keys(errors).length > 0,
                    cancel: !dirty,
                  }
                }
                isSubmitting={isSubmitting}
                onSave={async () => {
                  await submitForm();
                }}
                isLoading={isLoading}
                children={
                  travelerData && (
                    <Stack>
                      {travelerData?.traveler?._isStagedApprovalRequest && (
                        <PrimaryButton
                          disabled={dirty}
                          className={styles.disabledButton}
                          text="Request Approval"
                          onClick={() => toggleConfirmDialog()}
                        />
                      )}
                    </Stack>
                  )
                }
              />
            </ModalWrapper>
          );
        }}
      </Formik>
    </>
  );
};
