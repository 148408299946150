import { Separator, Stack, Text } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useStyles } from './index.styles';
import { DataView } from '../DataView';
import { AccountingEntryInvoice } from '../__generated__/AccountingEntryInvoice';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { TransactionLayout } from 'common/types/globalTypes';
import { LabelParams } from 'ap/signing/transactionSigning/view/FormView/BasicForm/utils';

interface BasicFormProps {
  invoiceDetails?: AccountingEntryInvoice;
  receiptTotal?: string | null;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  invoiceDetails,
  receiptTotal,
}) => {
  const style = useStyles();
  const mainData = invoiceDetails?.invoice;
  const [vendorLabelPlaceholder, setVendorLabelPlaceholder] =
    useState<string>();
  const [invoiceNumberLabelPlaceholder, setInvoiceNumberLabelPlaceholder] =
    useState<string | undefined>();
  const [invoiceDateLabelPlaceholder, setInvoiceDateLabelPlaceholder] =
    useState<string | undefined>();
  const [invoiceTotalLabelPlaceholder, setInvoiceTotalLabelPlaceholder] =
    useState<string | undefined>();
  const setFormLabelsAndPlaceholders = (param: LabelParams) => {
    setVendorLabelPlaceholder(param.vendor);
    setInvoiceNumberLabelPlaceholder(param.invoiceNumber);
    setInvoiceTotalLabelPlaceholder(param.invoiceTotal);
    setInvoiceDateLabelPlaceholder(param.invoiceDate);
  };
  const isSigningAmountDoc =
    invoiceDetails?.invoice?.transactionType?.layoutType ===
    TransactionLayout.SIGNING_AMOUNT_DOCUMENT;

  useEffect(() => {
    if (invoiceDetails?.invoice?.transactionType?.layoutType) {
      switch (invoiceDetails?.invoice?.transactionType?.layoutType) {
        case TransactionLayout.SIGNING_AMOUNT_DOCUMENT:
          return setFormLabelsAndPlaceholders({
            vendor: 'Name',
            invoiceTotal: 'Weekly Limit',
          });

        case TransactionLayout.AP_INVOICE:
          return setFormLabelsAndPlaceholders({
            vendor: 'Vendor',
            invoiceNumber: 'Invoice Number',
            invoiceDate: 'Invoice Date',
            invoiceTotal: 'Invoice Total',
          });
        case TransactionLayout.PETTY_CASH:
        case TransactionLayout.PER_DIEM:
        case TransactionLayout.PETTY_CASH_FUND:
        case TransactionLayout.PETTY_CASH_ELAPSED_DAYS:
          return setFormLabelsAndPlaceholders({
            vendor: 'Employee',
            invoiceNumber: 'Envelope Number',
            invoiceDate: 'Envelope Date',
            invoiceTotal: 'Envelope Amount',
          });
        case TransactionLayout.PR_INVOICE:
          return setFormLabelsAndPlaceholders({
            vendor: 'Payroll Company',
            invoiceNumber: 'PR Invoice Number',
            invoiceDate: 'Invoice Date',
            invoiceTotal: 'Invoice Total',
          });
        case TransactionLayout.CREDIT_CARD_INVOICE:
          return setFormLabelsAndPlaceholders({
            vendor: 'Card holder',
            invoiceNumber: 'Statement Number',
            invoiceDate: 'Date',
            invoiceTotal: 'Card Total',
          });
        case TransactionLayout.PURCHASE_ORDER:
          return setFormLabelsAndPlaceholders({
            vendor: 'Supplier',
            invoiceNumber: 'PO Number',
            invoiceDate: 'Order Date',
            invoiceTotal: 'Order Total',
          });
        case TransactionLayout.JOURNAL_VOUCHER:
          return setFormLabelsAndPlaceholders({
            vendor: 'Journal Type',
            invoiceNumber: 'Voucher Number',
            invoiceDate: 'Journal Date',
            invoiceTotal: 'Control Total (Debits)',
          });
        case TransactionLayout.TRAVEL_AUTHORIZATION:
          return setFormLabelsAndPlaceholders({
            vendor: 'Passenger(s)',
            invoiceNumber: 'TA Number',
            invoiceDate: 'Transaction Date',
            invoiceTotal: 'Trip Total',
          });
        case TransactionLayout.WIRE_TRANSFER:
          return setFormLabelsAndPlaceholders({
            vendor: 'Wire To (Name)',
            invoiceNumber: 'Reference Number',
            invoiceDate: 'Wire Date',
            invoiceTotal: 'Wire Total',
          });
        case TransactionLayout.PAYMENT_REQUEST:
          return setFormLabelsAndPlaceholders({
            vendor: 'Pay To (Name)',
            invoiceNumber: 'Reference Number',
            invoiceDate: 'Payment Date',
            invoiceTotal: 'Payment Total',
          });
        case TransactionLayout.PAYMENT:
          return setFormLabelsAndPlaceholders({
            vendor: 'Pay To (Name)',
            invoiceNumber: 'Reference/Cheque Number',
            invoiceDate: 'Payment Date',
            invoiceTotal: 'Payment Total',
          });
        case TransactionLayout.PAY_CARD:
          return setFormLabelsAndPlaceholders({
            vendor: 'Vendor/Merchant',
            invoiceNumber: 'Reference Number',
            invoiceDate: 'Transaction Date',
            invoiceTotal: 'Card Total',
          });
        case TransactionLayout.TIME_CARD:
          return setFormLabelsAndPlaceholders({
            vendor: 'Employee',
            invoiceNumber: 'T/C Number',
            invoiceDate: 'W/E Date',
            invoiceTotal: 'Total',
          });
        default:
          return setFormLabelsAndPlaceholders({
            vendor: 'Vendor',
            invoiceNumber: 'Invoice Number',
            invoiceDate: 'Invoice Date',
            invoiceTotal: 'Invoice Total',
          });
      }
    }
  }, [invoiceDetails]);

  const isPettyCashLayout: boolean =
    invoiceDetails?.invoice?.transactionType?.layoutType ===
    TransactionLayout.PETTY_CASH;
  const isPayrollPettyCashLayout: boolean =
    invoiceDetails?.invoice?.transactionType?.layoutType ===
    TransactionLayout.PETTY_CASH_ELAPSED_DAYS;

  return (
    <>
      <Stack
        horizontal
        tokens={{ childrenGap: 50 }}
        className={style.mainContainer}
      >
        <Stack
          grow={5}
          style={{ maxWidth: '500px' }}
          tokens={{ childrenGap: 20 }}
        >
          <DataView
            title="Transaction type"
            description={mainData?.transactionType?.transactionType || '-'}
          />
          <DataView
            title="Business Unit"
            description={mainData?.businessUnit?.name || '-'}
          />
          <DataView
            title={vendorLabelPlaceholder}
            description={mainData?.vendorReference || '-'}
          />
          <DataView title="Description" description={mainData?.description} />
          {!isSigningAmountDoc && (
            <DataView
              title={invoiceNumberLabelPlaceholder}
              description={mainData?.invoiceNumber || '-'}
            />
          )}
          {isPayrollPettyCashLayout && (
            <>
              <DataView
                isAmount
                title="Receipts Dated"
                description={
                  (mainData?.approvalTriggerDate &&
                    dateFormat(
                      dateConvertions(mainData?.approvalTriggerDate)
                    )) ||
                  '-'
                }
              />
              {mainData?._elapsedTriggerDays &&
                mainData?._elapsedTriggerDays > 0 && (
                  <Text className={style.textColor}>
                    {mainData?._elapsedTriggerDays}
                  </Text>
                )}
            </>
          )}
          {isPettyCashLayout && (
            <DataView
              isAmount
              title="Reimbursed Amount"
              description={mainData?.reimburseAmount || ''}
            />
          )}
        </Stack>
        <Separator vertical />
        <Stack
          grow={5}
          style={{ maxWidth: '300px' }}
          tokens={{ childrenGap: 20 }}
        >
          <DataView
            title="Department"
            description={mainData?.department?.name || '-'}
          />
          {!isSigningAmountDoc && (
            <DataView
              title={invoiceDateLabelPlaceholder}
              description={
                (mainData?.invoiceDate &&
                  dateFormat(dateConvertions(mainData?.invoiceDate))) ||
                '-'
              }
            />
          )}
          <DataView
            title="Accounting period"
            description={mainData?.companyCorporatePeriod?._periodYear || '-'}
          />
          <DataView
            title="Currency"
            description={
              `${mainData?.currency?.isoCode}-${mainData?.currency?.name}` ||
              '-'
            }
          />
          {receiptTotal && (
            <DataView
              title={'Receipt Total'}
              description={receiptTotal.toString() || '-'}
              isAmount
            />
          )}
          <DataView
            title={invoiceTotalLabelPlaceholder}
            description={mainData?.controlTotalAmount.toString() || '-'}
            isAmount
          />
          {isPettyCashLayout && (
            <DataView
              title="Account"
              description={mainData?.reimburseAccountReference || '-'}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};
