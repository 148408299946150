import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DefaultButton,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { ContactListPicker } from 'common/components/ContactsListPicker';
import DraggablePanel from 'common/components/DraggablePanel';
import { PanelCommonProps, PANEL_COMMON_WIDTH } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useStyles } from './index.styles';
import {
  AdditionalApproverList,
  AdditionalApproverListVariables,
} from './__generated__/AdditionalApproverList';
import {
  AppendAdditionalApprovers,
  AppendAdditionalApproversVariables,
} from './__generated__/AppendAdditionalApprovers';
const ADDITIONAL_APPROVERS = loader('./AdditionalApproverList.graphql');
const ADD_APPROVERS = loader('./AppendAdditionalApprovers.graphql');

interface AddApproverModalProps {
  entityApprovalBranchId: string;
}
export const AddApproverModal: React.FC<AddApproverModalProps> = ({
  entityApprovalBranchId,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [message, setMessage] = useState<string | undefined>();
  const [selectedUsers, setselectedUsers] = useState<string[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const toggleVisibility = () => setModalVisible((prevState) => !prevState);

  const [getApprovers, { data: additionalApproversData }] = useLazyQuery<
    AdditionalApproverList,
    AdditionalApproverListVariables
  >(ADDITIONAL_APPROVERS, { fetchPolicy: 'network-only' });

  const [
    addApprovers,
    { loading: addApproverLoading, error: addApproverError },
  ] = useMutation<
    AppendAdditionalApprovers,
    AppendAdditionalApproversVariables
  >(ADD_APPROVERS);

  const getApproversMemo = useCallback(getApprovers, [entityApprovalBranchId]);

  useEffect(() => {
    if (entityApprovalBranchId !== null) {
      getApproversMemo({
        variables: { entityApprovalBranchId: entityApprovalBranchId },
      });
    }
  }, [entityApprovalBranchId, getApproversMemo]);

  const onRenderFooterContent = () => (
    <Stack>
      <ProgressIndicator progressHidden={!addApproverLoading} />
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <PrimaryButton
          disabled={selectedUsers.length === 0 || addApproverLoading}
          onClick={async () => {
            await addApprovers({
              variables: {
                input: {
                  entityApprovalBranchId: entityApprovalBranchId,
                  newApproverUserId: selectedUsers,
                  comments: message,
                },
              },
            });
            getApprovers({
              variables: { entityApprovalBranchId: entityApprovalBranchId },
            });
            setselectedUsers([]);
            setMessage('');
            if (addApproverError)
              addToast('Failed while adding approver', {
                appearance: 'error',
              });
            else {
              toggleVisibility();
              addToast('Approver successfully added', {
                appearance: 'success',
              });
            }
          }}
          text="Add approver"
        >
          Save
        </PrimaryButton>
        <DefaultButton onClick={toggleVisibility} text="cancel" />
      </Stack>
    </Stack>
  );

  const onRenderHeader = () => (
    <Stack
      horizontal
      horizontalAlign="space-between"
      className={styles.headerConatiner}
      tokens={{ childrenGap: 10, padding: 20 }}
    >
      <Text variant="xLarge">Add Approver</Text>
      <CloseButton onClick={toggleVisibility} />
    </Stack>
  );

  return (
    <>
      <DraggablePanel
        {...PanelCommonProps}
        initialWidth={PANEL_COMMON_WIDTH}
        minWidth={PANEL_COMMON_WIDTH}
        isOpen={modalVisible}
        onDismiss={toggleVisibility}
        onRenderHeader={onRenderHeader}
        onRenderFooterContent={onRenderFooterContent}
      >
        <Stack tokens={{ padding: '50px 20px', childrenGap: 20 }}>
          <Stack>
            <TextField
              multiline
              rows={4}
              value={message}
              placeholder="Write your message below"
              resizable={false}
              onChange={(_, value) => setMessage(value)}
            />
          </Stack>

          <ContactListPicker
            data={additionalApproversData?.additionalApproverList?.nodes!}
            selectedContacts={(items) => {
              setselectedUsers(items.user);
            }}
          />
        </Stack>
      </DraggablePanel>

      <PrimaryButton text="Add Approver" onClick={() => toggleVisibility()} />
    </>
  );
};
