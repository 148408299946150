import { useLazyQuery, useMutation } from '@apollo/client';
import { BlockBlobClient } from '@azure/storage-blob';
import { InvoiceDetails_invoice } from 'ap/signing/transactionSigning/view/__generated__/InvoiceDetails';
import { TransactionSigningValues } from 'ap/signing/transactionSigning/view/types';
import { UploadFiles } from 'common/components/UploadFiles';
import { DocumentTypeOption } from 'common/components/UploadFiles/view';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import {
  AvailableInvoiceDocumentTypes,
  AvailableInvoiceDocumentTypesVariables,
} from '../../../__generated__/AvailableInvoiceDocumentTypes';
import {
  InvoiceUploadDocument,
  InvoiceUploadDocumentVariables,
  InvoiceUploadDocument_invoiceUploadDocument,
} from './__generated__/InvoiceUploadDocument';
const INVOICE_UPLOAD_DOCUMENT = loader('./InvoiceUploadDocument.graphql');
const AVAILABLE_DOCUMENT_TYPES = loader(
  '../../../AvailableInvoiceDocumentTypes.graphql'
);
interface UploadFormProps {
  invoice?: InvoiceDetails_invoice;
  onUpload?: (
    fileSelected: File,
    documentData: InvoiceUploadDocument_invoiceUploadDocument,
    toastId: string
  ) => void;
}
export const UploadForm: React.FC<UploadFormProps> = ({
  // availableDocumentTypes,
  invoice,
  onUpload,
}) => {
  const {
    formState: { isDirty },
    watch,
  } = useFormContext<TransactionSigningValues>();
  const transactionTypeId = watch('transactionTypeId');
  const { updateToast, addToast } = useToasts();
  const [visible, setVisible] = useState(false);
  const [uploadDocument] = useMutation<
    InvoiceUploadDocument,
    InvoiceUploadDocumentVariables
  >(INVOICE_UPLOAD_DOCUMENT);

  const [getAvailableDocumentTypes, { data: availableDocumentTypes }] =
    useLazyQuery<
      AvailableInvoiceDocumentTypes,
      AvailableInvoiceDocumentTypesVariables
    >(AVAILABLE_DOCUMENT_TYPES, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  useEffect(() => {
    if (transactionTypeId && visible)
      getAvailableDocumentTypes({
        variables: {
          transactionTypeId: transactionTypeId!,
          isDocumentUpload: true,
        },
      });
  }, [transactionTypeId, getAvailableDocumentTypes, visible]);

  const docTypeNodes =
    availableDocumentTypes?.invoiceSigningAvailableDocumentTypes?.nodes;

  const documentTypeOptions =
    docTypeNodes?.map(
      (doctype) =>
        ({
          key: doctype.id,
          text: doctype.documentType || '',
          isAccountingDocument: doctype.isAccountingDocument,
          extractionTypes: doctype.extractionTypes,
          title:
            (!invoice?.isDraft &&
              doctype.isAccountingDocument &&
              'Upload prohibited during/after the approval process') ||
            undefined,
          disabled:
            (!invoice?.isDraft && doctype.isAccountingDocument) || undefined,
        } as DocumentTypeOption)
    ) || [];

  return (
    <UploadFiles
      onVisibilityChange={setVisible}
      documentTypes={documentTypeOptions!}
      disableUpload={isDirty}
      uploadDocument={{
        uploadDocumentData: async (documentType, data, fileSelected) => {
          fileSelected.map(async (fileEntity, fileIndex) => {
            const toastId = `file.name.${fileEntity?.name}.${fileIndex}`;
            addToast(`Uploading ${fileEntity?.name}...`, {
              appearance: 'info',
              id: toastId,
              autoDismiss: false,
            });

            const uploadMutationResults = await uploadDocument({
              variables: {
                document: {
                  ...data,
                  documentTypeId: parseInt(documentType.key.toString()),
                  filename: fileEntity.name,
                },
                invoiceId: invoice?.id!,
              },
            });

            if (uploadMutationResults.errors)
              updateToast(toastId!, {
                content: `Upload of ${fileEntity.name} failed`,
                appearance: 'error',
                autoDismiss: true,
              });

            if (
              uploadMutationResults.data?.invoiceUploadDocument?.document &&
              uploadMutationResults.data.invoiceUploadDocument.document
                ._documentFileId
            ) {
              onUpload?.(
                fileEntity,
                uploadMutationResults.data?.invoiceUploadDocument,
                toastId!
              );

              const client = new BlockBlobClient(
                uploadMutationResults.data?.invoiceUploadDocument?.uploadLink
              );
              await client.uploadData(fileEntity);
            }
          });
        },
      }}
    />
  );
};
