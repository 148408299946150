import { useQuery, useReactiveVar } from '@apollo/client';
import { Checkbox, Stack, Toggle } from '@fluentui/react';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import {
  ColumnData,
  SearchBar,
  SearchBarProps,
} from 'common/components/SearchBar';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { PurchaseOrderFiltersData } from './__generated__/PurchaseOrderFiltersData';
import { useStyles } from './index.styles';
import { setPOListViewOptions } from '..';
import { globalMode } from 'utility/cache/ui';
const PURCHASE_ORDER_FILTERS_DATA = loader(
  './PurchaseOrderFiltersData.graphql'
);

interface POSearchBarProps extends SearchBarProps {
  onHintsViewToggle?: () => void;
  columns: ColumnData[];
  onFilterChange: (filterArray: FilterArrayType[]) => void;
  onToggleVisibility?: (columns: ColumnData[]) => void;
}

export const POSearchBar: React.FC<POSearchBarProps> = ({
  columns,
  onFilterChange,
  onEnterPress,
  hintsAvailable,
  onRefresh,
  searchInputPlaceholderText,
  onToggleVisibility,
  onHintsViewToggle,
  urlParams,
  onGlobalToggle,
}) => {
  const globalState = useReactiveVar(globalMode);
  const styles = useStyles();
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const POListViewOptions = useReactiveVar(setPOListViewOptions);
  const { data: purchaseOrderFiltersData } = useQuery<PurchaseOrderFiltersData>(
    PURCHASE_ORDER_FILTERS_DATA
  );

  const purchaseOrderTypeOptions = formatDropdownOptions(
    purchaseOrderFiltersData?.companyPurchaseOrderTypes?.nodes,
    {
      getKey: (item) => item.purchaseOrderTypeId || '',
      getText: (item) => item.purchaseOrderType || '',
      includeAll: true,
    }
  );

  const departmentOptions = formatDropdownOptions(
    purchaseOrderFiltersData?.companyDepartments?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: true,
    }
  );

  const businessUnitOptions = formatDropdownOptions(
    purchaseOrderFiltersData?.companyBusinessUnits?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: true,
    }
  );

  const currencyOptions = formatDropdownOptions(
    purchaseOrderFiltersData?.companyCurrencies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: true,
    }
  );

  const statusOptions = formatDropdownOptions(
    purchaseOrderFiltersData?.purchaseOrderStatusTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.statusType || '',
      includeAll: true,
    }
  );

  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    const newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    onFilterChange?.(newFilters);
    setFilterTypes(newFilters);
  };

  return (
    <SearchBar
      urlParams={urlParams}
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange?.([]);
        setFilterTypes([]);
      }}
      columns={columns}
      searchInputPlaceholderText={searchInputPlaceholderText}
      onEnterPress={onEnterPress}
      onRefresh={onRefresh}
      onGlobalToggle={onGlobalToggle}
      hintsAvailable={hintsAvailable}
      onHintsViewToggle={onHintsViewToggle}
      onToggleVisibility={onToggleVisibility}
      renderActions={
        <Stack
          className={styles.actionsWrapperStyle}
          horizontalAlign="center"
          verticalAlign="center"
          horizontal
          tokens={{ childrenGap: 25 }}
        >
          <Toggle
            label="Signing"
            disabled={globalState}
            inlineLabel
            onText="Accounting Entry"
            offText="Accounting Entry"
            onChange={(event, checked) => {
              if (event)
                setPOListViewOptions({
                  ...POListViewOptions,
                  isSigning: !checked,
                  isHistory:
                    POListViewOptions.isHistory && !checked
                      ? false
                      : POListViewOptions.isHistory,
                });
            }}
            checked={!POListViewOptions.isSigning}
            className={styles.signingAccountingToggle}
            styles={{
              label: {
                wordBreak: 'keep-all',
              },
            }}
          />

          {!POListViewOptions.isSigning && (
            <Checkbox
              // disabled={disabled}
              // styles={checkBoxStyle}
              label="History"
              checked={POListViewOptions.isHistory}
              onChange={(event, checked) => {
                if (event)
                  setPOListViewOptions({
                    ...POListViewOptions,
                    isHistory: !!checked,
                  });
              }}
            />
          )}
        </Stack>
      }
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20, padding: '0px 0px 0px 20px' }}
      >
        <DropdownFilter
          filterKey={'purchaseOrderTypeId'}
          options={purchaseOrderTypeOptions || []}
          placeholder={'Type'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'departmentId'}
          options={departmentOptions || []}
          placeholder={'Department'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'businessUnitId'}
          options={businessUnitOptions || []}
          placeholder={'Business Unit'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'currencyId'}
          options={currencyOptions || []}
          placeholder={'Currency'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="_statusTypeId"
          options={statusOptions}
          placeholder="Status"
          onOptionChange={onOptionChange}
        />
      </Stack>
    </SearchBar>
  );
};
