import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import { DocumentList } from 'common/components/DocumentList';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { PivotDisplay } from 'common/components/PivotDisplay';
import React, { useState } from 'react';
import { ApprovalMessages } from '../ApprovalMessages';
import { ApprovalNotes } from '../ApprovalNotes';
import { useStyles } from './index.styles';
import { columns } from './column.data';
import { userApproval_userApproval } from '../__generated__/userApproval';
import {
  UserEntityMessageCounts,
  UserEntityMessageCountsVariables,
} from 'common/graphql/__generated__/UserEntityMessageCounts';
import { useQuery, useReactiveVar } from '@apollo/client';
import { loader } from 'graphql.macro';
import { ApprovalPanelTypes } from 'approvals/types';
import { setUserDefaults } from 'utility/cache/ui';
import { ApprovalHistory_approvalHistory } from '../__generated__/ApprovalHistory';
import { Tags } from '../Tags';
const ENTITY_MESSAGE_COUNTS = loader(
  '../../../common/graphql/UserEntityMessageCounts.graphql'
);

enum ApprovalMenuKeys {
  attach = 'ATTACH_MENU',
  notes = 'NOTES_MENU',
  tags = 'TAGS_MENU',
  messaes = 'MESSAGES_MENU',
}

interface ApprovalMenuProps {
  data: userApproval_userApproval | ApprovalHistory_approvalHistory;
  // | undefined
  // | null;
  approvalPanelType: ApprovalPanelTypes;
}

export const MenuActions: React.FC<ApprovalMenuProps> = ({
  data,
  approvalPanelType,
}) => {
  const userDefaults = useReactiveVar(setUserDefaults);
  const [selectedKey, setSelectedKey] = React.useState<string | undefined>(
    ApprovalMenuKeys.attach
  );
  const [expandMenu, setExpandMenu] = useState(true);
  const styles = useStyles();

  const { data: userMessageCounts, refetch: userMessageCountsRefetch } =
    useQuery<UserEntityMessageCounts, UserEntityMessageCountsVariables>(
      ENTITY_MESSAGE_COUNTS,
      {
        variables: {
          entityId: data.id,
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
      }
    );

  const autoOpenFirstFile =
    !!data.entityDocumentsByEntityId.nodes?.[0]?.documentTypes
      ?._isAutoViewDocument && userDefaults?.isDocumentAutoviewEnabled === null
      ? true
      : !!userDefaults?.isDocumentAutoviewEnabled;

  return (
    <Stack className={styles.wrapper}>
      {expandMenu ? (
        <Stack>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Pivot
              selectedKey={selectedKey}
              onLinkClick={(item: PivotItem | undefined) => {
                setSelectedKey(item?.props.itemKey || ApprovalMenuKeys.attach);
              }}
            >
              {/* PROTECT */}
              <PivotItem
                itemKey={ApprovalMenuKeys.attach}
                itemIcon="Attach"
                headerText="Files"
                itemCount={data.entityDocumentsByEntityId.nodes.length || 0}
                defaultChecked={false}
                onRenderItemLink={PivotCustomRender}
              />

              <PivotItem
                itemKey={ApprovalMenuKeys.notes}
                itemIcon="Page"
                headerText="Notes"
                itemCount={data.notesByEntityId.nodes.length || 0}
                onRenderItemLink={PivotCustomRender}
              />
              <PivotItem
                itemKey={ApprovalMenuKeys.tags}
                itemIcon="Tag"
                headerText="Tags"
                itemCount={data.entityTagsByEntityId.nodes.length || 0}
                onRenderItemLink={PivotCustomRender}
              />
              <PivotItem
                itemKey={ApprovalMenuKeys.messaes}
                itemIcon="Message"
                headerText="Messages"
                itemCount={
                  userMessageCounts?.userEntityMessageCounts?.messagesSent || 0
                }
                onRenderItemLink={PivotCustomRender}
              />
            </Pivot>
            <IconButton
              onClick={() => setExpandMenu(false)}
              iconProps={{ iconName: 'ChevronUp' }}
              className={styles.iconButtonColor}
              ariaLabel="Collapse"
            />
          </Stack>
          <Stack>
            {selectedKey === ApprovalMenuKeys.attach ? (
              <Stack className={styles.paddingTop20}>
                <DocumentList
                  isNew={false}
                  columns={columns}
                  data={data.entityDocumentsByEntityId.nodes || []}
                  autoOpenFirstFile={autoOpenFirstFile}
                />
              </Stack>
            ) : selectedKey === ApprovalMenuKeys.notes ? (
              <ApprovalNotes
                availableNotes={data.notesByEntityId.nodes || []}
                approvalStatus={data.statusType?.approvalState}
                entity={data}
                approvalPanelType={approvalPanelType}
              />
            ) : selectedKey === ApprovalMenuKeys.tags ? (
              <Tags
                approvalId={data.id}
              />
            ) : selectedKey === ApprovalMenuKeys.messaes ? (
              <ApprovalMessages
                invoiceID={data.id}
                onCreateMessage={userMessageCountsRefetch}
                userMessageCounts={userMessageCounts}
              />
            ) : null}
          </Stack>
        </Stack>
      ) : (
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Stack horizontal verticalAlign="center">
            <PivotDisplay
              name="Files"
              iconName="Attach"
              count={data.entityDocumentsByEntityId.nodes.length || 0}
              onPivotClick={() => {
                setSelectedKey(ApprovalMenuKeys.attach);
                setExpandMenu(true);
              }}
            />
            <PivotDisplay
              name="Notes"
              iconName="Page"
              count={data.notesByEntityId.nodes.length || 0}
              onPivotClick={() => {
                setSelectedKey(ApprovalMenuKeys.notes);
                setExpandMenu(true);
              }}
            />
            <PivotDisplay
              name="Tags"
              iconName="Tag"
              count={data.entityTagsByEntityId.nodes.length || 0}
              onPivotClick={() => {
                setSelectedKey(ApprovalMenuKeys.tags);
                setExpandMenu(true);
              }}
            />
            <PivotDisplay
              count={userMessageCounts?.userEntityMessageCounts?.messagesSent!}
              name="Messages"
              iconName="Message"
              onPivotClick={() => {
                setSelectedKey(ApprovalMenuKeys.messaes);
                setExpandMenu(true);
              }}
            />
          </Stack>
          <IconButton
            onClick={() => setExpandMenu(true)}
            iconProps={{ iconName: 'ChevronDown' }}
            className={styles.iconButtonColor}
            ariaLabel="Expand"
          />
        </Stack>
      )}
    </Stack>
  );
};
