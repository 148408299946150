import { Link, Stack, Text } from '@fluentui/react';
import React from 'react';

interface SuccessMessageProps {
  downloadLink: string | null;
}

export const SuccessMessage: React.FC<SuccessMessageProps> = ({
  downloadLink,
}) => {
  return (
    <Stack>
      <Text style={{ color: '#006644' }}>
        Download link generated successfully! Click on the link below to start
        downloading. 
      </Text>
      <Link target="_blank" href={downloadLink!}>
        Click here
      </Link>
    </Stack>
  );
};
