import { useQuery, useReactiveVar } from '@apollo/client';
import { IconButton, Separator, Stack, Text } from '@fluentui/react';
import { ReferenceCodeList } from 'common/graphql/__generated__/ReferenceCodeList';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import { PurchaseOrderCommonData } from 'purchaseOrder/view/__generated__/PurchaseOrderCommonData';
import React, { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Trip_trip } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/__generated__/Trip';
import { TripCreateFormValues } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/types';
import { getDefaultDistribution } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/utils';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { setUserDefaults } from 'utility/cache/ui';
import { ColumnsHeader } from './ColumnHeader';
import { FormField } from './FormField';
import { getColumns } from './columns.data';
import { useStyles } from './index.styles';
import { DistributionRowTotal } from './Footer';
const COMMON_DATA = loader(
  '../../../../../../../../../../../purchaseOrder/view/PurchaseOrderCommonData.graphql'
);
const REFERENCE_CODE = loader(
  '../../../../../../../../../../../common/graphql/ReferenceCodesList.graphql'
);

interface AccountingProps {
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
  tripItem: Trip_trip | null | undefined;
  tripItemIndex: number;
  tripSectionId?: string | null | undefined;
  inputsDisabled?: boolean;
  businessUnitId: string | null;
}

export const Accounting: React.FC<AccountingProps> = ({
  travelAuthorizationData,
  tripItemIndex,
  tripSectionId,
  businessUnitId,
}) => {
  const userDefaults = useReactiveVar(setUserDefaults);
  const renderRef = useRef<boolean>(true);

  const { watch, setValue, control } = useFormContext<TripCreateFormValues>();
  const { fields, remove, append } = useFieldArray({
    name: `tripItems.${tripItemIndex}.tripDistributions`,
  });
  const tripSectionData = useWatch({
    name: `tripItems.${tripItemIndex}`,
    control
  });
  const { isSupplierBreakdownRequired } = {
    ...tripSectionData,
  }
  const { columnArray } = getColumns(userDefaults, isSupplierBreakdownRequired);

  const { data: commonData } = useQuery<PurchaseOrderCommonData>(COMMON_DATA, {
    fetchPolicy: 'cache-first',
  });

  const { data: referenceCodes } = useQuery<ReferenceCodeList>(REFERENCE_CODE, {
    fetchPolicy: 'cache-first',
  });
  const dropDownValues = {
    productionIncentivesOptions: formatDropdownOptions(
      commonData?.productionIncentives?.nodes,
      {
        getKey: (item) => item.id,
        getText: (item) => item._fullDescription!,
        includeAll: false,
      }
    ),
    tax1099T4Options: formatDropdownOptions(commonData?.tax1099T4Types?.nodes, {
      getKey: (item) => item.id,
      getText: (item) => item.tax1099T4Type!,
      includeAll: false,
    }),
    currencyOptions: formatDropdownOptions(commonData?.companyCurrencies?.nodes, {
      getKey: (item) => item.id,
      getText: (item) => item.isoCode || "",
      includeAll: false,
    }),
  };
  const styles = useStyles();
  const watchInvoiceDistributions = watch(
    `tripItems.${tripItemIndex}.tripDistributions`
  );

  const lastItem = watchInvoiceDistributions?.[fields.length - 1];
  const isValueExist = lastItem
    ? Object.entries(lastItem!).reduce((prev, [key, value]) => {
        if (
          key === 'tripSectionId' ||
          key === 'tripItemId' ||
          (key === 'referenceCode6' &&
            value === userDefaults?.referenceCode6) ||
          (key === 'referenceCode7' &&
            value === userDefaults?.referenceCode7) ||
          (key === 'projectReference' &&
            value === userDefaults?.project?.code) ||
          (key === 'accountReference' && value === userDefaults?.accountCode) ||
          (key === 'allocationPercent' &&
            value === '100' &&
            fields.length === 1)
        )
          return prev;

        const isValue = value !== null && value !== undefined;
        return isValue || prev;
      }, false)
    : false;

  useEffect(() => {
    if (isValueExist && !renderRef.current) {
      const values = getDefaultDistribution(tripSectionId!, userDefaults);
      append(values, { shouldFocus: false });
    }
    renderRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [append, isValueExist, userDefaults]);

  // useEffect(() => {
  //   if (fields.length === 1) {
  //     setValue(`purchaseOrderItemDistribution.0.allocationPercent`, '100', {
  //       shouldDirty: true,
  //       shouldTouch: false,
  //     });
  //   }
  // }, [fields.length]);

  const refCodesOptions = {
    refCode1Options: formatDropdownOptions(
      referenceCodes?.reference1Codes?.nodes,
      {
        getKey: (item) => item.referenceCode,
        getText: (item) => item.name!,
        includeAll: false,
      }
    ),
    refCode2Options: formatDropdownOptions(
      referenceCodes?.reference2Codes?.nodes,
      {
        getKey: (item) => item.referenceCode,
        getText: (item) => item.name!,
        includeAll: false,
      }
    ),
    refCode3Options: formatDropdownOptions(
      referenceCodes?.reference3Codes?.nodes,
      {
        getKey: (item) => item.referenceCode,
        getText: (item) => item.name!,
        includeAll: false,
      }
    ),
    refCode4Options: formatDropdownOptions(
      referenceCodes?.reference4Codes?.nodes,
      {
        getKey: (item) => item.referenceCode,
        getText: (item) => item.name!,
        includeAll: false,
      }
    ),
  };

  const { _isUpdatable, _isDistributionUpdateAllowed } = {
    ...travelAuthorizationData,
  };

  const isRowUpdatable = _isUpdatable ? true : _isDistributionUpdateAllowed;

  // useEffect(() => {
  //   if (!poModaReactiveProps.isNew) reset();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Stack tokens={{ padding: '30px 0px 0px 10px' }}>
      <Separator />
      <Stack tokens={{ padding: '20px 0px 20px 0px' }}>
        <Text variant="xLarge">Accounting</Text>
      </Stack>
      <Stack className={styles.scrollContainer}>
        <Stack>
          <Stack horizontal style={{ overflowX: 'scroll' }}>
            <Stack tokens={{ padding: '52px 0px', childrenGap: 3 }}>
              {fields.map((_, index) => {
                const isLastItem = fields.length - 1 !== index;
                return (
                  <Stack key={index} style={{ height: 33, width: 40 }}>
                    {isLastItem && (
                      <IconButton
                        disabled={!isRowUpdatable}
                        iconProps={{ iconName: 'Blocked2Solid' }}
                        ariaLabel="delete"
                        onClick={() => remove(index)}
                        style={{ color: 'red' }}
                      />
                    )}
                  </Stack>
                );
              })}
            </Stack>
            <Stack style={{ padding: '0px 0px 40px 0px' }}>
              <ColumnsHeader columnsArray={columnArray} />
              {fields.map((field, index) => {
                const baseField = `tripItems.${tripItemIndex}.tripDistributions.${index}`;
                return (
                  <Stack key={index} horizontal tokens={{ childrenGap: 10 }}>
                    {columnArray.map((value, key) => {
                      return (
                        <FormField
                          index={index}
                          _isUpdatable={_isUpdatable}
                          _isDistributionUpdateAllowed={
                            _isDistributionUpdateAllowed
                          }
                          businessUnitId={businessUnitId}
                          onSetAllocationDefault={() => {
                            setValue(
                              `tripItems.${tripItemIndex}.tripDistributions.${index}.allocationPercent`,
                              null
                            );
                          }}
                          travelAuthorizationData={travelAuthorizationData}
                          rowLength={fields.length}
                          dropdownValues={dropDownValues}
                          baseField={baseField}
                          columnData={value}
                          key={field.id + key}
                          item={watchInvoiceDistributions?.[index]}
                          refCodesOptions={refCodesOptions}
                          onSelectAccount={(data) => {
                            if (!!data.projectAccount) {
                              setValue(
                                `tripItems.${tripItemIndex}.tripDistributions.${index}.projectReference`,
                                data.projectAccount
                              );
                            }
                            if (!!data.setAccount) {
                              setValue(
                                `tripItems.${tripItemIndex}.tripDistributions.${index}.setReference`,
                                data.setAccount
                              );
                            }
                          }}
                        />
                      );
                    })}
                  </Stack>
                );
              })}
              <DistributionRowTotal
                columnsArray={columnArray}
                tripItemIndex={tripItemIndex}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
