import { makeStyles } from "@fluentui/react";
export const useStyles = makeStyles((theme) => ({
  formRow: {
    margin: "20px 0px"
  },
  companyNameContainer: {
    flex: 2
  },
  fieldContainer: {
    flex: 1
  },
  serviceTypeRow: {
    width: "66.66%",
    margin: "20px 0px"
  }
}));