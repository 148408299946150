import { ITextFieldProps, TextField } from '@fluentui/react';
import * as React from 'react';
import { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

export interface HookFormProps {
  name: string;
  rules?: UseControllerProps['rules'];
  defaultValue?: any;
  onTextChange?: (value: string | null) => void;
}

export const FormHookTextField: FC<HookFormProps & ITextFieldProps> = ({
  name,
  onTextChange,
  rules,
  ...props
}) => {
  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { error },
  } = useController({ name });

  return (
    <TextField
      value={value || ''}
      onChange={(_event, value) => {
        if (value) {
          if (value.replace(/\s/g, '').length) {
            onChange(value);
            onTextChange?.(value);
          }
        } else {
          onChange(null);
          onTextChange?.(null);
        }
      }} // send value to hook form
      onBlur={onBlur} // notify when input is touched
      errorMessage={error?.message?.toString()}
      componentRef={ref}
      {...props}
    />
  );
};
