import { ColumnData } from 'common/components/SearchBar';
import { ColumnActionsMode } from '@fluentui/react';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const getColumns = () => {
  const columns: ColumnData[] = [
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 250,
      maxWidth: 250,
      ...commonColumnProps,
    },
    {
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      minWidth: 250,
      maxWidth: 250,
      ...commonColumnProps,
    },
    {
      key: 'action',
      name: 'Actions',
      fieldName: 'action',
      minWidth: 50,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
      isResizable: false,
    },
  ];
  return columns;
};
