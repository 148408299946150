import { useLazyQuery, useQuery } from '@apollo/client';
import {
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IDetailsFooterProps,
  IRenderFunction,
  Link,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { getFormattedAmountValue } from 'common/components/AmountView/utils';
import { loader } from 'graphql.macro';
import { showPurchaseOrderModal } from 'purchaseOrder/view/FormView/POItems/POItemsModal';
import { PurchaseOrderCommonData } from 'purchaseOrder/view/__generated__/PurchaseOrderCommonData';
import React, { useState } from 'react';
import {
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId,
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId_nodes,
} from '../__generated__/userApproval';
import { POItemsView } from './POItemsModal';
import {
  ApprovalHistoryItem,
  ApprovalHistoryItemVariables,
} from './POItemsModal/__generated__/ApprovalHistoryItem';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { closeOpenedDocument } from 'utility/cache/ui';
import { StyledLink } from 'common/components/StyledLink';

const APPROVAL_HISTORY_ITEM = loader(
  './POItemsModal/ApprovalHistoryItem.graphql'
);
const PURCHASE_ORDER_TYPES = loader(
  '../../../purchaseOrder/view/PurchaseOrderCommonData.graphql'
);

export type ItemRow =
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId_nodes;
interface InvoiceAccountingListProps {
  data: userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId;
  onRefetch: () => void;
}

export const POItems: React.FC<InvoiceAccountingListProps> = ({
  data,
  onRefetch,
}) => {
  const styles = useStyles();
  const [selectedPOItem, setSelectedPOItem] = useState<ItemRow>();
  const { data: commonData } = useQuery<PurchaseOrderCommonData>(
    PURCHASE_ORDER_TYPES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [fetchApprovalHistoryItem, { data: approvalHistoryItemData }] =
    useLazyQuery<ApprovalHistoryItem, ApprovalHistoryItemVariables>(
      APPROVAL_HISTORY_ITEM,
      {
        onCompleted: (data) => {
          if (data) {
            const { nodes } = {
              ...data.approvalHistoryItem?.purchaseOrderItem?.purchaseOrder
                ?.purchaseOrderItems,
            };
            if (!!nodes?.length) {
              const poItem =
                data.approvalHistoryItem?.purchaseOrderItem?.purchaseOrder?.purchaseOrderItems?.nodes?.find(
                  (ele) => ele.id === selectedPOItem?.id1
                );
              showPurchaseOrderModal({
                poItem: poItem,
                isModalOpen: true,
                isNew: false,
              });
            }
          }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
      }
    );

  const purchaseOrderData = {
    ...approvalHistoryItemData?.approvalHistoryItem?.purchaseOrderItem,
  };
  const { companyPurchaseOrderTypes } = { ...commonData };
  const { purchaseOrderTypeId } = { ...purchaseOrderData.purchaseOrder };

  const companyPurchaseOrderItemType = companyPurchaseOrderTypes?.nodes?.find(
    (item) => item.purchaseOrderTypeId === purchaseOrderTypeId
  );

  const purchaseOrderItemAvailable =
    companyPurchaseOrderItemType?.companyPurchaseOrderItemTypes?.nodes;

  const showPOItemsModal = (data: ItemRow) => {
    if (data) {
      setSelectedPOItem(data);
      fetchApprovalHistoryItem({
        variables: {
          id: data.id,
        },
      });
      closeOpenedDocument(true);
    }
  };

  const renderColumn = (item?: ItemRow, _index?: number, column?: IColumn) => {
    if (!item || !column) return undefined;
    const fieldContent = item[column.fieldName as keyof ItemRow] as
      | string
      | null;
    switch (column?.key) {
      case 'itemDescription1':
        return (
          <StyledLink
            onLinkClick={() => showPOItemsModal(item)}
            linkText={fieldContent}
          />
        );
      case 'itemAmount1':
        return (
          <AmountTextView
            className={clsx(styles.amount)}
            value={fieldContent}
          />
        );
      case 'itemUnit1':
      case 'itemDescription5':
        return <Text className={clsx(styles.amount)}>{fieldContent}</Text>;
      default:
        return <Text>{fieldContent}</Text>;
    }
  };

  const renderFooterColumn = (
    _item?: ItemRow,
    _index?: number,
    column?: IColumn
  ) => {
    if (column?.key === 'itemAmount1' && data?.aggregates?.sum)
      return (
        <Text className={clsx(styles.amount, styles.amountTotal)}>
          {getFormattedAmountValue(
            Number(data.aggregates.sum.itemAmount1).toFixed(2)
          )}
        </Text>
      );

    return null;
  };

  const renderFooter: IRenderFunction<IDetailsFooterProps> = (props) => {
    if (!props || !data.aggregates?.sum?.itemAmount1) {
      return null;
    }
    return (
      <DetailsRow
        {...props}
        item={{}}
        onRenderItemColumn={renderFooterColumn}
        itemIndex={-1}
      />
    );
  };

  return (
    <Stack tokens={{ padding: '0px 0px 0px 10px' }}>
      <Text variant="xLarge">Order Items</Text>
      <DetailsList
        compact
        columns={columns}
        items={data.nodes}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        onRenderItemColumn={renderColumn}
        onRenderDetailsFooter={renderFooter}
      />
      {selectedPOItem && (
        <POItemsView
          purchaseOrderData={{
            purchaseOrder: purchaseOrderData.purchaseOrder,
          }}
          commonData={commonData}
          purchaseOrderItemAvailable={purchaseOrderItemAvailable}
          onRefetch={onRefetch}
        />
      )}
    </Stack>
  );
};
