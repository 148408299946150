import { Stack } from '@fluentui/react';
import clsx from 'clsx';
import {
  FormikDropdown,
  FormikCheckBox, FormikNumberTextField,
  FormikTextField
} from 'common/components';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ApprovalSetupCommonData
} from 'settings/account/approvalSetup/__generated__/ApprovalSetupCommonData';
import { ApprovalValues } from '../types';
import { Approval } from '../__generated__/Approval';
import { useStyles } from './index.styles';
interface BasicFormProps {
  isNew: boolean;
  approvalDetails: Approval | undefined;
  clearStates?: boolean;
  data: ApprovalSetupCommonData
}
export interface AttachedDocumentsDataType {
  documentTypeId: number | null | undefined;
  entityDocumentId: string[] | null;
}
export const BasicForm: React.FC<BasicFormProps> = ({
  isNew,
  clearStates,
  approvalDetails,
  data
}) => {
  const style = useStyles();
  const [isShowAttachDocumentButton, setIsShowAttachDocumentButton] =
    useState(false);
  const { values } = useFormikContext<ApprovalValues>();
  const approvalTypeOptions =
    data?.approvalTypes?.nodes.map((item) => ({
      key: item.id,
      text: item.approvalType || '',
    })) || [];
  const projectPhaseOptions =
    data?.approvalPhases?.nodes.map((item) => ({
      key: item.id,
      text: item.phaseName || '',
    })) || [];
  useEffect(() => {
    if (clearStates) {
      setIsShowAttachDocumentButton(false);
    }
  }, [clearStates]);

  const handleIsNewState = () => {
    if (values.approvalTypeId) {
      setIsShowAttachDocumentButton(true);
    }
  };

  const handleIsNewStateMemo = useCallback(handleIsNewState, [
    values,
    isShowAttachDocumentButton,
  ]);
  useEffect(() => {
    handleIsNewStateMemo();
  }, [handleIsNewStateMemo]);

  const isDisabled = isNew ? false : !approvalDetails?.approval?._isUpdatable;

  return (
    <>
      <Stack
        horizontal
        tokens={{ childrenGap: 50 }}
        className={style.mainContainer}
      >
        <Stack
          grow={1}
          tokens={{ childrenGap: 20 }}
          className={style.detailsContainer}
        >
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={style.flex50}>
              <FormikDropdown
                label="Approval Type"
                placeholder="Select"
                name="approvalTypeId"
                options={approvalTypeOptions || []}
                disabled={isDisabled}
                required
              />
            </Stack.Item>
            <Stack.Item className={style.flex50}>
              <FormikNumberTextField
                disabled={isDisabled}
                inputMode="decimal"
                name="priorityNumber"
                label="Priority Number"
                placeholder="Priority number"
                required
              />
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={style.flex50}>
              <FormikTextField
                disabled={isDisabled}
                name="name"
                label="Name"
                placeholder="Enter name"
                required
              />
            </Stack.Item>
            <Stack.Item className={style.flex50}>
              <FormikDropdown
                label="Project Phase"
                placeholder="Select"
                name="approvalPhaseTypeId"
                options={projectPhaseOptions || []}
                disabled={isDisabled}
              />
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={style.flex50}>
              <FormikTextField
                disabled={isDisabled}
                name="description"
                label="Description"
                placeholder="Enter description"
              />
            </Stack.Item>
            <Stack.Item className={clsx(style.flex50, style.paddingTop20)}>
              <FormikCheckBox
                disabled={isDisabled}
                label="Allow Adding Approvers?"
                boxSide="end"
                name="isAddApproversEligible"
              />
            </Stack.Item>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
