import { useReactiveVar } from '@apollo/client';
import {
  FontIcon,
  IconButton,
  Stack,
  TooltipHost,
  IColumn,
  SearchBox,
  ISearchBoxStyleProps,
  ISearchBoxStyles,
  Link,
} from '@fluentui/react';
import { SortOrder } from 'common/utils/commonTypes';
import React, { useState, useEffect, useCallback } from 'react';
import { globalMode, setGlobalSearchText } from 'utility/cache/ui';
import { useStyles } from './index.styles';
import { ShowColumns } from './ShowColumns';

/**
 * ColumnData : Custom Interface extended from existing IColumn interface.
 */
export type ColumnData = IColumn & {
  /** property to determine whether this coloumn can be toggled for visibility */
  toggleVisibility?: boolean;
  /** property to determine whether the column is visble or not*/
  isVisible?: boolean;
};

export interface SearchBarProps {
  /** @param hintsAvailable boolean value to determine if any hints are aviailable, on true hints icon will be showed */
  hintsAvailable?: boolean;
  /** @param onHintsViewToggle callback when hint icon is clicked, used to toggle the view on parent  */
  onHintsViewToggle?: () => void;
  /** @param onRefresh callback when refresh button clicked*/
  onRefresh?: () => void;
  /** @param searchInputPlaceholderText placeholder text in place of search area */
  searchInputPlaceholderText?: string;
  /** @param  onEnterPress callback when return key pressed after searching, or search area cleared*/
  onEnterPress?: (searchValue: string, globalSearch?: boolean) => void;
  /** @param columns columns data passed to support show columns */
  columns: ColumnData[];
  /** @param onToggleVisibility callback to return new columns header to be rendered */
  onToggleVisibility?: (columns: ColumnData[]) => void;
  /** @param onFilterClear callback when filter is cleared upon button click */
  onFilterClear?: () => void;
  /** @param children children component loaded, for filter implementation */
  children?: React.ReactNode;
  /** @param renderActions children component  if any extra UI needed to be rendered */
  renderActions?: React.ReactNode;
  /** @param filtersVisible Setting visibility of filters from parent component  */
  filtersVisible?: boolean;
  /**  @param urlParams Set of url params that can be used to modify the UI Construct */
  urlParams?: {
    searchParam?: string | undefined;
    searchHintParam?: number | undefined;
    sortOrderParams?: SortOrder | undefined;
    filtersParam?: string[];
  };
  isGlobalAvailable?: boolean;
  onGlobalToggle?: (value: boolean) => void;
  searchEnabled?: boolean;
  disabled?: boolean;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  onEnterPress,
  onRefresh,
  hintsAvailable = false,
  searchInputPlaceholderText = 'Search by keyword',
  columns,
  onToggleVisibility,
  onHintsViewToggle,
  onFilterClear,
  children,
  renderActions,
  urlParams,
  filtersVisible = false,
  isGlobalAvailable = true,
  onGlobalToggle,
  searchEnabled = true,
  disabled,
}) => {
  const globalState = useReactiveVar(globalMode);
  const { searchParam } = { ...urlParams };
  const styles = useStyles();
  const [searchValue, setSearchValue] = useState<string | null>(
    searchParam || null
  );
  const [showFilters, setShowFilters] = useState<boolean>(filtersVisible);

  const onEnterPressMemo = useCallback(onEnterPress!, [
    globalState,
    searchParam,
  ]);

  useEffect(() => {
    if (searchParam?.length! > 0 && globalState && searchEnabled) {
      setSearchValue(searchParam!);
      setGlobalSearchText(searchParam!);
      onEnterPressMemo?.(searchParam!, true);
    }
  }, [searchParam, globalState, onEnterPressMemo, searchEnabled]);

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      className={styles.searchContainer}
    >
      <Stack horizontal verticalAlign="center" grow={1}>
        {searchEnabled && (
          <>
            {isGlobalAvailable && (
              <Link
                disabled={disabled}
                onClick={() => {
                  globalMode(!globalState);
                  onGlobalToggle?.(!globalState);
                }}
                className={styles.textDecoder}
              >
                <Stack tokens={{ padding: '0px 10px 0px 0px' }}>
                  <TooltipHost
                    content={`Global Search ${
                      globalState ? 'Active' : 'Inactive'
                    }`}
                  >
                    <Stack
                      className={
                        globalState
                          ? styles.globalSearchStyle
                          : styles.globalSearchStyleDisable
                      }
                    >
                      <FontIcon
                        iconName={'Globe'}
                        className={
                          globalState
                            ? styles.globeStyleIcon
                            : styles.globeStyleIconDisable
                        }
                      />
                    </Stack>
                  </TooltipHost>
                </Stack>
              </Link>
            )}

            {hintsAvailable && (
              <TooltipHost content="Hints">
                <IconButton
                  disabled={disabled}
                  onClick={onHintsViewToggle}
                  className={styles.iconBlueStyles}
                  iconProps={{ iconName: 'info' }}
                />
              </TooltipHost>
            )}
            {!!searchValue?.length && (
              <TooltipHost content={'Clear'}>
                <IconButton
                  disabled={disabled}
                  onClick={() => {
                    setSearchValue('');
                    onEnterPress?.('');
                  }}
                  className={styles.iconBlueStyles}
                  iconProps={{ iconName: 'cancel' }}
                />
              </TooltipHost>
            )}
            <Stack grow={1}>
              <SearchBox
                disabled={disabled}
                value={searchValue || ''}
                placeholder={searchInputPlaceholderText}
                onChange={(_, value) => {
                  if (value?.length === 0) globalMode(false);
                  setSearchValue(value?.toString()!);
                  if (!value?.length) onEnterPress?.('', false);
                }}
                onSearch={(searchValue) => {
                  if (searchValue !== undefined) onEnterPress?.(searchValue!);
                }}
                autoComplete="off"
                styles={getSearchInputStyles}
              />
            </Stack>
          </>
        )}
      </Stack>
      <Stack verticalAlign="center" horizontal>
        <Stack horizontal tokens={{ padding: '0px 30px' }}>
          {renderActions}
          {showFilters && children}
        </Stack>
        {children && (
          <TooltipHost content={showFilters ? 'Close filters' : 'Open Filters'}>
            <IconButton
              disabled={disabled}
              className={
                showFilters
                  ? styles.iconButtonPrimaryStyles
                  : styles.iconButtonStyles
              }
              iconProps={{ iconName: showFilters ? 'ClearFilter' : 'Filter' }}
              onClick={() => {
                if (showFilters) onFilterClear?.();
                setShowFilters((prevState) => !prevState);
              }}
            />
          </TooltipHost>
        )}
        {/* Padding added to this stack to give space on both sides of this showcolumns Component */}
        <Stack tokens={{ padding: '0px 10px 0px 10px' }}>
          <ShowColumns
            disabled={disabled}
            columns={columns}
            onToggleVisibility={onToggleVisibility}
          />
        </Stack>

        <TooltipHost content={'Refresh'}>
          <IconButton
            disabled={disabled}
            onClick={() => onRefresh?.()}
            className={styles.iconButtonStyles}
            iconProps={{ iconName: 'refresh' }}
          />
        </TooltipHost>
      </Stack>
    </Stack>
  );
};

/**
 * This styles object is used to change the background color of the textfield which
 * can only be done through ITextFieldStyles and not through useStyles class
 */
function getSearchInputStyles(
  props: ISearchBoxStyleProps
): Partial<ISearchBoxStyles> {
  return {
    clearButton: { display: 'none' },
    root: {
      backgroundColor: props.theme.palette.neutralLighter,
      border: 'none',
      ':after': {
        border: 'none',
      },
      '&.is-active': {
        border: 'none',
      },
    },
  };
}
