import { Stack } from '@fluentui/react';
import {
  DropdownFilter,
  FilterArrayType
} from 'common/components/Filters';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import {
  ColumnData,
  SearchBar,
  SearchBarProps
} from 'common/components/SearchBar';
import { formatDropdownOptions } from 'common/utils';
import React, { useState } from 'react';
import { FileCabinetCommonData } from '../__generated__/FileCabinetCommonData';
interface DocumentsSearchBar extends SearchBarProps {
  onHintsViewToggle?: () => void;
  columns: ColumnData[];
  onFilterChange: (filterArray: FilterArrayType[]) => void;
  onToggleVisibility?: (columns: ColumnData[]) => void;
  toggleActions?: () => void;
  fileCabinetCommonData: FileCabinetCommonData | undefined;
}
export const DocumentsSearchBar: React.FC<DocumentsSearchBar> = ({
  columns,
  onEnterPress,
  onRefresh,
  searchInputPlaceholderText,
  onToggleVisibility,
  urlParams,
  onFilterChange,
  onGlobalToggle,
  fileCabinetCommonData,
}) => {
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const currencyOptions = formatDropdownOptions(
    fileCabinetCommonData?.currencies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.isoCode || "",
      includeAll: true,
    }
  );
  const documentTypeOptions = formatDropdownOptions(
    fileCabinetCommonData?.fileCabinetDocumentTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.documentType || "",
      includeAll: true,
    }
  );
  const poolOptions = formatDropdownOptions(
    fileCabinetCommonData?.fileCabinetPools?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name,
      includeAll: true,
    }
  );
  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    const newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    onFilterChange?.(newFilters);
    setFilterTypes(newFilters);
  };
  return (
    <SearchBar
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange([]);
        setFilterTypes([]);
      }}
      onGlobalToggle={onGlobalToggle}
      isGlobalAvailable={false}
      urlParams={urlParams}
      columns={columns}
      searchInputPlaceholderText={searchInputPlaceholderText}
      onEnterPress={onEnterPress}
      onRefresh={onRefresh}
      hintsAvailable={false}
      onToggleVisibility={onToggleVisibility}
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20, padding: '0px 0px 0px 20px' }}
      >
        <DropdownFilter
          filterKey={'indexCurrencyId'}
          options={currencyOptions}
          placeholder={'Currency'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'documentTypeId'}
          options={documentTypeOptions}
          placeholder={'Document Type'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'_documentPoolId'}
          options={poolOptions}
          placeholder={'Folder'}
          onOptionChange={onOptionChange}
        />
      </Stack>
    </SearchBar>
  );
};
