import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { globalMode } from 'utility/cache/ui';
import { AccountingEntry } from './accountingEntry/list';
import { TransactionSigning } from './transactionSigning/list';
import { ColumnData } from './components/Accounting/columns.data';
import { SortOrder } from './transactionSigning/view/FormView/AttachDocumentsForm/types';
import { useColumns } from './transactionSigning/list/columns.data';

export const Signing: React.FC = () => {
  const globalState = useReactiveVar(globalMode);
  const { columns } = useColumns();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [isSigningSearch, setSigningSearch] = React.useState(true);
  const [isHistorySearch, setIsHistorySearch] = React.useState<boolean>(false);
  const toggleSigningSearch = () => setSigningSearch((prevState) => !prevState);
  const [isSearchAllData, setIsSearchAllData] = useState(true);

  useEffect(() => {
    if (!isSigningSearch && globalState) {
      setSigningSearch(true);
      setIsHistorySearch(false);
    }
  }, [globalState, isSigningSearch]);

  return (
    <>
      {isSigningSearch ? (
        <TransactionSigning
          toggleActions={() => {
            toggleSigningSearch();
            setIsHistorySearch(false);
          }}
          onHistoryCheckbox={() => setIsHistorySearch(false)}
          isSigningSearch={isSigningSearch}
          isSearchAllData={isSearchAllData}
          onDefaultDataViewChange={setIsSearchAllData}
          gridColumns={gridColumns}
          setGridColumns={setGridColumns}
          sortOrderParam={sortOrderParam}
          setSortOrderParam={setSortOrderParam}
        />
      ) : (
        <AccountingEntry
          toggleActions={() => {
            toggleSigningSearch();
            setIsHistorySearch(false);
          }}
          isHistorySearch={isHistorySearch}
          onHistoryCheckbox={() =>
            setIsHistorySearch((prevState) => !prevState)
          }
          isSigningSearch={isSigningSearch}
          isSearchAllData={isSearchAllData}
          onDefaultDataViewChange={setIsSearchAllData}
          gridColumns={gridColumns}
          setGridColumns={setGridColumns}
          sortOrderParam={sortOrderParam}
          setSortOrderParam={setSortOrderParam}
        />
      )}
    </>
  );
};
