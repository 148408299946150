import { IDropdownOption, IStackStyles } from '@fluentui/react';
import {
  FormHookAmount,
  FormHookDropdown,
} from 'common/components/FormHooksFields';
import {
  AutoCompleteTextField,
  LookUpProps,
} from 'common/components/FormHooksFields/AutoCompleteTextField';
import { FormHookTextField } from 'common/components/FormHooksFields/FormHookTextField';
import { ColumnData } from 'common/components/SearchBar';
import React, { useEffect } from 'react';
import { TripDistributionFormValues } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/types';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';

const lookUpCalloutStyles: Partial<IStackStyles> = {
  root: {
    maxHeight: 400,
    padding: 10,
  },
};

export interface FormFieldProps {
  baseField: string;
  columnData: ColumnData;
  index: number;
  item: TripDistributionFormValues | undefined;
  _isUpdatable?: boolean | null | undefined;
  _isDistributionUpdateAllowed?: boolean | null | undefined;
  rowLength: number;
  dropdownValues: {
    productionIncentivesOptions: IDropdownOption[];
    tax1099T4Options: IDropdownOption[];
    currencyOptions: IDropdownOption[];
  };
  travelAuthorizationData:
  | Partial<TravelAuthorization_travelAuthorization>
  | null
  | undefined;

  refCodesOptions: {
    refCode1Options: IDropdownOption[];
    refCode2Options: IDropdownOption[];
    refCode3Options: IDropdownOption[];
    refCode4Options: IDropdownOption[];
  };
  onSelectAccount: (lookupData: LookUpProps) => void;
  onSetAllocationDefault?: () => void;
  businessUnitId: string | null;
}

export const FormField: React.FC<FormFieldProps> = ({
  baseField,
  columnData,
  dropdownValues,
  index,
  onSelectAccount,
  refCodesOptions,
  _isDistributionUpdateAllowed,
  _isUpdatable,
  item,
  rowLength,
  onSetAllocationDefault,
  travelAuthorizationData,
  businessUnitId,
}) => {
  const isRowUpdatable = _isUpdatable ? true : _isDistributionUpdateAllowed;
  const isAllocationEntered = item ? !!item.allocationPercent : false;
  const isAmountEntered = item ? !!item.distributionAmount : false;

  useEffect(() => {
    if (
      item?.allocationPercent === '100' &&
      item.distributionAmount !== null &&
      index === 0
    ) {
      onSetAllocationDefault?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.distributionAmount]);

  switch (columnData.key) {
    case 'delete':
      return null;
    case 'accountReference':
      return (
        <AutoCompleteTextField
          id={`accountReference${columnData.key}${index}`}
          name={`${baseField}.${columnData.key}`}
          width={columnData.maxWidth!}
          lookUpCalloutWidth={500}
          onVendorSelect={onSelectAccount}
          underlined
          businessUnitId={businessUnitId}
          disabled={!isRowUpdatable}
          lookupCalloutStyles={lookUpCalloutStyles}
        />
      );
    case 'projectReference':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          max={4}
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'setReference':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          max={4}
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'referenceCode1':
      return (
        <FormHookDropdown
          options={refCodesOptions.refCode1Options}
          name={`${baseField}.${columnData.key}`}
          width={columnData.maxWidth}
          underlined
          disabled={!isRowUpdatable}
          calloutProps={{ calloutWidth: 150 }}
          isCustomTitle
        />
      );
    case 'referenceCode2':
      return (
        <FormHookDropdown
          options={refCodesOptions.refCode2Options}
          name={`${baseField}.${columnData.key}`}
          width={columnData.maxWidth}
          underlined
          disabled={!isRowUpdatable}
          calloutProps={{ calloutWidth: 150 }}
          isCustomTitle
        />
      );
    case 'referenceCode3':
      return (
        <FormHookDropdown
          options={refCodesOptions.refCode3Options}
          name={`${baseField}.${columnData.key}`}
          width={columnData.maxWidth}
          underlined
          calloutProps={{ calloutWidth: 150 }}
          disabled={!isRowUpdatable}
          isCustomTitle
        />
      );
    case 'referenceCode4':
      return (
        <FormHookDropdown
          options={refCodesOptions.refCode4Options}
          name={`${baseField}.${columnData.key}`}
          width={columnData.maxWidth}
          underlined
          calloutProps={{ calloutWidth: 150 }}
          disabled={!isRowUpdatable}
          isCustomTitle
        />
      );
    case 'referenceCode5':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          maxLength={3}
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'referenceCode6':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          maxLength={3}
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'referenceCode7':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          maxLength={3}
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'productionIncentiveId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={dropdownValues.productionIncentivesOptions}
          width={columnData.maxWidth}
          underlined
          disabled={!isRowUpdatable}
          calloutProps={{ calloutWidth: 200 }}
        />
      );
    case 'tax1099T4TypeId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={dropdownValues.tax1099T4Options}
          width={columnData.maxWidth}
          underlined
          disabled={!isRowUpdatable}
          calloutProps={{ calloutWidth: 200 }}
        />
      );

    case 'distributionAmount':
      return (
        <FormHookAmount
          name={`${baseField}.${columnData.key}`}
          style={{ width: columnData.maxWidth, textAlign: 'right' }}
          fixedDecimalScale
          decimalScale={2}
          maxLength={15}
          allowNegative
          underlined
          disabled={
            !isRowUpdatable
              ? true
              : index === 0 &&
                item?.allocationPercent === '100' &&
                rowLength === 1
                ? false
                : isAllocationEntered && !_isDistributionUpdateAllowed
            // ? isAllocationEntered || !!_isDistributionUpdateAllowed
            // : true
          }
        />
      );
    case 'currencyId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={dropdownValues.currencyOptions}
          width={columnData.maxWidth}
          underlined
          disabled={!isRowUpdatable}
          calloutProps={{ calloutWidth: 80 }}
        />
      );

    case 'allocationPercent':
      return (
        <FormHookAmount
          name={`${baseField}.${columnData.key}`}
          style={{ width: columnData.maxWidth, textAlign: 'right' }}
          maxLength={3}
          underlined
          allowNegative={false}
          disabled={
            !_isUpdatable
              ? true
              : isAmountEntered && !_isDistributionUpdateAllowed
            // (!!_isDistributionUpdateAllowed && !_isUpdatable) || isAmountEntered
          }
        />
      );
    default:
      return null;
  }
};
