import {
  Callout,
  Checkbox,
  IconButton,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import React, { useState } from 'react';
import { ColumnData } from '..';
import { useStyles } from '../index.styles';
import { useId } from '@fluentui/react-hooks';
import { useCommonStyles } from 'common/styles';
/**
 * @param columns Pass exisintg columns Data
 * @param onToggleVisibility callback on change of any visibility of column from callout
 */
interface ShowColumnsProps {
  columns: ColumnData[];
  onToggleVisibility?: (columns: ColumnData[]) => void;
  disabled?: boolean;
}
export const ShowColumns: React.FC<ShowColumnsProps> = ({
  columns,
  onToggleVisibility,
  disabled,
}) => {
  const styles = useStyles();
  const showColumnsButtonId = useId('showColumns-callout-label');
  const [calloutVisibility, setCalloutVisibility] = useState<boolean>(false);
  const toggleVisibility = () => {
    setCalloutVisibility((prevState) => !prevState);
  };
  if (
    columns.filter((singleColumn) => singleColumn.toggleVisibility).length > 0
  )
    return (
      <>
        <TooltipHost content={'Show Columns'}>
          <IconButton
            disabled={disabled}
            id={showColumnsButtonId}
            className={styles.iconButtonStyles}
            onClick={toggleVisibility}
            iconProps={{ iconName: 'TripleColumn' }}
          />
        </TooltipHost>
        {calloutVisibility && (
          <Callout
            calloutMaxWidth={400}
            role="alertdialog"
            gapSpace={0}
            target={`#${showColumnsButtonId}`}
            onDismiss={toggleVisibility}
            setInitialFocus
          >
            <CalloutContainer
              columns={columns}
              onToggleVisibility={(columnsArray) => {
                onToggleVisibility?.(columnsArray);
              }}
            />
          </Callout>
        )}
      </>
    );
  else return null;
};

interface CalloutContainerProps {
  columns: ColumnData[];
  onToggleVisibility: (columns: ColumnData[]) => void;
}

const CalloutContainer: React.FC<CalloutContainerProps> = ({
  columns,
  onToggleVisibility,
}) => {
  const commonStyles = useCommonStyles();
  return (
    <Stack tokens={{ childrenGap: 10, padding: '20px 25px' }}>
      <Stack>
        <Text className={commonStyles.bold}>Show columns</Text>
      </Stack>

      {columns
        .filter((col) => col.toggleVisibility)
        .map((item) => {
          return (
            <Checkbox
              key={item.fieldName}
              label={item.name}
              defaultChecked={item.isVisible}
              onChange={(_event, value) => {
                let visibleColumnArray = [...columns];
                let newdata = visibleColumnArray.map((columnEntity) => {
                  let newColoumn = { ...columnEntity };
                  if (columnEntity.fieldName === item.fieldName)
                    newColoumn.isVisible = value;
                  return newColoumn;
                });
                onToggleVisibility?.(newdata);
              }}
            />
          );
        })}
    </Stack>
  );
};
