import {
  NetworkStatus,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  IColumn,
  IDetailsRowBaseProps,
  IconButton,
  Pivot,
  PivotItem,
  PrimaryButton,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { TransactionModal } from 'ap/paymentCycle/view/TransactionModal';
// import { StatusIcon } from 'approvals/ApprovalInputGroup/StatusIcon';
import clsx from 'clsx';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { CustomDropdown } from 'common/components/CustomDropdown';
import { FilterArrayType } from 'common/components/Filters';
import {
  HighLightActiveLink,
  HighlightTextView,
} from 'common/components/HighLight';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  EntityDeleteInput,
  InvoiceBatchCreateInput,
  InvoiceFilter,
  InvoicesOrderBy,
} from 'common/types/globalTypes';
import { getColumn, getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { convertToTitleCase } from 'common/utils/convertToTitleCase';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import {
  BatchEditTabs,
  BatchListOption,
  batchEditTabMode,
  setBatchSelected,
} from 'postingTracker/batchEdit';
import { BatchTransactions } from 'postingTracker/batchEdit/batch/list/__generated__/BatchTransactions';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  setCurrentProfile,
  setGlobalSearchText,
  setUserDefaults,
} from 'utility/cache/ui';
import { InvoiceBatchTransactionModal } from '../InvoiceBatchModal';
import { TransactionSearchBar } from '../TransactionSearchBar';
import { toFilterVariable, toOrderByVariable } from '../utils';
import {
  Transactions,
  TransactionsVariables,
  Transactions_invoiceSigningSearch_nodes,
} from './__generated__/Transactions';
import { useStyles } from './index.styles';

import {
  InvoiceSigningSearchFilterTotals,
  InvoiceSigningSearchFilterTotalsVariables,
  InvoiceSigningSearchFilterTotals_invoiceSigningSearchFilterTotals,
} from 'ap/signing/__generated__/InvoiceSigningSearchFilterTotals';
import { getFormattedAmountValue } from 'common/components/AmountView/utils';
import { Badge } from 'common/components/Badge';
import { StatusIcon } from 'common/components/StatusIcon';
import { getFilterParams } from 'postingTracker/utils.common';
import { useToasts } from 'react-toast-notifications';
import {
  InvoiceBatchCreate,
  InvoiceBatchCreateVariables,
} from './__generated__/InvoiceBatchCreate';
import { useColumns } from './columns.data';

const INVOICES = loader('./Transactions.graphql');
const INVOICES_FILTER_TOTAL = loader(
  '../../../../ap/signing/InvoiceSigningSearchFilterTotals.graphql'
);
const BATCH_TRANSACTION = loader('../../batch/list/BatchTransactions.graphql');
const CREATE_BATCH_INVOICE = loader('./InvoiceBatchCreate.graphql');

export type InvoiceRow = Transactions_invoiceSigningSearch_nodes & {
  name: string | null | undefined;
  date: string | null;
  purchaseOrder: string | null | undefined;
  corporatePeriod: string | null | undefined;
  amount: string;
  status: string | null | undefined;
  requesterDate: string;
  approvers: string;
  approvedDate: string;
  amendedByName: string;
  rejectors: string;
  rejectedDate: string;
  amendedDate: string;
  requesterName: string;
  transactionType: string;
  isDocumentSigningPayment: boolean | undefined;
  production: string | null;
  currencyName: string | null;
};

export interface paymentTrackingNavProps {
  clearSelection?: boolean | undefined;
  reloadList?: boolean | undefined;
}

interface UnpostedTransactionsProps {}

export const UnpostedTransactionsList: React.FC<
  UnpostedTransactionsProps
> = () => {
  const { addToast } = useToasts();
  const batchSelected = useReactiveVar(setBatchSelected);
  const commonStyles = useCommonStyles();
  const { state } = useLocation<paymentTrackingNavProps | undefined>();
  const styles = useStyles();
  const { columns } = useColumns();
  const renderRef = useRef(false);
  const globalSearchText = useReactiveVar(setGlobalSearchText);
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [selectedInvoice, setSelectedInvoice] = useState<string | undefined>();
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>([]);
  const [selectedRows, setSelectedRows] = useState<InvoiceRow[]>([]);
  const [searchTextValue, setSearchTextValue] = useState<string | null>(null);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>({
    column: 'name',
    direction: 'asc' as OrderDirection,
  });
  const [batchesOptions, setBatchesOptions] = useState<BatchListOption[]>([]);
  const [showBatchTransactionModal, setBatchTransactionModal] =
    useState<boolean>(false);
  const userDefaults = useReactiveVar(setUserDefaults);
  const currentProfileData = useReactiveVar(setCurrentProfile);
  const batchesTabState = useReactiveVar(batchEditTabMode);

  const {
    loading: invoicesLoading,
    data: invoicesData,
    networkStatus,
    fetchMore,
    refetch,
    variables: invoiceVariables,
  } = useQuery<Transactions, TransactionsVariables>(INVOICES, {
    variables: {
      isSigningSearch: false,
      isHistorySearch: true,
      first: TABLE_ROWS,
      isLinkSearch: false,
      invoiceSigningSearchSearchRequest: {
        searchText: [''],
        searchHintId: 101,
      },
      orderBy: [
        InvoicesOrderBy.VENDOR_REFERENCE_ASC,
        InvoicesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
        InvoicesOrderBy.PRIMARY_KEY_ASC,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { data: invoicesFilterTotal, refetch: refetchFilterTotal } = useQuery<
    InvoiceSigningSearchFilterTotals,
    InvoiceSigningSearchFilterTotalsVariables
  >(INVOICES_FILTER_TOTAL, {
    variables: {
      isSigningSearch: false,
      isHistorySearch: true,
      isLinkSearch: false,
      invoiceSigningSearchSearchRequest: {
        searchText: [''],
        searchHintId: 101,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { data: batchTransactions } = useQuery<BatchTransactions>(
    BATCH_TRANSACTION,
    {
      variables: {
        filter: { _accountingStampDate: { isNull: true } },
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  const [createBatchInvoice] = useMutation<
    InvoiceBatchCreate,
    InvoiceBatchCreateVariables
  >(CREATE_BATCH_INVOICE, { errorPolicy: 'all' });

  const { invoiceSigningSearchFilterTotals } = {
    ...invoicesFilterTotal,
  };

  const addBatchInvoice = async () => {
    const invoices = selectedRows.map((item) => {
      return {
        id: item.id,
        rowTimestamp: item._rowTimestamp,
      } as EntityDeleteInput;
    });
    const selectedInvoice: InvoiceBatchCreateInput = {
      batchTransactionId: batchSelected?.key?.toString()!,
      invoice: invoices,
    };
    const { errors } = await createBatchInvoice({
      variables: {
        input: selectedInvoice,
        batchInputId: batchSelected?.key?.toString()!,
      },
      update: (cache, { data }) => {
        const position = batchesOptions.findIndex(
          (item) => item.key === batchSelected?.key
        );
        let tempOptions = [...batchesOptions];
        tempOptions[position] = {
          ...batchSelected!,
          count:
            data?.invoiceBatchCreate?.query?.batchTransaction
              ?._batchTransactionCount!,
          total:
            data?.invoiceBatchCreate?.query?.batchTransaction
              ?._batchTransactionTotal!,
        };
        setBatchesOptions(tempOptions);
        setBatchSelected({
          ...batchSelected!,
          count:
            data?.invoiceBatchCreate?.query?.batchTransaction
              ?._batchTransactionCount!,
          total:
            data?.invoiceBatchCreate?.query?.batchTransaction
              ?._batchTransactionTotal!,
        });
        const insetedId = invoices?.map((entity) => entity?.id);
        if (insetedId && data?.invoiceBatchCreate !== null) {
          const filteredList = invoicesData?.invoiceSigningSearch?.nodes.filter(
            (emp) => insetedId.indexOf(emp.id) === -1
          );
          const newSum =
            selectedRows?.reduce(
              (total, item) => total + Number(item.controlTotalAmount),
              0
            ) || 0;
          const baseCurrencyAmount =
            selectedRows?.reduce(
              (total, item) => total + Number(item._baseCurrencyAmount),
              0
            ) || 0;
          const spotCurrencyAmount =
            selectedRows?.reduce(
              (total, item) => total + Number(item._spotCurrencyAmount),
              0
            ) || 0;
          const { totalAmount1, totalAmount2, totalAmount3, totalCount1 } = {
            ...invoiceSigningSearchFilterTotals,
          };
          try {
            cache.modify({
              fields: {
                invoiceSigningSearchFilterTotals(
                  existing: InvoiceSigningSearchFilterTotals_invoiceSigningSearchFilterTotals
                ) {
                  if (existing?.totalCount1 !== 0) {
                    return {
                      totalAmount1: (
                        parseFloat(totalAmount1 || '0.00') - newSum
                      ).toString(),
                      totalAmount2: (
                        parseFloat(totalAmount2 || '0.00') - baseCurrencyAmount
                      ).toString(),
                      totalAmount3: (
                        parseFloat(totalAmount3 || '0.00') - spotCurrencyAmount
                      ).toString(),
                      totalCount1: (totalCount1 || 0) + 1,
                    };
                  } else {
                    return {
                      ...existing,
                    };
                  }
                },
                invoiceSigningSearch(existing: Transactions) {
                  return {
                    ...existing,
                    nodes: filteredList,
                    pageInfo: invoicesData?.invoiceSigningSearch?.pageInfo!,
                  };
                },
              },
            });
          } catch (error) {}
        }
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Invoice added Successfully', {
        appearance: 'success',
      });
      setSelectedRows([]);
    }
  };

  useEffect(() => {
    const batches: BatchListOption[] =
      batchTransactions?.batchTransactions?.nodes
        .filter((item) => item._isUpdatable)
        .map((item) => {
          return {
            key: item.id,
            text:
              `${item.description}  ${
                item.currency?.isoCode ? `(${item.currency?.isoCode})` : ''
              } ` || '',
            description: item.description,
            isoCode: item.currency?.isoCode!,
            count: item._batchTransactionCount!,
            total: item._batchTransactionTotal!,
          };
        }) || [];

    setBatchesOptions(batches);
  }, [batchTransactions]);

  useEffect(() => {
    if (state?.clearSelection) {
      setSelectedRows([]);
    }
    if (state?.reloadList) {
      refetch();
    }
    window.history.replaceState({}, document.title);
  }, [state, refetch]);

  const refetching =
    invoicesLoading && networkStatus !== NetworkStatus.fetchMore;

  let transformedData = refetching
    ? undefined
    : invoicesData?.invoiceSigningSearch?.nodes.map(
        (inv) =>
          ({
            ...inv,
            ...inv.invoiceHistoryApprover,
            id: inv.id,
            transactionType: inv.transactionType?.transactionType,
            businessUnit: inv.businessUnit?.name,
            name: inv.vendorReference,
            description: inv.description,
            department: inv.department?.name,
            invoiceNumber: inv.invoiceNumber,
            date: inv.invoiceDate
              ? dateFormat(dateConvertions(inv.invoiceDate))
              : '',
            corporatePeriod: inv.companyCorporatePeriod?._periodYear,
            amount: inv.controlTotalAmount,
            _baseCurrencyAmount: inv._baseCurrencyAmount,
            _spotCurrencyAmount: inv._spotCurrencyAmount,
            currency: inv.currency?.isoCode,
            status: inv.statusType?.statusType,
            production: inv.company?.name,
            isDocumentSigningPayment:
              inv.transactionType?.isDocumentSigningPayment,
          } as InvoiceRow)
      );

  const _renderItemColumn = (
    item: InvoiceRow | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof InvoiceRow
      ] as string;

      const approverData = item.invoiceHistoryApprover?.approvers
        ? item.invoiceHistoryApprover?.approvers.split(',').join(', ')
        : '';
      switch (column?.key) {
        case 'transactionType':
          return (
            <HighLightActiveLink
              highlightText={globalSearchText}
              text={item?.transactionType}
              preventDefault
              onClick={() => {
                setSelectedInvoice(item.id);
              }}
            />
          );
        case '_baseCurrencyAmount':
        case '_spotCurrencyAmount':
        case 'amount':
          return (
            <AmountColumnTextView
              value={fieldContent}
              searchText={globalSearchText}
            />
          );
        case 'currency':
          return (
            <Stack verticalAlign="center" className={styles.currencyCell}>
              <HighlightTextView
                highlightText={globalSearchText}
                text={fieldContent}
              />
            </Stack>
          );

        case 'requesterDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>
                {item.invoiceHistoryApprover?.requesterDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.requesterDate!
                  )}
              </Text>
            </Stack>
          );
        case 'approvedDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>
                {item.invoiceHistoryApprover?.approvedDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.approvedDate!
                  )}
              </Text>
            </Stack>
          );
        case 'rejectedDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>
                {item.invoiceHistoryApprover?.rejectedDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.rejectedDate!
                  )}
              </Text>
            </Stack>
          );
        case 'amendedDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>
                {item.invoiceHistoryApprover?.amendedDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.amendedDate!
                  )}
              </Text>
            </Stack>
          );
        case 'status':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
              horizontal
              horizontalAlign="space-between"
            >
              <HighlightTextView
                className={styles.statusType}
                highlightText={globalSearchText}
                text={convertToTitleCase(item?.status!)!}
              />
              <Stack>
                <StatusIcon
                  approval={false}
                  iconType={item.statusType!}
                  approvalData={item}
                />
              </Stack>
            </Stack>
          );

        case 'approvers':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <HighlightTextView
                highlightText={globalSearchText}
                text={approverData!}
              />
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                />
              )}
            </Stack>
          );
      }
    }
  };

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  const getNewColumns = () => {
    if (sortOrderParam?.column !== '') {
      const columnData = getColumn(sortOrderParam?.column!, gridColumns);
      const sortedColumns = getSortedColumns(columnData, gridColumns);
      return sortedColumns;
    } else return undefined;
  };

  const getNewColumnsMemo = useCallback(getNewColumns, []);

  useEffect(() => {
    const sortedColumns = getNewColumnsMemo();
    if (sortedColumns) setGridColumns(sortedColumns.newColumns);
  }, [getNewColumnsMemo]);

  const handleSearch = async (showMore: boolean) => {
    if (!showMore) setSelectedRows([]);
    const searchRequest = {
      searchText: [searchTextValue ? searchTextValue : ''],
      searchHintId: 101,
    };

    let filter = searchFilters?.length
      ? ({ and: toFilterVariable(searchFilters) } as InvoiceFilter)
      : undefined;

    const filterParams = getFilterParams(searchFilters || []);

    const variables: TransactionsVariables = {
      ...invoiceVariables,
      after: showMore
        ? invoicesData?.invoiceSigningSearch?.pageInfo.endCursor
        : undefined,
      filter: filter,
      orderBy: toOrderByVariable(sortOrderParam),
      invoiceSigningSearchSearchRequest: searchRequest,
    };
    const totalFilterVariables: InvoiceSigningSearchFilterTotalsVariables = {
      isSigningSearch: invoiceVariables?.isSigningSearch,
      isHistorySearch: invoiceVariables?.isHistorySearch,
      invoiceSigningSearchSearchRequest: searchRequest,
      isLinkSearch: false,
      ...filterParams,
    };
    if (showMore) await fetchMore?.({ variables });
    else {
      refetch(variables);
      refetchFilterTotal({
        ...totalFilterVariables,
      });
    }
  };

  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
    searchTextValue,
  ]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
  }, [searchFilters, sortOrderParam, handleSearchMemo]);

  useEffect(() => {
    if (!renderRef.current) renderRef.current = true;
    else {
      handleSearchMemo(false);
    }
  }, [searchTextValue, handleSearchMemo]);

  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      const { totalAmount1, totalAmount2, totalAmount3 } = {
        ...invoiceSigningSearchFilterTotals,
      };
      const fieldContent =
        column?.key === 'amount'
          ? totalAmount1
          : column?.key === '_baseCurrencyAmount'
          ? totalAmount2
          : column?.key === '_spotCurrencyAmount'
          ? totalAmount3
          : '';

      return (
        <AmountTextView
          variant="medium"
          className={clsx(styles.amountStack, styles.contentColumnAlignRight)}
          value={fieldContent!}
        />
      );
    };
  const showFooter =
    !refetching &&
    parseFloat(invoiceSigningSearchFilterTotals?.totalAmount1 || '0.0') > 0 &&
    parseInt(invoiceSigningSearchFilterTotals?.totalCount1?.toString() || '0') >
      0;

  const setBaseAmountHeading = () => {
    if (currentProfileData) {
      const updatedColumns: ColumnData[] = gridColumns.map((col) => {
        switch (col.fieldName) {
          case '_baseCurrencyAmount':
            return {
              ...col,
              name: `${currentProfileData.currentUserProfile?.company?.currency?.isoCode} Amount`,
            };
          case '_spotCurrencyAmount':
            return {
              ...col,
              name: `${currentProfileData.currentUserProfile?.company?.currency?.isoCode} Spot Rate`,
            };
          default:
            return { ...col };
        }
      });
      setGridColumns(updatedColumns);
    }
  };

  const setBaseAmountHeadingMemo = useCallback(setBaseAmountHeading, [
    currentProfileData,
  ]);

  useEffect(() => {
    setBaseAmountHeadingMemo();
  }, [setBaseAmountHeadingMemo]);

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Batch/Edit</Text>
          <Stack
            horizontalAlign="space-between"
            verticalAlign="center"
            horizontal
            tokens={{ childrenGap: 20 }}
          >
            {selectedRows.length !== 0 && batchSelected?.key && (
              <PrimaryButton
                onClick={addBatchInvoice}
                iconProps={{ iconName: 'Add' }}
                text="Add Transaction"
              />
            )}
          </Stack>
        </Stack>
      </Stack>

      <Stack tokens={{ padding: '0px 0px 10px 20px', childrenGap: 10 }}>
        <Stack horizontal>
          <Stack>
            <Pivot
              selectedKey={batchesTabState}
              onLinkClick={(item: PivotItem | undefined) => {
                batchEditTabMode(item?.props.itemKey || BatchEditTabs.batch);
              }}
              aria-label="Menu_Actions"
            >
              <PivotItem itemKey={BatchEditTabs.batch} headerText="Batches" />
              <PivotItem
                itemKey={BatchEditTabs.transactions}
                headerText="Available Transactions"
              />
            </Pivot>
          </Stack>

          <Stack
            horizontal
            tokens={{ childrenGap: 10 }}
            className={styles.paymentCyclesDropdownContainer}
          >
            <Stack>
              <CustomDropdown
                style={{ width: 300 }}
                placeholder="Select batch"
                options={batchesOptions}
                selectedKey={batchSelected?.key ? batchSelected?.key : null}
                onChange={(_, option) => {
                  const selectedOption = option as BatchListOption;
                  setBatchSelected(selectedOption);
                }}
                onClear={() => {
                  setBatchSelected(undefined);
                }}
                underlined
              />
            </Stack>
            {batchSelected?.key && (
              <TooltipHost
                content={
                  batchSelected?.count! > 0
                    ? `(${batchSelected.count})  ${
                        batchSelected.total
                          ? getFormattedAmountValue(batchSelected.total)
                          : 0.0
                      }`
                    : 'No Transactions added yet'
                }
                id="tooltipId"
              >
                <Badge visible={batchSelected.count! > 0}>
                  <IconButton
                    type="button"
                    onClick={() => {
                      setBatchTransactionModal(true);
                    }}
                    iconProps={{ iconName: 'RedEye' }}
                    ariaLabel="View Batch"
                  />
                </Badge>
              </TooltipHost>
            )}
          </Stack>
        </Stack>
        <Stack style={{ marginRight: 20 }}>
          <TransactionSearchBar
            columns={gridColumns}
            onRefresh={() => handleSearch(false)}
            onEnterPress={(value) => {
              setSearchTextValue(value);
              setGlobalSearchText(value);
            }}
            onFilterChange={setSearchFilters}
            onToggleVisibility={(columns) => {
              setGridColumns(columns);
            }}
          />
        </Stack>
      </Stack>

      <InfiniteList
        loading={invoicesLoading}
        items={transformedData}
        hasNextPage={invoicesData?.invoiceSigningSearch?.pageInfo.hasNextPage}
        showFooter={showFooter}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={async () => await handleSearch(true)}
        onSelectionChanged={setSelectedRows}
        selectedRows={selectedRows}
        onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
        totalRowsCount={invoiceSigningSearchFilterTotals?.totalCount1}
      />

      {selectedInvoice !== undefined && (
        <TransactionModal
          entityId={selectedInvoice}
          isOpen={selectedInvoice !== undefined}
          onDismiss={() => setSelectedInvoice(undefined)}
        />
      )}

      {showBatchTransactionModal && (
        <InvoiceBatchTransactionModal
          isOpen={showBatchTransactionModal}
          onDismiss={() => setBatchTransactionModal(false)}
          batchTransactionId={batchSelected?.key.toString()!}
          batchDetails={batchSelected!}
          onRemove={(value) => {
            refetch();

            const position = batchesOptions.findIndex(
              (item) => item.key === batchSelected?.key
            );

            let tempOptions = [...batchesOptions];
            tempOptions[position] = {
              ...batchSelected!,
              count: value?._batchTransactionCount!,
              total: value?._batchTransactionTotal!,
            };
            setBatchesOptions(tempOptions);

            setBatchSelected({
              ...batchSelected!,
              count: value?._batchTransactionCount!,
              total: value?._batchTransactionTotal!,
            });
          }}
        />
      )}
    </>
  );
};
