import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import React from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import {
  Checkbox,
  FontSizes,
  FontWeights,
  ICheckboxStyles,
  Stack,
  Toggle,
} from '@fluentui/react';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import {
  ColumnData,
  SearchBar,
  SearchBarProps,
} from 'common/components/SearchBar';
import { formatDropdownOptions } from 'common/utils';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { useState } from 'react';
import { globalMode } from 'utility/cache/ui';
import { useStyles } from './index.styles';
import { InvoiceTransactionTypes } from 'ap/signing/__generated__/InvoiceTransactionTypes';
const INVOICE_TRANSACTION_TYPES = loader(
  '../../InvoiceTransactionTypes.graphql'
);

interface TransactionSearchBarProps extends SearchBarProps {
  disabled?: boolean;
  onHintsViewToggle?: () => void;
  columns: ColumnData[];
  onFilterChange: (filterArray: FilterArrayType[]) => void;
  onToggleVisibility?: (columns: ColumnData[]) => void;
  isSigningSearch: boolean;
  toggleActions: () => void;
  onHistoryCheckbox: () => void;
}
export const TransactionSearchBar: React.FC<TransactionSearchBarProps> = ({
  columns,
  onFilterChange,
  onEnterPress,
  hintsAvailable,
  onRefresh,
  searchInputPlaceholderText,
  onToggleVisibility,
  onHintsViewToggle,
  isSigningSearch,
  toggleActions,
  onHistoryCheckbox,
  urlParams,
  onGlobalToggle,
  disabled,
}) => {
  const globalState = useReactiveVar(globalMode);
  const styles = useStyles();
  let checkBoxStyle: ICheckboxStyles = {
    label: {
      fontWeight: FontWeights.bold,
      fontSize: FontSizes.size10,
    },
  };

  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const { data: invoiceTransactionTypesData } =
    useQuery<InvoiceTransactionTypes>(INVOICE_TRANSACTION_TYPES);
  const typeOptions = formatDropdownOptions(
    invoiceTransactionTypesData?.invoiceTransactionTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.transactionType!,
      includeAll: true,
    }
  );
  const businessUnitOptions = formatDropdownOptions(
    invoiceTransactionTypesData?.companyBusinessUnits?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );
  const departmentOptions = formatDropdownOptions(
    invoiceTransactionTypesData?.companyDepartments?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );
  const statusOptions = formatDropdownOptions(
    invoiceTransactionTypesData?.invoiceStatusTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.statusType!,
      includeAll: true,
    }
  );

  const companiesOptions = formatDropdownOptions(
    invoiceTransactionTypesData?.companies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );

  const currenciesOptions = formatDropdownOptions(
    invoiceTransactionTypesData?.companyCurrencies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );

  const accountingPeriodOptions =
    invoiceTransactionTypesData?.companyCorporatePeriods?.nodes.map((item) => ({
      key: item.id,
      text:
        item._periodYear +
          ' (' +
          dateFormat(dateConvertions(item.startDate)) +
          ' - ' +
          dateFormat(dateConvertions(item.endDate)) +
          ')' || '',
    })) || [];

  const invoiceUsers = formatDropdownOptions(
    invoiceTransactionTypesData?.invoiceUsers?.nodes,
    {
      getKey: (item) => item.userId!,
      getText: (item) => item.userName!,
      includeAll: true,
    }
  );
  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    let newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    newFilters = newFilters.filter((element) => element.value);
    onFilterChange(newFilters);
    setFilterTypes(newFilters);
  };

  return (
    <SearchBar
      disabled={disabled}
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange([]);
        setFilterTypes([]);
      }}
      urlParams={urlParams}
      columns={columns}
      searchInputPlaceholderText={searchInputPlaceholderText}
      onEnterPress={onEnterPress}
      onRefresh={onRefresh}
      hintsAvailable={hintsAvailable}
      onHintsViewToggle={onHintsViewToggle}
      onToggleVisibility={onToggleVisibility}
      onGlobalToggle={onGlobalToggle}
      renderActions={
        <Stack
          className={styles.actionsWrapperStyle}
          horizontalAlign="center"
          verticalAlign="center"
          horizontal
          tokens={{ childrenGap: 25 }}
        >
          {/* If we used default toggle action it's not rendering UI. For that we need to used on isSigningSearch actions */}
          <Toggle
            label="Signing"
            disabled={globalState || disabled}
            inlineLabel
            defaultChecked={isSigningSearch ? false : true}
            onText="Accounting Entry"
            offText="Accounting Entry"
            onChange={toggleActions}
            className={styles.signingAccountingToggle}
            styles={{
              label: {
                wordBreak: 'keep-all',
              },
            }}
          />

          {!isSigningSearch && (
            <Checkbox
              disabled={disabled}
              styles={checkBoxStyle}
              label="History"
              onChange={onHistoryCheckbox}
            />
          )}
        </Stack>
      }
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20 }}
        className={styles.filtersWrapperStyle}
      >
        <DropdownFilter
          filterKey="transactionTypeId"
          options={typeOptions}
          placeholder="Type"
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="businessUnitId"
          options={businessUnitOptions}
          placeholder="Business Unit"
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="departmentId"
          options={departmentOptions}
          placeholder="Department"
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="currencyId"
          options={currenciesOptions}
          placeholder="Currency"
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'_companyId'}
          options={companiesOptions || []}
          placeholder={'Productions'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="corporatePeriodId"
          options={accountingPeriodOptions}
          placeholder="Accounting Period"
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="_createdByUserid"
          options={invoiceUsers}
          placeholder="Created by"
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="_statusTypeId"
          options={statusOptions}
          placeholder="Status"
          onOptionChange={onOptionChange}
        />
      </Stack>
    </SearchBar>
  );
};
