import {
  ItemDistributionValues,
  PurchaseOrderItemFormProps,
} from './interface';

export const DISTRIBUTION_INITIAL_VALUES: ItemDistributionValues = {
  accountReference: null,
  distributionAmount: null,
  productionIncentiveId: null,
  projectReference: null,
  setReference: null,
  tax1099T4TypeId: null,
  referenceCode1: null,
  referenceCode2: null,
  referenceCode3: null,
  referenceCode4: null,
  referenceCode5: null,
  id: null,
  _rowTimestamp: null,
  referenceCode6: null,
  referenceCode7: null,
  allocationPercent: null,
};

export const PURCHASE_ORDER_ITEM_INITIAL_VALUES: PurchaseOrderItemFormProps = {
  itemFromDate: null,
  itemQuantity: null,
  itemToDate: null,
  itemUnitPrice: null,
  isTaxable: null,
  isDiscounted: null,
  purchaseOrderItemTypeId: null,
  expenditureTypeId: null,
  description: null,
  itemExtendedAmount: null,
  unitOfMeasureItemId: null,
  itemYears: null,
  itemYearRate: null,
  itemYearAmount: null,
  itemWeeks: null,
  itemWeekRate: null,
  itemWeekAmount: null,
  itemRentalDates: null,
  itemNumber: null,
  itemMonths: null,
  itemMonthRate: null,
  itemMonthAmount: null,
  itemHours: null,
  itemHourRate: null,
  itemHourAmount: null,
  itemDays: null,
  itemDayRate: null,
  itemDayAmount: null,
  additionalDepartmentId: null,
  deliveryTelephoneNumber: null,
  deliveryStateRegionId: null,
  deliveryPostalCode: null,
  deliveryNotes: null,
  deliveryMainContact: null,
  deliveryMobileNumber: null,
  deliveryEmailAddress: null,
  deliveryCountryId: null,
  deliveryCityName: null,
  deliveryAttention: null,
  deliveryAlternateContact: null,
  deliveryAddressLine2: null,
  deliveryAddressId: null,
  deliveryAddressLine1: null,
  isDeliveryTracked: null,
  scheduledDeliveryDate: null,
  additionalBusinessUnitId: null,
  isPackingSlipAttachmentRequired: null,
  taxTypeId: null,
  subjectToAmount: null,
  _extendedItemTotal: null,
  purchaseOrderItemDistribution: [{ ...DISTRIBUTION_INITIAL_VALUES }],
  id: null,
};
