import {
  NetworkStatus,
  useLazyQuery,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  IDetailsRowProps,
  IRenderFunction,
  SelectionMode,
  Stack,
} from '@fluentui/react';
import {
  InvoiceSigningSearchFilterTotals,
  InvoiceSigningSearchFilterTotalsVariables,
} from 'ap/signing/__generated__/InvoiceSigningSearchFilterTotals';
import {
  InvoiceSigningSearchHints,
  InvoiceSigningSearchHintsVariables,
} from 'ap/signing/__generated__/InvoiceSigningSearchHints';
import {
  InvoicesSigningSearch,
  InvoicesSigningSearchVariables,
} from 'ap/signing/__generated__/InvoicesSigningSearch';
import { StampTransactionFooter } from 'ap/signing/components/StampTransaction';
import { TransactionSearchBar } from 'ap/signing/components/TransactionSearchBar';
import { getTransformedData, toOrderByVariable } from 'ap/signing/utils.common';
import clsx from 'clsx';
import { FilterArrayType } from 'common/components/Filters';
import { InfiniteList } from 'common/components/InfiniteList';
import {
  MultiSelectOption,
  MultiSelectTags,
} from 'common/components/MultiSelectTags';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import {
  expandedNav,
  globalMode,
  setGlobalSearchText
} from 'utility/cache/ui';
import { InvoiceRow } from '../../types';
import { CheckboxControls } from './CheckboxControls';
import { DefaultDataViewEnum, Header } from './Header';
import {
  ColumnStylesProps,
  onRenderFooterItems,
  onRenderItems,
} from './columns.render';
import { useStyles } from './index.styles';
import { handleSearchGlobal } from './utils';
const INVOICES = loader('../../InvoicesSigningSearch.graphql');
const INVOICES_FILTER_TOTAL = loader(
  '../../InvoiceSigningSearchFilterTotals.graphql'
);
const INVOICE_SIGNING_SEARCH_HINTS = loader(
  '../../InvoiceSigningSearchHints.graphql'
);
interface TransactionSigningProps {
  isSigningSearch: boolean;
  toggleActions: () => void;
  onHistoryCheckbox: () => void;
  onDefaultDataViewChange: (param: boolean) => void;
  isSearchAllData: boolean;
  gridColumns: ColumnData[];
  setGridColumns: React.Dispatch<React.SetStateAction<ColumnData[]>>;
  sortOrderParam: SortOrder | undefined;
  setSortOrderParam: React.Dispatch<
    React.SetStateAction<SortOrder | undefined>
  >;
}

export const TransactionSigning: React.FC<TransactionSigningProps> = ({
  isSigningSearch,
  toggleActions,
  onHistoryCheckbox,
  onDefaultDataViewChange,
  gridColumns,
  setGridColumns,
  sortOrderParam,
  setSortOrderParam,
  ...props
}) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const renderRef = useRef(false);

  const [isAllRequestChecked, setIsAllRequestlChecked] = React.useState(false);
  const [isAllReleaseReview, setIsAllReleaseReview] = React.useState(false);
  const [requestedIndices, setRequestedIndices] = React.useState<number[]>([]);
  const [releasedIndices, setReleasedIndices] = React.useState<number[]>([]);
  const globalSearchText = useReactiveVar(setGlobalSearchText);
  const [selectedRows, setSelectedRows] = useState<InvoiceRow[]>([]);
  const [urlParameters, setUrlParameters] = useQueryParams({ t: StringParam });
  const [stampTransaction, setStampTransaction] = useState(false);
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>([]);
  const { t: searchParam } = urlParameters;
  const [searchTextValue, setSearchTextValue] = useState<string | null>(
    searchParam! || null
  );
  const [transactionStampField, setTransactionStampField] = useState<
    Map<string, string>
  >(new Map());
  const [hintsVisibility, setHintsVisibility] = useState<boolean>(false);
  const [isSearchAllData, setIsSearchAllData] = useState(props.isSearchAllData);
  const [searchHints, setSearchHints] = useState<number | null>();
  const globalState = useReactiveVar(globalMode);
  const [defaultDataView, setDefaultDataView] = useState<string>();

  const {
    loading: invoicesLoading,
    data: invoicesData,
    fetchMore,
    networkStatus,
    refetch,
    variables,
  } = useQuery<InvoicesSigningSearch, InvoicesSigningSearchVariables>(
    INVOICES,
    {
      variables: {
        isSigningSearch: true,
        isHistorySearch: false,
        isLinkSearch: false,
        isSearchAllData: isSearchAllData,
        first: TABLE_ROWS,
        orderBy: toOrderByVariable(sortOrderParam),
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const { data: invoicesFilterTotal, refetch: refetchFilterTotal } = useQuery<
    InvoiceSigningSearchFilterTotals,
    InvoiceSigningSearchFilterTotalsVariables
  >(INVOICES_FILTER_TOTAL, {
    variables: {
      isSigningSearch: true,
      isHistorySearch: false,
      isLinkSearch: false,
      isSearchAllData: isSearchAllData,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const [fetchHints, { data: hintsData }] = useLazyQuery<
    InvoiceSigningSearchHints,
    InvoiceSigningSearchHintsVariables
  >(INVOICE_SIGNING_SEARCH_HINTS, {
    fetchPolicy: 'network-only',
  });

  const { invoiceSigningSearch } = {
    ...invoicesData,
  };

  const { invoiceSigningSearchFilterTotals } = {
    ...invoicesFilterTotal,
  };
  const { totalCount1 } = { ...invoiceSigningSearchFilterTotals };
  const { nodes, pageInfo } = { ...invoiceSigningSearch };

  const refetching =
    invoicesLoading && networkStatus !== NetworkStatus.fetchMore;
  const showFooter =
    !refetching &&
    parseFloat(invoiceSigningSearchFilterTotals?.totalAmount1 || '0.0') > 0 &&
    totalCount1! > 0;
  const transformedData = getTransformedData(refetching, nodes);

  const columnStyles: ColumnStylesProps = {
    cellContainer: styles.cellContainer,
    amountStack: styles.amountStack,
    statusType: styles.statusType,
    logo: styles.logo,
    urgentBox: commonStyles.urgentBox,
  };

  const onSetTransactionStampField = (
    id: string,
    value: string | undefined
  ) => {
    if (value && value !== '') {
      setTransactionStampField(new Map(transactionStampField.set(id, value)));
    } else {
      transactionStampField.delete(id);
      setTransactionStampField(new Map(transactionStampField));
    }
  };
  
  const _renderItemColumn = useCallback(
    onRenderItems(
      globalSearchText,
      columnStyles,
      transactionStampField,
      onSetTransactionStampField,
    ),
    [columnStyles, globalSearchText]
  );

  const _renderDetailsFooterItemColumn = onRenderFooterItems(
    invoicesFilterTotal,
    styles
  );
  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };
  const searchHintsOptions: MultiSelectOption[] =
    hintsData?.invoiceSigningSearchHints?.nodes.map(
      (hint) =>
        ({
          key: hint.id?.toString(),
          text: `${hint.caption} (${hint.resultsCount})`,
        } as MultiSelectOption)
    ) || [];

  const isHintsAvailable =
    !!searchHintsOptions.length && !!searchTextValue?.length;

  const _onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    const item: InvoiceRow = { ...props.item };
    const { _urgencyLevel } = { ...item };
    const newProps: IDetailsRowProps | undefined = props
      ? {
          ...props,
          className: clsx(
            styles.rowBaseStyle,
            _urgencyLevel === 0 ? commonStyles.urgentRow : styles.rowBaseStyle
          ),
        }
      : undefined;

    return <>{defaultRender?.(newProps)}</>;
  };
  const handleSearch = async (
    showMore: boolean,
    newHints: boolean,
    globalValue: boolean
  ) => {
    if (!showMore) {
      setSelectedRows([]);
    }
    const {
      searchQueryParam,
      searchRequest,
      variableHints,
      queryVariables,
      queryTotalFilterVariables,
    } = await handleSearchGlobal(
      showMore,
      globalValue,
      searchTextValue,
      searchHints,
      invoicesData,
      sortOrderParam,
      searchFilters,
      isSearchAllData,
      true,
      false
    );
    setUrlParameters({ t: searchQueryParam }, 'replace');
    if (showMore) await fetchMore?.({ variables: queryVariables });
    else {
      refetch(queryVariables);
      refetchFilterTotal({
        ...queryTotalFilterVariables,
      });
    }
    if (newHints && searchRequest && !searchHints) {
      setSearchHints(null);
      fetchHints({
        variables: variableHints,
      });
    }
  };

  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
    searchTextValue,
    searchHints,
    isSearchAllData,
  ]);

  const onToggleActions = () => {
    if (globalState) {
      handleSearch(false, false, true);
      globalMode(false);
    } else {
      handleSearch(false, false, false);
    }
    toggleActions();
  };
  useEffect(() => {
    if (renderRef.current) {
      handleSearchMemo(false, true, globalState);
    } else renderRef.current = true;
  }, [handleSearchMemo, globalState]);

  useEffect(() => {
    if (props.isSearchAllData)
      setDefaultDataView(DefaultDataViewEnum.all_Transactions);
    else setDefaultDataView(DefaultDataViewEnum.my_Transactions);
  }, [props.isSearchAllData]);

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Header
          variables={variables}
          selectedRows={selectedRows}
          invoicesData={invoicesData}
          invoicesFilterTotal={invoicesFilterTotal}
          setRequestedIndices={setRequestedIndices}
          setIsAllReleaseReview={setIsAllReleaseReview}
          setIsAllRequestlChecked={setIsAllRequestlChecked}
          setReleasedIndices={setReleasedIndices}
          isAllRequestChecked={isAllRequestChecked}
          refetch={refetch}
          defaultDataView={defaultDataView}
          onDataViewChange={(option) => {
            setDefaultDataView(option?.toString() || '');
            if (option === DefaultDataViewEnum.all_Transactions) {
              onDefaultDataViewChange(true);
              setIsSearchAllData(true);
            } else {
              onDefaultDataViewChange(false);
              setIsSearchAllData(false);
            }
          }}
          onStamp={() => {
            setStampTransaction((prevState) => !prevState);
            expandedNav(stampTransaction);
            const newColumns = gridColumns.map((item) => {
              if (item.key === 'transactionStamp')
                return { ...item, isVisible: !stampTransaction };
              else return item;
            });
            setGridColumns(newColumns);
          }}
        />
        <Stack>
          <TransactionSearchBar
            urlParams={{
              searchParam: searchParam!,
            }}
            disabled={stampTransaction}
            toggleActions={onToggleActions}
            onHistoryCheckbox={onHistoryCheckbox}
            isSigningSearch={isSigningSearch}
            columns={gridColumns}
            hintsAvailable={isHintsAvailable}
            onRefresh={() => handleSearch(false, false, globalState)}
            onEnterPress={(value) => {
              setSearchHints(null);
              setHintsVisibility(false);
              setSearchTextValue(value);
              setGlobalSearchText(value);
            }}
            onFilterChange={setSearchFilters}
            onHintsViewToggle={() =>
              setHintsVisibility((prevState) => !prevState)
            }
            onToggleVisibility={setGridColumns}
            onGlobalToggle={(value) => handleSearch(false, true, value)}
          />
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Stack style={{ flex: 1 }}>
            {hintsVisibility && isHintsAvailable && (
              <MultiSelectTags
                multiSelect={false}
                options={searchHintsOptions}
                onSelectionChange={(selctedTags) => {
                  const hints = selctedTags.map((item) => parseInt(item.key));
                  setSearchHints(hints[0] || null);
                }}
              />
            )}
          </Stack>
          <CheckboxControls
            data={transformedData}
            isAllReleaseReview={isAllReleaseReview}
            setReleasedIndices={setReleasedIndices}
            setIsAllReleaseReview={setIsAllReleaseReview}
            setIsAllRequestlChecked={setIsAllRequestlChecked}
            isAllRequestChecked={isAllRequestChecked}
            setRequestedIndices={setRequestedIndices}
          />
        </Stack>
      </Stack>
      <InfiniteList
        loading={invoicesLoading}
        items={transformedData}
        hasNextPage={pageInfo?.hasNextPage}
        showFooter={showFooter}
        selectionMode={SelectionMode.multiple}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={async () => await handleSearch(true, false, globalState)}
        onRenderRow={_onRenderRow}
        onSelectionChanged={setSelectedRows}
        requestedIndices={requestedIndices}
        releasedIndices={releasedIndices}
        totalRowsCount={totalCount1}
      />
      <StampTransactionFooter
        isStampTransactionMode={stampTransaction}
        invoices={transformedData}
        stampFields={transactionStampField}
        onStampProcessComplete={(isSuccess) => {
          expandedNav(true);
          setStampTransaction(false);
          setTransactionStampField(new Map([]));
          const newColumns = gridColumns.map((item) => {
            if (item.key === 'transactionStamp')
              return { ...item, isVisible: false };
            else return item;
          });
          setGridColumns(newColumns);
        }}
      />
    </>
  );
};
