import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
import useGetScreenWidth from 'common/hooks/useGetScreenWidth';

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};
const colHeaderLeftAlignStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    marginLeft: 0,
  },
};
const useColumns = () => {
  const { isSm, isLg } = useGetScreenWidth();
  const columns: ColumnData[] = [
    {
      key: '_urgencyLevel',
      name: '',
      fieldName: '_urgencyLevel',
      minWidth: 10,
      maxWidth: 10,
      styles: colHeaderLeftAlignStyle,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
    },
    {
      key: 'description',
      name: 'Cycle',
      fieldName: 'description',
      minWidth: 270,
      ...commonColumnProps,
    },
    {
      key: '_paymentType',
      name: 'Type',
      fieldName: '_paymentType',
      minWidth: 150,
      ...commonColumnProps,
      isVisible: !isSm,
    },
    {
      key: '_paymentAccount',
      name: 'Bank account',
      fieldName: '_paymentAccount',
      minWidth: 200,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'defaultPaymentDate',
      name: 'Payment Date',
      fieldName: 'defaultPaymentDate',
      minWidth: 120,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
    },
    {
      key: 'currencyIsoCode',
      name: 'Currency',
      fieldName: 'currencyIsoCode',
      minWidth: 80,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'controlTotalAmount',
      name: 'Cycle Total',
      fieldName: 'controlTotalAmount',
      minWidth: 120,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
    },
    {
      key: '_totalPayments',
      name: 'Total Payments',
      fieldName: '_totalPayments',
      minWidth: 120,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: '_paymentCount',
      name: 'Payments',
      fieldName: '_paymentCount',
      minWidth: 70,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'status',
      name: 'Status',
      fieldName: 'status',
      minWidth: 150,
      ...commonColumnProps,
    },
  ];
  return { columns };
};
export { useColumns };
