import { useQuery } from '@apollo/client';
import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import { HistoryActionMenuView } from 'common/components/History';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { PivotDisplay } from 'common/components/PivotDisplay';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
// import { useToasts } from 'react-toast-notifications';
import { Supplier_supplier } from '../__generated__/Supplier';
import { SupplierNotesSection } from './SupplierNotes';
import {
  ListSupplierNotes,
  ListSupplierNotesVariables,
} from './__generated__/ListSupplierNotes';
import { useStyles } from './index.styles';
import { TagsSection } from './SupplierTags';
import { SupplierMessage } from './SupplierMessage';
import { AttachSection } from './SupplierAttach';
import { SupplierCommonData_secureRowLevels } from '../__generated__/SupplierCommonData';
import { SupplierUploadDocument_supplierUploadDocument } from './SupplierAttach/UploadForm/__generated__/SupplierUploadDocument';
import {
  UserEntityMessageCounts,
  UserEntityMessageCountsVariables,
} from 'common/graphql/__generated__/UserEntityMessageCounts';
const ENTITY_NOTES_LIST = loader('./ListSupplierNotes.graphql');
const ENTITY_MESSAGE_COUNTS = loader(
  '../../../../../common/graphql/UserEntityMessageCounts.graphql'
);

const ActionConsts = {
  attach: 'Attach_Action',
  notes: 'Notes_Action',
  history: 'History_Action',
  tags: 'Tags_Action',
  messages: 'Messages_Action',
};
interface ActionMenuProps {
  supplierDetails: Supplier_supplier;
  secureRowLevels: SupplierCommonData_secureRowLevels | undefined | null;
  onUpload?: (
    fileSelected: File,
    documentData: SupplierUploadDocument_supplierUploadDocument,
    toastId: string
  ) => void;
  onRefetch?: () => void;
}
export const ActionMenu: React.FC<ActionMenuProps> = ({
  supplierDetails,
  secureRowLevels,
  onUpload,
  onRefetch,
}) => {
  const styles = useStyles();

  const [selectedKey, setSelectedKey] = useState(ActionConsts.attach);
  const [expandMenu, setExpandMenu] = useState(false);

  const {
    id,
    supplierHistoriesByEntityId,
    entityDocumentsByEntityId,
    entityTagsByEntityId,
    notesByEntityId,
  } = { ...supplierDetails }

  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    ListSupplierNotes,
    ListSupplierNotesVariables
  >(ENTITY_NOTES_LIST, {
    variables: {
      id,
    },
  });
  const getEntityNotes = () => {
    const variables: ListSupplierNotesVariables = {
      id,
    };
    entityNotesListRefetch(variables);
  };
  const getEntityNotesMemo = useCallback(getEntityNotes, [id]);

  const { data: userMessageCounts, refetch: userMessageCountsRefetch } =
    useQuery<UserEntityMessageCounts, UserEntityMessageCountsVariables>(
      ENTITY_MESSAGE_COUNTS,
      {
        variables: {
          entityId: id,
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
      }
    );

  const getSelectedSection = (key: string) => {
    switch (key) {
      case 'Notes_Action':
        return (
          <Stack style={{ margin: '0px -10px 0px -10px' }}>
            <SupplierNotesSection
              supplierId={id}
              data={entityNotesData?.supplier?.notesByEntityId.nodes!}
              listRefreshRequest={getEntityNotesMemo}
            />
          </Stack>
        );
      case 'History_Action':
        return (
          <HistoryActionMenuView
            moduleName="Supplier"
            numberOfItems={4}
            historyData={supplierHistoriesByEntityId.nodes || []}
          />
        );
      case 'Tags_Action':
        return <TagsSection supplierID={id} />;
      case 'Messages_Action':
        return (
          <SupplierMessage
            onCreateMessage={userMessageCountsRefetch}
            userMessageCounts={userMessageCounts}
            supplierID={id}
          />
        );
      case 'Attach_Action':
        return (
          <AttachSection
            secureRowLevel={secureRowLevels?.nodes!}
            supplier={supplierDetails}
            onUpload={onUpload}
            onRefetch={onRefetch}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Stack>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.pivotContainer}
            >
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey || ActionConsts.attach);
                }}
                aria-label="Menu_Actions"
              >
                <PivotItem
                  itemKey={ActionConsts.attach}
                  itemIcon="Attach"
                  headerText="Files"
                  itemCount={entityDocumentsByEntityId.nodes.length || 0}
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.notes}
                  itemIcon="Page"
                  headerText="Notes"
                  itemCount={
                    entityNotesData?.supplier?.notesByEntityId.nodes.length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.history}
                  itemIcon="History"
                  headerText="History"
                  itemCount={supplierHistoriesByEntityId.nodes.length || 0}
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.tags}
                  itemIcon="Tag"
                  headerText="Tags"
                  itemCount={entityTagsByEntityId.nodes.length || 0}
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.messages}
                  itemIcon="Message"
                  headerText="Messages"
                  itemCount={
                    userMessageCounts?.userEntityMessageCounts?.messagesSent!
                  }
                  onRenderItemLink={PivotCustomRender}
                />
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
                ariaLabel="Collapse"
              />
            </Stack>
          </Stack>
          <Stack>{getSelectedSection(selectedKey)}</Stack>
        </Stack>
      ) : (
        <Stack
          horizontalAlign="space-between"
          horizontal
          className={styles.pivotContainer}
        >
          <Stack horizontal verticalAlign="center">
            <Stack horizontal verticalAlign="center">
              <PivotDisplay
                name="Files"
                iconName="Attach"
                count={entityDocumentsByEntityId.nodes.length || 0}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.attach);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Notes"
                iconName="Page"
                count={notesByEntityId.nodes.length || 0}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.notes);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="History"
                iconName="History"
                count={supplierHistoriesByEntityId.nodes.length || 0}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.history);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Tags"
                iconName="Tag"
                count={entityTagsByEntityId.nodes.length || 0}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.tags);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Messages"
                iconName="Message"
                count={
                  userMessageCounts?.userEntityMessageCounts?.messagesSent!
                }
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.messages);
                  setExpandMenu(true);
                }}
              />
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
              ariaLabel="Expand"
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
