import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import React, { useCallback } from 'react';
import { useState } from 'react';
import { useStyles } from './index.styles';
import { Approval } from '../__generated__/Approval';
import { AttachSection } from './ApprovalSetupAttachSection';
import { loader } from 'graphql.macro';
import {
  ListApprovalNotes,
  ListApprovalNotesVariables,
} from './ApprovalSetupNotesSection/__generated__/ListApprovalNotes';
import { useQuery } from '@apollo/client';
import { ApprovalSetupUploadDocument_approvalSetupUploadDocument } from './ApprovalSetupAttachSection/UploadForm/__generated__/ApprovalSetupUploadDocument';
import { ApprovalSetupCommonData_secureRowLevels } from 'settings/account/approvalSetup/__generated__/ApprovalSetupCommonData';
import { ApprovalSetupNotesSection } from './ApprovalSetupNotesSection';
import { HistoryActionMenuView } from 'common/components/History';
import { GlobalActions } from 'common/constants';
import { ListInvoiceNotesVariables } from 'common/graphql/__generated__/ListInvoiceNotes';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { PivotDisplay } from 'common/components/PivotDisplay';
const ENTITY_NOTES_LIST = loader(
  './ApprovalSetupNotesSection/ListApprovalNotes.graphql'
);

interface ApprovalDetailsProps {
  approvalDetails: Approval | undefined;
  onUpload?: (
    fileSelected: File,
    documentData: ApprovalSetupUploadDocument_approvalSetupUploadDocument,
    toastId: string
  ) => void;
  secureRowLevels: ApprovalSetupCommonData_secureRowLevels | undefined | null;
}
export const ActionsMenu: React.FC<ApprovalDetailsProps> = ({
  approvalDetails,
  onUpload,
  secureRowLevels,
}) => {
  const [selectedKey, setSelectedKey] = React.useState<string | undefined>(
    GlobalActions.attach
  );
  const [expandMenu, setExpandMenu] = useState(false);
  const styles = useStyles();
  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    ListApprovalNotes,
    ListApprovalNotesVariables
  >(ENTITY_NOTES_LIST, {
    variables: {
      id: approvalDetails?.approval?.id!,
    },
  });

  const getEntityNotes = () => {
    const variables: ListInvoiceNotesVariables = {
      id: approvalDetails?.approval?.id!,
    };
    entityNotesListRefetch(variables);
  };
  const getEntityNotesMemo = useCallback(getEntityNotes, [
    approvalDetails?.approval?.id,
  ]);

  const getSelectedSection = (key: string) => {
    switch (key) {
      case GlobalActions.attach:
        return (
          <AttachSection
            secureRowLevel={secureRowLevels!}
            approvalSetupDetails={approvalDetails?.approval!}
            onUpload={onUpload}
            entityAttachData={approvalDetails?.approval!}
          />
        );
      case GlobalActions.notes:
        return (
          <ApprovalSetupNotesSection
            data={approvalDetails?.approval?.notesByEntityId.nodes!}
            listRefreshRequest={getEntityNotesMemo}
            id={approvalDetails?.approval?.id!}
          />
        );
      case GlobalActions.history:
        return (
          <HistoryActionMenuView
            moduleName="Approval Setup"
            numberOfItems={4}
            historyData={
              approvalDetails?.approval?.approvalSetupHistoriesByEntityId.nodes || []
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <Stack>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack horizontal className={styles.centerAlign}>
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey!);
                }}
              >
                <PivotItem
                  itemKey={GlobalActions.attach}
                  itemIcon="Attach"
                  headerText="Files"
                  itemCount={
                    approvalDetails?.approval?.entityDocumentsByEntityId.nodes
                      .length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={GlobalActions.notes}
                  itemIcon="Page"
                  headerText="Notes"
                  itemCount={
                    approvalDetails?.approval?.notesByEntityId.nodes.length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={GlobalActions.history}
                  itemIcon="History"
                  headerText="History"
                  itemCount={
                    approvalDetails?.approval?.approvalSetupHistoriesByEntityId
                      .nodes.length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
                ariaLabel="Collapse"
              />
            </Stack>
          </Stack>

          <Stack>{getSelectedSection(selectedKey!)}</Stack>
        </Stack>
      ) : (
        <Stack horizontalAlign="space-between" horizontal>
          <Stack horizontal verticalAlign="center">
            <Stack horizontal verticalAlign="center">
              <PivotDisplay
                name="Files"
                iconName="Attach"
                count={
                  approvalDetails?.approval?.entityDocumentsByEntityId.nodes
                    .length
                }
                onPivotClick={() => {
                  setSelectedKey(GlobalActions.attach);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Notes"
                iconName="Page"
                count={entityNotesData?.approval?.notesByEntityId.nodes.length}
                onPivotClick={() => {
                  setSelectedKey(GlobalActions.notes);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="History"
                iconName="History"
                count={
                  approvalDetails?.approval?.approvalSetupHistoriesByEntityId
                    .nodes.length
                }
                onPivotClick={() => {
                  setSelectedKey(GlobalActions.history);
                  setExpandMenu(true);
                }}
              />
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
