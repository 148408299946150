import { IPanelProps, PanelType } from '@fluentui/react';

// When a interface has an index signature, we lose autocomplete/intellisense on that type.
// To remove the index signature we can use some Typescript magic using conditional types.
// https://stackoverflow.com/a/51956054
type KnownKeys<T> = {
  [K in keyof T]: string extends K ? never : number extends K ? never : K;
} extends { [_ in keyof T]: infer U }
  ? U
  : never;
export type NoIndexSignature<T extends Record<any, any>> = Pick<
  T,
  KnownKeys<T>
>;

export type RequiredNameProps<T> = Omit<T, 'name'> & {
  name: string;
};

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type SVGComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & { title?: string }
>;
export const PANEL_COMMON_WIDTH = 800;
export const PanelCommonProps: Partial<IPanelProps> = {
  hasCloseButton: false,
  isBlocking: true,
  isFooterAtBottom: true,
  closeButtonAriaLabel: 'Close',
  customWidth: '800px',
  type: PanelType.custom,
  styles: {
    header: { marginTop: 100 },
    commands: { marginTop: 0, paddingTop: 0 },
    content: { padding: 0 },
    main: { overflow: 'hidden' },
  },
};

export enum EntityAction {
  Delete = 'Delete',
  Remove = 'Remove',
  Invite = 'Invite',
  Request = 'Request',
  Release = 'Release',
  verify = 'Verify',
}

export enum EntityType {
  Document = 'Document',
  Department = 'Department',
  Folder = 'Folder',
  PayCycle = 'Pay Cycle',
  Payment = 'Payment',
  Transaction = 'Transaction',
  TagSetup = 'Tag',
  UserProfile = 'User Profile',
  ApprovalSetup = 'Approval Setup',
  TagGroup = 'Tag Group',
  ApprovalConfig = 'Approval Configuration',
  User = 'User',
  UserTemplate='User Template',
  CommunicationChannel = 'Communication Channel',
  TagCategory = 'Tag Category',
  CommunicationGroup = 'Communication Group',
  Traveler = 'Traveler',
  Supplier = 'Supplier',
  AccountingPeriodYear = 'Year',
  AccountingPeriod = 'Period',
  Batch = 'Batch',
  CardHolder = 'Card Holder',
  PurchaseOrder = 'Purchase Order',
  InvoiceSchedule = 'Invoice Schedule',
  Trip = 'Trip',
  TravelPlan = 'Travel Plan',
  TravelPolicy = 'Travel Policy',
  TravelPolicyAllowance = 'Travel Policy Allowance',
  CorporateAccount = 'Corporate Account',
  Account = 'Account',
  ChartOfAccount = 'Chart Of Account',
  WorkGroup = 'Work Group',
  DepartmentGroup = 'Department Group',
  Occupation='Occupation'
}
