import * as Yup from 'yup';
const _ = require('lodash');

const validateFieldArray = (fields: any, isTreeValidation: boolean) => {
  if (fields.length > 0) {
    let validationArray = fields[fields.length - 1];

    const removableKeys = ['approvalBranches'];
    if (isTreeValidation) {
      validationArray = _.omit(fields[fields.length - 1], removableKeys);
    }

    if (fields.length === 1) return fields.slice(0, fields.length);
    else {
      const isNullish = Object.values(validationArray).every((value) => {
        if (value === null) return true;
        return false;
      });
      if (fields.length > 1 && isNullish)
        return fields.slice(0, fields.length - 1);
      else return fields.slice(0, fields.length);
    }
  }
};

const validateRequestorFieldArray = (fields: any) => {
  if (fields.length > 1) {
    let validationArray = fields[fields.length - 1];
    const isNullish = Object.values(validationArray).every((value) => {
      if (value === null) return true;
      return false;
    });
    if (fields.length > 1 && isNullish)
      return fields.slice(0, fields.length - 1);
    else return fields.slice(0, fields.length);
  }
};

export const validationSchema = () =>
  Yup.object({
    approvalTypeId: Yup.string().required('Required').nullable(),
    priorityNumber: Yup.string().required('Required').nullable(),
    name: Yup.string().required('Required').nullable(),
    approvalSelectionConditions: Yup.array()
      .transform((fields) => validateRequestorFieldArray(fields))
      .of(
        Yup.object().shape({
          description: Yup.string().required('Required').nullable(),
          importanceLevel: Yup.string().required('Resquired').nullable(),
        })
      ),
    approvalTrees: Yup.array()
      .transform((fields) => validateFieldArray(fields, true))
      .of(
        Yup.object().shape({
          approvalLevel: Yup.string().required('Required').nullable(),
          name: Yup.string().required('Required').nullable(),
          requiredBranchApprovalCount: Yup.string()
            .required('Required')
            .nullable(),
          approvalBranches: Yup.array()
            .transform((fields) => validateFieldArray(fields, false))
            .of(
              Yup.object().shape({
                description: Yup.string().required('Required').nullable(),
              })
            ),
        })
      ),
  });
