import { useQuery } from '@apollo/client';
import { Separator, Stack } from '@fluentui/react';
import { NoDataView } from 'common/components/DataPlaceholders';
import { DocumentList } from 'common/components/DocumentList';
import { loader } from 'graphql.macro';
import { PurchaseOrder_purchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import React from 'react';
import { PurchaseOrderSecureRowLevels } from './__generated__/PurchaseOrderSecureRowLevels';
import { AttachForm } from './AttachForm';
import { PurchaseOrderAvailableDocumentTypes, PurchaseOrderAvailableDocumentTypesVariables } from './__generated__/PurchaseOrderAvailableDocumentTypes';
import { columns } from './coloumn.data';
import { PurchaseOrderUploadDocument_purchaseOrderUploadDocument } from './UploadForm/__generated__/PurchaseOrderUploadDocument';
import { UploadForm } from './UploadForm';
import { PurchaseOrderValues } from 'purchaseOrder/view/types';
import { useFormContext } from 'react-hook-form';
const PURCHASE_ORDER_SECURE_ROW_LEVELS = loader(
  './PurchaseOrderSecureRowLevels.graphql'
);
const PURCHASE_ORDER_AVAILABLE_DOCUMENT_TYPES = loader(
  './PurchaseOrderAvailableDocumentTypes.graphql'
);

interface AttachProps {
  purchaseOrder: PurchaseOrder_purchaseOrder;
  onRemove?: () => void;
  onUpload?: (
    fileSelected: File,
    documentData: PurchaseOrderUploadDocument_purchaseOrderUploadDocument,
    toastId: string
  ) => void;
}

export const Attach: React.FC<AttachProps> = ({
  purchaseOrder,
  onUpload,
  onRemove,
}) => {
  const { formState: { isDirty } } = useFormContext<PurchaseOrderValues>();

  const { data: purchaseOrderSecureRowLevels } = useQuery<PurchaseOrderSecureRowLevels>(
    PURCHASE_ORDER_SECURE_ROW_LEVELS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const { data: availableDocumentTypes } =
    useQuery<
      PurchaseOrderAvailableDocumentTypes,
      PurchaseOrderAvailableDocumentTypesVariables
    >(PURCHASE_ORDER_AVAILABLE_DOCUMENT_TYPES, {
      variables: {
        purchaseOrderTypeId: purchaseOrder.purchaseOrderTypeId
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  return (
    <Stack
      tokens={{ childrenGap: 10 }}
    >
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{
          childrenGap: 10,
        }}
      >
        <AttachForm
          availableDocumentTypes={availableDocumentTypes}
          purchaseOrder={purchaseOrder}
        />
        <Separator vertical />
        <UploadForm
          availableDocumentTypes={availableDocumentTypes}
          purchaseOrder={purchaseOrder}
          onUpload={onUpload}
        />
      </Stack>
      {purchaseOrder?.entityDocumentsByEntityId?.nodes.length! > 0 && (
        <Stack
          tokens={{
            childrenGap: 10,
            padding: "0px 0px 0px 10px"
          }}
        >
          <DocumentList
            isNew={false}
            data={purchaseOrder?.entityDocumentsByEntityId?.nodes || []}
            isDeleteButtonVisible
            isShareAndProtectButtonsVisible
            secureRowLevel={purchaseOrderSecureRowLevels?.secureRowLevels?.nodes}
            onRemoveRow={() => {
              onRemove?.();
            }}
            columns={columns}
            disableUpdate={isDirty}
          />
        </Stack>
      )}
      <Stack tokens={{ padding: '0px 25px' }}>
        <NoDataView
          show={purchaseOrder?.entityDocumentsByEntityId.totalCount === 0}
          title="No Attachments"
        />
      </Stack>
    </Stack>
  );
};
