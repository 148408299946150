import { NetworkStatus, useQuery } from '@apollo/client';
import { IColumn, IconButton, Stack, Text, TooltipHost } from '@fluentui/react';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { AttachDocumentModal } from 'common/components/AttachDocumentModal';
import { DocumentDataCallout } from 'common/components/AttachDocumentModal/DocumentDataCallout';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { DISABLED_FILE_FORMATS } from 'common/constants';
import { EntityDocumentFilter } from 'common/types/globalTypes';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useCallback, useMemo, useState } from 'react';
import { AttachableBatchDocumentsType, AttachBatchDocumentsProps } from '..';
import {
  AttachableBatchTransactionDocuments,
  AttachableBatchTransactionDocumentsVariables,
} from '../../../__generated__/AttachableBatchTransactionDocuments';
import { BatchTransactionAvailableDocumentTypes } from '../../../__generated__/BatchTransactionAvailableDocumentTypes';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { SortOrder } from './types';
import { toOrderByVariable } from './utils';

const ATTACHABLE_BATCH_TRANSACTION_DOCUMENTS = loader(
  '../../../AttachableBatchTransactionDocuments.graphql'
);
const BATCH_TRANSACTION_AVAILABLE_DOCUMENT_TYPES = loader(
  '../../../BatchTransactionAvailableDocumentTypes.graphql'
);

type AttachBatchDocumentsModalProps = AttachBatchDocumentsProps & {
  setOpen: (param: boolean) => void;
};

export const AttachBatchDocumentsModal: React.FC<
  AttachBatchDocumentsModalProps
> = ({ prevSelectedDocuments, onDocumentsAttached, setOpen }) => {
  const styles = useStyles();
  const [selected, setSelected] = useState<AttachableBatchDocumentsType[]>([]);
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });

  const { data: documentTypesData } =
    useQuery<BatchTransactionAvailableDocumentTypes>(
      BATCH_TRANSACTION_AVAILABLE_DOCUMENT_TYPES,
      {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
      }
    );

  const {
    data: documentsList,
    loading: documentsLoading,
    variables: documentsVariables,
    refetch,
    fetchMore,
    networkStatus,
  } = useQuery<
    AttachableBatchTransactionDocuments,
    AttachableBatchTransactionDocumentsVariables
  >(ATTACHABLE_BATCH_TRANSACTION_DOCUMENTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const documentTypeOptions =
    documentTypesData?.batchTransactionAvailableDocumentTypes?.nodes.map(
      (doctype) => ({
        key: doctype.id,
        text: doctype.documentType || '',
      })
    ) || [];

  const transformedData = useMemo(() => {
    const documents =
      documentsList?.attachableBatchTransactionDocuments?.nodes.map(
        (documents) =>
          ({
            ...documents,
            available: documents.documentAppliedAmounts?.remainingTotal,
            isoCode: documents.currency?.isoCode,
          } as AttachableBatchDocumentsType)
      );

    if (prevSelectedDocuments?.length && documents?.length) {
      const filteredArray = documents.filter(
        ({ id: id1 }) =>
          !prevSelectedDocuments.some(({ id: id2 }) => id2 === id1)
      );
      return filteredArray;
    }
    return documents;
  }, [documentsList, prevSelectedDocuments]);

  const onRenderItemColumn = (
    item?: AttachableBatchDocumentsType,
    _index?: number,
    column?: IColumn
  ) => {
    if (!item || !column) return undefined;
    const fieldContent = item[
      column.fieldName as keyof AttachableBatchDocumentsType
    ] as string | null;
    const viewDocumentVisible =
      item._isProtected! || DISABLED_FILE_FORMATS.includes(item._fileType!);
    switch (column.key) {
      case 'indexAmount':
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <AmountTextView
              value={fieldContent}
              className={styles.contentColumnAlignRight}
            />
          </Stack>
        );
      case '_documentPoolName':
        return (
          <Stack
            horizontal
            className={styles.columnHeight}
            verticalAlign="center"
            tokens={{ childrenGap: 5 }}
          >
            <Text>{fieldContent}</Text>
          </Stack>
        );
      case 'fileReference':
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <DocumentDataCallout item={item} />
          </Stack>
        );
      case '_uploadDate':
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <Text>{getGlobalDateFormat(item._uploadDate!)}</Text>
          </Stack>
        );
      case 'view':
        return (
          <Stack
            className={styles.columnHeight}
            tokens={{ childrenGap: 10 }}
            horizontal
            verticalAlign="center"
          >
            <TooltipHost content="View" id="tooltipId">
              <IconButton
                disabled={viewDocumentVisible}
                iconProps={{ iconName: 'View' }}
                onClick={() =>
                  setDocViewState({
                    isOpen: true,
                    title: item.fileReference,
                    entityDocumentId: item.id,
                    _fileType: item._fileType!,
                  })
                }
              />
            </TooltipHost>
          </Stack>
        );
      case 'indexTransactionDate':
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            {item.indexTransactionDate && (
              <Text>
                {dateFormat(dateConvertions(item.indexTransactionDate))}
              </Text>
            )}
          </Stack>
        );

      default:
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <Text>{fieldContent}</Text>
          </Stack>
        );
    }
  };

  const onSelectionChanged = useCallback(
    (items: AttachableBatchDocumentsType[]) => {
      setSelected(items);
    },
    []
  );

  const reload = useCallback(
    async (sort?: SortOrder) =>
      await refetch({ after: null, orderBy: toOrderByVariable(sort) }),
    [refetch]
  );

  const loadMore = useCallback(async () => {
    await fetchMore?.({
      variables: {
        ...documentsVariables,
        after:
          documentsList?.attachableBatchTransactionDocuments?.pageInfo
            .endCursor,
      },
    });
  }, [fetchMore, documentsVariables, documentsList]);

  const onFiltersReload = useCallback(
    async (filter: EntityDocumentFilter | undefined) =>
      await refetch({ ...documentsVariables, filter }),
    [documentsVariables, refetch]
  );

  const _onAttach = async () => {
    const attachedDocuments = selected.map((item) => {
      return {
        ...item,
      } as AttachableBatchDocumentsType;
    });
    // const populativeData = {
    //   ...attachedDocuments.filter((item) => item._isAccountingDocument)[0],
    // };
    // const { indexTransactionDate } = { ...populativeData };
    // if (populativeData) {
    //   setTransactionFieldValues('defaultPaymentDate', indexTransactionDate);
    //   setTransactionFieldValues('description', populativeData.indexDescription);
    //   setTransactionFieldValues(
    //     'controlTotalAmount',
    //     populativeData.indexAmount
    //   );
    // }

    // onDocumentsAttached({
    //   attachedDocuments,
    // });

    onDocumentsAttached(attachedDocuments);
    setOpen(false);
  };

  const onDocumentTypeReload = useCallback(
    async (documentTypeId: number | null | undefined) => {
      await refetch?.({
        ...documentsVariables,
        documentTypeId,
      });
    },
    [refetch, documentsVariables]
  );

  const attachBtnDisabled = selected.length === 0;

  const hasNextPage =
    documentsList?.attachableBatchTransactionDocuments?.pageInfo.hasNextPage;

  return (
    <>
      <AttachDocumentModal
        columns={columns}
        loading={documentsLoading}
        modalWidth={1450}
        items={
          networkStatus === NetworkStatus.refetch ||
          networkStatus === NetworkStatus.setVariables
            ? undefined
            : transformedData
        }
        hasNextPage={hasNextPage}
        attachLoading={false}
        availableDocumentTypes={documentTypeOptions || []}
        setOpen={setOpen}
        onSortReload={reload}
        onLoadMore={loadMore}
        onFiltersReload={onFiltersReload}
        onDocumentTypeChange={onDocumentTypeReload}
        onSelectionChanged={onSelectionChanged}
        attachDisabled={attachBtnDisabled}
        dropdownDisabled={selected.length > 0}
        onRenderItemColumn={onRenderItemColumn}
        onAttachDocuments={_onAttach}
        onCancel={() => setSelected([])}
      />
      <DocumentViewModal
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </>
  );
};
