import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  ContextualMenu,
  DatePicker,
  DayOfWeek,
  IDragOptions,
  IModalStyles,
  Modal,
  Separator,
  Stack,
  TextField,
  TooltipHost,
  makeStyles,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import {
  TravelerApprovalCreate,
  TravelerApprovalCreateVariables,
} from 'common/graphql/__generated__/TravelerApprovalCreate';
import {
  TravelerApprovalRevoke,
  TravelerApprovalRevokeVariables,
} from 'common/graphql/__generated__/TravelerApprovalRevoke';
import {
  TravelerCreate,
  TravelerCreateVariables,
  TravelerCreate_travelerCreate_traveler,
} from 'common/graphql/__generated__/TravelerCreate';
import {
  TravelerUpdate,
  TravelerUpdateVariables,
} from 'common/graphql/__generated__/TravelerUpdate';
import {
  ApprovalRequestInput,
  TravelerAddressInput,
  TravelerAddressUpdateTypeInput,
  TravelerApprovalRevokeInput,
  TravelerDietaryRequirementInput,
  TravelerInput,
  TravelerPatch,
  TravelerPolicyAllowanceInput,
  UploadStatusType,
} from 'common/types/globalTypes';
import { dateFormat, onFormatDate } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { BasicForm } from './BasicForm';
import { DietaryRequirements } from './DietaryRequirements';
import { Footer } from './Footer';
import { Header } from './Header';
import { SeatPreferenceOptions, SeatingPreference } from './SeatingPreference';
import { ShimmerView } from './Shimmer/ShimmerViews';
import { TravelPreferences } from './TravelPreferences';
import {
  TravelerDetails,
  TravelerDetailsVariables,
} from './__generated__/TravelerDetails';
import { TravelerModalDropdownsData } from './__generated__/TravelerModalDropdownsData';
import { TRAVELER_INITIAL_VALUES } from './constants';
import { AddressRowValues, TravelerValues } from './interfaces';
import {
  getDefaultValues,
  getDeletedDietaryRequirementsValue,
  getDeletedTravelerPolicyAllowances,
  getNewDietaryRequirementsValue,
  getNewTravelerPolicyAllowances,
  getUpdatedDietaryRequirementsValue,
  getUpdatedTravelerPolicyAllowances,
} from './utils';
import { validationSchema } from './validation';
import { useParams } from 'react-router-dom';
import { ActionMenu } from './ActionMenu';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { Trip_trip } from '../../../../../__generated__/Trip';
import {
  TravelAuthorization,
  TravelAuthorizationVariables,
} from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { UrgencyToggle } from 'common/components/UrgencySelector/UrgencyToggle';
const TRAVELER_DETAILS = loader('./TravelerDetails.graphql');
const TRAVELER_AUTHORIZATION = loader(
  '../../../../../../../../TravelAuthorization.graphql'
);
const TRAVELER_DROPDOWN_DATA = loader('./TravelerModalDropdownsData.graphql');
const TRAVELER_CREATE = loader(
  '../../../../../../../../../../../common/graphql/TravelerCreate.graphql'
);
const TRAVELER_UPDATE = loader(
  '../../../../../../../../../../../common/graphql/TravelerUpdate.graphql'
);
const TRAVELER_APPROVAL_REVOKE = loader(
  '../../../../../../../../../../../common/graphql/TravelerApprovalRevoke.graphql'
);
const TRAVELER_APPROVAL_CREATE = loader(
  '../../../../../../../../../../../common/graphql/TravelerApprovalCreate.graphql'
);
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../../../../../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
const CONFIRM_REQUEST_DIALOG_TITLE =
  'Are you sure you want to request this traveler for approval?';
const CONFIRM_REQUEST_DIALOG_SUBTEXT =
  'Your traveler will be requested for approval.';
const CONFIRM_AMEND_DIALOG_TITLE = 'Are you sure?';
const CONFIRM_AMEND_DIALOG_SUBTEXT =
  'This will remove the Traveler from the approval cycle and require re-approval.';
const FINALIZED_BY_DATE_TOOLTIP =
  'Automatically promotes to urgent notifications when not approved by this date';
const useStyles = makeStyles(() => ({
  formContainer: {
    width: 850,
    height: '80vh',
    paddingTop: 20,
  },
  requestComment: {
    marginTop: 10,
  },
}));

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
};

const modalStyles: Partial<IModalStyles> = {
  scrollableContent: {
    overflowY: 'visible',
  },
};

interface TravelerModalProps {
  setOpen: (open: boolean) => void;
  selectedTravelerId: string | undefined;
  isNew: boolean;
  tripData: Trip_trip | null | undefined;
  onTravelerCreate: (param: TravelerCreate_travelerCreate_traveler) => void;
  onTravelerDelete: () => void;
}

export const TravelerModal: React.FC<TravelerModalProps> = ({
  setOpen,
  selectedTravelerId,
  isNew,
  tripData,
  onTravelerCreate,
  onTravelerDelete,
}) => {
  const styles = useStyles();
  const client = useApolloClient();
  const { travelId } = useParams<{ travelId: string | undefined }>();
  const { addToast, updateToast } = useToasts();
  const saveAnotherPO = useRef<boolean>(false);
  const fetched = useRef<boolean>(false);
  const [requestComment, setRequestComment] = useState<string>();
  const [requiredDate, setRequiredDate] = React.useState<string | undefined>();
  const [urgencyLevel, setUrgencyLevel] = React.useState<number>(1);
  const [hideConfirmRequestDialog, { toggle: toggleConfirmDialog }] =
    useBoolean(true);
  const [hideConfirmAmendDialog, { toggle: toggleConfirmAmendDialog }] =
    useBoolean(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    setIsEdit(!isNew);
  }, [isNew]);

  const [
    fetchTravelerDetails,
    { data: travelerDetails, loading: travelerDetailsLoading, refetch },
  ] = useLazyQuery<TravelerDetails, TravelerDetailsVariables>(
    TRAVELER_DETAILS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const { data: travelerModalDropdownsData } =
    useQuery<TravelerModalDropdownsData>(TRAVELER_DROPDOWN_DATA, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  const { dietaryRequirements } = { ...travelerModalDropdownsData };

  const { traveler } = { ...travelerDetails };

  const {
    id,
    _rowTimestamp,
    _isUpdatable,
    _isStagedApprovalRequest,
    _isApprovalRevocable,
    _isUpdateTravelerOverride,
  } = { ...traveler };
  const isUpdatable = isEdit ? !!_isUpdatable : true;
  const isUpdateTravelerOverride = isEdit ? !!_isUpdateTravelerOverride : true;
  const [createTraveler, { loading: travelerCreateLoading }] = useMutation<
    TravelerCreate,
    TravelerCreateVariables
  >(TRAVELER_CREATE, { errorPolicy: 'all' });

  const [updateTraveler, { loading: travelerUpdateLoading }] = useMutation<
    TravelerUpdate,
    TravelerUpdateVariables
  >(TRAVELER_UPDATE, { errorPolicy: 'all' });

  const [travelerApprovalCreate, { loading: travelerApprovalLoading }] =
    useMutation<TravelerApprovalCreate, TravelerApprovalCreateVariables>(
      TRAVELER_APPROVAL_CREATE,
      {
        errorPolicy: 'all',
        onCompleted: () => {
          setUrgencyLevel(1);
        },
      }
    );

  const [travelerApprovalRevoke, { loading: travelerApprovalRevokeLoading }] =
    useMutation<TravelerApprovalRevoke, TravelerApprovalRevokeVariables>(
      TRAVELER_APPROVAL_REVOKE,
      { errorPolicy: 'all' }
    );

  const formMethods = useForm<TravelerValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const {
    handleSubmit,
    trigger,
    reset,
    formState: { isDirty, isValid, isSubmitting },
  } = { ...formMethods };

  const triggerCallBack = useCallback(trigger, []);

  useEffect(() => {
    triggerCallBack();
  }, [triggerCallBack]);

  const defaultValues = getDefaultValues({
    isNew: !isEdit,
    travelerDetails,
    totalDietaryRequirements: dietaryRequirements?.nodes.length,
  });

  const onHandleSubmit = async (values: TravelerValues) => {
    const {
      travelerAddressesByTravelerId,
      travelerPolicyAllowancesByTravelerId,
      travelerDietaryRequirementsByTravelerId,
      seatPreference,
      travelerCompanionTickets,
      ...travelerFields
    } = values;
    if (!isEdit) {
      const { data, errors } = await createTraveler({
        variables: {
          input: {
            isTravelAuthMutation: true,
            traveler: {
              ...travelerFields,
              travelerCompanionTickets: Number(travelerCompanionTickets),
              isIsleSeatPreferred:
                seatPreference === SeatPreferenceOptions.Isle ? true : false,
              isWindowSeatPreferred:
                seatPreference === SeatPreferenceOptions.Window ? true : false,
            } as TravelerInput,
            travelerAddresses: travelerAddressesByTravelerId?.map(
              ({ id, _rowTimestamp, ...addr }) => addr as TravelerAddressInput
            ),
            travelerPolicyAllowances: travelerPolicyAllowancesByTravelerId
              ?.filter((ele) => ele && ele.isChecked)
              ?.map(
                (ele) =>
                  ({
                    travelPolicyAllowanceId: ele.id,
                    allowanceOverrideAmount: ele.allowanceOverrideAmount,
                  } as TravelerPolicyAllowanceInput)
              ),
            travelerDietaryRequirements: travelerDietaryRequirementsByTravelerId
              ?.filter((item) => item.dietaryRequirementId !== null)
              ?.map(
                ({
                  id,
                  _rowTimestamp,
                  _isDeletable,
                  isDescriptionRequired,
                  ...requirement
                }) => requirement as TravelerDietaryRequirementInput
              ),
          },
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Traveler added successfully', {
          appearance: 'success',
        });
        if (saveAnotherPO.current) {
          reset(TRAVELER_INITIAL_VALUES);
          setOpen(false);
        } else {
          setIsEdit(true);
          fetchTravelerDetails({
            variables: {
              id: data?.travelerCreate?.traveler?.id!,
            },
          });
        }
        onTravelerCreate(data?.travelerCreate?.traveler!);
      }
    } else {
      if (_isUpdatable || isUpdateTravelerOverride) {
        const updatedTravelerFields = {
          ...travelerFields,
          isIsleSeatPreferred:
            seatPreference === SeatPreferenceOptions.Isle ? true : false,
          isWindowSeatPreferred:
            seatPreference === SeatPreferenceOptions.Window ? true : false,
          travelerCompanionTickets: Number(travelerCompanionTickets),
        };

        const travelerPatch: TravelerPatch = Object.entries(
          updatedTravelerFields
        ).reduce((res, [key, val]) => {
          if (val !== defaultValues[key as keyof TravelerValues]) {
            return { ...res, [key]: val };
          }
          return res;
        }, {});

        const { id, _rowTimestamp, ...updatedAddress } =
          travelerAddressesByTravelerId![0];

        const updatedFields =
          travelerAddressesByTravelerId &&
          travelerAddressesByTravelerId?.length > 0 &&
          Object.entries(updatedAddress).reduce((res, [key, val]) => {
            if (
              val !==
              defaultValues?.travelerAddressesByTravelerId![0][
                key as keyof AddressRowValues
              ]
            )
              return { ...res, [key]: val };
            return res;
          }, {});
        const updatedAddresses = {
          id,
          rowTimestamp: _rowTimestamp,
          travelerAddressPatch: updatedFields,
        } as TravelerAddressUpdateTypeInput;

        const newDietaryRequirements = getNewDietaryRequirementsValue(
          travelerDietaryRequirementsByTravelerId
        );
        const updatedDietaryRequirements = getUpdatedDietaryRequirementsValue(
          defaultValues.travelerDietaryRequirementsByTravelerId,
          travelerDietaryRequirementsByTravelerId
        );
        const deletedDietaryRequirements = getDeletedDietaryRequirementsValue(
          defaultValues.travelerDietaryRequirementsByTravelerId,
          travelerDietaryRequirementsByTravelerId
        );

        const newTravelerPolicyAllowances = getNewTravelerPolicyAllowances(
          travelerPolicyAllowancesByTravelerId
        );
        const updatedTravelerPolicyAllowances =
          getUpdatedTravelerPolicyAllowances(
            defaultValues.travelerPolicyAllowancesByTravelerId,
            travelerPolicyAllowancesByTravelerId
          );
        const deletedTravelerPolicyAllowances =
          getDeletedTravelerPolicyAllowances(
            defaultValues.travelerPolicyAllowancesByTravelerId,
            travelerPolicyAllowancesByTravelerId
          );

        const { data, errors } = await updateTraveler({
          variables: {
            input: {
              isTravelAuthMutation: true,
              id: travelerDetails?.traveler?.id!,
              rowTimestamp: travelerDetails?.traveler?._rowTimestamp!,
              travelerPatch: !isEmpty(travelerPatch)
                ? travelerPatch
                : undefined,
              travelerAddressesUpdate:
                [updatedAddresses] && [updatedAddresses].length > 0
                  ? [updatedAddresses]
                  : undefined,
              travelerDietaryRequirementsCreate: newDietaryRequirements,
              travelerDietaryRequirementsUpdate: updatedDietaryRequirements,
              travelerDietaryRequirementsDelete: deletedDietaryRequirements,
              travelerPolicyAllowancesCreate: newTravelerPolicyAllowances || [],
              travelerPolicyAllowancesUpdate:
                updatedTravelerPolicyAllowances || [],
              travelerPolicyAllowancesDelete:
                deletedTravelerPolicyAllowances || [],
            },
          },
        });
        if (errors?.length) {
          addToast(errors[0].message, {
            appearance: 'error',
          });
        } else {
          onTravelerCreate(data?.travelerUpdate?.traveler!);
          addToast('Traveler edited successfully', {
            appearance: 'success',
          });
        }
      }
    }
  };

  const isLoading: boolean =
    travelerCreateLoading ||
    travelerUpdateLoading ||
    travelerApprovalLoading ||
    travelerApprovalRevokeLoading;

  const showRequestApproval = isEdit && !!_isStagedApprovalRequest;
  const showAmendApproval = isEdit && !!_isApprovalRevocable;

  useEffect(() => {
    if (travelerDetails && !fetched.current) {
      const defaultValues = getDefaultValues({
        isNew: !isEdit,
        travelerDetails,
        totalDietaryRequirements: dietaryRequirements?.nodes.length,
      });
      reset(defaultValues);
      fetched.current = true;
    } else {
      const defaultValues = getDefaultValues({
        isNew: !isEdit,
        travelerDetails,
        totalDietaryRequirements: dietaryRequirements?.nodes.length,
      });
      reset(defaultValues);
    }
  }, [travelerDetails, isNew, reset, isEdit, dietaryRequirements]);

  useEffect(() => {
    if (selectedTravelerId) {
      fetchTravelerDetails({
        variables: {
          id: selectedTravelerId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTravelerId]);

  const { travelPolicy } = { ...travelerDetails?.traveler };

  const refetchDetails = async () => {
    const res = await client.query<TravelerDetails, TravelerDetailsVariables>({
      query: TRAVELER_DETAILS,
      variables: { id: id! },
      fetchPolicy: 'network-only',
    });
    if (!!res.data.traveler) onTravelerCreate(res.data.traveler);
    await client.query<TravelAuthorization, TravelAuthorizationVariables>({
      query: TRAVELER_AUTHORIZATION,
      variables: { id: travelId! },
      fetchPolicy: 'network-only',
    });
  };

  return (
    <Modal
      isOpen
      isBlocking
      onDismiss={() => setOpen(false)}
      dragOptions={DragOptions}
      styles={modalStyles}
    >
      <Header
        isEdit={isEdit}
        traveler={traveler}
        loading={travelerDetailsLoading}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        setOpen={setOpen}
        onTravelerDelete={onTravelerDelete}
      />
      <Stack
        className={styles.formContainer}
        tokens={{
          childrenGap: 10,
        }}
      >
        <FormProvider {...formMethods}>
          {isEdit && travelerDetailsLoading ? (
            <ShimmerView />
          ) : (
            <Stack style={{ paddingBottom: 30 }}>
              {isEdit && !!travelerDetails?.traveler && (
                <>
                  <ActionMenu
                    traveler={travelerDetails.traveler}
                    onRemove={refetch}
                    onUpload={async (fileSelected, document, toastId) => {
                      const observer = client.subscribe({
                        query: DOCUMENT_UPLOAD_STATUS,
                        variables: {
                          documentId: document.document._documentFileId!,
                        },
                      });
                      const subscription = observer.subscribe((response) => {
                        const subscribedData =
                          response.data as OnDocumentUploadStatus;

                        const { status, document } = {
                          ...subscribedData.documentUploadStatus,
                        };

                        if (!document) {
                          if (status.type === UploadStatusType.VALIDATING) {
                            updateToast(toastId!, {
                              content: status.message
                                ? `Validating files ${fileSelected.name} - ${status.message}`
                                : `Validating files ${fileSelected.name}`,
                              appearance: 'info',
                              autoDismiss: false,
                            });
                          } else if (
                            status.type === UploadStatusType.EXTRACTING
                          ) {
                            updateToast(toastId!, {
                              content: status.message
                                ? `Extracting data from ${fileSelected.name} - ${status.message}`
                                : `Extracting data from ${fileSelected.name}`,
                              appearance: 'info',
                              autoDismiss: false,
                            });
                          } else if (status.type === UploadStatusType.FAILURE) {
                            subscription.unsubscribe();
                            updateToast(toastId!, {
                              content: status.message
                                ? `Upload of ${fileSelected.name} failed - ${status.message}`
                                : `Upload of ${fileSelected.name} failed`,
                              appearance: 'error',
                              autoDismiss: true,
                            });
                          } else if (status.type === UploadStatusType.WARNING) {
                            subscription.unsubscribe();
                            updateToast(toastId!, {
                              content: status.message
                                ? `Warning for file ${fileSelected.name}: ${status.message}`
                                : `Warning for file ${fileSelected.name}`,
                              appearance: 'warning',
                              autoDismiss: true,
                            });
                          }
                        } else {
                          subscription.unsubscribe();
                          updateToast(toastId!, {
                            content: status.message
                              ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                              : `Successfully uploaded ${fileSelected.name}`,
                            appearance: 'success',
                            autoDismiss: true,
                          });
                          refetch?.();
                        }
                      });
                    }}
                  />
                  <Separator />
                </>
              )}
              <BasicForm
                isNew={!isEdit}
                isUpdatable={isUpdatable}
                isUpdateTravelerOverride={isUpdateTravelerOverride}
                traveler={traveler}
                commonData={travelerModalDropdownsData}
                tripData={tripData}
                onTravelerCreate={onTravelerCreate}
              />
              <Separator />
              <TravelPreferences
                isEdit={isEdit}
                commonData={travelerModalDropdownsData}
                travelPolicyDetails={travelPolicy}
                isUpdatable={isUpdatable}
                isUpdateTravelerOverride={isUpdateTravelerOverride}
              />
              <Separator />
              <DietaryRequirements
                commonData={travelerModalDropdownsData}
                inputsDisabled={!isUpdatable}
              />
              <Separator />
              <SeatingPreference inputsDisabled={!isUpdatable} />
            </Stack>
          )}
          <Footer
            isNew={!isEdit}
            isLoading={isLoading}
            isDisabled={!isDirty || !isValid || isSubmitting}
            showRequestApproval={showRequestApproval}
            showAmendApproval={!!showAmendApproval}
            onSaveAndClose={(value) => (saveAnotherPO.current = value)}
            onSave={handleSubmit(onHandleSubmit)}
            onCancel={() => setOpen(false)}
            onToggleConfirmDialog={toggleConfirmDialog}
            onToggleAmendDialog={toggleConfirmAmendDialog}
          />
        </FormProvider>
      </Stack>
      <ConfirmDialog
        hidden={hideConfirmRequestDialog}
        title={CONFIRM_REQUEST_DIALOG_TITLE}
        subText={CONFIRM_REQUEST_DIALOG_SUBTEXT}
        onDismiss={toggleConfirmDialog}
        minWidth={500}
        onConfirm={async () => {
          toggleConfirmDialog();
          const inputVariables: ApprovalRequestInput = {
            entityId: id!,
            rowTimestamp: _rowTimestamp!,
            comments: requestComment,
          };
          if (requiredDate) inputVariables.requiredDate = requiredDate;
          const { errors } = await travelerApprovalCreate({
            variables: {
              input: {
                entityApproval: [inputVariables],
                urgencyLevel: urgencyLevel,
              },
            },
          });
          if (errors?.length)
            addToast(errors[0].message, {
              appearance: 'error',
            });
          else {
            refetchDetails();
            setRequestComment('');
            setRequiredDate('');
            addToast('Request sent for approval', {
              appearance: 'success',
            });
          }
        }}
      >
        <Stack tokens={{ childrenGap: 10 }}>
          <TextField
            className={styles.requestComment}
            multiline
            rows={3}
            value={requestComment}
            placeholder="Please write your comment here (optional)"
            resizable={false}
            onChange={(_event, value) => setRequestComment(value || '')}
          />
          <TooltipHost content={FINALIZED_BY_DATE_TOOLTIP}>
            <DatePicker
              minDate={new Date()}
              firstDayOfWeek={DayOfWeek.Sunday}
              placeholder="Finalized by date (optional)"
              ariaLabel="Date"
              formatDate={onFormatDate}
              firstWeekOfYear={1}
              showMonthPickerAsOverlay
              showGoToToday={false}
              onSelectDate={(date) =>
                setRequiredDate(dateFormat(date!.toString()))
              }
            />
          </TooltipHost>
          <Stack tokens={{ padding: '6px 0px 0px 0px' }}>
            <UrgencyToggle
              onToggle={(data) => {
                setUrgencyLevel(data ? 0 : 1);
              }}
            />
          </Stack>
        </Stack>
      </ConfirmDialog>
      <ConfirmDialog
        isAmendButton
        hidden={hideConfirmAmendDialog}
        title={CONFIRM_AMEND_DIALOG_TITLE}
        subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
        onDismiss={toggleConfirmAmendDialog}
        onConfirm={async () => {
          toggleConfirmAmendDialog();

          const inputVariables: TravelerApprovalRevokeInput = {
            entityId: id!,
            rowTimestamp: _rowTimestamp!,
          };
          const { errors } = await travelerApprovalRevoke({
            variables: {
              input: inputVariables,
            },
          });
          if (errors?.length)
            addToast(errors[0].message, {
              appearance: 'error',
            });
          else {
            refetchDetails();
            addToast('Approval amended successfully', {
              appearance: 'success',
            });
          }
        }}
      />
    </Modal>
  );
};
