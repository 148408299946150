import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { getFormattedAmountValue } from 'common/components/AmountView/utils';
import { InvoiceDetails } from '../../../__generated__/InvoiceDetails';
import { InvoiceTransactionTypes } from 'ap/signing/__generated__/InvoiceTransactionTypes';

const styles = StyleSheet.create({
  headerStyle: {
    fontSize: 8,
    fontWeight: 'bold',
    color: '#605e5c',
  },
  amountHeaderStyle: {
    fontSize: 8,
    fontWeight: 'bold',
    color: '#605e5c',
    alignSelf: 'flex-end',
  },
  normalFont: {
    fontSize: 8,
    color: '#605e5c',
  },
  amountFont: {
    fontSize: 8,
    color: '#605e5c',
    alignSelf: 'flex-end',
  },
  totalAmountFont: {
    fontSize: 8,
    color: '#0078d4',
    marginTop: 15,
    alignSelf: 'flex-end',
    marginRight: 190,
  },
  tableHeaderWrapper: {
    flexDirection: 'row',
    marginHorizontal: 20,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 0.2,
  },
  centerHeader: {
    fontSize: 8,
    fontWeight: 'semibold',
    color: '#605e5c',
  },
  flexEndHeader: {
    fontSize: 8,
    color: '#605e5c',
    fontWeight: 'semibold',
    alignSelf: 'flex-end',
  },
  tableRowWrapper: {
    flexDirection: 'row',
    marginHorizontal: 20,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallFlex: {
    flex: 0.5,
  },
  singleFlex: {
    flex: 1,
  },
  mediumFlex: {
    flex: 1.5,
  },
  largeFlex: {
    flex: 2,
  },
});

interface TableProps {
  data: InvoiceDetails;
  transactionTypeData?: InvoiceTransactionTypes;
}

const headerTableData = [
  {
    headerTitle: 'Account',
    headerStyle: styles.headerStyle,
    wrapperStyle: styles.singleFlex,
  },
  {
    headerTitle: 'Project',
    headerStyle: styles.headerStyle,
    wrapperStyle: styles.singleFlex,
  },
  {
    headerTitle: 'Set',
    headerStyle: styles.headerStyle,
    wrapperStyle: styles.singleFlex,
  },
  {
    headerTitle: 'Item Description',
    headerStyle: styles.headerStyle,
    wrapperStyle: styles.mediumFlex,
  },
  {
    headerTitle: 'Incentive',
    headerStyle: styles.headerStyle,
    wrapperStyle: styles.mediumFlex,
  },
  {
    headerTitle: '1099/T4',
    headerStyle: styles.headerStyle,
    wrapperStyle: styles.singleFlex,
  },
  {
    headerTitle: 'Ins',
    headerStyle: styles.headerStyle,
    wrapperStyle: styles.singleFlex,
  },
  {
    headerTitle: 'Amount',
    headerStyle: styles.amountHeaderStyle,
    wrapperStyle: styles.singleFlex,
  },
  {
    headerTitle: 'FF1',
    headerStyle: styles.amountHeaderStyle,
    wrapperStyle: styles.singleFlex,
  },
  {
    headerTitle: 'FF2',
    headerStyle: styles.amountHeaderStyle,
    wrapperStyle: styles.singleFlex,
  },
  {
    headerTitle: 'FF3',
    headerStyle: styles.amountHeaderStyle,
    wrapperStyle: styles.singleFlex,
  },
  {
    headerTitle: 'FF4',
    headerStyle: styles.amountHeaderStyle,
    wrapperStyle: styles.singleFlex,
  },
];

export const Table: React.FC<TableProps> = ({ data, transactionTypeData }) => {
  const { nodes, aggregates } = { ...data.invoice?.invoiceDistributions };
  const { distributionAmount } = { ...aggregates?.sum }
  const tableData = nodes || [];

  const returnIncentiveType = (incentiveId: string | null) => {
    let incentiveType = '';
    if (incentiveId !== null) {
      for (let types of transactionTypeData?.productionIncentives?.nodes!) {
        if (incentiveId === types.id) {
          incentiveType = types?.incentiveType?.incentiveType!;
        }
      }
    }

    return incentiveType;
  };

  const returntaxType = (taxId: number | null) => {
    let taxType = '';
    if (taxId !== null) {
      for (let types of transactionTypeData?.tax1099T4Types?.nodes!) {
        if (taxId.toString() === types.id.toString()) {
          taxType = types?.abbreviation!;
        }
      }
    }

    return taxType;
  };

  if (!tableData.length) {
    return null;
  } else
    return (
      <>
        <View style={{ marginHorizontal: 20, marginTop: 20 }}>
          <Text style={{ fontSize: 12 }}>Accounting</Text>
        </View>
        <View style={styles.tableHeaderWrapper}>
          {headerTableData.map((item, _index) => (
            <View style={item.wrapperStyle} key={_index}>
              <Text style={item.headerStyle!}>{item.headerTitle}</Text>
            </View>
          ))}
        </View>

        {tableData.map((item) => {
          return (
            <View key={item.id} style={styles.tableRowWrapper}>
              <View style={[styles.singleFlex]}>
                <Text style={styles.normalFont}>
                  {item?.accountReference || '-'}
                </Text>
              </View>
              <View style={[styles.singleFlex]}>
                <Text style={styles.normalFont}>
                  {item?.projectReference || '-'}
                </Text>
              </View>
              <View style={[styles.singleFlex]}>
                <Text style={styles.normalFont}>
                  {item?.setReference || '-'}
                </Text>
              </View>
              <View style={[styles.mediumFlex]}>
                <Text style={styles.normalFont}>
                  {item?.additionalDescription || '-'}
                </Text>
              </View>
              <View style={styles.mediumFlex}>
                <Text style={styles.normalFont}>
                  {item?.productionIncentiveId
                    ? returnIncentiveType(item?.productionIncentiveId!)
                    : '-'}
                </Text>
              </View>
              <View style={styles.singleFlex}>
                <Text style={styles.normalFont}>
                  {item?.tax1099T4TypeId
                    ? returntaxType(item?.tax1099T4TypeId!)
                    : '-'}
                </Text>
              </View>

              <View style={styles.singleFlex}>
                <Text style={styles.normalFont}>
                  {item?.referenceCode5 || '-'}
                </Text>
              </View>
              <View style={styles.singleFlex}>
                <Text style={styles.amountFont}>
                  {item?.distributionAmount
                    ? getFormattedAmountValue(item?.distributionAmount)
                    : '-'}
                </Text>
              </View>
              <View style={styles.singleFlex}>
                <Text style={styles.amountFont}>
                  {item?.referenceCode1 || '-'}
                </Text>
              </View>
              <View style={styles.singleFlex}>
                <Text style={styles.amountFont}>
                  {item?.referenceCode2 || '-'}
                </Text>
              </View>
              <View style={styles.singleFlex}>
                <Text style={styles.amountFont}>
                  {item?.referenceCode3 || '-'}
                </Text>
              </View>
              <View style={styles.singleFlex}>
                <Text style={styles.amountFont}>
                  {item?.referenceCode4 || '-'}
                </Text>
              </View>
            </View>
          );
        })}
        {!!tableData.length && (
          <View style={{ borderBottomWidth: 0.1, paddingBottom: 20 }}>
            <Text style={styles.totalAmountFont}>
              {getFormattedAmountValue(
                !!distributionAmount ? distributionAmount.toString() : '-'
              )}
            </Text>
          </View>
        )}
      </>
    );
};
