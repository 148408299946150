import React from 'react';
import { UserTemplatesRowItem } from '..';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { EntityDeleteInput } from 'common/types/globalTypes';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityAction, EntityType } from 'common/types/utility';
import {
  UserTemplateDelete,
  UserTemplateDeleteVariables,
} from '../__generated__/UserTemplateDelete';

const USER_TEMPLATE_DELETE = loader('../UserTemplateDelete.graphql');

interface DeleteTemplateProps {
  selectedRows: UserTemplatesRowItem[];
}

export const DeleteTemplate: React.FC<DeleteTemplateProps> = ({
  selectedRows,
}) => {
  const { addToast } = useToasts();

  const [deleteUserProfile] = useMutation<
    UserTemplateDelete,
    UserTemplateDeleteVariables
  >(USER_TEMPLATE_DELETE, {
    errorPolicy: 'all',
  });

  const _onConfirm = async () => {
    const deletableRows: UserTemplatesRowItem[] = selectedRows.filter(
      (item) => item._isDeletable
    );
    const entityDelete: EntityDeleteInput[] = deletableRows.map((item) => {
      return { id: item.id, rowTimestamp: item._rowTimestamp! };
    });
    const { errors } = await deleteUserProfile({
      variables: {
        input: {
          entityDelete,
        },
      },
      update(cache) {
        deletableRows.forEach((item) => {
          const identity = cache.identify({
            ...item,
          });
          cache.evict({ id: identity });
          cache.gc();
        });
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('User template deleted successfully.', {
        appearance: 'success',
      });
    }
  };

  return (
    <ActionMessageModal
      entityType={EntityType.UserTemplate}
      action={EntityAction.Remove}
      disabled={!selectedRows.some((selected) => selected._isDeletable)}
      visible={!!selectedRows.length}
      multiple={{
        validCount: selectedRows.filter((selected) => selected._isDeletable)
          .length,
        invalidNames: selectedRows
          .filter((selected) => !selected._isDeletable)
          .map((selected) => selected.name || ''),
      }}
      onConfirm={_onConfirm}
    />
  );
};
