import { PrimaryButton, Stack } from '@fluentui/react';
import { useStyles } from '../index.styles';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import React, { useCallback, useEffect, useState } from 'react';
import { InvoiceDetails } from '../../../__generated__/InvoiceDetails';

import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import {
  InvoiceSigningDraftRelease,
  InvoiceSigningDraftReleaseVariables,
} from 'ap/signing/transactionSigning/__generated__/InvoiceSigningDraftRelease';
import { InvoiceSigningDraftReleaseInput } from 'common/types/globalTypes';

const INVOICE_SIGNING_DRAFT_RELEASE = loader(
  '../../../../InvoiceSigningDraftRelease.graphql'
);
const INVOICE_DETAILS = loader('../../../InvoiceDetails.graphql');

const CONFIRM_RELEASE_FOR_REVIEW_TITLE =
  'Are you sure you want to release this transaction for review?';

interface ReleaseReviewProps {
  invoiceDetailsData?: InvoiceDetails;
  isNew: boolean;
  isDirty: boolean;
  onReleaseStatus: (value: boolean) => void;
}

export const ReleaseReviewTransaction: React.FC<ReleaseReviewProps> = ({
  invoiceDetailsData,
  isNew,
  isDirty,
  onReleaseStatus,
}) => {
  const { addToast } = useToasts();
  const [visible, setVisible] = useState(false);
  const styles = useStyles();
  const { _isApprovalDocumentsRequired, statusType } = {
    ...invoiceDetailsData?.invoice,
  };

  const [invoiceSigningDraftRelease, { loading }] = useMutation<
    InvoiceSigningDraftRelease,
    InvoiceSigningDraftReleaseVariables
  >(INVOICE_SIGNING_DRAFT_RELEASE, { errorPolicy: 'all' });

  const onAmendingStatusCb = useCallback(onReleaseStatus, []);

  useEffect(() => {
    onAmendingStatusCb(loading);
  }, [loading, onAmendingStatusCb]);

  if (
    !isNew &&
    statusType?.isDraftReleaseRequired &&
    !_isApprovalDocumentsRequired
  )
    return (
      <Stack>
        <PrimaryButton
          disabled={isDirty}
          className={styles.diabledButton}
          text="Release for Review"
          onClick={() => setVisible(true)}
        />
        {visible && (
          <ConfirmDialog
            isAmendButton
            hidden={false}
            title={CONFIRM_RELEASE_FOR_REVIEW_TITLE}
            onDismiss={() => setVisible(false)}
            onConfirm={async () => {
              setVisible(false);
              const inputVariables: InvoiceSigningDraftReleaseInput = {
                entitySelection: [
                  {
                    id: invoiceDetailsData?.invoice?.id!,
                    rowTimestamp: invoiceDetailsData?.invoice?._rowTimestamp,
                  },
                ],
              };
              const { errors } = await invoiceSigningDraftRelease({
                variables: {
                  input: inputVariables,
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: INVOICE_DETAILS,
                    variables: {
                      id: invoiceDetailsData?.invoice?.id!,
                    },
                  },
                ],
              });

              if (errors?.length)
                addToast(errors[0].message, {
                  appearance: 'error',
                });
              else
                addToast('Released for review successfully', {
                  appearance: 'success',
                });
            }}
          />
        )}
      </Stack>
    );
  else return null;
};
