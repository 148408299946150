import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 970,
    maxHeight: 600,
    paddingBottom: 30
  },
  itemSelected: {
    cursor: 'pointer',
    backgroundColor: theme.palette.neutralQuaternaryAlt,
    height: 50,
  },
  itemHover: {
    cursor: 'pointer',
    ':hover': {
      backgroundColor: theme.palette.neutralLight,
    },
    height: 50,
  },
  item: {
    cursor: 'pointer',
    height: 50,
  },
  addressStack: {
    width: 25,
  },
   acceptIcon:{
    marginRight: 8,
   },
   iconButton:{
    marginRight: 8
   },
   rowCell:{
    paddingLeft: 15,
   },
   selection:{
    width: 50
   },
   sequence:{
    width: 92
   },
   scheduleDate:{
    width: 152
   },
   scheduleNote:{
    width: 350
   },
   scheduleAmount:{
    width: 168,
    paddingRight:10,
   },
   invoiceNumber:{
    width: 155
   },
   content:{
    marginTop: "42px !important",
   },
   headerContainer:{
    width: 970,
    position: "fixed",
    zIndex: 100,
    top: 72,
    backgroundColor: theme.palette.white,
  },
  root: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'space-between',
    ':hover': {
      backgroundColor: theme.palette.neutralLighter,
    },
    width: "100%",
    paddingLeft: 15,
  },
  amountColumn: {
    justifyContent: "end",
    paddingRight:10
  }
}));