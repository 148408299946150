import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles(() => ({
  mainContainer: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  detailsContainer: {
    marginTop: '10px !important',
    padding: '0px 10px',
  },
  flex50: {
    flex: 0.5,
  },
  flex40: {
    flex: 0.49,
  },
  paddingTop20: {
    paddingTop: 30,
  },
  documentsContainer: {
    margin: '0px 0px',
  },
  marginB20: {
    marginBottom: 20,
  },
  modalInsideStack: {
    width: 1200,
    height: 700,
  },
  stickyInsideStack: {
    alignItems: 'self-start',
    padding: 25,
  },
  stack: {
    padding: 25,
  },
  paddingL25: {
    paddingLeft: 25,
  },
}));
