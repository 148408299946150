import { useLazyQuery, useMutation } from '@apollo/client';
import { Stack, Text } from '@fluentui/react';
import { DocumentPackage } from 'common/components/DocumentPackage';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { useCommonStyles } from 'common/styles';
import { loader } from 'graphql.macro';
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import React, { useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { DocumentPackageEmail } from '../../../../common/components/DocumentPackageEmail/DocumentPackageEmail';
import {
  PurchaseOrderEmailCreate,
  PurchaseOrderEmailCreateVariables,
} from './__generated__/PurchaseOrderEmailCreate';
import { useStyles } from './index.styles';
import { EmailCreateValues } from 'common/components/DocumentPackageEmail/DocumentPackageEmail/DocumentPackageEmailModal/FormModal/types';
import {
  PurchaseOrderEmailStatusUpdate,
  PurchaseOrderEmailStatusUpdateVariables,
} from './__generated__/PurchaseOrderEmailStatusUpdate';
import { IconState } from 'common/types/globalTypes';
import { useParams } from 'react-router-dom';
const PURCHASE_ORDER_EMAIL_CREATE = loader(
  './PurchaseOrderEmailCreate.graphql'
);
const PURCHASE_ORDER = loader('../../PurchaseOrder.graphql');
const FETCH_EMAIL_STATUS = loader('./PurchaseOrderEmailStatusUpdate.graphql');

interface HeaderProps {
  isNew: boolean;
  dataLoading: boolean;
  dirty: boolean;
  isSubmitting: boolean;
  purchaseOrderData: PurchaseOrder | undefined;
}

export const Header: React.FC<HeaderProps> = ({
  isNew,
  dataLoading,
  dirty,
  isSubmitting,
  purchaseOrderData,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();
  const { purchaseOrderId } = useParams<{
    purchaseOrderId: string | undefined;
  }>();

  const [purchaseOrderEmailCreate, { loading, data }] = useMutation<
    PurchaseOrderEmailCreate,
    PurchaseOrderEmailCreateVariables
  >(PURCHASE_ORDER_EMAIL_CREATE, { errorPolicy: 'all' });

  const panelHeading = isNew ? 'Create Purchase Order' : 'Edit Purchase Order';
  const {
    id,
    purchaseOrderNumber,
    stampedEntityDocumentId,
    _emailDocument,
    emailDocumentsByEntityId,
  } = {
    ...purchaseOrderData?.purchaseOrder,
  };
  const [showEmailStatus, setShowEmailStatus] = useState(false);

  const [fetchEmailStatus, { stopPolling }] = useLazyQuery<
    PurchaseOrderEmailStatusUpdate,
    PurchaseOrderEmailStatusUpdateVariables
  >(FETCH_EMAIL_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    pollInterval: 60000,
    onCompleted(data) {
      if (
        data.purchaseOrder?._emailDocument?._sendIconState === IconState.SENT
      ) {
        stopPolling?.();
        setShowEmailStatus(true);
      }
    },
  });

  const _onSubmitValues = async (values: EmailCreateValues) => {
    if (id) {
      const { errors } = await purchaseOrderEmailCreate({
        variables: {
          input: {
            entityId: id!,
            ...values,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: PURCHASE_ORDER,
            variables: {
              id,
            },
          },
        ],
      });
      if (!!errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Email sent successfully.', {
          appearance: 'success',
        });
        fetchEmailStatus({
          variables: {
            id: purchaseOrderId!,
          },
        });
      }
    }
  };

  const isSuccessful = !!data?.purchaseOrderEmailCreate;

  const fetchEmailStatusMemo = useCallback(() => {
    fetchEmailStatus({
      variables: {
        id: purchaseOrderId!,
      },
    });
  }, [fetchEmailStatus, purchaseOrderId]);

  useEffect(() => {
    if (_emailDocument?._sendIconState === IconState.PENDING) {
      fetchEmailStatusMemo();
    }
  }, [_emailDocument, fetchEmailStatusMemo]);

  return (
    <Stack className={styles.container}>
      <Stack grow horizontal horizontalAlign="space-between">
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">{panelHeading}</Text>
            {!isNew && (
              <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
                {purchaseOrderNumber}
              </Text>
            )}
            <UnsavedIndicator
              visible={!isNew && !dataLoading && dirty && !isSubmitting}
            />
            {stampedEntityDocumentId && (
              <DocumentPackage documentPackageId={stampedEntityDocumentId} />
            )}
            <DocumentPackageEmail
              emailDocument={_emailDocument}
              emailDocumentsByEntityId={emailDocumentsByEntityId?.nodes || []}
              isSuccessful={isSuccessful}
              loading={loading}
              onSubmitValues={_onSubmitValues}
              showEmailStatus={showEmailStatus}
              setShowEmailStatus={setShowEmailStatus}
              dataLoading={dataLoading}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
