import {
  ActionButton,
  DirectionalHint,
  IColumn,
  IDetailsRowProps,
  IRenderFunction,
  IconButton,
  Stack,
  Text,
  TooltipHost
} from '@fluentui/react';
import React, { useCallback, useMemo, useState } from 'react';

import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { AccountingEntryInvoice_invoice } from 'ap/signing/accountingEntry/view/__generated__/AccountingEntryInvoice';
import {
  AvailableInvoiceDocumentTypes,
  AvailableInvoiceDocumentTypesVariables,
} from 'ap/signing/transactionSigning/view/FormView/ActionMenu/__generated__/AvailableInvoiceDocumentTypes';
import {
  AppliableInvoiceSigningDocuments,
  AppliableInvoiceSigningDocumentsVariables,
  AppliableInvoiceSigningDocuments_appliableInvoiceSigningDocuments_nodes,
} from 'ap/signing/transactionSigning/view/FormView/ActionMenu/actionMenuContents/Attach/AttachForm/__generated__/AppliableInvoiceSigningDocuments';
import {
  ApplyInvoiceSigningDocument,
  ApplyInvoiceSigningDocumentVariables,
} from 'ap/signing/transactionSigning/view/FormView/ActionMenu/actionMenuContents/Attach/AttachForm/__generated__/ApplyInvoiceSigningDocument';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { AttachDocumentModal } from 'common/components/AttachDocumentModal';
import { DocumentDataCallout } from 'common/components/AttachDocumentModal/DocumentDataCallout';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { DISABLED_FILE_FORMATS, TABLE_ROWS } from 'common/constants';
import {
  EntityDocumentFilter,
  EntityDocumentInput,
  EntityDocumentsOrderBy,
} from 'common/types/globalTypes';
import { SortOrder } from 'common/utils/commonTypes';
import { getGlobalDateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { useFormContext } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { toOrderByVariable } from './utils';

const INVOICE_DETAILS = loader('../../../../AccountingEntryInvoice.graphql');
const APPLIABLE_INVOICE_SIGNING_DOCUMENTS = loader(
  '../../../../../../transactionSigning/view/FormView/ActionMenu/actionMenuContents/Attach/AttachForm/AppliableInvoiceSigningDocuments.graphql'
);

const APPLY_INVOICE_SIGNING_DOCUMENTS = loader(
  '../../../../../../transactionSigning/view/FormView/ActionMenu/actionMenuContents/Attach/AttachForm/ApplyInvoiceSigningDocument.graphql'
);
const AVAILABLE_DOCUMENT_TYPES = loader(
  '../../../../../../transactionSigning/view/FormView/ActionMenu/AvailableInvoiceDocumentTypes.graphql'
);

type AttachableDataType =
  AppliableInvoiceSigningDocuments_appliableInvoiceSigningDocuments_nodes;
interface AttachFormProps {
  invoice: AccountingEntryInvoice_invoice;
}

export const AttachForm: React.FC<AttachFormProps> = (props) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const {
    formState: { isDirty },
  } = useFormContext();

  const { data: AvailableDocumentTypes } = useQuery<
    AvailableInvoiceDocumentTypes,
    AvailableInvoiceDocumentTypesVariables
  >(AVAILABLE_DOCUMENT_TYPES, {
    variables: {
      transactionTypeId: props.invoice.transactionTypeId,
      isDocumentUpload: false,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  return (
    <>
      <Stack horizontal styles={{ root: { height: 44 } }}>
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Attach"
            checked
            disabled={isDirty}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachFormModal
          invoice={props.invoice}
          AvailableDocumentTypes={AvailableDocumentTypes}
          setOpen={setDialogVisible}
        />
      )}
    </>
  );
};

export const AttachFormModal: React.FC<
  AttachFormProps & {
    AvailableDocumentTypes: AvailableInvoiceDocumentTypes | undefined;
    setOpen: (open: boolean) => void;
  }
> = ({ AvailableDocumentTypes, invoice, setOpen }) => {
  const styles = useStyles();
  const [selectedList, setSelectedList] = useState<AttachableDataType[]>([]);
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });
  const {
    data: documentsList,
    loading: documentsLoading,
    fetchMore,
    variables: documentsVariables,
    networkStatus,
    refetch,
  } = useQuery<
    AppliableInvoiceSigningDocuments,
    AppliableInvoiceSigningDocumentsVariables
  >(APPLIABLE_INVOICE_SIGNING_DOCUMENTS, {
    variables: {
      first: TABLE_ROWS,
      // invoiceId: invoice?.invoice?.id!,
      invoiceId: invoice.id,
      orderBy: [
        EntityDocumentsOrderBy._UPLOAD_DATE_DESC,
        EntityDocumentsOrderBy.PRIMARY_KEY_DESC,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  const [attachDocuments, { loading: attachLoading }] = useMutation<
    ApplyInvoiceSigningDocument,
    ApplyInvoiceSigningDocumentVariables
  >(APPLY_INVOICE_SIGNING_DOCUMENTS, { errorPolicy: 'all' });

  const { addToast } = useToasts();

  const documentTypeOptions =
    AvailableDocumentTypes?.invoiceSigningAvailableDocumentTypes?.nodes.map(
      (doctype) => ({
        key: doctype.id,
        text: doctype.documentType || '',
        title:
          (!invoice.isDraft &&
            doctype.isAccountingDocument &&
            'Upload prohibited during/after the approval process') ||
          undefined,
        disabled:
          (!invoice.isDraft && doctype.isAccountingDocument) ||
          undefined,
      })
    ) || [];
  const canSelectItem = useCallback(
    (item: AttachableDataType) =>
      invoice.isDraft ? true : !item._isAccountingDocument!,
    [invoice]
  );
  const transformedData = useMemo(
    () =>
      documentsList?.appliableInvoiceSigningDocuments?.nodes.map(
        (documents) =>
        ({
          ...documents,
          available: documents.documentAppliedAmounts?.remainingTotal,
          isoCode: documents.currency?.isoCode,
          selected: false,
        } as AttachableDataType)
      ),
    [documentsList]
  );

  const onRenderItemColumn = useCallback(
    (item?: AttachableDataType, _index?: number, column?: IColumn) => {
      if (!item || !column) return undefined;
      const fieldContent = item[
        column.fieldName as keyof AttachableDataType
      ] as string | null;

      const viewDocumentVisible =
        item._isProtected! || DISABLED_FILE_FORMATS.includes(item._fileType!);

      switch (column.key) {
        case 'indexAmount':
        case 'available':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <AmountTextView
                className={clsx(
                  item._isAccountingDocument ? styles.disabledText : '',
                  styles.contentColumnAlignRight
                )}
                value={fieldContent}
              />
            </Stack>
          );
        case 'used':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <AmountTextView
                className={clsx(
                  item._isAccountingDocument ? styles.disabledText : '',
                  styles.contentColumnAlignRight
                )}
                value={item.documentAppliedAmounts?.usedTotal!}
              />
            </Stack>
          );
        case 'view':
          return (
            <Stack
              className={styles.columnHeight}
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );
        case 'fileReference':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <DocumentDataCallout
                disabled={item._isAccountingDocument!}
                item={item}
              />
            </Stack>
          );
        case '_uploadDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text
                className={
                  item._isAccountingDocument ? styles.disabledText : ''
                }
              >
                {getGlobalDateFormat(item._uploadDate!)}
              </Text>
            </Stack>
          );

        default:
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text
                className={
                  item._isAccountingDocument ? styles.disabledText : ''
                }
              >
                {fieldContent}
              </Text>
            </Stack>
          );
      }
    },
    [styles]
  );

  const _onAttach = async () => {
    const selectedEntities = selectedList.map((item) => {
      return {
        ...item,
      } as EntityDocumentInput;
    });

    const entityDocuments = selectedEntities.map(
      (item) =>
      ({
        id: item.id,
        comment: item.comment,
        entityId: item.entityId,
        fileReference: item.fileReference,
        fileIndexInformation: item.fileIndexInformation,
        entityDocumentTypeId: item.entityDocumentTypeId,
        indexName: item.indexName,
        indexDescription: item.indexDescription,
        indexTransactionDate: item.indexTransactionDate,
        indexAmount: item.indexAmount,
        indexCurrencyId: item.indexCurrencyId,
        documentAppliedAmount: item.documentAppliedAmount,
        documentStatusExplanation: item.documentStatusExplanation,
        isAppliedAmountRetired: item.isAppliedAmountRetired,
        isAppliedAmountOverage: item.isAppliedAmountOverage,
      } as EntityDocumentInput)
    );

    const { errors } = await attachDocuments({
      variables: {
        input: {
          invoiceId: invoice.id,
          entityDocuments: entityDocuments,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: INVOICE_DETAILS,
          variables: {
            id: invoice.id,
          },
        },
      ],
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Document attached successfully', { appearance: 'success' });
      setOpen(false);
    }
  };

  const onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    const { item } = { ...props };
    const tootTipMessage =
      'Upload prohibited during/after the approval process';
    const tooltipVisible =
      !invoice.isDraft && item._isAccountingDocument;
    return (
      <>
        <TooltipHost
          content={tooltipVisible ? tootTipMessage : ''}
          directionalHint={DirectionalHint.rightCenter}
        >
          {defaultRender!({
            ...props,
          })}
        </TooltipHost>
      </>
    );
  };

  const loadMore = useCallback(
    async () =>
      await fetchMore({
        variables: {
          ...documentsVariables,
          after:
            documentsList?.appliableInvoiceSigningDocuments?.pageInfo.endCursor,
        },
      }),
    [fetchMore, documentsVariables, documentsList]
  );

  const onFiltersReload = useCallback(
    async (filter: EntityDocumentFilter | undefined) =>
      await refetch({ ...documentsVariables, filter }),
    [documentsVariables, refetch]
  );
  const reload = useCallback(
    async (sort?: SortOrder) =>
      await refetch({ after: null, orderBy: toOrderByVariable(sort) }),
    [refetch]
  );

  const onDocumentTypeReload = useCallback(
    async (documentTypeId: number | null) =>
      await refetch({ ...documentsVariables, documentTypeId }),
    [refetch, documentsVariables]
  );

  const attachDisabled = selectedList.length === 0;

  return (
    <>
      <AttachDocumentModal
        columns={columns}
        modalWidth={1450}
        loading={documentsLoading}
        items={
          networkStatus === NetworkStatus.refetch ||
            networkStatus === NetworkStatus.setVariables
            ? undefined
            : transformedData
        }
        hasNextPage={
          documentsList?.appliableInvoiceSigningDocuments?.pageInfo.hasNextPage
        }
        attachLoading={attachLoading}
        availableDocumentTypes={documentTypeOptions}
        setOpen={setOpen}
        onSortReload={reload}
        onLoadMore={loadMore}
        onFiltersReload={onFiltersReload}
        onRenderRow={onRenderRow}
        onDocumentTypeChange={onDocumentTypeReload}
        attachDisabled={attachDisabled}
        onSelectionChanged={setSelectedList}
        dropdownDisabled={selectedList.length > 0}
        onRenderItemColumn={onRenderItemColumn}
        onAttachDocuments={_onAttach}
        canSelectItem={canSelectItem}
        isDraggable
      />
      <DocumentViewModal
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </>
  );
};
