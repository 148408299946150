import { useMutation, useReactiveVar } from '@apollo/client';
import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  PrimaryButton,
  Stack,
  Text,
  useTheme,
} from '@fluentui/react';
import {
  InvoicesSigningSearch,
  InvoicesSigningSearchVariables,
} from 'ap/signing/__generated__/InvoicesSigningSearch';
import { InvoiceRow } from 'ap/signing/types';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalRequestInput,
  InvoiceSigningDraftReleaseInput,
} from 'common/types/globalTypes';
import { EntityAction, EntityType } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  InvoiceSigningDraftRelease,
  InvoiceSigningDraftReleaseVariables,
} from '../../__generated__/InvoiceSigningDraftRelease';
import { DeleteTransaction } from '../DeleteTransaction';
import { ReleaseReviewModal } from './ReleaseReviewModal';
import { RequestMessageModal } from 'common/components/RequestMessageModal';
import {
  InvoiceSigningApprovalCreate,
  InvoiceSigningApprovalCreateVariables,
} from '../../__generated__/InvoiceSigningApprovalCreate';
import { ExportCsvButton } from 'ap/signing/components/ExportCsv';
import { globalMode } from 'utility/cache/ui';
import { InvoiceSigningSearchFilterTotals } from 'ap/signing/__generated__/InvoiceSigningSearchFilterTotals';

const INVOICES = loader('../../../InvoicesSigningSearch.graphql');
const INVOICE_SIGNING_DRAFT_RELEASE = loader(
  '../../InvoiceSigningDraftRelease.graphql'
);
const INVOICE_SIGNING_APPROVAL_CREATE = loader(
  '../../InvoiceSigningApprovalCreate.graphql'
);
interface HeaderProps {
  selectedRows: InvoiceRow[];
  invoicesData: InvoicesSigningSearch | undefined;
  invoicesFilterTotal: InvoiceSigningSearchFilterTotals | undefined;
  variables: InvoicesSigningSearchVariables | undefined;
  setIsAllReleaseReview: (data: boolean) => void;
  isAllRequestChecked: boolean;
  setReleasedIndices: (data: number[]) => void;
  setIsAllRequestlChecked: (data: boolean) => void;
  setRequestedIndices: (data: number[]) => void;
  refetch: () => void;
  onStamp: () => void;
  defaultDataView: any;
  onDataViewChange: (key: string | number | undefined) => void;
}
export const Header: React.FC<HeaderProps> = ({
  selectedRows,
  invoicesData,
  invoicesFilterTotal,
  variables,
  setReleasedIndices,
  setIsAllReleaseReview,
  setIsAllRequestlChecked,
  setRequestedIndices,
  refetch,
  onStamp,
  defaultDataView,
  onDataViewChange,
}) => {
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const globalState = useReactiveVar(globalMode);
  const theme = useTheme();
  const { addToast } = useToasts();
  const [invoiceSigningDraftRelease] = useMutation<
    InvoiceSigningDraftRelease,
    InvoiceSigningDraftReleaseVariables
  >(INVOICE_SIGNING_DRAFT_RELEASE, { errorPolicy: 'all' });

  const [
    invoiceSigningApprovalCreate,
    { loading: invoiceSigningApprovalCreateLoading },
  ] = useMutation<
    InvoiceSigningApprovalCreate,
    InvoiceSigningApprovalCreateVariables
  >(INVOICE_SIGNING_APPROVAL_CREATE, { errorPolicy: 'all' });

  const { nodes } = { ...invoicesData?.invoiceSigningSearch };
  const showRequestAll = nodes?.some(
    (invoice) => invoice._isStagedApprovalRequest
  );

  const showReleaseAll = nodes?.some(
    ({ _isApprovalDocumentsRequired, statusType }) =>
      statusType?.isDraftReleaseRequired && !_isApprovalDocumentsRequired
  );

  const onReleaseReviewConfirm = async () => {
    const input: InvoiceSigningDraftReleaseInput = {
      entitySelection: selectedRows
        .filter(
          (item) =>
            item.statusType?.isDraftReleaseRequired &&
            !item._isApprovalDocumentsRequired
        )
        .map(({ id, _rowTimestamp }) => ({ id, rowTimestamp: _rowTimestamp })),
    };

    if (!!input.entitySelection.length) {
      const { errors } = await invoiceSigningDraftRelease({
        variables: { input },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: INVOICES,
            variables: variables,
          },
        ],
      });
      if (!!errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Released for review successfully', {
          appearance: 'success',
        });
        setIsAllReleaseReview(false);
        setReleasedIndices([]);
      }
    }
  };

  const rowsStampable = useMemo(() => {
    const data = nodes?.filter((item) => {
      return (
        !item._isAccountingEntryStampedComplete &&
        item._isAccountingEntry &&
        item.reimburseAmount === null
      );
    });
    return data;
  }, [nodes]);

  const dropdownStyles: Partial<IDropdownStyles> = {
    title: {
      border: 0,
      outline: 0,
      borderBottom: `1px solid ${theme.semanticColors.inputBorder}`,
    },
    dropdown: {
      width: 140,
    },
  };
  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      className={commonStyles.listTitleContainer}
    >
      <Text variant="xLarge">Transaction Signing</Text>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Dropdown
          placeholder="Select"
          selectedKey={defaultDataView}
          options={defaultDataViewOptions}
          onChange={(_, option) => {
            onDataViewChange(option?.key);
          }}
          styles={dropdownStyles}
        />

        {rowsStampable && globalState && (
          <DefaultButton
            text="Stamp"
            onClick={onStamp}
            iconProps={{
              iconName: 'StampSmall',
              styles: {
                root: {
                  fill: theme.palette.black,
                },
              },
            }}
          />
        )}
        {selectedRows.length > 0 && (
          <Stack style={{ alignItems: 'flex-end' }}>
            <ExportCsvButton selectedRows={selectedRows} refetch={refetch} />
          </Stack>
        )}

        {showReleaseAll && (
          <ReleaseReviewModal
            entityType={EntityType.Transaction}
            action={EntityAction.Release}
            disabled={
              !selectedRows.some(
                (selected) =>
                  selected.statusType?.isDraftReleaseRequired &&
                  !selected._isApprovalDocumentsRequired
              )
            }
            visible={selectedRows.length > 0}
            multiple={{
              validCount: selectedRows.filter(
                (selected) =>
                  selected.statusType?.isDraftReleaseRequired &&
                  !selected._isApprovalDocumentsRequired
              ).length,
              invalidNames: selectedRows
                .filter(
                  (selected) =>
                    !(
                      selected.statusType?.isDraftReleaseRequired &&
                      !selected._isApprovalDocumentsRequired
                    )
                )
                .map((cannotDelete) => cannotDelete.vendorReference!),
            }}
            buttonProps={{ text: 'Release for Review' }}
            onConfirm={onReleaseReviewConfirm}
          />
        )}
        {showRequestAll && (
          <RequestMessageModal
            entityType={EntityType.Transaction}
            action={EntityAction.Request}
            disabled={
              !selectedRows.some(
                (selected) => selected._isStagedApprovalRequest
              )
            }
            visible={selectedRows.length > 0}
            multiple={{
              validCount: selectedRows.filter(
                (selected) => selected._isStagedApprovalRequest
              ).length,
              invalidNames: selectedRows
                .filter((selected) => !selected._isStagedApprovalRequest!)
                .map((cannotDelete) => cannotDelete.vendorReference!),
            }}
            buttonProps={{ text: 'Request Approval' }}
            onConfirm={async (
              requestComment: string | null,
              requiredDate: string | null
            ) => {
              const entityApproval: ApprovalRequestInput[] = selectedRows
                .filter((item) => item._isStagedApprovalRequest)
                .map((requestInput) => ({
                  entityId: requestInput.id!,
                  rowTimestamp: requestInput._rowTimestamp!,
                  comments: requestComment,
                  requiredDate: requiredDate ? requiredDate : undefined,
                }));
              if (!!entityApproval.length) {
                const { errors } = await invoiceSigningApprovalCreate({
                  variables: {
                    input: { entityApproval },
                  },
                  awaitRefetchQueries: true,
                  refetchQueries: [
                    {
                      query: INVOICES,
                      variables: variables,
                    },
                  ],
                });
                if (errors?.length)
                  addToast(errors[0].message, {
                    appearance: 'error',
                  });
                else {
                  addToast('Request sent for approvals', {
                    appearance: 'success',
                  });
                  setIsAllRequestlChecked(false);
                  setRequestedIndices([]);
                }
              }
            }}
            isLoading={invoiceSigningApprovalCreateLoading}
          />
        )}
        <DeleteTransaction
          variables={variables}
          invoicesData={invoicesData}
          invoicesFilterTotal={invoicesFilterTotal}
          selectedRows={selectedRows}
        />
        <PrimaryButton
          onClick={() => history.push('/signing/transaction')}
          iconProps={{
            iconName: 'Add',
          }}
          text="Add New Transaction"
        />
      </Stack>
    </Stack>
  );
};

export enum DefaultDataViewEnum {
  all_Transactions = 'ALL_TRANSACTIONS',
  my_Transactions = 'MY_TRANSACTIONS',
}

const defaultDataViewOptions: IDropdownOption[] = [
  {
    key: DefaultDataViewEnum.my_Transactions,
    text: 'My Transactions',
  },
  {
    key: DefaultDataViewEnum.all_Transactions,
    text: 'All Transactions',
  },
];
