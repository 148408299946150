import { PrimaryButton, Stack } from '@fluentui/react';
import { useStyles } from '../index.styles';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import React, { useCallback, useEffect, useState } from 'react';
import { InvoiceDetails } from '../../../__generated__/InvoiceDetails';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { InvoiceSigningApprovalRevokeInput } from 'common/types/globalTypes';
import {
  InvoiceSigningApprovalRevoke,
  InvoiceSigningApprovalRevokeVariables,
} from 'ap/signing/__generated__/InvoiceSigningApprovalRevoke';

const INVOICE_SIGNING_APPROVAL_REVOKE = loader(
  '../../../../../InvoiceSigningApprovalRevoke.graphql'
);
const INVOICE_DETAILS = loader('../../../InvoiceDetails.graphql');

const CONFIRM_AMEND_DIALOG_TITLE =
  'Are you sure you want to amend this transaction?';
const CONFIRM_AMEND_DIALOG_SUBTEXT =
  'This will remove the Transaction signing from the approval cycle and require re-approval.';

interface AmendTransactionProps {
  invoiceDetailsData?: InvoiceDetails;
  isNew: boolean;
  isDirty: boolean;
  onAmendingStatus: (value: boolean) => void;
}

export const AmendTransaction: React.FC<AmendTransactionProps> = ({
  invoiceDetailsData,
  isNew,
  isDirty,
  onAmendingStatus,
}) => {
  const { addToast } = useToasts();
  const [visible, setVisible] = useState(false);
  const styles = useStyles();
  const { _isApprovalRevocable } = { ...invoiceDetailsData?.invoice };

  const [invoiceSigningApprovalRevoke, { loading }] = useMutation<
    InvoiceSigningApprovalRevoke,
    InvoiceSigningApprovalRevokeVariables
  >(INVOICE_SIGNING_APPROVAL_REVOKE, { errorPolicy: 'all' });

  const onAmendingStatusCb = useCallback(onAmendingStatus, []);

  useEffect(() => {
    onAmendingStatusCb(loading);
  }, [loading, onAmendingStatusCb]);

  if (!isNew && _isApprovalRevocable)
    return (
      <Stack>
        <PrimaryButton
          disabled={isDirty}
          className={styles.diabledButton}
          text="Amend Transaction"
          onClick={() => setVisible(true)}
        />
        {visible && (
          <ConfirmDialog
            isAmendButton
            hidden={false}
            title={CONFIRM_AMEND_DIALOG_TITLE}
            subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
            onDismiss={() => setVisible(false)}
            onConfirm={async () => {
              setVisible(false);
              const inputVariables: InvoiceSigningApprovalRevokeInput = {
                entityId: invoiceDetailsData?.invoice?.id!,
                rowTimestamp: invoiceDetailsData?.invoice?._rowTimestamp!,
              };
              const { errors } = await invoiceSigningApprovalRevoke({
                variables: {
                  input: inputVariables,
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: INVOICE_DETAILS,
                    variables: {
                      id: invoiceDetailsData?.invoice?.id!,
                    },
                  },
                ],
              });

              if (errors?.length)
                addToast(errors[0].message, {
                  appearance: 'error',
                });
              else
                addToast('Approval amended successfully', {
                  appearance: 'success',
                });
            }}
          />
        )}
      </Stack>
    );
  else return null;
};
