import {
  NetworkStatus,
  makeVar,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  Checkbox,
  DefaultButton,
  Dropdown,
  FontSizes,
  FontWeights,
  ICheckboxStyles,
  IColumn,
  IDetailsRowBaseProps,
  IDetailsRowProps,
  IDropdownStyles,
  IRenderFunction,
  PrimaryButton,
  Stack,
  Text,
  TextField,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { FilterArrayType } from 'common/components/Filters';
import {
  HighLightActiveLink,
  HighlightTextView,
} from 'common/components/HighLight';
import { InfiniteList } from 'common/components/InfiniteList';
import {
  MultiSelectOption,
  MultiSelectTags,
} from 'common/components/MultiSelectTags';
import { RequestMessageModal } from 'common/components/RequestMessageModal';
import { ColumnData } from 'common/components/SearchBar';
import { StatusIcon } from 'common/components/StatusIcon';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalRequestInput,
  EntityDeleteInput,
  SearchRequestInput,
  TravelAuthorizationFilter,
  TravelAuthorizationsOrderBy,
} from 'common/types/globalTypes';
import { EntityAction, EntityType } from 'common/types/utility';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { convertToTitleCase } from 'common/utils/convertToTitleCase';
import {
  dateConvertions,
  dateFormat,
  formatDateTime,
} from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  TravelAuthorizationApprovalCreate,
  TravelAuthorizationApprovalCreateVariables,
} from 'travelAuthorization/TravelPlan/__generated__/TravelAuthorizationApprovalCreate';
import {
  TravelAuthorizationDelete,
  TravelAuthorizationDeleteVariables,
} from 'travelAuthorization/TravelPlan/__generated__/TravelAuthorizationDelete';
import { StringParam, useQueryParams } from 'use-query-params';
import {
  expandedNav,
  globalMode,
  setCurrentProfile,
  setGlobalSearchText,
  setUserDefaults,
} from 'utility/cache/ui';
import { PurchaseOrderGeneration } from './POGeneration';
import { SectionIcons } from './SectionIcons';
import { StampTravelPlanFooter } from './StampTravelPlanFooter';
import { TASearchBar } from './TASearchBar';
import {
  TravelAuthorizationSearch,
  TravelAuthorizationSearchVariables,
  TravelAuthorizationSearch_travelAuthorizationSearch_nodes,
} from './__generated__/TravelAuthorizationSearch';
import {
  TravelAuthorizationSearchHints,
  TravelAuthorizationSearchHintsVariables,
} from './__generated__/TravelAuthorizationSearchHints';

import { useStyles } from './index.styles';
import {
  getFilterParams,
  listTypeEnum,
  listTypeOptions,
  toFilterVariable,
  toOrderByVariable,
} from './utils';
import { getOverageInfo } from 'common/utils';
import { getColumns } from './columns.data';
import { RedBoxIndicator } from 'common/components/RedBoxIndicator';
import clsx from 'clsx';
import {
  TravelAuthorizationSearchFilterTotals,
  TravelAuthorizationSearchFilterTotalsVariables,
  TravelAuthorizationSearchFilterTotals_travelAuthorizationSearchFilterTotals,
} from './__generated__/TravelAuthorizationSearchFilterTotals';
const TRAVEL_AUTHORIZATION_SEARCH = loader(
  './TravelAuthorizationSearch.graphql'
);
const TRAVEL_AUTHORIZATION_FILTER_TOTAL = loader(
  './TravelAuthorizationSearchFilterTotals.graphql'
);
const TRAVEL_AUTHORIZATION_SEARCH_HINTS = loader(
  './TravelAuthorizationSearchHints.graphql'
);
const TRAVEL_AUTHORIZATION_DELETE = loader(
  '../TravelAuthorizationDelete.graphql'
);
const TRAVEL_AUTHORIZATION_APPROVAL_CREATE = loader(
  '../TravelAuthorizationApprovalCreate.graphql'
);
const checkBoxStyle: ICheckboxStyles = {
  label: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.size10,
    color: '#a9a9a9',
  },
};

export const setTAListViewOptions = makeVar<{
  isSigning: boolean;
  isHistory: boolean;
  isSearchAll: boolean;
}>({ isSigning: true, isHistory: false, isSearchAll: true });

export type TravelAuthorizationItem =
  TravelAuthorizationSearch_travelAuthorizationSearch_nodes & {
    department: string | null;
    businessUnit: string | null;
    currency: string | null;
    status: string;
  };

export const TravelAuthorization = () => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const renderRef = useRef(false);
  const { addToast } = useToasts();
  const [urlParameters, setUrlParameters] = useQueryParams({
    t: StringParam,
  });
  const { t: searchParam } = urlParameters;
  const globalSearchText = useReactiveVar(setGlobalSearchText);
  const TAListViewOptions = useReactiveVar(setTAListViewOptions);
  const globalState = useReactiveVar(globalMode);
  const currentProfileData = useReactiveVar(setCurrentProfile);
  const useDefaultsData = useReactiveVar(setUserDefaults);
  const columns = getColumns(currentProfileData, useDefaultsData);

  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [searchTextValue, setSearchTextValue] = useState<string | null>(
    searchParam! || null
  );

  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>([]);
  const [searchHints, setSearchHints] = useState<number | null>();
  const [hintsVisibility, setHintsVisibility] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<TravelAuthorizationItem[]>(
    []
  );
  const [isAllRequestChecked, setIsAllRequestChecked] = useState(false);
  const [requestedIndices, setRequestedIndices] = useState<number[]>([]);
  const [stampTransaction, setStampTransaction] = useState(false);
  const [transactionStampField, setTransactionStampField] = useState<
    Map<string, string>
  >(new Map());

  const dropdownStyles: Partial<IDropdownStyles> = {
    title: {
      border: 0,
      outline: 0,
      borderBottom: `1px solid ${theme.semanticColors.inputBorder}`,
    },
    dropdown: {
      width: 140,
    },
  };

  const [deleteTravelPlan] = useMutation<
    TravelAuthorizationDelete,
    TravelAuthorizationDeleteVariables
  >(TRAVEL_AUTHORIZATION_DELETE, {
    errorPolicy: 'all',
  });

  const [
    travelAuthorizationApprovalCreate,
    { loading: travelAuthorizationApprovalCreateLoading },
  ] = useMutation<
    TravelAuthorizationApprovalCreate,
    TravelAuthorizationApprovalCreateVariables
  >(TRAVEL_AUTHORIZATION_APPROVAL_CREATE, { errorPolicy: 'all' });

  const [fetchHints, { data: hintsData }] = useLazyQuery<
    TravelAuthorizationSearchHints,
    TravelAuthorizationSearchHintsVariables
  >(TRAVEL_AUTHORIZATION_SEARCH_HINTS, {
    variables: {
      searchRequest: {
        searchText: [searchTextValue ? searchTextValue : ''],
        searchHintId: searchHints ? searchHints : null,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const {
    loading,
    data,
    networkStatus,
    refetch,
    fetchMore,
    variables: travelAuthorizationSearchVariables,
  } = useQuery<TravelAuthorizationSearch, TravelAuthorizationSearchVariables>(
    TRAVEL_AUTHORIZATION_SEARCH,
    {
      variables: {
        first: TABLE_ROWS,
        orderBy: [
          TravelAuthorizationsOrderBy.TRAVEL_AUTHORIZATION_NUMBER_DESC,
          TravelAuthorizationsOrderBy.PRIMARY_KEY_ASC,
        ],
        isHistorySearch: false,
        isSigningSearch: true,
        isSearchAllData: true,
      },
      // skip: renderRef.current,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const { data: travelAuthFilterTotal, refetch: refetchFilterTotal } = useQuery<
    TravelAuthorizationSearchFilterTotals,
    TravelAuthorizationSearchFilterTotalsVariables
  >(TRAVEL_AUTHORIZATION_FILTER_TOTAL, {
    variables: {
      isSigningSearch: true,
      isHistorySearch: false,
      isLinkSearch: false,
      isSearchAllData: true,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const searchHintsOptions: MultiSelectOption[] =
    hintsData?.travelAuthorizationSearchHints?.nodes.map(
      (hint) =>
        ({
          key: hint.id?.toString(),
          text: `${hint.caption} (${hint.resultsCount})`,
        } as MultiSelectOption)
    ) || [];

  const HINTS_AVAILABLE =
    searchHintsOptions.length > 0 && searchTextValue?.length! > 0;

  const refetching = loading && networkStatus !== NetworkStatus.fetchMore;

  const { travelAuthorizationSearchFilterTotals } = {
    ...travelAuthFilterTotal,
  };

  const { totalAmount1, totalCount1 } = {
    ...travelAuthorizationSearchFilterTotals,
  };

  const showFooter =
    !refetching &&
    parseFloat(totalAmount1 || '0.0') > 0 &&
    (totalCount1 || 0) > 0;

  const hasNextPage = data?.travelAuthorizationSearch?.pageInfo.hasNextPage;

  const transformedData = refetching
    ? undefined
    : data?.travelAuthorizationSearch?.nodes?.map((item) => {
        const currency = !!item?.currency?.name
          ? `${item?.currency?.isoCode} - ${item?.currency?.name}`
          : item.currency?.isoCode;
        return {
          ...item,
          travelAuthorizationDate: item?.travelAuthorizationDate
            ? dateFormat(dateConvertions(item?.travelAuthorizationDate))
            : null,
          department: item?.department?.name,
          businessUnit: item?.businessUnit?.name,
          currency: currency,
          status: item?.statusType?.statusType,
        } as TravelAuthorizationItem;
      });

  const showRequestAll = transformedData?.some(
    (plan) => plan._isStagedApprovalRequest
  );

  const _onConfirm = async () => {
    const selectedData: TravelAuthorizationItem[] = selectedRows;
    const deletedRows: TravelAuthorizationItem[] = selectedData.filter(
      (item) => item._isDeletable
    );
    const entityDelete: EntityDeleteInput[] = selectedData
      .filter((item) => item._isDeletable)
      .map((item) => {
        return { id: item.id, rowTimestamp: item._rowTimestamp! };
      });
    const { errors } = await deleteTravelPlan({
      variables: {
        input: {
          entityDelete,
        },
      },
      update(cache) {
        deletedRows.forEach((item) => {
          const identity = cache.identify({
            ...item,
          });
          cache.evict({ id: identity });
          cache.gc();
          try {
            cache.modify({
              fields: {
                travelAuthorizationSearchFilterTotals(
                  existing: TravelAuthorizationSearchFilterTotals_travelAuthorizationSearchFilterTotals
                ) {
                  if (existing?.totalCount1 !== 0) {
                    const controlTotalAmount =
                      Number(existing?.totalAmount1) -
                        Number(item?.controlTotalAmount) ?? 0;
                    const budgetAmount =
                      Number(existing?.totalAmount4) -
                        Number(item?.budgetAmount) ?? 0;
                    return {
                      ...existing,
                      totalAmount1: controlTotalAmount.toString(),
                      totalAmount4: budgetAmount.toString(),
                      totalCount1: existing.totalCount1
                        ? existing.totalCount1 - 1
                        : 0,
                    };
                  } else {
                    return {
                      ...existing,
                    };
                  }
                },
              },
            });
          } catch (error) {}
        });
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record Deleted Successfully', {
        appearance: 'success',
      });
    }
  };

  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );
        setGridColumns(newColumns);
        setSortOrderParam({
          column: clickedColumn.key,
          direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
        });
      }
    },
    [gridColumns]
  );

  const _renderItemColumn = (
    item: TravelAuthorizationItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof TravelAuthorizationItem
      ] as string;
      const _isUrgentApproval = !Boolean(item._urgencyLevel);
      switch (column?.key) {
        case '_urgencyLevel':
          return (
            <RedBoxIndicator
              _isUrgentApproval={_isUrgentApproval}
              itemId={item?.id}
            />
          );
        case 'travelAuthorizationNumber':
          return (
            <Stack verticalAlign="center" className={styles.rowContainer}>
              <HighLightActiveLink
                key={item?.id}
                to={`/ta/travel-plan/travel/${item?.id}`}
                highlightText={globalSearchText}
                text={fieldContent}
              />
            </Stack>
          );
        case 'budgetAmount':
          return (
            <Stack verticalAlign="center">
              <AmountColumnTextView
                value={fieldContent}
                searchText={globalSearchText!}
              />
            </Stack>
          );
        case 'controlTotalAmount':
          const toolTipContent = getOverageInfo(
            item.controlTotalAmount,
            item.budgetAmount
          );

          return (
            <TooltipHost content={toolTipContent}>
              <Stack
                tokens={{ childrenGap: 10 }}
                horizontal
                horizontalAlign="end"
                verticalAlign="center"
              >
                <AmountColumnTextView
                  value={fieldContent}
                  searchText={globalSearchText!}
                  textColor={item._isOverBudget ? 'red' : undefined}
                />
              </Stack>
            </TooltipHost>
          );
        case '_isCorporateTravelAuthorization':
          return (
            <Stack verticalAlign="center" className={styles.rowContainer}>
              <HighlightTextView
                highlightText={globalSearchText}
                text={fieldContent ? 'Corporate' : 'Production'}
              />
            </Stack>
          );
        case '_createdDate':
          return (
            <Stack verticalAlign="center" className={styles.rowContainer}>
              {!!item._createdDate && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={formatDateTime(item._createdDate)}
                />
              )}
            </Stack>
          );
        case 'status':
          return (
            <Stack
              verticalAlign="center"
              className={styles.rowContainer}
              horizontal
              tokens={{ childrenGap: 10 }}
            >
              <HighlightTextView
                className={styles.statusType}
                highlightText={globalSearchText}
                text={convertToTitleCase(item?.status!)!}
              />
              <StatusIcon
                approval={false}
                iconType={item.statusType!}
                approvalData={item!}
              />
            </Stack>
          );
        case 'sectionIcons':
          return <SectionIcons data={item} />;
        case 'transactionStamp':
          if (
            !item._isAccountingEntryStampedComplete &&
            item._isAccountingEntry
          )
            return (
              <Stack verticalAlign="center" className={styles.rowContainer}>
                <TextField
                  underlined
                  value={transactionStampField.get(item.id) || ''}
                  onChange={(_, newValue) => {
                    if (newValue && newValue !== '') {
                      setTransactionStampField(
                        new Map(transactionStampField.set(item.id, newValue))
                      );
                    } else {
                      transactionStampField.delete(item.id);
                      setTransactionStampField(new Map(transactionStampField));
                    }
                  }}
                />
              </Stack>
            );
          else return null;
        case 'currency':
          return (
            <Stack verticalAlign="center" className={styles.rowContainer}>
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={item?.currency?.slice(0, 3) || ''}
                />
              )}
            </Stack>
          );
        default:
          return (
            <Stack verticalAlign="center" className={styles.rowContainer}>
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                />
              )}
            </Stack>
          );
      }
    }
  };

  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      const { totalAmount1, totalAmount4 } = {
        ...travelAuthorizationSearchFilterTotals,
      };
      const fieldContent =
        column?.key === 'controlTotalAmount'
          ? totalAmount1
          : column?.key === 'budgetAmount'
          ? totalAmount4
          : '';

      return (
        <AmountTextView
          variant="medium"
          className={styles.footerTotal}
          value={fieldContent!}
        />
      );
    };

  const _onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    const item: TravelAuthorizationItem = { ...props.item };
    const _isUrgentApproval = !Boolean(item._urgencyLevel);
    const newProps: IDetailsRowProps | undefined = props
      ? {
          ...props,
          className: clsx(
            styles.rowBaseStyle,
            _isUrgentApproval ? commonStyles.urgentRow : styles.rowBaseStyle
          ),
        }
      : undefined;
    return <>{defaultRender?.(newProps)}</>;
  };
  const filterParams = getFilterParams(searchFilters || []);
  const handleSearchGlobal = (
    showMore: boolean,
    newHints: boolean,
    globalValue: boolean
  ) => {
    if (!showMore) {
      setSelectedRows([]);
    }
    setIsAllRequestChecked(false);
    const filter = searchFilters?.length
      ? ({ and: toFilterVariable(searchFilters) } as TravelAuthorizationFilter)
      : undefined;
    const searchRequest = searchTextValue
      ? {
          searchText: [searchTextValue ? searchTextValue : ''],
          searchHintId: searchHints,
        }
      : undefined;
    const searchQueryParam =
      searchTextValue && searchTextValue.length > 0
        ? searchTextValue
        : undefined;
    const variables: TravelAuthorizationSearchVariables = {
      first: TABLE_ROWS,
      after: showMore
        ? data?.travelAuthorizationSearch?.pageInfo.endCursor
        : undefined,
      orderBy: toOrderByVariable(sortOrderParam),
      filter: filter,
      searchRequest: searchRequest,
      isLinkSearch: globalValue,
      isSigningSearch: TAListViewOptions.isSigning,
      isHistorySearch: TAListViewOptions.isHistory,
      isSearchAllData: TAListViewOptions.isSearchAll,
    };
    const totalFilterVariables: TravelAuthorizationSearchFilterTotalsVariables =
      {
        searchRequest: searchRequest,
        isLinkSearch: globalValue,
        isSigningSearch: TAListViewOptions.isSigning,
        isHistorySearch: TAListViewOptions.isHistory,
        isSearchAllData: TAListViewOptions.isSearchAll,
        ...filterParams,
      };
    const searchHintsRequest: SearchRequestInput = {
      searchText: [searchTextValue ? searchTextValue : ''],
    };
    const variableHints: TravelAuthorizationSearchHintsVariables = {
      searchRequest: searchHintsRequest,
      isLinkSearch: globalValue,
    };
    setUrlParameters({ t: searchQueryParam }, 'replace');
    if (showMore) fetchMore?.({ variables });
    else {
      refetch(variables);
      refetchFilterTotal(totalFilterVariables);
    }
    if (newHints && searchRequest && searchHints === null) {
      setSearchHints(null);
      fetchHints({
        variables: variableHints!,
      });
    }
  };

  const handleSearch = (showMore: boolean, newHints: boolean) =>
    handleSearchGlobal(showMore, newHints, globalState);

  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
    searchTextValue,
    searchHints,
    TAListViewOptions,
  ]);

  useEffect(() => {
    if (renderRef.current) {
      handleSearchMemo(false, true);
    } else renderRef.current = true;
  }, [handleSearchMemo]);

  useEffect(() => {
    if (requestedIndices.length > 0) {
      const stagedRowsLength = selectedRows.filter(
        (item) => item._isStagedApprovalRequest
      ).length;
      if (stagedRowsLength !== requestedIndices.length && isAllRequestChecked) {
        setIsAllRequestChecked(false);
      } else {
        if (requestedIndices.length === selectedRows.length) {
          setIsAllRequestChecked(true);
        } else {
          if (isAllRequestChecked) setIsAllRequestChecked(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const rowsStampable = useMemo(() => {
    const data = transformedData?.filter((item) => {
      return !item._isAccountingEntryStampedComplete && item._isAccountingEntry;
    });
    return data;
  }, [transformedData]);

  //Reset the TAListViewOptions reactive var on unmount of this component.
  useEffect(() => {
    return () => {
      setTAListViewOptions({
        isSigning: true,
        isHistory: false,
        isSearchAll: true,
      });
    };
  }, []);

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Travel Authorization</Text>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Dropdown
              placeholder="Select"
              selectedKey={
                TAListViewOptions.isSearchAll
                  ? listTypeEnum.all
                  : listTypeEnum.my
              }
              options={listTypeOptions}
              onChange={(event, option) => {
                if (event) {
                  const isSearchAll = option?.key === listTypeEnum.all;
                  setTAListViewOptions({ ...TAListViewOptions, isSearchAll });
                }
              }}
              styles={dropdownStyles}
            />
            <PurchaseOrderGeneration
              selectedRows={selectedRows}
              variables={travelAuthorizationSearchVariables}
            />
            {!!rowsStampable?.length && !TAListViewOptions.isHistory && (
              <DefaultButton
                text="Stamp"
                onClick={() => {
                  setStampTransaction((prevState) => !prevState);
                  expandedNav(stampTransaction);
                  const newColumns = gridColumns.map((item) => {
                    if (item.key === 'transactionStamp')
                      return { ...item, isVisible: !stampTransaction };
                    else return item;
                  });
                  setGridColumns(newColumns);
                }}
                iconProps={{
                  iconName: 'StampSmall',
                  styles: {
                    root: {
                      fill: theme.palette.black,
                    },
                  },
                }}
              />
            )}
            {showRequestAll && (
              <Stack tokens={{ padding: '0px 10px 0px 0px' }}>
                <RequestMessageModal
                  entityType={EntityType.TravelPlan}
                  action={EntityAction.Request}
                  disabled={
                    !selectedRows.some(
                      (selected) => selected._isStagedApprovalRequest
                    )
                  }
                  visible={selectedRows.length > 0}
                  multiple={{
                    validCount: selectedRows.filter(
                      (selected) => selected._isStagedApprovalRequest
                    ).length,
                    invalidNames: selectedRows
                      .filter((selected) => !selected._isStagedApprovalRequest!)
                      .map(
                        (cannotDelete) =>
                          cannotDelete.travelAuthorizationNumber!
                      ),
                  }}
                  buttonProps={{ text: 'Request Approval' }}
                  onConfirm={async (
                    requestComment: string | null,
                    requiredDate: string | null
                  ) => {
                    const inputVariables: ApprovalRequestInput[] = selectedRows
                      .filter((item) => item._isStagedApprovalRequest)
                      .map((requestInput) => ({
                        entityId: requestInput.id!,
                        rowTimestamp: requestInput._rowTimestamp!,
                        comments: requestComment,
                        requiredDate: requiredDate ? requiredDate : undefined,
                      }));
                    if (inputVariables.length > 0) {
                      const { errors } =
                        await travelAuthorizationApprovalCreate({
                          variables: {
                            input: {
                              entityApproval: inputVariables,
                            },
                          },
                          awaitRefetchQueries: true,
                          refetchQueries: [
                            {
                              query: TRAVEL_AUTHORIZATION_SEARCH,
                              variables: travelAuthorizationSearchVariables,
                            },
                          ],
                        });
                      if (errors?.length)
                        addToast(errors[0].message, {
                          appearance: 'error',
                        });
                      else {
                        addToast('Request sent for approvals', {
                          appearance: 'success',
                        });
                        setIsAllRequestChecked(false);
                        setRequestedIndices([]);
                      }
                    }
                  }}
                  isLoading={travelAuthorizationApprovalCreateLoading}
                />
              </Stack>
            )}
            <ActionMessageModal
              entityType={EntityType.TravelPlan}
              disabled={!selectedRows.some((selected) => selected._isDeletable)}
              visible={selectedRows.length > 0}
              multiple={{
                validCount: selectedRows.filter(
                  (selected) => selected._isDeletable
                ).length,
                invalidNames: selectedRows
                  .filter((selected) => !selected._isDeletable)
                  .map((selected) => selected.travelAuthorizationNumber || ''),
              }}
              onConfirm={_onConfirm}
            />
            <PrimaryButton
              text="New Travel Plan"
              iconProps={{ iconName: 'Add' }}
              onClick={() => {
                history.push('/ta/travel-plan/travel');
              }}
            />
          </Stack>
        </Stack>
        <TASearchBar
          urlParams={{
            searchParam: searchParam!,
          }}
          hintsAvailable={HINTS_AVAILABLE}
          columns={gridColumns}
          onToggleVisibility={setGridColumns}
          onRefresh={() => {
            handleSearch(false, false);
          }}
          onFilterChange={(filters) => {
            setSearchFilters(filters);
          }}
          onEnterPress={(value) => {
            setSearchHints(null);
            setHintsVisibility(false);
            setSearchTextValue(value);
            setGlobalSearchText(value);
          }}
          onHintsViewToggle={() =>
            setHintsVisibility((prevState) => !prevState)
          }
          onGlobalToggle={(value) => {
            setTAListViewOptions({
              ...TAListViewOptions,
              isSigning: true,
              isHistory: false,
            });
            handleSearchGlobal(false, true, value);
          }}
        />
        {hintsVisibility && HINTS_AVAILABLE && (
          <MultiSelectTags
            multiSelect={false}
            options={searchHintsOptions}
            onSelectionChange={(selctedTags) => {
              const hints = selctedTags.map((item) => parseInt(item.key));
              setSearchHints(hints[0]);
            }}
          />
        )}
        <Stack horizontal className={styles.checkboxContainer}>
          {showRequestAll && (
            <Checkbox
              boxSide="end"
              styles={checkBoxStyle}
              checked={isAllRequestChecked}
              label="Request All"
              onChange={(_, value) => {
                setIsAllRequestChecked(!isAllRequestChecked!);
                if (value) {
                  const requestedIndicesArray = transformedData?.reduce(
                    function (array: number[], element, index) {
                      if (element._isStagedApprovalRequest) array.push(index);
                      return array;
                    },
                    []
                  );
                  setRequestedIndices(requestedIndicesArray || []);
                } else {
                  setRequestedIndices([]);
                }
              }}
            />
          )}
        </Stack>
      </Stack>
      <InfiniteList
        items={transformedData || []}
        loading={loading}
        hasNextPage={hasNextPage}
        showFooter={showFooter}
        columns={gridColumns.filter((column) => column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
        onRenderRow={_onRenderRow}
        onColumnHeaderClick={_onColumnClick}
        onLoadMore={async () => await handleSearch(true, false)}
        onSelectionChanged={setSelectedRows}
        requestedIndices={requestedIndices}
        totalRowsCount={totalCount1}
      />
      <StampTravelPlanFooter
        isStampTransactionMode={stampTransaction}
        rows={transformedData}
        rowsStampable={rowsStampable}
        stampFields={transactionStampField}
        onStampProcessComplete={() => {
          expandedNav(true);
          setStampTransaction(false);
          setTransactionStampField(new Map([]));
          const newColumns = gridColumns.map((item) => {
            if (item.key === 'transactionStamp')
              return { ...item, isVisible: false };
            else return item;
          });
          setGridColumns(newColumns);
        }}
      />
    </>
  );
};
