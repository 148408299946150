import React, { useCallback, useMemo, useState } from 'react';
import {
  Stack,
  Text,
  TooltipHost,
  ActionButton,
  IconButton,
} from '@fluentui/react';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { loader } from 'graphql.macro';
import { useStyles } from './index.styles';
import { _columns } from './columns.data';
import { toOrderByVariable } from './utils';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { NetworkStatus, useQuery } from '@apollo/client';
import { AttachDocumentModal } from 'common/components/AttachDocumentModal';
import { DISABLED_FILE_FORMATS, TABLE_ROWS } from 'common/constants';
import { DocumentDataCallout } from 'common/components/AttachDocumentModal/DocumentDataCallout';
import { SortOrder } from 'common/utils/commonTypes';
import { Approval_approval } from '../__generated__/Approval';
import { ApprovalAvailableDocumentTypes } from '../ActionMenu/ApprovalSetupAttachSection/__generated__/ApprovalAvailableDocumentTypes';
import {
  AttachableApprovalDocuments,
  AttachableApprovalDocumentsVariables,
  AttachableApprovalDocuments_attachableApprovalDocuments_nodes,
} from '../ActionMenu/ApprovalSetupAttachSection/__generated__/AttachableApprovalDocuments';
import { EntityDocumentFilter } from 'common/types/globalTypes';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { DocumentViewModalState } from 'common/components/DocumentList';
const ATTACHABLE_USER_DOCUMENTS = loader(
  '../ActionMenu/ApprovalSetupAttachSection/AttachableApprovalDocuments.graphql'
);
const APPROVAL_SETUP_AVAILABLE_DOCUMENT_TYPES = loader(
  '../ActionMenu/ApprovalSetupAttachSection/ApprovalAvailableDocumentTypes.graphql'
);

type AttachableDataType =
  AttachableApprovalDocuments_attachableApprovalDocuments_nodes;

export interface AttachedDocumentsDataType {
  documentTypeId: number | null | undefined;
  entityDocumentId: string[] | null;
  attachedDocuments: AttachableDataType[];
}
interface UserDocumentsProps {
  approvalSetupDetails?: Approval_approval | null | undefined;
  isOpen?: boolean;
  dismissModal?: (param: boolean) => void;
  onDocumentsAttached: (
    attachedDocumentsData: AttachedDocumentsDataType
  ) => void;
  prevSelectedDocuments?: AttachableDataType[];
}
export const UserDocuments: React.FC<UserDocumentsProps> = ({
  approvalSetupDetails,
  onDocumentsAttached,
  prevSelectedDocuments,
}) => {
  const styles = useStyles();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [documentTypeId, setDocumentTypeId] = useState<
    number | null | undefined
  >();
  const [selectedList, setSelectedList] = useState<AttachableDataType[]>([]);
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });
  const {
    data: documentsList,
    loading: documentsLoading,
    variables: documentsVariables,
    networkStatus,
    fetchMore,
    refetch,
  } = useQuery<
    AttachableApprovalDocuments,
    AttachableApprovalDocumentsVariables
  >(ATTACHABLE_USER_DOCUMENTS, {
    variables: {
      approvalId: approvalSetupDetails?.id!,
      documentTypeId: documentTypeId,
      first: TABLE_ROWS,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { data: availableDocumentTypes } =
    useQuery<ApprovalAvailableDocumentTypes>(
      APPROVAL_SETUP_AVAILABLE_DOCUMENT_TYPES,
      {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
      }
    );

  const documentTypeOptions =
    availableDocumentTypes?.approvalAvailableDocumentTypes?.nodes.map(
      (doctype) => ({
        key: doctype.id,
        text: doctype.documentType || '',
      })
    ) || [];

  const { pageInfo } = {
    ...documentsList?.attachableApprovalDocuments,
  };

  const onFiltersReload = useCallback(
    async (filter: EntityDocumentFilter | undefined) =>
      await refetch({ ...documentsVariables, filter }),
    [documentsVariables, refetch]
  );

  const reload = useCallback(
    async (sort?: SortOrder) =>
      await refetch({ after: null, orderBy: toOrderByVariable(sort) }),
    [refetch]
  );

  const loadMore = useCallback(
    async () =>
      await fetchMore({
        variables: {
          ...documentsVariables,
          after: documentsList?.attachableApprovalDocuments?.pageInfo.endCursor,
        },
      }),
    [fetchMore, documentsVariables, documentsList]
  );

  const onDocumentTypeReload = useCallback(
    async (documentTypeId: number | null) => {
      setDocumentTypeId(documentTypeId);
      await refetch({ ...documentsVariables, documentTypeId });
    },
    [refetch, documentsVariables]
  );

  const transformedData = useMemo(() => {
    const documents = documentsList?.attachableApprovalDocuments?.nodes.map(
      (documents) =>
        ({
          ...documents,
          available: documents.documentAppliedAmounts?.remainingTotal,
          isoCode: documents.currency?.isoCode,
        } as AttachableDataType)
    );
    if (prevSelectedDocuments?.length && documents?.length) {
      const filteredArray = documents.filter(
        ({ id: id1 }) =>
          !prevSelectedDocuments.some(({ id: id2 }) => id2 === id1)
      );
      return filteredArray;
    }
    return documents;
  }, [documentsList, prevSelectedDocuments]);

  const onRenderItemColumn = useCallback(
    (item?: AttachableDataType, _index?: number, column?: IColumn) => {
      if (!item || !column) return undefined;

      const fieldContent = item[
        column.fieldName as keyof AttachableDataType
      ] as string | null;
      const viewDocumentVisible =
        item._isProtected! || DISABLED_FILE_FORMATS.includes(item._fileType!);
      switch (column.key) {
        case 'fileReference':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <DocumentDataCallout item={item} />
            </Stack>
          );
        case 'documentType':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text>{item.documentTypes?.documentType}</Text>
            </Stack>
          );

        case 'isoCode':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text>{item.currency?.isoCode}</Text>
            </Stack>
          );

        case '_uploadDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text>{getGlobalDateFormat(item._uploadDate!)}</Text>
            </Stack>
          );

        case 'indexTransactionDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              {item.indexTransactionDate && (
                <Text>
                  {dateFormat(dateConvertions(item.indexTransactionDate))}
                </Text>
              )}
            </Stack>
          );
        case 'view':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );

        default:
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    },
    [styles]
  );

  const _onAttach = async () => {
    const selectedDocumentsArray = selectedList;
    if (selectedDocumentsArray.length > 0) {
      const documentsIDs: string[] = selectedDocumentsArray.map(
        (ele) => ele.id
      );
      onDocumentsAttached({
        documentTypeId: documentTypeId,
        entityDocumentId: documentsIDs,
        attachedDocuments: selectedDocumentsArray,
      });
    }
    setDialogVisible(false);
    setSelectedList([]);
  };

  const attachDisabled = selectedList.length === 0;

  return (
    <Stack>
      <Stack horizontal horizontalAlign="end">
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Documents"
            checked={true}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachDocumentModal
          columns={_columns}
          loading={documentsLoading}
          modalWidth={1250}
          items={
            networkStatus === NetworkStatus.refetch ||
            networkStatus === NetworkStatus.setVariables
              ? undefined
              : transformedData
          }
          hasNextPage={pageInfo?.hasNextPage}
          attachLoading={false}
          setOpen={setDialogVisible}
          availableDocumentTypes={documentTypeOptions}
          onSortReload={reload}
          onLoadMore={loadMore}
          onFiltersReload={onFiltersReload}
          onDocumentTypeChange={onDocumentTypeReload}
          attachDisabled={attachDisabled}
          onSelectionChanged={setSelectedList}
          dropdownDisabled={selectedList.length > 0}
          onRenderItemColumn={onRenderItemColumn}
          onAttachDocuments={_onAttach}
        />
      )}
      <DocumentViewModal
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </Stack>
  );
};
