import { ColumnActionsMode } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const _columns: ColumnData[] = [
  {
    key: '_documentPoolName',
    name: 'Folder',
    fieldName: '_documentPoolName',
    minWidth: 120,
    maxWidth: 130,
    ...commonColumnProps,
  },
  {
    key: 'indexName',
    name: 'Name',
    fieldName: 'indexName',
    minWidth: 170,
    ...commonColumnProps,
  },
  {
    key: 'indexReferenceNumber',
    name: 'Number',
    fieldName: 'indexReferenceNumber',
    minWidth: 100,
    maxWidth: 100,
    ...commonColumnProps,
  },
  {
    key: 'indexTransactionDate',
    name: 'Date',
    fieldName: 'indexTransactionDate',
    minWidth: 70,
    ...commonColumnProps,
  },
  {
    key: '_documentContents',
    name: 'Contents',
    fieldName: '_documentContents',
    minWidth: 170,
    ...commonColumnProps,
  },
  {
    key: 'view',
    name: '',
    fieldName: 'view',
    minWidth: 20,
    maxWidth: 20,
    isResizable: true,
    columnActionsMode: ColumnActionsMode.disabled,
    ...commonColumnProps,
  },
  
  {
    key: 'fileReference',
    name: 'Document',
    fieldName: 'fileReference',
    minWidth: 140,
    ...commonColumnProps,
  },
  {
    key: 'indexAmount',
    name: 'Total',
    fieldName: 'indexAmount',
    minWidth: 80,
    ...commonColumnProps,
  },
  {
    key: '_uploadDate',
    name: 'Uploaded',
    fieldName: '_uploadDate',
    minWidth: 150,
    isResizable: true,
    ...commonColumnProps,
  },
  
];
